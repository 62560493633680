import React, { useEffect } from "react";
import T from "prop-types";
import { Controller } from "react-hook-form";

import { useTranslation } from "@tint_fe/hooks";
import { Input } from "@tint_fe/ui";
import { isAirport } from "@tint_fe/helpers";

const FlightNumber = ({ label, name, dependOnLocation, control, onSelectNotAirport, ...rest }) => {
  const { t } = useTranslation();

  const isLocationAirport = isAirport(dependOnLocation);

  useEffect(() => {
    if (!isLocationAirport && onSelectNotAirport) {
      onSelectNotAirport();
    }
  }, [isLocationAirport]);

  return isLocationAirport ? (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => {
        return (
          <Input
            label={label}
            classNameWrapper="form-group--wrapper"
            placeholder={t("formField.flightNumber.placeholder")}
            {...field}
            {...rest}
          />
        );
      }}
    />
  ) : null;
};

FlightNumber.propTypes = {
  label: T.string.isRequired,
  name: T.string.isRequired,
  dependOnLocation: T.string.isRequired,
  control: T.object.isRequired,
  onSelectNotAirport: T.func,
};

export default FlightNumber;
