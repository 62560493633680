import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { timeToShowTimer } from "@tint_fe/helpers";
import { useRouterMatch } from "@tint_fe/hooks";
import { PAYMENT, getCustomerPathByBuildType } from "../react/router/routes";

const useRedirectFromPayment = () => {
  const { build_type } = useSelector(({ order }) => order.data);
  const isPayment = useRouterMatch(PAYMENT);

  const { replace } = useHistory();
  const { pathname, state } = useLocation();
  const customerPath = getCustomerPathByBuildType(build_type);
  const paymentExpireAt = useMemo(() => new Date().getTime() + timeToShowTimer.PAYMENT, [isPayment]);

  return {
    goToPrevPath: () => {
      replace({ pathname: customerPath, state: { prevPath: pathname } });
    },
    paymentExpireAt,
    isPrevStepPayment: state?.prevPath?.includes(PAYMENT),
  };
};

export default useRedirectFromPayment;
