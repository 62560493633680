import React from "react";
import { useSelector } from "react-redux";

import { ContactUsModal as SharedContactUsModal } from "@tint_fe/ui";
import { toggleContactUsModalAction } from "../../redux/global/globalActions";
import { useReduxAction } from "../../hooks";

const ContactUsModal = () => {
  const rootId = useSelector(({ global }) => global.rootId);
  const toggleContactUsModal = useReduxAction(toggleContactUsModalAction);

  const integration = useSelector(({ global }) => global.integration);
  const openContactUsModal = useSelector(({ global }) => global.openContactUsModal);

  return (
    <SharedContactUsModal
      rootId={rootId}
      toggleContactUsModal={toggleContactUsModal}
      openContactUsModal={openContactUsModal}
      integration={integration}
    />
  );
};

ContactUsModal.propTypes = {};

export default ContactUsModal;
