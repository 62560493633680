import React from "react";
import T from "prop-types";

import { useControlledInput } from "@tint_fe/hooks";
import { PhoneInput } from "../../components";

const ControlledPhoneInput = ({ name, rules, control, ...rest }) => {
  const {
    field,
    fieldState: { error },
    showValidateMark,
  } = useControlledInput({ name, rules, control });

  return <PhoneInput error={error} showValidateMark={showValidateMark} {...rest} {...field} />;
};

ControlledPhoneInput.propTypes = {
  name: T.string,
  rules: T.object,
  control: T.object,
};

export default ControlledPhoneInput;
