import { createSelector } from "reselect";

import { bookingTypes } from "../../helpers/constants";
import { dates } from "@tint_fe/helpers";

export const selectAccommodationExpiredTime = createSelector(
  (state) => state.smartPackage?.data,
  (smartPackage) => smartPackage.accomodation_expire_at || 0,
);

export const selectHotelsForSummary = createSelector(
  [
    ({ order }) => order.data.product_items || [],
    ({ smartPackage }) => smartPackage.accommodation || [],
    ({ smartPackage }) => smartPackage.selectedHotels || [],
  ],
  (orderProductItems, accommodation, selectedHotels) => {
    // if we don't have order pls try to get hotels from accommodation
    if (!orderProductItems.length) {
      return selectedHotels.map((el) => ({
        name: el.display_name,
        days: accommodation?.find((city) => city.from === el.start_at)?.nights,
        id: el.product_id,
      }));
    }

    return orderProductItems
      .filter((el) => el.klass === bookingTypes.HOTEL || el.skip_accommodation)
      .map((el) => ({
        name: el.display_name,
        days: dates.differenceInCalendarDays(el.end_at, el.start_at),
      }));
  },
);
