import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

import "./i18n";
import App from "./App";
import configureStore from "./redux/configureStore";
import "./assets/scss/index.scss";
import { api } from "@tint_fe/api";
import { initAnalytics } from "@tint_fe/helpers/services/google-analytics-v2";

smoothscroll.polyfill();
const store = configureStore();

function initializeWidget(props, id = "root") {
  const node = document.getElementById(id);

  api.initialize(props);
  initAnalytics();

  if (node) {
    //  Initialize google maps
    if (!window.google) {
      const googleAutocomplete = document.createElement("script");
      googleAutocomplete.type = "text/javascript";
      googleAutocomplete.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyA5fOnU6hcz4zltSNkwltQCit2uMcR5xE0&libraries=places";
      document.body.insertBefore(googleAutocomplete, document.body.firstChild);
    }

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <Router>
            <App rootId={id} {...props} />
          </Router>
        </Provider>
      </React.StrictMode>,
      node,
    );
  } else {
    console.warn(`DOM element with id ${id} not found.`);
  }
}

window.initializeWidget = initializeWidget;
