import React from "react";
import { components } from "react-select";

import { Close } from "../../../assets/icons";

const SelectClearValue = ({ ...rest }) => {
  return (
    <components.ClearIndicator {...rest}>
      <Close width={24} height={24} />
    </components.ClearIndicator>
  );
};

export default SelectClearValue;
