import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { InputWrapper } from "..";

const Checkbox = React.forwardRef(
  ({ className, label, error, errorClasses, scrollIntoViewOnError, onClick, value, onChange, ...rest }, ref) => {
    const { mainCls, elCls } = parseClasses({
      base: "checkbox",
      additional: className,
      classes: [error && "error"],
    });

    return (
      <InputWrapper
        scrollIntoViewOnError={scrollIntoViewOnError}
        classNameWrapper={mainCls}
        data-testid="checkbox-wrapper-testid"
        error={error}
        errorClasses={errorClasses}
        withoutOverflowTooltip
      >
        <div className={elCls("content")}>
          <input
            ref={ref}
            type="checkbox"
            onChange={(e) => onChange && onChange(e)}
            onClick={(e) => onClick && onClick(e)}
            value={value}
            data-testid="checkbox-testid"
            {...rest}
          />
          <span className={elCls("checkmark")} />
          <label className={`${elCls("content")}`}>
            {label && <span className={`${elCls("label")} mg-l-3`}>{label}</span>}
          </label>
        </div>
      </InputWrapper>
    );
  },
);

Checkbox.propTypes = {
  error: T.object,
  className: T.string,
  errorClasses: T.string,
  label: T.oneOfType([T.string, T.node]),
  scrollIntoViewOnError: T.bool,
  onClick: T.func,
  value: T.bool,
  onChange: T.func,
};

export default Checkbox;
