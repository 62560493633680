import React from "react";
import T from "prop-types";
import { useTranslation } from "@tint_fe/hooks";
import { checkCurrencyChar } from "@tint_fe/helpers";

const SummaryLateBooking = ({ price, classNameItem, currency = "USD" }) => {
  const { t } = useTranslation();
  const currencyChar = checkCurrencyChar(currency);

  return (
    price > 0 && (
      <div className={classNameItem}>
        <span dir="ltr">{t("summary.lateBookingSupplement")}</span>
        <span className={"price"}>
          {currencyChar}
          {price}
        </span>
      </div>
    )
  );
};

SummaryLateBooking.defaultProps = {
  price: 0,
};

SummaryLateBooking.propTypes = {
  price: T.number,
  classNameItem: T.string,
  currency: T.string,
};

export default SummaryLateBooking;
