import React, { useEffect } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select, { components } from "react-select";

import { FlagUS, FlagEU, FlagGB, FlagJP } from "../../assets/icons";
import { bookingTypes, parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { api } from "@tint_fe/api";
import { steps } from "../../helpers/constants";
import { useReduxAction } from "@tint_fe/redux-utils";
import { fetchPricesRequest, setRefetchPricesDueCurrencyChange } from "../../redux/global/globalActions";
import { useChangeOrderCurrency } from "../../hooks";

const getCustomStyles = ({ menuPosition = { right: 0 } }) => ({
  menu: (provided) => ({
    ...provided,
    ...(menuPosition || {}),
    width: 126,
  }),
  valueContainer: (provided) => ({
    ...provided,
    visibility: "hidden",
    position: "absolute",
    zIndex: -1,
  }),
});

const defaultCurrencies = [
  {
    value: "USD",
    label: "USD ($)",
    icon: FlagUS,
    shortLabel: "USD ($)",
  },
  {
    value: "EUR",
    label: "EUR (€)",
    icon: FlagEU,
    shortLabel: "EUR (€)",
  },
  {
    value: "GBP",
    label: "GBP (£)",
    icon: FlagGB,
    shortLabel: "GBP (£)",
  },
  {
    value: "JPY",
    label: "JPY (¥)",
    icon: FlagJP,
    shortLabel: "JPY (¥)",
  },
];

const Option = ({ data, ...rest }) => {
  const Flag = data.icon;

  return (
    <components.Option {...rest} className={"select-lang__option"}>
      <Flag className="flag mg-t-1" />
      <span className="main-text">{data.label} </span>
    </components.Option>
  );
};

Option.propTypes = {
  children: T.node,
  data: T.object,
};

const Control = ({ selectProps, children, ...rest }) => {
  const { shortLabel } = selectProps.value;

  return (
    <components.Control selectProps={selectProps} className={"select-lang__control"} {...rest}>
      <span className="select-lang__value-text">{shortLabel}</span>
      {children}
    </components.Control>
  );
};

Control.propTypes = {
  selectProps: T.object,
  children: T.node,
};

const CurrencySelect = ({ hideIndicatorsContainer, className, currencies }) => {
  const { isRtl } = useTranslation();
  const { pathname } = useLocation();
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);
  const bookingType = useSelector((state) => state.global.bookingType);
  const order = useSelector((state) => state.order.data);
  const price = useSelector((state) => state.global.price);
  const isOrderCreated = !!order?.number;
  const orderChargeCurrency = isOrderCreated ? price?.charge_currency : api.currency;

  const fetchPrices = useReduxAction(fetchPricesRequest);
  const fetchPricesWithOrderNumber = () => {
    if (isOrderCreated) {
      fetchPrices({
        order: {
          number: order.number,
        },
        ...(bookingTypes.SMART_PACKAGE === order.build_type && { number: order.number }),
      });
    } else {
      fetchPrices({});
    }
  };
  const { changeCurrency } = useChangeOrderCurrency({ orderId: order?.number, onSuccess: fetchPricesWithOrderNumber });

  const { mainCls } = parseClasses({
    base: "select-lang",
    classes: [isRtl && "rtl"],
    additional: [className, "select", "booking-select-lng", "input-wrapper"],
  });

  const customStyles = getCustomStyles(isRtl ? { left: 0 } : { right: 0 });

  const handleChange = ({ value }) => {
    api.changeCurrency(value);

    if (pathname.includes(steps.CHECKOUT)) {
      if (isOrderCreated) {
        changeCurrency(value);
      } else if (bookingType !== bookingTypes.TOUR) {
        fetchPrices({});
      }
      setRefetchPrices(true);
    } else if (pathname.includes(steps.SELECT_HOTELS)) {
      if (isOrderCreated) {
        changeCurrency(value);
      }
      setRefetchPrices(true);
    } else if (pathname.includes(steps.CUSTOMER) || pathname.includes(steps.PAYMENT)) {
      changeCurrency(value);

      setRefetchPrices(true);
    }
  };

  useEffect(() => {
    if (isOrderCreated) {
      api.changeCurrency(orderChargeCurrency);
    }
  }, []);

  return (
    <div className={mainCls}>
      <Select
        isRtl={isRtl}
        inputId="currency"
        classNamePrefix="select-lang"
        styles={customStyles}
        defaultValue={
          orderChargeCurrency ? currencies.find(({ value }) => value === orderChargeCurrency) : currencies[0]
        }
        options={currencies}
        onChange={handleChange}
        // menuIsOpen
        components={{
          Option,
          Control,
          IndicatorSeparator: () => null,
          IndicatorsContainer: hideIndicatorsContainer ? () => null : components.IndicatorsContainer,
          Input: (inputProps) => <components.Input {...inputProps} readOnly />,
        }}
      />
    </div>
  );
};

CurrencySelect.defaultProps = {
  currencies: defaultCurrencies,
};

CurrencySelect.propTypes = {
  className: T.string,
  hideIndicatorsContainer: T.bool,
  currencies: T.array,
};

export default CurrencySelect;
