import { dates } from "@tint_fe/helpers";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import { createSmartPackageOrderRequest } from "../../../../redux/order/orderActions";
import useGetSPCheckoutFromData from "./useGetSPCheckoutFromData";

export const normalizeDataForCreateOrder = (orderDetails) => {
  const isWithRooms = typeof orderDetails?.rooms === "object";
  const paxes = isWithRooms
    ? {
        rooms: {
          // eslint-disable-next-line no-unused-vars
          ...orderDetails.rooms.map(({ key, children, ...rest }) => ({
            ...rest,
            ...(children.length && { children }),
          })),
        },
      }
    : { adults: orderDetails.adults, children: orderDetails.children };

  return {
    order: {
      ...orderDetails,
      package_start_date: dates.format(orderDetails.package_start_date, "yyyy-MM-dd"),
      ...paxes,
      package_start_location_id: String(orderDetails.package_start_location_id),
    },
  };
};

const useCreateAutoAccommodationSPOrder = () => {
  const { push } = useHistoryNavigation();
  const createSmartPackageOrder = useReduxAction(createSmartPackageOrderRequest);
  const getSPCheckoutFromData = useGetSPCheckoutFromData();

  return () => {
    const checkoutFormData = getSPCheckoutFromData();

    const normalizedData = normalizeDataForCreateOrder(checkoutFormData);
    createSmartPackageOrder({ body: normalizedData, push, autoAccommodation: true });
  };
};

export default useCreateAutoAccommodationSPOrder;
