import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { Img, Button } from "../";

const SuggestedCard = ({ img, name, link, onClick }) => {
  const { mainCls, elCls } = parseClasses({ base: "suggested-card" });

  return (
    <li className={mainCls}>
      <Img withBorder={!img} src={img} />
      <div className={elCls("content")}>
        <p className={elCls("name", "bold", "uppercase", "p5")}>{name}</p>
        <Button onClick={onClick ? onClick : null} ghost href={link} as="a">
          discover
        </Button>
      </div>
    </li>
  );
};

SuggestedCard.propTypes = {
  img: T.string,
  name: T.string,
  link: T.string.isRequired,
  onClick: T.func,
};

export default SuggestedCard;
