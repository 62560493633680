import React from "react";
import T from "prop-types";

import { useControlledInput } from "@tint_fe/hooks";
import { Textarea } from "../../components";

const Notes = ({ name, control, rule, ...rest }) => {
  const { field } = useControlledInput({ name, control, rule });

  return <Textarea {...rest} {...field} />;
};

Notes.propTypes = {
  name: T.string,
  control: T.object,
  rule: T.object,
};

export default Notes;
