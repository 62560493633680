import React from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";

const SummaryTaxes = ({ className, taxes, currencyChar }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {taxes.included_taxes && (
        <div className="row">
          <span className="name">{t("formField.includesTaxes")}</span>
          <span className="value">
            {currencyChar}
            {taxes.included_taxes}
          </span>
        </div>
      )}
      {taxes.excluded_taxes && (
        <div className="row">
          <span className="name">{t("formField.excludesTaxes")}</span>
          <span className="value">
            {currencyChar}
            {taxes.excluded_taxes}
          </span>
        </div>
      )}
    </div>
  );
};

SummaryTaxes.defaultProps = {
  taxes: {},
  currencyChar: "$",
};

SummaryTaxes.propTypes = {
  className: T.string,
  taxes: T.shape({
    included_taxes: T.number,
    excluded_taxes: T.number,
  }),
  currencyChar: T.string,
};

export default SummaryTaxes;
