import { useCallback } from "react";

import { QueryString } from "@tint_fe/helpers";
import { fetchPricesRequest } from "../../../../redux/global/globalActions";
import { useGetUser, useReduxAction } from "../../../../hooks";
import useSerializedTourAttributes from "./useSerializedTourAttributes";
import { useSelector } from "react-redux";

/* This hook use when language, drop off point, price bands, extras, are changed */
const useUpdateTourPrice = () => {
  const fetchPrices = useReduxAction(fetchPricesRequest);
  const serializedTourAttributes = useSerializedTourAttributes();
  const productItems = useSelector((state) => state.order?.data?.product_items);
  const { user } = useGetUser();

  return useCallback(
    (data, settings) => {
      const { preventSendingProductItems, sendOrderNumber, preventPriceBands, getExtrasFromFormContext } =
        settings || {};
      const productItemsAttributes = serializedTourAttributes(data, {
        preventPriceBands,
        getExtrasFromFormContext,
      });
      const productItemId = user?.isAgent ? productItems?.[0]?.id : null;

      const { order: orderNumber } = QueryString.parse(document.location.search);

      fetchPrices({
        order: {
          ...(sendOrderNumber && { number: orderNumber }),
          ...((!preventSendingProductItems || user?.isAgent) && {
            product_items_attributes: { 0: { ...productItemsAttributes, ...(productItemId && { id: productItemId }) } },
          }),
        },
      });
    },
    [serializedTourAttributes, productItems],
  );
};

export default useUpdateTourPrice;
