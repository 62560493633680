import React from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import { parseClasses } from "@tint_fe/helpers";
import { ValidateMark } from "../../assets/icons";

const ValidateMarks = ({ indent }) => {
  const { isRtl } = useTranslation();

  const { mainCls } = parseClasses({
    base: "validate-mark",
    classes: [indent && "indent"],
    additional: isRtl && "rtl",
  });

  return (
    <div className={mainCls}>
      <ValidateMark />
    </div>
  );
};

ValidateMarks.propTypes = {
  indent: T.bool,
};

export default ValidateMarks;
