import { useEffect, useMemo, useState } from "react";

import { useRequest } from "@tint_fe/hooks";
import { getForbiddenDatesActivity } from "@tint_fe/api";
import { dates, findFirstActivityAvailableDate } from "@tint_fe/helpers";

const PACKAGE_FORMAT_DATE = "yyyy-MM-dd";
const formatStartDate = (date) => {
  const currDate = dates.isBefore(date, new Date()) ? dates.startOfMonth(date, false) : date;

  return dates.format(currDate, PACKAGE_FORMAT_DATE, false);
};

const useForbiddenDatesForActivity = ({ activityId, offset, preventGetAvailableDateOnInit, pickupPointId }) => {
  const [currDateToSearch, setCurrDateToSearch] = useState(new Date());
  const [forbiddenDates, setForbiddenDates] = useState([]);
  const firstAvailableDate = useMemo(
    () => forbiddenDates && findFirstActivityAvailableDate(forbiddenDates),
    [forbiddenDates],
  );
  const availableTo = dates.add(new Date(), { months: 11 }, false);

  useEffect(() => {
    if (!forbiddenDates.length) return;

    if (firstAvailableDate) {
      setCurrDateToSearch(firstAvailableDate);
    }
  }, [forbiddenDates]);

  const { loading, callToApi: getForbiddenDates } = useRequest({
    apiCall: getForbiddenDatesActivity,
    initialLoading: true,
    onSuccess: (response) => {
      const fbd = response?.product?.forbidden_dates ?? [];
      if (!fbd.length) return;
      if (forbiddenDates.includes(fbd[1])) return;

      setForbiddenDates((prev) => [...prev, ...fbd]);
    },
  });

  const addOffset = (data) => {
    if (typeof offset === "number" && offset > 0) {
      return dates.add(data, { months: 1 }, false);
    }

    return data;
  };

  const handleGetForbiddenDates = (date, point_id, skipDate = false, skipRequest = false) => {
    const pointId = point_id || pickupPointId;
    setCurrDateToSearch(date);
    !skipRequest &&
      getForbiddenDates({
        id: activityId,
        params: {
          ...(!skipDate && { date: formatStartDate(addOffset(date)) }),
          ...(pointId && { pickup_point_id: pointId }),
        },
      });
  };

  useEffect(() => {
    if (preventGetAvailableDateOnInit) {
      return;
    }

    handleGetForbiddenDates(new Date());
  }, []);

  const checkIsDisabled = (date, mode) => {
    if (dates.isBefore(date, new Date()) || dates.isAfter(date, availableTo)) return true;
    if (firstAvailableDate && dates.isBefore(date, firstAvailableDate, false)) return true;
    if (mode && mode !== "date") return false;
    if (!forbiddenDates.length) return false;

    const isAfterDateRange = dates.isAfter(
      date,
      dates.endOfMonth(dates.add(currDateToSearch, { months: 2 }, false), false),
    );
    const isBeforeDateRange = dates.isBefore(date, dates.startOfMonth(currDateToSearch));

    if (isBeforeDateRange || isAfterDateRange) return true;

    return !!forbiddenDates?.find((el) => {
      return dates.format(date, "dd/MM/yyyy", false) === el;
    });
  };

  return {
    forbiddenDates,
    loading,
    currDateToSearch,
    handleGetForbiddenDates,
    checkIsDisabled,
    firstAvailableDate,
    availableTo,
  };
};

export default useForbiddenDatesForActivity;
