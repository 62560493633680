import React, { useEffect, useMemo, useState } from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { PaginationArrow } from "../../assets/icons";
import { Preloader } from "../";

const getNumbersOfPages = (currPage, countOfPages) => {
  const list = [];
  const curr = currPage === countOfPages - 1 ? currPage : currPage + 1;

  const currentWithNeighbors = [
    ...(curr > countOfPages - 3 ? [countOfPages - 4, countOfPages - 3] : []),
    curr <= 1 ? 1 : curr - 1,
    curr <= 1 ? 2 : curr,
    curr <= 1 ? 3 : curr + 1,
    ...(curr < 4 ? [4, 5] : []),
  ];

  for (let i = 1; i <= countOfPages; i++) {
    if (currentWithNeighbors.includes(i) || i === 1 || countOfPages === i) {
      list.push(i);
      continue;
    }
    if (i === 2 || i === countOfPages - 1) {
      list.push("...");
      continue;
    }
  }

  return list;
};

const Pagination = ({ total, perPage, onChangePage, caption, children, fetching, style, page }) => {
  const [currPage, setCurrPage] = useState(page ?? 0);
  const { mainCls, elCls } = parseClasses({ base: "pagination" });
  const countOfPages = Math.ceil(total / perPage);
  const from = currPage * perPage + 1;
  const to = currPage === countOfPages - 1 ? total : (currPage + 1) * perPage;
  const numbersOfPages = useMemo(() => getNumbersOfPages(currPage, countOfPages), [currPage, countOfPages]);

  const onChange = (number) => () => {
    setCurrPage(number);
    onChangePage && onChangePage(number + 1);
  };

  useEffect(() => {
    if (typeof page === "number") {
      setCurrPage(page);
    }
  }, [page]);

  return (
    <div data-testid="pagination" style={style} className={mainCls}>
      <div className={elCls("body")}>
        {fetching && (
          <Preloader dataTestId="pagination--preloader" scrollOnLoadingToTop className={elCls("preloader")} />
        )}
        {children}
      </div>
      {countOfPages > 1 && (
        <div className={elCls("controls")}>
          <button
            disabled={currPage === 0}
            className={elCls("arrow", "mg-r-2")}
            onClick={onChange(currPage === 0 ? currPage : currPage - 1)}
            data-testid="pagination--perv-btn"
          >
            <PaginationArrow />
          </button>
          <ul data-testid="pagination--btns" className={elCls("list")}>
            {numbersOfPages.map((el, index) => (
              <li
                data-testid={`pagination--btn-${el}`}
                key={index}
                className={elCls("item", el - 1 === currPage && "current")}
              >
                {typeof el === "number" ? <button onClick={onChange(el - 1)}>{el}</button> : el}
              </li>
            ))}
          </ul>
          <button
            disabled={currPage === countOfPages - 1}
            className={elCls("arrow", "mg-l-2")}
            onClick={onChange(currPage === countOfPages - 1 ? currPage : currPage + 1)}
            data-testid="pagination--next-btn"
          >
            <PaginationArrow rotate={180} />
          </button>
        </div>
      )}
      <div data-testid="pagination--caption" className={elCls("caption", "mg-t-3 mg-md-t-6")}>
        {from} – {to} of {total} {caption}
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  caption: "items to discover",
};

Pagination.propTypes = {
  total: T.string,
  perPage: T.number,
  onChangePage: T.func,
  caption: T.string,
  children: T.oneOfType([T.node, T.arrayOf(T.node)]).isRequired,
  fetching: T.bool,
  style: T.object,
  page: T.number,
};

export default Pagination;
