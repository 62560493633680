import React from "react";
import T from "prop-types";

import { Select } from "@tint_fe/ui";

const SelectField = React.forwardRef(
  ({ name, errors, options = [], disabled, isPreselected, value, onChange, onSelect, ...rest }, ref) => {
    const selectedOption = value && options.find((el) => el.value === value);

    return (
      <Select
        ref={ref}
        error={errors?.[name] || errors}
        {...(onChange && { onChange: (data) => onChange({ [name]: data.value, ...data }) })}
        {...(onSelect && { onSelect: (data) => onSelect({ [name]: data.value, ...data }) })}
        showOptionFrom={0}
        options={options}
        value={selectedOption}
        isDisabled={disabled || isPreselected}
        isPreselected={isPreselected}
        classNameWrapper="form-group--wrapper"
        {...rest}
      />
    );
  },
);

SelectField.propTypes = {
  name: T.string,
  errors: T.oneOfType([T.string, T.object]),
  options: T.array,
  disabled: T.bool,
  isPreselected: T.bool,
  value: T.oneOfType([T.string, T.object, T.number]),
  onChange: T.func,
  onSelect: T.func,
};

export default SelectField;
