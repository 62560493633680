import React from "react";
import T from "prop-types";

import { CountingField } from "@tint_fe/ui";

const NumericInput = ({ name, onChange, ...rest }) => {
  const handleChange = (newValue) => onChange({ [name]: newValue });

  return <CountingField onChange={handleChange} {...rest} />;
};

NumericInput.propTypes = {
  name: T.oneOfType([T.string, T.number]).isRequired,
  onChange: T.func.isRequired,
};

export default NumericInput;
