import { rest } from "msw";

export const suggestedProducts = {
  TY58026842: [
    {
      display_name: "Dubai (test tour)",
      image_url: "https://tourist-images-stg-s3.s3.eu-west-1.amazonaws.com/4lqtkprvfo0cash4jo6c3vwce71e",
      external_link: "https://www.google.com",
    },
    {
      display_name: "Lviv test tour display (no tranfsfer/no hotels)",
      image_url: "https://tourist-images-stg-s3.s3.eu-west-1.amazonaws.com/32tmihcho2jkkkgfcdtoexqhmaq8",
      external_link: "https://www.google.com",
    },
    {
      display_name: "asd",
      image_url: "https://tourist-images-stg-s3.s3.eu-west-1.amazonaws.com/ox3wb29j6ofjtuv21if17ob28x7j",
      external_link: "https://docs.safecharge.com/documentation/guides/testing/testing-cards/",
    },
  ],
};

export const suggestedProductsHandlers = {
  getSuggestedProducts: rest.get("/orders/:number/suggested_products", (req, res, ctx) => {
    const orderNumber = req.params.number;
    const products = suggestedProducts[orderNumber];

    if (!products) {
      return res(
        ctx.status(404),
        ctx.json({
          errors: {
            messages: ["Product unavailable"],
          },
        }),
      );
    }

    return res(ctx.json(products));
  }),
};
