import React from "react";
import T from "prop-types";
import { useSelector } from "react-redux";

import { useTranslation, usePaymentLayout, useBreakPoints, useIsRequestPayment } from "@tint_fe/hooks";

import BookingNav from "../components/BookingNav/BookingNav";
import LangsSelect from "../components/LangsSelect";
import CurrencySelect from "../components/CurrencySelect";
import { selectIfCustomPrice } from "../../redux/sharedSelectors";

const CommonLayout = ({ children }) => {
  const { isRtl } = useTranslation();
  const isPayment = usePaymentLayout();
  const { isMd } = useBreakPoints();
  const isRequestPayment = useIsRequestPayment();
  const isCustomPrice = useSelector(selectIfCustomPrice);
  const hideCurrecnySelect = isRequestPayment || isCustomPrice;

  return (
    <>
      <div dir={isRtl ? "rtl" : "auto"} className="nav-wrapper row">
        <BookingNav />
        <div className="row col-md-4 nav-wrapper__justify-end">
          {!isPayment && !isMd ? (
            <>
              {!hideCurrecnySelect && (
                <>
                  <CurrencySelect className="mg-r-4" />
                  <div className="nav-wrapper__separator" />
                </>
              )}
              <LangsSelect className="mg-l-4" />
            </>
          ) : null}
        </div>
      </div>
      {children}
    </>
  );
};

CommonLayout.propTypes = {
  children: T.node.isRequired,
};

export default CommonLayout;
