import * as React from "react";

function SvgCalendar(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3C9 2.44772 8.55228 2 8 2C7.44772 2 7 2.44772 7 3V4H5C3.45713 4 2 5.13618 2 6.77778V11V19.2222C2 20.8638 3.45713 22 5 22H19C20.5429 22 22 20.8638 22 19.2222V11V6.77778C22 5.13618 20.5429 4 19 4H17V3ZM20 10V6.77778C20 6.4557 19.6663 6 19 6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6H5C4.33373 6 4 6.4557 4 6.77778V10H20ZM4 12H20V19.2222C20 19.5443 19.6663 20 19 20H5C4.33373 20 4 19.5443 4 19.2222V12Z"
        fill="#8F8F90"
      />
    </svg>
  );
}

export default SvgCalendar;
