import React from "react";
import T from "prop-types";
import GButton from "@google-pay/button-react";
import { useTranslation } from "@tint_fe/hooks";

const GPayButton = ({ totalPrice, merchantId, onLoadPaymentData, disabled, chargeCurrency }) => {
  const { i18n } = useTranslation();

  const disabledStyle = {
    pointerEvents: "none",
    opacity: "0.6",
  };

  return (
    <GButton
      style={disabled ? disabledStyle : {}}
      buttonType="plain"
      buttonLocale={i18n.language}
      environment={process.env.REACT_APP_GOOGLE_PAY_ENV || "TEST"}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: ["AMEX", "MASTERCARD", "VISA"],
              assuranceDetailsRequired: true,
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "credorax",
                gatewayMerchantId: String(merchantId),
              },
            },
          },
        ],
        merchantInfo: {
          merchantName: "Tint Tourism Technology LTD",
          merchantId: "BCR2DN4TWCB4L22I",
        },
        transactionInfo: {
          countryCode: "US",
          currencyCode: chargeCurrency ?? "USD",
          totalPriceStatus: "FINAL",
          totalPrice: totalPrice,
        },
      }}
      onLoadPaymentData={onLoadPaymentData}
    />
  );
};

GPayButton.propTypes = {
  totalPrice: T.oneOfType([T.string, T.number]),
  merchantId: T.string,
  onLoadPaymentData: T.func,
  disabled: T.bool,
  chargeCurrency: T.string,
};

export default GPayButton;
