import React, { useEffect, useRef } from "react";
import T from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { useTranslation, useBreakPoints } from "@tint_fe/hooks";
import { MIN_ADULTS, MAX_ROOM_PAX, MAX_KIDS_AGE } from "@tint_fe/helpers";
import { CountingField } from "..";
import SelectChildAge from "./SelectChildAge";

const RoomAvailability = ({
  adults,
  kids,
  onChange,
  scrollIntoView,
  childrenNotAvailable,
  paxOverflow,
  minChildAge,
  maxChildAge,
}) => {
  const { isSm } = useBreakPoints();
  const { t } = useTranslation();
  const ref = useRef();
  const maxAdults = MAX_ROOM_PAX - kids.length > 3 ? 3 : MAX_ROOM_PAX - kids.length;
  const maxChildren = adults >= 3 ? 2 : 3;

  useEffect(() => {
    scrollIntoView && isSm && ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [isSm, scrollIntoView]);

  const selectChildAgeLabel = (i) => {
    switch (i) {
      case 1:
        return t("room.children.first");
      case 2:
        return t("room.children.second");
      case 3:
        return t("room.children.third");
      case 4:
        return t("room.children.fourth");
      default:
        return t("room.childNumber", { number: i });
    }
  };

  return (
    <div className="room__content">
      <CountingField
        className="mg-b-3 mg-t-3 price-band"
        label={t("adults")}
        type="range"
        max={maxAdults}
        incrementDisabled={!maxAdults || paxOverflow}
        min={MIN_ADULTS}
        value={adults}
        onChange={(val) => onChange({ adults: val })}
      />
      {!childrenNotAvailable && (
        <>
          <CountingField
            label={maxChildAge === MAX_KIDS_AGE ? t("room.children") : t("room.childrenUnder", { age: maxChildAge })}
            type="range"
            className={"price-band"}
            max={maxChildren}
            incrementDisabled={!maxChildren || paxOverflow}
            value={kids.length}
            onChange={(_, isIncrement) => {
              return onChange({
                children: isIncrement ? [...kids, 0] : kids.slice(0, kids.length - 1),
              });
            }}
          />
          {kids.length > 0 &&
            kids?.map((el, index) => (
              <SelectChildAge
                key={uuidv4()}
                className="mg-t-2"
                label={selectChildAgeLabel(index + 1)}
                value={Number(el)}
                onChange={(val) => {
                  kids[index] = val;

                  onChange({ children: [...kids] });
                }}
                minChildAge={minChildAge}
                maxChildAge={maxChildAge}
              />
            ))}
        </>
      )}
    </div>
  );
};

RoomAvailability.defaultProps = {
  minChildAge: 0,
  maxChildAge: MAX_KIDS_AGE,
};

RoomAvailability.propTypes = {
  adults: T.number.isRequired,
  kids: T.array.isRequired,
  onChange: T.func.isRequired,
  scrollIntoView: T.bool.isRequired,
  childrenNotAvailable: T.bool.isRequired,
  paxOverflow: T.bool.isRequired,
  minChildAge: T.number,
  maxChildAge: T.number,
};

export default RoomAvailability;
