import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { Info } from "../../assets/icons";

const InfoMsg = ({ children, className, bold, icon: Icon, centered }) => {
  const { mainCls, elCls } = parseClasses({
    base: "info-msg",
    classes: [centered && "align-center"],
    additional: className,
  });

  return (
    <div className={mainCls}>
      <span className={elCls("icon")}>{<Icon />}</span>
      <span className={`p4 ${bold ? "bold" : ""}`}>{children}</span>
    </div>
  );
};

InfoMsg.defaultProps = {
  icon: Info,
  bold: true,
  centered: false,
};

InfoMsg.propTypes = {
  children: T.node.isRequired,
  className: T.string,
  icon: T.func,
  bold: T.bool,
  centered: T.bool,
};

export default InfoMsg;
