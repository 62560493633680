import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import GoogleMap from "google-map-react";

import { parseClasses } from "@tint_fe/helpers";
import { Address } from "..";
import MapMarker from "./MapMarker";

const Map = ({ address, className, locationObj, hotelName, isMapOpen }) => {
  const [open, setOpen] = useState(isMapOpen);
  const { mainCls, elCls } = parseClasses({ base: "map", additional: className });
  const ref = useRef();

  const { mainCls: mapClasses } = parseClasses({
    base: elCls("container"),
    classes: [open && "open"],
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => ref.current?.scrollIntoView({ block: "start", behavior: "smooth" }), 100);
    }
  }, [open]);

  return (
    <div ref={ref} className={mainCls}>
      {address && (
        <Address
          address={address}
          open={open}
          {...(locationObj?.lat && locationObj?.lng && { onClick: () => setOpen(!open) })}
        />
      )}
      {locationObj?.lat && locationObj?.lng ? (
        <div className={mapClasses}>
          <GoogleMap
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
            defaultCenter={{
              ...locationObj,
            }}
            defaultZoom={13}
          >
            <MapMarker name={hotelName} {...locationObj} />
          </GoogleMap>
        </div>
      ) : null}
    </div>
  );
};

Map.propTypes = {
  address: T.string,
  className: T.string,
  locationObj: T.object,
  hotelName: T.string,
  isMapOpen: T.bool,
};

export default Map;
