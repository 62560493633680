import React from "react";
import T from "prop-types";
import { Controller } from "react-hook-form";

import Password from "./Password";

const ControlledPassword = ({ control, defaultValue, label, name, validate, ignoreValidation }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, ...restField }, fieldState: { error } }) => {
        const handleChange = (val) => {
          return val.target.value.replace(/\s/g, "");
        };

        return <Password label={label} onChange={(e) => onChange(handleChange(e))} error={error} {...restField} />;
      }}
      rules={{
        required: {
          value: true,
          message: { text: "Password is required" },
        },
        validate: (val) => {
          if (ignoreValidation) {
            return;
          }

          if (val.length < 6) {
            return "Minimum length for password is 6 characters";
          }
          if (!/\d/.test(val)) {
            return "Password must contain at least one number";
          }

          if (!/[A-Z]/.test(val)) {
            return "Password must contain at least one uppercase letter";
          }
          if (!/[a-z]/.test(val)) {
            return "Password must contain at least one lowercase letter";
          }

          if (typeof validate === "function") {
            return validate(val);
          }
        },
      }}
    />
  );
};

ControlledPassword.defaultProps = {
  label: "Password",
  name: "password",
  ignoreValidation: false,
};

ControlledPassword.propTypes = {
  control: T.object.isRequired,
  label: T.string,
  name: T.string,
  validate: T.func,
  ignoreValidation: T.bool,
  defaultValue: T.string,
};

export default ControlledPassword;
