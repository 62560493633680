import * as React from "react";

function SvgPlus(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 6.75L3.6 2.25H20.4L22.5 6.75V9V21C22.5 21.8284 21.8284 22.5 21 22.5H3C2.17157 22.5 1.5 21.8284 1.5 21V9V6.75ZM5.25 9V12C5.25 15.7279 8.27208 18.75 12 18.75C15.7279 18.75 18.75 15.7279 18.75 12V9H17.25V12C17.25 14.8995 14.8995 17.25 12 17.25C9.10051 17.25 6.75 14.8995 6.75 12V9H5.25ZM4.38462 3.75H19.6154L21 6.75H3L4.38462 3.75Z"
        fill="#151209"
      />
    </svg>
  );
}

export default SvgPlus;
