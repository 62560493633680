import React from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";

const SummaryToursItems = ({ tours, classNameItem }) => {
  const { t } = useTranslation();

  return (
    tours.length > 0 && (
      <>
        <div className="bold mg-t-2 mg-b-1">{t("summary.toursIncluded")}</div>

        {tours.map((el) => (
          <div className={classNameItem} key={el.id}>
            {el.name}
          </div>
        ))}
      </>
    )
  );
};

SummaryToursItems.defaultProps = {
  tours: [],
};

SummaryToursItems.propTypes = {
  tours: T.array,
  classNameItem: T.string,
};

export default SummaryToursItems;
