import React, { useState, useRef, useEffect } from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useWindowSize, useTranslation } from "@tint_fe/hooks";
import { ArrowRight } from "../../assets/icons";

const Collapse = ({
  children,
  className,
  header,
  headerSecondaryInfo,
  icon,
  initialHeight,
  open,
  withAnimation,
  ...rest
}) => {
  const { isRtl } = useTranslation();
  const bodyRef = useRef();
  const headerRef = useRef();
  const { width } = useWindowSize();

  const [openMinHeight, setOpenMinHeight] = useState(initialHeight);
  const [openHeight, setOpenHeight] = useState(initialHeight);
  const { mainCls, elCls } = parseClasses({
    base: "collapse",
    classes: [open ? "open" : "close", isRtl && "rtl", withAnimation && "with-animation"],
    additional: className,
  });

  useEffect(() => {
    if (bodyRef.current && headerRef.current) {
      const bodyHeight = bodyRef.current?.getBoundingClientRect().height;
      const headerHeight = headerRef.current?.getBoundingClientRect().height;
      const collapseHeight = open ? bodyHeight + headerHeight : headerHeight;

      setOpenMinHeight(collapseHeight);
      setOpenHeight(collapseHeight);
    }
  }, [open, width, children]);

  return (
    <div
      style={{
        height: open ? openHeight : initialHeight,
        minHeight: open ? openMinHeight : initialHeight,
      }}
      data-testid="test-collapse-wrapper"
      className={mainCls}
      {...rest}
    >
      <div
        data-testid="test-collapse-header"
        ref={headerRef}
        style={{ height: initialHeight }}
        className={elCls("header")}
      >
        {header}
        {headerSecondaryInfo && <div className={elCls("header-secondary")}>{headerSecondaryInfo}</div>}
        {icon}
      </div>
      <div ref={bodyRef} onClick={(e) => e.stopPropagation()} className={elCls("body")}>
        {children}
      </div>
    </div>
  );
};

Collapse.defaultProps = {
  icon: <ArrowRight className="collapse__icon mg-md-l-6" height={24} width={24} />,
  initialHeight: 36,
  withAnimation: true,
};

Collapse.propTypes = {
  children: T.oneOfType([T.node, T.arrayOf(T.node)]).isRequired,
  className: T.string,
  disabled: T.bool,
  header: T.oneOfType([T.node, T.string]).isRequired,
  headerSecondaryInfo: T.oneOfType([T.node, T.string]),
  icon: T.node,
  initialHeight: T.number,
  open: T.bool,
  withAnimation: T.bool,
};

export default Collapse;
