import React from "react";
import T from "prop-types";
import { components } from "react-select";

import { join } from "@tint_fe/helpers";

const SelectOption = ({ children, data, ...rest }) => {
  return (
    <components.Option {...rest} className={data.flag ? "select__option-flag" : ""}>
      {data.flag && <img src={data.flag} alt="Flag" className="mg-r-2" />}
      {data.region || data.country ? (
        <>
          <span className="main-text">{data.name || children} </span>
          <span className="additional-text">{join([data.region, data.country], ", ")}</span>
        </>
      ) : (
        <span dir="auto" {...(rest.selectProps.isRtl ? { style: { textAlign: "right" } } : {})}>
          {children}
        </span>
      )}
    </components.Option>
  );
};

SelectOption.propTypes = {
  children: T.node,
  data: T.object,
};

export default SelectOption;
