import React, { useEffect, useState } from "react";
import T from "prop-types";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { bookingTypes, parseClasses, QueryString } from "@tint_fe/helpers";
import { useBreakPoints, useIsLogged, useTranslation } from "@tint_fe/hooks";
import { Container, InfoMsg, PassportInfo, SuccessIcon } from "@tint_fe/ui";
import SharedSuccessSummary from "@tint_fe/ui/src/components/SuccessPage/SharedSuccessSummary";
import SuccessBookingSignUp from "../../components/SuccessBookingSignUp";
import StandaloneBookingExtras from "./StandaloneBookingExtras";
import SmartPackageItinerary from "../../components/SmartPackageItinerary/SmartPackageItinerary";
import ModalExtraBooking from "./ModalExtraBooking";
import EnhanceExperience from "../../components/EnhanceExperience";
import SelectedExtras from "./SelectedExtras";
import ModalPayment from "./ModalPayment";
import { useExtraAsExtra, useSelectedExtras } from "./hooks";
import { useGetOrderAvailableExtras, useGetOrderItinerary } from "../../../hooks";
import { hasSPExtras } from "./helpers";

// const convertExtrasToArray = (extras) => {
//   const arr = [];

//   for (const key in extras) {
//     if (isEmpty(extras[key])) continue;

//     const extrasWithPID = extras[key].map((extra) => ({ ...extra, product_item_id: key }));
//     arr.push(...extrasWithPID);
//   }

//   return arr;
// };

const SuccessPageWithExtras = ({
  updatePassportDetails,
  order,
  product,
  price,
  brandName,
  hideFooter,
  chargeCurrency,
}) => {
  const { t } = useTranslation();
  const { isSm } = useBreakPoints();
  const requestPaymentProcessing = useSelector((state) => state.order.requestPaymentProcessing);

  const search = QueryString.parse(document.location.search);
  const isPassportRequest = search.source === "passport_email";
  const isOrderNeedPassport = order?.state === "passport" || order?.need_passport;
  const isPassportRequestButAlreadyFilled = isPassportRequest && !isOrderNeedPassport;
  const bookingType = order?.build_type;
  const isSP = bookingType === bookingTypes.SMART_PACKAGE;
  const isLoggedIn = useIsLogged();
  const showSignUp = !!document.querySelector(".authorization-widget") && !isLoggedIn;

  // Extras
  const { res: availableExtras, loading: availableExtrasLoading } = useGetOrderAvailableExtras({
    orderId: order?.number,
    ignore: isSP,
  });
  const { res: spOrderItinerary, loading: spOrderItineraryLoading } = useGetOrderItinerary({
    orderId: order?.number,
    ignore: !isSP,
  });
  const hasHotelsAsExtra = availableExtras?.order?.extra_hotels?.length > 0;
  const hasToursAsExtra = availableExtras?.order?.extra_tours?.length > 0;
  const spExtrasPresent = isSP ? hasSPExtras(spOrderItinerary) : false;
  const hasExtrasAsExtra = isSP ? !isEmpty(availableExtras?.order?.extras ?? {}) : false;
  const hasAnyExtra = hasHotelsAsExtra || hasToursAsExtra || hasExtrasAsExtra || spExtrasPresent;
  const {
    selectedExtras,
    setSelectedExtras,
    paidAndSelectedExtras,
    selectedProductExtras,
    setSelectedProductExtras,
    paidAndSelectedProductExtras,
  } = useSelectedExtras(order);

  // Modal
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [bookingProductDetails, setBookingProductDetails] = useState({});

  const [showPassportFields, setShowPassportFields] = useState(isPassportRequest);

  const { mainCls, elCls } = parseClasses({
    base: "success-page",
  });

  const handleShowPassport = (val) => {
    if (!val) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    setShowPassportFields(val);
  };

  const { patcher: patchExtras } = useExtraAsExtra();

  const onSelectExtra = ({ type, ...rest }) => {
    setBookingProductDetails({
      ...rest,
      type,
    });
    setIsBookingModalOpen(true);
  };

  const onDeleteExtra = ({ product_item_id, product_extra_id, quantity = 0 }) => {
    patchExtras({
      orderNumber: order.number,
      product_item_id,
      body: { product_extra_id, quantity },
    });
  };

  const onPaymentPress = () => {
    setIsPaymentModalOpen(true);
  };

  useEffect(() => {
    if (!requestPaymentProcessing) {
      setTimeout(() => {
        setIsBookingModalOpen(false);
        setIsPaymentModalOpen(false);
      });
    }
  }, [requestPaymentProcessing]);

  return (
    <>
      <div className={mainCls}>
        <div className={elCls("content-row")}>
          <div className={elCls("content")}>
            <div className={elCls("title-row")}>
              {!isSm && <SuccessIcon height={80} width={80} />}
              <h3 className={elCls("title", "h3", "second-font")}>
                {t("successPage.title", { brand_name: brandName })}
              </h3>
            </div>
            <p className={elCls("name", "p5", "mg-0", "mg-b-1")}>{t("successPage.text")}</p>

            {/* [3779] Use new layout for hotel success (+companions) */}
            <SharedSuccessSummary order={order} price={price}>
              <>
                {product?.meeting_point && (
                  <InfoMsg bold={false} centered className="mg-t-4 col col-lg-8">
                    {product.meeting_point}
                  </InfoMsg>
                )}
                {isOrderNeedPassport && (
                  <>
                    <div className={elCls("left-passport")}>
                      <p className={elCls("left-passport-attention")}>
                        <span>!</span>&nbsp; {t("order.justOneMoreThing")}
                      </p>
                      <p className={elCls("left-passport-info")}>{t("order.enterPassportsDetailsArrangements")}</p>
                      {isOrderNeedPassport && !showPassportFields && (
                        <button
                          className={elCls("left-passport-button", "p4")}
                          onClick={() => setShowPassportFields((prev) => !prev)}
                        >
                          {t("order.enterPassportsDetails")}&gt;
                        </button>
                      )}
                    </div>
                    {showPassportFields && (
                      <PassportInfo
                        scrollIntoView={isPassportRequest}
                        updatePassportDetails={updatePassportDetails}
                        setShowPassportInfo={handleShowPassport}
                        order={order}
                      />
                    )}
                    <SelectedExtras
                      orderNumber={order?.number}
                      selectedExtras={selectedExtras}
                      setSelectedExtras={setSelectedExtras}
                      selectedProductExtras={selectedProductExtras}
                      setSelectedProductExtras={setSelectedProductExtras}
                      chargeCurrency={chargeCurrency}
                      onDeleteExtra={onDeleteExtra}
                      onPaymentPress={onPaymentPress}
                    />
                  </>
                )}
                {isPassportRequestButAlreadyFilled && (
                  <div className={elCls("left-passport-provided")}>
                    <p className={elCls("left-passport-provided-attention")}>
                      <span>!</span>
                    </p>
                    <p className={elCls("left-passport-provided-attention")}>{t("order.passportAlreadyProvided")}</p>
                  </div>
                )}
              </>
            </SharedSuccessSummary>
          </div>
          {showSignUp && <SuccessBookingSignUp />}
        </div>

        {hasAnyExtra && <EnhanceExperience className={mainCls} />}

        {/* Extras */}
        {isSP ? (
          <SmartPackageItinerary
            loading={spOrderItineraryLoading}
            orderNumber={order?.number}
            chargeCurrency={chargeCurrency}
            packageStartDate={order?.package_start_date}
            itineraryData={spOrderItinerary?.order?.smart_package_itinerary}
            onSelectExtra={onSelectExtra}
            selectedIds={paidAndSelectedExtras.map((extra) => extra.product_option_id)}
            numberOfPeople={order?.pax_quantity}
          />
        ) : (
          <StandaloneBookingExtras
            className={mainCls}
            loading={availableExtrasLoading}
            chargeCurrency={chargeCurrency}
            hasHotelsAsExtra={hasHotelsAsExtra}
            hasToursAsExtra={hasToursAsExtra}
            hasExtrasAsExtra={hasExtrasAsExtra}
            hotels={availableExtras?.order?.extra_hotels ?? []}
            tours={availableExtras?.order?.extra_tours ?? []}
            onSelectExtra={onSelectExtra}
            selectedIds={paidAndSelectedProductExtras.map((extra) => extra.product_id)}
          />
        )}
      </div>

      <ModalExtraBooking
        isOpen={isBookingModalOpen}
        closeModal={() => setIsBookingModalOpen(false)}
        product={bookingProductDetails}
        chargeCurrency={chargeCurrency}
        orderNumber={order?.number}
        order={order}
        selectedExtras={selectedExtras}
        selectedProductExtras={selectedProductExtras}
      />

      <ModalPayment
        isOpen={isPaymentModalOpen}
        closeModal={() => setIsPaymentModalOpen(false)}
        selectedExtras={selectedExtras}
        selectedProductExtras={selectedProductExtras}
        chargeCurrency={chargeCurrency}
      />

      {!hideFooter && (
        <footer className={elCls("footer")}>
          <Container>
            <p className="mg-0 p3">&#169; {`${new Date().getFullYear()} ${brandName}`}</p>
          </Container>
        </footer>
      )}
    </>
  );
};

SuccessPageWithExtras.propTypes = {
  updatePassportDetails: T.func,
  order: T.object,
  price: T.object,
  product: T.object,
  brandName: T.string,
  hideFooter: T.bool,
  chargeCurrency: T.string,
};

export default SuccessPageWithExtras;
