import * as React from "react";
const UserCheck20 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        stroke="#21AFE4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.335 4.167 15 5.833 18.335 2.5m0 7.5v4.333c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.093c-.535.272-1.235.272-2.635.272H5.668c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.667c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.535-.272 1.235-.272 2.635-.272h4.333M1.79 16.605a3.335 3.335 0 0 1 3.212-2.438h5.833c.775 0 1.162 0 1.484.064a3.333 3.333 0 0 1 2.62 2.619c.063.322.063.709.063 1.483M11.668 7.917a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default UserCheck20;
