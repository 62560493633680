import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { SharedSuccessPage } from "@tint_fe/ui";
import { QueryString, handleABTestingFeatures } from "@tint_fe/helpers";

import SuccessPageWithExtras from "./SuccessPageWithExtras";

import { steps } from "../../../helpers/constants";
import { useReduxAction } from "../../../hooks";
import { updatePassportDetailsRequest } from "../../../redux/order/orderActions";
import { selectOrder } from "../../../redux/sharedSelectors";
import { ga_purchase } from "@tint_fe/helpers/services/google-analytics-v2";

const SuccessPage = () => {
  const updatePassportDetails = useReduxAction(updatePassportDetailsRequest);
  const [gaEventSent, setGaEventSent] = useState(false);

  const sendedRef = useRef(false);
  const { postExtras: ABTestPostExtras } = handleABTestingFeatures();

  const order = useSelector(selectOrder);
  const product = useSelector((state) => state.product.data);
  const spProduct = useSelector((state) => state.smartPackage.data);

  const display_name = useSelector((state) => state.smartPackage.data.display_name || state.product.data.display_name);
  const price = useSelector(({ global }) => global.price);
  const brandName = useSelector((state) => state.global.integration.brand_name);
  const chargeCurrency = useSelector((state) => state.global.integration.charge_currency);

  useEffect(() => {
    if (
      (product.hasOwnProperty("id") || spProduct.hasOwnProperty("id")) &&
      !isEmpty(order) &&
      price.hasOwnProperty("tint_charge_price") &&
      !gaEventSent
    ) {
      const isSp = spProduct.hasOwnProperty("id");
      ga_purchase(isSp ? spProduct : product, order, price.tint_charge_price, price.charge_currency);
      setGaEventSent(true);
    }
  }, [order, product, spProduct, price]);

  useEffect(() => {
    const { source } = QueryString.parse(document.location.search);

    if (display_name && source !== "passport_email" && !sendedRef.current) {
      sendedRef.current = true;
    }
  }, [display_name]);

  useEffect(() => {
    if (order.state === steps.COMPLETED) {
      setTimeout(() => window.scrollTo(0, 0), 50);
    }
  }, [order]);

  if (ABTestPostExtras) {
    return (
      <SuccessPageWithExtras
        updatePassportDetails={updatePassportDetails}
        order={order}
        product={product}
        brandName={brandName}
        price={price}
        chargeCurrency={chargeCurrency}
        hideFooter
      />
    );
  }

  return (
    <SharedSuccessPage
      updatePassportDetails={updatePassportDetails}
      order={order}
      product={product}
      brandName={brandName}
      price={price}
      hideFooter
    />
  );
};

export default SuccessPage;
