import { QueryString } from "@tint_fe/helpers";
import { useLocation } from "react-router-dom";

const useIsRequestPayment = (custom_string = "") => {
  const search = custom_string || document.location.search;
  const { search: hashSearch } = useLocation();

  if (search || hashSearch) {
    const searchQuery = QueryString.parse(search);
    const { source: searchSource = "" } = searchQuery;

    const hashQuery = QueryString.parse(hashSearch);
    const { source: hashSource = "" } = hashQuery;

    return [searchSource, hashSource].includes("request_payment");
  }

  return false;
};

export default useIsRequestPayment;
