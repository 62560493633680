import React from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { dates, validationRule } from "@tint_fe/helpers";

import { selectAvailableLangs, selectAvailableCities } from "../../../../../redux/product/productSelectors";
import HiddenField from "../../../../components/Form/HiddenField";
import SelectField from "../../../../components/Form/SelectField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation, useReduxAction } from "../../../../../hooks";
import { TOUR_DETAILS_FORM, PACKAGE_FORMAT_DATE } from "../../../../../helpers/constants";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";
import useUpdatePriceBands from "../../hooks/useUpdatePriceBands";
import useBackToCheckoutStep from "../../hooks/useBackToCheckoutStep";
import useHandleChangeTourAvailability from "../../hooks/useHandleChangeTourAvailability";
import { getTourAvailabilityAction } from "../../../../../redux/product/productActions";

const SelectReservationsLang = () => {
  const { control, watch, reset, setValue, getValues } = useFormContext();

  const updateTourPrice = useUpdateTourPrice();
  const updatePriceBands = useUpdatePriceBands();
  const backToCheckoutStep = useBackToCheckoutStep();
  const handleChangeTourAvailability = useHandleChangeTourAvailability({ setValue, getValues });
  const getTourAvailability = useReduxAction(getTourAvailabilityAction);

  const availableLanguages = useSelector(selectAvailableLangs);
  const availableCities = useSelector(selectAvailableCities);
  const loading = useSelector((state) => state.product.priceBandsFetching);

  const { t } = useTranslation();
  const startDateValue = watch("start_date");

  const handleChange = (val) => {
    const isOneCityAvailable = availableCities.length === 1;
    const data = isOneCityAvailable ? { ...val, city_id: availableCities[0].id } : val;

    updateTourPrice({ formData: data }, { preventPriceBands: true });
    isOneCityAvailable && updatePriceBands(data);

    getTourAvailability({
      date: dates.format(startDateValue, PACKAGE_FORMAT_DATE, false),
      langCode: val.language_code,
      onSuccess: handleChangeTourAvailability,
    });

    reset({
      ...TOUR_DETAILS_FORM,
      start_date: startDateValue,
      language_code: val.value,
      ...(isOneCityAvailable && { city_id: availableCities[0].id }),
    });

    backToCheckoutStep();

    return val;
  };

  const options = availableLanguages.map((x) => ({ value: x.code, label: x.name }));

  return (
    <ControllerInput
      control={control}
      name="language_code"
      render={({ field: { onChange, value, ...rest }, fieldState: { error }, formState: { isSubmitted } }) => {
        const isSelect =
          availableLanguages.length > 1 || (availableLanguages.length === 1 && availableLanguages[0].code !== "en");

        return isSelect ? (
          <SelectField
            label={t("formField.language")}
            placeholder={t("formField.selectLanguage")}
            disabled={availableLanguages.length === 1 || !startDateValue}
            options={options}
            errors={error?.message}
            isSubmitting={isSubmitted}
            onChange={(val) => onChange(handleChange(val))}
            loading={loading}
            value={value ?? options[0].value}
            {...rest}
          />
        ) : (
          <HiddenField value={value ?? options[0].value} {...rest} />
        );
      }}
      rules={{ ...validationRule.required("formField.language") }}
    />
  );
};

export default SelectReservationsLang;
