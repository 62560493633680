import * as React from "react";

function SvgInfo3(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <circle cx={12} cy={12} r={11} stroke="#0C3164" strokeWidth={2} />
      <path
        d="M13 7.25c0 1.667-2.5 1.667-2.5 0s2.5-1.667 2.5 0zM11.333 10H13v6h1v2h-4v-2h1v-4h-1v-2h1.333z"
        fill="#0C3164"
      />
    </svg>
  );
}

export default SvgInfo3;
