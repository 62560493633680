import * as React from "react";

function SvgFeedbackRatingStar(props) {
  return (
    <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.6505 14.7016L21.5 2.8541L25.3495 14.7016C25.6842 15.7317 26.6441 16.4291 27.7271 16.4291L40.1844 16.4291L30.1063 23.7513C29.23 24.3879 28.8634 25.5163 29.1981 26.5463L33.0476 38.3939L22.9695 31.0717C22.0932 30.4351 20.9068 30.4351 20.0305 31.0717L9.95243 38.3939L13.8019 26.5463C14.1366 25.5163 13.77 24.3879 12.8937 23.7513L2.81564 16.4291L15.2729 16.4291C16.3559 16.4291 17.3158 15.7317 17.6505 14.7016Z"
        stroke="#F5854D"
        strokeWidth="3"
      />
    </svg>
  );
}

export default SvgFeedbackRatingStar;
