import React from "react";
import { toast, Slide } from "react-toastify";

import {
  CloseSemi,
  Checked,
  ErrorIcon,
  WarnIcon,
  Info3,
  Changed,
  IceCreamBrokenSvg,
  IceCreamMeltsSvg,
  SupportIcon,
} from "../../assets/icons";
import AvailabilityNotificationUI from "./AvailabilityNotificationUI";
import CustomNotificationUI from "./CustomNotificationUI";
import SplitPaymentNotificationUI from "./SplitPaymentNotificationUI";

const defaultOptions = {
  autoClose: 3000,
  hideProgressBar: false,
  position: toast.POSITION.TOP_CENTER,
  pauseOnHover: true,
  closeButton: () => <CloseSemi className="notification__close-btn" />,
  bodyClassName: "notification__body",
  progressClassName: "notification__progress",
  transition: Slide,
};

export const notification = {
  _addClassName(type, ...rest) {
    return ["notification", type && `notification__${type}`, ...rest].filter(Boolean).join(" ");
  },

  success(params) {
    const { text, options } = params || {};
    const toastId = "success";

    return toast.success(text, {
      ...defaultOptions,
      icon: <Checked width={24} height={24} />,
      className: this._addClassName("success"),
      toastId,
      ...options,
    });
  },

  info(params) {
    const { text, options } = params || {};
    const toastId = "info";

    return toast.info(text, {
      ...defaultOptions,
      icon: <Info3 width={24} height={24} />,
      className: this._addClassName("info"),
      toastId,
      ...options,
    });
  },

  warn(params) {
    const { text, options } = params || {};
    const toastId = "warn";

    return toast.warn(text, {
      ...defaultOptions,
      icon: <WarnIcon width={24} height={24} />,
      className: this._addClassName("warn"),
      toastId,
      ...options,
    });
  },

  error(params) {
    const { text, options } = params || {};
    const toastId = "error";

    return toast.error(text, {
      ...defaultOptions,
      icon: <ErrorIcon width={24} height={24} />,
      className: this._addClassName("error"),
      toastId,
      ...options,
    });
  },

  infoBig(params) {
    const { options, props } = params || {};
    const toastId = "infoBig";

    if (toast.isActive(toastId)) {
      return;
    }

    return toast(<CustomNotificationUI {...props} />, {
      ...defaultOptions,
      icon: <Checked width={48} height={48} />,
      className: this._addClassName("info", "big"),
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
      closeButton: () => null,
      closeOnClick: false,
      toastId,
      ...options,
    });
  },

  successBig(params) {
    const { options, props } = params || {};
    const toastId = "successBig";

    return toast(<CustomNotificationUI {...props} />, {
      ...defaultOptions,
      icon: <Changed width={48} height={48} />,
      className: this._addClassName("success", "big"),
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
      closeButton: () => null,
      closeOnClick: false,
      toastId,
      ...options,
    });
  },

  neutralBig(params) {
    const { options, props } = params || {};
    const toastId = "neutralBig";

    return toast(<CustomNotificationUI {...props} />, {
      ...defaultOptions,
      icon: <IceCreamMeltsSvg width={48} height={48} />,
      className: this._addClassName("neutral", "big"),
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
      closeButton: () => null,
      closeOnClick: false,
      toastId,
      ...options,
    });
  },

  errorBig(params) {
    const { options, props } = params || {};
    const toastId = "errorBig";

    return toast(<CustomNotificationUI isCritical title="error.title" {...props} />, {
      ...defaultOptions,
      icon: <IceCreamBrokenSvg width={48} height={48} />,
      className: this._addClassName("error", "big", "vertically-centered"),
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
      closeButton: () => <CloseSemi className="notification__close-btn" />,
      closeOnClick: true,
      draggable: false,
      toastId,
      ...options,
    });
  },

  availabilityBig(params) {
    const { options, props } = params || {};
    const toastId = "availabilityBig";

    return toast(<AvailabilityNotificationUI {...props} />, {
      ...defaultOptions,
      icon: <SupportIcon width={48} height={48} />,
      className: this._addClassName("availability", "big"),
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
      closeButton: () => null,
      closeOnClick: false,
      draggable: false,
      toastId,
      ...options,
    });
  },

  splitPaymentBig(params) {
    const { options, props } = params || {};
    const toastId = "splitPaymentBig";

    return toast(<SplitPaymentNotificationUI isCritical {...props} />, {
      ...defaultOptions,
      icon: <IceCreamBrokenSvg />,
      className: this._addClassName("error", "big", "vertically-centered", "split-payment"),
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
      closeButton: () => (
        <div onClick={() => toast.dismiss()}>
          <CloseSemi className="notification__close-btn" />
        </div>
      ),
      closeOnClick: false,
      draggable: false,
      toastId,
      ...options,
    });
  },
};
