import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { transformArrayOfObjectsToOptionsWithTwoLocations, validationRule } from "../../../../../utils";
import SelectField from "../../../../components/Form/SelectField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation } from "../../../../../hooks";
import HiddenField from "../../../../components/Form/HiddenField";

const PickupPoint = () => {
  const pickupPoints = useSelector(({ product }) => product.data.pickup_points) || [];
  const product = useSelector((state) => state.product);
  const loading = product?.scheduleGroupsFetching;

  const {
    control,
    formState: { isSubmitted },
    setValue,
  } = useFormContext();

  const { t } = useTranslation();

  const pickupPointOptions = transformArrayOfObjectsToOptionsWithTwoLocations(pickupPoints, "id", "name");

  const handleChange = (val) => {
    return val;
  };

  useEffect(() => {
    if (pickupPointOptions.length === 1) {
      const option = pickupPointOptions[0];
      setTimeout(() => {
        setValue("pickup_point_id", option.value);
      }, 300);
    }
  }, []);

  return (
    <ControllerInput
      control={control}
      name="pickup_point_id"
      render={({ field: { onChange, ...restField }, fieldState: { error } }) => {
        if (pickupPointOptions.length > 1) {
          return (
            <SelectField
              label={t("formField.startFrom")}
              placeholder={t("formField.selectPoint")}
              disabled={false}
              isPreselected={false}
              options={pickupPointOptions}
              errors={error?.message}
              isSearchable={false}
              isSubmitting={isSubmitted}
              noOptionsMessage={t("formField.startFrom.noOptionsMessage")}
              onChange={(val) => onChange(handleChange(val))}
              loading={loading}
              {...restField}
            />
          );
        }

        return <HiddenField value={pickupPointOptions[0]?.value} {...restField} />;
      }}
      rules={{
        ...validationRule.required("Select pickup point"),
      }}
    />
  );
};

export default PickupPoint;
