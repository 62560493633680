import lodash from "lodash";

import { useEffect, useState } from "react";
import { QueryString } from "@tint_fe/helpers";

import { setUuidToSessionStorage } from "../storage";
import { updateUrl } from "../utils";

const useParseSearchParams = () => {
  const [params, setParams] = useState({});

  /* Get params from search query. */
  const parseSearchParams = () => {
    const { search, pathname } = window.location;

    const { x, fail_payment, assumptions, ...rest } = QueryString.parse(search);
    let query = lodash.cloneDeep(rest);

    /* Encrypt x value to search params and replace it (for compatibility with the previous version). */
    if (typeof x === "string") {
      query = { ...rest, ...QueryString.decode(x) };
    }

    /* Set order auth token from incomplete email url to local storage */
    if (query.uuid) {
      setUuidToSessionStorage(query.uuid);
    }

    /* Payment canceled page (remove all params) */
    if (query.payment_cancelled && query.order) {
      query = { order: query.order };
    }

    updateUrl(`${pathname}?${QueryString.stringify(assumptions ? { ...query, assumptions } : query)}`, "replace");

    return { ...query, fail_payment };
  };

  useEffect(() => {
    setParams(parseSearchParams());
  }, []);

  return params;
};

export default useParseSearchParams;
