import React, { useEffect, useState } from "react";
import T from "prop-types";

import { parseClasses, toUpFirstLater, validationRule } from "@tint_fe/helpers";
import { useTranslation, useUser } from "@tint_fe/hooks";
import { PolicyCheckbox, ControlledInput } from "../../components";
import CountriesSelect from "./CountriesSelect";
import InvoiceRecipientSelect from "./InvoiceRecipientSelect";
import ControlledPhoneInput from "./ControlledPhoneInput";
import Notes from "./Notes";

const CustomerForm = ({ control, countriesSelectNextIcon, termsAndConditionLink, revalidateErrors, isExpedia }) => {
  const { t, i18n } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "customer-form", additional: ["mg-b-6"] });
  const { user } = useUser();
  const { isAgent } = user || {};
  const [formMounted, setFormMounted] = useState(false);
  const [isPhoneCountryCodeValid, setIsPhoneCountryCodeValid] = useState(true);

  useEffect(() => {
    if (formMounted) {
      revalidateErrors();
    } else {
      setFormMounted(true);
    }
  }, [i18n.language]);

  const getLabel = (label) => (isAgent ? `${toUpFirstLater(t("traveler"))} ${label}` : label);

  return (
    <div className={mainCls}>
      <div className={elCls("row")}>
        <ControlledInput
          control={control}
          name="first_name"
          label={getLabel(`${t("formField.firstName")}*`)}
          removeStartEndSpaces
          rules={{ ...validationRule.required(t("formField.firstName")) }}
        />
        <ControlledInput
          control={control}
          name="last_name"
          label={getLabel(`${t("formField.lastName")}*`)}
          classNameWrapper={elCls("second-item-mg-md-l-2")}
          removeStartEndSpaces
          rules={{ ...validationRule.required(t("formField.lastName")) }}
        />
      </div>
      <div className={elCls("row")}>
        <ControlledInput
          control={control}
          name="email"
          label={getLabel(`${t("formField.emailAddress")}*`)}
          rules={{
            ...validationRule.required(t("formField.emailAddress")),
            ...validationRule.email,
          }}
        />
      </div>
      <div className={elCls("row")}>
        <ControlledPhoneInput
          control={control}
          type="phone"
          name="phone"
          label={getLabel(`${t("formField.phoneNumber")}*`)}
          countryCodeSetter={setIsPhoneCountryCodeValid}
          rules={{
            ...validationRule.required(t("formField.phoneNumber")),
            ...validationRule.minLength(t("formField.phoneNumber"), 10),
            ...validationRule.validatePhoneCountryCode(isPhoneCountryCodeValid),
          }}
        />
      </div>
      <div className={elCls("row")}>
        <CountriesSelect
          control={control}
          name="citizenship"
          label={getLabel(`${t("formField.citizenship")}*`)}
          rules={{ ...validationRule.required(t("formField.citizenship")) }}
          remark={t("formField.citizenship.remark")}
          nextIcon={countriesSelectNextIcon}
        />
      </div>
      {isAgent && (
        <div className={elCls("row")}>
          <InvoiceRecipientSelect
            control={control}
            name="invoice"
            label={`${t("formField.invoiceRecipient")}*`}
            rules={{ ...validationRule.required(t("formField.invoiceRecipient")) }}
            nextIcon={countriesSelectNextIcon}
          />
        </div>
      )}
      <div className={elCls("row")}>
        <Notes
          control={control}
          type="textarea"
          placeholder={t("formField.specialRequests.placeholder")}
          label={t("formField.specialRequests")}
          name="notes"
          style={{ resize: "none" }}
          rows={2}
        />
      </div>
      <PolicyCheckbox
        control={control}
        name="accept_terms"
        rules={{ required: "error.policy" }}
        scrollIntoViewOnError
        termsAndConditionLink={termsAndConditionLink}
        isExpedia={isExpedia}
      />
    </div>
  );
};

CustomerForm.propTypes = {
  control: T.object.isRequired,
  termsAndConditionLink: T.string,
  countriesSelectNextIcon: T.node,
  revalidateErrors: T.func,
  isExpedia: T.bool,
};

export default CustomerForm;
