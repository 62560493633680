import { integrationHandlers, integration } from "./integration";
import { pricesByOrderNumber, pricesHandlers } from "./prices";
import { orders, orderHandlers } from "./orders";
import { suggestedProducts, suggestedProductsHandlers } from "./suggestedProducts";

export const handlers = {
  ...integrationHandlers,
  ...pricesHandlers,
  ...orderHandlers,
  ...suggestedProductsHandlers,
};

export const mockedData = {
  integration,
  pricesByOrderNumber,
  orders,
  suggestedProducts,
};
