import React, { useRef } from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useSticky } from "@tint_fe/hooks";

const StickyButtonGroupWrapper = ({ children, preventSticky, className }) => {
  const ref = useRef();
  const isSticky = useSticky(ref.current, "bottom");
  const { mainCls, elCls } = parseClasses({
    base: "buttons-group",
    classes: [isSticky && !preventSticky && "sticky"],
    additional: className,
  });

  return (
    <div ref={ref} className="button-group-wrapper">
      <div className={mainCls}>
        <div className={elCls("container", isSticky && !preventSticky && "sticky")}>
          <div className={elCls("content", isSticky && !preventSticky && " col col-12 col-md-7")}>{children}</div>
        </div>
      </div>
    </div>
  );
};

StickyButtonGroupWrapper.propTypes = {
  preventSticky: T.bool,
  children: T.node,
  className: T.string,
};

export default StickyButtonGroupWrapper;
