import * as React from "react";

function SvgDelete(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4 0a1 1 0 00-.991.868L4.124 3H1a1 1 0 000 2h1.067l.705 10.07a1 1 0 00.997.93h8.462a1 1 0 00.997-.93L13.932 5H15a1 1 0 100-2h-3.124L11.59.868A1 1 0 0010.6 0H5.4zm4.458 3H6.142l.134-1h3.448l.134 1zM4.702 14l-.63-9h7.856l-.63 9H4.702zM7 11a1 1 0 100 2h2a1 1 0 100-2H7z"
        fill="#151209"
      />
    </svg>
  );
}

export default SvgDelete;
