import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Modal, Button } from "../..";
import PhoneButton from "./PhoneButton";
import OpenChatButton from "./OpenChatButton";

const ContactUsModal = ({ toggleContactUsModal, openContactUsModal, integration, rootId }) => {
  const { t } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "contact-us" });

  const closeModal = () => toggleContactUsModal(false);

  return (
    <Modal withCloseBtn closeModal={closeModal} containerClass={mainCls} isOpen={openContactUsModal} rootId={rootId}>
      <h3 className={elCls("title", "second-font")}>{t("contactUs.title")}</h3>
      <p className={elCls("subtitle")}>{t("contactUs.subtitle")}</p>
      <div className={elCls("btns")}>
        {integration?.phone_number && (
          <PhoneButton phone={integration.phone_number} ghost big className={elCls("btn")} />
        )}
        {integration?.whatsapp_link && (
          <Button
            href={integration.whatsapp_link}
            target="_blank"
            rel="noopener"
            as="a"
            ghost
            big
            className={elCls("btn")}
          >
            {t("whatsapp")}
          </Button>
        )}
        <OpenChatButton onOpenChat={closeModal} ghost big className={elCls("btn")} />
        {integration?.contact_us_link && (
          <Button
            href={integration.contact_us_link}
            target="_blank"
            rel="noopener"
            as="a"
            ghost
            big
            className={elCls("btn")}
          >
            {t("email")}
          </Button>
        )}
      </div>
    </Modal>
  );
};

ContactUsModal.propTypes = {
  toggleContactUsModal: T.func.isRequired,
  openContactUsModal: T.bool,
  integration: T.object,
  rootId: T.string,
};

export default ContactUsModal;
