import { useEffect, useState } from "react";

import { getUserFromSessionStorage, subscribeUserChange } from "@tint_fe/helpers";

const useUser = () => {
  const [user, setUser] = useState(getUserFromSessionStorage());

  useEffect(() => {
    subscribeUserChange((data) => {
      setUser(data);
    });

    return () => {
      // TODO: need to investigate when it is wright to unsubscribe
      // unsubscribeUserChange();
    };
  }, []);

  return { user: user ? { ...user, isAgent: user.role === "product_agent" } : null, setUser };
};

export default useUser;
