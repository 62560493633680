import React from "react";
import T from "prop-types";
import { useController } from "react-hook-form";
import { isObject, isEmpty } from "lodash";

import { Select } from "../../components";

const options = [
  {
    value: "customer",
    label: "Traveler",
  },
  {
    value: "agent",
    label: "Agency",
  },
];

const InvoiceRecipientSelect = ({ control, name, rules, label, withValidateMark, nextIcon }) => {
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting, isSubmitSuccessful },
  } = useController({
    rules,
    control,
    name,
  });

  const value = isObject(field?.value) ? field.value : options?.find((el) => el.value === field.value);

  return (
    <Select
      error={error}
      showOptionFrom={0}
      label={label}
      nextIcon={nextIcon}
      placeholder=""
      options={options}
      {...field}
      value={value}
      showValidateMark={withValidateMark && (!isEmpty(error) || isSubmitting || isSubmitSuccessful)}
    />
  );
};

InvoiceRecipientSelect.propTypes = {
  control: T.object,
  name: T.string.isRequired,
  rules: T.object,
  label: T.string,
  withValidateMark: T.bool,
  nextIcon: T.node,
};

export default InvoiceRecipientSelect;
