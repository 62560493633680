import * as React from "react";
import PropTypes from "prop-types";

function SvgHotel(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M1 15h14M3 14.5V2h10v7"
        stroke={props?.color || "#151209"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 15V9a1 1 0 011-1h6a1 1 0 011 1v6" stroke={props?.color || "#151209"} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.75A.75.75 0 015.75 4h4.5a.75.75 0 010 1.5h-4.5A.75.75 0 015 4.75zm0 3A.75.75 0 015.75 7h4.5a.75.75 0 010 1.5h-4.5A.75.75 0 015 7.75zM5.75 10a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z"
        stroke={props?.color || "#151209"}
      />
    </svg>
  );
}

SvgHotel.propTypes = {
  color: PropTypes.string,
};

export default SvgHotel;
