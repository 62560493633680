import React from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";

import { SubmitButton, BackButton } from "@tint_fe/ui";
import { useBreakPoints } from "../../hooks";
import StickyButtonsGroupWrapper from "./StickyButtonsGroupWrapper";

const StickyButtonGroup = ({ onBack, onSubmit, loading, label, submitDisabled }) => {
  const { isMd } = useBreakPoints();
  const { t } = useTranslation();

  return (
    <StickyButtonsGroupWrapper className={!onBack && "one-btn"} preventSticky={!isMd}>
      {onBack && <BackButton type="button" onClick={onBack} />}
      <SubmitButton disabled={submitDisabled} label={t(label)} loading={loading} onClick={onSubmit} />
    </StickyButtonsGroupWrapper>
  );
};

StickyButtonGroup.defaultWrapper = {
  label: "continueBooking",
};

StickyButtonGroup.propTypes = {
  onBack: T.func,
  onSubmit: T.func.isRequired,
  loading: T.bool.isRequired,
  label: T.string,
  submitDisabled: T.bool,
};

export default StickyButtonGroup;
