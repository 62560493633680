import React from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "..";

const BackButton = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button data-testid={"back-button-main"} isBackBtn ghost type="button" {...rest}>
      {t("back")}
    </Button>
  );
};

BackButton.propTypes = {
  className: T.string,
};

export default BackButton;
