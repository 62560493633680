import { createActivityOrderRequest } from "../../../../redux/order/orderActions";
import { useReduxAction } from "../../../../hooks";
import { getSourceLink } from "../../../../utils";

const useCreateActivityOrder = (history) => {
  const createActivityOrder = useReduxAction(createActivityOrderRequest);

  return (productItemsAttributes) => {
    createActivityOrder({
      body: {
        order: {
          product_items_attributes: { 0: productItemsAttributes },
          source_link: getSourceLink(),
        },
      },
      history,
    });
  };
};

export default useCreateActivityOrder;
