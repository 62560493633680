import React from "react";
import T from "prop-types";
import { parseClasses } from "@tint_fe/helpers";
import { Button, RocketIcon } from "@tint_fe/ui";

const EnhanceExperience = ({ className }) => {
  const { elCls } = parseClasses({
    base: className,
  });

  const scrollToExtras = () => {
    const el = document.querySelector(".success-page__extras-title");
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className={elCls("upgrade-container")}>
      <div className={elCls("upgrade-row")}>
        <div className={elCls("rocket-icon-container")}>
          <RocketIcon />
        </div>
        <p>{"Review and upgrade your trip"}</p>
      </div>
      <p className={elCls("upgrade-text")}>
        {
          "Enhance your experience by adding an additional services to your booking. Check each day of your itinerary below to see which upgrades are on offer."
        }
      </p>
      <div className={elCls("upgrade-row")}>
        <Button className={elCls("btn")} onClick={() => scrollToExtras()} nextIcon={<></>}>
          {"Upgrade"}
        </Button>
      </div>
    </div>
  );
};

EnhanceExperience.propTypes = {
  className: T.string,
};

export default EnhanceExperience;
