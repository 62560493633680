import * as React from "react";

function SvgCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M9.996 14.352l.436.366.307-.479c2.493-3.888 5.096-6.8 7.777-9.067l-8.338 13.02c-1.848-2.01-3.36-4.503-4.562-7.522l4.38 3.682z"
        fill="#fff"
        stroke="#fff"
      />
    </svg>
  );
}

export default SvgCheck;
