import React from "react";
import T from "prop-types";

import { join } from "@tint_fe/helpers";

const Loader = ({ dark, className, ...rest }) => {
  return (
    <div
      role="progressbar"
      aria-valuetext="Loading…"
      aria-busy="true"
      className={join([`loader${dark ? " dark" : ""}`, className], " ")}
      {...rest}
    >
      <span />
      <span />
      <span />
    </div>
  );
};

Loader.propTypes = {
  dark: T.bool,
  className: T.string,
};

export default Loader;
