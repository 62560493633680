export const transformArrayOfObjectsToOptions = (array = [], keyValue = "id", keyLabel = "name") => {
  return array.map((item) => ({ value: item[keyValue], label: item[keyLabel] }));
};

export const transformArrayOfObjectsToOptionsWithTwoLocations = (
  array = [],
  keyValue = "id",
  keyLabel = "name",
  secondKeyLabel = "city_name",
) => {
  return array.map((item) => ({ value: item[keyValue], label: `${item[keyLabel]}, ${item[secondKeyLabel]}` }));
};

export const scrollToError = () => {
  setTimeout(() => {
    const node = document.querySelector(".input-wrapper__error");

    if (node && node.scrollIntoView) {
      node.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, 100);
};
