import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { QueryString } from "@tint_fe/helpers";
import { RequestPayment as CRequestPayment } from "@tint_fe/ui/src/components";
import { getPaymentsLinks } from "../../../utils";
import { requestPaymentContinueRequest } from "../../../redux/actions";
import { useHistoryNavigation, useReduxAction } from "../../../hooks";

import { selectError } from "../../../redux/sharedSelectors";

const RequestPayment = () => {
  const history = useHistoryNavigation();
  const error = useSelector(selectError);
  const isError = !isEmpty(error);

  const requestPaymentContinue = useReduxAction(requestPaymentContinueRequest);

  const termsAndConditionLink = useSelector((state) => state.global.integration.terms_and_conditions_url);

  const handleSubmit = () => {
    const { search } = document.location;
    const { order, payment_uuid } = QueryString.parse(search);

    requestPaymentContinue({
      history,
      orderId: order,
      paymentBody: { order: { payment_uuid, ...getPaymentsLinks({ orderNumber: order }), agree: true } },
    });
  };

  return <CRequestPayment onSubmit={handleSubmit} isError={isError} termsAndConditionLink={termsAndConditionLink} />;
};

export default RequestPayment;
