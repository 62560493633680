import React from "react";
import T from "prop-types";
import { isEmpty, isObject } from "lodash";
import { useController } from "react-hook-form";

import { findCountryFlag } from "@tint_fe/helpers";
import { Select } from "../../components";
import countries from "../../assets/countries/countries.json";

const CountriesSelect = ({ control, name, rules, label, withValidateMark, nextIcon }) => {
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting, isSubmitSuccessful },
  } = useController({
    rules,
    control,
    name,
  });

  const value = isObject(field?.value) ? field.value : countries?.find((el) => el.alpha2Code === field.value);

  return (
    <Select
      classNameWrapper="select-citizenship"
      error={error}
      showOptionFrom={0}
      label={label}
      nextIcon={nextIcon}
      placeholder=""
      options={countries?.map((el) => ({
        value: el.alpha2Code,
        label: el.name,
        flag: findCountryFlag(el.alpha2Code),
      }))}
      {...field}
      value={value && { value: value.alpha2Code, label: value.name, ...value }}
      showValidateMark={withValidateMark && (!isEmpty(error) || isSubmitting || isSubmitSuccessful)}
    />
  );
};

CountriesSelect.propTypes = {
  control: T.object,
  name: T.string.isRequired,
  rules: T.object,
  label: T.string,
  withValidateMark: T.bool,
  nextIcon: T.node,
};

export default CountriesSelect;
