import React from "react";
import T from "prop-types";
import { DayPicker as RcDayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import en_US from "date-fns/locale/en-US";
import de_DE from "date-fns/locale/de";
import fr_FR from "date-fns/locale/fr";
import it_IT from "date-fns/locale/it";
import es_ES from "date-fns/locale/es";
import he_IL from "date-fns/locale/he";

import { dates } from "@tint_fe/helpers";
import { useBreakPoints, useTranslation } from "@tint_fe/hooks";

const LOCALES_MAP = {
  en: en_US,
  de: de_DE,
  fr: fr_FR,
  it: it_IT,
  es: es_ES,
  he: he_IL,
};

const DayPicker = ({ numberOfMonths, ...rest }) => {
  const { isRtl, i18n } = useTranslation();
  const { isMd } = useBreakPoints();
  const defaultNumberOfMonths = !isMd ? 2 : 1;

  return (
    <RcDayPicker
      dir={isRtl ? "rtl" : "auto"}
      numberOfMonths={numberOfMonths || defaultNumberOfMonths}
      locale={LOCALES_MAP[i18n.language] || LOCALES_MAP.en}
      formatters={{
        formatWeekdayName: (d) => dates.format(d, "ccc", false),
        formatCaption: (d) => dates.format(d, "MMMM yyyy", false),
      }}
      {...rest}
    />
  );
};

DayPicker.defaultProps = {
  fromMonth: new Date(),
  fixedWeeks: true,
  pagedNavigation: true,
};

DayPicker.propTypes = {
  fromMonth: T.instanceOf(Date),
  month: T.instanceOf(Date),
  fixedWeeks: T.bool,
  pagedNavigation: T.bool,
  numberOfMonths: T.number,
};

export default DayPicker;
