export const REQUEST_PRODUCT_FAILED = "REQUEST_PRODUCT_FAILED";

/****************************************************** PRODUCTS ******************************************************/

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "GET_PRODUCT_FAILED";

/******************************************************* TOURS ********************************************************/

export const GET_TOUR_AVAILABILITY_REQUEST = "GET_TOUR_AVAILABILITY_REQUEST";
export const GET_TOUR_AVAILABILITY_SUCCESS = "GET_TOUR_AVAILABILITY_SUCCESS";

export const GET_TOUR_PRICE_BANDS_REQUEST = "GET_TOUR_PRICE_BANDS_REQUEST";
export const GET_TOUR_PRICE_BANDS_SUCCESS = "GET_TOUR_PRICE_BANDS_SUCCESS";

export const GET_PICKUP_POINTS_BY_LOCATION_REQUEST = "GET_PICKUP_POINTS_BY_LOCATION_REQUEST";
export const GET_PICKUP_POINTS_BY_LOCATION_SUCCESS = "GET_PICKUP_POINTS_BY_LOCATION_SUCCESS";

/**************************************************** ACTIVITIES ******************************************************/

export const GET_SCHEDULE_ACTIVITY_REQUEST = "GET_SCHEDULE_ACTIVITY_REQUEST";
export const GET_SCHEDULE_ACTIVITY_SUCCESS = "GET_SCHEDULE_ACTIVITY_SUCCESS";
export const GET_SCHEDULE_ACTIVITY_FAILURE = "GET_SCHEDULE_ACTIVITY_FAILURE";
