import * as React from "react";
const ImportantNote = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#21AFE4"
      fillRule="evenodd"
      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm7.995-4.724c.52 0 .94.423.94.945V8c0 .522-.42.945-.94.945A.942.942 0 0 1 7.055 8V4.221c0-.522.421-.945.94-.945Zm-.94 8.503c0-.522.421-.945.94-.945h.01c.519 0 .94.423.94.945s-.421.945-.94.945h-.01a.942.942 0 0 1-.94-.945Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ImportantNote;
