import * as React from "react";
const CommissionLevelIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#21AFE4"
        stroke="#21AFE4"
        d="M1.5 8h3v7h-3V8ZM9.542 6.043v.5h4.604c.761 0 1.354.597 1.354 1.301 0 .075-.007.148-.02.219v.002l-1.02 5.695c-.126.706-.763 1.24-1.529 1.24H4.5V7.613l2.846-6A1.167 1.167 0 0 1 8.382 1c.654 0 1.16.512 1.16 1.112v3.931Z"
      />
      <path fill="#fff" d="M3 8.5h1v6H3z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h16v15H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CommissionLevelIcon;
