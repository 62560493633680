import React, { useState } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { ArrowRight, Button, Close, Loader, Modal } from "@tint_fe/ui";
import { useReduxAction } from "@tint_fe/redux-utils";

import { capitalizeFirstLetter } from "../../../utils";
import { getOrderRequest } from "../../../redux/actions";
import { useProductAsExtra, useProductDetails } from "./hooks";
import { modalSteps } from "./helpers";

import ControllerInput from "../../components/Form/ControllerInput";
import useExtraAsExtra from "./hooks/useExtraAsExtra";
import ModalNav from "./ModalNav";
import TourExtrasItem from "../Tour/TourCheckoutForm/TourExtras/TourExtrasItem";
import ExtraHotelCheckout from "../Hotel/HotelCheckoutForm/ExtraHotelCheckout";
import ExtraTourCheckoutForm from "../Tour/TourCheckoutForm/ExtraTourCheckoutForm";
import Payment from "../Payment/Payment";
import useHotelCheckoutForm from "../Hotel/hooks/useHotelCheckoutForm";
import { TOUR_DETAILS_FORM } from "../../../helpers/constants";
import ProductDetails from "./ProductDetails";
import ExtraOrderSummary from "./ExtraOrderSummary";

const ExtraAsProduct = ({ form, product, chargeCurrency, maxNumber }) => {
  const defaultValue = product?.capacity_values?.[0] || 0;
  const maxValue = maxNumber || product?.capacity_values?.[product?.capacity_values?.length - 1] || 0;
  const images = product?.images || [];

  const updateExtrasHandler = (data, id) => {
    form?.setValue("extrasInfo", { [id]: data });

    return data[id];
  };

  return (
    <ControllerInput
      key={`extra-info-${product.id}`}
      name={`extrasInfo.${product.id}`}
      control={form?.control}
      shouldUnregister
      defaultValue={defaultValue}
      render={({ field: { value, onChange, ...rest } }) => (
        <TourExtrasItem
          extra={{
            ...product,
            images: images,
          }}
          price={product?.price}
          quantity={typeof value === "undefined" ? defaultValue : value}
          max={maxValue}
          updateExtrasHandler={(val) => onChange(updateExtrasHandler(val, product.id))}
          currency={chargeCurrency}
          {...rest}
        />
      )}
    />
  );
};

ExtraAsProduct.propTypes = {
  form: T.object.isRequired,
  product: T.object.isRequired,
  chargeCurrency: T.string,
  maxNumber: T.number,
};

const ModalExtraBooking = ({
  isOpen,
  closeModal,
  product,
  chargeCurrency,
  orderNumber,
  order,
  selectedExtras,
  selectedProductExtras,
}) => {
  const { t } = useTranslation();
  const getOrder = useReduxAction(getOrderRequest);
  const { mainCls, elCls } = parseClasses({ base: "modal-extra-booking" });
  const [step, setStep] = useState(modalSteps.CONFIGURATION);
  const form = useForm();
  const rootId = useSelector(({ global }) => global.rootId);
  const maxNumber = order?.pax_quantity || 0;

  const { type, product_item_id, id } = product;
  const preventFetchProductDetails = type === "extra" || !id;
  const { productDetails, productLoading } = useProductDetails(id, preventFetchProductDetails);

  const isProductConfigured = step === modalSteps.PAYMENT;

  const onSuccessfulPatchExtras = () => {
    closeModal();
    getOrder({ orderNumber });
  };

  const {
    patcher: patchExtras,
    // res: patchExtrasRes,
    // error: patchExtrasError,
    loading: patchExtrasLoading,
  } = useExtraAsExtra(() => onSuccessfulPatchExtras());

  const onSuccessCreateProductAsExtra = () => {
    getOrder({ orderNumber });
    setStep(modalSteps.PAYMENT);
  };

  const {
    patcher: createProductExtra,
    // error: createHotelExtraError,
    loading: createProductExtraLoading,
  } = useProductAsExtra({ onSuccess: () => onSuccessCreateProductAsExtra() });

  const loading = patchExtrasLoading || createProductExtraLoading;

  const hotelMethods = useHotelCheckoutForm();
  const tourMethods = useForm({
    defaultValues: TOUR_DETAILS_FORM,
    shouldFocusError: true,
    reValidateMode: "onChange",
  });

  const renderProductByType = () => {
    switch (type) {
      case "hotel":
        if (productLoading || !productDetails) return <Loader />;

        return (
          <FormProvider {...hotelMethods}>
            <ProductDetails product={productDetails} />
            <ExtraHotelCheckout
              pid={product.id}
              orderNumber={orderNumber}
              createHotelProductItem={createProductExtra}
              createLoading={createProductExtraLoading}
              startDate={order?.package_start_date}
            />
          </FormProvider>
        );
      case "tour":
        if (productLoading || !productDetails) return <Loader />;

        return (
          <FormProvider {...tourMethods}>
            <ProductDetails product={productDetails} />
            <ExtraTourCheckoutForm
              product={productDetails}
              orderNumber={orderNumber}
              createTourProductItem={createProductExtra}
              createLoading={createProductExtraLoading}
              startDate={order?.package_start_date}
            />
          </FormProvider>
        );
      case "extra":
        return <ExtraAsProduct form={form} product={product} chargeCurrency={chargeCurrency} maxNumber={maxNumber} />;
      default:
        return <div>Default</div>;
    }
  };

  const clickByType = () => {
    if (type === "extra") {
      const extrasInfo = form?.getValues("extrasInfo");
      const body = {
        product_extra_id: Number(Object.keys(extrasInfo)[0]),
        quantity: Object.values(extrasInfo)[0],
      };

      patchExtras({ orderNumber, product_item_id, body });
    } else {
      // eslint-disable-next-line no-console
      console.log("not extra yet");
    }
  };

  const isExtras = type === "extra";

  return (
    <Modal closeModal={closeModal} className={mainCls} isOpen={isOpen} rootId={rootId}>
      <div className={elCls("close")}>
        <button onClick={closeModal}>
          <Close width={24} height={24} />
        </button>
      </div>
      <h3 className={elCls("title", "second-font mg-t-0 mg-b-6")}>{`${capitalizeFirstLetter(type)} booking`}</h3>
      {!isExtras && <ModalNav step={isProductConfigured ? 1 : 0} />}
      {/* {step === modalSteps.CONFIGURATION && renderProductDetailsByType()} */}
      {step === modalSteps.CONFIGURATION && renderProductByType()}
      {step === modalSteps.PAYMENT && (
        <ExtraOrderSummary
          selectedExtras={selectedExtras}
          selectedProductExtras={selectedProductExtras}
          chardeCurrency={chargeCurrency}
        />
      )}
      {step === modalSteps.PAYMENT && <Payment hideBackBtn={true} />}
      {isExtras && (
        <>
          {/* {typeof error === "object" && (
            <div className={elCls("error-container")}>
              <span className={elCls("error-text")}>{"Error occured"}</span>
            </div>
          )} */}
          <div className={elCls("btns")}>
            <Button
              className={elCls("btn")}
              fullsize
              onClick={() => clickByType()}
              nextIcon={<ArrowRight />}
              processing={loading}
            >
              {t("continue")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

ModalExtraBooking.propTypes = {
  isOpen: T.bool.isRequired,
  closeModal: T.func.isRequired,
  product: T.object,
  chargeCurrency: T.string,
  orderNumber: T.string,
  order: T.object,
  selectedExtras: T.array,
  selectedProductExtras: T.array,
};

export default ModalExtraBooking;
