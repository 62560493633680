import { useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import { useSelector } from "react-redux";

import { QueryString, dates } from "@tint_fe/helpers";
import { SPCheckoutFormFields } from "../helpers";
import { updateUrl } from "../../../../utils";
import { getSmartPackageSessionStorage } from "../../../../storage";
import { mealPlans, PACKAGE_FORMAT_DATE } from "../../../../helpers/constants";
import { isEmpty } from "lodash";

const {
  MEAL_PLAN,
  HOTEL_LEVEL,
  START_LOCATION,
  START_LOCATION_TYPE,
  START_DATE,
  END_LOCATION,
  END_LOCATION_TYPE,
  ROOMS,
  CHILDREN,
  ADULTS,
  FLIGHT_NUMBER,
  DEPARTURE_FLIGHT_NUMBER,
  ARRIVAL_EXTRA_NIGHTS,
  DEPARTURE_EXTRA_NIGHTS,
} = SPCheckoutFormFields;

const parseDate = (data) => (data ? dates.parse(data, PACKAGE_FORMAT_DATE, false) : null);
const parseRooms = (rooms) => {
  if (rooms) {
    return Object.values(rooms).map((el) => {
      return el.children ? { ...el, key: uuidV4() } : { ...el, children: [], key: uuidV4() };
    });
  }

  return [];
};

const getValidStartDate = (date) => {
  if (!date) {
    return null;
  }
  const isDateBeforeToday = dates.isBefore(date, new Date(), false);

  return isDateBeforeToday ? null : parseDate(date);
};

export const getOrderDetailsFromOrder = (order) => {
  return {
    [MEAL_PLAN]: order[MEAL_PLAN],
    [HOTEL_LEVEL]: order[HOTEL_LEVEL],
    package_id: order.package_id,
    [START_LOCATION]: Number(order[START_LOCATION]),
    [START_LOCATION_TYPE]: order[START_LOCATION_TYPE],
    [START_DATE]: order[ARRIVAL_EXTRA_NIGHTS]
      ? dates.add(parseDate(order[START_DATE]), { days: order[ARRIVAL_EXTRA_NIGHTS] }, false)
      : parseDate(order[START_DATE]),
    ...(order[END_LOCATION] && { [END_LOCATION]: Number(order[END_LOCATION]) }),
    ...(order[END_LOCATION_TYPE] && { [END_LOCATION_TYPE]: order[END_LOCATION_TYPE] }),
    [CHILDREN]: Number(order[CHILDREN]),
    [ADULTS]: Number(order[ADULTS]),
    [FLIGHT_NUMBER]: order[FLIGHT_NUMBER],
    [DEPARTURE_FLIGHT_NUMBER]: order[DEPARTURE_FLIGHT_NUMBER],
    [ROOMS]: parseRooms(order[ROOMS]),
    [ARRIVAL_EXTRA_NIGHTS]: Number(order[ARRIVAL_EXTRA_NIGHTS]) || 0,
    [DEPARTURE_EXTRA_NIGHTS]: Number(order[DEPARTURE_EXTRA_NIGHTS]) || 0,
  };
};

const getInitialSPCheckoutFormData = ({ package_id, allowAccomodation }) => {
  const savedValues = getSmartPackageSessionStorage();
  const isSavedValuesValid = package_id === savedValues?.package_id;
  const validDate = isSavedValuesValid ? getValidStartDate(savedValues?.[START_DATE]) : null;

  const initData = {
    [ROOMS]: [{ adults: 2, children: [], id: uuidV4() }],
    children: 0,
    adults: 2,
    [MEAL_PLAN]: mealPlans.ROOM_ONLY.value,
    [FLIGHT_NUMBER]: "",
    [DEPARTURE_FLIGHT_NUMBER]: "",
    [ARRIVAL_EXTRA_NIGHTS]: 0,
    [DEPARTURE_EXTRA_NIGHTS]: 0,
  };

  const normalizedInitialData = isSavedValuesValid
    ? { ...initData, ...savedValues, [START_DATE]: validDate }
    : { ...initData, [START_DATE]: validDate };

  if (!allowAccomodation) {
    delete normalizedInitialData[ROOMS];
    delete normalizedInitialData[MEAL_PLAN];
    delete normalizedInitialData[HOTEL_LEVEL];
  }

  return { ...normalizedInitialData };
};

const usePreselectSPCheckoutForm = (reset, getValues) => {
  const order = useSelector((state) => state.order.data);
  const package_id = useSelector((state) => state.smartPackage.data.id);
  const skip_accommodation = useSelector((state) => state.smartPackage.data.skip_accommodation);

  const start_date = getValues(START_DATE);
  const arrival_extra_nights = getValues(ARRIVAL_EXTRA_NIGHTS);
  const departure_extra_nights = getValues(DEPARTURE_EXTRA_NIGHTS);
  const start_location = getValues(START_LOCATION);
  const start_location_type = getValues(START_LOCATION_TYPE);
  const isOvernightsSelected = Number(arrival_extra_nights ?? 0) > 0 || Number(departure_extra_nights ?? 0) > 0;
  const allowAccomodation = !skip_accommodation || isOvernightsSelected;

  // This useEffect is being used to track overnights changes on SP with skip_accommodation: true
  useEffect(() => {
    const initialValues = getInitialSPCheckoutFormData({
      package_id,
      allowAccomodation,
    });

    if (package_id) {
      reset({
        ...initialValues,
        [START_DATE]: start_date,
        [ARRIVAL_EXTRA_NIGHTS]: arrival_extra_nights,
        [DEPARTURE_EXTRA_NIGHTS]: departure_extra_nights,
        [START_LOCATION]: start_location,
        [START_LOCATION_TYPE]: start_location_type,
        ...(allowAccomodation && { [CHILDREN]: 0, [ADULTS]: 2 }),
      });
    }
  }, [allowAccomodation]);

  useEffect(() => {
    const initialValues = getInitialSPCheckoutFormData({
      package_id,
      allowAccomodation,
    });

    if (package_id && isEmpty(order)) {
      reset(initialValues);
    }

    if (!isEmpty(order)) {
      reset({ ...initialValues, ...getOrderDetailsFromOrder(order) });
    }
  }, [package_id, order]);

  // Listen to the initial url and update the form
  useEffect(() => {
    const { date } = QueryString.parse(document.location.search);
    if (date) {
      const initialValues = getInitialSPCheckoutFormData({
        package_id,
        allowAccomodation,
      });

      updateUrl(document.location.href.replace(`&date=${date}`, ""), "replace", {});

      const actualDate = parseDate(date);
      reset({ ...initialValues, [START_DATE]: actualDate });
    }
  }, [package_id]);
};

export default usePreselectSPCheckoutForm;
