/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import T from "prop-types";

import { dates, parseClasses } from "@tint_fe/helpers";
import ItineraryItem from "./ItineraryItem";
import { isEmpty } from "lodash";
import { useExtraAsExtra } from "../../containers/SuccessPage/hooks";
import { useReduxAction } from "@tint_fe/redux-utils";
import { getOrderRequest } from "../../../redux/actions";
import { Preloader } from "@tint_fe/ui";

const createItinerary = (packageStartDate, itineraryData) => {
  const itinerary = [];
  let firstOpenDay = 0;
  let firstOpenDayFound = false;

  for (const date in itineraryData) {
    if (itineraryData.hasOwnProperty(date)) {
      const dayObject = itineraryData[date];
      const availableExtras = dayObject.available_extras;
      const isFirstDay = dates.differenceInDays(new Date(date), packageStartDate, false) === 0;
      const isLastDay =
        dates.differenceInDays(new Date(date), packageStartDate, false) === Object.keys(itineraryData).length - 1;

      if (!isEmpty(availableExtras) && !firstOpenDayFound) {
        firstOpenDay = dates.differenceInDays(new Date(date), packageStartDate, false);
        firstOpenDayFound = true;
      }

      const extras = [];

      for (const productItemId in availableExtras) {
        if (availableExtras.hasOwnProperty(productItemId)) {
          const extras_pid = availableExtras[productItemId];
          extras.push(
            ...extras_pid.map((extra) => ({
              product_item_id: productItemId,
              ...extra,
            })),
          );
        }
      }
      const firstDayTitle = isFirstDay && !dayObject?.highlights?.length ? "Arrival" : null;
      const lastDayTitle = isLastDay && !dayObject?.highlights?.length ? "Departure" : null;
      const dayTitle = firstDayTitle || lastDayTitle || dayObject.city_name;

      itinerary.push({
        date: date,
        extras: extras,
        dayTitle: dayTitle,
        highlights: dayObject.highlights || [],
      });
    }
  }

  return { itinerary, firstOpenDay };
};

const SmartPackageItinerary = ({
  orderNumber,
  loading,
  chargeCurrency,
  onSelectExtra,
  packageStartDate,
  itineraryData,
  selectedIds,
  numberOfPeople,
}) => {
  const { mainCls, elCls } = parseClasses({
    base: "smart-package-itinerary",
  });
  const getOrder = useReduxAction(getOrderRequest);
  const days = itineraryData && Object.keys(itineraryData)?.length;
  const { itinerary, firstOpenDay } = createItinerary(packageStartDate, itineraryData);
  const [firstOpenDayNum, setFirstOpenDayNum] = useState(0);
  const [createdItinerary, setCreatedItinerary] = useState([]);

  useEffect(() => {
    setCreatedItinerary(itinerary);
    setFirstOpenDayNum(firstOpenDay);
  }, [firstOpenDay, itineraryData]);

  const handleSelectExtra = useCallback((...args) => onSelectExtra(...args), []);

  const onSuccessfulPatchExtras = () => {
    getOrder({ orderNumber });
  };

  const { patcher: patchExtras } = useExtraAsExtra(() => onSuccessfulPatchExtras());

  const onDirectAddExtra = (extra_id, product_item_id) => {
    const body = {
      product_extra_id: extra_id,
      quantity: 1,
    };

    patchExtras({ orderNumber, product_item_id, body });
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      {!!createdItinerary?.length && <span className={"success-page__extras-title"}>{"Tour itinerary"}</span>}
      <div className={mainCls}>
        {createdItinerary.map((item, i) => {
          return (
            <ItineraryItem
              key={item.dayTitle + i}
              chargeCurrency={chargeCurrency}
              number={i + 1}
              isLast={i === days - 1}
              isFirst={i === 0}
              initOpen={i === firstOpenDayNum}
              onSelectExtra={handleSelectExtra}
              onDirectAddExtra={onDirectAddExtra}
              selectedIds={selectedIds}
              numberOfPeople={numberOfPeople}
              {...item}
            />
          );
        })}
      </div>
    </>
  );
};

SmartPackageItinerary.propTypes = {
  orderNumber: T.string,
  loading: T.bool,
  chargeCurrency: T.string,
  onSelectExtra: T.func,
  packageStartDate: T.string,
  itineraryData: T.object,
  selectedIds: T.array,
  numberOfPeople: T.number,
};

export default SmartPackageItinerary;
