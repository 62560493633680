const UUID = "uuid";
const PAYMENT_UUID = "payment_uuid";

// Analytics
const ITEM_LIST_ID = "tintWidgetListId";
const ITEM_LIST_NAME = "tintWidgetListName";
const ITEM_LIST_INDEX = "tintWidgetViewIndex";
const ITEM_VIEW_INDEX = "tintWidgetViewIndex";

export const getUuidFromSessionStorage = () => sessionStorage.getItem(UUID);
export const setUuidToSessionStorage = (uuid) => uuid && sessionStorage.setItem(UUID, uuid);

export const getPaymentUuidFromSessionStorage = () => sessionStorage.getItem(PAYMENT_UUID);
export const setPaymentUuidToSessionStorage = (paymentUuid) => {
  sessionStorage.setItem(PAYMENT_UUID, paymentUuid);
};

export const getItemListIdFromSessionStorage = () => sessionStorage.getItem(ITEM_LIST_ID);
export const getItemListNameFromSessionStorage = () => sessionStorage.getItem(ITEM_LIST_NAME);
export const getItemListIndexFromSessionStorage = () => sessionStorage.getItem(ITEM_LIST_INDEX);
export const getItemViewIndexFromSessionStorage = () => sessionStorage.getItem(ITEM_VIEW_INDEX);

export * from "./userStorage";
