import { useSelector } from "react-redux";
import { flow, orderBy } from "lodash";

import { useTranslation } from "@tint_fe/hooks";
import { formatPricePerItem } from "@tint_fe/helpers";

import { bookingTypes } from "../../../../helpers/constants";

const filterActivityProductItems = (lineItems) => {
  return lineItems.filter((item) => {
    if (item.product_option_type === "ProductExtra") {
      return Number(item.quantity) > 0;
    }

    return !!Number.parseFloat(item.calculated_item_price);
  });
};

const stringifyLineItems = (lineItems, getListItemProperty) => {
  return lineItems.map((item) => {
    return {
      id: item.id || item.product_option_id,
      price: item.rounded_calculated_item_price,
      number: item.quantity,
      pricePerItem: formatPricePerItem(item.rounded_price_per_person),
      ...getListItemProperty(item),
    };
  });
};

const sortByPriceBand = (arr) => {
  return arr.sort((a, b) => {
    if (a.product_option_type === "PriceBand" && b.product_option_type !== "PriceBand") {
      return -1;
    }
    if (a.product_option_type !== "PriceBand" && b.product_option_type === "PriceBand") {
      return 1;
    }

    return 0;
  });
};

const useActivitySummaryInfo = (price) => {
  const { t } = useTranslation();
  const dropOffLocation = useSelector((state) => state.global.dropOffLocation);
  const bookingType = useSelector(({ global }) => global.bookingType);

  if (bookingType !== bookingTypes.ACTIVITY) {
    return [];
  }

  const getListItemProperty = (item) => {
    switch (item.product_option_type) {
      case "SingleSupplement":
        return { name: t("summary.singleSupplementExtraPrice"), class: "additional" };
      case "DropoffAdjustment":
        return {
          name: dropOffLocation ? t("summary.dropOff", { dropOffLocation }) : t("summary.dropOffExtraPrice"),
          class: "additional",
        };
      case "ProductExtra":
        return {
          name: item.display_name,
          class: "additional",
        };
      default:
        return item.display_name
          ? { name: item.display_name }
          : { name: t("summary.extraPrice"), class: "additional " };
    }
  };

  const getActivityLineItems = () => {
    const lineItems = price?.product_items?.[0]?.line_items || [];

    return flow(
      filterActivityProductItems,
      (arr) => {
        return arr.map((el) => ({
          ...el,
          display_name: el.display_name ? el.display_name.trim() : "",
        }));
      },
      (arr) => orderBy(arr, "display_name", "asc"),
      sortByPriceBand,
      (arr) => stringifyLineItems(arr, getListItemProperty),
    )(lineItems);
  };

  return getActivityLineItems();
};

export default useActivitySummaryInfo;
