export const toBinaryStr = (str) => {
  const encoder = new TextEncoder();
  const charCodes = encoder.encode(str);

  return String.fromCharCode(...charCodes);
};

export const base64ToBytes = (base64) => {
  const binString = atob(base64);

  return Uint8Array.from(binString, (m) => m.codePointAt(0));
};

export const bytesToBase64 = (bytes) => {
  const binString = String.fromCodePoint(...bytes);

  return btoa(binString);
};
