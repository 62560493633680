import { rest } from "msw";

export const orders = {
  TY03237960: {
    order: {
      number: "TY03237960",
      adults: null,
      available_states: ["checkout", "extras", "customer", "payment", "passport", "completed", "confirmed"],
      build_type: "tour",
      children: null,
      custom_package_info_id: null,
      customer: {
        id: 122940,
        citizenship: null,
        email: null,
        first_name: null,
        last_name: null,
        notes: "",
        phone: null,
      },
      discount_code: null,
      discountable: false,
      id: 123155,
      meal_plan: null,
      package_cities_to_visit: null,
      package_end_city_id: null,
      package_end_date: "2022-02-27",
      package_end_location_id: null,
      package_id: null,
      package_start_city_id: null,
      package_start_date: "2022-02-26",
      package_start_location_id: null,
      package_start_location_type: null,
      pax_quantity: 1,
      product_items: [
        {
          id: 173364,
          city_id: 40,
          display_name: "UA Test tour (Kyiv)",
          dropoff_city_id: null,
          dropoff_point: {
            id: 8731,
            city_id: 40,
            city_name: "Odesa (Test)",
            country_code: "UA",
            name: "Hotel Continental (test)",
            raw_time_points: ["19:15"],
          },
          end_at: "2022-02-27T17:11:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969456,
              display_name: "Extra accommodation",
              name: "Test Extra accommodation",
              product_option_id: 1846,
              quantity: 0,
            },
            {
              id: 1969458,
              display_name: "Guide",
              name: "Guide (test)",
              product_option_id: 2321,
              quantity: 0,
            },
            {
              id: 1969457,
              display_name: "Test Extra transportation",
              name: "Test Extra transportation",
              product_option_id: 1847,
              quantity: 0,
            },
            {
              id: 1969459,
              display_name: "Vegan lunch",
              name: "Vegan lunch (Test)",
              product_option_id: 2333,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 1,
          pickup_point: {
            id: 4732,
            city_id: 40,
            city_name: "Odesa (Test)",
            country_code: "UA",
            name: "Hotel Continental (test)",
            raw_time_points: [],
          },
          price_band_line_items: [
            {
              id: 1969453,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 7846,
              quantity: 1,
            },
            {
              id: 1969450,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 3072,
              quantity: 0,
            },
            {
              id: 1969449,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 7493,
              quantity: 0,
            },
            {
              id: 1969447,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 2311,
              quantity: 0,
            },
            {
              id: 1969452,
              display_name: "Children",
              name: "Children",
              product_option_id: 2312,
              quantity: 0,
            },
            {
              id: 1969451,
              display_name: "Children",
              name: "Children",
              product_option_id: 7990,
              quantity: 0,
            },
            {
              id: 1969446,
              display_name: "Override",
              name: "Override",
              product_option_id: 4932,
              quantity: 0,
            },
            {
              id: 1969448,
              display_name: "Students",
              name: "Students",
              product_option_id: 7991,
              quantity: 0,
            },
          ],
          product_id: 500,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-02-26T11:11:00.000Z",
          start_from: "Київ, Україна, 02000",
          state: "unconfirmed",
        },
      ],
      rooms: null,
      source_link: "http://localhost:3000/?pid=500",
      state: "customer",
      terms_and_conditions_url: "https://touringplatform.com/terms_and_conditions/4",
      product_ids: null,
    },
  },
  TY02868710: {
    order: {
      number: "TY02868710",
      adults: null,
      available_states: ["checkout", "customer", "payment", "completed", "confirmed"],
      build_type: "hotel",
      children: null,
      custom_package_info_id: null,
      customer: {
        id: 122941,
        citizenship: null,
        email: null,
        first_name: null,
        last_name: null,
        notes: "",
        phone: null,
      },
      discount_code: null,
      discountable: false,
      id: 123156,
      meal_plan: null,
      package_cities_to_visit: null,
      package_end_city_id: null,
      package_end_date: "2022-08-27",
      package_end_location_id: null,
      package_id: null,
      package_start_city_id: null,
      package_start_date: "2022-08-25",
      package_start_location_id: null,
      package_start_location_type: null,
      pax_quantity: 2,
      product_items: [
        {
          id: 173365,
          city_id: 123,
          custom_pickup_lat: null,
          custom_pickup_lon: null,
          custom_pickup_point: null,
          display_name: "Radisson Blu Resort, Bukovel",
          dropoff_city_id: null,
          dropoff_location_lat: null,
          dropoff_location_lon: null,
          dropoff_location_name: null,
          dropoff_point: null,
          end_at: "2022-08-27T12:00:00.000Z",
          expire_at: 1644778509369,
          extra_line_items: [],
          feedback: null,
          flight_number: null,
          hotel_line_items: [
            {
              id: 1969460,
              cancellation: "Free cancellation until 22 August at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-08-22",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "350.0",
              product_item_id: 173365,
              product_option_id: null,
              quantity: 1,
              room_name: "Superior Room",
              rph: "2",
            },
          ],
          klass: "hotel",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: null,
          price_band_line_items: [],
          product_id: 727,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-25T15:00:00.000Z",
          start_from: null,
          state: "confirmed",
        },
      ],
      rooms: null,
      source_link: "http://localhost:3000/?pid=727",
      state: "customer",
      terms_and_conditions_url: "https://touringplatform.com/terms_and_conditions/4",
      product_ids: null,
    },
  },
  TY19268720: {
    order: {
      number: "TY19268720",
      adults: 2,
      available_states: ["checkout", "customer", "payment", "passport", "completed", "confirmed"],
      build_type: "smart_package",
      children: 0,
      custom_package_info_id: null,
      customer: {
        id: 122942,
        citizenship: null,
        email: null,
        first_name: null,
        last_name: null,
        notes: "",
        phone: null,
      },
      discount_code: null,
      discountable: false,
      id: 123157,
      meal_plan: "Room Only",
      package_cities_to_visit: null,
      package_end_city_id: null,
      package_end_date: "2022-08-30",
      package_end_location_id: null,
      package_id: 11,
      package_start_city_id: "1",
      package_start_date: "2022-08-26",
      package_start_location_id: null,
      package_start_location_type: null,
      pax_quantity: 2,
      product_items: [
        {
          id: 173366,
          city_id: 1,
          display_name: "Masada, Ein Gedi, and Dead Sea Tour from Tel Aviv & Jerusalem ",
          dropoff_city_id: 1,
          dropoff_point: {
            id: 7296,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["19:45"],
          },
          end_at: "2022-08-26T19:45:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969477,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 144,
              quantity: 0,
            },
            {
              id: 1969476,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 143,
              quantity: 0,
            },
            {
              id: 1969478,
              display_name: "Guiding in Spanish (only available Sunday and Thursday from March 1st) ",
              name: "Guiding in Spanish (only available Sunday and Thursday, from March 1st) ",
              product_option_id: 1817,
              quantity: 0,
            },
            {
              id: 1969480,
              display_name: "Small Group ",
              name: "Masada, Ein Gedi, and Dead Sea Tour from Jerusalem - Small group supplement",
              product_option_id: 2325,
              quantity: 0,
            },
            {
              id: 1969479,
              display_name: "Small Group ",
              name: "Masada, Ein Gedi, and Dead Sea Tour from Tel Aviv - Small group supplement ",
              product_option_id: 2324,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 2,
          pickup_point: {
            id: 817,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["07:45"],
          },
          price_band_line_items: [
            {
              id: 1969472,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 224,
              quantity: 2,
            },
            {
              id: 1969470,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 223,
              quantity: 0,
            },
            {
              id: 1969463,
              display_name: "Black out ",
              name: "Black out ",
              product_option_id: 7242,
              quantity: 0,
            },
            {
              id: 1969469,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 227,
              quantity: 0,
            },
            {
              id: 1969468,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 228,
              quantity: 0,
            },
            {
              id: 1969466,
              display_name: "Small Group ",
              name: "Small Group ",
              product_option_id: 6367,
              quantity: 0,
            },
            {
              id: 1969465,
              display_name: "Small Group ",
              name: "Small Group ",
              product_option_id: 6366,
              quantity: 0,
            },
            {
              id: 1969471,
              display_name: "Students",
              name: "Students",
              product_option_id: 226,
              quantity: 0,
            },
            {
              id: 1969467,
              display_name: "Students",
              name: "Students",
              product_option_id: 225,
              quantity: 0,
            },
            {
              id: 1969473,
              display_name: "Xmas special",
              name: "Xmas special",
              product_option_id: 2134,
              quantity: 0,
            },
            {
              id: 1969462,
              display_name: "Yom Kippur 2021",
              name: "Yom Kippur 2021",
              product_option_id: 4853,
              quantity: 0,
            },
            {
              id: 1969464,
              display_name: "Yom Kippur 2022",
              name: "Yom Kippur 2022",
              product_option_id: 4854,
              quantity: 0,
            },
          ],
          product_id: 39,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-26T07:45:00.000Z",
          start_from: "2 PINES STREET, 2A ,9128002, JERUSALEM, ISRAEL",
          state: "unconfirmed",
        },
        {
          id: 173371,
          city_id: 1,
          custom_pickup_lat: null,
          custom_pickup_lon: null,
          custom_pickup_point: null,
          display_name: "L1 Prima Palace, Jerusalem",
          dropoff_city_id: null,
          dropoff_location_lat: null,
          dropoff_location_lon: null,
          dropoff_location_name: null,
          dropoff_point: null,
          end_at: "2022-08-29T12:00:00.000Z",
          expire_at: 1644779916684,
          extra_line_items: [],
          feedback: null,
          flight_number: null,
          hotel_line_items: [
            {
              id: 1969559,
              cancellation: "Free cancellation until 22 August at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-08-22",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "529.0",
              product_item_id: 173371,
              product_option_id: null,
              quantity: 1,
              room_name: "Double or Twin COMFORT",
              rph: "2",
            },
          ],
          klass: "hotel",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: null,
          price_band_line_items: [],
          product_id: 2453,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-26T15:00:00.000Z",
          start_from: null,
          state: "confirmed",
        },
        {
          id: 173367,
          city_id: 1,
          display_name: "Bethlehem, Jericho and Jordan River Tour",
          dropoff_city_id: 1,
          dropoff_point: {
            id: 7272,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["17:45"],
          },
          end_at: "2022-08-27T19:45:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969500,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 138,
              quantity: 0,
            },
            {
              id: 1969499,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 137,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: {
            id: 809,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["07:45"],
          },
          price_band_line_items: [
            {
              id: 1969488,
              display_name: "Adult ",
              name: "Adult ",
              product_option_id: 7682,
              quantity: 0,
            },
            {
              id: 1969495,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 166,
              quantity: 0,
            },
            {
              id: 1969489,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7681,
              quantity: 0,
            },
            {
              id: 1969485,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 167,
              quantity: 2,
            },
            {
              id: 1969482,
              display_name: "Black out ",
              name: "Black out ",
              product_option_id: 7252,
              quantity: 0,
            },
            {
              id: 1969496,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 171,
              quantity: 0,
            },
            {
              id: 1969491,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 7686,
              quantity: 0,
            },
            {
              id: 1969490,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 7685,
              quantity: 0,
            },
            {
              id: 1969486,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 170,
              quantity: 0,
            },
            {
              id: 1969494,
              display_name: "Students",
              name: "Students",
              product_option_id: 7684,
              quantity: 0,
            },
            {
              id: 1969492,
              display_name: "Students",
              name: "Students",
              product_option_id: 7683,
              quantity: 0,
            },
            {
              id: 1969487,
              display_name: "Students",
              name: "Students",
              product_option_id: 168,
              quantity: 0,
            },
            {
              id: 1969484,
              display_name: "Students",
              name: "Students",
              product_option_id: 169,
              quantity: 0,
            },
            {
              id: 1969493,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2135,
              quantity: 0,
            },
            {
              id: 1969481,
              display_name: "Yom Kippur 2021",
              name: "Yom Kippur 2021",
              product_option_id: 4841,
              quantity: 0,
            },
            {
              id: 1969483,
              display_name: "Yom Kippur 2022",
              name: "Yom Kippur 2022",
              product_option_id: 4842,
              quantity: 0,
            },
          ],
          product_id: 35,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-27T07:45:00.000Z",
          start_from: "2 PINES STREET, 2A ,9128002, JERUSALEM, ISRAEL",
          state: "unconfirmed",
        },
        {
          id: 173368,
          city_id: 1,
          display_name: "Jerusalem Day Tour !",
          dropoff_city_id: 1,
          dropoff_point: {
            id: 8399,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["17:45"],
          },
          end_at: "2022-08-28T17:45:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969514,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 1070,
              quantity: 0,
            },
            {
              id: 1969515,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 1071,
              quantity: 0,
            },
            {
              id: 1969516,
              display_name: "Guiding in Spanish (only available Sunday and Thursday from March 1st) ",
              name: "Guiding in Spanish (only available Sunday and Thursday, from March 1st) ",
              product_option_id: 1816,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 2,
          pickup_point: {
            id: 4370,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["07:45"],
          },
          price_band_line_items: [
            {
              id: 1969508,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 1986,
              quantity: 2,
            },
            {
              id: 1969507,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 1987,
              quantity: 0,
            },
            {
              id: 1969501,
              display_name: "Black out ",
              name: "Black out ",
              product_option_id: 7249,
              quantity: 0,
            },
            {
              id: 1969503,
              display_name: "Blackout",
              name: "Blackout",
              product_option_id: 7948,
              quantity: 0,
            },
            {
              id: 1969511,
              display_name: "Children",
              name: "Children",
              product_option_id: 2025,
              quantity: 0,
            },
            {
              id: 1969510,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 2026,
              quantity: 0,
            },
            {
              id: 1969509,
              display_name: "Students",
              name: "Students",
              product_option_id: 2024,
              quantity: 0,
            },
            {
              id: 1969506,
              display_name: "Students",
              name: "Students",
              product_option_id: 2023,
              quantity: 0,
            },
            {
              id: 1969505,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2140,
              quantity: 0,
            },
            {
              id: 1969502,
              display_name: "Yom Kippur 2021",
              name: "Yom Kippur 2021",
              product_option_id: 4681,
              quantity: 0,
            },
            {
              id: 1969504,
              display_name: "Yom Kippur 2022",
              name: "Yom Kippur 2022",
              product_option_id: 4682,
              quantity: 0,
            },
          ],
          product_id: 240,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-28T07:45:00.000Z",
          start_from: "2 PINES STREET, 2A ,9128002, JERUSALEM, ISRAEL",
          state: "confirmed",
        },
        {
          id: 173369,
          city_id: 1,
          display_name: "Galilee, Nazareth and More Tour",
          dropoff_city_id: 2,
          dropoff_point: {
            id: 8472,
            city_id: 2,
            city_name: "Tel Aviv",
            country_code: "IL",
            name: "Textile Building (2 Kaufmann Street), Tel Aviv",
            raw_time_points: ["18:30"],
          },
          end_at: "2022-08-29T17:50:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969539,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 1082,
              quantity: 0,
            },
            {
              id: 1969540,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 1083,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 2,
          pickup_point: {
            id: 4448,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["05:50"],
          },
          price_band_line_items: [
            {
              id: 1969535,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7687,
              quantity: 0,
            },
            {
              id: 1969530,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7667,
              quantity: 0,
            },
            {
              id: 1969529,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7666,
              quantity: 2,
            },
            {
              id: 1969524,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2003,
              quantity: 0,
            },
            {
              id: 1969523,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2002,
              quantity: 0,
            },
            {
              id: 1969518,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7688,
              quantity: 0,
            },
            {
              id: 1969532,
              display_name: "Children",
              name: "Children",
              product_option_id: 7669,
              quantity: 0,
            },
            {
              id: 1969531,
              display_name: "Children",
              name: "Children",
              product_option_id: 7668,
              quantity: 0,
            },
            {
              id: 1969527,
              display_name: "Children",
              name: "Children",
              product_option_id: 2034,
              quantity: 0,
            },
            {
              id: 1969525,
              display_name: "Children",
              name: "Children",
              product_option_id: 2033,
              quantity: 0,
            },
            {
              id: 1969521,
              display_name: "Children",
              name: "Children",
              product_option_id: 7692,
              quantity: 0,
            },
            {
              id: 1969520,
              display_name: "Children",
              name: "Children",
              product_option_id: 7691,
              quantity: 0,
            },
            {
              id: 1969517,
              display_name: "Kippur 2022",
              name: "Kippur 2022",
              product_option_id: 7665,
              quantity: 0,
            },
            {
              id: 1969536,
              display_name: "Students",
              name: "Students",
              product_option_id: 7689,
              quantity: 0,
            },
            {
              id: 1969534,
              display_name: "Students",
              name: "Students",
              product_option_id: 7671,
              quantity: 0,
            },
            {
              id: 1969533,
              display_name: "Students",
              name: "Students",
              product_option_id: 7670,
              quantity: 0,
            },
            {
              id: 1969526,
              display_name: "Students",
              name: "Students",
              product_option_id: 2031,
              quantity: 0,
            },
            {
              id: 1969522,
              display_name: "Students",
              name: "Students",
              product_option_id: 7690,
              quantity: 0,
            },
            {
              id: 1969519,
              display_name: "Students",
              name: "Students",
              product_option_id: 2032,
              quantity: 0,
            },
            {
              id: 1969528,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2143,
              quantity: 0,
            },
          ],
          product_id: 249,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-29T05:50:00.000Z",
          start_from: "2 PINES STREET, 2A ,9128002, JERUSALEM, ISRAEL",
          state: "unconfirmed",
        },
        {
          id: 173372,
          city_id: 2,
          custom_pickup_lat: null,
          custom_pickup_lon: null,
          custom_pickup_point: null,
          display_name: "Artist Hotel Tel Aviv - an Atlas Boutique Hotel, Tel Aviv",
          dropoff_city_id: null,
          dropoff_location_lat: null,
          dropoff_location_lon: null,
          dropoff_location_name: null,
          dropoff_point: null,
          end_at: "2022-08-30T12:00:00.000Z",
          expire_at: 1644779916736,
          extra_line_items: [],
          feedback: null,
          flight_number: null,
          hotel_line_items: [
            {
              id: 1969560,
              cancellation: "Free cancellation until 27 August at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-08-27",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "178.0",
              product_item_id: 173372,
              product_option_id: null,
              quantity: 1,
              room_name: "Small Double Room",
              rph: "2",
            },
          ],
          klass: "hotel",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: null,
          price_band_line_items: [],
          product_id: 812,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-29T15:00:00.000Z",
          start_from: null,
          state: "confirmed",
        },
        {
          id: 173370,
          city_id: 2,
          display_name: "Caesarea, Haifa  and Akko Tour",
          dropoff_city_id: 2,
          dropoff_point: {
            id: 8482,
            city_id: 2,
            city_name: "Tel Aviv",
            country_code: "IL",
            name: "Textile Building (2 Kaufmann Street), Tel Aviv",
            raw_time_points: ["18:00"],
          },
          end_at: "2022-08-30T19:15:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969557,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 1137,
              quantity: 0,
            },
            {
              id: 1969558,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 1138,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: {
            id: 4456,
            city_id: 2,
            city_name: "Tel Aviv",
            country_code: "IL",
            name: "Textile Building (2 Kaufmann Street), Tel Aviv",
            raw_time_points: ["07:15"],
          },
          price_band_line_items: [
            {
              id: 1969553,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2018,
              quantity: 0,
            },
            {
              id: 1969548,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7673,
              quantity: 2,
            },
            {
              id: 1969547,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7672,
              quantity: 0,
            },
            {
              id: 1969542,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2017,
              quantity: 0,
            },
            {
              id: 1969550,
              display_name: "Children",
              name: "Children",
              product_option_id: 7675,
              quantity: 0,
            },
            {
              id: 1969549,
              display_name: "Children",
              name: "Children",
              product_option_id: 7674,
              quantity: 0,
            },
            {
              id: 1969545,
              display_name: "Children",
              name: "Children",
              product_option_id: 2021,
              quantity: 0,
            },
            {
              id: 1969543,
              display_name: "Children",
              name: "Children",
              product_option_id: 2022,
              quantity: 0,
            },
            {
              id: 1969541,
              display_name: "Kippur 2022",
              name: "Kippur 2022",
              product_option_id: 7664,
              quantity: 0,
            },
            {
              id: 1969554,
              display_name: "Students",
              name: "Students",
              product_option_id: 2019,
              quantity: 0,
            },
            {
              id: 1969552,
              display_name: "Students",
              name: "Students",
              product_option_id: 7677,
              quantity: 0,
            },
            {
              id: 1969551,
              display_name: "Students",
              name: "Students",
              product_option_id: 7676,
              quantity: 0,
            },
            {
              id: 1969546,
              display_name: "Students",
              name: "Students",
              product_option_id: 2020,
              quantity: 0,
            },
            {
              id: 1969544,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2050,
              quantity: 0,
            },
          ],
          product_id: 254,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-30T07:15:00.000Z",
          start_from: "BEN YEHUDA STREET, 35 ,63807, TEL AVIV, ISRAEL",
          state: "confirmed",
        },
      ],
      rooms: {
        0: {
          id: "fe422a50-c397-46e9-9cc3-17277e593ce2",
          adults: 2,
        },
      },
      source_link: "http://localhost:3000/?pid=PK00011",
      state: "customer",
      terms_and_conditions_url: "https://touringplatform.com/terms_and_conditions/4",
      product_ids: null,
    },
  },
  // hotel > pid 727 > completed
  TY58026842: {
    order: {
      number: "TY58026842",
      adults: null,
      available_states: ["checkout", "customer", "payment", "completed", "confirmed"],
      build_type: "hotel",
      children: null,
      custom_package_info_id: null,
      customer: {
        id: 122944,
        citizenship: "UA",
        email: "protsyshyn.taras@coaxsoft.com",
        first_name: "Taras",
        last_name: "Protsyshyn",
        notes: "",
        phone: "+380676767676",
      },
      discount_code: null,
      discountable: true,
      id: 123159,
      meal_plan: null,
      package_cities_to_visit: null,
      package_end_city_id: null,
      package_end_date: "2022-08-27",
      package_end_location_id: null,
      package_id: null,
      package_start_city_id: null,
      package_start_date: "2022-08-25",
      package_start_location_id: null,
      package_start_location_type: null,
      pax_quantity: 2,
      product_items: [
        {
          id: 173374,
          city_id: 123,
          custom_pickup_lat: null,
          custom_pickup_lon: null,
          custom_pickup_point: null,
          display_name: "Radisson Blu Resort, Bukovel",
          dropoff_city_id: null,
          dropoff_location_lat: null,
          dropoff_location_lon: null,
          dropoff_location_name: null,
          dropoff_point: null,
          end_at: "2022-08-27T12:00:00.000Z",
          expire_at: 1644825793443,
          extra_line_items: [],
          feedback: null,
          flight_number: null,
          hotel_line_items: [
            {
              id: 1969575,
              cancellation: "Free cancellation until 22 August at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-08-22",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "350.0",
              product_item_id: 173374,
              product_option_id: null,
              quantity: 1,
              room_name: "Superior Room",
              rph: "2",
            },
            {
              id: 1969576,
              cancellation: "Free cancellation until 22 August at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-08-22",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "350.0",
              product_item_id: 173374,
              product_option_id: null,
              quantity: 1,
              room_name: "Superior Room",
              rph: "2",
            },
            {
              id: 1969577,
              cancellation: "Free cancellation until 22 August at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-08-22",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "350.0",
              product_item_id: 173374,
              product_option_id: null,
              quantity: 1,
              room_name: "Superior Room",
              rph: "2",
            },
            {
              id: 1969578,
              cancellation: "Free cancellation until 22 August at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-08-22",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "350.0",
              product_item_id: 173374,
              product_option_id: null,
              quantity: 1,
              room_name: "Superior Room",
              rph: "2",
            },
          ],
          klass: "hotel",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 8,
          pickup_point: null,
          price_band_line_items: [],
          product_id: 727,
          refreshed_id: 727,
          refreshed_name: "Radisson Blu Resort, Bukovel",
          refreshed_price: "1400.0",
          start_at: "2022-08-25T15:00:00.000Z",
          start_from: null,
          state: "confirmed",
        },
      ],
      rooms: null,
      source_link: "http://localhost:3000/?pid=727",
      state: "completed",
      terms_and_conditions_url: "https://touringplatform.com/terms_and_conditions/4",
      product_ids: null,
    },
  },
  // tour > pid 500 > passport
  TY84872207: {
    order: {
      number: "TY84872207",
      adults: null,
      available_states: ["checkout", "extras", "customer", "passport", "completed", "confirmed"],
      build_type: "tour",
      children: null,
      custom_package_info_id: null,
      customer: {
        id: 122947,
        citizenship: "UA",
        email: "protsyshyn.taras@coaxsoft.com",
        first_name: "Taras",
        last_name: "Protsyshyn",
        notes: "",
        phone: "+380676767676",
      },
      discount_code: null,
      discountable: true,
      id: 123162,
      meal_plan: null,
      package_cities_to_visit: null,
      package_end_city_id: null,
      package_end_date: "2022-08-29",
      package_end_location_id: null,
      package_id: null,
      package_start_city_id: null,
      package_start_date: "2022-08-28",
      package_start_location_id: null,
      package_start_location_type: null,
      pax_quantity: 1,
      product_items: [
        {
          id: 173382,
          city_id: 40,
          display_name: "UA Test tour (Kyiv)",
          dropoff_city_id: null,
          dropoff_point: {
            id: 8731,
            city_id: 40,
            city_name: "Odesa (Test)",
            country_code: "UA",
            name: "Hotel Continental (test)",
            raw_time_points: ["19:15"],
          },
          end_at: "2022-08-29T17:11:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969658,
              display_name: "Extra accommodation",
              name: "Test Extra accommodation",
              product_option_id: 1846,
              quantity: 0,
            },
            {
              id: 1969660,
              display_name: "Guide",
              name: "Guide (test)",
              product_option_id: 2321,
              quantity: 1,
            },
            {
              id: 1969659,
              display_name: "Test Extra transportation",
              name: "Test Extra transportation",
              product_option_id: 1847,
              quantity: 0,
            },
            {
              id: 1969661,
              display_name: "Vegan lunch",
              name: "Vegan lunch (Test)",
              product_option_id: 2333,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 1,
          pickup_point: {
            id: 4732,
            city_id: 40,
            city_name: "Odesa (Test)",
            country_code: "UA",
            name: "Hotel Continental (test)",
            raw_time_points: [],
          },
          price_band_line_items: [
            {
              id: 1969655,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 7846,
              quantity: 0,
            },
            {
              id: 1969652,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 3072,
              quantity: 0,
            },
            {
              id: 1969651,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 7493,
              quantity: 1,
            },
            {
              id: 1969649,
              display_name: "Adult",
              name: "Adult",
              product_option_id: 2311,
              quantity: 0,
            },
            {
              id: 1969654,
              display_name: "Children",
              name: "Children",
              product_option_id: 2312,
              quantity: 0,
            },
            {
              id: 1969653,
              display_name: "Children",
              name: "Children",
              product_option_id: 7990,
              quantity: 0,
            },
            {
              id: 1969648,
              display_name: "Override",
              name: "Override",
              product_option_id: 4932,
              quantity: 0,
            },
            {
              id: 1969650,
              display_name: "Students",
              name: "Students",
              product_option_id: 7991,
              quantity: 0,
            },
          ],
          product_id: 500,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-08-28T11:11:00.000Z",
          start_from: "Україна",
          state: "unconfirmed",
        },
      ],
      rooms: null,
      source_link: "http://localhost:3000/?pid=500",
      state: "passport",
      terms_and_conditions_url: "https://touringplatform.com/terms_and_conditions/4",
      product_ids: null,
    },
  },
  // custom package > pid PK000011 > passport
  TY75259782: {
    order: {
      number: "TY75259782",
      adults: 2,
      available_states: ["checkout", "customer", "passport", "completed", "confirmed"],
      build_type: "smart_package",
      children: 0,
      custom_package_info_id: null,
      customer: {
        id: 122950,
        citizenship: "UA",
        email: "protsyshyn.taras@coaxsoft.com",
        first_name: "Taras",
        last_name: "Protsyshyn",
        notes: "",
        phone: "+380676767676",
      },
      discount_code: null,
      discountable: true,
      id: 123165,
      meal_plan: "Room Only",
      package_cities_to_visit: null,
      package_end_city_id: null,
      package_end_date: "2022-06-28",
      package_end_location_id: null,
      package_id: 11,
      package_start_city_id: "1",
      package_start_date: "2022-06-24",
      package_start_location_id: null,
      package_start_location_type: null,
      pax_quantity: 2,
      product_items: [
        {
          id: 173397,
          city_id: 1,
          display_name: "Masada, Ein Gedi, and Dead Sea Tour from Tel Aviv & Jerusalem ",
          dropoff_city_id: 1,
          dropoff_point: {
            id: 7296,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["19:45"],
          },
          end_at: "2022-06-24T19:45:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969875,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 144,
              quantity: 0,
            },
            {
              id: 1969874,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 143,
              quantity: 0,
            },
            {
              id: 1969876,
              display_name: "Guiding in Spanish (only available Sunday and Thursday from March 1st) ",
              name: "Guiding in Spanish (only available Sunday and Thursday, from March 1st) ",
              product_option_id: 1817,
              quantity: 0,
            },
            {
              id: 1969878,
              display_name: "Small Group ",
              name: "Masada, Ein Gedi, and Dead Sea Tour from Jerusalem - Small group supplement",
              product_option_id: 2325,
              quantity: 0,
            },
            {
              id: 1969877,
              display_name: "Small Group ",
              name: "Masada, Ein Gedi, and Dead Sea Tour from Tel Aviv - Small group supplement ",
              product_option_id: 2324,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 2,
          pickup_point: {
            id: 817,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["07:45"],
          },
          price_band_line_items: [
            {
              id: 1969870,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 224,
              quantity: 2,
            },
            {
              id: 1969868,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 223,
              quantity: 0,
            },
            {
              id: 1969861,
              display_name: "Black out ",
              name: "Black out ",
              product_option_id: 7242,
              quantity: 0,
            },
            {
              id: 1969867,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 227,
              quantity: 0,
            },
            {
              id: 1969866,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 228,
              quantity: 0,
            },
            {
              id: 1969864,
              display_name: "Small Group ",
              name: "Small Group ",
              product_option_id: 6367,
              quantity: 0,
            },
            {
              id: 1969863,
              display_name: "Small Group ",
              name: "Small Group ",
              product_option_id: 6366,
              quantity: 0,
            },
            {
              id: 1969869,
              display_name: "Students",
              name: "Students",
              product_option_id: 226,
              quantity: 0,
            },
            {
              id: 1969865,
              display_name: "Students",
              name: "Students",
              product_option_id: 225,
              quantity: 0,
            },
            {
              id: 1969871,
              display_name: "Xmas special",
              name: "Xmas special",
              product_option_id: 2134,
              quantity: 0,
            },
            {
              id: 1969860,
              display_name: "Yom Kippur 2021",
              name: "Yom Kippur 2021",
              product_option_id: 4853,
              quantity: 0,
            },
            {
              id: 1969862,
              display_name: "Yom Kippur 2022",
              name: "Yom Kippur 2022",
              product_option_id: 4854,
              quantity: 0,
            },
          ],
          product_id: 39,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-06-24T07:45:00.000Z",
          start_from: "7 Horkanos Street, Mount of Olives JERUSALEM, ISRAEL",
          state: "unconfirmed",
        },
        {
          id: 173402,
          city_id: 1,
          custom_pickup_lat: null,
          custom_pickup_lon: null,
          custom_pickup_point: null,
          display_name: "L1 Jerusalem Inn Hotel",
          dropoff_city_id: null,
          dropoff_location_lat: null,
          dropoff_location_lon: null,
          dropoff_location_name: null,
          dropoff_point: null,
          end_at: "2022-06-27T12:00:00.000Z",
          expire_at: 1644833256892,
          extra_line_items: [],
          feedback: null,
          flight_number: null,
          hotel_line_items: [
            {
              id: 1969957,
              cancellation: "Free cancellation until 20 June at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-06-20",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "468.0",
              product_item_id: 173402,
              product_option_id: null,
              quantity: 1,
              room_name: "Double or Twin STANDARD",
              rph: "2",
            },
          ],
          klass: "hotel",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: null,
          price_band_line_items: [],
          product_id: 3255,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-06-24T15:00:00.000Z",
          start_from: null,
          state: "confirmed",
        },
        {
          id: 173398,
          city_id: 1,
          display_name: "Bethlehem, Jericho and Jordan River Tour",
          dropoff_city_id: 1,
          dropoff_point: {
            id: 7272,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["17:45"],
          },
          end_at: "2022-06-25T19:45:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969898,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 138,
              quantity: 0,
            },
            {
              id: 1969897,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 137,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: {
            id: 809,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["07:45"],
          },
          price_band_line_items: [
            {
              id: 1969886,
              display_name: "Adult ",
              name: "Adult ",
              product_option_id: 7682,
              quantity: 0,
            },
            {
              id: 1969893,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 166,
              quantity: 0,
            },
            {
              id: 1969887,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7681,
              quantity: 0,
            },
            {
              id: 1969883,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 167,
              quantity: 2,
            },
            {
              id: 1969880,
              display_name: "Black out ",
              name: "Black out ",
              product_option_id: 7252,
              quantity: 0,
            },
            {
              id: 1969894,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 171,
              quantity: 0,
            },
            {
              id: 1969889,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 7686,
              quantity: 0,
            },
            {
              id: 1969888,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 7685,
              quantity: 0,
            },
            {
              id: 1969884,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 170,
              quantity: 0,
            },
            {
              id: 1969892,
              display_name: "Students",
              name: "Students",
              product_option_id: 7684,
              quantity: 0,
            },
            {
              id: 1969890,
              display_name: "Students",
              name: "Students",
              product_option_id: 7683,
              quantity: 0,
            },
            {
              id: 1969885,
              display_name: "Students",
              name: "Students",
              product_option_id: 168,
              quantity: 0,
            },
            {
              id: 1969882,
              display_name: "Students",
              name: "Students",
              product_option_id: 169,
              quantity: 0,
            },
            {
              id: 1969891,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2135,
              quantity: 0,
            },
            {
              id: 1969879,
              display_name: "Yom Kippur 2021",
              name: "Yom Kippur 2021",
              product_option_id: 4841,
              quantity: 0,
            },
            {
              id: 1969881,
              display_name: "Yom Kippur 2022",
              name: "Yom Kippur 2022",
              product_option_id: 4842,
              quantity: 0,
            },
          ],
          product_id: 35,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-06-25T07:45:00.000Z",
          start_from: "7 Horkanos Street, Mount of Olives JERUSALEM, ISRAEL",
          state: "unconfirmed",
        },
        {
          id: 173399,
          city_id: 1,
          display_name: "Jerusalem Day Tour !",
          dropoff_city_id: 1,
          dropoff_point: {
            id: 8399,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["17:45"],
          },
          end_at: "2022-06-26T17:45:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969912,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 1070,
              quantity: 0,
            },
            {
              id: 1969913,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 1071,
              quantity: 0,
            },
            {
              id: 1969914,
              display_name: "Guiding in Spanish (only available Sunday and Thursday from March 1st) ",
              name: "Guiding in Spanish (only available Sunday and Thursday, from March 1st) ",
              product_option_id: 1816,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 2,
          pickup_point: {
            id: 4370,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["07:45"],
          },
          price_band_line_items: [
            {
              id: 1969906,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 1986,
              quantity: 2,
            },
            {
              id: 1969905,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 1987,
              quantity: 0,
            },
            {
              id: 1969899,
              display_name: "Black out ",
              name: "Black out ",
              product_option_id: 7249,
              quantity: 0,
            },
            {
              id: 1969901,
              display_name: "Blackout",
              name: "Blackout",
              product_option_id: 7948,
              quantity: 0,
            },
            {
              id: 1969909,
              display_name: "Children",
              name: "Children",
              product_option_id: 2025,
              quantity: 0,
            },
            {
              id: 1969908,
              display_name: "Children ",
              name: "Children ",
              product_option_id: 2026,
              quantity: 0,
            },
            {
              id: 1969907,
              display_name: "Students",
              name: "Students",
              product_option_id: 2024,
              quantity: 0,
            },
            {
              id: 1969904,
              display_name: "Students",
              name: "Students",
              product_option_id: 2023,
              quantity: 0,
            },
            {
              id: 1969903,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2140,
              quantity: 0,
            },
            {
              id: 1969900,
              display_name: "Yom Kippur 2021",
              name: "Yom Kippur 2021",
              product_option_id: 4681,
              quantity: 0,
            },
            {
              id: 1969902,
              display_name: "Yom Kippur 2022",
              name: "Yom Kippur 2022",
              product_option_id: 4682,
              quantity: 0,
            },
          ],
          product_id: 240,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-06-26T07:45:00.000Z",
          start_from: "7 Horkanos Street, Mount of Olives JERUSALEM, ISRAEL",
          state: "confirmed",
        },
        {
          id: 173400,
          city_id: 1,
          display_name: "Galilee, Nazareth and More Tour",
          dropoff_city_id: 2,
          dropoff_point: {
            id: 8472,
            city_id: 2,
            city_name: "Tel Aviv",
            country_code: "IL",
            name: "Textile Building (2 Kaufmann Street), Tel Aviv",
            raw_time_points: ["18:30"],
          },
          end_at: "2022-06-27T17:50:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969937,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 1082,
              quantity: 0,
            },
            {
              id: 1969938,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 1083,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: true,
          pax_quantity: 2,
          pickup_point: {
            id: 4448,
            city_id: 1,
            city_name: "Jerusalem",
            country_code: "IL",
            name: "David Citadel Hotel, Jerusalem",
            raw_time_points: ["05:50"],
          },
          price_band_line_items: [
            {
              id: 1969933,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7687,
              quantity: 0,
            },
            {
              id: 1969928,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7667,
              quantity: 0,
            },
            {
              id: 1969927,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7666,
              quantity: 2,
            },
            {
              id: 1969922,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2003,
              quantity: 0,
            },
            {
              id: 1969921,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2002,
              quantity: 0,
            },
            {
              id: 1969916,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7688,
              quantity: 0,
            },
            {
              id: 1969930,
              display_name: "Children",
              name: "Children",
              product_option_id: 7669,
              quantity: 0,
            },
            {
              id: 1969929,
              display_name: "Children",
              name: "Children",
              product_option_id: 7668,
              quantity: 0,
            },
            {
              id: 1969925,
              display_name: "Children",
              name: "Children",
              product_option_id: 2034,
              quantity: 0,
            },
            {
              id: 1969923,
              display_name: "Children",
              name: "Children",
              product_option_id: 2033,
              quantity: 0,
            },
            {
              id: 1969919,
              display_name: "Children",
              name: "Children",
              product_option_id: 7692,
              quantity: 0,
            },
            {
              id: 1969918,
              display_name: "Children",
              name: "Children",
              product_option_id: 7691,
              quantity: 0,
            },
            {
              id: 1969915,
              display_name: "Kippur 2022",
              name: "Kippur 2022",
              product_option_id: 7665,
              quantity: 0,
            },
            {
              id: 1969934,
              display_name: "Students",
              name: "Students",
              product_option_id: 7689,
              quantity: 0,
            },
            {
              id: 1969932,
              display_name: "Students",
              name: "Students",
              product_option_id: 7671,
              quantity: 0,
            },
            {
              id: 1969931,
              display_name: "Students",
              name: "Students",
              product_option_id: 7670,
              quantity: 0,
            },
            {
              id: 1969924,
              display_name: "Students",
              name: "Students",
              product_option_id: 2031,
              quantity: 0,
            },
            {
              id: 1969920,
              display_name: "Students",
              name: "Students",
              product_option_id: 7690,
              quantity: 0,
            },
            {
              id: 1969917,
              display_name: "Students",
              name: "Students",
              product_option_id: 2032,
              quantity: 0,
            },
            {
              id: 1969926,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2143,
              quantity: 0,
            },
          ],
          product_id: 249,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-06-27T05:50:00.000Z",
          start_from: "7 Horkanos Street, Mount of Olives JERUSALEM, ISRAEL",
          state: "unconfirmed",
        },
        {
          id: 173403,
          city_id: 2,
          custom_pickup_lat: null,
          custom_pickup_lon: null,
          custom_pickup_point: null,
          display_name: "Prima City Hotel,  Tel Aviv",
          dropoff_city_id: null,
          dropoff_location_lat: null,
          dropoff_location_lon: null,
          dropoff_location_name: null,
          dropoff_point: null,
          end_at: "2022-06-28T12:00:00.000Z",
          expire_at: 1644833256944,
          extra_line_items: [],
          feedback: null,
          flight_number: null,
          hotel_line_items: [
            {
              id: 1969958,
              cancellation: "Free cancellation until 25 June at 12:00PM, local time",
              capacity: "2 adults",
              deadline: "2022-06-25",
              display_name: null,
              meal_plan: "Room Only",
              name: null,
              price: "176.0",
              product_item_id: 173403,
              product_option_id: null,
              quantity: 1,
              room_name: "Double or Twin STANDARD",
              rph: "2",
            },
          ],
          klass: "hotel",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: null,
          price_band_line_items: [],
          product_id: 2518,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-06-27T15:00:00.000Z",
          start_from: null,
          state: "confirmed",
        },
        {
          id: 173401,
          city_id: 2,
          display_name: "Caesarea, Haifa  and Akko Tour",
          dropoff_city_id: 2,
          dropoff_point: {
            id: 8482,
            city_id: 2,
            city_name: "Tel Aviv",
            country_code: "IL",
            name: "Textile Building (2 Kaufmann Street), Tel Aviv",
            raw_time_points: ["18:00"],
          },
          end_at: "2022-06-28T19:15:00.000Z",
          expire_at: null,
          extra_line_items: [
            {
              id: 1969955,
              display_name: "Dropoff in Jerusalem",
              name: "IL TI - Dropoff in Jerusalem (starting in Tel Aviv) $15",
              product_option_id: 1137,
              quantity: 0,
            },
            {
              id: 1969956,
              display_name: "Dropoff in Tel Aviv (2, Kaufman St.)",
              name: "IL TI - Dropoff in Tel Aviv (start in Jerusalem) $15",
              product_option_id: 1138,
              quantity: 0,
            },
          ],
          feedback: null,
          flight_number: null,
          hotel_line_items: [],
          klass: "tour",
          language_code: "en",
          passport_infos: [],
          passport_required: false,
          pax_quantity: 2,
          pickup_point: {
            id: 4456,
            city_id: 2,
            city_name: "Tel Aviv",
            country_code: "IL",
            name: "Textile Building (2 Kaufmann Street), Tel Aviv",
            raw_time_points: ["07:15"],
          },
          price_band_line_items: [
            {
              id: 1969951,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2018,
              quantity: 0,
            },
            {
              id: 1969946,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7673,
              quantity: 2,
            },
            {
              id: 1969945,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 7672,
              quantity: 0,
            },
            {
              id: 1969940,
              display_name: "Adults",
              name: "Adults",
              product_option_id: 2017,
              quantity: 0,
            },
            {
              id: 1969948,
              display_name: "Children",
              name: "Children",
              product_option_id: 7675,
              quantity: 0,
            },
            {
              id: 1969947,
              display_name: "Children",
              name: "Children",
              product_option_id: 7674,
              quantity: 0,
            },
            {
              id: 1969943,
              display_name: "Children",
              name: "Children",
              product_option_id: 2021,
              quantity: 0,
            },
            {
              id: 1969941,
              display_name: "Children",
              name: "Children",
              product_option_id: 2022,
              quantity: 0,
            },
            {
              id: 1969939,
              display_name: "Kippur 2022",
              name: "Kippur 2022",
              product_option_id: 7664,
              quantity: 0,
            },
            {
              id: 1969952,
              display_name: "Students",
              name: "Students",
              product_option_id: 2019,
              quantity: 0,
            },
            {
              id: 1969950,
              display_name: "Students",
              name: "Students",
              product_option_id: 7677,
              quantity: 0,
            },
            {
              id: 1969949,
              display_name: "Students",
              name: "Students",
              product_option_id: 7676,
              quantity: 0,
            },
            {
              id: 1969944,
              display_name: "Students",
              name: "Students",
              product_option_id: 2020,
              quantity: 0,
            },
            {
              id: 1969942,
              display_name: "Xmas Special",
              name: "Xmas Special",
              product_option_id: 2050,
              quantity: 0,
            },
          ],
          product_id: 254,
          refreshed_id: null,
          refreshed_name: null,
          refreshed_price: null,
          start_at: "2022-06-28T07:15:00.000Z",
          start_from: "MAPU STREET, 9, Tel Aviv, ISRAEL",
          state: "confirmed",
        },
      ],
      rooms: {
        0: {
          id: "31dbf7ae-9690-416c-8e17-9c17ce467deb",
          adults: 2,
        },
      },
      source_link: "http://3.122.252.66/order?productId=PK00011",
      state: "passport",
      terms_and_conditions_url: "https://touringplatform.com/terms_and_conditions/4",
      product_ids: null,
    },
  },
};

export const orderHandlers = {
  getOrder: rest.get("/orders/:orderNumber", (req, res, ctx) => {
    const orderNumber = req.params.orderNumber;

    const order = orders[orderNumber];

    if (!order) {
      return res(
        ctx.status(404),
        ctx.json({
          errors: {
            messages: ["Product unavailable"],
          },
        }),
      );
    }

    return res(ctx.json(order));
  }),
};
