import * as fns from "date-fns";
import { de, it, es, he, fr } from "date-fns/locale";

const locales = { de, it, es, he, fr };

export const checkIfDate = (date) => {
  if (!date) return new Date();
  if (date instanceof Date) {
    return date;
  }

  return new Date(date);
};

export const convertDateToUTC = (data) => {
  const date = checkIfDate(data);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const checkIfUtc = (date, isUtc) => {
  return isUtc ? convertDateToUTC(date) : checkIfDate(date);
};

export const format = (date, form, isUtc = true, locale) => {
  const d = isUtc ? convertDateToUTC(date) : checkIfDate(date);

  return fns.format(d, form, { locale: locales[locale || window.__localeId__] });
};

export const formatForApi = (date, isUtc = true, locale) => {
  const d = isUtc ? convertDateToUTC(date) : checkIfDate(date);

  return fns.format(d, "yyyy-MM-dd", { locale: locales[locale || window.__localeId__] });
};

export const parse = (str, form) => {
  return fns.parse(str, form, new Date(), { locale: locales[window.__localeId__] });
};

export const getNumberOfDaysFromToday = (date) => {
  return date && fns.differenceInCalendarDays(checkIfDate(date), new Date());
};

export const getYear = (date, isUtc = true) => {
  return fns.getYear(isUtc ? convertDateToUTC(date) : checkIfDate(date));
};

export const sub = (date, settings, isUtc = true) => {
  return fns.sub(checkIfUtc(date, isUtc), settings);
};

export const set = (date, settings, isUtc = true) => {
  return fns.set(checkIfUtc(date, isUtc), settings);
};

export const setDay = (date, number, isUtc = true) => {
  return fns.setDay(checkIfUtc(date, isUtc), number, {
    locale: locales[window.__localeId__],
  });
};

export const add = (date, settings, isUtc = true) => {
  return fns.add(checkIfUtc(date, isUtc), settings);
};

export const isBefore = (date, dateToCompare, isUtc = true) => {
  return fns.isBefore(checkIfUtc(date, isUtc), checkIfUtc(dateToCompare, isUtc));
};

export const isAfter = (date, dateToCompare, isUtc = true) => {
  return fns.isAfter(checkIfUtc(date, isUtc), checkIfUtc(dateToCompare, isUtc));
};

export const isSameDay = (date, dateToCompare, isUtc = true) => {
  return fns.isSameDay(checkIfUtc(date, isUtc), checkIfUtc(dateToCompare, isUtc));
};

export const isBeforeOrSameDay = (date, dateToCompare, isUtc = true) => {
  return isBefore(date, dateToCompare, isUtc) || isSameDay(date, dateToCompare, isUtc);
};

export const isToday = (date, isUtc = true) => {
  return fns.isToday(checkIfUtc(date, isUtc));
};

export const isThisMonth = (date, isUtc = true) => {
  return fns.isThisMonth(checkIfUtc(date, isUtc));
};

export const isSameMonth = (date, dateToCompare, isUtc = true) => {
  return fns.isSameMonth(checkIfUtc(date, isUtc), checkIfUtc(dateToCompare, isUtc));
};

export const isSameYear = (date, dateToCompare, isUtc = true) => {
  return fns.isSameYear(checkIfUtc(date, isUtc), checkIfUtc(dateToCompare, isUtc));
};

export const isSameDecade = (date, dateToCompare) => {
  const yearLeft = Math.floor(fns.getYear(date) / 10) * 10;
  const yearRight = Math.floor(fns.getYear(dateToCompare) / 10) * 10;

  return yearLeft === yearRight;
};

export const isSameCentury = (date, dateToCompare) => {
  const yearLeft = Math.floor(fns.getYear(date) / 100) * 100;
  const yearRight = Math.floor(fns.getYear(dateToCompare) / 100) * 100;

  return yearLeft === yearRight;
};

export const isBetween = (date, leftTime, rightTime, strict = true, isUtc = true) => {
  if (strict) {
    return !isBefore(date, leftTime, isUtc) && !isAfter(date, rightTime, isUtc);
  }

  return (
    (!isBefore(date, leftTime, isUtc) && !isAfter(date, rightTime, isUtc)) ||
    isSameDay(date, rightTime, false) ||
    isSameDay(date, leftTime, false)
  );
};

export const endOfMonth = (date, isUtc = true) => {
  return fns.endOfMonth(checkIfUtc(date, isUtc));
};

export const startOfMonth = (date, isUtc = true) => {
  return fns.startOfMonth(checkIfUtc(date, isUtc));
};

export const lastDayOfMonth = (date, isUtc = true) => {
  return fns.lastDayOfMonth(checkIfUtc(date, isUtc));
};

export const setDate = (date, dayOfMonth, isUtc = true) => {
  return fns.setDate(checkIfUtc(date, isUtc), dayOfMonth);
};

export const getHours = (date, isUtc = true) => {
  return fns.getHours(checkIfUtc(date, isUtc));
};

export const getHHMM = (date, isUtc = true) => {
  return fns.format(checkIfUtc(date, isUtc), "HH:mm", { locale: locales[window.__localeId__] });
};

export const getMonth = (date, isUtc = true) => {
  return fns.getMonth(checkIfUtc(date, isUtc));
};

export const differenceInHours = (dateLeft, dateRight, isUtc = true) => {
  return fns.differenceInHours(checkIfUtc(dateLeft, isUtc), checkIfUtc(dateRight, isUtc));
};

export const differenceInDays = (dateLeft, dateRight, isUtc = true) => {
  return fns.differenceInDays(checkIfUtc(dateLeft, isUtc), checkIfUtc(dateRight, isUtc));
};

export const differenceInCalendarDays = (leftDate, rightDate, isUtc = true) => {
  return fns.differenceInCalendarDays(checkIfUtc(leftDate, isUtc), checkIfUtc(rightDate, isUtc));
};

export const differenceInMonths = (leftDate, rightDate, isUtc = true) => {
  return fns.differenceInMonths(checkIfUtc(leftDate, isUtc), checkIfUtc(rightDate, isUtc));
};

export const differenceInYears = (leftDate, rightDate, isUtc = true) => {
  return fns.differenceInYears(checkIfUtc(leftDate, isUtc), checkIfUtc(rightDate, isUtc));
};

export const isFuture = (date) => {
  return fns.isFuture(date);
};

export const startOfTomorrow = () => {
  return fns.startOfTomorrow();
};

export const parseDateFromApi = (str) => {
  return fns.parse(str, "yyyy-MM-dd", new Date());
};

export const isEqual = (date, dateToCompare) => {
  return fns.isEqual(date, dateToCompare);
};

/*
  Helper that works with api date format "yyyy-MM-dd" as well as new Date()
  and "yyyy-MM-ddT15:00:00.000Z"
  As result returns midnight (00:00) of date specified
  Originaly made for CP to remove hours from dates with different timezones,
  but takes place on hotels now as well
*/
export const cpDateTransformer = (date) => {
  const newDate = new Date(date);
  if (date.length === 10 || date.length === 24) {
    const dateParsed = date.slice(0, 10).split("-");

    return new Date(dateParsed[0], Number(dateParsed[1]) - 1, dateParsed[2], 0, 0, 0);
  }

  return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0, 0);
};

export const getDaysInMonth = (year, month) => {
  return new Date(year || new Date().getYear(), month || new Date().getMonth() + 1, 0).getDate();
};

export const countDatesWithMonth = (datesArray, targetDate) => {
  let count = 0;

  for (const date of datesArray) {
    if (isSameMonth(parse(date, "dd/MM/yyyy", false), targetDate, false)) {
      count++;
    }
  }

  return count;
};
