export * from "@tint_fe/redux-utils/src/order/orderActions";
import * as types from "./orderActionTypes";

export const createTourOrderRequest = (payload) => ({
  type: types.CREATE_TOUR_ORDER_REQUEST,
  payload: payload,
});

export const createActivityOrderRequest = (payload) => ({
  type: types.CREATE_ACTIVITY_ORDER_REQUEST,
  payload: payload,
});

export const createHotelOrderRequest = (body, productType, history) => ({
  type: types.CREATE_HOTEL_ORDER_REQUEST,
  payload: { body, productType, history },
});

export const createSmartPackageOrderRequest = (payload) => ({
  type: types.CREATE_SMART_PACKAGE_ORDER_REQUEST,
  payload,
});

export const getOrderRequest = (payload) => ({
  type: types.GET_ORDER_REQUEST,
  payload,
});

export const getOrderWithProductsRequest = (payload) => ({
  type: types.GET_ORDER_WITH_PRODUCTS_REQUEST,
  payload,
});

export const getAvailableExtrasRequest = (orderId, productItemId) => ({
  type: types.GET_AVAILABLE_EXTRAS_REQUEST,
  payload: { orderId, productItemId },
});

export const updateExtrasRequest = (payload) => ({
  type: types.UPDATE_EXTRAS_REQUEST,
  payload,
});

export const updateOrderProductItemRequest = (payload) => ({
  type: types.UPDATE_ORDER_PRODUCT_ITEM_REQUEST,
  payload,
});

export const updateCustomerDetailsRequest = (payload) => ({
  type: types.UPDATE_CUSTOMER_DETAILS_REQUEST,
  payload,
});

export const createPaymentRequest = (payload) => ({
  type: types.CREATE_PAYMENT_REQUEST,
  payload,
});

export const initPaymentRequestActions = (payload) => ({
  type: types.INIT_PAYMENT_REQUEST,
  payload,
});

export const requestPaymentAction = (payload) => ({
  type: types.REQUEST_PAYMENT,
  payload,
});

export const requestPaymentContinueRequest = (payload) => ({
  type: types.REQUEST_PAYMENT_CONTINUE_REQUEST,
  payload,
});

export const setCustomerInfoActions = (payload) => ({
  type: types.SET_CUSTOMER_INFO,
  payload,
});

export const sendDiscountCodeAction = (payload) => ({
  type: types.SEND_DISCOUNT_CODE,
  payload,
});

export const clearDataForPaymentAction = () => ({
  type: types.CLEAR_DATA_FOR_PAYMENT,
});

export const createUserAgentPaymentRequest = (payload) => ({
  type: types.CREATE_USER_AGENT_PAYMENT_REQUEST,
  payload,
});
