import React, { useRef } from "react";
import T from "prop-types";

import { Button } from "../";
import { useTranslation, useOutsideClickHandler } from "@tint_fe/hooks";
import { parseClasses } from "@tint_fe/helpers";
import { buildNotificationHeading } from "./helpers";

const CustomNotificationUI = ({
  hideBtn,
  handleClickOnBtn,
  textBtn,
  children,
  containerClassName,
  closeToast,
  message,
  refErrorCode,
  isCritical,
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const { elCls } = parseClasses({
    base: "notification",
    additional: [containerClassName],
  });

  useOutsideClickHandler(ref, () => (isCritical ? null : closeToast()));

  const notiHeading = buildNotificationHeading(refErrorCode || message, t);

  return (
    <div ref={ref} className={elCls("content")}>
      <p className="mg-0 p4 bold mg-b-1">{notiHeading}</p>
      {children}
      {!hideBtn && (
        <Button small background="dark" onClick={handleClickOnBtn || closeToast} className={elCls("btn")}>
          {t(textBtn)}
        </Button>
      )}
    </div>
  );
};

CustomNotificationUI.defaultProps = {
  textBtn: "alright",
};

CustomNotificationUI.propTypes = {
  containerClassName: T.string,
  hideBtn: T.bool,
  handleClickOnBtn: T.func,
  textBtn: T.string,
  children: T.node,
  closeToast: T.func,
  message: T.string,
  refErrorCode: T.string,
  isCritical: T.bool,
};

export default CustomNotificationUI;
