import React, { useState } from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import { Check, Add, Clear } from "../../assets/icons";
import { Button } from "..";

const TintCardSelectButton = ({ selected, btnClasses, onSelect, withHover, btnText }) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  const getIcon = () => {
    switch (true) {
      case selected && hover:
        return <Clear height={24} width={24} />;
      case selected:
        return <Check height={24} width={24} />;
      default:
        return <Add height={24} width={24} />;
    }
  };

  return (
    <Button
      small
      prevIcon={getIcon()}
      onClick={(e) => {
        e.preventDefault();
        onSelect();
      }}
      onMouseEnter={() => withHover && setHover(true)}
      onMouseLeave={() => withHover && setHover(false)}
      className={btnClasses}
      background="blue"
    >
      {hover && selected ? t("remove") : t(btnText)}
    </Button>
  );
};

TintCardSelectButton.propTypes = {
  selected: T.bool,
  btnClasses: T.string,
  onSelect: T.func,
  withHover: T.bool,
  btnText: T.string,
};

export default TintCardSelectButton;
