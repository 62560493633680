import React from "react";
import { Route, Switch } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { ACTIVITY_CHECKOUT, ACTIVITY_CUSTOMER, ACTIVITY_PAYMENT } from "./routes";
import { CommonLayout } from "../Layouts";
import Payment from "../containers/Payment/Payment";
import CustomerInfo from "../containers/CustomerInfo/CustomerInfo";
import ActivityCheckoutForm from "../containers/Activity/ActivityCheckoutForm/ActivityCheckoutForm";
import ActivityLayout from "../containers/Activity/ActivityLayout/ActivityLayout";
import { ACTIVITY_DETAILS_FORM } from "../../helpers/constants";
import usePreselectActivityCheckoutForm from "../containers/Activity/hooks/usePreselectActivityCheckoutForm";

const ActivityRouter = () => {
  const { ...methods } = useForm({
    defaultValues: ACTIVITY_DETAILS_FORM,
    shouldFocusError: true,
    reValidateMode: "onChange",
  });

  usePreselectActivityCheckoutForm(methods.reset);

  return (
    <FormProvider {...methods}>
      <CommonLayout>
        <ActivityLayout>
          <Switch>
            <Route path={ACTIVITY_CHECKOUT} component={ActivityCheckoutForm} />
            <Route path={ACTIVITY_CUSTOMER} component={CustomerInfo} />
            <Route path={ACTIVITY_PAYMENT} component={Payment} />
          </Switch>
        </ActivityLayout>
      </CommonLayout>
    </FormProvider>
  );
};

export default ActivityRouter;
