import { api } from "@tint_fe/api";

export * from "@tint_fe/api/order";

export const createSmartPackageOrder = (body) => {
  return api.post({ path: "/orders/smart_packages", body, authorizationCustomer: true, withCurrencyInOrder: true });
};

export const createOrderApi = (body) => {
  return api.post({ path: "/orders", body, authorizationCustomer: true, withCurrencyInOrder: true });
};

export const changeOrderCurrencyApi = ({ orderId, body }) => {
  return api.patch({ path: `/orders/${orderId}/currency`, body, withUuid: true });
};

export const getAvailableExtrasApi = ({ orderId, productItemId }) => {
  return api.get({
    path: `/orders/${orderId}/product_items/${productItemId}/available_extras`,
    withUuid: true,
  });
};

export const getOrderAvailableExtrasApi = ({ orderId }) => {
  return api.get({
    path: `/orders/${orderId}/available_extras`,
    withUuid: true,
  });
};

export const getOrderItineraryApi = ({ orderId }) => {
  return api.get({
    path: `/orders/${orderId}/itinerary`,
    withUuid: true,
  });
};

export const updateExtrasApi = ({ orderId, productItemId, body }) => {
  return api.patch({
    path: `/orders/${orderId}/product_items/${productItemId}/extras`,
    body,
    withUuid: true,
  });
};

export const updateOrderProductItemApi = ({ orderId, productItemId, body }) => {
  return api.patch({
    path: `/orders/${orderId}/product_items/${productItemId}`,
    body,
    withUuid: true,
  });
};

export const updateCustomerDetailsApi = ({ orderId, customerBody }) => {
  return api.patch({ path: `/orders/${orderId}/customer`, body: customerBody, withUuid: true });
};

export const requestPaymentApi = ({ orderId, paymentBody }) => {
  return api.post({
    path: `/orders/${orderId}/request_payment`,
    body: paymentBody,
    withUuid: true,
  });
};

export const requestPaymentContinueApi = ({ orderId, paymentBody }) => {
  return api.patch({
    path: `/orders/${orderId}/fetch_payment_page_url`,
    body: paymentBody,
    withUuid: true,
  });
};

export const initPaymentForm = ({ orderNumber, body }) => {
  return api.post({ path: `/orders/${orderNumber}/init_payment_form`, body, withUuid: true });
};

export const sendDiscountCode = ({ orderId, order }) => {
  return api.post({
    path: `/orders/${orderId}/discount`,
    body: order,
    withUuid: true,
  });
};

export const postCreateAgentPayment = async ({ orderNumber }) => {
  return api.post({
    path: `/orders/${orderNumber}/agent_payment`,
    body: {},
    withUuid: true,
  });
};
