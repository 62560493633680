import React from "react";
import { Route, Switch } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { TOUR_CHECKOUT, TOUR_CUSTOMER, TOUR_PAYMENT } from "./routes";
import { CommonLayout } from "../Layouts";
import Payment from "../containers/Payment/Payment";
import CustomerInfo from "../containers/CustomerInfo/CustomerInfo";
import TourCheckoutForm from "../containers/Tour/TourCheckoutForm/TourCheckoutForm";
import TourLayout from "../containers/Tour/TourLayout/TourLayout";
import { TOUR_DETAILS_FORM } from "../../helpers/constants";

const TourRouter = () => {
  const methods = useForm({
    defaultValues: TOUR_DETAILS_FORM,
    shouldFocusError: true,
    reValidateMode: "onChange",
  });

  return (
    <FormProvider {...methods}>
      <CommonLayout>
        <TourLayout>
          <Switch>
            <Route path={TOUR_CHECKOUT} component={TourCheckoutForm} />
            <Route path={TOUR_CUSTOMER} component={CustomerInfo} />
            <Route path={TOUR_PAYMENT} component={Payment} />
          </Switch>
        </TourLayout>
      </CommonLayout>
    </FormProvider>
  );
};

export default TourRouter;
