import React, { useEffect } from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { dates, findCountryFlag, parseClasses } from "@tint_fe/helpers";
import { Select, ControlledInput, DatePickerInput } from "../../components";
import { Delete } from "../../assets/icons";
import countries from "../../assets/countries/countries.json";

const Document = ({ document, onRemove, singleProduct }) => {
  const { mainCls, elCls } = parseClasses({ base: "document" });
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();
  const dateNow = new Date();

  const handleSelectNationality = (val) => {
    if (singleProduct && document.number === 0) {
      const documents = getValues("documents");

      if (!documents[document.number].nationality?.value) {
        documents.forEach((_, index) => {
          setValue(`documents.${index}.nationality`, val);
        });
      }
    }
  };

  useEffect(() => {
    setValue(`${document.name}.key`, document.key);
  }, []);

  return (
    <div className={mainCls}>
      <div className={elCls("heading")}>
        <div className="uppercase bold mg-b-4">
          {t("traveler")} {document.number + 1}
        </div>
        {onRemove && (
          <button onClick={onRemove} className={elCls("remove")}>
            <Delete height={20} width={20} />
          </button>
        )}
      </div>
      <div className={elCls("info")}>
        <ControlledInput
          name={`${document.name}.first_name`}
          label={`${t("formField.firstName")}*`}
          classNameWrapper={elCls("firstName")}
          control={control}
          upFirstLater
        />

        <ControlledInput
          name={`${document.name}.last_name`}
          label={`${t("formField.lastName")}*`}
          classNameWrapper={elCls("lastName")}
          control={control}
          upFirstLater
        />

        <Controller
          control={control}
          name={`${document.name}.date_of_birth`}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { value, ref, onChange, ...restField }, fieldState: { error } }) => (
            <DatePickerInput
              classNameWrapper={elCls("dateOfBirth")}
              label={`${t("formField.dateOfBirth")}*`}
              placeholder="DD/MM/YY"
              defaultMode={"year"}
              errors={error}
              scrollIntoViewOnError
              availableFrom={dates.sub(new Date(), { years: 121 })}
              availableTo={new Date()}
              disabledDate={(currDate) => {
                return (
                  dates.isBefore(currDate, dates.sub(new Date(), { years: 121 })) || dates.isAfter(currDate, dateNow)
                );
              }}
              onChange={onChange}
              {...restField}
            />
          )}
        />

        <Controller
          control={control}
          name={`${document.name}.nationality`}
          scrollIntoViewOnError
          render={({ field: { onChange, ...restField }, fieldState }) => (
            <Select
              classNameWrapper={elCls("nationality")}
              label={`${t("formField.nationality")}*`}
              placeholder="Select"
              showOptionFrom={0}
              scrollIntoViewOnError
              withoutOverflowTooltip
              onChange={(val) => {
                handleSelectNationality(val);
                onChange(val);
              }}
              options={countries.map((el) => ({
                value: el.alpha2Code,
                label: el.name,
                flag: findCountryFlag(el.alpha2Code),
              }))}
              isIconOption={true}
              {...restField}
              {...fieldState}
            />
          )}
        />

        <ControlledInput
          control={control}
          classNameWrapper={elCls("number")}
          name={`${document.name}.number`}
          label={`${t("formField.passportNumber")}*`}
        />

        <Controller
          control={control}
          name={`${document.name}.start_date`}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { value, ref, onChange, ...fieldRest }, fieldState: { error } }) => (
            <DatePickerInput
              classNameWrapper={elCls("start")}
              label={`${t("formField.dateOfIssue")}*`}
              placeholder="DD/MM/YY"
              defaultMode={"year"}
              errors={error}
              scrollIntoViewOnError
              availableFrom={dates.sub(new Date(), { years: 121 })}
              availableTo={new Date()}
              disabledDate={(currDate) => {
                return (
                  dates.isBefore(currDate, dates.sub(new Date(), { years: 121 })) || dates.isAfter(currDate, dateNow)
                );
              }}
              onChange={onChange}
              {...fieldRest}
            />
          )}
        />

        <Controller
          control={control}
          name={`${document.name}.expiry_date`}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { value, ref, onChange, ...fieldRest }, fieldState: { error } }) => (
            <DatePickerInput
              isRightIcon
              classNameWrapper={elCls("expiry_date")}
              label={`${t("formField.expirationDate")}*`}
              placeholder="DD/MM/YY"
              defaultMode={"year"}
              errors={error}
              scrollIntoViewOnError
              placement="right"
              availableFrom={new Date()}
              availableTo={dates.add(new Date(), { years: 100 })}
              disabledDate={(currDate) => {
                return (
                  dates.isBefore(currDate, dateNow) || dates.isAfter(currDate, dates.add(new Date(), { years: 100 }))
                );
              }}
              onChange={onChange}
              dropdownAlign={{
                points: ["tr", "br"],
                offset: [0, 5],
                targetOffset: ["0%", "0%"],
                overflow: { adjustX: true, adjustY: true },
              }}
              {...fieldRest}
            />
          )}
        />
      </div>
    </div>
  );
};

Document.propTypes = {
  document: T.object.isRequired,
  onRemove: T.func,
  singleProduct: T.bool,
};

export default Document;
