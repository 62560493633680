import { rest } from "msw";

export const integration = {
  name: "touristjourney",
  banners: [
    {
      name: "first_offer",
      image: "",
      url: "https://touristjourney.com/tours",
    },
    {
      name: "second_offer",
      image: "",
      url: "https://touristjourney.com/tours",
    },
    {
      name: "logo",
      image: "https://tourist-images-stg-s3.s3.eu-west-1.amazonaws.com/h7500ur596ir5wjggo83vmwozovc",
      url: "https://touristjourney.com/tours",
    },
    {
      name: "confirmation",
      image: "https://tourist-images-stg-s3.s3.eu-west-1.amazonaws.com/e28u2os146395k8f77y0ez4021xq",
      url: "https://touristjourney.com/tours/",
    },
    {
      name: "main_offer",
      image: "https://tourist-images-stg-s3.s3.eu-west-1.amazonaws.com/b52qou37t3d7zhish73gd09moyb4",
      url: "https://touristjourney.com/tours",
    },
  ],
  brand_name: "Tourist Journey",
  code: "TY",
  contact_us_email: "info1@touristjourney.com",
  contact_us_link: "https://touristjourney.com/contact/",
  email: "oleg.f+123@coaxsoft.com",
  external_feedbacks_link: "https://www.provenexpert.com/tourist-journey/",
  payment_provider: "credorax",
  phone_number: "+1 (929) 323 2112",
  show_private: false,
  terms_and_conditions_url: "https://touringplatform.com/terms_and_conditions/4",
  whatsapp_link: "https://api.whatsapp.com/send?phone=+19293232112",
};

export const integrationHandlers = {
  getIntegration: rest.get("/integration", (req, res, ctx) => {
    return res(ctx.json(integration));
  }),
};
