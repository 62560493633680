import { api } from "@tint_fe/api";

export const getPackageById = ({ id, query }) => {
  return api.get({ path: `/packages/${id}`, params: query });
};

export const getSmartPackagesAccommodation = ({ query }) => {
  return api.get({ path: "/orders/smart_packages/accommodation", params: query });
};

export const getSPReplaceHotelList = ({ orderNumber, productItemId }) => {
  return api.get({ path: `/orders/${orderNumber}/product_items/${productItemId}/replace_hotel_list`, withUuid: true });
};

export const postSPReplaceHotelList = ({ orderNumber, productItemId, body }) => {
  return api.post({
    path: `/orders/${orderNumber}/product_items/${productItemId}/replace_hotel`,
    body,
    withUuid: true,
  });
};

export * from "@tint_fe/api/smart-package";
