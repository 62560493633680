import * as dates from "./dates";
import { trim, groupBy } from "lodash";

const { convertDateToUTC } = dates;

const checkIsDateHaveAvailableLang = (date, availableDates) => {
  const obj = {};

  availableDates.forEach((range) => {
    if (
      dates.isBetween(
        date,
        dates.convertDateToUTC(range.start_time),
        dates.convertDateToUTC(range.end_time),
        false,
        false,
      )
    ) {
      const days = range.days_of_week.filter((el) => el);

      if (days.length === 0 || days.includes(dates.format(date, "EEEE", false, "en"))) {
        if (!(range.language in obj) || range.priority === "override") {
          obj[range.language] = range.available;
        }
      }
    }
  });

  return obj;
};

export const checkIsDateAvailable = (date, availableDates) => {
  const obj = checkIsDateHaveAvailableLang(date, availableDates);

  return !Object.values(obj).find(Boolean);
};

export const checkIsDateAvailableThroughSupplier = (date, rangesFromApi) => {
  const groupedBySupplier = groupBy(rangesFromApi, ({ supplier_id }) => supplier_id);

  const obj = {};

  for (const [supplier_id, available_dates] of Object.entries(groupedBySupplier)) {
    const res = {};

    available_dates.forEach((range) => {
      const isInRange = dates.isBetween(
        date,
        dates.convertDateToUTC(range.start_time),
        dates.convertDateToUTC(range.end_time),
        false,
        false,
      );

      if (isInRange) {
        const days = range.days_of_week.filter((el) => el);

        if (days.length === 0 || days.includes(dates.format(date, "EEEE", false, "en"))) {
          if (!(range.language in res) || range.priority === "override") {
            res[range.language] = range.available;
          }
        }
      }

      obj[supplier_id] = Object.values(res).find(Boolean);
    });
  }

  return !Object.values(obj).find(Boolean);
};

export const checkIsDateDisabled = (date, forbiddenDates) => {
  const parsedDates = forbiddenDates?.map((el) => dates.parse(el, "dd/MM/yyyy"));
  const isDisabled = parsedDates?.find((el) => dates.isSameDay(date, el));

  return !!isDisabled;
};

export const isDateDisabled = (date, forbiddenDates) => {
  const formattedDate = dates.format(date, "dd/MM/yyyy", false);
  const isDisabled =
    forbiddenDates?.find((el) => el === formattedDate) ||
    dates.isBefore(date, new Date(), false) ||
    dates.isAfter(date, dates.add(new Date(), { months: 11 }, false));

  return !!isDisabled;
};

export const getExtremeDate = (availableDates, extremeType) => {
  const result = availableDates?.reduce((date, el) => {
    if (el.available) {
      const nextDate = convertDateToUTC(el[extremeType]);

      if (!date) {
        return nextDate;
      }

      if (date) {
        const isAfter = dates.isAfter(date, nextDate);

        if (extremeType === "start_time" && isAfter) {
          return nextDate;
        }

        if (extremeType === "end_time" && !isAfter) {
          return nextDate;
        }
      }
    }

    return date;
  }, 0);

  return result ? new Date(result) : new Date();
};

export const getMinDateTourAvailability = (availableDates, minDateWithoutOverrides = new Date(), checkIsDisabled) => {
  const allEndTimes = availableDates.map((item) => item.end_time);
  const earliestEndTime = Math.min(...allEndTimes);
  // const latestEndTime = Math.max(...allEndTimes);

  const overrideDates = availableDates.filter((item) => item.priority === "override" && !item.available);

  if (!overrideDates.length) {
    const earliestStartTime = Math.min(...availableDates.map((item) => item.start_time));

    return new Date(earliestStartTime);
  }

  const { startTime: earliestOverrideStartTime, endTime: earliestOverrideEndTime } = overrideDates.reduce(
    (acc, item) => {
      if (item.end_time < acc.endTime) {
        acc.endTime = item.end_time;
      }
      if (item.start_time < acc.startTime) {
        acc.startTime = item.start_time;
      }

      return acc;
    },
    { startTime: Infinity, endTime: Infinity },
  );

  const oneDayBeforeOverrideStarts = dates.add(dates.convertDateToUTC(earliestOverrideStartTime), { days: -1 }, false);
  const isPrevOverrideDateDisabled = checkIsDisabled(oneDayBeforeOverrideStarts);
  const isOverrideStartInFuture = dates.add(earliestOverrideStartTime, { days: -1 }, false) > new Date();

  const isAvailableIn7DaysFromMinDate = Array.from({ length: 7 }).some((_, i) => {
    const date = dates.add(minDateWithoutOverrides, { days: i }, false);

    return !checkIsDisabled(date) && dates.isSameMonth(date, minDateWithoutOverrides, false);
  });

  if (!isPrevOverrideDateDisabled || isOverrideStartInFuture || isAvailableIn7DaysFromMinDate) {
    return minDateWithoutOverrides;
  }

  const finalEarliestEndTime = Math.min(earliestEndTime, earliestOverrideEndTime);

  const finalEarliestDate = dates.convertDateToUTC(dates.add(new Date(finalEarliestEndTime), { days: 1 }, false));

  return finalEarliestDate;
};

export const checkFirstDateAvailable = (date, availableDates, language, skipIsSameMonthCheck = false) => {
  let availableDate = null;
  let counter = 0;
  // eslint-disable-next-line no-constant-condition
  while (counter < 400) {
    if (typeof language === "undefined" || counter > 300) {
      return "";
    }
    const newDate = dates.add(date, { days: counter }, false);
    const obj = checkIsDateHaveAvailableLang(newDate, availableDates);
    const isAvailable =
      trim(language) === ""
        ? !!Object.values(obj).find(Boolean)
        : Object.keys(obj).includes(language) && !!obj[language];

    if (isAvailable) {
      availableDate = newDate;
      break;
    }
    counter++;
  }

  if (skipIsSameMonthCheck) {
    return availableDate;
  }

  if (dates.isSameMonth(date, availableDate)) {
    return availableDate;
  }

  return "";
};

// Not being used currently
export const checkDatesAvailableThisMonth = (thisMonthDate, availableDates, language) => {
  const availableDatesThisMonth = [];
  let counter = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (typeof language === "undefined" || counter > 300) {
      return availableDatesThisMonth;
    }
    const newDate = dates.add(thisMonthDate, { days: counter }, false);
    const obj = checkIsDateHaveAvailableLang(newDate, availableDates);
    const isAvailable =
      trim(language) === "" ? !!Object.values(obj).find(Boolean) : Object.keys(obj).includes(language);

    if (isAvailable && dates.isSameMonth(thisMonthDate, newDate, false)) {
      availableDatesThisMonth.push(newDate);
    }

    counter++;
  }
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

// Not being used currently
export const selectLastSeatsDate = (availableDatesArr = []) => {
  const length = availableDatesArr.length;
  if (length) {
    const randomNum = getRandomInt(length);

    return availableDatesArr[randomNum];
  }

  return "";
};

// Not being used currently
export const selectPopularDate = (lastSeatsDate, availableDatesArr = []) => {
  if (!lastSeatsDate) {
    return "";
  }

  const length = availableDatesArr.length;
  if (length) {
    let date = lastSeatsDate;
    let counter = 0;
    while (
      counter <= length &&
      (dates.isSameDay(dates.add(date, { days: 1 }), lastSeatsDate) ||
        dates.isSameDay(dates.sub(date, { days: 1 }), lastSeatsDate) ||
        dates.isSameDay(date, lastSeatsDate))
    ) {
      const randomNum = getRandomInt(length);
      date = availableDatesArr[randomNum];
      counter++;
    }

    if (
      dates.isSameDay(dates.add(date, { days: 1 }), lastSeatsDate) ||
      dates.isSameDay(dates.sub(date, { days: 1 }), lastSeatsDate) ||
      dates.isSameDay(date, lastSeatsDate)
    ) {
      return "";
    }

    return date;
  }

  return "";
};

export const findFirstActivityAvailableDate = (forbiddenDates) => {
  const today = new Date();
  const currentDay = new Date(today);
  const yearAhead = dates.add(today, { months: 11 }, false);

  while (
    dates.isBefore(currentDay, yearAhead, false) &&
    forbiddenDates.includes(dates.format(currentDay, "dd/MM/yyyy", false))
  ) {
    currentDay.setDate(currentDay.getDate() + 1);
  }

  return forbiddenDates.includes(dates.format(currentDay, "dd/MM/yyyy", false)) ? today : currentDay;
};
