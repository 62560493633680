import { api } from "@tint_fe/api";

export const getProductApi = (id) => {
  return api.get({ path: `/products/${id}` });
};

export const getTourAvailabilityApi = ({ tourId, query }) => {
  return api.get({ path: `/tours/${tourId}/availability`, params: query });
};

export const getTourPriceBandsApi = ({ tourId, query }) => {
  return api.get({ path: `/tours/${tourId}/price_bands`, params: query });
};

export const getPickupPointsByLocationApi = ({ tourId, query }) => {
  return api.get({ path: `/tours/${tourId}/pickup_points`, params: query });
};

export const getScheduleActivityApi = ({ id, params }) => {
  return api.get({ path: `/activities/${id}/schedule`, params });
};
