import * as React from "react";

function TintProsCheckMark(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 108 0a8 8 0 000 16zm5-13c-2.213 1.415-4.348 3.34-6.364 6.17L3 6.42c.87 2.318 2.055 4.165 3.597 5.58L13 3z"
        fill="#F48D2E"
      />
    </svg>
  );
}

export default TintProsCheckMark;
