import { useEffect, useState } from "react";

import { useBreakPoints, useRequest } from "@tint_fe/hooks";
import { getAvailableFromSmartPackage, getAvailableDatesSmartPackage } from "@tint_fe/api";
import { dates } from "@tint_fe/helpers";
import { useReduxAction } from "@tint_fe/redux-utils";
import { setErrorAction } from "@tint_fe/redux-utils/src/global/globalActions";

const PACKAGE_FORMAT_DATE = "yyyy-MM-dd";
const formatStartDate = (date) => {
  const currDate = dates.isBefore(date, new Date()) ? dates.startOfMonth(date, false) : date;

  return dates.format(currDate, PACKAGE_FORMAT_DATE, false);
};

const checkIfForbiddenDatesIncludesWholeMonth = (forbiddenDates, dateOfMonth) => {
  const requestedDate = new Date(dateOfMonth);
  const daysInMonthCount = dates.getDaysInMonth(requestedDate.getYear(), requestedDate.getMonth() + 1);
  const daysThatMatchesTheMonth = dates.countDatesWithMonth(forbiddenDates, requestedDate);

  return daysInMonthCount === daysThatMatchesTheMonth;
};

const useAvailableDatesForSmartPackage = ({
  packageId,
  offset,
  preventGetAvailableDateOnInit,
  canUseRedux = false,
}) => {
  const setError = canUseRedux && useReduxAction(setErrorAction);
  const { isSm, isMd, isLg } = useBreakPoints();
  const [isNotAvailable, setIsNotAvailable] = useState(false);
  const [currDateToSearch, setCurrDateToSearch] = useState();
  const [startDate, setStartDate] = useState(null);
  const [minPrices, setMinPrices] = useState({});
  const [disabledDates, setDisabledDates] = useState([]);
  const [initLoading, setInitLoading] = useState(true);

  const {
    loading,
    callToApi: getAvailableDates,
    res,
  } = useRequest({
    apiCall: getAvailableDatesSmartPackage,
    initialLoading: true,
  });

  useEffect(() => {
    if (Object.keys(res?.package?.prices ?? {}).length !== 0) {
      setMinPrices((prev) => ({ ...prev, ...res.package.prices }));
    }
    if (res?.package?.disabled_dates?.length > 0) {
      setDisabledDates((prev) => [...new Set([...prev, ...res.package.disabled_dates])]);
    }
  }, [res]);

  const addOffset = (data) => {
    if (typeof offset === "number" && offset > 0) {
      return dates.add(data, { months: 1 }, false);
    }

    return data;
  };

  const { callToApi: getAvailableFromDate } = useRequest({
    apiCall: getAvailableFromSmartPackage,
    onSuccess: (data) => {
      const available_from = data.package.available_from
        ? dates.parse(data.package.available_from, "dd/MM/yyyy", false)
        : new Date();

      setCurrDateToSearch(available_from);
      !preventGetAvailableDateOnInit &&
        getAvailableDates({
          id: packageId,
          params: { start_date: formatStartDate(addOffset(available_from)), force: canUseRedux },
        });

      setStartDate(available_from);
      setInitLoading(false);
    },
    onError: () => {
      setCurrDateToSearch(new Date());
      getAvailableDates({ id: packageId, params: { start_date: formatStartDate(new Date()), force: canUseRedux } });
    },
  });

  const handleGetAvailableFromDate = (date) => {
    getAvailableFromDate({
      id: packageId,
      params: {
        start_date: formatStartDate(date),
      },
    });
  };

  useEffect(() => {
    handleGetAvailableFromDate(formatStartDate(new Date()));
  }, []);

  const handleGetAvailableDates = (date) => {
    setCurrDateToSearch(date);
    const needsForce = canUseRedux && dates.isSameMonth(date, new Date(), false);
    getAvailableDates({ id: packageId, params: { start_date: formatStartDate(addOffset(date)), force: needsForce } });
  };

  useEffect(() => {
    if (!canUseRedux) return;
    const dateToSearch = currDateToSearch;

    const forbiddenDatesIncludesWholeMonth = checkIfForbiddenDatesIncludesWholeMonth(disabledDates, dateToSearch);
    // TINT-4434: Fix for cached available_date received from BE
    const nextMonthHasNoAvailability = checkIfForbiddenDatesIncludesWholeMonth(
      disabledDates,
      dates.add(dateToSearch, { months: 1 }, false),
    );

    if (forbiddenDatesIncludesWholeMonth && nextMonthHasNoAvailability) {
      setError({
        status: 400,
        fields: ["datepicker"],
        messages: ["errors.package.fullMonthAvailability"],
        type: "availability",
        contactUsButton: false,
      });
    }
  }, [disabledDates]);

  useEffect(() => {
    if (isSm) return setIsNotAvailable(checkIfForbiddenDatesIncludesWholeMonth(disabledDates, currDateToSearch));

    if (isMd || isLg) {
      return setIsNotAvailable(
        checkIfForbiddenDatesIncludesWholeMonth(disabledDates, currDateToSearch) &&
          checkIfForbiddenDatesIncludesWholeMonth(disabledDates, dates.add(currDateToSearch, { months: 1 }, false)),
      );
    }

    return setIsNotAvailable(
      checkIfForbiddenDatesIncludesWholeMonth(disabledDates, currDateToSearch) &&
        checkIfForbiddenDatesIncludesWholeMonth(disabledDates, dates.add(currDateToSearch, { months: 1 }, false)) &&
        checkIfForbiddenDatesIncludesWholeMonth(disabledDates, dates.add(currDateToSearch, { months: 2 }, false)),
    );
  }, [disabledDates]);

  const checkIsDisabled = (date, mode) => {
    if (dates.isBefore(date, new Date())) return true;
    if (dates.isAfter(date, dates.add(new Date(), { months: 12 }, false))) return true;
    if (mode && mode !== "date") return false;
    if (!disabledDates.length) return false;

    const isAfterDateRange = dates.isAfter(
      date,
      dates.endOfMonth(dates.add(currDateToSearch, { months: 2 }, false), false),
    );
    const isBeforeDateRange = dates.isBefore(date, dates.startOfMonth(currDateToSearch));

    if (isBeforeDateRange || isAfterDateRange) return true;

    return !!disabledDates?.find((el) => {
      return dates.format(date, "dd/MM/yyyy", false) === el;
    });
  };

  // TINT-4434: Fix for cached available_date received from BE
  useEffect(() => {
    const dateToSearchIsSameMonth = dates.isSameMonth(currDateToSearch, new Date());
    if (dateToSearchIsSameMonth && checkIfForbiddenDatesIncludesWholeMonth(disabledDates, startDate)) {
      handleGetAvailableDates(dates.add(currDateToSearch, { months: 1 }, false));
      setStartDate(dates.add(startDate, { months: 1 }, false));
    }
  }, [disabledDates]);

  return {
    disabledDates,
    minPrices,
    loading,
    initLoading,
    startDate: startDate || null,
    currDateToSearch: currDateToSearch || startDate,
    handleGetAvailableDates,
    handleGetAvailableFromDate,
    checkIsDisabled,
    isNotAvailable,
  };
};

export default useAvailableDatesForSmartPackage;
