import { useEffect } from "react";
import { getOrderItineraryApi } from "../api/order";
import { useRequest } from "@tint_fe/hooks";

const useGetOrderItinerary = ({ orderId, ignore = false }) => {
  const { callToApi, res, error, loading } = useRequest({ apiCall: getOrderItineraryApi });

  useEffect(() => {
    if (orderId && !ignore) {
      callToApi({ orderId });
    }
  }, [orderId]);

  return { res, error, loading };
};

export default useGetOrderItinerary;
