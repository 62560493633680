import React from "react";
import T from "prop-types";

import { Pin } from "../../assets/icons";
import { parseClasses } from "@tint_fe/helpers";

const MapMarker = ({ name }) => {
  const { mainCls, elCls } = parseClasses({ base: "map-marker" });

  return (
    <div className={mainCls}>
      <Pin height={43} width={27} />
      <span className={elCls("text")}>{name}</span>
    </div>
  );
};

MapMarker.propTypes = {
  name: T.string,
};

export default MapMarker;
