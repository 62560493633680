import { createSelector } from "reselect";
import { isEmpty } from "lodash";

export * from "@tint_fe/redux-utils/src/sharedSelectors";

const _checkIfPriceChanged = (order, price) => {
  let isPriceChanged = false;

  order.product_items?.forEach((el) => {
    if (el.refreshed_price && price.product_items) {
      const itemPrice = price.product_items.find((x) => x.id === el.id);

      if (
        itemPrice?.tint_charge_price &&
        el?.refreshed_price &&
        Number(itemPrice.tint_charge_price) !== Number(el.refreshed_price)
      ) {
        isPriceChanged = true;
      }
    }
  });

  return isPriceChanged;
};

const _normalizeChangedProducts = (changedProducts) => {
  return changedProducts?.map(({ id, refreshed_name, start_at, end_at, name, display_name }) => {
    return {
      id,
      refreshed_name,
      start_at,
      end_at,
      name: name || display_name,
    };
  });
};

export const selectChangedProducts = createSelector(
  [(state) => state?.order.data, (state) => state.global.price],
  (order, price) => {
    const changedProducts = order.product_items?.filter((el) => el?.refreshed_id && el.refreshed_id !== el.product_id);

    return {
      isPriceChanged: _checkIfPriceChanged(order, price),
      changedProducts: _normalizeChangedProducts(changedProducts),
    };
  },
);

export const selectOrderNumber = createSelector(
  [(state) => state.order.data, (state) => state.smartPackage.order],
  (singleOrder, smartPackageOrder) => singleOrder?.number || smartPackageOrder?.number,
);

export const selectOrder = createSelector(
  [(state) => state.order.data, (state) => state.smartPackage.order],
  (singleOrder, smartPackageOrder) => ({ ...singleOrder, ...smartPackageOrder }),
);

export const selectCustomer = createSelector(
  [(state) => state.order.data, (state) => state.smartPackage.order],
  (singleOrder, smartPackageOrder) => singleOrder?.customer || smartPackageOrder?.customer || {},
);

export const selectError = createSelector(
  (state) => state,
  (state) => {
    const combinedError = {
      ...state.order.error,
      ...state.global.error,
      ...state.product.error,
      ...state.hotel.error,
      ...state.smartPackage.error,
    };

    return !isEmpty(combinedError) ? combinedError : null;
  },
);

export const selectIfCustomPrice = createSelector(
  (state) => state.global.price,
  (price) => {
    if (price?.product_items?.length) {
      return price.product_items.some((item) => item.custom_charge_price);
    }

    return false;
  },
);
