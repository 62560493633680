import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import FormHeader from "../../../../components/Form/FormHeader";
import { useTranslation } from "../../../../../hooks";
import ControllerInput from "../../../../components/Form/ControllerInput";
import NumericField from "../../../../components/Form/NumericField";

const generateRangeValues = (min, max) => {
  const arr = [0];
  for (let i = min; i <= max; i++) {
    arr.push(i);
  }

  return arr;
};

const WhoTravelingForm = () => {
  const { t } = useTranslation();
  const product = useSelector((state) => state.product);
  const { scheduleGroups } = product;

  const { control, getValues, watch, setValue } = useFormContext();
  const ticket_option = watch("ticket_option");
  const start_time = watch("start_time");
  const who_traveling = watch("who_traveling");

  const timeOptions = useMemo(
    () => scheduleGroups.find((el) => el.name === ticket_option)?.slots,
    [scheduleGroups, ticket_option],
  );

  const productsOptions = timeOptions ? timeOptions.find((el) => el?.time === start_time)?.products ?? [] : [];
  const hideFields = !productsOptions.length;

  useEffect(() => {
    Object.keys(productsOptions).forEach(({ product_id, min_buy }) => {
      setValue(`who_traveling.${product_id}`, min_buy);
    });
  }, [productsOptions]);

  if (hideFields) {
    return null;
  }

  return (
    <>
      <FormHeader text={t("numberOfPeople")} />
      {productsOptions.map(({ product_id, name, availability = -1, max_buy, min_buy, retail_price }) => {
        const show2PaxHint = min_buy === 2 && who_traveling?.[product_id] === 2;
        const max = 50 || (availability < 0 ? max_buy : Math.min(availability, max_buy));
        const options = generateRangeValues(min_buy, max);

        return (
          <>
            <ControllerInput
              key={`price-band-${product_id}`}
              control={control}
              name={`who_traveling.${product_id}`}
              shouldUnregister
              defaultValue={0}
              render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => {
                return (
                  <NumericField
                    id={product_id}
                    price={retail_price?.value}
                    label={name}
                    max={max}
                    min={0}
                    options={options}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    errors={error?.message}
                    value={value || 0}
                    currency={retail_price?.currency}
                    {...restField}
                  />
                );
              }}
              rules={{
                validate: () => {
                  const whoTraveling = getValues("who_traveling");
                  const notEmpty = whoTraveling && Object.values(whoTraveling).some((el) => el > 0);

                  return notEmpty || "error.adults";
                },
              }}
            />
            {show2PaxHint && <span className="booking-form__hint">{t("formField.twoPaxMinHint")}</span>}
          </>
        );
      })}
    </>
  );
};

export default WhoTravelingForm;
