import React, { useState } from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

import Collapse from "../Collapse/Collapse";
import CollapseHeadingSuccess from "../Collapse/CollapseHeadingSuccess";
import { Cross16, ImportantNote } from "../../assets/icons";

const ImportantNotes = ({ classNameWrapper, notes }) => {
  const { t, isRtl } = useTranslation();
  const { elCls } = parseClasses({
    base: classNameWrapper,
    classes: [isRtl && "rtl"],
  });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapse
      open={isOpen}
      onClick={() => setIsOpen((prev) => !prev)}
      header={
        <CollapseHeadingSuccess
          icon={isOpen ? <Cross16 /> : <ImportantNote />}
          text={isOpen ? "Close important notes" : "Read important notes"}
        />
      }
      icon={<></>}
      initialHeight={36}
    >
      <ul className={elCls("hints-list", "mg-t-0")}>
        {notes.map((note, index) => (
          <li key={index} className={elCls("hint", "p4-1", "mg-0")}>
            {t(note)}
          </li>
        ))}
      </ul>
    </Collapse>
  );
};

ImportantNotes.propTypes = {
  classNameWrapper: T.string.isRequired,
  notes: T.array,
};

export default ImportantNotes;
