import { changeOrderCurrencyApi } from "../api/order";
import { useRequest } from "@tint_fe/hooks";

const useChangeOrderCurrency = ({ orderId, onSuccess }) => {
  const { callToApi, res, error, loading } = useRequest({ apiCall: changeOrderCurrencyApi, onSuccess });

  const changeCurrency = (currency) => {
    callToApi({ orderId, body: { charge_currency: currency } });
  };

  return { changeCurrency, res, error, loading };
};

export default useChangeOrderCurrency;
