import * as React from "react";

function SvgMyBookingCalendarIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H5V1.49769H11V0H13V1.49769H15C15.5523 1.49769 16 1.94541 16 2.49769V4H0V2.49769C0 1.94541 0.447715 1.49769 1 1.49769H3V0ZM0 5H16V15C16 15.5523 15.5523 16 15 16H1C0.447715 16 0 15.5523 0 15V5ZM2 9.5C2 9.77614 2.22386 10 2.5 10H4.5C4.77614 10 5 9.77614 5 9.5V7.5C5 7.22386 4.77614 7 4.5 7H2.5C2.22386 7 2 7.22386 2 7.5V9.5ZM2 13.5C2 13.7761 2.22386 14 2.5 14H4.5C4.77614 14 5 13.7761 5 13.5V11.5C5 11.2239 4.77614 11 4.5 11H2.5C2.22386 11 2 11.2239 2 11.5V13.5ZM7.00334 9.99522C6.7272 9.99522 6.50334 9.77136 6.50334 9.49522V7.5C6.50334 7.22386 6.7272 7 7.00334 7H8.99856C9.2747 7 9.49856 7.22386 9.49856 7.5V9.49522C9.49856 9.77136 9.2747 9.99522 8.99856 9.99522H7.00334ZM11 9.49522C11 9.77136 11.2239 9.99522 11.5 9.99522H13.4952C13.7714 9.99522 13.9952 9.77136 13.9952 9.49522V7.5C13.9952 7.22386 13.7714 7 13.4952 7H11.5C11.2239 7 11 7.22386 11 7.5V9.49522ZM7.00334 13.9952C6.7272 13.9952 6.50334 13.7714 6.50334 13.4952V11.5C6.50334 11.2239 6.7272 11 7.00334 11H8.99856C9.2747 11 9.49856 11.2239 9.49856 11.5V13.4952C9.49856 13.7714 9.2747 13.9952 8.99856 13.9952H7.00334Z"
        fill="#56483A"
      />
    </svg>
  );
}

export default SvgMyBookingCalendarIcon;
