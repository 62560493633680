import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import SelectField from "../../../../components/Form/SelectField";
import HiddenField from "../../../../components/Form/HiddenField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation } from "../../../../../hooks";
import { validationRule } from "../../../../../utils";

const ActivityLanguage = () => {
  const { t } = useTranslation();
  const product = useSelector((state) => state.product);
  const { scheduleGroups, scheduleGroupsFetching } = product;

  const {
    control,
    formState: { isSubmitted },
    watch,
    setValue,
  } = useFormContext();
  const start_date = watch("start_date");
  const ticket_option = watch("ticket_option");
  const start_time = watch("start_time");

  const timeOptions = useMemo(
    () => scheduleGroups.find((el) => el.name === ticket_option)?.slots,
    [scheduleGroups, ticket_option, start_time],
  );

  const langOptions = useMemo(() => {
    if (timeOptions?.length) {
      const option = timeOptions.find((el) => el.time === start_time);

      return option?.languages?.map((el) => ({ value: el.code, label: el.name })) ?? [];
    }

    return [];
  }, [timeOptions, start_time]);

  const defaultLangOptions = [{ value: "en", label: "English" }];

  const mainLangOptions = langOptions.length ? langOptions : defaultLangOptions;

  useEffect(() => {
    if (mainLangOptions?.length === 1) {
      const option = mainLangOptions[0];
      setValue("language_code", option.value);
    }
  }, [mainLangOptions]);

  return (
    <ControllerInput
      control={control}
      name="language_code"
      render={({ field, fieldState: { error }, value }) => {
        if (mainLangOptions?.length > 1) {
          return (
            <SelectField
              isSubmitting={isSubmitted}
              disabled={!start_date || !ticket_option}
              isSearchable={false}
              placeholder={t("formField.selectLanguage")}
              label={t("formField.language")}
              options={langOptions}
              errors={error?.message}
              loading={scheduleGroupsFetching}
              {...field}
            />
          );
        }

        return <HiddenField value={value ?? mainLangOptions[0].value} {...field} />;
      }}
      rules={{
        ...validationRule.required("formField.language"),
      }}
    />
  );
};

export default ActivityLanguage;
