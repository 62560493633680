import React from "react";
import { Route, Switch } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import {
  SMART_PACKAGE_CHECKOUT,
  SMART_PACKAGE_CUSTOMER,
  SMART_PACKAGE_SELECT_HOTELS,
  SMART_PACKAGE_PAYMENT,
} from "./routes";
import { CommonLayout } from "../Layouts";
import { SPCheckoutForm, SelectHotels, SmartPackageCustomerInfo, SmartPackageLayout } from "../containers/SmartPackage";
import { usePreselectSPCheckoutForm } from "../containers/SmartPackage/hooks";
import Payment from "../containers/Payment/Payment";

const SmartPackageRouter = () => {
  const { ...methods } = useForm();

  usePreselectSPCheckoutForm(methods.reset, methods.getValues);

  return (
    <FormProvider {...methods}>
      <CommonLayout>
        <SmartPackageLayout>
          <Switch>
            <Route path={SMART_PACKAGE_CHECKOUT} component={SPCheckoutForm} />
            <Route path={SMART_PACKAGE_SELECT_HOTELS} component={SelectHotels} />
            <Route path={SMART_PACKAGE_CUSTOMER} component={SmartPackageCustomerInfo} />
            <Route path={SMART_PACKAGE_PAYMENT} component={Payment} />
          </Switch>
        </SmartPackageLayout>
      </CommonLayout>
    </FormProvider>
  );
};

export default SmartPackageRouter;
