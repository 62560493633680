import * as React from "react";

function SvgInfo(props) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon" {...props}>
      <circle cx={20} cy={20} r={17.25} stroke="#0C3164" strokeWidth={1.5} />
      <path d="M21 12.5c0 2-3 2-3 0s3-2 3 0zM19 16h2v10h2v2h-6v-2h2v-8h-2v-2h2z" fill="#0C3164" />
    </svg>
  );
}

export default SvgInfo;
