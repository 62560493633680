import { useSelector } from "react-redux";

import { bookingTypes } from "../../../helpers/constants";
import { useUser } from "@tint_fe/hooks";

const useBundleSummary = () => {
  const { user } = useUser();
  const product_items = useSelector(({ order }) => order.data.product_items);
  const price = useSelector(({ global }) => global.price);
  const requestedPrice = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);
  const bookingType = useSelector(({ order }) => order.data.build_type);

  if (bookingType !== bookingTypes.BUNDLE) {
    return {
      lineItems: [],
    };
  }

  return {
    lineItems: product_items.map((el) => ({ name: el.display_name })),
    citizenshipTaxAmount: price.rounded_citizenship_tax_amount,
    roundedTotalPrice: price.rounded_taxed_components_price,
    agentFeeAmount: user?.isAgent ? price?.agent_fee_amount : 0,
    totalPrice: price.tint_charge_price,
    requestedPrice,
    currency: price?.charge_currency,
  };
};

export default useBundleSummary;
