import React from "react";
import T from "prop-types";
import { isObject } from "lodash";

import { capitalizeFirstLetter, checkCurrencyChar } from "../../../utils";
import NumericInput from "./NumericInput";
import SwitchField from "./SwitchField";
import { useTranslation } from "../../../hooks";

const parseError = (err, id) => {
  if (isObject(err)) {
    return err[id];
  }

  return err;
};

const NumericField = ({ price, label, errors, id, shouldUseSwitch, preventToShowPrice, byItem, currency, ...rest }) => {
  const { t, i18n, isRtl } = useTranslation();
  const nameToCheck = label.toLowerCase();
  const err = parseError(errors, id);
  const currencyChar = checkCurrencyChar(currency);

  return (
    <div className="form-group--wrapper">
      <div className="extra-info__wrapper">
        <div className={`extra-info ${isRtl ? "rtl" : ""}`}>
          <label className="extra-info__name">
            {capitalizeFirstLetter(i18n.exists(nameToCheck) ? t(nameToCheck) : label)}
          </label>
          {!preventToShowPrice && price >= 0 && (
            <span className="extra-info__price">{`${currencyChar}${price} / ${byItem ? t("item") : t("person")}`}</span>
          )}
        </div>
        {shouldUseSwitch ? <SwitchField name={`${id}`} {...rest} /> : <NumericInput name={`${id}`} {...rest} />}
      </div>
      {err && (
        <div className="error-message">
          <span>{t(err)}</span>
        </div>
      )}
    </div>
  );
};

NumericField.defaultProps = {
  byItem: false,
};

NumericField.propTypes = {
  price: T.oneOfType([T.number, T.string]),
  label: T.string,
  errors: T.oneOfType([T.object, T.string]),
  id: T.oneOfType([T.string, T.number]),
  shouldUseSwitch: T.bool,
  preventToShowPrice: T.bool,
  byItem: T.bool,
  currency: T.string,
};

export default NumericField;
