// from backend in common cases we get error key in message field
// here we store related settings for each error,
// type, and show or not contact us btn
// message for user, we will get from localizations files,
// key is will be the same for localizations as for this settings

import { errorMsgTypes } from "../constants";

export const errorsMsgSettingList = {
  "errors.packages.expired_hotel_rates": {
    type: errorMsgTypes.NEUTRAL,
    contactUsButton: false,
  },
  "errors.packages.smart.no_rooms_available": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.smart.no_rooms_available_multiple": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.smart.start_city": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.smart.start_date": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.smart.end_city": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.custom.not_enough_tours": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.custom.transfer_not_found": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.custom.no_rooms_available": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: false,
  },
  "errors.packages.custom.no_cities_selected": {
    type: errorMsgTypes.NEUTRAL,
    contactUsButton: false,
  },
  "errors.packages.custom.too_much_cities": {
    type: errorMsgTypes.NEUTRAL,
    contactUsButton: false,
  },
  "errors.packages.custom.short_duration": {
    type: errorMsgTypes.NEUTRAL,
    contactUsButton: false,
  },
  "errors.parsec_500": {
    type: errorMsgTypes.AVAILABILITY,
    contactUsButton: true,
  },
  "errors.credorax.apm.denied": {
    type: errorMsgTypes.CRITICAL_ERRORS,
    contactUsButton: true,
  },
  "errors.credorax.apm.security_declined": {
    type: errorMsgTypes.CRITICAL_ERRORS,
    contactUsButton: true,
  },
  "errors.credorax.apm.error_occured": {
    type: errorMsgTypes.CRITICAL_ERRORS,
    contactUsButton: true,
  },
  "errors.credorax.apm.in_progress": {
    type: errorMsgTypes.CRITICAL_ERRORS,
    contactUsButton: true,
  },
  "errors.credorax.apm.duplicated": {
    type: errorMsgTypes.CRITICAL_ERRORS,
    contactUsButton: true,
  },
};

export const defaultErrorMsgSettings = {
  type: errorMsgTypes.CRITICAL_ERRORS,
  contactUsButton: true,
};
