import { useCallback, useState } from "react";

const useRequest = ({ onError, onSuccess, apiCall, initialLoading }) => {
  const [loading, setLoading] = useState(initialLoading || false);
  const [error, setError] = useState(null);
  const [res, setRes] = useState(null);

  const handleError = useCallback(
    (err) => {
      setLoading(false);
      if (typeof onError === "function") {
        const normalizedErr = onError(err);

        return setError(normalizedErr);
      }
      setRes(null);
      setError(err);
    },
    [onError],
  );

  const handleSuccess = useCallback(
    (data) => {
      setLoading(false);
      if (typeof onSuccess === "function") {
        const normalizedRes = onSuccess(data);

        return setRes(normalizedRes);
      }
      setError(null);
      setRes(data);
    },
    [onSuccess],
  );

  const callToApi = useCallback(
    async (...payload) => {
      setLoading(true);

      try {
        const response = await apiCall(...payload);

        handleSuccess(response);
      } catch (err) {
        handleError(err);
      }
    },
    [apiCall, handleSuccess, handleError],
  );

  return { loading, res, error, callToApi };
};

export default useRequest;
