import { useSelector } from "react-redux";
import {
  bookingTypes,
  dates,
  transformArrayOfObjectsToOptions,
  transformArrayOfObjectsToOptionsWithTwoLocations,
} from "../../../../utils";
import { useFormContext } from "react-hook-form";

const useActivityExtendedSummary = () => {
  const productData = useSelector(({ product }) => product.data);
  const scheduleGroups = useSelector(({ product }) => product.scheduleGroups);
  const bookingType = useSelector(({ global }) => global.bookingType);
  const order = useSelector((state) => state.order.data);
  const { pickup_points: pickupPoints = [], settings = {}, open = false } = productData;
  const { custom_pickup_point_strategy } = settings;

  const startCityOptions = custom_pickup_point_strategy
    ? transformArrayOfObjectsToOptions(pickupPoints, "id", "city_name")
    : transformArrayOfObjectsToOptionsWithTwoLocations(pickupPoints, "id", "name");

  if (bookingType && bookingType !== bookingTypes.ACTIVITY) {
    return {
      startLocation: null,
      ticketOption: null,
    };
  }

  // eslint-disable-next-line no-empty-function
  const { watch } = useFormContext() || { watch: () => {} };

  const ticket_option = watch("ticket_option") || null;

  const ticketOptions = transformArrayOfObjectsToOptions(scheduleGroups, "name", "name");
  const timeOptions = scheduleGroups.find((el) => el.name === ticket_option)?.slots;

  // From form
  const stringTimeForm = (timeOptions && timeOptions.length === 1 && timeOptions[0]?.time) || null;
  const nameForm = (startCityOptions && startCityOptions.length === 1 && startCityOptions[0]?.label) || null;
  const ticketNameForm = ticketOptions && ticketOptions.length === 1 && ticketOptions[0]?.label;
  const date = watch("start_date") || null;

  // From order
  const stringTimeOrder = order?.product_items?.[0]?.start_at && dates.getHHMM(order?.product_items?.[0]?.start_at);
  const nameOrder = order?.product_items?.[0]?.pickup_point?.name;
  const ticketNameOrder = order?.product_items?.[0]?.ticket_option;

  // return values
  const stringTime = stringTimeForm || stringTimeOrder;
  const name = nameForm || nameOrder;
  const ticketName = ticketNameForm || ticketNameOrder;

  return {
    startLocation: {
      ...(date && !open && { date }),
      ...(name && { name }),
      ...(stringTime && { stringTime }),
    },
    ticketOption: {
      ...(ticketName && { ticketName }),
    },
  };
};

export default useActivityExtendedSummary;
