import * as React from "react";

function SvgPin(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M13.5 0a13.28 13.28 0 00-6.612 1.791A13.71 13.71 0 001.97 6.668a14.16 14.16 0 00-1.968 6.72c-.02.552.05 1.217.168 1.913.568 3.34 2.382 6.295 4.293 9.091C6.968 28.055 10.39 33.5 12 38.028c.68 1.911-.524 4.975 1.505 4.972 2.028-.003.82-3.06 1.497-4.972 1.604-4.526 5.022-9.965 7.526-13.628 1.916-2.802 3.737-5.763 4.303-9.11.118-.692.186-1.353.167-1.902a14.16 14.16 0 00-1.968-6.72 13.71 13.71 0 00-4.917-4.877A13.28 13.28 0 0013.5 0z"
        fill="#D8513F"
      />
      <ellipse cx={13.502} cy={12.009} rx={4.502} ry={4.503} fill="#761F17" />
    </svg>
  );
}

export default SvgPin;
