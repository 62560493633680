import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useReduxAction } from "../../../../hooks";
import { updateSelectedHotelsAction } from "../../../../redux/smartPackage/smartPackageAction";

const useUpdateSelectedHotels = () => {
  const setHotels = useReduxAction(updateSelectedHotelsAction);
  const selectedHotels = useSelector((state) => state.smartPackage.selectedHotels);

  const changeSelectedHotel = useCallback(
    (dates, hotel) => {
      setHotels(
        selectedHotels.map((el) => {
          if (el.city_id === hotel.city_id) {
            return {
              ...el,
              dates,
              product_id: hotel.hotel_id,
              price: Number(hotel.price),
              display_name: hotel.display_name,
            };
          }

          return el;
        }),
      );
    },
    [selectedHotels],
  );

  return { selectedHotels, changeSelectedHotel };
};

export default useUpdateSelectedHotels;
