import React from "react";
import { Route, Switch } from "react-router-dom";

import { useTranslation } from "@tint_fe/hooks";

import { BUNDLE_CUSTOMER, BUNDLE_PAYMENT } from "./routes";
import { CommonLayout } from "../Layouts";
import Payment from "../containers/Payment/Payment";
import CustomerInfo from "../containers/CustomerInfo/CustomerInfo";
import BundleSummary from "../containers/Bundle/BundleSummary";

const BundleRouter = () => {
  const { isRtl } = useTranslation();

  return (
    <CommonLayout>
      <div
        data-testid="smart-package-router"
        dir={isRtl ? "rtl" : "auto"}
        className={`row order-summary__wrapper ${isRtl}`}
      >
        <div className="col col-12 col-md-8">
          <Switch>
            <Route path={BUNDLE_CUSTOMER} component={CustomerInfo} />
            <Route path={BUNDLE_PAYMENT} component={Payment} />
          </Switch>
        </div>
        <div className="col col-12 col-md-4">
          <BundleSummary />
        </div>
      </div>
    </CommonLayout>
  );
};

export default BundleRouter;
