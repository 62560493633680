import React, { useCallback, useEffect, useState } from "react";
import T from "prop-types";
import { v4 as uuidV4 } from "uuid";

import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";
import Arrow from "./Arrow";
import Img from "../Img/Img";
import Button from "../Button/Button";
import { Check2 } from "../../assets/icons";

const getButtonLabel = (type, isSelected) => {
  switch (type) {
    case "tour":
      return isSelected ? "Tour added" : "Add tour";
    case "hotel":
      return isSelected ? "Hotel added" : "Book hotel";
    case "extra":
      return isSelected ? "Service added" : "Add service";
    default:
      return "Book Now";
  }
};

const shouldShowArrows = (numOfItems, screenSize, type) => {
  const isToursUnavailable = type === "tours-unavailable";
  if (screenSize > 1024) {
    return isToursUnavailable ? numOfItems > 2 : numOfItems > 3;
  } else if (screenSize > 654) {
    return numOfItems > 2;
  }

  return numOfItems > 1;
};

const SnapCarousel = ({
  items,
  type,
  chargeCurrency,
  onSelectExtra,
  onDirectAddExtra,
  extraLineItems,
  selectedIds,
  numberOfPeople,
}) => {
  const { mainCls, elCls } = parseClasses({ base: "snap-carousel" });
  const [moveClass, setMoveClass] = useState("");
  const [carouselItems, setCarouselItems] = useState(items);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const currencyChar = checkCurrencyChar(chargeCurrency);

  useEffect(() => {
    const container = document.querySelector(`.snap-carousel__carousel-${type}`);
    if (container) {
      container.style.width = `${carouselItems.length * 295}px`; // 271 + 24 (watch styles)
    }
  }, [carouselItems]);

  const Card = useCallback(
    ({
      cover_image_url,
      cover_image,
      images,
      display_name,
      name,
      min_price,
      capacity_values = [],
      isSelected,
      ...rest
    }) => {
      const baseType = type.split("-")[0];
      const hasMinPrice = typeof min_price === "number" || !!Number(min_price);
      const startPrice = Number(hasMinPrice ? min_price : min_price?.price) ?? null;
      const isRangePrice = min_price?.range_price ?? false;
      const isByItem = min_price?.by_item || rest?.by_item;
      const price = Math.ceil(startPrice);
      const priceString = startPrice
        ? `From ${currencyChar}${price}/${isRangePrice ? "group" : isByItem ? "item" : "person"}`
        : "Click Book Now to see price";
      const serviceName = display_name ?? name;
      const buttonLabel = getButtonLabel(baseType, isSelected);
      const isExtraWithOneCapacity = capacity_values.length && capacity_values[capacity_values.length - 1] === 1;

      const onButtonClick = () => {
        if (isExtraWithOneCapacity || numberOfPeople === 1) {
          onDirectAddExtra(rest?.id, rest?.product_item_id);
        } else {
          onSelectExtra({
            type: baseType,
            cover_image_url,
            cover_image,
            images,
            display_name,
            name,
            price,
            min_price,
            capacity_values,
            ...rest,
          });
        }
      };

      const coverImage = images && typeof images === "object" ? images[0] : cover_image_url || cover_image;

      return (
        <li className={elCls("card")}>
          <Img className={elCls("card-image")} src={coverImage} alt={serviceName} />
          <span className={elCls("card-title")}>{serviceName}</span>
          <span className={elCls("card-price")}>{priceString}</span>
          <Button
            className={elCls(isSelected ? "btn-selected" : "btn")}
            ghost
            // forceDisabled={isSelected}
            onClick={isSelected ? null : onButtonClick}
            prevIcon={isSelected ? <Check2 height={24} width={24} /> : null}
          >
            {buttonLabel}
          </Button>
        </li>
      );
    },
    [],
  );

  Card.propTypes = {
    id: T.number,
    cover_image_url: T.string,
    cover_image: T.string,
    display_name: T.string,
    name: T.string,
    min_price: T.oneOfType([T.object, T.string]),
    isSelected: T.bool,
  };

  const shiftPrev = (copy) => {
    const lastcard = copy.pop();
    copy.splice(0, 0, lastcard);
    setCarouselItems(copy);
  };

  const shiftNext = (copy) => {
    const firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    setCarouselItems(copy);
  };

  const handleAnimationEnd = () => {
    if (moveClass === "snap-carousel__next") {
      shiftNext([...carouselItems]);
    } else if (moveClass === "snap-carousel__prev") {
      shiftPrev([...carouselItems]);
    }
    !showPrevButton && setShowPrevButton(true);
    setMoveClass("");
  };

  const showControls = shouldShowArrows(carouselItems.length, window.innerWidth, type);

  return (
    <div className={mainCls}>
      {showControls && (
        <div className={elCls("controls")}>
          {showPrevButton ? (
            <button onClick={() => setMoveClass("snap-carousel__prev")}>
              <Arrow direction={"left"} />
            </button>
          ) : (
            <span></span>
          )}

          <button onClick={() => setMoveClass("snap-carousel__next")}>
            <Arrow direction={"right"} />
          </button>
        </div>
      )}

      <ul
        onAnimationEnd={handleAnimationEnd}
        className={`${moveClass} ${elCls("carousel")} ${elCls(`carousel-${type}`)}`}
      >
        {carouselItems.map((item) => {
          return (
            <Card
              key={uuidV4()}
              extraLineItems={extraLineItems}
              isSelected={selectedIds?.includes(item.id)}
              {...item}
            />
          );
        })}
      </ul>
    </div>
  );
};

SnapCarousel.propTypes = {
  items: T.array.isRequired,
  type: T.string.isRequired,
  chargeCurrency: T.string,
  onSelectExtra: T.func,
  extraLineItems: T.array,
  selectedIds: T.array,
  onDirectAddExtra: T.func,
  numberOfPeople: T.number,
};

export default SnapCarousel;
