import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { dates, QueryString } from "@tint_fe/helpers";
import { useBreakPoints } from "@tint_fe/hooks";

import MobileSummary from "../../../components/BookingNav/MobileBookingSummary";
import { scrollToError } from "../../../../utils";
import StickyButtonGroup from "../../../components/StickyButtonGroup";
import ReservationDetailsForm from "./ReservationDetails/ReservationDetailsForm";
import WhoTravelingForm from "./TourWhoTraveling/WhoTravelingForm";
import TourExtrasInfo from "./TourExtras/TourExtras";
import { serializeProductItemsAttributes } from "../../../../helpers/serializeOrderBody";
import { steps, PACKAGE_FORMAT_DATE } from "../../../../helpers/constants";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import { selectProductValuesFromOrder } from "../../../../redux/product/productSelectors";
import { getTourAvailabilityAction } from "../../../../redux/product/productActions";
import useHandleChangeTourAvailability from "../hooks/useHandleChangeTourAvailability";
import { selectOderProductExtraLineItems } from "../../../../redux/order/orderSelectors";
import useUpdateTourProduct from "../hooks/useUpdateTourProduct";
import useCreateTourOrder from "../hooks/useCreateTourOrder";
import useUpdateExtras from "../hooks/useUpdateExtras";
import { allowShowExtras } from "../helpers";
import useUpdateTourPrice from "../hooks/useUpdateTourPrice";
import { ga_begin_checkout } from "@tint_fe/helpers/services/google-analytics-v2";
import { setRefetchPricesDueCurrencyChange } from "../../../../redux/global/globalActions";

const TourCheckoutForm = () => {
  const { handleSubmit, ...methods } = useFormContext();
  const history = useHistoryNavigation();

  const getTourAvailability = useReduxAction(getTourAvailabilityAction);
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);
  const handleChangeTourAvailability = useHandleChangeTourAvailability(methods);
  const updateTourProduct = useUpdateTourProduct(history);
  const createTourOrder = useCreateTourOrder(history);
  const updateExtras = useUpdateExtras(history);
  const updateTourPrice = useUpdateTourPrice();

  const step = useSelector((state) => state.global.step);
  const product = useSelector((state) => state.product.data);
  const orderNumber = useSelector((state) => state.order.data.number);
  const availableExtras = useSelector((state) => state.order.availableExtras);
  const loading = useSelector((state) => state.order.loading);
  const loadingFetchPrices = useSelector((state) => state.global.loadingFetchPrices);
  const productValuesFromOrder = useSelector(selectProductValuesFromOrder);
  const orderProductExtraLineItems = useSelector(selectOderProductExtraLineItems);
  const shouldRefetchPrices = useSelector((state) => state.global.refetchPricesDueCurrencyChange);

  const showExtras = allowShowExtras(step) && availableExtras?.length > 0;
  const submitBtnLoading = loading || (showExtras && loadingFetchPrices);

  const { isMd } = useBreakPoints();

  useEffect(() => {
    setTimeout(() => {
      ga_begin_checkout(product);
    }, 1000);
  }, []);

  const getProductItemsAttribute = (values) => {
    const return_different_location = methods.getValues("return_different_location");
    const { who_traveling: whoTraveling, ...reservationDetails } = values;

    return serializeProductItemsAttributes(product, {
      reservationDetails: {
        ...(!return_different_location && {
          departure_flight_number: productValuesFromOrder?.departure_flight_number,
        }),
        ...reservationDetails,
        start_from: reservationDetails.location?.name,
      },
      whoTraveling,
    });
  };

  const submitHandlerCheckoutStep = (values) => {
    const productItemsAttributes = getProductItemsAttribute(values);

    if (!orderNumber) {
      createTourOrder(productItemsAttributes);
    } else {
      updateTourProduct(productItemsAttributes);
    }
  };

  const submitHandlerExtrasStep = (values) => {
    const extras = methods.getValues("extrasInfo");
    const productItemsAttributes = getProductItemsAttribute(values);

    updateTourProduct(productItemsAttributes);
    updateExtras(extras);
  };

  const onSubmit = (values) => {
    if (step === steps.CHECKOUT) {
      submitHandlerCheckoutStep(values);
    } else {
      submitHandlerExtrasStep(values);
    }
  };

  const onError = (e) => {
    console.error(e);
    scrollToError();
  };

  const submitHandler = handleSubmit(onSubmit, onError);

  useEffect(() => {
    if (product.id) {
      if (productValuesFromOrder) {
        const date = dates.format(productValuesFromOrder.start_date, PACKAGE_FORMAT_DATE, false);
        const prevReturnDifferentLocation = methods.getValues("return_different_location");

        methods.reset({
          ...productValuesFromOrder,
          return_different_location: prevReturnDifferentLocation || false,
        });

        getTourAvailability({
          date: date,
          langCode: productValuesFromOrder.language_code,
          onSuccess: (data) => handleChangeTourAvailability(data, date, true),
        });
      } else {
        const { date, language_code } = QueryString.parse(document.location.search);
        if (date) {
          language_code && methods.setValue("language_code", language_code);
          getTourAvailability({
            date,
            langCode: language_code,
            onSuccess: (data) => handleChangeTourAvailability(data, date),
          });
        }
      }
    }
  }, [product.id]);

  useEffect(() => {
    if (orderNumber) {
      updateTourPrice({}, { preventSendingProductItems: true, sendOrderNumber: true });
    }
  }, [orderNumber]);

  useEffect(() => {
    if (orderNumber && !showExtras) {
      updateTourPrice(
        {
          extrasInfo: {},
        },
        { sendOrderNumber: true },
      );
    }
  }, [orderNumber, showExtras]);

  useEffect(() => {
    if (shouldRefetchPrices) {
      setRefetchPrices(false);
      updateTourPrice({}, { sendOrderNumber: true });
    }
  }, [shouldRefetchPrices]);

  return (
    <>
      <ReservationDetailsForm />
      <WhoTravelingForm defaultWhoTraveling={productValuesFromOrder?.who_traveling} showExtras={showExtras} />
      {showExtras && (
        <TourExtrasInfo availableExtras={availableExtras} orderProductExtraLineItems={orderProductExtraLineItems} />
      )}
      {isMd && <MobileSummary />}
      <StickyButtonGroup loading={submitBtnLoading} onSubmit={submitHandler} />
    </>
  );
};

export default TourCheckoutForm;
