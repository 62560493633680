/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { isEqual } from "lodash";

import { getSmartPackageSessionStorage, setSmartPackageSessionStorage } from "../../../../storage";
import useGetSPCheckoutFromData from "./useGetSPCheckoutFromData";
import useGetAccomodation from "./useGetAccomodation";
import { handleABTestingFeatures } from "@tint_fe/helpers";

const useSPCheckoutChanges = () => {
  const getSPCheckoutFromData = useGetSPCheckoutFromData();

  const getAccomodation = useGetAccomodation();

  const sp_checkout = getSPCheckoutFromData();
  const sp_session = getSmartPackageSessionStorage();

  const { autoAccommodation } = handleABTestingFeatures();

  useEffect(() => {
    if (!isEqual(sp_checkout, sp_session) && !autoAccommodation) {
      getAccomodation({ preventRedirect: true });
      setSmartPackageSessionStorage(sp_checkout);
    }
  }, [sp_checkout, sp_session]);
};

export default useSPCheckoutChanges;
