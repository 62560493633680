import React from "react";
import T from "prop-types";

import { Input } from "@tint_fe/ui";
import { join } from "@tint_fe/helpers";

const TextField = ({ name, onChange, value, className, ...rest }) => {
  const handleChange = (val) => {
    onChange({ [name]: val });
  };

  return (
    <Input
      classNameWrapper={join(["form-group--wrapper", className], " ")}
      value={value?.[name] || value}
      onChange={handleChange}
      {...rest}
    />
  );
};

TextField.propTypes = {
  name: T.string.isRequired,
  onChange: T.func.isRequired,
  value: T.oneOfType([T.string, T.object]),
  className: T.string,
};

export default TextField;
