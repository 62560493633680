export * from "@tint_fe/helpers/constants";

export const API_URL = process.env.REACT_APP_API_URL;

export const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const PACKAGE_FORMAT_DATE = "yyyy-MM-dd";

export const OVERNIGHT_FORMAT_DATE = "eeee, MMM dd yyyy";

export const ROOMS_FIELD_MAX_VALUE = 5;

export const TOUR_DETAILS_FORM = {
  city_id: null,
  custom_pickup_lat: null,
  custom_pickup_lon: null,
  custom_pickup_point: null,
  dropoff_location_lat: null,
  dropoff_location_lon: null,
  dropoff_location_name: null,
  dropoff_point_id: null,
  dropoff_city_id: null,
  flight_number: "",
  language_code: "",
  pickup_point_id: null,
  start_time: "",
  start_from: "",
  start_date: "",
  return_different_location: false,
  location: null,
  did_not_booked_yet: false,
};

export const ACTIVITY_DETAILS_FORM = {
  city_id: null,
  custom_pickup_lat: null,
  custom_pickup_lon: null,
  custom_pickup_point: null,
  pickup_point_id: null,
  start_date: "",
  start_time: "",
  ticket_option: null,
  language_code: "",
  who_traveling: [],
};

export const steps = {
  CHECKOUT: "checkout",
  EXTRAS: "extras",
  SELECT_HOTELS: "select-hotels",
  CUSTOMER: "customer",
  PAYMENT: "payment",
  PASSPORT: "passport",
  COMPLETED: "completed",
  CONFIRMED: "confirmed",
  REQUEST_PAYMENT: "request_payment",
  FEEDBACK: "feedback",
  ERROR: "error",
  HOTEL_REDIRECT: "hotel-redirect",
  TOUR_REDIRECT: "tour-redirect",
  ACTIVITY_REDIRECT: "activity-redirect",
  SMART_PACKAGE_REDIRECT: "smart-package-redirect",
};

export const HIDE_NAV_STEPS = [
  steps.PASSPORT,
  steps.COMPLETED,
  steps.CONFIRMED,
  steps.REQUEST_PAYMENT,
  steps.FEEDBACK,
  steps.ERROR,
];

export const mealPlans = {
  ROOM_ONLY: {
    value: "Room Only",
    label: "roomOnly",
  },
  BREAKFAST_INCLUDED: {
    value: "Breakfast is included",
    label: "breakfastIncluded",
  },
};

export const integrationsNames = {
  TOURIST_ISRAEL: "touristisrael",
  TOURIST_JORDAN: "touristjordan",
  TOURIST_JOURNEY: "touristjourney",
  PETRA_FROM_ISRAEL: "petrafromisrael",
  TOURIST_ITALY: "touristitaly",
  TOURIST_JAPAN: "touristjapan",
};

export const COVID_TEST_PRODUCT1 = 4264;
export const COVID_TEST_PRODUCT1_2 = 5436;
export const COVID_TEST_PRODUCT2 = 4534;
export const COVID_TEST_PRODUCT2_1 = 5435;
export const COVID_TEST_PRODUCT2_2 = 5303;
export const COVID_TEST_PRODUCT3 = 4535;
export const COVID_TEST_PRODUCT4 = 4946;
export const COVID_TEST_PRODUCT5 = 5282;

export const covidTestProductNumbers = [
  COVID_TEST_PRODUCT1,
  COVID_TEST_PRODUCT1_2,
  COVID_TEST_PRODUCT2,
  COVID_TEST_PRODUCT2_1,
  COVID_TEST_PRODUCT2_2,
  COVID_TEST_PRODUCT3,
  COVID_TEST_PRODUCT4,
  COVID_TEST_PRODUCT5,
];

export const updateRatesTypes = {
  SELECT_HOTEL: "select_hotel",
  ORDER: "order",
};

export const SP_HOTEL_LEVELS = [
  {
    label: "hotelLevel.level1.title",
    value: "Level 1",
  },
  {
    label: "hotelLevel.level2.title",
    value: "Level 2",
  },
  {
    label: "hotelLevel.level3.title",
    value: "Level 3",
  },
];
