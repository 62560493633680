import * as React from "react";

function SvgClose(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41412 7.99979L13.7069 3.70701L12.2927 2.2928L7.99991 6.58557L3.70732 2.29299L2.29311 3.7072L6.58569 7.99979L2.29268 12.2928L3.70689 13.707L7.99991 9.414L12.2931 13.7072L13.7073 12.293L9.41412 7.99979Z"
        fill="#151209"
      />
    </svg>
  );
}

export default SvgClose;
