import React from "react";
import T from "prop-types";
import { useFormContext, Controller } from "react-hook-form";

import RoomsForm from "../../../components/RoomsForm";
import { getTotalPersonsFromRooms, SPCheckoutFormFields } from "../helpers";

const { ADULTS, CHILDREN } = SPCheckoutFormFields;

const SPRoomsForm = ({ name, ...rest }) => {
  const { control, setValue } = useFormContext();

  const handleChange = (rooms) => {
    const { adults, children } = getTotalPersonsFromRooms(rooms);
    setValue(ADULTS, adults);
    setValue(CHILDREN, children);

    return rooms;
  };

  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line no-unused-vars
      render={({ field: { onChange, value, ref, ...fieldRest } }) => {
        return (
          <RoomsForm
            rooms={value || []}
            handleChange={(rooms) => {
              return rooms && onChange(handleChange(rooms));
            }}
            {...fieldRest}
            {...rest}
          />
        );
      }}
    />
  );
};

SPRoomsForm.propTypes = {
  name: T.string.isRequired,
};

export default SPRoomsForm;
