export const buildNotificationHeading = (message, t) => {
  if (!message) {
    return "";
  }

  if (message.startsWith("errors.credorax") || message.startsWith("Payment was not successful")) {
    return "Uh oh! There was an error processing your payment.";
  }

  if (message === "No rooms available!") {
    return t("errors.packages.smart.no_rooms_available.title");
  }

  const heading = t(`${message}.title`);

  if (heading !== `${message}.title`) {
    return heading;
  }

  return t("error.title");
};

export const buildNotificationMessage = (message, t, brandName) => {
  if (message === "No rooms available!") {
    return t("errors.packages.smart.no_rooms_available");
  }

  return t(`${message}`, { brandName });
};
