import React from "react";
import { v4 as uuidV4 } from "uuid";
import T from "prop-types";
import { useController } from "react-hook-form";

import { parseClasses, validationRule } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { InputWrapper } from "../../components";
import RatingItem from "./RatingItem";

const generateRatingMarks = (from, to) => {
  const ratings = [];

  for (let i = from; i <= to; i++) {
    ratings.push({
      value: String(i),
      key: uuidV4(),
    });
  }

  return ratings;
};

const ratingMarks = generateRatingMarks(1, 5);

const Rating = ({ name, control, defaultValue }) => {
  const { t } = useTranslation();
  const { mainCls } = parseClasses({ base: "rating" });

  const {
    field: { onChange, value: selectedValue },
    fieldState: { error },
  } = useController({
    rules: { ...validationRule.required("Rating") },
    control,
    name,
    defaultValue: String(defaultValue),
  });

  return (
    <InputWrapper
      classNameWrapper={mainCls}
      error={error}
      withoutOverflowTooltip
      scrollIntoViewOnError
      withValidateMark
      label={t("feedback.stars.label")}
    >
      {ratingMarks.map(({ value, key }) => (
        <RatingItem
          key={key}
          name={name}
          type="radio"
          value={value}
          onChange={onChange}
          checked={selectedValue === value}
          inRange={Number(value) <= Number(selectedValue)}
        />
      ))}
    </InputWrapper>
  );
};

Rating.defaultProps = {
  name: "rating",
  defaultValue: "",
};

Rating.propTypes = {
  name: T.string.isRequired,
  control: T.object.isRequired,
  defaultValue: T.oneOfType([T.string, T.number]),
};

export default Rating;
