import React, { useState } from "react";
import T from "prop-types";

import { useBreakPoints, useTranslation } from "@tint_fe/hooks";
import { Button } from "../..";

const PhoneButton = ({ phone, ...rest }) => {
  const { t } = useTranslation();
  const { isMd } = useBreakPoints();
  const [showPhone, setShowPhone] = useState(false);

  const handleClick = () => {
    !isMd && setShowPhone((prev) => !prev);
  };

  return (
    <Button onClick={handleClick} {...(isMd && { href: `tel:${phone}`, as: "a" })} {...rest}>
      {showPhone ? phone : t("phone")}
    </Button>
  );
};

PhoneButton.propTypes = {
  phone: T.string.isRequired,
};

export default PhoneButton;
