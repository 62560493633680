import { useEffect, useState } from "react";
import { useRequest } from "@tint_fe/hooks";
import { QueryString } from "@tint_fe/helpers";
import { getSPReplaceHotelList, postSPReplaceHotelList } from "../../../../api/smartPackage";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import { SMART_PACKAGE_CUSTOMER } from "../../../router";
import { getOrderRequest } from "../../../../redux/actions";

const useGetSPReplaceHotelList = ({ productItemId, orderNumber }) => {
  const history = useHistoryNavigation();
  const getOrderAction = useReduxAction(getOrderRequest);
  const [initLoaded, setInitLoaded] = useState(false);

  const {
    callToApi: getApiCall,
    res: hotels_list,
    loading: getLoading,
  } = useRequest({ apiCall: getSPReplaceHotelList });

  useEffect(() => {
    !initLoaded && hotels_list && setInitLoaded(true);
  }, [hotels_list]);

  const onSucessHotelReplace = () => {
    getOrderAction({ orderNumber });

    const newSearch = QueryString.stringify({
      ...QueryString.parse(document.location.search),
      order: orderNumber,
    });
    history.replace({ pathname: SMART_PACKAGE_CUSTOMER, search: newSearch });
  };

  const { callToApi: postApiCall, loading: postLoading } = useRequest({
    apiCall: postSPReplaceHotelList,
    onSuccess: onSucessHotelReplace,
  });

  useEffect(() => {
    if (productItemId) {
      getApiCall({ productItemId, orderNumber });
    }
  }, [productItemId]);

  const handleGetSPReplaceList = () => {
    if (productItemId) {
      getApiCall({ productItemId, orderNumber });
    }
  };

  const replaceHotelFn = (body) => {
    postApiCall({ productItemId, orderNumber, body });
  };

  return { hotels_list, loading: getLoading || postLoading, initLoaded, replaceHotelFn, handleGetSPReplaceList };
};

export default useGetSPReplaceHotelList;
