import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { omit, trim } from "lodash";

import { hasValues } from "@tint_fe/helpers";
import useUser from "./useUser";

const getCitizenship = (citizenship) => {
  if (typeof citizenship === "object") {
    return citizenship.value;
  }

  return citizenship;
};

const useCustomerInfoForm = ({ order, onCheckAcceptTerms, userFromOutside }) => {
  const { user } = useUser();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors: formErrors },
  } = useForm();

  const acceptTerms = watch("accept_terms");
  const customer = order?.customer || {};

  const first_name = customer.first_name || user?.first_name || userFromOutside?.first_name;
  const last_name = customer.last_name || user?.last_name || userFromOutside?.last_name;
  const email = trim(customer.email || user?.email || userFromOutside?.email);
  const phone = customer.phone || user?.phone || userFromOutside?.phone;
  const citizenship = customer.citizenship || user?.citizenship || userFromOutside?.citizenship;
  const notes = customer.notes || user?.notes || userFromOutside?.notes;
  const invoice = customer.invoice || user?.invoice || userFromOutside?.invoice;

  const orderNumber = order.number;

  const normalizeOrderDetails = (values) => {
    return {
      customer_attributes: {
        ...omit(values, "accept_terms"),
        email: trim(values.email.toLowerCase()).replace(" ", ""),
        phone: values.phone.includes("+") ? values.phone : `+${values.phone}`,
        citizenship: getCitizenship(values.citizenship),
        ...(values.invoice ? { invoice: values.invoice.value } : {}),
      },
    };
  };

  useEffect(() => {
    first_name && setValue("first_name", first_name);
    last_name && setValue("last_name", last_name);
    email && setValue("email", email);
    phone && setValue("phone", phone);
    notes && setValue("notes", notes);
    citizenship && setValue("citizenship", citizenship);
    invoice && setValue("invoice", invoice);
  }, [first_name, last_name, email, phone, citizenship, notes, invoice]);

  useEffect(() => {
    if (acceptTerms && orderNumber) {
      const values = getValues();
      const isValid = hasValues(values, ["first_name", "last_name", "email", "phone", "citizenship", "invoice"]);
      const emailIsValid = values.email.match(/^(\s?)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}(\s?)$/i);

      if (hasValues(values, ["email"])) {
        setValue("email", trim(values.email).replace(" ", ""));
      }

      if (isValid && emailIsValid) {
        onCheckAcceptTerms(normalizeOrderDetails(values));
      }
    }
  }, [acceptTerms, orderNumber]);

  return {
    handleSubmit: (cb) => handleSubmit((val) => cb(normalizeOrderDetails(val))),
    getValues,
    control,
    formErrors,
  };
};

export default useCustomerInfoForm;
