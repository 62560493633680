import React from "react";
import T from "prop-types";

import { useControlledInput, useTranslation } from "@tint_fe/hooks";
import { Textarea } from "../../components";

const Comment = ({ control, defaultValue }) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
    showValidateMark,
  } = useControlledInput({ control, defaultValue, name: "comment" });

  return (
    <Textarea
      placeholder={t("comments")}
      label={t("feedback.comment.label")}
      style={{ resize: "none" }}
      rows={4}
      error={error}
      showValidateMark={showValidateMark}
      {...field}
    />
  );
};

Comment.propTypes = {
  defaultValue: T.string,
  control: T.object,
};

export default Comment;
