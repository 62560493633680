import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import T from "prop-types";

import { validationRule } from "@tint_fe/helpers";
import { DatePickerInput } from "@tint_fe/ui";

import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation, useForbiddenDatesForActivity, useOpenDatesForActivity } from "../../../../../hooks";

const StartDate = ({ isOpenDate }) => {
  const { control, watch, setValue } = useFormContext();
  const { id: activityId } = useSelector((state) => state.product.data);
  const productError = useSelector((state) => state.product.error);
  const start_date = watch("start_date");
  const pickup_point_id = watch("pickup_point_id");

  const { handleGetForbiddenDates, checkIsDisabled, firstAvailableDate, availableTo } = useForbiddenDatesForActivity({
    activityId,
    preventGetAvailableDateOnInit: isOpenDate || !pickup_point_id,
    pickupPointId: pickup_point_id,
  });

  const { handleGetOpenDates, checkIsPastDate, availableOpenDate } = useOpenDatesForActivity({ activityId });
  const { t } = useTranslation();

  const handleChangeStartDate = (date) => {
    return date;
  };

  const handleOpenDateChange = (date) => {
    handleGetOpenDates(pickup_point_id);

    return date;
  };

  useEffect(() => {
    !pickup_point_id && setValue("start_date", null);
  }, [pickup_point_id]);

  useEffect(() => {
    productError?.fields && productError?.fields.includes("start_date") && setValue("start_date", null);
  }, [productError]);

  useEffect(() => {
    if (availableOpenDate && isOpenDate) {
      setValue("start_date", availableOpenDate);
    }
  }, [availableOpenDate, isOpenDate]);

  useEffect(() => {
    if (!isOpenDate || !pickup_point_id) return;

    handleGetOpenDates();
  }, [pickup_point_id, isOpenDate]);

  useEffect(() => {
    if (isOpenDate || !pickup_point_id) return;

    handleGetForbiddenDates(start_date || new Date(), pickup_point_id, true);
  }, [isOpenDate, pickup_point_id]);

  if (isOpenDate) {
    return (
      <ControllerInput
        control={control}
        name="start_date"
        render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
          <DatePickerInput
            label={isOpenDate ? "Ticket can be used until" : "Start Date"}
            placeholder={t("formField.selectDate")}
            classNameWrapper="form-group--wrapper"
            availableFrom={new Date()}
            disabled={true}
            defaultPickerValue={value}
            disabledDate={checkIsPastDate}
            errors={error?.message}
            onChange={(date) => onChange(handleOpenDateChange(date))}
            value={value}
            {...rest}
          />
        )}
        rules={{ ...validationRule.required("Start Date") }}
      />
    );
  }

  return (
    <ControllerInput
      control={control}
      name="start_date"
      render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
        <DatePickerInput
          label={"Start Date"}
          placeholder={t("formField.selectDate")}
          classNameWrapper="form-group--wrapper"
          availableFrom={firstAvailableDate || new Date()}
          availableTo={availableTo}
          disabled={!pickup_point_id}
          disabledDate={checkIsDisabled}
          onMonthChange={(date) => handleGetForbiddenDates(date, null, true, true)}
          onModeChange={(date) => handleGetForbiddenDates(date, null, true, true)}
          defaultPickerValue={firstAvailableDate || value}
          errors={error?.message}
          onChange={(date) => onChange(handleChangeStartDate(date))}
          value={value}
          {...rest}
        />
      )}
      rules={{ ...validationRule.required("Start Date") }}
    />
  );
};

StartDate.propTypes = {
  isOpenDate: T.bool,
};

export default StartDate;
