import { updateOrderProductItemRequest } from "../../../../redux/order/orderActions";
import { useReduxAction } from "../../../../hooks";

const useUpdateTourProduct = (history) => {
  const updateOrderProductItem = useReduxAction(updateOrderProductItemRequest);

  return (productItemsAttributes) => {
    return updateOrderProductItem({
      body: {
        product_item: productItemsAttributes,
      },
      history,
    });
  };
};

export default useUpdateTourProduct;
