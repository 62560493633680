import React from "react";
import T from "prop-types";

import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";
import { TintCard } from "@tint_fe/ui";
import { TintProsCheckMark } from "../../../../../assets/icons";
import { useTranslation } from "../../../../../hooks";

const TourExtrasCard = ({
  extra,
  isCounting,
  price,
  preventScrollIntoView,
  updateExtrasHandler,
  max,
  quantity,
  currency,
}) => {
  const { t, isRtl } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "extra-card", additional: [isRtl && "rtl"] });
  const selected = quantity > 0;
  const currencyChar = checkCurrencyChar(currency);

  return (
    <TintCard
      onSelect={() => {
        updateExtrasHandler({ [extra.id]: selected ? 0 : 1 });
      }}
      selected={selected}
      imgs={extra.images.map((src) => ({ image_url: src }))}
      title={extra.name}
      className={mainCls}
      withHover
      btnText={selected ? "added" : "add"}
      preventScrollIntoView={preventScrollIntoView}
      isCounting={isCounting}
      counterMax={max}
      counterOptions={extra.capacity_values}
      counterQuantity={quantity}
      onChangeCounter={(val) => {
        return updateExtrasHandler({
          [extra.id]: val,
        });
      }}
    >
      <ul className={elCls("list")}>
        {extra.descriptions.slice(0, 3).map((text, index) => (
          <li className={elCls("list-item")} key={`${text}-${index}`}>
            {extra.descriptions.length > 0 && <TintProsCheckMark />}{" "}
            <span className={elCls("list-item-text", extra.descriptions.length === 1 && "full-text", isRtl && "rtl")}>
              {text}
            </span>
          </li>
        ))}
      </ul>
      <div className={elCls("price")}>
        <span className={elCls("price-number")}>
          {currencyChar}
          {price}
        </span>
        <span className={elCls("price-text")}> / {extra?.by_item ? t("item") : t("person")}</span>
      </div>
    </TintCard>
  );
};

TourExtrasCard.propTypes = {
  extra: T.object,
  isCounting: T.bool,
  price: T.oneOfType([T.number, T.string]),
  preventScrollIntoView: T.bool,
  updateExtrasHandler: T.func.isRequired,
  max: T.number,
  quantity: T.number,
  currency: T.string,
};

export default TourExtrasCard;
