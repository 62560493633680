import * as React from "react";
const FlagItinerary = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M16 0v10.353c-.534 0-.956.082-1.267.246-.322.177-.62.366-.894.568a5.802 5.802 0 0 1-.875.568c-.323.177-.751.265-1.285.265-.546 0-.975-.088-1.285-.265a5.412 5.412 0 0 1-.894-.568 8.751 8.751 0 0 0-.875-.568c-.323-.164-1.089-.206-1.625-.099-.342.068-.548.237-.897.525-.161.133-.353.29-.603.475-.637.5-1.618.5-2.45.5H3V1.647c.534 0 .962-.089 1.285-.265.31-.164.602-.354.875-.568.274-.202.572-.391.894-.568C6.364.082 6.787 0 7.321 0c.546 0 .98.082 1.304.246.31.177.602.366.875.568.273.214.571.404.894.568.31.176.739.265 1.285.265.534 0 .962-.089 1.285-.265.31-.164.602-.354.875-.568.274-.202.572-.391.894-.568C15.043.082 15.466 0 16 0ZM5 13H3v7h2v-7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default FlagItinerary;
