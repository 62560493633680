import { useRequest } from "@tint_fe/hooks";
import { getOpenDatesActivity } from "@tint_fe/api";
import { dates } from "@tint_fe/helpers";
import { useState } from "react";
import { notification } from "@tint_fe/ui";

const useOpenDatesForActivity = ({ activityId }) => {
  const [availableDate, setAvailableDate] = useState(null);

  const { loading, callToApi: getOpenDates } = useRequest({
    apiCall: getOpenDatesActivity,
    initialLoading: true,
    onSuccess: (resp) => {
      const dateInResp = resp?.product?.open_date?.day;
      if (!dateInResp) {
        return notification.info("No available dates");
      }
      setAvailableDate(dates.convertDateToUTC(dateInResp));
    },
  });

  const checkIsPastDate = (date) => {
    if (dates.isBefore(date, new Date())) return true;
  };

  const handleGetOpenDates = () => {
    getOpenDates({ id: activityId });
  };

  return {
    loading,
    availableOpenDate: availableDate,
    handleGetOpenDates,
    checkIsPastDate,
  };
};

export default useOpenDatesForActivity;
