import api from "./api";

export const getWidgetIntegrationApi = () => {
  return api.get({ path: "/integration", withUuid: true });
};

export const updateUserFeedback = async ({ order, product, body }) => {
  return api.patch({
    path: `orders/${order}/product_items/${product}/feedback`,
    body,
    withUuid: true,
  });
};

export const postWidgetIssues = async ({ body, queryParam }) => {
  return api.post({
    path: `users/widget_issue?${queryParam}`,
    body,
  });
};
