import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { transformArrayOfObjectsToOptions, validationRule } from "../../../../../utils";
import SelectField from "../../../../components/Form/SelectField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation } from "../../../../../hooks";
import HiddenField from "../../../../components/Form/HiddenField";

const TicketOption = () => {
  const { t } = useTranslation();
  const product = useSelector((state) => state.product);
  const { scheduleGroups, scheduleGroupsFetching } = product;

  const {
    control,
    formState: { isSubmitted },
    setValue,
    watch,
  } = useFormContext();

  const start_date = watch("start_date");

  const ticketOptions = useMemo(
    () => transformArrayOfObjectsToOptions(scheduleGroups, "name", "name"),
    [start_date, scheduleGroups],
  );

  const label = "Ticket options";

  const handleChange = (val) => {
    return val;
  };

  useEffect(() => {
    if (ticketOptions.length === 1) {
      const option = ticketOptions[0];
      setValue("ticket_option", option.value);
    }
  }, [ticketOptions]);

  return (
    <ControllerInput
      control={control}
      name="ticket_option"
      render={({ field: { onChange, ...restField }, fieldState: { error } }) => {
        if (ticketOptions.length > 1 || !ticketOptions.length) {
          return (
            <SelectField
              label={t(label)}
              placeholder={"Ticket Option"}
              disabled={!start_date}
              isPreselected={ticketOptions.length === 1}
              options={ticketOptions}
              errors={error?.message}
              isSearchable={false}
              isSubmitting={isSubmitted}
              noOptionsMessage={t("formField.startFrom.noOptionsMessage")}
              onChange={(val) => onChange(handleChange(val))}
              loading={scheduleGroupsFetching}
              {...restField}
            />
          );
        }

        return <HiddenField value={ticketOptions[0]?.value} {...restField} />;
      }}
      rules={{
        ...validationRule.required(label),
      }}
    />
  );
};

export default TicketOption;
