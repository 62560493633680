import { omitBy, isNil } from "lodash";

import * as sharedTypes from "@tint_fe/redux-utils/src/global/globalActionTypes";
import * as types from "./orderActionTypes";

export const INIT_STATE = {
  data: {},
  availableExtras: null,
  error: null,
  fetchingCreateOrder: false,
  fetchingUpdateOrder: false,
  loading: false,
  requestPaymentProcessing: false,
  dataForPayment: {},
  payment_method: "",
};

const order = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.ORDER_PROCESSING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_TOUR_ORDER_REQUEST:
    case types.CREATE_HOTEL_ORDER_REQUEST:
    case types.CREATE_ACTIVITY_ORDER_REQUEST:
      return {
        ...state,
        fetchingCreateOrder: true,
        loading: true,
      };
    case types.GET_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ORDER_SUCCESS:
    case types.CREATE_TOUR_ORDER_SUCCESS:
    case types.CREATE_HOTEL_ORDER_SUCCESS:
    case types.CREATE_SMART_PACKAGE_ORDER_SUCCESS:
    case types.CREATE_ACTIVITY_ORDER_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          customer: {
            ...(state.data?.customer || {}),
            ...omitBy(action.payload.customer || {}, isNil),
          },
        },
        fetchingCreateOrder: false,
        error: null,
        loading: false,
      };
    case types.RESET_ORDER:
      return {
        ...state,
        data: {},
      };

    case types.CREATE_PAYMENT_FAILED:
      return {
        ...state,
        error: action.payload,
        fetchingCreateOrder: false,
        loading: false,
        requestPaymentProcessing: false,
      };

    case types.GET_AVAILABLE_EXTRAS_SUCCESS:
      return {
        ...state,
        availableExtras: action.payload,
        loading: false,
      };

    case types.UPDATE_ORDER_PRODUCT_ITEM_REQUEST:
    case types.UPDATE_EXTRAS_REQUEST:
      return {
        ...state,
        fetchingUpdateOrder: true,
        loading: true,
      };

    case types.CREATE_PAYMENT_PROCESSING:
    case types.REQUEST_PAYMENT_PROCESSING:
    case types.CREATE_USER_AGENT_PAYMENT_REQUEST:
      return {
        ...state,
        requestPaymentProcessing: true,
      };

    case types.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        requestPaymentProcessing: false,
        loading: false,
        dataForPayment: action.payload,
      };
    case types.CLEAR_DATA_FOR_PAYMENT:
      return {
        ...state,
        dataForPayment: {},
      };
    case types.SET_CUSTOMER_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          customer: {
            ...(action.payload || {}),
          },
        },
      };
    case types.UPDATE_ORDER_PRODUCT_ITEM_SUCCESS:
    case types.UPDATE_EXTRAS_SUCCESS:
    case types.REQUEST_PAYMENT_SUCCESS:
    case types.CREATE_USER_AGENT_PAYMENT_SUCCESS:
      return {
        ...state,
        error: null,
        fetchingCreateOrder: false,
        fetchingUpdateOrder: false,
        loading: false,
        requestPaymentProcessing: false,
        payment_method: action.payload ? action.payload.payment_method : "",
      };
    case types.UPDATE_ORDER_PRODUCT_ITEM_FAILED:
    case types.UPDATE_PASSPORT_DETAILS_FAILED:
    case types.UPDATE_EXTRAS_FAILED:
    case types.ORDER_REQUEST_FAILED:
    case types.REQUEST_PAYMENT_FAILED:
    case types.CREATE_USER_AGENT_PAYMENT_FAILED:
      return {
        ...state,
        error: action.payload,
        fetchingCreateOrder: false,
        fetchingUpdateOrder: false,
        loading: false,
        requestPaymentProcessing: false,
      };
    case types.UPDATE_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case sharedTypes.CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};

export default order;
