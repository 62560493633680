import * as React from "react";

function SvgMap(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M11.5 14.39V3.41l3.402-.68a.5.5 0 01.598.49v9.96a.5.5 0 01-.402.49l-3.598.72zM.5 3.244a.5.5 0 01.356-.479L4.5 1.672v10.456l-3.356 1.007a.5.5 0 01-.644-.479V3.244z"
        fill="#F48D2E"
        stroke="#F48D2E"
        className="fill"
      />
      <path d="M5.5 12.167V1.694l5 1.666v10.89l-5-2.083z" stroke="#F48D2E" className="transparent" />
    </svg>
  );
}

export default SvgMap;
