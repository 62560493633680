import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";

import { dates } from "@tint_fe/helpers";
import { SPCheckoutFormFields } from "../helpers";
import { PACKAGE_FORMAT_DATE } from "../../../../helpers/constants";
import { getSmartPackageSessionStorage } from "../../../../storage";

const {
  ROOMS,
  START_DATE,
  MEAL_PLAN,
  HOTEL_LEVEL,
  START_LOCATION,
  START_LOCATION_TYPE,
  END_LOCATION,
  END_LOCATION_TYPE,
  FLIGHT_NUMBER,
  DEPARTURE_FLIGHT_NUMBER,
  ARRIVAL_EXTRA_NIGHTS,
  DEPARTURE_EXTRA_NIGHTS,
} = SPCheckoutFormFields;

const normalizeSendingSPCheckoutFormData = (data) => {
  const parseValue = (typeField) => {
    return data[typeField] ? { [typeField]: data[typeField] } : {};
  };

  const checkIfDateIsAlreadyFormatted = (date) => {
    if (typeof date === "string") {
      return date;
    }

    return dates.format(date, PACKAGE_FORMAT_DATE, false);
  };

  return {
    package_id: data.package_id,
    ...(data.rooms && { [ROOMS]: data.rooms }),
    [START_DATE]: data[START_DATE] ? checkIfDateIsAlreadyFormatted(data[START_DATE]) : null,
    adults: data.adults,
    children: data.children,
    ...parseValue(START_LOCATION),
    ...parseValue(END_LOCATION),
    ...parseValue(MEAL_PLAN),
    ...parseValue(HOTEL_LEVEL),
    ...parseValue(START_LOCATION_TYPE),
    ...parseValue(END_LOCATION_TYPE),
    ...parseValue(FLIGHT_NUMBER),
    ...parseValue(DEPARTURE_FLIGHT_NUMBER),
    ...parseValue(ARRIVAL_EXTRA_NIGHTS),
    ...parseValue(DEPARTURE_EXTRA_NIGHTS),
  };
};

const useGetSPCheckoutFromData = () => {
  const { getValues } = useFormContext();
  const package_id = useSelector((state) => state.smartPackage.data.id);

  return (data) => {
    const formData = data || getValues();
    const checkoutFormData = isEmpty(formData) ? getSmartPackageSessionStorage() : formData;

    return normalizeSendingSPCheckoutFormData({
      package_id,
      ...checkoutFormData,
      ...(checkoutFormData?.rooms && {
        // eslint-disable-next-line no-unused-vars
        rooms: checkoutFormData.rooms.map(({ key, ...rest }) => rest),
      }),
    });
  };
};

export default useGetSPCheckoutFromData;
