import * as types from "./hotelActionTypes";

export const getHotelAvailabilityRequest = (hotelId, params, data) => {
  return {
    type: types.GET_HOTEL_AVAILABILITY_REQUEST,
    payload: { hotelId, params, ...data },
  };
};

export const updateHotelProductItemRequest = (orderId, productItemId, body, history) => ({
  type: types.UPDATE_HOTEL_PRODUCT_ITEM_REQUEST,
  payload: { orderId, productItemId, body, history },
});

export const setHotelErrorAction = (err) => ({
  type: types.SET_HOTEL_ERROR_ACTION,
  payload: err,
});

export const clearHotelAvailabilityAction = () => ({
  type: types.CLEAR_HOTEL_AVAILABILITY_ACTION,
});
