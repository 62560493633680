import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useBreakPoints } from "@tint_fe/hooks";
import { Info3 } from "../../assets/icons";
import OverflowValueTooltip from "./OverflowValueTooltip";

const OverflowValueIcon = ({ className, ignoreBreakpoints, isContentOverflow, ...rest }) => {
  const { isLg } = useBreakPoints();
  const { mainCls } = parseClasses({
    base: "overflow-value-icon",
    classes: [isContentOverflow && "show"],
    additional: [className],
  });

  if (isLg || ignoreBreakpoints) {
    return (
      <OverflowValueTooltip
        containerClassName={mainCls}
        isContentOverflow={isContentOverflow}
        ignoreBreakpoints={ignoreBreakpoints}
        containerStyles={{ width: "auto" }}
        {...rest}
      >
        <Info3 height={24} width={24} />
      </OverflowValueTooltip>
    );
  }

  return null;
};

OverflowValueIcon.defaultProps = {
  ignoreBreakpoints: false,
};

OverflowValueIcon.propTypes = {
  className: T.string,
  isContentOverflow: T.bool,
  ignoreBreakpoints: T.bool,
};

export default OverflowValueIcon;
