import api from "./api";

export const updatePassportDetailsApi = ({ orderNumber, body }) => {
  return api.patch({
    path: `orders/${orderNumber}/passport`,
    body,
    withUuid: true,
  });
};

export const getOrderApi = (orderId, uuid) => {
  return api.get({ path: `/orders/${orderId}`, withUuid: true, uuid });
};

export const getApplePaymentSession = (orderId, params, uuid) => {
  return api.get({
    path: `/orders/${orderId}/apple_payment_session`,
    params,
    withUuid: true,
    uuid,
  });
};

export const sendIncompleteEmail = ({ body, uuid }) => {
  return api.post({
    path: "/orders/custom_packages/incomplete_email",
    body,
    withUuid: true,
    uuid,
  });
};

export const getSuggestedProductsApi = ({ number }) => {
  return api.get({
    path: `/orders/${number}/suggested_products`,
    withUuid: true,
  });
};

export const requestProcessPaymentForm = async ({ orderNumber, payment_uuid, body }) => {
  return api.post({
    path: `/orders/${orderNumber}/process_payment_form/${payment_uuid}`,
    body,
    withUuid: true,
  });
};

export const getPackagePrice = async ({ number }) => {
  return api.get({
    path: `/orders/${number}/package_prices`,
    withUuid: true,
  });
};

export const patchCompanions = async ({ number, body }) => {
  return api.patch({
    path: `/orders/${number}/companions`,
    body,
    withUuid: true,
  });
};

export const patchExtraCustomerData = async ({ orderNumber, body }) => {
  return api.patch({
    path: `/orders/${orderNumber}/extra_customer_data`,
    body,
    withUuid: true,
  });
};

export const patchParticipantInfos = async ({ orderNumber, body }) => {
  return api.patch({
    path: `/orders/${orderNumber}/participant_infos`,
    body,
    withUuid: true,
  });
};
