import { useSelector } from "react-redux";
import { omitBy, isNil } from "lodash";

const isPriceInRange = (price1, price2) => {
  return Math.abs(Number(price1) - Number(price2)) < 20;
};

export const useSPOrderPrice = () => {
  const globalPrice = useSelector(({ global }) => global.price || {});
  const requestedPrice = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);

  const roundedTotalPrice = isPriceInRange(globalPrice.tint_charge_price, globalPrice.rounded_taxed_components_price)
    ? globalPrice.rounded_taxed_components_price
    : globalPrice.tint_charge_price;

  return omitBy(
    {
      price_per_adult: globalPrice.rounded_price_per_adult,
      price_per_child: globalPrice.rounded_price_per_child,
      children: globalPrice.children,
      adults: globalPrice.adults,
      roundedTotalPrice: Number(roundedTotalPrice).toFixed(0),
      totalPrice: globalPrice.tint_charge_price,
      hidePricesSection: !isPriceInRange(globalPrice.tint_charge_price, globalPrice.rounded_taxed_components_price),
      requestedPrice,
    },
    isNil,
  );
};
