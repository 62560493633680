import * as React from "react";
const HeartCheck20 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke="#21AFE4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.501 9.167 1.667 1.666 3.75-3.75M9.996 4.28C8.329 2.332 5.55 1.808 3.464 3.592c-2.088 1.783-2.382 4.765-.743 6.875 1.239 1.593 4.756 4.792 6.404 6.262.302.27.454.405.631.459a.844.844 0 0 0 .479 0c.177-.054.329-.189.632-.459 1.647-1.47 5.165-4.669 6.403-6.262 1.64-2.11 1.381-5.11-.742-6.875-2.124-1.765-4.866-1.26-6.532.688Z"
    />
  </svg>
);
export default HeartCheck20;
