import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { Cross } from "@tint_fe/ui";
import useHandleIsOpenAllPoliciesModal from "./useHandleIsOpenAllPoliciesModal";
// import { useTranslation } from "@tint_fe/hooks";

const PolicyModal = ({ descriptions, check_in, check_out, integrationCode }) => {
  const { elCls } = parseClasses({
    base: "policy-btn-modal",
  });
  const handleIsOpenInfoModal = useHandleIsOpenAllPoliciesModal();
  const headingShouldBeUppercased = !["PI", "TY"].includes(integrationCode);

  const formattedDescriptions = Object.keys(descriptions).map((key) => {
    const points = descriptions[key]
      .trim()
      .split(". ")
      .map((point) => `${point.trim().replace(".", "")}.`);

    return {
      title: headingShouldBeUppercased ? key.toUpperCase() : key,
      points,
    };
  });

  return (
    <div className={elCls("modal-bg")}>
      <div className={elCls("modal-container")}>
        <div className={elCls("top-row")}>
          <div onClick={() => handleIsOpenInfoModal(false)} className={elCls("close-btn")}>
            <Cross />
          </div>
        </div>
        <span className={elCls("title")}>Hotel Policies</span>
        <div className={elCls("check-info")}>
          {check_in && (
            <span className={elCls("check-text")}>
              <b>Check in</b> {check_in}
            </span>
          )}
          {check_out && (
            <span className={elCls("check-text")}>
              <b>Check out</b> {check_out}
            </span>
          )}
        </div>
        {formattedDescriptions.map((item, key) => {
          return (
            <div key={key} className={elCls("column")}>
              <span className={elCls("subtitle")}>{item.title}</span>
              <ul className={elCls("list")}>
                {item.points.map((point, key2) => {
                  return (
                    <li key={key2} className={elCls("point")}>
                      {point}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PolicyModal.propTypes = {
  descriptions: T.object,
  check_in: T.string,
  check_out: T.string,
  integrationCode: T.string,
};

export default PolicyModal;
