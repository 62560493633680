import React from "react";
import T from "prop-types";

import { Controller } from "react-hook-form";

const ControllerInput = ({ render, name, ...rest }) => {
  const handleChange = (val) => {
    if (val && (val[name] || typeof val[name] === "string" || typeof val[name] === "number")) {
      return val[name];
    }

    return val;
  };

  return (
    <Controller
      name={name}
      {...rest}
      // eslint-disable-next-line no-unused-vars
      render={({ field: { onChange, ref, ...restOfField }, ...restOfRender }) => {
        return render({
          field: { onChange: (val) => onChange(handleChange(val)), ...restOfField },
          ...restOfRender,
        });
      }}
    />
  );
};

ControllerInput.propTypes = {
  name: T.string.isRequired,
  render: T.func.isRequired,
};

export default ControllerInput;
