import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { useTranslation } from "../../../../../hooks";
import {
  selectProductSettings,
  selectAvailableDropOffPoints,
  selectProduct,
} from "../../../../../redux/product/productSelectors";
import FlightNumber from "../../../../components/Form/FlightNumber";
import useIsCityIsAirport from "../../hooks/useIsCityIsAirport";

const TourDepartureFlightNumber = () => {
  const { t } = useTranslation();

  const availableDropOffPoints = useSelector(selectAvailableDropOffPoints);
  const { custom_dropoff_point_strategy, different_end_location } = useSelector(selectProductSettings);
  const { departure_flight_number } = useSelector(selectProduct);

  const { control, watch, setValue } = useFormContext();
  const startDateValue = watch("start_date");
  const dropoff_city_id = watch("dropoff_city_id");
  const return_different_location = watch("return_different_location");
  const location = watch(custom_dropoff_point_strategy ? "custom_dropoff_point" : "dropoff_point_id");

  const [isCityAirport, cityName] = useIsCityIsAirport(dropoff_city_id);

  const selectedLocationName = (loc) => {
    if (isCityAirport) return cityName;
    if (custom_dropoff_point_strategy) return loc?.name;

    return availableDropOffPoints.find((el) => el.id === loc)?.name || "";
  };

  const dependOnLocation = selectedLocationName(location);

  useEffect(() => {
    if (departure_flight_number) {
      setValue("departure_flight_number", departure_flight_number);
    }
  }, [departure_flight_number, availableDropOffPoints]);

  // hide this field when return_different_location === false, but only when different_end_location === false
  if (!return_different_location && !different_end_location) {
    return null;
  }

  return (
    <FlightNumber
      control={control}
      name="departure_flight_number"
      label={t("formField.flightNumber.departure")}
      disabled={!startDateValue}
      dependOnLocation={dependOnLocation || ""}
      onSelectNotAirport={() => setValue("departure_flight_number", "")}
    />
  );
};

export default TourDepartureFlightNumber;
