import * as React from "react";

function SvgLocation(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4662 1.38242C5.40354 0.498641 6.6744 0.00148298 8 0C9.3256 0.00148283 10.5965 0.498641 11.5338 1.38242C12.4711 2.2662 12.9984 3.46443 13 4.71429C13 8.74805 8.45455 11.7949 8.26101 11.9226C8.18447 11.973 8.09336 12 8 12C7.90664 12 7.81553 11.973 7.73899 11.9226C7.54545 11.7949 3 8.74805 3 4.71429C3.00157 3.46443 3.52886 2.2662 4.4662 1.38242ZM8 7C9.10457 7 10 6.10457 10 5C10 3.89543 9.10457 3 8 3C6.89543 3 6 3.89543 6 5C6 6.10457 6.89543 7 8 7Z"
        fill="#2E1702"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.15506 10.4927C2.25445 11.0291 1 11.9518 1 13C1 14.6569 4.13401 16 8 16C11.866 16 15 14.6569 15 13C15 11.9518 13.7456 11.0291 11.8449 10.4927C11.4882 10.9592 11.116 11.3866 10.7526 11.7692C11.3675 11.8975 11.9099 12.065 12.3589 12.2574C12.8811 12.4812 13.2079 12.7092 13.3823 12.8859C13.4307 12.935 13.461 12.973 13.4796 13C13.461 13.027 13.4307 13.065 13.3823 13.1141C13.2079 13.2908 12.8811 13.5188 12.3589 13.7426C11.3198 14.1879 9.77972 14.5 8 14.5C6.22028 14.5 4.68022 14.1879 3.64113 13.7426C3.11892 13.5188 2.79215 13.2908 2.61772 13.1141C2.56927 13.065 2.53897 13.027 2.52038 13C2.53897 12.973 2.56927 12.935 2.61772 12.8859C2.79215 12.7092 3.11892 12.4812 3.64113 12.2574C4.09005 12.065 4.63249 11.8975 5.24735 11.7692C4.88402 11.3866 4.51182 10.9592 4.15506 10.4927ZM13.507 12.9502C13.5073 12.9502 13.5066 12.9527 13.5044 12.9579C13.5056 12.9527 13.5068 12.9502 13.507 12.9502ZM2.49298 12.9502C2.49323 12.9502 2.49436 12.9527 2.49565 12.9579C2.49338 12.9527 2.49274 12.9502 2.49298 12.9502ZM2.49298 13.0498C2.49274 13.0498 2.49338 13.0473 2.49565 13.0421C2.49436 13.0472 2.49323 13.0498 2.49298 13.0498ZM13.5044 13.0421C13.5066 13.0473 13.5073 13.0498 13.507 13.0498C13.5068 13.0498 13.5056 13.0472 13.5044 13.0421Z"
        fill="#2E1702"
      />
    </svg>
  );
}

export default SvgLocation;
