import React, { useEffect, useState } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { CustomerForm, SubmitButton, BackButton, CustomerExtraForm } from "@tint_fe/ui";
import { QueryString } from "@tint_fe/helpers";
import FormContainer from "../../components/Form/FormContainer";
import {
  getOrderRequest,
  updateCustomerDetailsRequest,
  createPaymentRequest,
  setCustomerInfoActions,
} from "../../../redux/actions";
import { bookingTypes, getPaymentsLinks } from "../../../utils";
import {
  useHistoryNavigation,
  useReduxAction,
  useTranslation,
  useBreakPoints,
  useCustomerInfoForm,
  useRequest,
} from "../../../hooks";
import { getCheckoutPathByBuildType } from "../../router";
import {
  getSessionStorageProductId,
  getSessionStorageSourceLink,
  removeSessionStorageProductId,
  removeSessionStorageSourceLink,
} from "../../../storage";
import { selectIfCustomPrice, selectOrder } from "../../../redux/sharedSelectors";
import { setErrorAction, setRefetchPricesDueCurrencyChange } from "../../../redux/global/globalActions";
import { selectOrderError } from "../../../redux/order/orderSelectors";
import { steps } from "../../../helpers/constants";
import { useForm } from "react-hook-form";
import {
  PARTICIPANT_INFOS_KEY,
  POSSIBLE_STEPS,
  formatCustomerDetailsBody,
  formatParticipantInfosBody,
  generateSteps,
} from "./helpers";
import { patchExtraCustomerData, patchParticipantInfos } from "@tint_fe/api";
import { ga_add_shipping_info } from "@tint_fe/helpers/services/google-analytics-v2";

const RELOAD_TIMEOUT = 1000 * 10; // 10 seconds

const CustomerInfo = ({ onBack, hideBack }) => {
  const { t } = useTranslation();
  const history = useHistoryNavigation();
  const { isSm } = useBreakPoints();

  const getOrder = useReduxAction(getOrderRequest);
  const updateCustomerDetails = useReduxAction(updateCustomerDetailsRequest);
  const createPayment = useReduxAction(createPaymentRequest);
  const setCustomerInfo = useReduxAction(setCustomerInfoActions);
  const setError = useReduxAction(setErrorAction);
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);

  const shouldRefetchPrices = useSelector((state) => state.global.refetchPricesDueCurrencyChange);
  const tint_charge_price = useSelector((state) => state.global?.price?.tint_charge_price);
  const termsAndConditionLink = useSelector((state) => state.global.integration.terms_and_conditions_url);
  const product = useSelector((state) => state.product.data);
  const spProduct = useSelector((state) => state.smartPackage.data);
  const requestPaymentProcessing = useSelector((state) => state.order.requestPaymentProcessing);
  const updatingRates = useSelector((state) => state.global.ratesWasExpired.updating);
  const order = useSelector(selectOrder);
  const apiErrors = useSelector(selectOrderError);
  const isCustomPrice = useSelector(selectIfCustomPrice);
  const { search } = window.location;
  const { success_payment } = QueryString.parse(search);
  const [userDataCopy, setUserDataCopy] = useState({});

  const successPaymentButStateIsCustomer = [steps.CUSTOMER, steps.PAYMENT].includes(order?.state) && !!success_payment;
  const orderNumber = order.number;

  const hideBackButton = hideBack || isCustomPrice;

  const paxQuantity = order.pax_quantity;
  const hasCustomerExtraSchema = !!order.customer_extra_schema && !isEmpty(order.customer_extra_schema);
  const hasParticipantsSchema = !!order.participant_schema && !isEmpty(order.participant_schema);

  const customer_steps = generateSteps(hasCustomerExtraSchema, hasParticipantsSchema);
  const [currentStep, setCurrentStep] = useState(customer_steps[0]);

  const configuredProduct = order.build_type === "smart_package" ? spProduct : product;

  useEffect(() => {
    if (configuredProduct.hasOwnProperty("id")) {
      ga_add_shipping_info(configuredProduct, order, tint_charge_price);
    }
  }, [configuredProduct]);

  useEffect(() => {
    if (shouldRefetchPrices) {
      setRefetchPrices(false);
      if (orderNumber) {
        setTimeout(() => {
          getOrder({ orderNumber });
        }, 500);
      }
    }
  }, [shouldRefetchPrices]);

  const onCheckAcceptTerms = (values) => {
    updateCustomerDetails({
      orderId: orderNumber,
      customerBody: { order: values },
    });
  };

  const {
    handleSubmit,
    getValues,
    control: customerControl,
    formErrors,
  } = useCustomerInfoForm({
    order,
    onCheckAcceptTerms,
  });

  const { control: customerExtraControl, handleSubmit: handleCustomerExtraSubmit } = useForm({ mode: "all" });
  const { control: participantInfosControl, handleSubmit: handleParticipantInfosSubmit } = useForm({ mode: "all" });

  const [pressedBack, setPressedBack] = useState(false);

  const buildHotelSearchQuery = () => {
    const hdf = order?.package_start_date;
    const hdt = order?.package_end_date;
    let roomsQuery = "";

    order?.rooms_description?.forEach((room, index) => {
      roomsQuery += `rooms[${index}][adults]=${room.adults}`;

      if (room.children && room.children.length > 0) {
        room.children.forEach((child, childIndex) => {
          roomsQuery += `&rooms[${index}][children][${childIndex}]=${child}`;
        });
      }

      if (index !== order.rooms_description.length - 1) {
        roomsQuery += "&";
      }
    });

    return `?hdf=${hdf}&hdt=${hdt}&${roomsQuery}`;
  };

  const stepRedirect = (sourceLink) => {
    const productId = Number.parseInt(getSessionStorageProductId(), 10);

    if (product.id === productId && sourceLink) {
      switch (product.klass) {
        case bookingTypes.HOTEL:
          removeSessionStorageProductId();
          removeSessionStorageSourceLink();
          window.location.href = `${sourceLink}${buildHotelSearchQuery()}`;

          return;
        case bookingTypes.ACTIVITY:
        case bookingTypes.TOUR:
          removeSessionStorageProductId();
          removeSessionStorageSourceLink();
          window.location.href = sourceLink;

          return;
        default:
          history.replace(getCheckoutPathByBuildType(product.klass));
      }
    }

    history.replace(getCheckoutPathByBuildType(product.klass));

    setTimeout(() => {
      order.number && getOrder({ orderNumber });
      window.scrollTo(0, 0);
    }, 50);
  };

  const redirectHandler = () => {
    const sourceLink = getSessionStorageSourceLink();

    if (sourceLink) {
      if ([bookingTypes.HOTEL, bookingTypes.ACTIVITY, bookingTypes.TOUR].includes(product.klass)) {
        stepRedirect(sourceLink);
      } else {
        history.goBack();
      }
    } else {
      stepRedirect(sourceLink);
    }
  };

  const onFinalContinue = (values) => {
    return createPayment({
      orderId: orderNumber,
      customerBody: { order: values },
      paymentBody: {
        order: { agree: true, ...getPaymentsLinks({ orderNumber }) },
      },
      history,
    });
  };

  const { callToApi: patchExtraCustomerDataRequest, loading: patchExtraCustomerDataLoading } = useRequest({
    apiCall: patchExtraCustomerData,
    onSuccess: () => {
      if (customer_steps.length === 2) {
        onFinalContinue({ ...userDataCopy });
      } else {
        setCurrentStep(customer_steps[2]);
      }
    },
    onError: () => {
      setError({
        status: 400,
        fields: ["customer_extra_info"],
        messages: ["errors.activity.customer_extra_data"],
        type: "availability",
        contactUsButton: false,
      });
    },
  });

  const { callToApi: patchParticipantInfosRequest, loading: patchParticipantInfosLoading } = useRequest({
    apiCall: patchParticipantInfos,
    onSuccess: () => {
      onFinalContinue({ ...userDataCopy });
    },
    onError: () => {
      setError({
        status: 400,
        fields: ["customer_extra_info"],
        messages: ["errors.activity.customer_extra_data"],
        type: "availability",
        contactUsButton: false,
      });
    },
  });

  const onCustomerDataSubmit = (values) => {
    onCheckAcceptTerms(values);
    setUserDataCopy(values);
    if (customer_steps.length === 1) {
      onFinalContinue(values);
    } else {
      setCurrentStep(customer_steps[1]);
    }
  };

  const onCustomerExtraContinue = (values) => {
    const orderBody = formatCustomerDetailsBody(values);
    patchExtraCustomerDataRequest({ orderNumber, body: orderBody });
  };

  const onParticipantInfosContinue = (values) => {
    const orderBody = formatParticipantInfosBody(values);
    patchParticipantInfosRequest({ orderNumber, body: orderBody });
  };

  const saveCustomerInfoInRedux = () => setCustomerInfo(getValues());

  const handleRedirectBack = () => {
    if (currentStep === POSSIBLE_STEPS.participantInfos) {
      if (customer_steps.includes(POSSIBLE_STEPS.customerExtraData)) {
        setCurrentStep(POSSIBLE_STEPS.customerExtraData);

        return;
      }
      setCurrentStep(POSSIBLE_STEPS.customerData);

      return;
    } else if (currentStep === POSSIBLE_STEPS.customerExtraData) {
      setCurrentStep(POSSIBLE_STEPS.customerData);

      return;
    }

    setPressedBack(true);
    saveCustomerInfoInRedux();

    return onBack ? onBack() : redirectHandler();
  };

  useEffect(() => {
    return () => {
      !pressedBack && saveCustomerInfoInRedux();
    };
  }, [pressedBack]);

  useEffect(() => {
    if (successPaymentButStateIsCustomer) {
      setTimeout(() => {
        window.location.reload();
      }, RELOAD_TIMEOUT);
    }
  }, [successPaymentButStateIsCustomer]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);
  }, [currentStep]);

  return (
    <FormContainer
      errors={{ ...apiErrors, ...formErrors }}
      loading={
        requestPaymentProcessing ||
        updatingRates ||
        successPaymentButStateIsCustomer ||
        patchExtraCustomerDataLoading ||
        patchParticipantInfosLoading
      }
      loadingMessage={successPaymentButStateIsCustomer ? t("paymentFrom.weAreProcessing") : null}
      scrollLoaderIntoView
    >
      {currentStep === POSSIBLE_STEPS.customerData && (
        <CustomerForm
          termsAndConditionLink={termsAndConditionLink}
          control={customerControl}
          revalidateErrors={handleSubmit(onFinalContinue)}
          isExpedia={order?.show_expedia_terms && order?.build_type === bookingTypes.HOTEL}
        />
      )}

      {currentStep === POSSIBLE_STEPS.customerExtraData && (
        <CustomerExtraForm control={customerExtraControl} schema={order.customer_extra_schema} />
      )}
      {currentStep === POSSIBLE_STEPS.participantInfos &&
        new Array(paxQuantity)
          .fill(0)
          .map((_, index) => (
            <CustomerExtraForm
              key={index}
              control={participantInfosControl}
              schema={order.participant_schema}
              fieldsPrefix={`${PARTICIPANT_INFOS_KEY}.${String(index)}.`}
              position={index + 1}
            />
          ))}

      <div className={`buttons-group${hideBackButton ? " buttons-group__one-btn" : ""}`}>
        {!hideBackButton && <BackButton type="button" className="customer-back" onClick={handleRedirectBack} />}
        <SubmitButton
          label={isSm ? t("continue") : t("continueBooking")}
          disabled={requestPaymentProcessing || !isEmpty(formErrors)}
          loading={requestPaymentProcessing}
          onClick={
            currentStep === POSSIBLE_STEPS.customerData
              ? handleSubmit(onCustomerDataSubmit)
              : currentStep === POSSIBLE_STEPS.customerExtraData
              ? handleCustomerExtraSubmit(onCustomerExtraContinue)
              : handleParticipantInfosSubmit(onParticipantInfosContinue)
          }
          className="mg-l-3"
        />
      </div>
    </FormContainer>
  );
};

CustomerInfo.propTypes = {
  hideBack: T.bool,
  onBack: T.func,
};

export default CustomerInfo;
