import * as React from "react";
const ConfirmationPriceTag = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#212121"
      d="m1.879 10.706 9.415-9.413A1 1 0 0 1 12 1h10a1 1 0 0 1 1 1v10a1 1 0 0 1-.293.707l-9.413 9.415a2.999 2.999 0 0 1-4.243 0L1.879 14.95a3 3 0 0 1 0-4.244ZM17 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
);
export default ConfirmationPriceTag;
