import * as React from "react";

function SvgPlus(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 14v8h4v-8h8v-4h-8V2h-4v8H2v4h8z" fill="#F48D2E" />
    </svg>
  );
}

export default SvgPlus;
