import React from "react";

const CheckMark = () => {
  return (
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Web-Site" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Hotel-Selection-(sticked-title-+-button)" transform="translate(-351.000000, -373.000000)">
          <g id="Hotel-1" transform="translate(107.000000, 260.000000)">
            <g id="Checkmark" transform="translate(244.000000, 113.000000)">
              <rect id="Rectangle" fill="#D8D8D8" opacity="0" x="0" y="0" width="12" height="12"></rect>
              <path
                d="M10.9498144,0.615357123 L5.38310555,11.7422166 C5.19546368,12.0760223 4.69508535,12.0760223 4.50744348,11.7978509 C3.38159224,9.96191905 2.19319372,8.12598725 1.06734248,6.29005544 C0.817153318,5.90061536 1.31753164,5.45554098 1.69281539,5.73371247 C2.69357204,6.29005544 3.6943287,6.84639841 4.69508535,7.34710708 C5.14855321,6.74903839 5.59420265,6.14401541 6.0378975,5.53725385 L7.0353863,4.1713069 C8.0345243,2.80682686 9.04603127,1.45334872 10.1366996,0.170282745 C10.4494361,-0.219157335 11.2000036,0.114648448 10.9498144,0.615357123 Z"
                id="Path"
                fill="#028C3E"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckMark;
