import { bookingTypes } from "../../helpers/constants";

export const ADDITIONAL_PAYMENT = "/additional-payment";
export const CHECKOUT = "/checkout";
export const SELECT_HOTELS = "/select-hotels";
export const CUSTOMER = "/customer";
export const PAYMENT = "/payment";
export const PASSPORT = "/passport";
export const COMPLETED = "/completed";
export const CONFIRMED = "/confirmed";
export const REQUEST_PAYMENT = "/request-payment";
export const FEEDBACK = "/feedback";
export const ERROR = "/error";
export const UNAVAILABLE_ERROR = "/unavailable-error";

export const BUNDLE = "/bundle";
export const BUNDLE_CUSTOMER = BUNDLE + CUSTOMER;
export const BUNDLE_PAYMENT = BUNDLE + PAYMENT;

export const SMART_PACKAGE = "/smart-package";
export const SMART_PACKAGE_CHECKOUT = SMART_PACKAGE + CHECKOUT;
export const SMART_PACKAGE_SELECT_HOTELS = SMART_PACKAGE + SELECT_HOTELS;
export const SMART_PACKAGE_CUSTOMER = SMART_PACKAGE + CUSTOMER;
export const SMART_PACKAGE_PAYMENT = SMART_PACKAGE + PAYMENT;

export const HOTEL = "/hotel";
export const HOTEL_CHECKOUT = HOTEL + CHECKOUT;
export const HOTEL_CUSTOMER = HOTEL + CUSTOMER;
export const HOTEL_PAYMENT = HOTEL + PAYMENT;

export const TOUR = "/tour";
export const TOUR_CHECKOUT = TOUR + CHECKOUT;
export const TOUR_CUSTOMER = TOUR + CUSTOMER;
export const TOUR_PAYMENT = TOUR + PAYMENT;

export const ACTIVITY = "/activity";
export const ACTIVITY_CHECKOUT = ACTIVITY + CHECKOUT;
export const ACTIVITY_CUSTOMER = ACTIVITY + CUSTOMER;
export const ACTIVITY_PAYMENT = ACTIVITY + PAYMENT;

export const getPaymentPathByBuildType = (buildType) => {
  switch (buildType) {
    case bookingTypes.TOUR:
      return TOUR_PAYMENT;
    case bookingTypes.HOTEL:
      return HOTEL_PAYMENT;
    case bookingTypes.CUSTOM_PACKAGE:
    case bookingTypes.SMART_PACKAGE:
      return SMART_PACKAGE_PAYMENT;
    case bookingTypes.BUNDLE:
      return BUNDLE_PAYMENT;
    case bookingTypes.ACTIVITY:
      return ACTIVITY_PAYMENT;
    default:
      return "";
  }
};

export const getCustomerPathByBuildType = (buildType) => {
  switch (buildType) {
    case bookingTypes.TOUR:
      return TOUR_CUSTOMER;
    case bookingTypes.HOTEL:
      return HOTEL_CUSTOMER;
    case bookingTypes.CUSTOM_PACKAGE:
    case bookingTypes.SMART_PACKAGE:
      return SMART_PACKAGE_CUSTOMER;
    case bookingTypes.BUNDLE:
      return BUNDLE_CUSTOMER;
    case bookingTypes.ACTIVITY:
      return ACTIVITY_CUSTOMER;
    default:
      return "";
  }
};

export const getCheckoutPathByBuildType = (buildType) => {
  switch (buildType) {
    case bookingTypes.TOUR:
      return TOUR_CHECKOUT;
    case bookingTypes.HOTEL:
      return HOTEL_CHECKOUT;
    case bookingTypes.SMART_PACKAGE:
      return SMART_PACKAGE_CHECKOUT;
    case bookingTypes.ACTIVITY:
      return ACTIVITY_CHECKOUT;
    default:
      return "";
  }
};

export const getPathByProductType = ({ to, type }) => {
  switch (to) {
    case CHECKOUT:
      return getCheckoutPathByBuildType(type);
    case CUSTOMER:
      return getCustomerPathByBuildType(type);
    case PAYMENT:
      return getPaymentPathByBuildType(type);
    default:
      return "";
  }
};
