import * as React from "react";
const FlagEU = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path fill="#043CAE" d="M.667 0h18.667v16H.667z" />
      <path
        fill="#FFD429"
        fillRule="evenodd"
        d="m9.339 3.988.66-.193.662.193-.194-.66.194-.661L10 2.86l-.661-.193.194.66-.194.661Zm0 9.345.66-.193.662.193-.194-.66.194-.661-.661.193-.661-.193.194.66-.194.661Zm5.333-4.866-.66.194.193-.661-.193-.66.66.193.661-.194L15.14 8l.193.66-.66-.193Zm-10.006.194.661-.194.661.194L5.795 8l.193-.66-.66.193-.662-.194L4.86 8l-.194.66Zm9.38-2.53-.66.194.193-.661-.193-.661.66.193.661-.193-.193.66.193.662-.66-.194Zm-8.753 4.866.66-.194.661.194-.193-.66.193-.662-.66.194-.661-.194.193.661-.193.661Zm7.043-6.576-.66.193.193-.66-.194-.661.661.193.66-.193-.193.66.194.661-.66-.193Zm-5.333 8.286.66-.193.661.193-.193-.66.193-.661-.66.193-.661-.193.193.66-.193.661Zm7.043-1.904-.66.194.193-.66-.193-.662.66.194.661-.194-.193.661.193.661-.66-.194ZM5.293 6.325l.66-.194.661.194-.193-.661.193-.661-.66.193-.661-.193.193.66-.193.662Zm7.043 6.189-.66.193.193-.66-.194-.661.661.193.66-.193-.193.66.194.661-.66-.193Zm-5.333-7.9.66-.193.661.193-.193-.66.193-.661-.66.193-.661-.193.193.66-.193.661Z"
        clipRule="evenodd"
      />
    </g>
    <path stroke="#fff" d="M1.167.5h17.667v15H1.167z" />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.667 0h18.667v16H.667z" />
      </clipPath>
    </defs>
  </svg>
);
export default FlagEU;
