import * as React from "react";

function SvgWarnIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C11.5366 2.25 11.0826 2.37417 10.6822 2.60837C10.282 2.84244 9.95002 3.17771 9.71565 3.57911L10.3633 3.95729L9.71359 3.58265L1.6068 17.6424L1.60671 17.6423L1.60099 17.6527C1.37163 18.0653 1.25128 18.531 1.25001 19.0039C1.24874 19.4767 1.36657 19.9431 1.59366 20.357C1.82084 20.7711 2.15038 21.1196 2.55229 21.3653C2.95442 21.6111 3.41385 21.7446 3.88464 21.75L3.88464 21.75H3.8932H20.1068V21.75L20.1154 21.75C20.5861 21.7446 21.0456 21.6111 21.4477 21.3653C21.8496 21.1196 22.1792 20.7711 22.4063 20.357C22.6334 19.9431 22.7513 19.4767 22.75 19.0039C22.7487 18.531 22.6284 18.0653 22.399 17.6527L22.3991 17.6526L22.3932 17.6424L14.2864 3.58265L14.2864 3.58264L14.2844 3.57911C14.05 3.17771 13.718 2.84244 13.3178 2.60837C12.9174 2.37417 12.4634 2.25 12 2.25ZM11.011 4.33545L11.0119 4.33392C11.1187 4.15158 11.2668 4.00417 11.4395 3.90316C11.6125 3.802 11.8054 3.75 12 3.75C12.1946 3.75 12.3875 3.802 12.5605 3.90316C12.7332 4.00417 12.8813 4.15158 12.9881 4.33392L12.989 4.33545L21.0905 18.386C21.1938 18.5737 21.2494 18.7881 21.25 19.0079C21.2506 19.2296 21.1952 19.446 21.0913 19.6355C20.9874 19.8248 20.8396 19.979 20.6654 20.0855C20.4925 20.1912 20.2984 20.2471 20.102 20.25H3.89799C3.70161 20.2471 3.50754 20.1912 3.33463 20.0855C3.16038 19.979 3.01258 19.8248 2.90872 19.6355C2.80478 19.446 2.74941 19.2296 2.75 19.0079C2.7506 18.7881 2.80623 18.5737 2.90954 18.386L11.011 4.33545ZM12 8C12.5523 8 13 8.44772 13 9V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V9C11 8.44772 11.4477 8 12 8ZM11 17C11 16.4477 11.4477 16 12 16H12.0047C12.557 16 13.0047 16.4477 13.0047 17C13.0047 17.5523 12.557 18 12.0047 18H12C11.4477 18 11 17.5523 11 17Z"
        fill="#F48D2E"
      />
    </svg>
  );
}

export default SvgWarnIcon;
