import { useSelector } from "react-redux";

import { checkIsMobile, dates as datesUtils } from "@tint_fe/helpers";

import { getHotelAvailabilityRequest } from "../../../../redux/hotel/hotelActions";
import { useIsLogged, useReduxAction } from "../../../../hooks";
import { PACKAGE_FORMAT_DATE } from "../../../../helpers/constants";

const useGetHotelsAvailability = ({ onSuccess, pid } = {}) => {
  const getHotelAvailability = useReduxAction(getHotelAvailabilityRequest);
  const isMobile = checkIsMobile();
  const isLogged = useIsLogged();

  const productId = pid ? pid : useSelector((state) => state.product.data.id);

  return ({ start_date, end_date, rooms, ...rest }) => {
    const params = {
      from: datesUtils.format(start_date, PACKAGE_FORMAT_DATE, false),
      to: datesUtils.format(end_date, PACKAGE_FORMAT_DATE, false),
      // eslint-disable-next-line no-unused-vars
      rooms: rooms?.map(({ key, ...room }) => ({ ...room })),
      mobile_user: isMobile,
      loggedin_user: isLogged,
    };

    productId && getHotelAvailability(productId, params, { ...rest, onSuccess });
  };
};

export default useGetHotelsAvailability;
