import React from "react";
import T from "prop-types";

const HiddenField = ({ name, value }) => {
  return <input type="hidden" name={name} value={value || ""} disabled={true} style={{ height: 0, opacity: 0 }} />;
};

HiddenField.propTypes = {
  name: T.string.isRequired,
  value: T.oneOfType([T.string, T.number]),
};

export default HiddenField;
