import { useState, useEffect } from "react";
import T from "prop-types";
import ReactDOM from "react-dom";

const Portal = ({ children, portalClassName, rootId }) => {
  const [container] = useState(() => {
    const el = document.createElement("div");

    el.className = portalClassName ? `portal ${portalClassName}` : `portal`;

    return el;
  });

  useEffect(() => {
    const parent = rootId
      ? document.querySelector(`#${rootId || "root"} .parent-portal`)
      : document.querySelector("body");

    parent?.appendChild(container);

    return () => parent?.removeChild(container);
  }, [rootId]);

  return ReactDOM.createPortal(children, container);
};

Portal.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  portalClassName: T.string,
};

export default Portal;
