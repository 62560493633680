import React from "react";
import { useSelector } from "react-redux";

import { GlobalError } from "@tint_fe/ui";
import { useReduxAction } from "../../../hooks";
import { toggleContactUsModalAction } from "../../../redux/global/globalActions";
import { selectError } from "../../../redux/sharedSelectors";

const ErrorPage = () => {
  const toggleContactUsModal = useReduxAction(toggleContactUsModalAction);
  const error = useSelector(selectError);

  return <GlobalError error={error} showContactUsModal={() => toggleContactUsModal(true)} />;
};

export default ErrorPage;
