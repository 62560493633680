import React from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import InputWrapper from "./InputWrapper";

const TimePickerField = ({
  name,
  label,
  placeholder,
  value,
  disabled = false,
  errors,
  isSubmitting,
  onChange,
  remark,
  className,
  nextIcon,
}) => {
  const { isRtl } = useTranslation();

  const handleChange = (evt) => {
    try {
      const val = evt.target.value;
      let formattedValue = val;

      if (val.length > 7) {
        return;
      } else if (/^[3-9]$/.test(val)) {
        formattedValue = `0${val} : `;
      } else if (/^[3-9]/.test(val) && !/^[0-9]\s:\s/.test(val)) {
        formattedValue = `2${val.charAt(1) > 3 ? "3" : val.charAt(1)}${val.substr(2) ? val.substr(2) : " : "}`;
      } else if (/^2[4-9]/.test(value)) {
        formattedValue = `${val.charAt(0)}3${val.substr(2) ? val.substr(2) : " : "}`;
      } else if (/^[0-2][0-9]\s:$/.test(val)) {
        formattedValue = val.charAt(0);
      } else if (/^[0-2][0-9]$/.test(val)) {
        formattedValue = `${val} : `;
      } else if (/^[0-2][0-9]\s:\s[6-9]/.test(val)) {
        formattedValue = `${val.substr(0, 5)}5${val.substr(6)}`;
      } else if (
        /^[0-2][0-9]:/.test(val) ||
        /^[0-2][0-9]\s:[0-9]{0,2}$/.test(val) ||
        /[0-2][0-9]\s\s[0-9]{0,2}/.test(val) ||
        val.match(/[^0-9:\s]/g)
      ) {
        return;
      }

      onChange(formattedValue);
    } catch (err) {
      console.error(err);
    }
  };

  const handleBlur = (evt) => {
    try {
      const val = evt.target.value;
      let formattedValue = val;

      // [2,3,4].includes(length) is already handled by onChange
      if (val.length > 7) {
        return;
      } else if (val.length === 1) {
        formattedValue = `${val}0 : 00`;
      } else if (val.length === 5) {
        formattedValue = `${val}00`;
      } else if (val.length === 6) {
        formattedValue = `${val}0`;
      }

      onChange(formattedValue);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <InputWrapper
      classNameWrapper={className}
      type="text"
      showValidateMark={isSubmitting}
      name={name}
      label={label}
      error={errors}
      remark={remark}
      disabled={disabled}
      nextIcon={nextIcon}
    >
      <input
        type="text"
        name={name}
        className="input"
        placeholder={placeholder}
        disabled={disabled}
        value={value || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={(evt) => evt.key === ":" && evt.preventDefault()} // prevent enter ':' symbol
        dir={"auto"}
        {...(isRtl ? { style: { textAlign: "right" } } : {})}
      />
    </InputWrapper>
  );
};

TimePickerField.propTypes = {
  name: T.string.isRequired,
  label: T.string,
  placeholder: T.string,
  value: T.oneOfType([T.string, T.object]),
  disabled: T.bool,
  errors: T.object,
  isSubmitting: T.bool.isRequired,
  onChange: T.func.isRequired,
  remark: T.string,
  className: T.string,
  nextIcon: T.node,
};

export default TimePickerField;
