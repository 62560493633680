import React from "react";
import { Route, Switch } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { HOTEL_CHECKOUT, HOTEL_CUSTOMER, HOTEL_PAYMENT } from "./routes";
import { CommonLayout } from "../Layouts";
import Payment from "../containers/Payment/Payment";
import CustomerInfo from "../containers/CustomerInfo/CustomerInfo";
import HotelCheckoutForm from "../containers/Hotel/HotelCheckoutForm/HotelCheckoutForm";
import HotelLayout from "../containers/Hotel/HotelLayout/HotelLayout";
import useHotelCheckoutForm from "../containers/Hotel/hooks/useHotelCheckoutForm";

const HotelRouter = () => {
  const methods = useHotelCheckoutForm();

  return (
    <FormProvider {...methods}>
      <CommonLayout>
        <HotelLayout>
          <Switch>
            <Route path={HOTEL_CHECKOUT} component={HotelCheckoutForm} />
            <Route path={HOTEL_CUSTOMER} component={CustomerInfo} />
            <Route path={HOTEL_PAYMENT} component={Payment} />
          </Switch>
        </HotelLayout>
      </CommonLayout>
    </FormProvider>
  );
};

export default HotelRouter;
