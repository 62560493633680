import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { Counter } from "..";

const CountingField = ({ className, label, ...rest }) => {
  const { mainCls } = parseClasses({ base: "counting-field", additional: className });

  return (
    <div className={mainCls}>
      <span className="counting-field__label bold">{label}</span>
      <Counter {...rest} />
    </div>
  );
};

CountingField.propTypes = {
  className: T.string,
  label: T.string,
};

export default CountingField;
