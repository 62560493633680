import React, { useEffect, useRef } from "react";
import T from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Trans } from "react-i18next";

import { useTranslation, useBreakPoints } from "@tint_fe/hooks";
import { MIN_ADULTS, MAX_ROOM_PAX, MAX_KIDS_AGE } from "@tint_fe/helpers";
import { CountingField, InfoMsg, Button, Fade } from "..";
import { Delete, Hotel } from "../../assets/icons";
import SelectChildAge from "./SelectChildAge";

const Room = ({
  number,
  adults,
  hideRemoveBtn,
  kids,
  onChange,
  onRemove,
  scrollIntoView,
  childrenNotAvailable,
  paxOverflow,
  minChildAge,
  maxChildAge,
  isJP,
}) => {
  const { isSm } = useBreakPoints();
  const { t } = useTranslation();
  const ref = useRef();
  const total = adults + kids.length;
  const maxAdults = MAX_ROOM_PAX - kids.length > 3 ? 3 : MAX_ROOM_PAX - kids.length;
  const maxChildren = adults >= 3 ? 2 : 3;
  const showInfoMsg = total >= 3;

  useEffect(() => {
    scrollIntoView && isSm && ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [isSm, scrollIntoView]);

  const selectChildAgeLabel = (i) => {
    switch (i) {
      case 1:
        return t("room.children.first");
      case 2:
        return t("room.children.second");
      case 3:
        return t("room.children.third");
      case 4:
        return t("room.children.fourth");
      default:
        return t("room.childNumber", { number: i });
    }
  };

  return (
    <div ref={ref} className="room">
      <div className="room__header">
        <p className="room__title mg-0">
          <Hotel />
          <Trans i18nKey="room.title">Room {{ number: number || "" }}</Trans>
        </p>
        {!hideRemoveBtn && (
          <Button onClick={onRemove} round small className="room__delete bold" prevIcon={<Delete />} background="light">
            {t("remove")}
          </Button>
        )}
      </div>
      <div className="room__content">
        <CountingField
          className="mg-b-4 mg-t-6"
          label={t("adults")}
          type="range"
          max={maxAdults}
          incrementDisabled={!maxAdults || paxOverflow}
          min={MIN_ADULTS}
          value={adults}
          onChange={(val) => onChange({ adults: val })}
        />
        {!childrenNotAvailable && (
          <>
            <CountingField
              label={maxChildAge === MAX_KIDS_AGE ? t("room.children") : t("room.childrenUnder", { age: maxChildAge })}
              type="range"
              max={maxChildren}
              incrementDisabled={!maxChildren || paxOverflow}
              value={kids.length}
              onChange={(_, isIncrement) => {
                return onChange({
                  children: isIncrement ? [...kids, 0] : kids.slice(0, kids.length - 1),
                });
              }}
            />
            {kids.length > 0 &&
              kids?.map((el, index) => (
                <SelectChildAge
                  key={uuidv4()}
                  className="mg-t-4"
                  label={selectChildAgeLabel(index + 1)}
                  value={Number(el)}
                  onChange={(val) => {
                    kids[index] = val;

                    onChange({ children: [...kids] });
                  }}
                  minChildAge={minChildAge}
                  maxChildAge={maxChildAge}
                />
              ))}
          </>
        )}

        <Fade show={showInfoMsg}>
          <InfoMsg className={isJP ? "japan-override mg-t-3 " : "mg-t-3"}>
            {isJP
              ? "Many hotels in Japan don't accommodate 3+ people per room. We'll try to propose hotels which do, however, splitting people between rooms could give more choice and lower prices."
              : t("room.war")}
          </InfoMsg>
        </Fade>
      </div>
    </div>
  );
};

Room.defaultProps = {
  minChildAge: 0,
  maxChildAge: MAX_KIDS_AGE,
};

Room.propTypes = {
  number: T.number,
  adults: T.number,
  kids: T.arrayOf(T.number),
  onChange: T.func.isRequired,
  onRemove: T.func,
  scrollIntoView: T.bool,
  hideRemoveBtn: T.bool,
  childrenNotAvailable: T.bool,
  paxOverflow: T.bool,
  minChildAge: T.number,
  maxChildAge: T.number,
  isJP: T.bool,
};

export default Room;
