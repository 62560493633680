import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { v4 as uuidV4 } from "uuid";

import { useTranslation, useBreakPoints } from "@tint_fe/hooks";

import MobileSummary from "../../../components/BookingNav/MobileBookingSummary";
import { QueryString, dates, getSourceLink } from "../../../../utils";
import {
  updateHotelProductItemRequest,
  clearHotelAvailabilityAction,
  setHotelErrorAction,
} from "../../../../redux/hotel/hotelActions";
import { createHotelOrderRequest } from "../../../../redux/order/orderActions";
import { setRefetchPricesDueCurrencyChange, setStepAction } from "../../../../redux/global/globalActions";
import { PACKAGE_FORMAT_DATE, steps } from "../../../../helpers/constants";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";

import FormHeader from "../../../components/Form/FormHeader";
import HotelSelectDates from "./HotelSelectDates";
import HotelsRoomsForm from "./HotelsRoomsForm";
import HotelRoomRatesForm from "./HotelRoomRatesForm";
import HotelButtonsGroup from "./HotelButtonsGroup";

import useGetHotelsAvailability from "../hooks/useGetHotelsAvailability";
import useSerializeHotelCheckoutFormData from "../hooks/useSerializeHotelCheckoutFormData";
import { setHotelSessionStorage } from "../../../../storage";
import { ga_begin_checkout } from "@tint_fe/helpers/services/google-analytics-v2";

const HotelCheckoutForm = () => {
  const clearHotelAvailability = useReduxAction(clearHotelAvailabilityAction);
  const setHotelError = useReduxAction(setHotelErrorAction);
  const createHotelOrder = useReduxAction(createHotelOrderRequest);
  const updateHotelProductItem = useReduxAction(updateHotelProductItemRequest);
  const setStep = useReduxAction(setStepAction);
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);

  const product = useSelector((state) => state.product.data);
  const step = useSelector((state) => state.global.step);
  const order = useSelector((state) => state.order.data);
  const hotelRooms = useSelector((state) => state.hotel.hotelRooms);
  const fetchingGetRooms = useSelector((state) => state.hotel.fetchingGetRooms);
  const fetchingCreateOrder = useSelector((state) => state.order.fetchingCreateOrder);
  const fetchingUpdateOrder = useSelector((state) => state.order.fetchingUpdateOrder);
  const shouldRefetchPrices = useSelector((state) => state.global.refetchPricesDueCurrencyChange);

  const { isMd } = useBreakPoints();

  const { handleSubmit, getValues, setValue } = useFormContext();

  const { t } = useTranslation();

  const history = useHistoryNavigation();
  const getHotelsAvailability = useGetHotelsAvailability();
  const serializeHotelCheckoutFormData = useSerializeHotelCheckoutFormData();

  const isFetching = fetchingGetRooms || fetchingCreateOrder || fetchingUpdateOrder;

  useEffect(() => {
    setTimeout(() => {
      ga_begin_checkout(product);
    }, 1000);
  }, []);

  const onClearRatesHandler = () => {
    const hotelLineItems = getValues("hotel_line_items_attributes");

    hotelLineItems &&
      Object.keys(hotelLineItems).forEach((key) => {
        const val = hotelLineItems[key];

        setValue(`hotel_line_items_attributes.${key}`, {
          ...val,
          quantity: 0,
        });
      });
  };

  const readQueryParams = () => {
    const { hdf, hdt, rooms } = QueryString.parse(document.location.search);

    if (hdf && hdt) {
      const start_date = dates.parse(hdf, PACKAGE_FORMAT_DATE, false);
      const end_date = dates.parse(hdt, PACKAGE_FORMAT_DATE, false);
      setValue("start_date", start_date);
      setValue("end_date", end_date);
    }
    if (rooms) {
      const roomsParsed = rooms.map((room) => {
        return {
          id: uuidV4(),
          adults: Number(room.adults),
          children: [],
        };
      });
      setValue("rooms", roomsParsed);
    }
  };

  const returnToCheckoutStep = () => {
    if (step === steps.EXTRAS) {
      setStep(steps.CHECKOUT);
      hotelRooms.length && clearHotelAvailability();
      onClearRatesHandler();
    }
  };

  const onSubmit = handleSubmit((values) => {
    if (step === steps.CHECKOUT) {
      setHotelSessionStorage({
        ...values,
        start_date: dates.format(values?.start_date, PACKAGE_FORMAT_DATE, false),
        end_date: dates.format(values?.end_date, PACKAGE_FORMAT_DATE, false),
      });

      return getHotelsAvailability(values);
    }

    try {
      const productItemsAttributes = serializeHotelCheckoutFormData(values);

      if (!order.number) {
        createHotelOrder(
          {
            order: {
              product_items_attributes: { 0: productItemsAttributes },
              source_link: getSourceLink(),
            },
          },
          "hotel",
          history,
        );
      } else {
        updateHotelProductItem(
          order.number,
          order.product_items[0].id,
          { product_item: productItemsAttributes },
          history,
        );
      }
    } catch (error) {
      setHotelError({ messages: [error.message] });
    }
  });

  useEffect(() => {
    if (shouldRefetchPrices) {
      setRefetchPrices(false);

      if (step === steps.EXTRAS) {
        getHotelsAvailability(getValues());
      }
    }
  }, [shouldRefetchPrices]);

  useEffect(() => {
    onClearRatesHandler();
    readQueryParams();
  }, []);

  return (
    <>
      <div className="hotels-container">
        <FormHeader text={t("hotelDetails")} />
        <HotelSelectDates handleChange={returnToCheckoutStep} />
        <HotelsRoomsForm handleChange={returnToCheckoutStep} />

        {step === steps.EXTRAS && <HotelRoomRatesForm hotelRooms={hotelRooms} loading={isFetching} />}
      </div>
      {isMd && <MobileSummary />}
      <HotelButtonsGroup
        onClearRates={onClearRatesHandler}
        onSubmit={onSubmit}
        disabled={isFetching}
        fetchingGetRooms={fetchingGetRooms}
        fetchingCreateOrder={fetchingCreateOrder}
        fetchingUpdateOrder={fetchingUpdateOrder}
        step={step}
        isClearSelections={!!hotelRooms.length}
      />
    </>
  );
};

export default HotelCheckoutForm;
