import React from "react";
// import T from "prop-types";
import { useSelector } from "react-redux";
import { parseClasses } from "@tint_fe/helpers";
import { ArrowRight, Button, Diamond20, HeartCheck20, UserCheck20 } from "@tint_fe/ui";

// TODO: replace text with i18n keys
const PERCS = [
  {
    key: "1",
    text: "Exclusive prices on tours and hotels",
    icon: <Diamond20 />,
  },
  {
    key: "2",
    text: "Faster checkout with pre-filled details",
    icon: <UserCheck20 />,
  },
  {
    key: "3",
    text: "Wishlist to save tours for your next trip",
    icon: <HeartCheck20 />,
  },
];

const SuccessBookingSignUp = () => {
  const { mainCls, elCls } = parseClasses({
    base: "success-booking-sign-up",
  });
  const customer = useSelector(({ order }) => order?.data?.customer);

  const openSignUpModal = () => {
    if (customer?.email && customer?.first_name && customer?.last_name) {
      window.tintAuthorization.open("sign-up-preselected", {
        user: {
          ...customer,
        },
      });
    }
  };

  return (
    <div className={mainCls}>
      <span className={elCls("title")}>Create an account in one click</span>
      <div>
        <span className={elCls("perc-text", "bold mg-l-0")}>Create your account and get:</span>
        {PERCS.map((perc) => {
          return (
            <div key={perc.key} className={elCls("perc")}>
              {perc.icon}
              <span className={elCls("perc-text")}>{perc.text}</span>
            </div>
          );
        })}
      </div>
      <Button className={elCls("btn")} fullsize onClick={() => openSignUpModal()} nextIcon={<ArrowRight />}>
        Get instant access
      </Button>
    </div>
  );
};

SuccessBookingSignUp.propTypes = {};

export default SuccessBookingSignUp;
