import { v4 as uuidV4 } from "uuid";

export const defaultPassenger = {
  first_name: "",
  last_name: "",
};

export const getMaxPaxQuantity = (order) => {
  return (
    order?.product_items?.reduce((max, el) => {
      if (max < el.pax_quantity) {
        return el.pax_quantity;
      }

      return max;
    }, 0) || 1
  );
};

export const getDocuments = (order, maxPaxQuantity) => {
  return [...Array(maxPaxQuantity).keys()].map(() => ({
    key: uuidV4(),
  }));
};

export const getTravelers = (maxPaxQuantity) => {
  return [...Array(maxPaxQuantity).keys()].map((number) => ({
    number,
    key: uuidV4(),
  }));
};
