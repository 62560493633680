import * as React from "react";

function SvgLock(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.07841 4.92838C9.82015 4.35147 10.869 4 12 4C13.131 4 14.1798 4.35147 14.9216 4.92838C15.6575 5.50075 16 6.21544 16 6.88889V9H8V6.88889C8 6.21544 8.3425 5.50075 9.07841 4.92838ZM6 9V6.88889C6 5.49954 6.71107 4.23592 7.85053 3.34968C8.98414 2.46797 10.4788 2 12 2C13.5212 2 15.0159 2.46797 16.1495 3.34968C17.2889 4.23592 18 5.49954 18 6.88889V9H19C20.6569 9 22 10.3431 22 12V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V12C2 10.3431 3.34315 9 5 9H6ZM17 11H19C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V12C4 11.4477 4.44772 11 5 11H7H17ZM12 17C11.4477 17 11 16.5523 11 16V15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15V16C13 16.5523 12.5523 17 12 17Z"
        fill="#8F8F90"
      />
    </svg>
  );
}

export default SvgLock;
