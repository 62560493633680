import React, { useMemo } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";

import { parseClasses } from "@tint_fe/helpers";
import { Carousel, Img, Modal, ModalCloseButton, RoomAreaIcon, RoomBedTypeIcon } from "@tint_fe/ui";
import { isEmpty } from "lodash";

const HotelRoomDetilsModal = ({ isOpen, closeModal, room }) => {
  const { mainCls, elCls } = parseClasses({ base: "hotel-room-details-modal" });
  const rootId = useSelector(({ global }) => global.rootId);
  const hasValidRoomSize =
    room?.roomSize.includes("sq") || room?.roomSize.includes("feet") || room?.roomSize.includes(" m ");
  //   const restAmenities = room?.restAmenities;
  const images = room?.room_info?.url_room ?? [];

  const imgsList = useMemo(() => {
    return images.map((image) => <Img key={uuidV4()} className={elCls("img")} alt={image} src={image} />);
  }, [images]);

  return (
    <Modal closeModal={closeModal} className={mainCls} isOpen={isOpen} rootId={rootId}>
      <ModalCloseButton onClose={closeModal} className={elCls("close-btn")} />
      <p className={elCls("section-title")}>{"Room information"}</p>
      <p className={elCls("title", "second-font")}>{room?.room_info?.name}</p>
      {hasValidRoomSize && (
        <div className="mg-t-4">
          <div className={elCls("row")}>
            <RoomAreaIcon />
            <p className={elCls("main-facility")}>{room?.roomSize}</p>
          </div>
          <div className={elCls("row")}>
            <RoomBedTypeIcon />
            <p className={elCls("main-facility")}>{room?.bedType}</p>
          </div>
        </div>
      )}

      {!isEmpty(images) && (
        <Carousel
          config={{
            perView: 1,
          }}
          className={elCls("carousel")}
          list={imgsList}
        />
      )}

      <p className={elCls("section-title", "mg-t-4")}>{"Room Facilities"}</p>
      <div className={elCls("amenities-list")} dangerouslySetInnerHTML={{ __html: room?.room_info?.description }} />
      {/* <div>
        {restAmenities?.map((el) => (
          <div key={el} dangerouslySetInnerHTML={{ __html: el }} />
        ))}
      </div> */}
    </Modal>
  );
};

HotelRoomDetilsModal.propTypes = {
  isOpen: T.bool.isRequired,
  closeModal: T.func.isRequired,
  room: T.object,
};

export default HotelRoomDetilsModal;
