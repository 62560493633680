import React from "react";
import i18n from "../../../../../widgets/booking/src/i18n";
import {
  CheckmarkBadge,
  ConfirmationHotel,
  ConfirmationNumber,
  ConfirmationPax,
  ConfirmationPriceTag,
  ConfirmationTour,
} from "../../assets/icons";
import { bookingTypes } from "@tint_fe/helpers";

export const generateLines = ({
  bookingType,
  orderNumber,
  productTitle,
  productTitleExtra,
  paxInfo,
  productItems,
  originalyOneProductItem,
  totalPrice,
  totalPriceTaxInfo,
}) => {
  const lines = [];

  // Line 1: Title
  !!orderNumber &&
    lines.push({
      icon: <ConfirmationNumber />,
      text: i18n.t("hotels.success.bookingNumber"),
      value: orderNumber,
    });

  // Line 2: Main product name
  const prodType =
    bookingType === bookingTypes.HOTEL
      ? i18n.t("hotels.success.hotel")
      : bookingType === bookingTypes.TOUR
      ? i18n.t("tours.success.tour")
      : null;
  if (!!productTitle && prodType) {
    lines.push({
      icon: bookingType === bookingTypes.HOTEL ? <ConfirmationHotel /> : <ConfirmationTour />,
      text: prodType,
      value: `${productTitle} ${productTitleExtra ? `(${productTitleExtra.trim()})` : ""}`,
    });
  }

  // Line 3: Pax info
  !!paxInfo &&
    lines.push({
      icon: <ConfirmationPax />,
      text: "",
      value: `People: ${paxInfo}`,
    });

  // Line 4: Extra booking info
  // hotel or tour or activity
  if (originalyOneProductItem) {
    if (productItems.length > 1) {
      const extraProductItems = productItems.slice(1);
      extraProductItems.forEach((productItem) => {
        lines.push({
          icon: <CheckmarkBadge />,
          extraText: productItem.display_name,
        });
      });
    }
  } else {
    // smart package
    const extraLineItems = productItems.map((pi) => pi.extra_line_items).flat();
    extraLineItems.forEach((eli) => {
      if (eli.quantity === 0 || !eli?.post_booking_paid) return;

      lines.push({
        icon: <CheckmarkBadge />,
        extraText: `${eli.quantity} x ${eli.display_name}`,
      });
    });
  }

  // Line 5: Total price
  !!totalPrice &&
    lines.push({
      icon: <ConfirmationPriceTag />,
      text: i18n.t("summary.totalCharge"),
      value: totalPrice,
      extraText: totalPriceTaxInfo,
    });

  return lines;
};
