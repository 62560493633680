import * as React from "react";

function SvgFood(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g clipPath="url(#food_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-7.02-.132c.76.201 1.7-.13 2.346-.9.834-.994.864-2.342.067-3.011-.798-.67-2.12-.406-2.954.588-.642.765-.807 1.74-.486 2.453L6.903 8.25l-3.315 3.893L4.609 13l3.459-4.061 3.432 4.046.883-.74-3.55-4.206.146-.171zM3.993 4.902c0-.657.252-1.288.705-1.763l2.383 2.824-1.511 1.8-.964-1.15a2.621 2.621 0 01-.613-1.684v-.027z"
          fill="#2E1702"
        />
      </g>
      <defs>
        <clipPath id="food_svg__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgFood;
