import * as types from "./productActionTypes";
import * as sharedTypes from "@tint_fe/redux-utils/src/global/globalActionTypes";

export const INIT_STATE = {
  data: {
    available_dates: [],
    settings: {},
  },
  step: "",
  availableDates: [],
  priceBands: [],
  availability: {},
  pickupPointsFromLocation: [],
  error: {},
  priceBandsFetching: false,
  availabilityFetching: false,

  scheduleGroups: [],
  scheduleGroupsFetching: false,
};

const product = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        data: payload,
        ...(payload?.available_dates && {
          availableDates: payload.available_dates,
        }),
      };
    case types.GET_TOUR_PRICE_BANDS_REQUEST:
      return {
        ...state,
        priceBandsFetching: true,
      };
    case types.GET_TOUR_PRICE_BANDS_SUCCESS:
      return {
        ...state,
        priceBands: payload,
        priceBandsFetching: false,
      };
    case types.GET_TOUR_AVAILABILITY_REQUEST:
      return {
        ...state,
        availabilityFetching: true,
      };
    case types.GET_TOUR_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: payload,
        availabilityFetching: false,
      };
    case types.GET_PICKUP_POINTS_BY_LOCATION_SUCCESS:
      return {
        ...state,
        pickupPointsFromLocation: payload,
      };
    case types.GET_SCHEDULE_ACTIVITY_REQUEST:
      return {
        ...state,
        scheduleGroupsFetching: true,
      };
    case types.GET_SCHEDULE_ACTIVITY_SUCCESS:
      return {
        ...state,
        scheduleGroups: payload?.groups || [],
        scheduleGroupsFetching: false,
      };
    case types.GET_SCHEDULE_ACTIVITY_FAILURE:
    case types.REQUEST_PRODUCT_FAILED:
    case sharedTypes.SET_ERROR:
      return {
        ...state,
        error: payload,
        priceBandsFetching: false,
        availabilityFetching: false,
        scheduleGroupsFetching: false,
      };
    case sharedTypes.CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};

export default product;
