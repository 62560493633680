import { useEffect } from "react";

import useHistoryNavigation from "./useHistoryNavigation";
import { useSelector } from "react-redux";
import { QueryString } from "@tint_fe/helpers";

if ("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}

let prevPathname = "";
let prevNavigatedPathName = "";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

// States user can not navigate back
const finalOrderStates = ["completed", "confirmed", "feedback", "passport"];

const useHistoryListen = () => {
  const { listen, replace } = useHistoryNavigation();
  const order = useSelector((state) => state.order?.data);
  const orderState = order?.state;

  useEffect(() => {
    window.onpopstate = () => {
      const { hash } = document.location;
      const normalizedHash = hash.replace("#", "");

      if (normalizedHash !== prevPathname) {
        replace(normalizedHash);
      }

      scrollToTop();
    };
  }, []);

  useEffect(() => {
    const unlisted = listen((loc) => {
      prevPathname = loc.pathname;

      if (prevNavigatedPathName !== loc.pathname) {
        prevNavigatedPathName = loc.pathname;
        scrollToTop();
      }
    });

    return () => unlisted();
  }, [listen]);

  // TINT-3907: In case order passed a state of payment, always redirect to the state user left
  useEffect(() => {
    window.onpopstate = () => {
      const { hash, search } = document.location;
      const searchQuery = QueryString.parse(search);
      const { source: searchSource = "" } = searchQuery;

      if (searchSource) {
        return;
      }

      const normalizedHash = hash.replace("#", "");
      const isFinalOrderState = finalOrderStates.includes(orderState);
      const isPrevPathnameAlreadyFinal = finalOrderStates.includes(prevPathname);

      if (isFinalOrderState && normalizedHash !== prevPathname && !isPrevPathnameAlreadyFinal) {
        replace(prevPathname);
      }

      scrollToTop();
    };
  }, [orderState]);
};

export default useHistoryListen;
