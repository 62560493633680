import React from "react";
import T from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import NumericField from "../../../components/Form/NumericField";

const SPNumericField = ({ name, label, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line no-unused-vars
      render={({ field: { onChange, value, ref, ...restField }, fieldState: { error } }) => {
        return (
          <NumericField
            label={label}
            id={name}
            value={value}
            onChange={(data) => {
              return onChange(data[name]);
            }}
            errors={error}
            type="range"
            {...restField}
            {...rest}
          />
        );
      }}
    />
  );
};

SPNumericField.propTypes = {
  name: T.string,
  label: T.string,
};

export default SPNumericField;
