import * as React from "react";
const AlternativesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill="#21AFE4"
      fillRule="evenodd"
      d="M10.02 1.036A8.134 8.134 0 0 0 2.644 4V2.36a.844.844 0 0 0-1.688 0V6.3c0 .465.378.843.844.843h.42a.84.84 0 0 0 .056 0H5.74a.844.844 0 0 0 0-1.687H3.663a6.446 6.446 0 0 1 11.7 2.76.844.844 0 0 0 1.672-.233 8.133 8.133 0 0 0-7.016-6.947ZM1.684 9.064a.844.844 0 0 1 .952.72 6.446 6.446 0 0 0 11.7 2.76h-2.073a.844.844 0 1 1 0-1.688h3.464a.92.92 0 0 1 .047 0h.426c.466 0 .843.378.843.844v3.936a.844.844 0 1 1-1.687 0v-1.639a8.13 8.13 0 0 1-7.376 2.966 8.134 8.134 0 0 1-7.016-6.947.844.844 0 0 1 .72-.952Z"
      clipRule="evenodd"
    />
  </svg>
);
export default AlternativesIcon;
