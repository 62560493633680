import { bookingTypes } from "@tint_fe/helpers";
import { useSelector } from "react-redux";

const useTourStartLocation = () => {
  const product_items = useSelector(({ order }) => order.data.product_items || {});
  const bookingType = useSelector(({ order }) => order.data.build_type);

  if (bookingType !== bookingTypes.TOUR) {
    return {
      startLocation: {},
    };
  }

  const time = product_items && product_items[0]?.start_at;
  const date = product_items && (product_items?.package_start_date || time);
  const name = product_items && (product_items[0]?.pickup_point?.name || product_items[0]?.custom_pickup_point);

  return {
    startLocation: { date, name, time },
  };
};

export default useTourStartLocation;
