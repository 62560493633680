import { createActivityOrderRequest } from "../redux/order/orderActions";
import { steps } from "../helpers/constants";
import { useHistoryNavigation, useReduxAction } from ".";
import { setSessionStorageProductId, setSessionStorageSourceLink } from "../storage/sessionStorage";
import { base64ToBytes } from "@tint_fe/helpers";

const useCreateActivityOrderFromQueryParams = () => {
  const createActivityOrder = useReduxAction(createActivityOrderRequest);
  const history = useHistoryNavigation();

  return () => {
    let activityInfo = {};

    try {
      const search = new URLSearchParams(document.location.search);
      const activity = search.get("activity");

      search.delete("activity");
      const string = search.toString();
      const sourceLink = `${window.location.origin}${window.location.pathname}${string ? `?${string}` : ""}`;

      activityInfo = activity ? JSON.parse(atob(activity)) : {};

      setSessionStorageProductId(activityInfo.product_id);
      setSessionStorageSourceLink(activityInfo.source_link);
      delete activityInfo.source_link;

      if (activityInfo.custom_pickup_point) {
        activityInfo.custom_pickup_point = new TextDecoder().decode(base64ToBytes(activityInfo.custom_pickup_point));
      }

      if (activityInfo.start_from) {
        activityInfo.start_from = new TextDecoder().decode(base64ToBytes(activityInfo.start_from));
      }

      createActivityOrder({
        body: {
          order: {
            product_items_attributes: { 0: activityInfo },
            source_link: sourceLink,
          },
        },
        history,
        productType: steps.ACTIVITY_REDIRECT,
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export default useCreateActivityOrderFromQueryParams;
