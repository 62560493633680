import { getProductCode } from "../utils";
import { getUserFromSessionStorage } from "../storages/userStorage";
import { bookingTypes, googleAnalyticsProductTypes } from "../constants/index";

export function initAnalytics() {
  window.dataLayer = window.dataLayer || [];
}

export function gtag(arg) {
  const user = getUserFromSessionStorage();

  window?.dataLayer?.push({
    ...arg,
    logged_in: !!user,
    ...(!!user && { user_name: `${user.first_name} ${user.last_name}`, user_email: user.email }),
  });
}

export const parseProductItems = (product_items, price, build_type) => {
  if (!product_items) return [];

  const findPrice = (product) => {
    if (!price?.product_items?.length) return null;

    const priceObj = price.product_items.find((el) => {
      return (
        el.id === product.id ||
        (product?.product_option_id && el?.product_option_id && product.product_option_id === el.product_option_id)
      );
    });

    return Number(priceObj?.taxed_components_price || priceObj?.tint_charge_price || 0);
  };

  const showCategory2 = (product) => {
    return (product.klass === "tour" || product.klass === "hotel") && product.category_name;
  };

  return product_items.map((product) => {
    const calculatedPrice = findPrice(product);
    const category = [bookingTypes.SMART_PACKAGE, bookingTypes.CUSTOM_PACKAGE, bookingTypes.BUNDLE].includes(build_type)
      ? `${googleAnalyticsProductTypes[build_type]}.${googleAnalyticsProductTypes[product.klass]}`
      : googleAnalyticsProductTypes[product.klass];

    return {
      id: product.code || getProductCode(product),
      name: product.display_name,
      brand: window.location.hostname,
      quantity: 1,
      ...(calculatedPrice && { price: findPrice(product) }),
      category,
      ...(showCategory2(product) && {
        item_category2: product.category_name,
      }),
      ...(product.klass === "tour" && {
        item_category3: product.kind === "regular" ? "Public Tours" : "Private Tours",
      }),
    };
  });
};

export const getExtrasFromTour = ({ order, price }) => {
  if (order?.product_items?.[0]?.klass !== "tour" || !price?.product_items?.[0]?.line_items) {
    return [];
  }

  const extras = price.product_items[0].line_items.filter(
    (el) => el.quantity && el.product_option_type === "ProductExtra",
  );

  return extras.map((el) => ({
    id: el.product_option_id,
    name: el.display_name,
    category: "Tours",
    item_category3: "Extras",
    price: Number(el.rounded_price_per_person),
    quantity: el.quantity,
  }));
};

export const googleAnalyticsTransaction = (order, price, payment_method = "") => {
  const priceValue = Number(price?.tintChargePrice || price?.tint_charge_price || 0);
  const currency = price?.charge_currency || "USD";
  const extras = getExtrasFromTour({ order, price });

  // This is gtag for GA4
  gtag({
    event: "purchase",
    ecommerce: {
      transaction: {
        transaction_id: order.number,
        order_id: order.number,
        currency,
        ...(order.package_id && { product_id: order.package_id }),
        value: priceValue,
        payment_type: payment_method,
        checking_date: order.package_start_date,
        user_email: order?.customer?.email || "",
        type: order.build_type,
        ...(order.display_name && { product_name: order.display_name }),
        ...(order.discount_code && { coupon_code: order.discount_code }),
        products: [...parseProductItems(order.product_items, price, order.build_type), ...extras],
      },
    },
  });

  // This is gtag for GA Universal
  gtag({
    event: "purchase_ua",
    ecommerce: {
      purchase: {
        actionField: {
          id: order.number,
          revenue: priceValue,
        },
        order_id: order.number,
        ...(order.package_id && { product_id: order.package_id }),
        value: priceValue,
        payment_type: payment_method,
        checking_date: order.package_start_date,
        user_email: order?.customer?.email || "",
        type: order.build_type,
        ...(order.display_name && { product_name: order.display_name }),
        ...(order.discount_code && { coupon_code: order.discount_code }),
        products: [...parseProductItems(order.product_items, price, order.build_type), ...extras],
      },
    },
  });
};

export const googleAnalyticsCheckout = ({ step, order, price }) => {
  const extras = getExtrasFromTour({ order, price });

  gtag({
    event: "checkout",
    ecommerce: {
      checkout: {
        ...(step && { actionField: { step: step } }),
        order_id: order.number,
        value: Number(price?.tintChargePrice || price?.tint_charge_price || 0),
        type: order.build_type,
        products: [...parseProductItems(order.product_items, price, order.build_type), ...extras],
      },
    },
  });
};

export const googleAnalyticsAddToCart = ({ order, price }) => {
  const extras = getExtrasFromTour({ order, price });

  gtag({
    event: "addToCart",
    ecommerce: {
      currencyCode: price?.charge_currency || "USD",
      add: {
        value: Number(price?.tintChargePrice || price?.tint_charge_price || 0),
        type: order.build_type,
        products: [...parseProductItems(order.product_items, price, order.build_type), ...extras],
      },
    },
  });

  gtag({
    event: "add_to_cart",
    ecommerce: {
      currency: price?.charge_currency || "USD",
      value: Number(price?.tintChargePrice || price?.tint_charge_price || 0),
      type: order.build_type,
      items: [...parseProductItems(order.product_items, price, order.build_type), ...extras],
    },
  });
};

export const googleAnalyticsChangeLng = (value) => {
  gtag({
    event: "lang",
    value,
  });
};

export const googleAnalyticsSuggestedClick = (values) => {
  const { orderNumber = "", productName = "", productId = "", clickSource = "" } = values;
  gtag({
    event: "suggested_product_click",
    ...(orderNumber && { orderNumber }),
    ...(productName && { productName }),
    ...(productId && { productId }),
    ...(clickSource && { clickSource }),
  });
};
