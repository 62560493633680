import React from "react";
import { useSelector } from "react-redux";
import { omit } from "lodash";

import { useBreakPoints, useRouterMatch } from "@tint_fe/hooks";
import { bookingTypes, handleABTestingFeatures, join } from "@tint_fe/helpers";

import TintProsBooking from "../../components/TintProsBooking";
import RoomsTimer from "../../components/RoomsTimer";
import { useReduxAction, usePaymentLayout, useRedirectFromPayment } from "../../../hooks";
import { PAYMENT } from "../../router";
import { selectOrderExpiredTime } from "../../../redux/order/orderSelectors";
import { timeToShowTimer, updateRatesTypes } from "../../../helpers/constants";
import { updateRatesAction } from "../../../redux/global/globalActions";
import { checkIsPaidOrCanceled } from "../../../utils";
import OrderSummaryView from "../../components/OrderSummaryView";
import useBundleSummary from "./useBundleSummary";

const BundleSummary = () => {
  const updateRates = useReduxAction(updateRatesAction);

  const { number: orderNumber, product_items = [], state } = useSelector(({ order }) => order.data);
  const expiredTime = useSelector(selectOrderExpiredTime);
  const summary = useBundleSummary();
  const { isMd } = useBreakPoints();

  const isOrderPaidOrCanceled = checkIsPaidOrCanceled(state);
  const isPaymentPage = useRouterMatch(PAYMENT);
  const isPaymentLayout = usePaymentLayout();
  const { goToPrevPath, paymentExpireAt } = useRedirectFromPayment();

  const bookingType = useSelector(({ global, order }) => global?.bookingType || order.data?.build_type);
  const preventShowNotify = bookingType === bookingTypes.TOUR;
  const { countdown: ABTestCountdown } = handleABTestingFeatures();
  const showTimer = typeof ABTestCountdown !== "undefined" && isPaymentPage ? ABTestCountdown : !isOrderPaidOrCanceled;
  const renderTimer = product_items.some(({ hotel_line_items = [] }) => hotel_line_items.length > 0);

  const getTimerExpiredHandler = () => {
    return (data) => {
      isPaymentPage && goToPrevPath();

      updateRates({
        updateType: updateRatesTypes.ORDER,
        orderNumber,
        ...(isPaymentPage ? omit(data, "isPaymentPage") : data),
      });
    };
  };

  return (
    <div className={join(["order-summary__sticky", isPaymentLayout && "payment-page"], "")}>
      {!isPaymentLayout && !isMd && <OrderSummaryView showTotal {...summary} />}

      {renderTimer && (
        <RoomsTimer
          onTimeExpired={getTimerExpiredHandler()}
          expireAt={isPaymentPage ? paymentExpireAt : expiredTime}
          timeToShow={isPaymentPage ? timeToShowTimer.PAYMENT : timeToShowTimer.ORDER}
          hideTimer={!showTimer}
          preventShowNotify={preventShowNotify ?? true}
        />
      )}

      <TintProsBooking {...(isPaymentLayout && { className: "payment-page" })} />
    </div>
  );
};

export default BundleSummary;
