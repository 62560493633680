import { isUndefined, omitBy } from "lodash";

import { steps } from "../../../helpers/constants";

export const serializeExtras = (orderProductExtraLineItems, extrasInfo, reset) => {
  const extras = omitBy({ ...extrasInfo }, isUndefined);

  const getQuantity = (product_option_quantity, quantity) => {
    if (reset) return 0;
    if (product_option_quantity !== "undefined") return product_option_quantity;
    if (quantity) return quantity;
  };

  return orderProductExtraLineItems.reduce((acc, { id, product_option_id, quantity }) => {
    acc[product_option_id] = {
      id,
      product_option_id,
      quantity: getQuantity(extras?.[product_option_id], quantity) || 0,
    };

    return acc;
  }, {});
};

export const allowShowExtras = (step) => {
  return (
    step === steps.EXTRAS ||
    step === steps.CUSTOMER ||
    step === steps.PASSPORT ||
    step === steps.COMPLETED ||
    step === steps.CONFIRMED ||
    !step
  );
};
