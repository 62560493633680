import * as React from "react";

function SvgClose(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g clipPath="url(#close_svg__clip0)" stroke="#151209" strokeWidth={1.5}>
        <path d="M23.965 24.035L16 16.071l8.036-8.035" />
        <path d="M8.071 8l8.071 8.071-8.07 8.071" />
      </g>
      <defs>
        <clipPath id="close_svg__clip0">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgClose;
