const SMART_PACKAGE_FORM_DATA = "SMART_PACKAGE_FORM_DATA";
const ACTIVITY_FORM_DATA = "ACTIVITY_FORM_DATA";
const HOTEL_FORM_DATA = "HOTEL_FORM_DATA"; // Being used only for widget_issue
const PRODUCT_ID = "product_id";
const SOURCE_LINK = "source_link";

export const setSmartPackageSessionStorage = (data) => {
  return sessionStorage.setItem(SMART_PACKAGE_FORM_DATA, JSON.stringify(data));
};

export const getSmartPackageSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem(SMART_PACKAGE_FORM_DATA));
};

export const clearSessionStorage = () => sessionStorage.clear();

export const setSessionStorageProductId = (id) => {
  return sessionStorage.setItem(PRODUCT_ID, id || "");
};

export const getSessionStorageProductId = () => {
  return sessionStorage.getItem(PRODUCT_ID);
};

export const removeSessionStorageProductId = () => {
  return sessionStorage.removeItem(PRODUCT_ID);
};

export const setSessionStorageSourceLink = (link) => {
  return sessionStorage.setItem(SOURCE_LINK, link || "");
};

export const getSessionStorageSourceLink = () => {
  return sessionStorage.getItem(SOURCE_LINK);
};

export const removeSessionStorageSourceLink = () => {
  return sessionStorage.removeItem(SOURCE_LINK);
};

export const setHotelSessionStorage = (data) => {
  return sessionStorage.setItem(HOTEL_FORM_DATA, JSON.stringify(data));
};

export const getHotelSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem(HOTEL_FORM_DATA));
};

export const setActivitySessionStorage = (data) => {
  return sessionStorage.setItem(ACTIVITY_FORM_DATA, JSON.stringify(data));
};

export const getActivitySessionStorage = () => {
  return JSON.parse(sessionStorage.getItem(ACTIVITY_FORM_DATA));
};

export * from "@tint_fe/helpers/storages/sessionStorage";
