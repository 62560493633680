import * as React from "react";
const ConfirmationPax = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g fill="#212121" clipPath="url(#a)">
      <path d="M5.893 10.31c0 .872.218 1.744.545 2.508h-4.47c-.655 0-1.31-.327-1.636-.873-.328-.654-.437-1.308-.219-1.853.437-1.09 1.309-1.963 2.29-2.509-.436-.545-.763-1.308-.763-2.071 0-1.636 1.418-3.054 3.053-3.054a3.048 3.048 0 0 1 3.054 3.054v.436c-1.09 1.09-1.854 2.617-1.854 4.362ZM23.667 11.836c-.327.546-.981.873-1.636.873h-4.47c.327-.764.545-1.636.545-2.508 0-1.745-.655-3.272-1.854-4.362v-.437a3.048 3.048 0 0 1 3.053-3.053c1.636 0 3.054 1.418 3.054 3.053 0 .764-.328 1.527-.764 2.072a6.665 6.665 0 0 1 2.29 2.617c.218.546.11 1.2-.218 1.745ZM14.617 13.8a4.385 4.385 0 0 0 1.854-3.6 4.375 4.375 0 0 0-4.362-4.361c-2.4 0-4.471 2.072-4.471 4.47 0 1.418.763 2.727 1.854 3.6-1.963.653-3.49 2.18-4.362 4.143-.327.763-.218 1.636.218 2.399.545.763 1.308 1.2 2.18 1.2h8.943c.872 0 1.744-.437 2.18-1.2.437-.763.546-1.636.219-2.4-.764-2.071-2.4-3.489-4.253-4.252Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ConfirmationPax;
