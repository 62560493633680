import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { flatten } from "lodash";

import { dates } from "@tint_fe/helpers";
import { useTranslation, useBreakPoints } from "@tint_fe/hooks";
import { SubmitButton, BackButton } from "../../components";
import Documents from "./Documents";
import Products from "./Products";
import { getMaxPaxQuantity } from "./helpers";

const formatDate = (date) => dates.format(date, "dd-MMM-yyyy");

const normalizeDocument = (document) => {
  // eslint-disable-next-line no-unused-vars
  const { key, date_of_birth, expiry_date, nationality, start_date, ...rest } = document || {};

  return {
    date_of_birth: formatDate(date_of_birth),
    expiry_date: formatDate(expiry_date),
    start_date: formatDate(start_date),
    nationality: nationality.value,
    ...rest,
  };
};

const normalizedPassportDetails = ({ documents, products }) => {
  return Object.keys(products).reduce((acc, key) => {
    const productDocuments = products[key].map((el) => {
      return normalizeDocument(documents.find((x) => x.key === el));
    });

    acc[key] = { passport_infos_attributes: { ...productDocuments } };

    return acc;
  }, {});
};

const PassportInfo = ({ setShowPassportInfo, order, updatePassportDetails, scrollIntoView }) => {
  const { isSm } = useBreakPoints();
  const ref = useRef({});
  const { handleSubmit, ...methods } = useForm({
    shouldFocusError: true,
    reValidateMode: "onChange",
  });

  const productItems = order.product_items?.filter((el) => el.passport_required);
  const singleProduct = productItems.length === 1;
  const maxPaxQuantity = getMaxPaxQuantity(order);
  const { t, i18n } = useTranslation();

  const [processing, setProcessing] = useState(false);

  const validateDocument = (document) => {
    let fail = false;

    const listForValidate = [
      { name: "first_name", label: "formField.firstName" },
      { name: "last_name", label: "formField.lastName" },
      { name: "date_of_birth", label: "formField.dateOfBirth" },
      { name: "nationality", label: "formField.nationality" },
      { name: "number", label: "formField.passportNumber" },
      { name: "start_date", label: "formField.dateOfIssue" },
      { name: "expiry_date", label: "formField.expirationDate" },
    ];

    listForValidate.forEach(({ name, label }) => {
      if (!document[name]) {
        fail = true;
        methods.setError(`documents.${document.index}.${name}`, {
          type: "manual",
          message: { text: "error.required", fieldName: label },
        });
      }
    });

    if (fail) {
      throw new Error("Failed validation");
    }
  };

  const validateDocuments = ({ documents, products }) => {
    const selectedDocumentsKeys = [...new Set(flatten(Object.values(products)))];
    const selectedDocuments = documents.reduce((arr, el, index) => {
      if (selectedDocumentsKeys.includes(el.key)) {
        return [...arr, { ...el, index }];
      }

      return arr;
    }, []);

    selectedDocuments.forEach((el) => validateDocument(el));
  };

  const validateProducts = (products) => {
    let fail = false;

    Object.keys(products).forEach((key) => {
      const documents = products[key];

      documents.forEach((document, index) => {
        if (!document) {
          fail = true;

          methods.setError(`products.${key}.${index}`, {
            type: "manual",
            message: { text: "error.required", fieldName: `Traveler ${index + 1}` },
          });
        }
      });
    });

    if (fail) {
      throw new Error("Failed validation");
    }
  };

  const onSubmit = handleSubmit((val) => {
    try {
      const documents = val.documents;
      const products = singleProduct ? { [productItems[0].id]: documents.map((el) => el.key) } : { ...val.products };

      !singleProduct && validateProducts(products);
      validateDocuments({ documents, products });

      setProcessing(true);

      updatePassportDetails({
        orderNumber: order.number,
        body: { order: normalizedPassportDetails({ documents, products }) },
        onSuccess: () => {
          setProcessing(false);
          setShowPassportInfo(false);
        },
        onFailed: () => {
          setProcessing(false);
        },
      });
    } catch (error) {
      console.error(error);
    }
  });

  const scrollPassportIntoView = () => {
    setTimeout(() => ref.current?.scrollIntoView({ block: "center", behavior: "smooth" }), 0);
  };

  useEffect(() => {
    if (scrollIntoView) {
      scrollPassportIntoView();
    }
  }, [scrollIntoView]);

  useEffect(() => {
    scrollPassportIntoView();
  }, []);

  return (
    <FormProvider {...methods} handleSubmit={handleSubmit}>
      <div ref={ref} className="passport">
        <div className="passport__heading h3">{t("passport.headingText")}</div>
        <Documents singleProduct={singleProduct} order={order} paxQuantity={maxPaxQuantity} />
        {!singleProduct && <Products productItems={productItems} maxPaxQuantity={maxPaxQuantity} />}

        <div className="passport__controls">
          <BackButton className="passport__controls-back" onClick={() => setShowPassportInfo(false)} isBackBtn ghost>
            {t("passport.finishLater")}
          </BackButton>
          <SubmitButton
            loading={processing}
            type="submit"
            className="btn-submit passport__controls-submit"
            onClick={onSubmit}
          >
            {t(i18n.language === "de" || isSm ? "complete" : "completeMyBooking")}
          </SubmitButton>
        </div>
      </div>
    </FormProvider>
  );
};

PassportInfo.propTypes = {
  setShowPassportInfo: T.func.isRequired,
  order: T.object,
  updatePassportDetails: T.func.isRequired,
  scrollIntoView: T.bool,
};

export default PassportInfo;
