import React, { useEffect, useRef } from "react";
import T from "prop-types";

import { Button, ControlledInput } from "../";
import { useTranslation, useOutsideClickHandler } from "@tint_fe/hooks";
import { parseClasses, validationRule } from "@tint_fe/helpers";
import { useForm } from "react-hook-form";
import { fetchUserCountryApi, postWidgetIssues } from "@tint_fe/api";
import { buildNotificationHeading, buildNotificationMessage } from "./helpers";

const normalizeFormValues = (val) => {
  const { full_name, phone, email } = val;
  const first_name = full_name.split(" ").slice(0, 1).join(" ");
  const last_name = full_name.split(" ").slice(1).join(" ");
  const formattedPhone = phone.replace(/[^0-9+]/g, "");
  delete val.full_name;

  return {
    ...val,
    first_name,
    last_name,
    email: email.trim().toLowerCase(),
    phone: formattedPhone,
  };
};

// Product configuration from session storage
const parseContext = (data = {}) => {
  let finalString = "";
  // Shared for sp and cp
  Object.keys(data).forEach((el) => {
    if (typeof data[el] === "object") {
      return;
    }
    finalString += `${el}=${data[el]},`;
  });
  if (data?.rooms) {
    data.rooms.forEach((room, index) => {
      finalString += `room${index + 1}.adults=${room?.adults ?? 0}.children=${(room?.children ?? []).length},`;
    });
  }

  // cp extra fields
  const {
    package_cities_to_visit = [],
    package_places_to_visit = [],
    package_regions_to_visit = [],
    tag_ids = [],
    tours_selected_tag_ids = [],
  } = data;
  if (package_cities_to_visit.length) {
    finalString += `package_cities_to_visit=${package_cities_to_visit.join(".")},`;
  }
  if (package_places_to_visit.length) {
    finalString += `package_places_to_visit=${package_places_to_visit.join(".")},`;
  }
  if (package_regions_to_visit.length) {
    finalString += `package_regions_to_visit=${package_regions_to_visit.join(".")},`;
  }
  if (tag_ids.length) {
    finalString += `tag_ids=${tag_ids.join(".")},`;
  }
  if (tours_selected_tag_ids.length) {
    finalString += `tours_selected_tag_ids=${tours_selected_tag_ids.join(".")},`;
  }

  return finalString;
};

const AvailabilityNotificationUI = ({
  containerClassName,
  closeToast,
  brandName,
  refErrorCode,
  productName,
  productConfiguration,
  priceCustomerLastSeen,
  user,
  message,
}) => {
  const ref = useRef();
  const { control, handleSubmit, setValue } = useForm();
  const { t } = useTranslation();
  const { elCls } = parseClasses({
    base: "notification",
    additional: [containerClassName],
  });

  useOutsideClickHandler(ref, () => closeToast());

  const notiHeading = buildNotificationHeading(refErrorCode || message, t);
  const notiMessage = buildNotificationMessage(message, t, brandName);

  /* 
    8 params configured on BE to accept: 
    ref_title, ref_url, ref_context, ref_price,
    ref_id, ref_error_code, ref_error_message country
  */
  const buildQueryParams = (ref_title, ref_context, ref_price, ref_error_code, country) => {
    let query = "";
    const parsed_context = parseContext(ref_context);
    if (ref_title) query += `details[ref_title]=${ref_title}&`;
    if (ref_context) query += `details[ref_context]=${parsed_context}&`;
    if (ref_price) query += `details[ref_price]=${ref_price}&`;
    if (ref_error_code) query += `details[ref_error_code]=${ref_error_code}&`;
    if (notiMessage)
      query += `details[ref_error_message]=${notiMessage.replace(/[&\\/\\#,+()$~%.'":*?<>{}]|(?! )/g, "")}&`;
    if (country) query += `details[country]=${country}&`;
    query += `details[ref_url]=${window.location.href}`;

    return query;
  };

  const postWidgetIssuesFn = async (formValues) => {
    const country = await fetchUserCountryApi();
    const queryParam = buildQueryParams(
      productName,
      productConfiguration,
      priceCustomerLastSeen,
      refErrorCode,
      country,
    );
    await postWidgetIssues({
      body: { ...formValues },
      queryParam,
    });
  };

  const submitForm = handleSubmit((val) => {
    const formValues = normalizeFormValues(val);
    postWidgetIssuesFn(formValues);
    closeToast();
  });

  useEffect(() => {
    if (user?.email) {
      setValue("email", user.email);
    }
    if (user?.first_name || user?.last_name) {
      setValue("full_name", `${user?.first_name} ${user?.last_name}`);
    }
  }, [user]);

  return (
    <div ref={ref} className={elCls("content")}>
      <p className="mg-0 p5 bold mg-b-1">{notiHeading}</p>
      <p className={"mg-0 p4"}>{notiMessage}</p>
      <div className="customer-form__row mg-t-3">
        <ControlledInput
          control={control}
          classNameWrapper={elCls("input")}
          name="full_name"
          placeholder={"Your full name"}
          label={"Full Name*"}
          removeStartEndSpaces
          rules={{ ...validationRule.required("Full Name") }}
        />
        <ControlledInput
          control={control}
          classNameWrapper={elCls("input")}
          name="email"
          placeholder={"example@gmail.com"}
          label={"Email*"}
          rules={{
            ...validationRule.required(t("formField.emailAddress")),
            ...validationRule.email,
          }}
        />
        <ControlledInput
          control={control}
          classNameWrapper={elCls("input")}
          name="phone"
          type="tel"
          placeholder={"+1 (800) 555‑0175"}
          label={"Phone number*"}
          removeStartEndSpaces
          rules={{
            ...validationRule.required("Phone number"),
            ...validationRule.minLength(t("Phone number"), 10),
          }}
        />
      </div>
      <Button small background="dark" onClick={submitForm} className={elCls("btn")}>
        {"Submit"}
      </Button>
    </div>
  );
};

AvailabilityNotificationUI.propTypes = {
  containerClassName: T.string,
  hideBtn: T.bool,
  handleClickOnBtn: T.func,
  children: T.node,
  closeToast: T.func,
  message: T.string,
  refErrorCode: T.string,

  // custom params only for this type of notification
  buildType: T.string,
  productName: T.string,
  brandName: T.string,
  productConfiguration: T.object,
  priceCustomerLastSeen: T.string,
  // For custom package preselection
  user: T.object,
};

export default AvailabilityNotificationUI;
