import api from "./api";

export const getUserApi = () => {
  return api.get({ path: "/users", authorizationCustomer: true });
};

export const updateUserApi = (body) => {
  return api.put({ path: "/users", body, authorizationCustomer: true });
};

export const confirmEmailApi = (body) => {
  return api.post({ path: "/users/email", body });
};

export const confirmEmailViaTokenApi = (body) => {
  return api.put({ path: "/users/confirm", body });
};

export const signInApi = (body) => {
  return api.post({ path: "/users/sign_in", body });
};

export const signUpApi = (body) => {
  return api.post({ path: "/users/sign_up", body });
};

export const forgotPasswordApi = (body) => {
  return api.post({ path: "/users/password", body });
};

export const resetPasswordApi = (body) => {
  return api.put({ path: "/users/reset", body });
};

export const acceptInvitationApi = (body) => {
  return api.put({ path: "/users/invitation", body });
};

export const signOutApi = () => {
  return api.delete({ path: "/users/sign_out" });
};

export const getUserOrdersApi = (params) => {
  return api.get({
    path: "/users/orders",
    params,
    authorizationCustomer: true,
    pagination: true,
  });
};

export const getUserOrderDetailsApi = ({ orderNumber, uuid }) => {
  return api.get({ path: `/users/orders/${orderNumber}`, authorizationCustomer: true, uuid });
};

export const sendItineraryEmail = ({ orderNumber }) => {
  return api.post({
    path: `/users/orders/${orderNumber}/itinerary_pdf_mail`,
    authorizationCustomer: true,
  });
};

export const getSavedCreditCardDetails = () => {
  return api.get({ path: "/users/saved_credit_cards", authorizationCustomer: true });
};
