import { useFormContext } from "react-hook-form";

const useWatchHotelCheckoutForm = () => {
  const { watch } = useFormContext() || {};
  if (!watch) return {};

  return {
    start_date: watch("start_date"),
    end_date: watch("end_date"),
    rooms: watch("rooms"),
  };
};

export default useWatchHotelCheckoutForm;
