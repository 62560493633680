import { useEffect, useState } from "react";

const useSelectedExtras = (order) => {
  const productItems = order?.product_items ?? [];
  const extras = productItems
    .map((pi) => {
      return pi.extra_line_items.map((eli) => {
        return { ...eli, product_item_id: pi.id };
      });
    })
    .flat();
  const [selectedExtras, setSelectedExtras] = useState(
    extras.filter((extra) => !extra?.post_booking_paid && extra.quantity),
  );

  const [selectedProductExtras, setSelectedProductExtras] = useState(
    productItems.filter((pi) => !pi?.post_booking_paid && pi.pax_quantity),
  );

  useEffect(() => {
    setSelectedExtras(extras.filter((extra) => !extra?.post_booking_paid && extra.quantity));
    setSelectedProductExtras(productItems.filter((pi) => !pi?.post_booking_paid && pi.pax_quantity));
  }, [order]);

  const paidAndSelectedExtras = [
    ...selectedExtras,
    ...extras.filter((extra) => extra?.post_booking_paid && extra.quantity),
  ];

  const paidAndSelectedProductExtras = [
    ...selectedProductExtras,
    ...productItems.filter((pi) => pi?.post_booking_paid && pi.pax_quantity),
  ];

  return {
    selectedExtras,
    setSelectedExtras,
    selectedProductExtras,
    setSelectedProductExtras,
    paidAndSelectedExtras,
    paidAndSelectedProductExtras,
  };
};

export default useSelectedExtras;
