import PubSub from "pubsub-js";

export const TINT_JWT = "TINT_JWT";

export const subscribeJWTChange = (cb) => {
  PubSub.subscribe(TINT_JWT, (_msg, data) => cb(data));
};

export const unsubscribeJWTChange = () => {
  PubSub.unsubscribe(TINT_JWT);
};

export const setTintJwtLocalStorage = (data) => {
  PubSub.publish(TINT_JWT, data);

  return localStorage.setItem(TINT_JWT, JSON.stringify(data));
};

export const getTintJwtLocalStorage = () => {
  return JSON.parse(localStorage.getItem(TINT_JWT));
};

export const removeTintJwtLocalStorage = () => {
  PubSub.publish(TINT_JWT, null);

  return localStorage.removeItem(TINT_JWT);
};
