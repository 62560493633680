import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { Plus, Minus, Plus24, Minus24 } from "../../assets/icons";
import { Tooltip } from "..";

const Counter = ({
  value,
  min = 0,
  max,
  type = "options",
  options = [],
  disabled,
  incrementDisabled,
  decrementDisabled,
  onChange,
  className,
  onIncreaseTip,
  onDecreaseTip,
  useLegacyIcons = true,
}) => {
  const { mainCls, elCls } = parseClasses({ base: "counter", additional: className });
  const i = max ? options.findIndex((x) => x >= max) : -1;
  const inputOptions = i > 0 ? options.slice(0, options[i] === max ? i + 1 : i) : options;
  const isDecrementDisabled =
    disabled ||
    decrementDisabled ||
    (type === "range" && min ? value <= min : value < 1) ||
    (type === "options" && value <= inputOptions[0]);

  const isIncrementDisabled =
    disabled ||
    incrementDisabled ||
    (type === "range" && max && value >= max) ||
    (type === "options" && value >= inputOptions[inputOptions.length - 1]);

  const getRangeVal = (isIncrement) => {
    if (isIncrement) {
      return max && value >= max ? max : value + 1;
    }

    if (min && value <= min) {
      return min;
    }

    return value <= 0 ? 0 : value - 1;
  };

  const getOptionVal = (isIncrement) => {
    const index = inputOptions.findIndex((item) => item === value);
    const last = inputOptions.length - 1;

    if (isIncrement) {
      return index !== -1 && index < last ? inputOptions[index + 1] : inputOptions[last];
    }

    return index !== -1 && index > 0 ? inputOptions[index - 1] : inputOptions[0];
  };

  const handleChangeField = (isIncrement) => {
    const newValue = type === "range" ? getRangeVal(isIncrement) : getOptionVal(isIncrement);

    onChange(newValue, isIncrement);
  };

  return (
    <div className={mainCls}>
      <Tooltip placement="top" {...(!onDecreaseTip && { visible: false })} text={onDecreaseTip}>
        <button
          type="button"
          className={elCls("button", isDecrementDisabled && "disabled")}
          onClick={() => !isDecrementDisabled && handleChangeField(false)}
        >
          {useLegacyIcons ? <Minus /> : <Minus24 />}
        </button>
      </Tooltip>

      <div className={elCls("value", "bold")}>{value}</div>

      <Tooltip placement="top" {...(!onIncreaseTip && { visible: false })} text={onIncreaseTip}>
        <button
          type="button"
          className={elCls("button", isIncrementDisabled && "disabled")}
          onClick={() => !isIncrementDisabled && handleChangeField(true)}
        >
          {useLegacyIcons ? <Plus /> : <Plus24 />}
        </button>
      </Tooltip>
    </div>
  );
};

Counter.propTypes = {
  value: T.number,
  min: T.number,
  max: T.number,
  type: T.oneOf(["options", "range"]),
  options: T.array,
  disabled: T.bool,
  incrementDisabled: T.bool,
  decrementDisabled: T.bool,
  onChange: T.func.isRequired,
  className: T.string,
  onIncreaseTip: T.string,
  onDecreaseTip: T.string,
  useLegacyIcons: T.bool,
};

export default Counter;
