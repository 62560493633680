import React from "react";
import T from "prop-types";

import NumericField from "../../../../components/Form/NumericField";
import TourExtrasCard from "./TourExtrasCard";

const TourExtrasItem = ({ extra, quantity, price, max, updateExtrasHandler, preventScrollIntoView, currency }) => {
  const { capacity_values = [] } = extra;
  const isCounting = capacity_values.length > 2 && max > 1;
  const isNumeric = !extra.images.length && !extra.descriptions.length;

  return isNumeric ? (
    <NumericField
      shouldUseSwitch={!isCounting}
      label={extra.name}
      price={price}
      id={extra.id}
      max={max}
      byItem={extra?.by_item}
      options={extra.capacity_values}
      value={quantity}
      currency={currency}
      onChange={(val) => {
        if (typeof val === "boolean") {
          return updateExtrasHandler({
            [extra.id]: val ? 1 : 0,
          });
        }

        return updateExtrasHandler({
          [extra.id]: val[extra.id],
        });
      }}
    />
  ) : (
    <TourExtrasCard
      extra={extra}
      isCounting={isCounting}
      price={price}
      updateExtrasHandler={updateExtrasHandler}
      max={max}
      quantity={quantity}
      preventScrollIntoView={preventScrollIntoView}
      currency={currency}
    />
  );
};

TourExtrasItem.propTypes = {
  extra: T.object,
  extrasInput: T.object,
  price: T.oneOfType([T.number, T.string]),
  max: T.number,
  updateExtrasHandler: T.func,
  quantity: T.number,
  preventScrollIntoView: T.bool,
  currency: T.string,
};

export default TourExtrasItem;
