import React from "react";
import T from "prop-types";
import { Link } from "react-router-dom";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

import { RoundSpinner } from "../../assets/icons";
import { Loader } from "..";

const ICON_TYPES = {
  prev: "prev-icon",
  next: "next-icon",
};

const Button = ({
  as,
  ghost,
  className,
  small,
  round,
  big,
  isBackBtn,
  children,
  background,
  processing,
  loadingSpinner,
  withoutBorder,
  likeDisabled,
  prevIcon,
  nextIcon,
  isSubmit,
  fullsize,
  overrideCls, // some globas styles does override .btn styles, so we need to override them back
  forceDisabled,
  ...rest
}) => {
  const { isRtl } = useTranslation();
  const cls = overrideCls ? "btn_override" : "btn";
  const { mainCls, elCls } = parseClasses({
    base: cls,
    classes: [
      ghost && "ghost",
      (prevIcon || nextIcon) && "icon",
      nextIcon && "next-icon",
      prevIcon && "prev-icon",
      small ? "small" : "p2",
      big ? "big p4" : "p2",
      round && "round",
      withoutBorder && "without-border",
      isRtl && "rtl",
      likeDisabled && "disabled",
      isBackBtn && "is-back-btn",
      processing && "processing",
      isSubmit && "submit",
      fullsize && "fullsize",
      background,
    ],
    additional: className,
  });

  const getIcon = (icon, type) => {
    if ((round && processing) || (loadingSpinner && type === ICON_TYPES.next)) {
      return <RoundSpinner className={elCls("spinner")} />;
    }

    return icon;
  };

  const calculatedPrevIcon = getIcon(prevIcon, ICON_TYPES.prev);
  const calculatedNextIcon = getIcon(nextIcon, ICON_TYPES.next);

  const content =
    processing && !round ? (
      <Loader data-testid={"button-main-loader"} dark />
    ) : (
      <>
        {calculatedPrevIcon}
        {children}
        {calculatedNextIcon}
      </>
    );

  switch (as) {
    case "link":
      return (
        <Link data-testid="button-main-link" className={mainCls} {...rest}>
          {content}
        </Link>
      );
    case "a":
      return (
        <a data-testid="button-main-a" className={mainCls} {...rest}>
          {content}
        </a>
      );
    default:
      return (
        <button
          data-testid="button-main"
          className={mainCls}
          disabled={processing || loadingSpinner || forceDisabled}
          {...rest}
        >
          {content}
        </button>
      );
  }
};

Button.defaultProps = {
  background: "dark",
  as: "button",
  overrideCls: false,
};

Button.propTypes = {
  children: T.node.isRequired,
  ghost: T.bool,
  small: T.bool,
  round: T.bool,
  className: T.string,
  prevIcon: T.node,
  nextIcon: T.node,
  processing: T.bool,
  loadingSpinner: T.bool,
  withoutBorder: T.bool,
  as: T.oneOf(["button", "link", "a"]),
  fullsize: T.bool,
  background: T.oneOf(["dark", "light", "blue", "transparent"]),
  big: T.bool,
  isBackBtn: T.bool,
  likeDisabled: T.bool,
  isSubmit: T.bool,
  overrideCls: T.bool,
  forceDisabled: T.bool,
};

export default Button;
