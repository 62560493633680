import React, { useEffect, useRef, useMemo } from "react";
import T from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { isArray } from "lodash";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Carousel, Img } from "..";
import Badge from "./Badge";
import TintCardSelectButton from "./TintCardSelectButton";
import TintCardLink from "./TintCardLink";
import FavoriteBtn from "./FavoriteBtn";
import Counter from "../Counter/Counter";

const TintCard = ({
  selected,
  onSelect,
  imgs,
  name,
  children,
  className,
  btnText,
  title,
  withHover,
  preventScrollIntoView,
  badge,
  forceUpdateCarousel,
  role,
  onSelectToFavorite,
  isFavorite,
  href,
  isCounting,
  counterMax,
  counterOptions,
  counterQuantity,
  onChangeCounter,
}) => {
  const ref = useRef();
  const { isRtl } = useTranslation();
  const { mainCls, elCls } = parseClasses({
    base: "tint-card",
    classes: [selected && "selected"],
    additional: [className],
  });

  const { mainCls: btnClasses } = parseClasses({
    base: elCls("btn"),
    classes: [selected && "selected"],
    additional: "bold",
  });

  useEffect(() => {
    if (selected && !preventScrollIntoView) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const imgsList = useMemo(() => {
    if (Array.isArray(imgs) && imgs.length > 1) {
      return imgs.map((el) => (
        <Img key={uuidV4()} className={elCls("img")} {...(el.alt_text && { alt: el.alt_text })} src={el.image_url} />
      ));
    }

    return null;
  }, []);

  const getControl = () => {
    switch (true) {
      case isCounting:
        return (
          <Counter
            max={counterMax}
            options={counterOptions}
            value={counterQuantity}
            onChange={onChangeCounter}
            className="tint-card__counter"
          />
        );

      case role === "select":
        return (
          <TintCardSelectButton
            selected={selected}
            onSelect={onSelect}
            withHover={withHover}
            btnText={btnText}
            btnClasses={btnClasses}
          />
        );

      default:
        return <TintCardLink href={href} btnText={btnText} btnClasses={btnClasses} />;
    }
  };

  return (
    <div ref={ref} className={mainCls}>
      <div className={elCls("carousel-wrapper")}>
        {onSelectToFavorite && (
          <FavoriteBtn className={elCls("favorite")} onChange={onSelectToFavorite} checked={isFavorite} />
        )}
        {imgsList?.length ? (
          <Carousel className={elCls("carousel")} list={imgsList} isCard forceUpdateCarousel={forceUpdateCarousel} />
        ) : (
          <Img className={elCls("img")} src={isArray(imgs) ? imgs[0]?.image_url || "" : imgs || ""} alt={name} />
        )}
        {React.isValidElement(badge) && <Badge>{badge}</Badge>}
      </div>

      <div className={elCls("content")}>
        <h3 dir={isRtl ? "rtl" : "ltr"} className={elCls("title", "bold")}>
          {title}
        </h3>
        {children}
        {getControl()}
      </div>
    </div>
  );
};

TintCard.defaultProps = {
  btnText: "hotel",
  showMoreText: "hotelCard.showMoreInfo",
  preventScrollIntoView: false,
  role: "select",
};

TintCard.propTypes = {
  selected: T.bool,
  onShowMoreInfo: T.func,
  onSelect: T.func,
  imgs: T.oneOfType([T.string, T.array]),
  name: T.string,
  children: T.node,
  className: T.string,
  btnText: T.string,
  showMoreText: T.string,
  withHover: T.bool,
  preventScrollIntoView: T.bool,
  badge: T.oneOfType([T.node, T.string]),
  role: T.oneOf(["select", "link"]),
  onSelectToFavorite: T.func,
  checked: T.bool,
  href: T.oneOfType([T.string, T.object]),
  title: T.string,
  forceUpdateCarousel: T.bool,
  isFavorite: T.bool,
  isCounting: T.bool,
  counterMax: T.number,
  counterOptions: T.array,
  counterQuantity: T.number,
  onChangeCounter: T.func,
};

export default TintCard;
