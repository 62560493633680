import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { validationRule, normalizedValidateError } from "@tint_fe/helpers";

import { useTranslation } from "../../../../../hooks";
import SelectField from "../../../../components/Form/SelectField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import GoogleAutocomplete from "../../../../components/Form/GoogleAutocomplete";
import HiddenField from "../../../../components/Form/HiddenField";
import {
  selectAvailableDropOffPoints,
  selectAvailablePickupPoints,
  selectProductSettings,
} from "../../../../../redux/product/productSelectors";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";

const EndLocationSelect = () => {
  const { t } = useTranslation();
  const dropOffPoints = useSelector(selectAvailableDropOffPoints);
  const availablePickupPoints = useSelector(selectAvailablePickupPoints);
  const { custom_dropoff_point_strategy, display_end_location, different_end_location } =
    useSelector(selectProductSettings);
  const showEndLocationFromAPI = display_end_location || different_end_location;
  const loading = useSelector((state) => state.product.priceBandsFetching);

  const updateTourPrice = useUpdateTourPrice();

  const {
    control,
    formState: { isSubmitted },
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const startDateValue = watch("start_date");
  const who_traveling = { ...watch("who_traveling") };
  const return_different_location = watch("return_different_location");
  const customDropoffPoint = watch("custom_dropoff_point");
  const pickupPointId = watch("pickup_point_id");
  const pickupPoint = availablePickupPoints.find((el) => el.id === pickupPointId);

  const getAvailableDropOffPoints = () => {
    if (dropOffPoints.length === 1) return dropOffPoints;

    return different_end_location
      ? dropOffPoints?.filter((el) => el.name !== pickupPoint?.name) || []
      : dropOffPoints || [];
  };

  const availableDropOffPoints = getAvailableDropOffPoints();

  const handleChangeDropOffId = (val) => {
    if (Object.values(who_traveling).some((el) => !!el)) {
      updateTourPrice({ formData: val }, { getExtrasFromFormContext: true });
    }

    return val;
  };

  useEffect(() => {
    if (availableDropOffPoints?.length === 1) {
      if (availableDropOffPoints[0].default_address && !customDropoffPoint && return_different_location) {
        setValue("custom_dropoff_point", { name: availableDropOffPoints[0].default_address, lat: null, lon: null });
      }

      const currDropOffPointId = getValues("dropoff_point_id");

      if (!currDropOffPointId || currDropOffPointId !== availableDropOffPoints[0].id) {
        setValue("dropoff_point_id", availableDropOffPoints[0].id);
      }
    }
  }, [availableDropOffPoints, return_different_location]);

  if (!return_different_location && !showEndLocationFromAPI) {
    return null;
  }

  if (custom_dropoff_point_strategy) {
    return (
      <ControllerInput
        control={control}
        name="custom_dropoff_point"
        render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
          <GoogleAutocomplete
            label={t("formField.endLocation")}
            placeholder={t("formField.enterLocation")}
            disabled={!startDateValue}
            errors={normalizedValidateError(error, "formField.endLocation")}
            showValidateMark={isSubmitted}
            onChange={onChange}
            onSelect={onChange}
            {...rest}
          />
        )}
        rules={{
          ...validationRule.required("formField.endLocation"),
          validate: validationRule.validateGoogleAutocompleteRequired(),
        }}
      />
    );
  }

  if (
    availableDropOffPoints?.length === 1 &&
    availablePickupPoints?.length === 1 &&
    availableDropOffPoints[0].name === availablePickupPoints[0].name &&
    !showEndLocationFromAPI
  ) {
    return (
      <ControllerInput control={control} name="dropoff_point_id" render={({ field }) => <HiddenField {...field} />} />
    );
  }

  return (
    (availableDropOffPoints?.length >= 1 || showEndLocationFromAPI) && (
      <ControllerInput
        control={control}
        name="dropoff_point_id"
        render={({ field: { onChange, ...restField }, fieldState: { error } }) => {
          return (
            <SelectField
              label={t("formField.endLocation")}
              placeholder={t("formField.selectPoint")}
              disabled={!startDateValue}
              isPreselected={availableDropOffPoints?.length === 1}
              options={availableDropOffPoints.map((el) => ({ value: el.id, label: el.name }))}
              errors={error?.message}
              noOptionsMessage={t("formField.startFrom.noOptionsMessage")}
              isSubmitting={isSubmitted}
              onChange={(val) => onChange(handleChangeDropOffId(val))}
              loading={loading}
              {...restField}
            />
          );
        }}
        rules={{ ...validationRule.required("formField.endLocation") }}
      />
    )
  );
};

export default EndLocationSelect;
