import React from "react";
import T from "prop-types";

const right = (
  <g id="Hotel-Selection" transform="translate(-302.000000, -1017.000000)" fill="#1D1D1B" fillRule="nonzero">
    <g id="Hotel-2" transform="translate(107.000000, 940.000000)">
      <g transform="translate(184.000000, 69.000000)">
        <polygon
          id="Path"
          points="11.4533333 22.12 17.56 16 11.4533333 9.88 13.3333333 8 21.3333333 16 13.3333333 24"
        ></polygon>
      </g>
    </g>
  </g>
);

const left = (
  <g id="Hotel-Selection" transform="translate(-131.000000, -1017.000000)" fill="#1D1D1B" fillRule="nonzero">
    <g id="Hotel-2" transform="translate(107.000000, 940.000000)">
      <g
        id="Slider-left"
        transform="translate(30.000000, 85.000000) rotate(-180.000000) translate(-30.000000, -85.000000) translate(14.000000, 69.000000)"
      >
        <polygon
          id="Path"
          points="11.4533333 22.12 17.56 16 11.4533333 9.88 13.3333333 8 21.3333333 16 13.3333333 24"
        ></polygon>
      </g>
    </g>
  </g>
);

const getArrow = (direction) => {
  switch (direction) {
    case "left":
      return left;
    case "right":
      return right;
    default:
      return right;
  }
};

const Arrow = ({ width, height, direction }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Web-Site" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        {getArrow(direction)}
      </g>
    </svg>
  );
};

Arrow.defaultProps = {
  width: 11,
  height: 16,
  direction: "right",
};

Arrow.propTypes = {
  width: T.number,
  height: T.number,
  direction: T.string,
};

export default Arrow;
