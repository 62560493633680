import { isObject, reduce, uniq, omit } from "lodash";

import {
  removeTintJwtLocalStorage,
  errorsMsgSettingList,
  defaultErrorMsgSettings,
  errorMsgTypes,
} from "@tint_fe/helpers";

const parseError = (errors, payload) => {
  if (typeof errors === "string") {
    return {
      messages: payload ? [...payload.messages, errors] : [errors],
      ...(payload && { fields: payload.fields }),
    };
  }

  return reduce(
    errors,
    (acc, val, key) => {
      if (typeof val === "string") {
        return {
          fields: typeof key === "string" ? uniq([...acc.fields, key]) : acc.fields,
          messages: uniq([...acc.messages, val]),
        };
      }

      const newPayload = {
        fields: [...acc.fields, key],
        messages: acc.messages,
      };

      if (Array.isArray(val)) {
        return val
          .map((el) => parseError(el, newPayload))
          .reduce(
            (accomodation, el) => ({
              ...accomodation,
              fields: uniq([...accomodation.fields, ...el.fields]),
              messages: uniq([...accomodation.messages, ...el.messages]),
            }),
            { fields: [], messages: [] },
          );
      }

      if (isObject(val)) {
        return parseError(val, newPayload);
      }
    },
    { messages: payload?.messages || [], fields: payload?.fields || [] },
  );
};

const getErrorMsgSettings = ({ messages }, status) => {
  switch (true) {
    case !!errorsMsgSettingList[messages[0]]:
      return errorsMsgSettingList[messages[0]];
    case messages.some((msg) => msg.includes("credorax")):
      return defaultErrorMsgSettings;
    case status === 400:
      return {
        type: errorMsgTypes.NEUTRAL,
        contactUsButton: true,
      };
    default:
      return defaultErrorMsgSettings;
  }
};

export const normalizeResponseErrors = (error) => {
  if (error.message === "Network Error") {
    return { messages: "Network Error", status: 503, ...defaultErrorMsgSettings };
  }

  const status = error.response?.status || 400;

  switch (status) {
    case 500:
      return {
        status,
        messages: ["Internal Server Error"],
        ...defaultErrorMsgSettings,
      };
    case 404:
      return {
        status,
        messages: ["Not Found"],
        ...defaultErrorMsgSettings,
        ...(error.response?.data?.backup_products && { backup_products: error.response?.data?.backup_products }),
      };
    case 401:
      removeTintJwtLocalStorage();

      return {
        status,
        ...parseError(error.response?.data.errors || error.response?.data.error || error.response),
        ...defaultErrorMsgSettings,
      };
    default: {
      const parsedError = parseError(error.response?.data.errors || error.response?.data.error || error.response);
      const errorSettings = getErrorMsgSettings(parsedError, status);

      return {
        status,
        ...parsedError,
        ...errorSettings,
        ...(error.response?.data && {
          interpolate: omit(error.response?.data, ["errors", "messages"]),
        }),
      };
    }
  }
};
