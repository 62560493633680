import { useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { dates as datesUtils } from "@tint_fe/helpers";
import { useRouterMatch } from "@tint_fe/hooks";
import useGetHotelsAvailability from "./useGetHotelsAvailability";
import { CHECKOUT } from "../../../router";

const defaultValues = {
  start_date: datesUtils.add(new Date(), { days: 2 }),
  end_date: datesUtils.add(new Date(), { days: 4 }),
  rooms: [{ id: uuidV4(), adults: 2, children: [] }],
};

const getRoomsFromLineItems = (lineItems) => {
  if (!lineItems?.length) return defaultValues.rooms;

  const rooms = lineItems
    .map((item) => {
      const array = item.rph.split("-").map((y) => parseInt(y, 10));
      const calculatedRooms = [];

      for (let index = 0; index < item.quantity; index++) {
        calculatedRooms.push({ adults: array[0], children: array.slice(1), id: uuidV4() });
      }

      return calculatedRooms;
    })
    .flat();

  return rooms?.length > 0 ? rooms : defaultValues.rooms;
};

const getRoomsFromRoomsDescription = (roomsDescription) => {
  if (!roomsDescription?.length) return defaultValues.rooms;

  const rooms = roomsDescription.map((r) => {
    return {
      id: uuidV4(),
      adults: Number(r.adults),
      ...(r?.children && { children: Array.from(r.children, Number) }),
    };
  });

  return rooms?.length > 0 ? rooms : defaultValues.rooms;
};

const useHotelCheckoutForm = () => {
  const order = useSelector((state) => state.order.data);
  const getHotelsAvailability = useGetHotelsAvailability();

  const { ...methods } = useForm({ defaultValues });
  const isCheckoutPage = useRouterMatch(CHECKOUT);

  useEffect(() => {
    if (order.number && isCheckoutPage) {
      const product = order.product_items[0];
      const rooms_description = order?.rooms_description || [];
      const start_date = datesUtils.convertDateToUTC(product.start_at);
      const end_date = datesUtils.convertDateToUTC(product.end_at);
      const rooms = getRoomsFromLineItems(product.hotel_line_items);
      const hasRoomsFromOrder = rooms_description.length > 0;
      const roomsFromOrder = getRoomsFromRoomsDescription(rooms_description);

      methods.setValue("start_date", start_date);
      methods.setValue("end_date", end_date);
      methods.setValue("rooms", hasRoomsFromOrder ? roomsFromOrder : rooms);

      getHotelsAvailability({ start_date, end_date, rooms });
    }
  }, [isCheckoutPage]);

  return methods;
};

export default useHotelCheckoutForm;
