import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { selectProduct } from "../../../../redux/product/productSelectors";
import { selectOderProductExtraLineItems } from "../../../../redux/order/orderSelectors";
import { serializeProductItemsAttributes } from "../../../../helpers/serializeOrderBody";
import { serializeExtras } from "../helpers";

const useSerializedTourAttributes = (getValuesFromForm) => {
  const product = useSelector(selectProduct);
  const orderProductExtraLineItems = useSelector(selectOderProductExtraLineItems);
  const formContext = useFormContext();
  const getValues = formContext ? formContext.getValues : getValuesFromForm;

  return useCallback(
    (payload, settings) => {
      const { formData = {}, whoTraveling = {}, extrasInfo = null } = payload || {};
      const { preventPriceBands, getExtrasFromFormContext } = settings || {};
      const { who_traveling, extrasInfo: extrasInfoFromForm, ...reservationDetails } = getValues ? getValues() : {};

      const extras = getExtrasFromFormContext
        ? serializeExtras(orderProductExtraLineItems, extrasInfoFromForm)
        : extrasInfo;

      return serializeProductItemsAttributes(
        product,
        {
          reservationDetails: { ...reservationDetails, ...formData },
          whoTraveling: { ...who_traveling, ...whoTraveling },
          ...(!isEmpty(extras) && { extrasInfo: extras }),
        },
        { preventPriceBands },
      );
    },
    [getValues, orderProductExtraLineItems],
  );
};

export default useSerializedTourAttributes;
