import React from "react";
import { bool } from "prop-types";
import { useFormContext } from "react-hook-form";

import { Checkbox } from "@tint_fe/ui";
import GoogleAutocomplete from "../../../../components/Form/GoogleAutocomplete";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation, useReduxAction, useBreakPoints } from "../../../../../hooks";
import checkIsCovidTest from "../../../../../helpers/checkIfIsCovidTest";
import { getPickupPointsByLocationActions } from "../../../../../redux/product/productActions";
import useBackToCheckoutStep from "../../hooks/useBackToCheckoutStep";
import useUpdatePriceBands from "../../hooks/useUpdatePriceBands";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";

const WhereAreYouStaying = ({ isOnlyOnePickupPoint }) => {
  const getPickupPointsByLocation = useReduxAction(getPickupPointsByLocationActions);
  const backToCheckoutStep = useBackToCheckoutStep();
  const updatePriceBands = useUpdatePriceBands();
  const updateTourPrice = useUpdateTourPrice();
  const { isLg } = useBreakPoints();

  const { t } = useTranslation();
  const {
    control,
    formState: { isSubmitted },
    watch,
    setValue,
  } = useFormContext();
  const startDateValue = watch("start_date");
  const didNotBookedYet = watch("did_not_booked_yet");
  const location = watch("location");
  const { isCovidTest } = checkIsCovidTest();

  /* Update nearest city when location (google autocomplete) is selected */
  const handleUpdatePickupPointsByLocations = (point) => {
    if (point?.city_id) {
      setValue("city_id", point.city_id);

      updatePriceBands({ city_id: point.city_id, pointFromGoogleAutocomplete: point });
    }
  };

  const handleGoogleAutocompleteSelect = (loc) => {
    getPickupPointsByLocation({
      query: loc.location,
      onSuccess: handleUpdatePickupPointsByLocations,
    });

    backToCheckoutStep();

    return loc;
  };

  const fieldPlaceholder = isLg ? t("formField.whereAreYouStaying") : t("formField.whereAreYouStayingLong");

  return (
    <>
      <ControllerInput
        control={control}
        name="location"
        render={({ field: { onChange, value, ...rest }, formState: { error } }) => {
          return (
            <GoogleAutocomplete
              label={t("formField.location")}
              placeholder={fieldPlaceholder}
              {...(!(isCovidTest || value?.lat) && !isOnlyOnePickupPoint && { remark: t("whereAreYouStaying.remark") })}
              disabled={!startDateValue || didNotBookedYet}
              errors={error}
              isSubmitting={isSubmitted}
              value={value}
              onChange={onChange}
              onSelect={(loc) => onChange(handleGoogleAutocompleteSelect(loc))}
              classNameWrapper={"mg-b-2"}
              {...rest}
            />
          );
        }}
      />
      <ControllerInput
        control={control}
        name="did_not_booked_yet"
        render={({ field: { onChange, ...restField } }) => {
          return (
            <Checkbox
              className={"mg-b-6"}
              label={t("formField.didNotBookedYet")}
              onClick={() => {
                if (!restField.value) {
                  setValue("location", { lat: null, lon: null, name: "I didn't book accommodation yet" });
                  // Update price bands only if user has filled location
                  if (location?.name) {
                    updateTourPrice({}, { preventPriceBands: true });
                    updatePriceBands();
                    backToCheckoutStep();
                  }
                } else {
                  setValue("location", { lat: null, lon: null, name: "" });
                }

                return onChange(!restField.value);
              }}
              {...restField}
            />
          );
        }}
      />
    </>
  );
};

export default WhereAreYouStaying;

WhereAreYouStaying.propTypes = {
  isOnlyOnePickupPoint: bool,
};
