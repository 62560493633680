import i18n from "../../../../../widgets/booking/src/i18n";

export const generatePaxInfo = (rooms = [], pax_quantity) => {
  if (rooms.length === 0) {
    return pax_quantity > 1 ? `${pax_quantity} persons` : `${pax_quantity} person`;
  }

  let adultsCount = 0;
  const childrenCount = {};

  rooms.forEach((item) => {
    adultsCount += parseInt(item.adults, 10);
    item.children.forEach((age) => {
      if (childrenCount.hasOwnProperty(age)) {
        childrenCount[age]++;
      } else {
        childrenCount[age] = 1;
      }
    });
  });

  let result = `${adultsCount} ${adultsCount > 1 ? "adults" : "adult"}`;

  for (const age in childrenCount) {
    if (childrenCount.hasOwnProperty(age)) {
      result += `, ${childrenCount[age]} ${childrenCount[age] > 1 ? "children" : "child"} aged ${age}`;
    }
  }

  return result;
};

const HINTS_COUNT = 4;
export const generateImportantNotes = () => {
  const arr = [];
  for (let i = 0; i < HINTS_COUNT; i++) {
    arr.push(`hotels.success.importantMessage${i + 1}`);
  }

  return arr;
};

export const generateTaxInfo = (p_i) => {
  let totalExcludedTaxes = 0;
  let totalIncludedTaxes = 0;

  // Iterate through each hotel_line_item
  for (let i = 0; i < p_i.hotel_line_items.length; i++) {
    const hotelLineItem = p_i.hotel_line_items[i];

    // Calculate excluded taxes for the current hotel_line_item
    const excludedTaxes = Number(hotelLineItem.excluded_taxes) * hotelLineItem.quantity;
    totalExcludedTaxes += excludedTaxes;

    // Calculate included taxes for the current hotel_line_item
    const includedTaxes = Number(hotelLineItem.included_taxes) * hotelLineItem.quantity;
    totalIncludedTaxes += includedTaxes;
  }

  return { totalExcludedTaxes: Math.ceil(totalExcludedTaxes), totalIncludedTaxes: Math.ceil(totalIncludedTaxes) };
};

export const generateRoomsInfo = (p_i) => {
  let roomsInfo = "";

  for (let i = 0; i < p_i.hotel_line_items.length; i++) {
    const hotelLineItem = p_i.hotel_line_items[i];
    const hliRoomInfo = `${hotelLineItem?.quantity > 1 ? `${hotelLineItem?.quantity} x ` : ""}${
      hotelLineItem.room_name
    }`;
    if (roomsInfo === "") {
      roomsInfo += `${hliRoomInfo}`;
    } else {
      roomsInfo += `, ${hliRoomInfo}`;
    }
  }

  return roomsInfo;
};

export const generateTaxInfoString = (totalIncludedTaxes = 0, totalExcludedTaxes = 0, currencyChar = "$") => {
  if (totalIncludedTaxes === 0 && totalExcludedTaxes === 0) {
    return "";
  } else if (totalIncludedTaxes !== 0 && totalExcludedTaxes !== 0) {
    return `(${i18n.t("formField.includedTaxes", { currency: currencyChar, value: totalIncludedTaxes })}. ${i18n.t(
      "formField.excludedTaxes",
      { currency: currencyChar, value: totalExcludedTaxes },
    )})`;
  } else if (totalIncludedTaxes !== 0) {
    return `(${i18n.t("formField.includedTaxes", { currency: currencyChar, value: totalIncludedTaxes })})`;
  }

  return `(${i18n.t("formField.excludedTaxes", { currency: currencyChar, value: totalExcludedTaxes })})`;
};
