import React from "react";
import T from "prop-types";

const SummaryRooms = ({ rooms, classNameItem }) => {
  // INFO: expand to the same mechanism as SummaryProductItems if needed
  const pluralize = (number, word) => {
    switch (word) {
      case "adult":
        return Number(number) > 1 ? "adults" : "adult";
      case "child":
        return Number(number) > 1 ? "children" : "child";
      default:
        return word;
    }
  };

  return (
    rooms?.length > 1 &&
    rooms.map((el, index) => (
      <div className={classNameItem} key={el.id}>
        (Room {index + 1} - {el.adults} {pluralize(el.adults, "adult")}
        {el.children.length ? ` and ${el.children.length} ${pluralize(el.children.length, "child")}` : ""})
      </div>
    ))
  );
};

SummaryRooms.propTypes = {
  classNameItem: T.string,
  rooms: T.array,
};

export default SummaryRooms;
