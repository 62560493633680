import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import T from "prop-types";

import { ArrowBottom, Button, CheckIn, CheckOut, Collapse, InfoMsg } from "@tint_fe/ui";
import { dates, parseClasses } from "@tint_fe/helpers";
import { OVERNIGHT_FORMAT_DATE } from "../../helpers/constants";
import { useTranslation } from "../../hooks";

const Overnights = ({ formStartDate, className, packageNights, names, maxNights }) => {
  const { t, isRtl } = useTranslation();
  const { control, setValue, getValues } = useFormContext();
  const { mainCls, elCls } = parseClasses({
    base: className,
    classes: [isRtl && "rtl"],
  });
  const [startDate, setStartDate] = useState(formStartDate);

  const MAX_NIGHTS = maxNights || 4;
  const isInitialyOpened = Boolean(getValues(names.arrival) || getValues(names.departure));
  const [open, setOpen] = useState(isInitialyOpened);

  /*
    TODO: refactor setTimeout
    TODO: make collapsible work without animation if initialyOpened
    complete as a part of task: https://coaxsoftware.atlassian.net/browse/TINT-3160
  */
  const [withAnimation, setWithAnimation] = useState(!isInitialyOpened);
  useEffect(() => {
    setTimeout(() => setWithAnimation(true), 300);
  }, [startDate]);

  const resetOvernights = () => {
    setValue(names.arrival, 0);
    setValue(names.departure, 0);
  };

  useEffect(() => {
    if (!dates.isEqual(startDate, formStartDate)) {
      resetOvernights();
      setStartDate(formStartDate);
    }
  }, [formStartDate]);

  return (
    <Collapse
      className={mainCls}
      header={<h3 className={elCls("wrapper")}>{t("formField.extraNights.header")}</h3>}
      onClick={() => setOpen((prev) => !prev)}
      icon={<ArrowBottom className={`collapse__icon__top-to-bottom ${elCls("icon")}`} height={24} width={24} />}
      initialHeight={44}
      withAnimation={withAnimation}
      open={open}
    >
      <InfoMsg bold={false} className={`${elCls("tooltip")} cap-3-reg`}>
        <span>{t("formField.extraNights.tipOne")}</span>
        <br />
        {t("formField.extraNights.tipTwo")}
      </InfoMsg>

      {/* ARRIVAL */}
      <div className={elCls("row")}>
        <Controller
          name={names.arrival}
          control={control}
          render={({ field: { onChange, value = 0 } }) => {
            return (
              <>
                <div className={elCls("date-container")}>
                  <div className={`${elCls("row")} arrive-depart-icon`}>
                    <CheckIn />
                    <span>{t("formField.extraNights.arrive")}</span>
                  </div>
                  <span className={elCls("date")}>
                    {dates.format(dates.add(formStartDate, { days: -value }, false), OVERNIGHT_FORMAT_DATE, false)}
                  </span>
                </div>
                <div className="info-row">
                  <span>
                    {value === 0 ? (
                      t("formField.extraNights.arrivalTime")
                    ) : (
                      <Trans i18nKey="formField.extraNights.arrivalTimeCustom">
                        {{ date: value === 1 ? `${value} ${t("day")}` : `${value} ${t("days")}` }}
                      </Trans>
                    )}
                  </span>
                </div>
                <div className={`${elCls("row")} buttons-container`}>
                  <Button
                    className={elCls("button")}
                    round
                    ghost
                    small
                    disabled={value === MAX_NIGHTS}
                    onClick={() => onChange(value + 1)}
                  >
                    {t("formField.extraNights.earlier")}
                  </Button>
                  <Button
                    className={elCls("button")}
                    round
                    ghost
                    small
                    disabled={value === 0}
                    onClick={() => onChange(value - 1)}
                  >
                    {t("formField.extraNights.later")}
                  </Button>
                </div>
              </>
            );
          }}
        />
      </div>

      {/* DEPARTURE */}
      <div className={elCls("row")}>
        <Controller
          name={names.departure}
          control={control}
          render={({ field: { onChange, value = 0 } }) => {
            return (
              <>
                <div className={elCls("date-container")}>
                  <div className={`${elCls("row")} arrive-depart-icon`}>
                    <CheckOut />
                    <span>{t("formField.extraNights.depart")}</span>
                  </div>
                  <span className={elCls("date")}>
                    {dates.format(
                      dates.add(formStartDate, { days: value + packageNights }, false),
                      OVERNIGHT_FORMAT_DATE,
                      false,
                    )}
                  </span>
                </div>
                <div className="info-row">
                  <span>
                    {value === 0 ? (
                      t("formField.extraNights.departureTime")
                    ) : (
                      <Trans i18nKey="formField.extraNights.departureTimeCustom">
                        {{ date: value === 1 ? `${value} ${t("day")}` : `${value} ${t("days")}` }}
                      </Trans>
                    )}
                  </span>
                </div>
                <div className={`${elCls("row")} buttons-container`}>
                  <Button
                    className={elCls("button")}
                    round
                    ghost
                    small
                    disabled={value === 0}
                    onClick={() => onChange(value - 1)}
                  >
                    {t("formField.extraNights.earlier")}
                  </Button>
                  <Button
                    className={elCls("button")}
                    round
                    ghost
                    small
                    disabled={value === MAX_NIGHTS}
                    onClick={() => onChange(value + 1)}
                  >
                    {t("formField.extraNights.later")}
                  </Button>
                </div>
              </>
            );
          }}
        />
      </div>
    </Collapse>
  );
};

Overnights.propTypes = {
  formStartDate: T.instanceOf(Date),
  className: T.string,
  packageNights: T.number,
  names: T.object,
  maxNights: T.number,
};

export default Overnights;
