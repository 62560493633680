import { useRequest } from "@tint_fe/hooks";
import { deleteProductItem } from "../../../../api";

const useDeleteExtraProduct = (onSuccess) => {
  const { callToApi, res, error, loading } = useRequest({
    apiCall: deleteProductItem,
    ...(onSuccess && { onSuccess: onSuccess }),
  });

  return { patcher: callToApi, res, error, loading };
};

export default useDeleteExtraProduct;
