import React from "react";
import T from "prop-types";

import { useControlledInput } from "@tint_fe/hooks";
import Input from "./Input";

const ControlledInput = ({ name, control, rules, label, ...rest }) => {
  const {
    field,
    fieldState: { error },
    showValidateMark,
  } = useControlledInput({ name, control, rules });

  return (
    <Input
      control={control}
      label={label}
      showValidateMark={showValidateMark}
      error={error}
      scrollIntoViewOnError
      {...field}
      {...rest}
    />
  );
};

ControlledInput.propTypes = {
  name: T.string,
  control: T.object,
  rules: T.object,
  label: T.string,
};

export default ControlledInput;
