import * as React from "react";
const CheckmarkBadge = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#F5854D"
      fillRule="evenodd"
      d="M14.85 3.05a4.393 4.393 0 0 0-5.7 0 2.575 2.575 0 0 1-1.464.606 4.393 4.393 0 0 0-4.03 4.03A2.575 2.575 0 0 1 3.05 9.15a4.393 4.393 0 0 0 0 5.698c.352.413.564.925.607 1.465a4.393 4.393 0 0 0 4.03 4.03c.54.043 1.052.255 1.465.607a4.393 4.393 0 0 0 5.698 0 2.575 2.575 0 0 1 1.465-.607 4.393 4.393 0 0 0 4.03-4.03c.043-.54.255-1.052.607-1.465a4.393 4.393 0 0 0 0-5.698 2.575 2.575 0 0 1-.607-1.465 4.393 4.393 0 0 0-4.03-4.03 2.575 2.575 0 0 1-1.465-.607Zm.975 7.32a.909.909 0 1 0-1.286-1.286l-3.448 3.449-1.175-1.176a.91.91 0 0 0-1.286 1.286l1.818 1.818a.91.91 0 0 0 1.286 0l4.09-4.09Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CheckmarkBadge;
