import React, { useCallback } from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import { checkCurrencyChar, pluralsAcceptible, pluralsAvailable, toUpFirstLater } from "@tint_fe/helpers";

const SummaryProductItems = ({ lineItems, classNameItem, currency = "USD" }) => {
  const { t, i18n } = useTranslation();
  const currencyChar = checkCurrencyChar(currency);

  const pluralize = (name, number = 1) => {
    const upFirstLetterName = toUpFirstLater(name);

    if (![...pluralsAvailable, ...Object.keys(pluralsAcceptible)].includes(upFirstLetterName) || Number(number) <= 1) {
      if (pluralsAvailable.includes(upFirstLetterName)) {
        return t(upFirstLetterName);
      }

      if (Object.keys(pluralsAcceptible).includes(upFirstLetterName)) {
        return t(pluralsAcceptible[upFirstLetterName]);
      }

      if (!i18n.exists(name)) {
        return name;
      }

      return t(name);
    }

    if (Object.keys(pluralsAcceptible).includes(upFirstLetterName)) {
      return t(`${pluralsAcceptible[upFirstLetterName]}Plural`);
    }

    return t(`${upFirstLetterName}Plural`);
  };

  /*
    No price  : 2 x Adults
    Has price : 2 Adults x 50$
  */
  const renderLineItem = useCallback(
    (item, hideNumber, lineItemName) => {
      if (item.pricePerItem && item.number > 1) {
        return (
          <span dir="ltr" className="summary__item__reqular">
            {!hideNumber ? `${item.number} ${lineItemName}` : lineItemName}{" "}
            {item.pricePerItem && item.number > 1 ? ` x ${currencyChar}${item.pricePerItem}` : ""}
          </span>
        );
      }

      return (
        <span dir="ltr" className="summary__item__reqular">
          {!hideNumber ? `${item.number} x ${lineItemName}` : lineItemName}{" "}
          {item.pricePerItem && item.number > 1 ? ` ${currencyChar}${item.pricePerItem}` : ""}
        </span>
      );
    },
    [currency],
  );

  return (
    lineItems.length > 0 &&
    lineItems.map((el) => {
      const hasNumber = typeof el.number !== "undefined";
      const hideNumber = !hasNumber || el.hideNumber;
      const lineItemName = pluralize(el.name, el.number);
      const hasSubtext = el.hasOwnProperty("subtext") && el.subtext !== "";

      return (
        <React.Fragment key={`${el.name}-${el.number}`}>
          <div className={classNameItem}>
            {renderLineItem(el, hideNumber, lineItemName)}
            {el.price && typeof el.price === "number" ? (
              <span className={"price"}>
                {currencyChar}
                {el.price}
              </span>
            ) : (
              ""
            )}
          </div>
          {hasSubtext && <span className={"summary__item__subtext"}>{el.subtext}</span>}
        </React.Fragment>
      );
    })
  );
};

SummaryProductItems.propTypes = {
  lineItems: T.arrayOf(
    T.shape({
      name: T.string,
      hideNumber: T.bool,
      number: T.oneOfType([T.number, T.string]),
      pricePerItem: T.oneOfType([T.number, T.string]),
      price: T.oneOfType([T.number, T.string]),
    }),
  ),
  classNameItem: T.string,
  currency: T.string,
};

export default SummaryProductItems;
