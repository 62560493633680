import * as React from "react";

function SvgCheck2(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.09091 10.5983C8.91304 6.19651 11.9012 3.20087 15 1L6.03557 15C3.87747 12.7991 2.21739 9.92577 1 6.31878L6.09091 10.5983Z"
        fill="#151209"
      />
    </svg>
  );
}

export default SvgCheck2;
