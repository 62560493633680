import { call, put } from "redux-saga/effects";

import * as types from "./orderActionTypes";
import { updatePassportDetailsApi } from "@tint_fe/api/order";

export function* updatePassportDetailsSaga(action) {
  const { onSuccess, onFailed, ...requestParams } = action.payload;
  try {
    const response = yield call(updatePassportDetailsApi, requestParams);

    yield put({
      type: types.GET_ORDER_REQUEST,
      payload: { orderNumber: requestParams.orderNumber },
    });
    yield put({ type: types.UPDATE_PASSPORT_DETAILS_SUCCESS, payload: response });

    yield onSuccess && onSuccess();
  } catch (err) {
    yield onFailed && onFailed();
    yield put({ type: types.UPDATE_PASSPORT_DETAILS_FAILED, payload: err });
  }
}

export function* requestPaymentWithGooglePaySaga(action) {
  const { onSuccess, onFailed, ...requestParams } = action.payload;
  try {
    const response = yield call(updatePassportDetailsApi, requestParams);

    yield put({
      type: types.GET_ORDER_REQUEST,
      payload: { orderNumber: requestParams.orderNumber },
    });
    yield put({ type: types.UPDATE_PASSPORT_DETAILS_SUCCESS, payload: response });

    yield onSuccess && onSuccess();
  } catch (err) {
    yield onFailed && onFailed();
    yield put({ type: types.UPDATE_PASSPORT_DETAILS_FAILED, payload: err });
  }
}
