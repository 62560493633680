import React from "react";
import T from "prop-types";

import { useControlledInput, useTranslation } from "@tint_fe/hooks";
import { Checkbox } from "../../../components";

const SavePaymentCard = ({ control, ...rest }) => {
  const { t } = useTranslation();

  const { field } = useControlledInput({
    control,
    name: "save_credit_card",
    defaultValue: false,
  });

  return <Checkbox label={t("paymentMethod.saveCard")} {...field} {...rest} />;
};

SavePaymentCard.propTypes = {
  control: T.object.isRequired,
};

export default SavePaymentCard;
