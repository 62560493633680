import api from "./api";

export const getAvailableFromSmartPackage = ({ id, params }) => {
  return api.get({
    path: `/packages/${id}/available_from`,
    params,
  });
};

export const getAvailableDatesSmartPackage = ({ id, params }) => {
  return api.get({
    path: `/packages/${id}/available_dates`,
    params,
  });
};
