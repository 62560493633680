import { useSelector } from "react-redux";
import { getActivitySessionStorage } from "../../../../storage";
import { useEffect } from "react";
import { dates } from "@tint_fe/helpers";
import { isEmpty } from "lodash";

const getInitialActivityCheckoutFormData = (product_id) => {
  const savedValues = getActivitySessionStorage();
  const actualDate = dates.checkIfDate(savedValues?.start_date);
  const dateIsValid = dates.isAfter(actualDate, new Date(), false);
  const isSavedValuesValid = product_id === savedValues?.product_id && dateIsValid;

  const initData = {
    city_id: "",
    custom_pickup_point: {
      lat: null,
      lon: null,
      name: null,
    },
    pickup_point_id: null,
    start_date: null,
    start_time: null,
    ticket_option: "",
    language_code: "en",
    who_traveling: [],
  };

  const whoTraveling = {};
  Object.values(savedValues?.activity_line_items_attributes || {}).forEach(({ booking_code, quantity }) => {
    if (booking_code && quantity) {
      whoTraveling[booking_code] = quantity;
    }
  });

  const normalizedInitialData = isSavedValuesValid
    ? {
        ...initData,
        ...savedValues,
        // who_traveling: whoTraveling,
        custom_pickup_point: {
          name: savedValues?.custom_pickup_point,
          lat: savedValues?.custom_pickup_lat,
          lon: savedValues?.custom_pickup_lon,
        },
        start_date: actualDate,
      }
    : { ...initData };

  return { ...normalizedInitialData };
};

export const getOrderDetailsFromOrder = (order, pickupPoints) => {
  const productItem = order?.product_items?.[0];
  const savedValues = getActivitySessionStorage();
  const { custom_pickup_lat, custom_pickup_lon, custom_pickup_point } = savedValues || {};

  return {
    city_id: productItem?.city_id,
    custom_pickup_point: {
      name: productItem?.custom_pickup_point || productItem?.start_from || custom_pickup_point,
      lat: productItem?.custom_pickup_lat || custom_pickup_lat,
      lon: productItem?.custom_pickup_lon || custom_pickup_lon,
    },
    did_not_booked_yet: custom_pickup_point === "I didn't book accommodation yet",
    start_date: dates.checkIfDate(productItem?.start_at),
    start_time: dates.getHHMM(productItem?.start_at),
    ticket_option: productItem?.ticket_option,
    language_code: productItem.language_code,
    ...(productItem?.city_id && {
      pickup_point_id: pickupPoints.find(({ city_id }) => city_id === productItem?.city_id)?.id,
    }),
    // who_traveling: [],
  };
};

const usePreselectActivityCheckoutForm = (reset) => {
  const order = useSelector((state) => state.order.data);
  const product_id = useSelector((state) => state.product?.data?.id);
  const pickupPoints = useSelector(({ product }) => product?.data?.pickup_points) || [];

  useEffect(() => {
    const initialValues = getInitialActivityCheckoutFormData(product_id);

    if (product_id && isEmpty(order)) {
      reset(initialValues);
    }

    if (!isEmpty(order)) {
      reset({ ...initialValues, ...getOrderDetailsFromOrder(order, pickupPoints) });
    }
  }, [product_id, order]);

  return null;
};

export default usePreselectActivityCheckoutForm;
