import { useSelector } from "react-redux";
import { SPCheckoutFormFields } from "../react/containers/SmartPackage/helpers";

const {
  ROOMS,
  START_DATE,
  MEAL_PLAN,
  HOTEL_LEVEL,
  START_LOCATION,
  START_LOCATION_TYPE,
  END_LOCATION,
  END_LOCATION_TYPE,
  FLIGHT_NUMBER,
  DEPARTURE_FLIGHT_NUMBER,
  ARRIVAL_EXTRA_NIGHTS,
  DEPARTURE_EXTRA_NIGHTS,
} = SPCheckoutFormFields;

const normalizeSendingSPCheckoutFormData = (data) => {
  const parseValue = (typeField) => {
    return data[typeField] ? { [typeField]: data[typeField] } : {};
  };

  return {
    package_id: data.package_id,
    ...(data.rooms && { [ROOMS]: data.rooms }),
    [START_DATE]: data[START_DATE],
    adults: data.adults,
    children: data.children,
    ...parseValue(START_LOCATION),
    ...parseValue(END_LOCATION),
    ...parseValue(MEAL_PLAN),
    ...parseValue(HOTEL_LEVEL),
    ...parseValue(START_LOCATION_TYPE),
    ...parseValue(END_LOCATION_TYPE),
    ...parseValue(FLIGHT_NUMBER),
    ...parseValue(DEPARTURE_FLIGHT_NUMBER),
    ...parseValue(ARRIVAL_EXTRA_NIGHTS),
    ...parseValue(DEPARTURE_EXTRA_NIGHTS),
  };
};

const useGetSPCheckoutFromData = () => {
  const package_id = useSelector((state) => state.smartPackage.data.id);

  return (data) => {
    const checkoutFormData = data;

    return normalizeSendingSPCheckoutFormData({
      package_id,
      ...checkoutFormData,
      ...(checkoutFormData?.rooms && {
        // eslint-disable-next-line no-unused-vars
        rooms: Object.values(checkoutFormData.rooms).map((item) => {
          return {
            adults: item.adults,
            children: item.children ? item.children : [],
            id: item.id,
          };
        }),
      }),
    });
  };
};

export default useGetSPCheckoutFromData;
