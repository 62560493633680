import { all, call, put, takeLatest } from "redux-saga/effects";
import { v4 as uuidV4 } from "uuid";

import * as types from "./hotelActionTypes";
import * as globalTypes from "../global/globalActionTypes";
import * as orderTypes from "../order/orderActionTypes";
import { errorMsgTypes, steps } from "../../helpers/constants";

import { getHotelAvailabilityApi, getOrderApi, updateOrderProductItemApi } from "../../api";
import { HOTEL_CUSTOMER } from "../../react/router";
import { fetchPricesSaga } from "../global/globalSagas";

function* getHotelAvailabilitySaga(action) {
  try {
    const { onSuccess, ...rest } = action.payload;
    const response = yield call(getHotelAvailabilityApi, rest);
    const array = Object.entries(response).map(([name, roomDesc]) => ({
      name,
      rates: roomDesc?.rates?.map((el) => ({ ...el, id: uuidV4() })),
      facilities: roomDesc?.facilities,
      room_info: roomDesc?.room_info,
    }));

    yield put({
      type: types.GET_HOTEL_AVAILABILITY_SUCCESS,
      payload: { hotelRooms: array, charge_currency: array?.[0]?.rates?.[0]?.currency },
    });
    yield put({ type: globalTypes.SET_STEP_ACTION, payload: steps.EXTRAS });
    yield onSuccess && onSuccess();
  } catch (err) {
    yield put({
      type: types.GET_HOTEL_AVAILABILITY_FAILED,
      payload: { ...err, type: err?.fields[0] === "rooms" ? errorMsgTypes.AVAILABILITY : err?.type },
    });
  }
}

function* updateHotelProductItemSaga(action) {
  try {
    const { orderId, history } = action.payload;
    yield call(updateOrderProductItemApi, action.payload);
    const response = yield call(getOrderApi, orderId);

    history.push(HOTEL_CUSTOMER, { step: steps.CUSTOMER });

    yield put({ type: globalTypes.SET_STEP_ACTION, payload: steps.CUSTOMER });
    yield put({ type: orderTypes.GET_ORDER_SUCCESS, payload: response.order });

    yield call(fetchPricesSaga, { payload: { number: response.order.number } });

    setTimeout(() => window.scrollTo(0, 0), 50);
  } catch (err) {
    yield put({ type: types.UPDATE_HOTEL_PRODUCT_ITEM_FAILED, payload: err });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_HOTEL_AVAILABILITY_REQUEST, getHotelAvailabilitySaga),
    yield takeLatest(types.UPDATE_HOTEL_PRODUCT_ITEM_REQUEST, updateHotelProductItemSaga),
  ]);
}
