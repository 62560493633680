import { useCallback } from "react";
import { useReduxAction } from "@tint_fe/redux-utils";
import { toggleShowAllPoliciesModalAction } from "@tint_fe/redux-utils/src/global/globalActions";

const useHandleIsOpenAllPoliciesModal = () => {
  const toggleShowAllPoliciesModal = useReduxAction(toggleShowAllPoliciesModalAction);

  return useCallback((isOpen) => toggleShowAllPoliciesModal(isOpen), []);
};

export default useHandleIsOpenAllPoliciesModal;
