import React, { useState } from "react";

import Input from "./Input";
import { EyeClose, EyeShow } from "../../assets/icons";

const Password = React.forwardRef(({ ...rest }, ref) => {
  const [type, setType] = useState("password");

  const onChange = () => {
    setType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const Icon = type === "password" ? EyeShow : EyeClose;

  return <Input withoutOverflowTooltip {...rest} nextIcon={<Icon onClick={onChange} />} type={type} ref={ref} />;
});

export default Password;
