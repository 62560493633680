import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";

const Container = React.forwardRef(({ children, className, isRtl, ...rest }, ref) => {
  const { mainCls } = parseClasses({
    base: "container",
    classes: [isRtl && "rtl"],
    additional: className,
  });

  return (
    <div data-testid="container-testid" ref={ref} dir={isRtl ? "rtl" : "auto"} className={mainCls} {...rest}>
      {children}
    </div>
  );
});

Container.propTypes = {
  children: T.node.isRequired,
  className: T.string,
  isRtl: T.bool,
};

export default Container;
