import React, { useRef } from "react";
import T from "prop-types";

import { Button } from "..";
import { useOutsideClickHandler } from "@tint_fe/hooks";
import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";

const SplitPaymentNotificationUI = ({
  handleClickOnBtn,
  containerClassName,
  closeToast,
  orderNumber,
  chargeCurrency = "USD",
  splitPaymentAmount,
}) => {
  const ref = useRef();
  const { elCls } = parseClasses({
    base: "notification",
    additional: [containerClassName],
  });

  const currencyChar = checkCurrencyChar(chargeCurrency);

  useOutsideClickHandler(ref, () => null);

  const handleBtnClick = () => {
    if (handleClickOnBtn) {
      handleClickOnBtn();
    }
    closeToast();
  };

  return (
    <div ref={ref} className={elCls("content")}>
      <p className="mg-0 p4 bold mg-b-2">{"Uh oh! There was an error processing your payment."}</p>
      <p className="mg-0 p4 mg-b-2">
        {"To lock in and confirm your reservation we offer you the option to pay "}
        <span className={elCls("price-text")}>
          {currencyChar}
          {splitPaymentAmount}
        </span>
        {" now and then resolve the payment related issues with your bank. Click here to process this payment."}
      </p>
      <p className="mg-0 p4 mg-b-2">
        {`Alternatively, please try paying with Google Pay or Apple Pay (available as payment options on the page). If you still encounter problems, please contact us and we will be happy to help. We can also offer payment via PayPal via our customer service team. Kindly provide order number ${orderNumber}`}
      </p>
      <Button small background="dark" onClick={handleBtnClick} className={elCls("btn")}>
        {"Process this payment"}
      </Button>
    </div>
  );
};

SplitPaymentNotificationUI.defaultProps = {
  textBtn: "process this payment",
};

SplitPaymentNotificationUI.propTypes = {
  containerClassName: T.string,
  hideBtn: T.bool,
  handleClickOnBtn: T.func,
  textBtn: T.string,
  children: T.node,
  closeToast: T.func,
  message: T.string,
  refErrorCode: T.string,
  orderNumber: T.string,
  chargeCurrency: T.string,
  splitPaymentAmount: T.number,
};

export default SplitPaymentNotificationUI;
