import * as React from "react";
const RoomBedTypeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#212121"
      d="M20 10V7c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v3c-1.1 0-2 .9-2 2v5h1.33L4 19h1l.67-2h12.67l.66 2h1l.67-2H22v-5c0-1.1-.9-2-2-2Zm-9 0H6V7h5v3Zm7 0h-5V7h5v3Z"
    />
  </svg>
);
export default RoomBedTypeIcon;
