export const STATIC_URL = process.env.REACT_APP_STATIC_URL;

export const bookingTypes = {
  CUSTOM_PACKAGE: "custom_package",
  SMART_PACKAGE: "smart_package",
  HOTEL: "hotel",
  TOUR: "tour",
  BUNDLE: "bundle",
  ACTIVITY: "activity",
};

export const googleAnalyticsProductTypes = {
  [bookingTypes.CUSTOM_PACKAGE]: "CustomPackage",
  [bookingTypes.SMART_PACKAGE]: "Packages",
  [bookingTypes.HOTEL]: "Hotels",
  [bookingTypes.TOUR]: "Tours",
  [bookingTypes.BUNDLE]: "Bundles",
  [bookingTypes.ACTIVITY]: "Activities",
};

export const paymentProviders = {
  CREDORAX: "credorax",
};

export const INTEGRATIONS_ENUM = {
  TI: "touristisrael",
  TJ: "touristjourney",
  PI: "petrafromisrael",
};

export const orderAvailableStates = {
  CHECKOUT: "checkout",
  EXTRAS: "extras",
  CUSTOMER: "customer",
  PAYMENT: "payment",
  COMPLETED: "completed",
  PASSPORT: "passport",
  CONFIRMED: "confirmed",
  CANCELED: "canceled",
};

export const MAX_KIDS = 3;
export const MAX_KIDS_AGE = 17;
export const MIN_KIDS = 0;
export const MIN_ADULTS = 1;
export const MAX_ROOM_PAX = 5;
export const MAX_TOTAL_PAX = 20;
export const MAX_TOTAL_ROOMS = 10;
export const MAX_TOTAL_ROOMS_HOTEL = 8;

export const API_FORMAT_DATE = "dd/MM/yyyy";

export const API_DOWN_ERROR_STATUSES = [502, 503, 504];

export const authorizationsEvents = {
  SIGN_IN: "sign-in",
  SIGN_OUT: "sign-out",
  SKIP_SIGN_IN: "skip-sign-in",
};

export const accountRoutes = {
  BASE: "/account",
  ACCOUNT: "/account/main",
  PROFILE: "/account/profile",
  FAVORITES: "/account/favorites",
  JOURNEYS: "/account/journeys",
  MY_BOOKINGS: "/account/my-bookings",
  ORDER: "/account/order",
  ORDER_ID: "/account/order/:orderNumber",
};

export const errorMsgTypes = {
  CRITICAL_ERRORS: "critical_errors",
  NEUTRAL: "neutral",
  POSITIVE: "positive",
  AVAILABILITY: "availability",
  SPLIT_PAYMENT: "split_payment",
};

export const timeToShowTimer = {
  PAYMENT: 20 * 60 * 1000,
  ORDER: 5 * 60 * 1000,
};

export const paymentMethods = {
  CREDIT_CARD: "credit_card",
  G_PAY: "g_pay",
  APPLE_PAY: "apple_pay",
};

export const AB_TESTING_KEY = "assumptions";

export const stuffPackageInfoId = "stuff";

// pluralsAvailable - keys for i18n
export const pluralsAvailable = ["Adult", "Children", "Passengers", "Students"];
export const pluralsAcceptible = { Adults: "Adult", Child: "Children", Passenger: "Passengers", Student: "Students" };
