import { createHotelOrderRequest } from "../redux/order/orderActions";
import { steps } from "../helpers/constants";
import { useHistoryNavigation, useReduxAction } from ".";
import { setSessionStorageProductId, setSessionStorageSourceLink } from "../storage/sessionStorage";

const useCreateHotelOrderFromQueryParams = () => {
  const createHotelOrder = useReduxAction(createHotelOrderRequest);
  const history = useHistoryNavigation();

  return () => {
    let hotelInfo = {};

    try {
      const search = new URLSearchParams(document.location.search);
      const hotel = search.get("hotel");

      search.delete("hotel");
      const string = search.toString();
      const sourceLink = `${window.location.origin}${window.location.pathname}${string ? `?${string}` : ""}`;

      hotelInfo = hotel ? JSON.parse(atob(hotel)) : {};
      setSessionStorageProductId(hotelInfo.product_id);
      setSessionStorageSourceLink(hotelInfo.source_link);

      const { start_date, end_date, expire_at, product_id, hotel_line_items_attributes } = hotelInfo;

      const rates = typeof hotel_line_items_attributes === "object" ? Object.values(hotel_line_items_attributes) : [];

      if (rates.length) {
        const newRatesObj = {};
        rates.forEach((value, index) => (newRatesObj[index] = value));
        const newHotelInfo = {
          start_date,
          end_date,
          expire_at,
          product_id,
          hotel_line_items_attributes: newRatesObj,
        };

        createHotelOrder(
          {
            order: {
              product_items_attributes: { 0: newHotelInfo },
              source_link: sourceLink,
            },
          },
          steps.HOTEL_REDIRECT,
          history,
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export default useCreateHotelOrderFromQueryParams;
