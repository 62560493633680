import { useSelector } from "react-redux";
import { PAYMENT, SELECT_HOTELS } from "../../router";

const useCheckIfNextStepIsAllowed = (steps) => {
  const availableStates = useSelector((state) => state.order.data.available_states);
  const accommodation = useSelector((state) => state.smartPackage.accommodation);

  return (stepIndex) => {
    if (stepIndex >= steps.length) return false;
    if (steps[stepIndex].path.includes(SELECT_HOTELS) && accommodation.length > 0) return true;
    if (!availableStates?.length) return false;
    if (PAYMENT.includes(steps[stepIndex].value)) return false;

    const stepsToCheck = steps.slice(stepIndex);

    return !!stepsToCheck.find((el) => availableStates.includes(el.value));
  };
};

export default useCheckIfNextStepIsAllowed;
