import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { flow, pickBy } from "lodash";

const getSelectedRooms = (hotelRooms, bookingCodes) => {
  const selectedBooking = Object.values(pickBy(bookingCodes, (val) => !!val.quantity));

  return flow([
    (arr) => arr.reduce((acc, el) => [...acc, ...el.rates], []),
    (arr) => {
      return arr.filter((el) => {
        return selectedBooking.find((selectedEl) => selectedEl.booking_code === el.booking_code);
      });
    },
    (arr) => {
      return arr.map((el) => {
        const quantity = selectedBooking.find(({ booking_code }) => booking_code === el.booking_code)?.quantity || 0;

        return { ...el, quantity };
      });
    },
  ])(hotelRooms);
};

const useSelectedRooms = (methods) => {
  const hotelRooms = useSelector((state) => state.hotel.hotelRooms);
  const { watch } = useFormContext() || methods || {};
  const bookingCodes = watch?.("hotel_line_items_attributes");

  return getSelectedRooms(hotelRooms, bookingCodes);
};

export default useSelectedRooms;
