import { useRequest } from "@tint_fe/hooks";
import useReduxAction from "./useReduxAction";

import { setErrorAction } from "../src/global/globalActions";

const useRequestWithGlobalError = ({ onError, shouldSetErrorInGlobalStore, ...rest }) => {
  const serErrorToGlobal = useReduxAction(setErrorAction);

  const handleError = (err) => {
    if (shouldSetErrorInGlobalStore) {
      serErrorToGlobal(err);
    }

    return typeof onError === "function" ? onError(err) : err;
  };

  const responseData = useRequest({ onError: handleError, ...rest });

  return responseData;
};

export default useRequestWithGlobalError;
