export const fetchUserCountryApi = async () => {
  const url = "https://ipinfo.io/?token=f6c6bf1d256212";

  try {
    const response = await fetch(url);
    const data = await response.json();

    return data?.country || "";
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const fetchUserIpApi = async () => {
  const url = "https://ipinfo.io/?token=f6c6bf1d256212";

  try {
    const response = await fetch(url);
    const data = await response.json();

    return data?.ip || "";
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
