import api from "./api";

export const getForbiddenDatesActivity = ({ id, params }) => {
  return api.get({
    path: `/activities/${id}/forbidden_dates`,
    params,
  });
};

export const getOpenDatesActivity = ({ id, params }) => {
  return api.get({
    path: `/activities/${id}/open_date`,
    params,
  });
};
