import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

const HotelReviews = ({ score, reviews, mgr, className }) => {
  const { mainCls, elCls } = parseClasses({ base: "hotel-reviews", additional: [className] });
  const { t } = useTranslation();

  return score && reviews ? (
    <div className={mainCls}>
      <div className={elCls("score", mgr ? "mg-r-2" : "")}>{score}</div>
      <span className={elCls("reviews")}>
        {reviews} {reviews > 1 ? t("reviews") : t("review")}
      </span>
    </div>
  ) : null;
};

HotelReviews.defaultProps = {
  mgr: true,
};

HotelReviews.propTypes = {
  score: T.number,
  reviews: T.number,
  className: T.string,
  mgr: T.bool,
};

export default HotelReviews;
