export const checkIfLetterIsCapital = (letter) => {
  if (typeof latter === "string") {
    return letter === letter.toUpperCase();
  }
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string === "string") {
    const firstLater = string.charAt(0);
    if (!checkIfLetterIsCapital(firstLater)) {
      return firstLater.toUpperCase() + string.slice(1);
    }
  }

  return string;
};

export const normalizeSentences = (str) => {
  if (typeof str === "string") {
    return str
      .split(" ")
      .map((el, index) => {
        if (!el.trim()) return "";

        if (index === 0) {
          return `${el[0]?.toUpperCase()}${el.slice(1) ? el.slice(1)?.toLowerCase() : ""}`;
        }

        return `${el ? el?.toLowerCase() : ""}`;
      })
      .join(" ");
  }
};
