import * as React from "react";
const ConfirmationHotel = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#151209"
      fillRule="evenodd"
      d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4h-2V9a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v1h-2V9a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v1H3V6Zm1 5a3 3 0 0 0-3 3v5.888a1.111 1.111 0 0 0 2.222 0v-1.11h17.556v1.11a1.111 1.111 0 1 0 2.222 0V14a3 3 0 0 0-3-3H4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ConfirmationHotel;
