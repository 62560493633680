import React from "react";
import { useSelector } from "react-redux";

import { selectIfCustomPrice } from "../../../../redux/sharedSelectors";
import OrderSummaryView from "../../../components/OrderSummaryView";
import useActivitySummaryInfo from "../hooks/useActivitySummaryInfo";
import useActivityExtendedSummary from "../hooks/useActivityExtendedSummary";
import { useAgentCommission, useRouterMatch } from "@tint_fe/hooks";
import { CHECKOUT } from "../../../router";

const ActivitySummary = () => {
  const orderData = useSelector(({ order }) => order.data);
  const { showAgentCommission, showAgentPays } = useAgentCommission({ order: orderData });

  const price = useSelector((state) => state.global.price);
  const product = useSelector((state) => state.product.data);
  const requestedPrice = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);
  const isCustomPrice = useSelector(selectIfCustomPrice);
  const lineItems = useActivitySummaryInfo(price);
  const isCheckout = useRouterMatch(CHECKOUT);

  const roundedTaxAmount = price?.product_items && price?.product_items[0]?.rounded_citizenship_tax_amount;
  const citizenshipTaxAmount = roundedTaxAmount || price.citizenship_tax_amount;
  const summaryTitle = orderData?.charge_strategy === "partial_agent_credit" ? "summary.titleAgent" : "summary.title";
  const { startLocation, ticketOption } = useActivityExtendedSummary();

  return (
    <OrderSummaryView
      summaryTitle={summaryTitle}
      startLocation={startLocation}
      ticketOption={ticketOption}
      name={product.display_name}
      img={product.cover_image}
      lineItems={isCheckout ? [] : lineItems}
      citizenshipTaxAmount={citizenshipTaxAmount}
      totalPrice={price?.tint_charge_price}
      requestedPrice={requestedPrice}
      roundedTotalPrice={price.rounded_taxed_components_price}
      showSummaryDetails={!isCustomPrice}
      currency={price?.charge_currency}
      agentFeeAmount={showAgentCommission ? price.agent_fee_amount : 0}
      agentPaysAmount={showAgentPays ? price?.tint_charge_price : 0}
    />
  );
};

export default ActivitySummary;
