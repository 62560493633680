import React from "react";
import T from "prop-types";

import { useTranslation } from "../../../hooks";

const FormHeader = React.forwardRef(({ text, className }, ref) => {
  const { isRtl } = useTranslation();

  return (
    <div ref={ref} className="row">
      <div className="col col-12">
        <h3 className={`booking-form--header-name${className ? ` ${className}` : ""}${isRtl ? " rtl" : ""}`}>{text}</h3>
      </div>
    </div>
  );
});

FormHeader.propTypes = {
  text: T.string.isRequired,
  className: T.string,
};

export default FormHeader;
