import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import { useOutsideClickHandler, useHandleESC } from "@tint_fe/hooks";
import { parseClasses, join } from "@tint_fe/helpers";
import { Portal } from "..";
import { Close } from "../../assets/icons";

const bodyScrollCheckIsIOSDevice = () => {
  return (
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.platform &&
    (/iP(ad|hone|od)/.test(window.navigator.platform) ||
      (window.navigator.platform === "MacIntel" && window.navigator.maxTouchPoints > 1))
  );
};

const getAnimations = (drawer, isRtl) => {
  switch (drawer) {
    case "bottom":
      return { in: "drawerBottomIn", out: "drawerBottomOut", duration: 0.5 };
    case "right":
      return {
        in: isRtl ? "rtlDrawerRightIn" : "drawerRightIn",
        out: isRtl ? "rtlDrawerRightOut" : "drawerRightOut",
        duration: 0.5,
      };
    default:
      return { in: "fadeIn", out: "fadeOut", duration: 0.3 };
  }
};

const Modal = React.forwardRef(
  (
    {
      isOpen,
      closeModal,
      closesOnOutsideClick,
      closeOnESC,
      children,
      drawer,
      className,
      withCloseBtn,
      containerClass,
      lockBodyScroll,
      rootId,
      isRtl,
      portalClassName,
      keepScrollBar,
    },
    ref,
  ) => {
    const { mainCls: drawerClasses } = parseClasses({ base: "drawer", classes: [drawer] });

    const containerClasses = join(
      ["modal-container", drawer && drawerClasses, "tint-custom-package", containerClass, isRtl && "rtl"],
      " ",
    );

    const animations = getAnimations(drawer, isRtl);
    const [render, setRender] = useState(isOpen);
    const modalRef = useRef();

    const onAnimationEnd = () => {
      if (!isOpen) setRender(false);
    };

    const handleESC = (event) => {
      if (closeOnESC && isOpen) {
        event.preventDefault();
        event.stopPropagation();

        closeModal();
      }
    };

    useOutsideClickHandler(modalRef, () => (closesOnOutsideClick ? closeModal() : null));
    useHandleESC(handleESC, ref?.current, isOpen, render);

    useEffect(() => {
      if (isOpen) {
        setRender(true);
      }
    }, [isOpen]);

    useEffect(() => {
      if (lockBodyScroll && modalRef.current) {
        const isIOSDevice = bodyScrollCheckIsIOSDevice();
        const bodyRect = document.body.getBoundingClientRect();
        const scrollY = bodyRect.top;
        const isScrollable = bodyRect.height > window.innerHeight;

        if (render && isOpen) {
          disableBodyScroll(modalRef.current);

          if (isIOSDevice) {
            const iosScrollY = document.body.getBoundingClientRect().top;
            document.body.style.top = `${iosScrollY}px`;
            document.documentElement.style.height = "100vh";
          }

          if (keepScrollBar && !isIOSDevice && isScrollable) {
            document.body.style.top = `${scrollY}px`;
            document.body.style.position = "fixed";
            document.body.style.overflowY = "scroll";
            document.body.style.width = "100%";
          }
        }

        if (!isOpen) {
          enableBodyScroll(modalRef.current);

          if (isIOSDevice) {
            document.body.style.removeProperty("top");
            document.documentElement.style.removeProperty("height");
          }

          if (keepScrollBar && !isIOSDevice && isScrollable) {
            document.body.style.removeProperty("top");
            document.body.style.removeProperty("width");
            document.body.style.removeProperty("position");
            document.body.style.removeProperty("overflowY");
            document.documentElement.scrollTop = -scrollY;
          }
        }

        return () => clearAllBodyScrollLocks();
      }
    }, [render, isOpen, lockBodyScroll]);

    return render ? (
      <Portal portalClassName={portalClassName} rootId={rootId}>
        <div
          id="test"
          style={{
            animation: `${isOpen ? animations.in : animations.out} ${animations.duration}s ease forwards`,
          }}
          onAnimationEnd={onAnimationEnd}
          className={containerClasses}
        >
          <div role="dialog" className={`modal${className ? ` ${className}` : ""}`} ref={modalRef}>
            {withCloseBtn && (
              <button className="modal__close-btn" onClick={closeModal}>
                <Close width={24} height={24} />
              </button>
            )}
            {children}
          </div>
          <div className="modal-overlay" />
        </div>
      </Portal>
    ) : null;
  },
);

Modal.defaultProps = {
  closeOnESC: true,
  closesOnOutsideClick: true,
};

Modal.propTypes = {
  isOpen: T.bool,
  closeModal: T.func,
  closesOnOutsideClick: T.bool,
  closeOnESC: T.bool,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  drawer: T.oneOf(["right", "bottom"]),
  className: T.string,
  withCloseBtn: T.bool,
  containerClass: T.string,
  rootId: T.string,
  lockBodyScroll: T.bool,
  portalClassName: T.string,
  keepScrollBar: T.bool,
  isRtl: T.bool,
};

export default Modal;
