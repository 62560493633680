export const GET_WIDGET_INTEGRATION_REQUEST = "GET_WIDGET_INTEGRATION_REQUEST";
export const GET_WIDGET_INTEGRATION_SUCCESS = "GET_WIDGET_INTEGRATION_SUCCESS";
export const GET_WIDGET_INTEGRATION_FAILED = "GET_WIDGET_INTEGRATION_FAILED";

export const SAVE_ROOT_ID = "SAVE_ROOT_ID";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const TOGGLE_CONTACT_US_MODAL = "TOGGLE_CONTACT_US_MODAL";

export const SET_ERROR = "SET_ERROR";

export const SHOW_MORE_INFO_MODAL = "SHOW_MORE_INFO_MODAL";
export const SHOW_ALL_POLICIES_MODAL = "SHOW_ALL_POLICIES_MODAL";

export const PARTIAL_PAYMENT = "PARTIAL_PAYMENT";
export const PARTIAL_PAYMENT_TOGGLE = "PARTIAL_PAYMENT_TOGGLE";
