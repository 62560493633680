import React from "react";
import T from "prop-types";
import { v4 as uuidV4 } from "uuid";

import { Star } from "../../assets/icons";

const getStars = (number) => {
  const array = [];

  for (let i = 0; i < Math.round(number); i++) {
    array.push(<Star key={uuidV4()} />);
  }

  return array;
};

const Rating = ({ number, className, ...rest }) => {
  const stars = getStars(typeof number === "string" ? Number(number.replace(/[^\d]/g, "")) : number);

  return (
    <span className={`rating${className ? ` ${className}` : ""}`} {...rest}>
      {stars}
    </span>
  );
};

Rating.propTypes = {
  number: T.oneOfType([T.number, T.string]),
  className: T.string,
};

export default Rating;
