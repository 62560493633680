import React from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { handleABTestingFeatures } from "@tint_fe/helpers";

import FormContainer from "../../../../components/Form/FormContainer";
import EndLocationSelect from "./EndLocationSelect";
import WhereAreYouStaying from "./WhereAreYouStaying";
import StartTimeInput from "./StartTimeInput";
import checkIfIsCovidTest from "../../../../../helpers/checkIfIsCovidTest";
import { selectProductSettings, selectAvailablePickupPoints } from "../../../../../redux/product/productSelectors";
import SelectReservationsLang from "./SelectReservationsLang";
import TourFlightNumber from "./TourFlightNumber";
import TourDepartureFlightNumber from "./TourDepartureFlightNumber";
import StartFrom from "./StartFrom";
import EndCitySelect from "./EndCitySelect";
import ReservationDetailsDatePicker from "./ReservationDetailsDatePicker";
import StartLocations from "./StartLocations";
import ReturnInDifferentLocationCheckbox from "./ReturnInDifferentLocationCheckbox";
import useIsCityIsAirport from "../../hooks/useIsCityIsAirport";

const ReservationDetailsForm = () => {
  const { ask_accommodation_location, custom_pickup_point_strategy, custom_dropoff_point_strategy } =
    useSelector(selectProductSettings);

  const availablePickupPoints = useSelector(selectAvailablePickupPoints);

  const { watch } = useFormContext();

  const city_id = watch("city_id");

  const [isCityAirport, cityName, cities] = useIsCityIsAirport(city_id);

  const { whereAreYouStaying: ABTestWhereAreYouStaying } = handleABTestingFeatures();
  const isOnlyOnePickupPoint = cities?.length <= 1 && availablePickupPoints?.length <= 1;

  const showWhereAreYouStaying =
    typeof ABTestWhereAreYouStaying === "undefined"
      ? ask_accommodation_location && !custom_pickup_point_strategy
      : !!(ABTestWhereAreYouStaying || !isOnlyOnePickupPoint) &&
        ask_accommodation_location &&
        !custom_pickup_point_strategy;

  const { isCovidTest1, isCovidTest } = checkIfIsCovidTest();
  const hideTimeField = isCovidTest1;

  const replaceStartLocationsWithFlightNumber =
    isCityAirport && cities.length > 1 && !custom_pickup_point_strategy && availablePickupPoints.length === 1;

  return (
    <FormContainer>
      <ReservationDetailsDatePicker />
      <SelectReservationsLang />
      {showWhereAreYouStaying && <WhereAreYouStaying isOnlyOnePickupPoint={isOnlyOnePickupPoint} />}
      <StartFrom />
      <div className="row">
        <div className={`col ${hideTimeField ? "col-12" : "col-sm-8 col-md-7 col-lg-8"}`}>
          {replaceStartLocationsWithFlightNumber ? <TourFlightNumber dependOn={cityName} /> : <StartLocations />}
        </div>
        <div className={`col ${hideTimeField ? "col-0" : "col-sm-4 col-md-5 col-lg-4"}`}>
          <StartTimeInput hideTimeField={hideTimeField} />
        </div>
      </div>

      {!replaceStartLocationsWithFlightNumber && <TourFlightNumber />}

      {!isCovidTest && <ReturnInDifferentLocationCheckbox />}
      {custom_dropoff_point_strategy && <EndCitySelect />}
      <EndLocationSelect />
      <TourDepartureFlightNumber />
    </FormContainer>
  );
};

export default ReservationDetailsForm;
