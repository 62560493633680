import * as React from "react";

function GooglePayMarkIcon(props) {
  return (
    <svg width="62" height="32" viewBox="0 0 62 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M45.4018 0H16.9279C8.02978 0 0.749512 7.2 0.749512 16C0.749512 24.8 8.02978 32 16.9279 32H45.4018C54.2999 32 61.5802 24.8 61.5802 16C61.5802 7.2 54.2999 0 45.4018 0Z"
        fill="white"
      />
      <path
        d="M45.4018 1.296C47.3999 1.296 49.3413 1.688 51.1694 2.456C52.941 3.2 54.5264 4.264 55.9016 5.616C57.2687 6.968 58.3445 8.544 59.0968 10.296C59.8734 12.104 60.2698 14.024 60.2698 16C60.2698 17.976 59.8734 19.896 59.0968 21.704C58.3445 23.456 57.2687 25.024 55.9016 26.384C54.5345 27.736 52.941 28.8 51.1694 29.544C49.3413 30.312 47.3999 30.704 45.4018 30.704H16.9279C14.9299 30.704 12.9885 30.312 11.1603 29.544C9.38876 28.8 7.80328 27.736 6.42812 26.384C5.06105 25.032 3.98519 23.456 3.23289 21.704C2.45633 19.896 2.05996 17.976 2.05996 16C2.05996 14.024 2.45633 12.104 3.23289 10.296C3.98519 8.544 5.06105 6.976 6.42812 5.616C7.79519 4.264 9.38876 3.2 11.1603 2.456C12.9885 1.688 14.9299 1.296 16.9279 1.296H45.4018ZM45.4018 0H16.9279C8.02978 0 0.749512 7.2 0.749512 16C0.749512 24.8 8.02978 32 16.9279 32H45.4018C54.2999 32 61.5802 24.8 61.5802 16C61.5802 7.2 54.2999 0 45.4018 0Z"
        fill="#3C4043"
      />
      <path
        d="M29.7575 17.136V21.976H28.2043V10.024H32.3217C33.3652 10.024 34.2551 10.368 34.9831 11.056C35.7273 11.744 36.0994 12.584 36.0994 13.576C36.0994 14.592 35.7273 15.432 34.9831 16.112C34.2632 16.792 33.3733 17.128 32.3217 17.128H29.7575V17.136ZM29.7575 11.496V15.664H32.3541C32.9689 15.664 33.4866 15.456 33.891 15.048C34.3036 14.64 34.5139 14.144 34.5139 13.584C34.5139 13.032 34.3036 12.544 33.891 12.136C33.4866 11.712 32.977 11.504 32.3541 11.504H29.7575V11.496Z"
        fill="#3C4043"
      />
      <path
        d="M40.1603 13.528C41.3089 13.528 42.2149 13.832 42.8782 14.44C43.5416 15.048 43.8732 15.88 43.8732 16.936V21.976H42.3929V20.84H42.3282C41.6891 21.776 40.8317 22.24 39.7639 22.24C38.8498 22.24 38.0894 21.976 37.4747 21.44C36.8599 20.904 36.5525 20.24 36.5525 19.44C36.5525 18.592 36.8761 17.92 37.5232 17.424C38.1703 16.92 39.0359 16.672 40.1117 16.672C41.0339 16.672 41.7943 16.84 42.3848 17.176V16.824C42.3848 16.288 42.1745 15.84 41.7458 15.464C41.317 15.088 40.8155 14.904 40.2412 14.904C39.3756 14.904 38.688 15.264 38.1865 15.992L36.8194 15.144C37.5717 14.064 38.688 13.528 40.1603 13.528ZM38.1541 19.464C38.1541 19.864 38.324 20.2 38.6719 20.464C39.0116 20.728 39.4161 20.864 39.8772 20.864C40.5324 20.864 41.1148 20.624 41.6244 20.144C42.134 19.664 42.3929 19.104 42.3929 18.456C41.9075 18.08 41.2361 17.888 40.3706 17.888C39.7396 17.888 39.2138 18.04 38.7932 18.336C38.3645 18.648 38.1541 19.024 38.1541 19.464Z"
        fill="#3C4043"
      />
      <path
        d="M52.3182 13.792L47.1411 25.568H45.5394L47.4647 21.448L44.051 13.792H45.7417L48.2008 19.664H48.2331L50.6275 13.792H52.3182Z"
        fill="#3C4043"
      />
      <path
        d="M23.5798 16.16C23.5798 15.6592 23.5345 15.18 23.4504 14.7192H16.9402V17.3592L20.6895 17.36C20.5374 18.2384 20.0481 18.9872 19.2982 19.4864V21.1992H21.53C22.8332 20.0064 23.5798 18.2432 23.5798 16.16Z"
        fill="#4285F4"
      />
      <path
        d="M19.2988 19.4864C18.6776 19.9008 17.8775 20.1432 16.9416 20.1432C15.1337 20.1432 13.6 18.9384 13.0507 17.3144H10.7485V19.0808C11.8891 21.3192 14.2334 22.8552 16.9416 22.8552C18.8135 22.8552 20.386 22.2464 21.5306 21.1984L19.2988 19.4864Z"
        fill="#34A853"
      />
      <path
        d="M12.834 16.004C12.834 15.548 12.9109 15.1072 13.0508 14.6928V12.9264H10.7486C10.277 13.852 10.0117 14.8968 10.0117 16.004C10.0117 17.1112 10.2779 18.156 10.7486 19.0816L13.0508 17.3152C12.9109 16.9008 12.834 16.46 12.834 16.004Z"
        fill="#FABB05"
      />
      <path
        d="M16.9416 11.864C17.9633 11.864 18.8782 12.212 19.6005 12.892L21.5783 10.9376C20.3771 9.83118 18.811 9.15198 16.9416 9.15198C14.2342 9.15198 11.8891 10.688 10.7485 12.9264L13.0507 14.6928C13.6 13.0688 15.1337 11.864 16.9416 11.864Z"
        fill="#E94235"
      />
    </svg>
  );
}

export default GooglePayMarkIcon;
