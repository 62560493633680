import { all, call, put, select, takeLatest } from "redux-saga/effects";

import * as types from "./globalActionTypes";

import { CustomError, dates, checkErrorOnSagas, bookingTypes } from "@tint_fe/helpers";

import { getWidgetIntegrationApi, fetchPricesApi, getPackagePrice } from "../../api";
import { updateRatesTypes } from "../../helpers/constants";
import { getSmartPackageAccommodationSaga } from "../smartPackage/smartPackageSagas";
import { selectChangedProducts } from "../sharedSelectors";
import { getDayJourney } from "../helpers";
import { selectOrderExpiredTime } from "../order/orderSelectors";
import { getOrderSaga, initPaymentRequest } from "../order/orderSagas";

function* getWidgetIntegrationSaga() {
  try {
    const response = yield call(getWidgetIntegrationApi);
    yield put({ type: types.GET_WIDGET_INTEGRATION_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.SET_ERROR_ACTION, payload: err });
  }
}

export function* fetchPricesSaga(action) {
  try {
    const { build_type, ...rest } = action.payload;
    const order = yield select((state) => state.order.data);
    const isPackage = order.build_type === bookingTypes.SMART_PACKAGE || build_type === bookingTypes.SMART_PACKAGE;

    const response = isPackage
      ? yield call(getPackagePrice, { number: rest.number })
      : yield call(fetchPricesApi, rest);

    yield put({ type: types.FETCH_PRICES_SUCCESS, payload: response.order });
  } catch (err) {
    console.error(err);
  }
}

let countOfUpdates = 0;

function* checkExpiredTime({ payload }) {
  const { expiredTime, onSuccess, updatesPayload } = payload;
  const updating = yield select((state) => state.global.ratesWasExpired.updating);

  if (expiredTime && !dates.isFuture(expiredTime) && countOfUpdates < 3 && !updating) {
    countOfUpdates += 1;
    console.warn("trays to refresh ====> ", countOfUpdates);

    return yield put({ type: types.UPDATE_RATES_REQUEST, payload: updatesPayload });
  } else if (!dates.isFuture(expiredTime) && countOfUpdates >= 3) {
    countOfUpdates = 0;
    throw new CustomError("Rates was expired");
  } else {
    countOfUpdates = 0;
    yield onSuccess();
  }
}

function* updateRatesSaga({ payload }) {
  yield put({ type: types.UPDATE_RATES_PROCESSING });
  try {
    const { updateType, orderNumber, onSuccess, usedReassessLink, isPaymentPage, smartPackageBody } = payload;

    if (updateRatesTypes.SELECT_HOTEL === updateType) {
      yield call(getSmartPackageAccommodationSaga, {
        payload: {
          body: smartPackageBody,
        },
      });

      yield put({ type: types.UPDATE_RATES_SUCCESS, payload: { priceIsChanged: false } });
    }

    if (updateRatesTypes.ORDER === updateType) {
      if (!usedReassessLink) {
        yield call(getOrderSaga, { payload: { orderNumber } });
      }

      const expiredTime = yield select(selectOrderExpiredTime);

      yield checkExpiredTime({
        payload: {
          expiredTime: expiredTime,
          onSuccess: function* () {
            const { changedProducts, isPriceChanged } = yield select(selectChangedProducts);
            const package_start_date = yield select(({ smartPackage }) => smartPackage.order?.package_start_date);

            yield put({
              type: types.UPDATE_RATES_SUCCESS,
              payload: {
                priceIsChanged: isPriceChanged,
                ...(changedProducts?.length && {
                  changedHotels: changedProducts.map((el) => ({
                    ...el,
                    days: getDayJourney(package_start_date, el),
                  })),
                }),
              },
            });
          },
          updatesPayload: payload,
        },
      });
    }

    if (isPaymentPage) {
      yield call(initPaymentRequest, { payload: { number: orderNumber } });
    }

    return yield onSuccess && onSuccess();
  } catch (err) {
    yield put({ type: types.UPDATE_RATES_FAILED, payload: checkErrorOnSagas(err) });
    console.error(err);
  }
}

function* partialPaymentSaga({ payload }) {
  const { paymentType } = payload;

  // eslint-disable-next-line no-unused-vars
  const { partial, split, ...price } = yield select(({ global }) => global.price);
  const dataForPayment = yield select(({ order }) => order.dataForPayment);
  const hasCalculatedPartialAmount = !!Number(dataForPayment?.partial_payment_pay_now);

  yield put({
    type: types.PARTIAL_PAYMENT_TOGGLE,
    payload: {
      ...price,
      ...(paymentType === "partial" && {
        partial: {
          partial_price: hasCalculatedPartialAmount
            ? Math.ceil(Number(dataForPayment.partial_payment_pay_now))
            : Math.ceil((Number(dataForPayment.price_to_be_charged) / 100) * 30),
        },
      }),
      ...(paymentType === "split" && {
        split: {
          split_price: Math.ceil(Number(dataForPayment.split_payment_amount) || 200),
        },
      }),
    },
  });
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_WIDGET_INTEGRATION_REQUEST, getWidgetIntegrationSaga),
    yield takeLatest(types.FETCH_PRICES_REQUEST, fetchPricesSaga),
    yield takeLatest(types.UPDATE_RATES_REQUEST, updateRatesSaga),
    yield takeLatest(types.PARTIAL_PAYMENT, partialPaymentSaga),
  ]);
}
