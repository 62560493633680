import React from "react";
import T from "prop-types";
import { useSelector } from "react-redux";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Close, Modal } from "@tint_fe/ui";

import Payment from "../Payment/Payment";
import ExtraOrderSummary from "./ExtraOrderSummary";

const ModalPayment = ({ isOpen, closeModal, selectedExtras, selectedProductExtras, chargeCurrency }) => {
  const { t } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "modal-extra-booking" });
  const rootId = useSelector(({ global }) => global.rootId);

  return (
    <Modal closeModal={closeModal} className={mainCls} isOpen={isOpen} rootId={rootId}>
      <div className={elCls("close")}>
        <button onClick={closeModal}>
          <Close width={24} height={24} />
        </button>
      </div>
      <h3 className={elCls("title", "second-font mg-t-0 mg-b-6")}>{t("steps.payment")}</h3>
      <ExtraOrderSummary
        selectedExtras={selectedExtras}
        selectedProductExtras={selectedProductExtras}
        chardeCurrency={chargeCurrency}
      />
      <Payment />
      {/* <div className={elCls("btns")}>
        <Button className={elCls("btn")} fullsize onClick={() => clickByType()} nextIcon={<ArrowRight />}>
          {t("continue")}
        </Button>
      </div> */}
    </Modal>
  );
};

ModalPayment.propTypes = {
  isOpen: T.bool.isRequired,
  closeModal: T.func.isRequired,
  selectedExtras: T.array,
  selectedProductExtras: T.array,
  chargeCurrency: T.string,
};

export default ModalPayment;
