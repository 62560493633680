import { isArray, isObject, set } from "lodash";

import { flat } from "../utils";

export default class QueryString {
  static _stringifyObject({ obj, key, firstCall }) {
    const values = Object.keys(obj).map((k) => {
      if (k === "rooms") {
        return this._stringifyRooms(obj[k], key);
      }
      if (isArray(obj[k])) {
        return this._stringifyArray(obj[k], k);
      }

      if (isObject(obj[k])) {
        return this._stringifyObject({ obj: obj[k], key: k });
      }

      return `[${k}]=${obj[k]}`;
    });

    return flat(values).map((el) => (firstCall ? `${key}${el}` : `[${key}]${el}`));
  }

  static _stringifyArray(arr, key) {
    return arr.map((item) => {
      if (isArray(item)) {
        return this._stringifyArray(item, key);
      }

      if (isObject(item)) {
        return this._stringifyObject({ obj: item, key });
      }

      return `[${key}][]=${item}`;
    });
  }

  static stringify = (query) => {
    try {
      const array = [];
      if (!query || typeof query !== "object") return "";

      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          const value = query[key];

          switch (true) {
            case key === "rooms":
              array.push(this._stringifyRooms(value));
              break;
            case isArray(value):
              array.push(...this._stringifyArray(value, key, true));
              break;
            case isObject(value):
              array.push(...this._stringifyObject({ obj: value, key, firstCall: true }));
              break;
            default:
              array.push(`${key}=${value}`);
              break;
          }
        }
      }

      if (array.length) {
        const str = array.join("&");

        return str.replace(/"+"/g, "%2B").replace(/\+/g, "%2B").replace(/" "/g, "+");
      }

      return "";
    } catch (err) {
      console.error("query string stringify: ", err);

      return "";
    }
  };

  static parse = (string = window.location.search) => {
    try {
      const obj = {};
      const params = new URLSearchParams(string);

      for (const [key, value] of params) {
        if (key.includes("[") && key.includes("]")) {
          const nesting = key.split("[").map((el) => el.replace("]", ""));

          set(obj, nesting, value);
          continue;
        }

        obj[key] = value;
      }

      return obj;
    } catch (err) {
      console.error("query string parse: ", err);

      return {};
    }
  };

  static getValue = (key, query = window.location.search, type) => {
    try {
      const params = new URLSearchParams(query);

      if (type === "integer") {
        return Number.parseInt(params.get(key), 10) || null;
      } else if (type === "float") {
        return Number.parseFloat(params.get(key)) || null;
      }

      return params.get(key);
    } catch (err) {
      console.error("query string getValue: ", err);

      return null;
    }
  };

  static decode = (text) => {
    try {
      return QueryString.parse(window.atob(text));
    } catch (e) {
      return {};
    }
  };

  static code = (text) => {
    try {
      return window.btoa(text);
    } catch (e) {
      return {};
    }
  };

  static _stringifyRooms(array, key) {
    let roomsString = key ? "[rooms]" : "rooms";
    const startOfItm = key ? `${key}[rooms]` : "rooms";

    array.forEach((item, index) => {
      const children = item.children?.length
        ? item.children.map((child) => `&${startOfItm}[${index}][children][]=${child}`).join("")
        : "";
      roomsString =
        index === 0
          ? `${roomsString}[${index}][adults]=${item.adults}${children}`
          : `${roomsString}&${startOfItm}[${index}][adults]=${item.adults}${children}`;
    });

    return roomsString;
  }
}
