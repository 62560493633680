import React, { useEffect, useMemo, useState } from "react";
import T from "prop-types";
import { v4 as uuidV4 } from "uuid";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import Picker from "rc-picker";

import en_US from "rc-picker/lib/locale/en_US";
import de_DE from "rc-picker/lib/locale/de_DE";
import fr_FR from "rc-picker/lib/locale/fr_FR";
import it_IT from "rc-picker/lib/locale/it_IT";
import es_ES from "rc-picker/lib/locale/es_ES";
import he_IL from "rc-picker/lib/locale/he_IL";

import { parseClasses, dates, join } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

import { Preloader } from "..";
import { Calendar, Close2 } from "../../assets/icons";
import InputWrapper from "../Inputs/InputWrapper";

import "rc-picker/assets/index.css";

// this code is for fixing this issue - https://github.com/react-component/picker/issues/147
de_DE.locale = "de";
fr_FR.locale = "fr";
it_IT.locale = "it";
es_ES.locale = "es";
he_IL.locale = "he";

const LOCALES_MAP = {
  en: en_US,
  de: de_DE,
  fr: fr_FR,
  it: it_IT,
  es: es_ES,
  he: he_IL,
};

// This component duplicates the functionality of the DatePickerInput component
// But alligned to new design:
// https://www.figma.com/file/ULqQt6LXI6hZG6bsXEyZXw/Widgets-%26-Website%2C-TI?node-id=3463%3A32343&t=pu66fC8Z4mPDSN1r-0

const DatePickerInput = ({
  classNameWrapper,
  disabled = false,
  errors,
  label,
  name,
  remark,
  scrollIntoViewOnError,
  placeholder,
  processing,
  hideOutside = true,
  showToday = false,
  disabledDate,
  value,
  defaultPickerValue,
  availableFrom,
  availableTo,
  dropdownAlign,
  format = "d MMM yyyy",
  onMonthChange,
  onChange,
}) => {
  const { i18n } = useTranslation();
  const wrapperId = useMemo(() => uuidV4(), []);
  const [currDate, setCurrDate] = useState(defaultPickerValue || value);
  const [currMode, setCurrMode] = useState("date");

  const handlePanelChange = (val, mode) => {
    if (mode !== "decade") {
      setCurrMode(mode);
    }

    if (mode === "date" && onMonthChange) {
      onMonthChange(val);
    }

    setCurrDate(val);
  };

  const handleDisabledDate = (current) => {
    if (disabledDate) {
      return disabledDate(current, currMode);
    }
  };

  const shouldNavIcon = (cornerDate) => {
    if (!cornerDate) {
      return false;
    }

    switch (currMode) {
      case "date":
        return dates.isSameMonth(cornerDate, currDate, false);
      case "month":
        return dates.isSameYear(cornerDate, currDate, false);
      case "year":
        return dates.isSameDecade(cornerDate, currDate, false);
      default:
        return false;
    }
  };

  const hidePrevIcons = shouldNavIcon(availableFrom);
  const hideNextIcons = shouldNavIcon(availableTo);

  const { mainCls, elCls } = parseClasses({
    base: "date-picker-new",
    classes: [hideOutside && "hide-outside", processing && "processing", !showToday && "hide-today"],
    additional: [classNameWrapper],
  });

  const isAfterStrict = (current) => {
    const lastDayOfMonth = dates.lastDayOfMonth(currDate, false);

    return dates.isAfter(current, lastDayOfMonth) && !dates.isSameDay(current, lastDayOfMonth, false);
  };

  useEffect(() => {
    defaultPickerValue && setCurrDate(defaultPickerValue);
  }, [defaultPickerValue]);

  useEffect(() => {
    value && setCurrDate(value);
  }, [value]);

  return (
    <InputWrapper
      wrapperId={wrapperId}
      classNameWrapper={mainCls}
      name={name}
      label={label}
      error={errors}
      remark={remark}
      disabled={disabled}
      loading={processing}
      withoutOverflowTooltip
      scrollIntoViewOnError={scrollIntoViewOnError}
    >
      <Picker
        className="input"
        defaultPickerValue={defaultPickerValue}
        locale={LOCALES_MAP[i18n.language]}
        generateConfig={dateFnsGenerateConfig}
        inputReadOnly
        disabled={disabled}
        format={format}
        placeholder={placeholder}
        getPopupContainer={() => document.getElementById(wrapperId)}
        dropdownClassName={elCls("dropdown")}
        disabledDate={handleDisabledDate}
        showToday={showToday}
        onPanelChange={handlePanelChange}
        onChange={onChange}
        value={value}
        dropdownAlign={dropdownAlign}
        mode={currMode}
        {...(hidePrevIcons && { prevIcon: null })}
        {...((hidePrevIcons || currMode === "date") && { superPrevIcon: null })}
        {...(hideNextIcons && { nextIcon: null })}
        {...((hideNextIcons || currMode === "date") && { superNextIcon: null })}
        dateRender={(current) => (
          <span className={join(["rc-picker-cell-inner", isAfterStrict(current) && "next-month"], " ")}>
            {new Date(current).getDate()}{" "}
          </span>
        )}
        panelRender={(panel) => (
          <>
            {panel}
            {processing && (
              <div className={elCls("dropdown-preloader")}>
                <Preloader />
              </div>
            )}
          </>
        )}
        suffixIcon={
          value ? (
            <button type="button" onClick={() => onChange()}>
              <Close2 />
            </button>
          ) : (
            <Calendar />
          )
        }
      />
    </InputWrapper>
  );
};

DatePickerInput.propTypes = {
  classNameWrapper: T.string,
  disabled: T.bool,
  errors: T.object,
  label: T.string,
  name: T.string,
  remark: T.string,
  scrollIntoViewOnError: T.bool,
  placeholder: T.string,
  processing: T.bool,
  hideOutside: T.bool,
  showToday: T.bool,
  disabledDate: T.func,
  value: T.oneOfType([T.string, T.instanceOf(Date)]),
  defaultPickerValue: T.oneOfType([T.string, T.instanceOf(Date)]),
  availableFrom: T.oneOfType([T.string, T.instanceOf(Date)]),
  availableTo: T.oneOfType([T.string, T.instanceOf(Date)]),
  dropdownAlign: T.string,
  format: T.string,
  onMonthChange: T.func,
  onChange: T.func,
};

export default DatePickerInput;
