import { QueryString } from "@tint_fe/helpers";
import { COVID_TEST_PRODUCT1, COVID_TEST_PRODUCT1_2, COVID_TEST_PRODUCT3, covidTestProductNumbers } from "./constants";

import { getNumberFromStr } from "../utils";

const checkIsCovidTest = () => {
  const { pid, productId, tourId } = QueryString.parse(document.location.search);

  const id = Number(getNumberFromStr(pid) || productId || tourId);
  const isCovidTest1 = COVID_TEST_PRODUCT1 === id || COVID_TEST_PRODUCT1_2 === id;
  const isCovidTest3 = COVID_TEST_PRODUCT3 === id;
  const isCovidTest = covidTestProductNumbers.includes(id);

  return { isCovidTest, isCovidTest1, isCovidTest3 };
};

export default checkIsCovidTest;
