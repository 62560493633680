import { useRequest } from "@tint_fe/hooks";
import { createPostBookingProductAsExtra } from "../../../../api";

const useProductAsExtra = ({ onSuccess }) => {
  const { callToApi, res, error, loading } = useRequest({ apiCall: createPostBookingProductAsExtra, onSuccess });

  return { patcher: callToApi, res, error, loading };
};

export default useProductAsExtra;
