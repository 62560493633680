import PubSub from "pubsub-js";

const USER = "user";

export const getUserFromSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem(USER));
};

export const saveUserToSessionStorage = (user) => {
  PubSub.publish(USER, user);
  sessionStorage.setItem(USER, JSON.stringify(user));

  return user;
};

export const removeUserToSessionStorage = () => {
  PubSub.publish(USER, null);
  sessionStorage.removeItem(USER);
};

export const subscribeUserChange = (cb) => {
  PubSub.subscribe(USER, (msg, data) => cb(data));
};

export const unsubscribeUserChange = () => {
  PubSub.unsubscribe(USER);
};
