import React from "react";
import T from "prop-types";
import { useSelector } from "react-redux";

import OrderSummaryView from "../../../components/OrderSummaryView";
import useHotelSummaryInfo from "../hooks/useHotelSummaryInfo";
import { selectIfCustomPrice } from "../../../../redux/sharedSelectors";
import PolicyBtn from "../../../components/PolicyBtnModal/PolicyBtn";
import { useAgentCommission, useRouterMatch } from "@tint_fe/hooks";
import { PAYMENT } from "../../../router";

const HotelSummary = () => {
  const orderData = useSelector(({ order }) => order.data);
  const { showAgentCommission, showAgentPays } = useAgentCommission({ order: orderData });

  const integration = useSelector((state) => state.global.integration);
  const price = useSelector((state) => state.global.price);
  const product = useSelector((state) => state.product.data);
  const isCustomPrice = useSelector(selectIfCustomPrice);
  const descriptions = product?.descriptions ?? {};
  const isPaymentPage = useRouterMatch(PAYMENT);

  const requestedPrice = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);
  const citizenshipTaxAmount = useSelector(({ global }) => global.price.citizenship_tax_amount);
  const hotelCurrency = useSelector(({ hotel }) => hotel?.charge_currency);
  const currency = price?.charge_currency ?? hotelCurrency ?? integration?.charge_currency;

  const { startLocation, endLocation, cancellation, lineItems, roundedTotalPrice, taxes } = useHotelSummaryInfo();

  return (
    <OrderSummaryView
      name={product.display_name}
      img={product.cover_image}
      startLocation={startLocation}
      endLocation={endLocation}
      lineItems={lineItems}
      hints={cancellation}
      totalPrice={price?.tint_charge_price}
      roundedTotalPrice={roundedTotalPrice}
      citizenshipTaxAmount={citizenshipTaxAmount}
      requestedPrice={requestedPrice}
      showSummaryDetails={!isCustomPrice}
      currency={currency}
      taxes={taxes}
      descriptions={descriptions}
      descriptionsComponent={isPaymentPage ? <PolicyBtn /> : null}
      agentFeeAmount={showAgentCommission ? price?.agent_fee_amount : 0}
      agentPaysAmount={showAgentPays ? price?.tint_charge_price : 0}
    />
  );
};

HotelSummary.propTypes = {
  selectedRooms: T.array,
};

export default HotelSummary;
