import axios from "axios";

import {
  QueryString,
  getUuidFromSessionStorage,
  setTintJwtLocalStorage,
  getTintJwtLocalStorage,
  getApiUrlLocalStorage,
} from "@tint_fe/helpers";
import { normalizeResponseErrors } from "./helpers";

class Api {
  constructor() {
    const apiUrlLocalStorage = getApiUrlLocalStorage();
    const { api_url } = apiUrlLocalStorage || {};
    const api = axios.create({
      baseURL: api_url || process.env.REACT_APP_API_URL,
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        "Referrer-Policy": "no-referrer",
      },
    });

    api.interceptors.response.use(
      (res) => {
        if (res.headers["authorization-customer"]) {
          setTintJwtLocalStorage(res.headers["authorization-customer"]);
        }

        return res;
      },
      (error) => {
        return error;
      },
    );

    this.api = api;
  }

  initialize = ({ name, token, agent_number, currency }) => {
    this.api.defaults.headers = {
      Authorization: `Basic ${btoa(`${name}:${token}`)}`,
    };

    this.agentNumber = agent_number;
    this.currency = currency || "USD";
  };

  changeCurrency = (currency) => {
    this.currency = currency;
  };

  handleError(err) {
    throw normalizeResponseErrors(err);
  }

  checkIsExternal = (path) => {
    return path.includes("https") || path.includes("http");
  };

  addUuid = (withUuid, uuid) => {
    if (uuid) {
      return { headers: { "Order-Uuid": uuid } };
    }

    return withUuid ? { headers: { "Order-Uuid": getUuidFromSessionStorage() } } : {};
  };

  shouldIgnoreCurrency = () => {
    const { search } = window.location;
    const { order } = QueryString.parse(search);

    return !!order;
  };

  addCurrency = () => {
    const charge_currency = this.currency;
    if (charge_currency) {
      return { charge_currency };
    }

    return {};
  };

  addCurrencyToGet = (path) => {
    const separator = path.includes("?") ? "&" : "?";
    const charge_currency = this.currency;

    if (!charge_currency) return path;

    return `${path}${separator}${encodeURIComponent("charge_currency")}=${encodeURIComponent(charge_currency)}`;
  };

  addAuthorizationCustomer = (authorizationCustomer) => {
    const token = getTintJwtLocalStorage();

    return authorizationCustomer && token ? { headers: { "authorization-customer": token } } : {};
  };

  async get({ path, params, withUuid, authorizationCustomer, pagination, uuid }) {
    const pathWithCurrency = this.addCurrencyToGet(path);

    try {
      if (this.checkIsExternal(pathWithCurrency)) {
        const res = await axios.get(pathWithCurrency);
        if (res instanceof Error) {
          throw res;
        }

        return res.data;
      }

      const res = await this.api.get(pathWithCurrency, {
        params,
        paramsSerializer: QueryString.stringify,
        ...this.addUuid(withUuid, uuid),
        ...this.addAuthorizationCustomer(authorizationCustomer),
      });

      if (res instanceof Error) {
        throw res;
      }

      return pagination ? { data: res.data, total: res.headers.total } : res.data;
    } catch (err) {
      throw this.handleError(err);
    }
  }

  async patch({ path, body, withUuid, authorizationCustomer }) {
    try {
      const res = await this.api.patch(
        path,
        { ...body, ...this.addCurrency() },
        {
          ...this.addUuid(withUuid),
          ...this.addAuthorizationCustomer(authorizationCustomer),
        },
      );
      if (res instanceof Error) {
        throw res;
      }

      return res.data;
    } catch (err) {
      throw this.handleError(err);
    }
  }

  async post({ path, body, withUuid, withCurrencyInOrder, uuid, authorizationCustomer, settings = {} }) {
    const reqBody = withCurrencyInOrder
      ? {
          ...body,
          order: {
            ...body.order,
            ...this.addCurrency(),
          },
        }
      : body;

    try {
      const res = await this.api.post(
        path,
        { ...reqBody, ...this.addCurrency() },
        {
          ...this.addUuid(withUuid, uuid),
          ...this.addAuthorizationCustomer(authorizationCustomer),
          ...settings,
        },
      );
      if (res instanceof Error) {
        throw res;
      }

      return res.data;
    } catch (err) {
      throw this.handleError(err);
    }
  }

  async put({ path, body, withUuid, settings = {}, authorizationCustomer }) {
    try {
      const res = await this.api.put(
        path,
        {
          ...body,
          ...this.addCurrency(),
        },
        {
          ...this.addUuid(withUuid),
          ...this.addAuthorizationCustomer(authorizationCustomer),
          ...settings,
        },
      );
      if (res instanceof Error) {
        throw res;
      }

      return res.data;
    } catch (err) {
      throw this.handleError(err);
    }
  }

  async delete({ path, body, withUuid, authorizationCustomer }) {
    try {
      const res = await this.api.delete(path, {
        data: body,
        ...this.addUuid(withUuid),
        ...this.addAuthorizationCustomer(authorizationCustomer),
      });
      if (res instanceof Error) {
        throw res;
      }

      return res.data;
    } catch (err) {
      throw this.handleError(err);
    }
  }

  getAgentNumber() {
    return this.agentNumber;
  }
}

const api = new Api();

export default api;
