import * as types from "./productActionTypes";

export const getProductRequest = (payload) => ({
  type: types.GET_PRODUCT_REQUEST,
  payload,
});

export const getPriceBandsRequest = (payload) => ({
  type: types.GET_TOUR_PRICE_BANDS_REQUEST,
  payload,
});

export const getTourAvailabilityAction = (payload) => ({
  type: types.GET_TOUR_AVAILABILITY_REQUEST,
  payload,
});

export const getPickupPointsByLocationActions = (payload) => ({
  type: types.GET_PICKUP_POINTS_BY_LOCATION_REQUEST,
  payload,
});

export const getScheduleActivityAction = (payload) => ({
  type: types.GET_SCHEDULE_ACTIVITY_REQUEST,
  payload,
});
