import * as React from "react";

function SvgSpainFlag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path d="M37 0H0v32h37V0z" fill="#C60B1E" />
      <path d="M37 6H0v20h37V6z" fill="#FFC400" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.143 14.443v5.038c0 1.4-1.248 2.52-2.786 2.52H9.643c-1.534-.005-2.786-1.13-2.786-2.524v-5.038c0-1.144.832-2.1 1.976-2.41.346-1.04 1.408-.107 2.667-.107 1.267 0 2.322-.926 2.667.11 1.14.317 1.976 1.275 1.976 2.41z"
        fill="#C8B47C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.143 15.998H18v5.998h-1.857v-5.998zM5 15.998h1.857v5.998H5v-5.998z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.143 19.997H18v1.999h-1.857v-2zM5 19.997h1.857v1.999H5v-2z"
        fill="#165C96"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.143 13.998H18v2h-1.857v-2zM5 13.998h1.857v2H5v-2z"
        fill="#A0790A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.715 13.998h1.857v3H8.715v-3zm3.714 4h1.857v2.998H12.43v-2.999z"
        fill="#D20636"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.429 13.998h1.857v3h-1.857v-3z" fill="#A18793" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.715 17.998h1.857v2.999H8.715v-3z" fill="#FFE100" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.572 13.999l-1.857-2h5.571l-1.857 2h-1.857z" fill="#B6161A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.571 10h1.857v2h-1.857v-2z" fill="#AC9300" />
      <path
        d="M0 0v-1h-1v1h1zm37 0h1v-1h-1v1zm0 32v1h1v-1h-1zM0 32h-1v1h1v-1zM0 1h37v-2H0v2zm36-1v32h2V0h-2zm1 31H0v2h37v-2zM1 32V0h-2v32h2z"
        fill="#F0F1F2"
      />
    </svg>
  );
}

export default SvgSpainFlag;
