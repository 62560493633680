import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import HotelsBucket from "../../../components/HotelsBucket";
import FormContainer from "../../../components/Form/FormContainer";
import ButtonsGroup from "./ButtonsGroup";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import {
  useUpdateSelectedHotels,
  useGetAccomodation,
  useGetPersons,
  useCreateSPOrder,
  useSPCheckoutChanges,
  useGetSPReplaceHotelList,
} from "../hooks";
import { SMART_PACKAGE_CHECKOUT, SMART_PACKAGE_CUSTOMER } from "../../../router";
import { QueryString, capitalizeFirstLetter, handleABTestingFeatures } from "../../../../utils";
import { selectError } from "../../../../redux/sharedSelectors";
import { setReplaceHotelIdAction } from "../../../../redux/smartPackage/smartPackageAction";
import { setRefetchPricesDueCurrencyChange } from "../../../../redux/global/globalActions";
import { getSessionStorageSourceLink } from "../../../../storage";
import { getCurrAccommodationPrice } from "../helpers";
import { ga_start_hotel_step } from "@tint_fe/helpers/services/google-analytics-v2";
import { getOrderRequest } from "../../../../redux/actions";

const SelectHotels = () => {
  const { t } = useTranslation();
  const { push, replace } = useHistoryNavigation();
  const selectHotelsRef = useRef(null);

  const createSPOrder = useCreateSPOrder();
  const getAccomodation = useGetAccomodation();
  const getPersons = useGetPersons();
  const setReplaceHotelId = useReduxAction(setReplaceHotelIdAction);
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);
  const getOrder = useReduxAction(getOrderRequest);

  const product = useSelector((state) => state.smartPackage.data);
  const spCities = product?.cities;
  const prices = product?.prices;

  const replaceHotelId = useSelector((state) => state.smartPackage.replaceHotelId);
  const replaceProductItemId = useSelector((state) => state.smartPackage.replaceProductItemId);
  const accommodation = useSelector((state) => state.smartPackage.accommodation);
  const gettingAccommodation = useSelector((state) => state.smartPackage.fetching);
  const errors = useSelector(selectError);
  const loading = useSelector((state) => state.order.loading);
  const isFetchPricesLoading = useSelector((state) => state.global.loadingFetchPrices);
  const currency = useSelector((state) => state.smartPackage?.charge_currency);
  const globalCurrency = useSelector(({ global }) => global.price?.charge_currency);
  const orderNumber = useSelector(({ order }) => order.data?.number);
  const shouldRefetchPrices = useSelector((state) => state.global.refetchPricesDueCurrencyChange);
  const integrationName = useSelector(({ global }) => global.integration.name);

  const { selectedHotels, changeSelectedHotel } = useUpdateSelectedHotels();
  const accommodationPrice = getCurrAccommodationPrice(selectedHotels);

  const getSelectedHotel = (city_id) => selectedHotels.find((city) => city.city_id === city_id);
  const isReplace = !!replaceHotelId || !!replaceProductItemId;
  const { autoAccommodation } = handleABTestingFeatures();

  const scrollToNextBucket = (number) => {
    if (number >= accommodation.length) {
      return;
    }

    const el = document.querySelector(`#hotel-bucket-${number} .hotel-card__selected`);
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    ga_start_hotel_step(product);
  }, []);

  // Get accommodation list: hotel-replace or accommodation
  useEffect(() => {
    if (!accommodation?.length && !autoAccommodation && !isReplace) {
      getAccomodation({ preventRedirect: true });
    }
  }, []);

  const {
    hotels_list: replaceHotelsList,
    loading: replaceLoading,
    initLoaded: replaceInitLoaded,
    replaceHotelFn,
  } = useGetSPReplaceHotelList({
    productItemId: replaceProductItemId,
    orderNumber,
  });

  useEffect(() => {
    return () => {
      setReplaceHotelId({ replaceHotelId: null, replaceProductItemId: null });
    };
  }, []);

  useEffect(() => {
    const selectedHotel = document.querySelector(".replace-item-card__selected");
    if (selectedHotel) {
      selectedHotel.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [replaceHotelsList]);

  const handleSelectedHotel = (dates, city_id, hotel, bucketId) => {
    if (isReplace) {
      return replaceHotelFn({ hotel_id: hotel.id });
    }

    !isReplace && bucketId && scrollToNextBucket(bucketId);
    changeSelectedHotel(dates, { ...hotel, city_id: city_id });
  };

  useSPCheckoutChanges();

  const city = isReplace && spCities.find((el) => el.id === replaceHotelsList?.product_item?.city_id);

  const onGoBack = () => {
    const sourceLink = getSessionStorageSourceLink();

    if (isReplace) {
      const newSearch = QueryString.stringify({
        ...QueryString.parse(document.location.search),
        order: orderNumber,
      });
      replace({ pathname: SMART_PACKAGE_CUSTOMER, search: newSearch });
    } else {
      if (sourceLink) {
        window.location = sourceLink;

        return;
      }
      push(SMART_PACKAGE_CHECKOUT);
    }
  };

  const onSubmit = () => {
    if (isReplace) {
      return onGoBack();
    }
    createSPOrder(selectedHotels);
  };

  useEffect(() => {
    if (shouldRefetchPrices) {
      setRefetchPrices(false);
      if (isReplace) {
        setTimeout(() => {
          !!orderNumber && getOrder({ orderNumber });
          onSubmit();
        }, 300);
      } else {
        getAccomodation({ preventRedirect: true });
      }
    }
  }, [shouldRefetchPrices]);

  useEffect(() => {
    if (!!orderNumber && currency !== globalCurrency) {
      setRefetchPrices(false);
      !isReplace && getAccomodation({ preventRedirect: true });
    }
  }, []);

  const formLoading = replaceInitLoaded
    ? loading || gettingAccommodation || isFetchPricesLoading
    : loading || gettingAccommodation || replaceLoading || isFetchPricesLoading;

  return (
    <>
      <FormContainer
        formContainerClassName="select-hotels__form-container"
        titleClassName="select-hotels__title"
        subtitleClassName="select-hotels__title"
        subtitle={capitalizeFirstLetter(t("hotelSelection.subtitle"))}
        errors={errors}
        loading={formLoading}
        scrollOnLoadingToTop
        useInformativeLoader={gettingAccommodation}
        integrationName={integrationName}
      >
        <div className="select-hotels" ref={selectHotelsRef}>
          {!isReplace &&
            accommodation?.map((el, index) => {
              return (
                <HotelsBucket
                  key={`hotel-bucket-${el.city_id}-${el.nights}`}
                  index={index}
                  city={el.city_name}
                  hotels={el.hotels}
                  selectedHotel={getSelectedHotel(el.city_id)}
                  changeSelectedHotel={(...args) => handleSelectedHotel(el.dates, el.city_id, ...args)}
                  persons={getPersons()}
                  dates={el.dates}
                  currency={currency}
                  initShowMore={!!replaceHotelsList}
                  accommodationPrice={accommodationPrice}
                  toursPrice={prices?.tours_price}
                />
              );
            })}
          {isReplace && !!city && (
            <HotelsBucket
              key={`hotel-bucket-${city.city_id}-${city.name}`}
              index={1}
              city={city.name}
              hotels={replaceHotelsList?.hotels ?? []}
              selectedHotel={{
                product_id: replaceHotelId,
                ...replaceHotelsList?.hotels?.find((h) => h.hotel_id === replaceHotelId),
              }}
              changeSelectedHotel={(...args) => handleSelectedHotel(city.dates, city.city_id, ...args)}
              persons={getPersons()}
              dates={city.dates}
              currency={globalCurrency}
              initShowMore={!!replaceHotelsList}
              accommodationPrice={accommodationPrice}
              toursPrice={replaceHotelsList?.tours_price}
            />
          )}
        </div>
      </FormContainer>
      <ButtonsGroup
        onBack={onGoBack}
        onSubmit={onSubmit}
        loading={formLoading}
        submitTitleOverride={replaceHotelsList ? t("save") : null}
      />
    </>
  );
};

export default SelectHotels;
