import React, { useState } from "react";
import T from "prop-types";
import { FormProvider, useForm } from "react-hook-form";

import { parseClasses, validationRule } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { patchCompanions } from "../../../../api";

import { CompanionsFace, Cross16, Plus16 } from "../../assets/icons";
import ControlledInput from "../Inputs/ControlledInput";
import Button from "../Button/Button";
import Collapse from "../Collapse/Collapse";
import CollapseHeadingSuccess from "../Collapse/CollapseHeadingSuccess";

const formatCompanionsRequestBody = (data, numberOfCompanions) => {
  const { full_name, email } = data;
  const companions = {};
  for (let i = 0; i < numberOfCompanions; i++) {
    companions[i] = {
      full_name: full_name[i],
      email: email[i],
    };
  }

  return { companions: companions };
};

const Companions = ({ className, orderNumber, paxQuantity, hideSection }) => {
  const { t, isRtl } = useTranslation();
  const numberOfCompanions = paxQuantity - 1;
  const [isOpen, setIsOpen] = useState(false);

  const { mainCls, elCls } = parseClasses({
    base: "companions",
    classes: [isRtl && "rtl"],
    additional: className,
  });

  const { control, handleSubmit, ...methods } = useForm({
    shouldFocusError: true,
    reValidateMode: "onChange",
  });

  const onSubmit = async (form) => {
    const formattedRequestBody = formatCompanionsRequestBody(form, numberOfCompanions);
    await patchCompanions({ number: orderNumber, body: formattedRequestBody });
    hideSection();
  };

  const submitCompanions = handleSubmit(onSubmit);

  return (
    <Collapse
      open={isOpen}
      onClick={() => setIsOpen((prev) => !prev)}
      header={<CollapseHeadingSuccess icon={isOpen ? <Cross16 /> : <Plus16 />} text={"Add your travel companions"} />}
      icon={<></>}
      initialHeight={36}
    >
      <FormProvider {...methods}>
        <div className={`${mainCls} mg-t-0`}>
          <div className={elCls("info-col")}>
            <CompanionsFace />
            <div>
              <span>{t("hotels.success.companionsHint")}</span>
            </div>
          </div>
          <div className={elCls("inputs-col")}>
            {[...Array(numberOfCompanions)].map((e, i) => {
              return (
                <div key={`companion${i}`} className={elCls("companion-fields")}>
                  <ControlledInput
                    control={control}
                    classNameWrapper={elCls("input")}
                    name={`full_name.${i}`}
                    placeholder={"Full Name"}
                    label={t("hotels.success.companionFullName", { number: i + 1 })}
                    removeStartEndSpaces
                    rules={{ ...validationRule.required("Full Name") }}
                  />
                  <ControlledInput
                    control={control}
                    classNameWrapper={elCls("input")}
                    name={`email.${i}`}
                    placeholder={"example@gmail.com"}
                    label={t("hotels.success.companionEmail", { number: i + 1 })}
                    rules={{
                      ...validationRule.required(t("formField.emailAddress")),
                      ...validationRule.email,
                    }}
                  />
                </div>
              );
            })}
            <div className={elCls("submit-btn-container")}>
              <Button small background="dark" onClick={submitCompanions} className={elCls("btn")}>
                {t("hotels.success.btn")}
              </Button>
            </div>
          </div>
        </div>
      </FormProvider>
    </Collapse>
  );
};

Companions.propTypes = {
  className: T.string,
  orderNumber: T.string.isRequired,
  paxQuantity: T.number.isRequired,
  hideSection: T.func,
};

export default Companions;
