import { useEffect } from "react";

import { defaultErrorMsgSettings } from "@tint_fe/helpers";
import { useTranslation, useReduxAction } from ".";
import { setErrorAction } from "../redux/global/globalActions";

const useHandleFailPayment = (fail_payment) => {
  const setError = useReduxAction(setErrorAction);
  const { t } = useTranslation();

  useEffect(() => {
    if (fail_payment) {
      setError({
        title: t("paymentFailed.title"),
        messages: [t("error.payment")],
        fail_payment: true,
        ...defaultErrorMsgSettings,
      });
    }
  }, [fail_payment]);
};

export default useHandleFailPayment;
