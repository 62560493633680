import * as React from "react";

const BadgeLastSeats = () => (
  <svg width={58} height={11} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1a1 1 0 0 1 1-1h56a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1Z" fill="var(--extra-color-50)" />
    <path
      d="M1.427 9h4.345V7.72H2.857V2.03h-1.43V9Zm4.488 0h1.56l.48-1.27h2.68L11.11 9h1.56L9.825 2.03h-1.06L5.915 9ZM8.44 6.45l.865-2.295.855 2.295H8.44Zm7.27 2.69c1.486 0 2.47-.9 2.47-2.16 0-1.09-.67-1.67-1.98-2.165-1-.375-1.32-.63-1.32-1.035 0-.38.34-.65.766-.65.435 0 .795.27 1.065.745l1.14-.695c-.445-.875-1.21-1.32-2.16-1.32-1.345 0-2.24.81-2.24 1.95 0 1.07.635 1.68 2.01 2.21.9.34 1.3.585 1.3 1.05 0 .505-.44.8-1 .8-.74 0-1.26-.41-1.4-1.235l-1.395.345c.205 1.31 1.225 2.16 2.745 2.16ZM20.698 9h1.45V3.31h2.09V2.03h-5.64v1.28h2.1V9Zm9.223.14c1.485 0 2.47-.9 2.47-2.16 0-1.09-.67-1.67-1.98-2.165-1-.375-1.32-.63-1.32-1.035 0-.38.34-.65.765-.65.435 0 .795.27 1.065.745l1.14-.695c-.445-.875-1.21-1.32-2.16-1.32-1.345 0-2.24.81-2.24 1.95 0 1.07.635 1.68 2.01 2.21.9.34 1.3.585 1.3 1.05 0 .505-.44.8-1 .8-.74 0-1.26-.41-1.4-1.235l-1.395.345c.205 1.31 1.225 2.16 2.745 2.16ZM33.35 9h1.42V6.16h.956c1.57 0 2.4-.725 2.4-2.095 0-1.365-.81-2.035-2.4-2.035H33.35V9Zm1.42-4.115V3.31h.81c.75 0 1.116.185 1.116.77 0 .615-.41.805-1.115.805h-.81Zm7.325 4.285c2 0 3.385-1.455 3.385-3.65 0-2.205-1.365-3.66-3.385-3.66-2 0-3.375 1.465-3.375 3.66 0 2.185 1.37 3.65 3.375 3.65Zm-1.94-3.65c0-1.43.755-2.29 1.94-2.29 1.19 0 1.94.88 1.94 2.29 0 1.4-.75 2.28-1.94 2.28-1.185 0-1.94-.86-1.94-2.28ZM47.865 9h1.45V3.31h2.09V2.03h-5.64v1.28h2.1V9Zm6.537.14c1.485 0 2.47-.9 2.47-2.16 0-1.09-.67-1.67-1.98-2.165-1-.375-1.32-.63-1.32-1.035 0-.38.34-.65.765-.65.435 0 .795.27 1.065.745l1.14-.695c-.445-.875-1.21-1.32-2.16-1.32-1.345 0-2.24.81-2.24 1.95 0 1.07.635 1.68 2.01 2.21.9.34 1.3.585 1.3 1.05 0 .505-.44.8-1 .8-.74 0-1.26-.41-1.4-1.235l-1.395.345c.205 1.31 1.225 2.16 2.745 2.16Z"
      fill="#fff"
    />
  </svg>
);

export default BadgeLastSeats;
