import React, { useEffect, useState } from "react";
import T from "prop-types";

import { parseClasses, getTotalPaxInRooms, MAX_TOTAL_PAX } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Button, Tooltip } from "..";
import Room from "./Room";
import { Add } from "../../assets/icons";
import RoomsTooltipContent from "./RoomsTooltipContent";
import { useReduxAction } from "@tint_fe/redux-utils";
import { setErrorAction } from "@tint_fe/redux-utils/src/global/globalActions";

const Rooms = ({
  rooms,
  handleRemove,
  handleAdd,
  handleChange,
  className,
  childrenNotAvailable,
  toggleContactUsModal,
  preventScrollIntoView,
  minChildAge,
  maxChildAge,
  isHotel,
  isJP,
}) => {
  const { t } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "rooms", additional: className });
  const [initialNumberOfRooms] = useState(rooms?.length);
  const [showAlert, setShowAlert] = useState();
  const setError = useReduxAction(setErrorAction);

  const { paxOverflow, overflowRooms, maxRooms } = getTotalPaxInRooms(rooms, isHotel);

  useEffect(() => {
    setShowAlert(paxOverflow || overflowRooms);
  }, [paxOverflow, overflowRooms]);

  useEffect(() => {
    if (showAlert) {
      setError({
        status: 400,
        fields: ["rooms"],
        messages: ["errors.package.room.overflowMsg"],
        type: "availability",
        contactUsButton: false,
        interpolate: { maxPax: MAX_TOTAL_PAX, maxRooms },
      });
    }
  }, [showAlert]);

  return (
    <div id="rooms-tooltip-container" className={`${mainCls} mg-t-8`}>
      <div className={elCls("header", "mg-v-6")}>
        <p className={`p7mg-0 ${elCls("title")}`}>{t("rooms.title")}</p>
        <Tooltip
          {...(!(paxOverflow || overflowRooms) && { visible: false })}
          text={<RoomsTooltipContent toggleContactUsModal={toggleContactUsModal} maxRooms={maxRooms} />}
          placement="top"
          containerId="rooms-tooltip-container"
        >
          <Button
            round
            small
            background="blue"
            className={elCls("btn")}
            onClick={() => !(overflowRooms || paxOverflow) && handleAdd()}
            prevIcon={<Add width={16} height={16} fill="#fff" />}
            likeDisabled={overflowRooms || paxOverflow}
          >
            {t("addRoom")}
          </Button>
        </Tooltip>
      </div>
      <div className={elCls("content")}>
        {rooms.map(({ id, children, adults, ...rest }, index) => (
          <Room
            key={id}
            kids={children}
            adults={Number(adults)}
            {...rest}
            number={index + 1}
            hideRemoveBtn={index === 0 && rooms.length === 1}
            onRemove={() => handleRemove(id)}
            onChange={(val) => handleChange({ ...val, id })}
            scrollIntoView={!preventScrollIntoView && initialNumberOfRooms < index + 1}
            childrenNotAvailable={childrenNotAvailable}
            paxOverflow={paxOverflow}
            minChildAge={minChildAge}
            maxChildAge={maxChildAge}
            isJP={isJP}
          />
        ))}
      </div>
    </div>
  );
};

Rooms.propTypes = {
  rooms: T.arrayOf(T.object).isRequired,
  handleRemove: T.func.isRequired,
  handleAdd: T.func,
  handleChange: T.func.isRequired,
  className: T.string,
  childrenNotAvailable: T.bool,
  toggleContactUsModal: T.func,
  preventScrollIntoView: T.bool,
  minChildAge: T.number,
  maxChildAge: T.number,
  isHotel: T.bool,
  isJP: T.bool,
};

export default Rooms;
