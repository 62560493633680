import * as React from "react";
const CompanionsFace = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
    <path
      fill="#21AFE4"
      fillRule="evenodd"
      d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16C0 7.164 7.163 0 16 0s16 7.164 16 16Zm-10 4c0 3.307-2.694 6-6 6-3.307 0-6.018-2.693-6.018-6h12.017ZM13.787 9.876c.96.96.96 2.489 0 3.449l-4.054 4.053-4.053-4.053a2.425 2.425 0 0 1 0-3.45c.96-.96 2.489-.96 3.449 0l.604.605.605-.604c.96-.96 2.489-.96 3.449 0Zm9.037 0c.96-.96 2.489-.96 3.449 0 .96.924.96 2.488 0 3.449l-4.053 4.053-4.054-4.053a2.425 2.425 0 0 1 0-3.45c.96-.96 2.49-.96 3.45 0l.604.605.604-.604Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CompanionsFace;
