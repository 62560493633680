import React, { useState } from "react";
import T from "prop-types";
import { isEmpty } from "lodash";

import { bookingTypes, checkCurrencyChar, parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import Companions from "../Companions/Companions";
import {
  generateImportantNotes,
  generatePaxInfo,
  generateRoomsInfo,
  generateTaxInfo,
  generateTaxInfoString,
} from "../SharedSummary/helpers";
import { generateLines } from "./helpers";
import ImportantNotes from "./ImportantNotes";

const IMPORTANT_NOTES_BUILD_TYPES = [bookingTypes.SMART_PACKAGE, bookingTypes.HOTEL];
const ONE_PRODUCT_ITEM_PRODUCTS = [bookingTypes.HOTEL, bookingTypes.TOUR];

const SharedSuccessSummary = ({ className, children, order, price }) => {
  const { isRtl } = useTranslation();
  const { mainCls, elCls } = parseClasses({
    base: "shared-success-summary",
    classes: [isRtl && "rtl"],
    additional: className,
  });
  const [hideCompanions, setHideCompanions] = useState(!isEmpty(order?.companions) || order?.pax_quantity === 1);
  const showImportantNotes = IMPORTANT_NOTES_BUILD_TYPES.includes(order?.build_type);
  const originalyOneProductItem = ONE_PRODUCT_ITEM_PRODUCTS.includes(order?.build_type);

  const paxInfo = generatePaxInfo(order?.rooms_description, order?.pax_quantity);
  const { totalIncludedTaxes, totalExcludedTaxes } = generateTaxInfo(order.product_items[0]);
  const productTitleExtra = generateRoomsInfo(order.product_items[0]);
  const currencyChar = checkCurrencyChar(price?.charge_currency || "USD");

  const lines = generateLines({
    bookingType: order?.build_type,
    orderNumber: order?.number,
    productTitle: order?.product_items && order.product_items[0].display_name,
    productTitleExtra,
    paxInfo,
    productItems: order?.product_items,
    originalyOneProductItem,
    totalPrice: `${currencyChar}${Number(price?.tint_charge_price || 0).toFixed(2)}`,
    totalPriceTaxInfo: generateTaxInfoString(totalIncludedTaxes, totalExcludedTaxes, currencyChar),
  });

  const importantNotes = generateImportantNotes();

  return (
    <div className={mainCls}>
      {/* Summary lines */}
      <div className="mg-b-4">
        {lines.map((line, index) => (
          <div key={index} className={elCls("line")}>
            <div className={elCls("icon")}>{line.icon}</div>
            <p className={elCls("text", "p5", "mg-0")}>
              {line.text ? `${line.text}: ` : ""}
              <span className={line.text && "bold"}>{line.value}</span> {line?.extraText && line.extraText}
            </p>
          </div>
        ))}
      </div>

      {/* Any child passed */}
      {children}

      {/* Collapsibles */}
      {showImportantNotes && <ImportantNotes classNameWrapper={mainCls} notes={importantNotes} />}
      {!hideCompanions && (
        <Companions
          orderNumber={order.number}
          paxQuantity={order?.pax_quantity}
          hideSection={() => setHideCompanions(true)}
        />
      )}
    </div>
  );
};

SharedSuccessSummary.propTypes = {
  className: T.string,
  children: T.node,
  order: T.object.isRequired,
  price: T.object.isRequired,
};

export default SharedSuccessSummary;
