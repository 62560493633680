import React from "react";
import T from "prop-types";

import { validationRule } from "@tint_fe/helpers";
import { useControlledInput } from "@tint_fe/hooks";
import { Input } from "../../../components";

const CardHolderInput = ({ control, ...rest }) => {
  const {
    field,
    fieldState: { error },
    showValidateMark,
  } = useControlledInput({
    name: "card_holder",
    control,
    rules: {
      ...validationRule.required("paymentForm.cardholderName"),
      ...validationRule.name("paymentForm.cardholderName"),
      ...validationRule.minLength("paymentForm.cardholderName", 5),
    },
  });

  return (
    <Input
      label="paymentForm.cardholderName"
      placeholder="John Doe"
      autoComplete="cc-name"
      showValidateMark={showValidateMark}
      error={error}
      {...rest}
      {...field}
    />
  );
};

CardHolderInput.propTypes = {
  control: T.object.isRequired,
};

export default CardHolderInput;
