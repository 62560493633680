import React from "react";
import T from "prop-types";

import { GoogleAutocomplete as SharedGoogleAutocomplete } from "@tint_fe/ui";

const GoogleAutocomplete = ({ name, errors, onChange, onSelect, ...rest }) => {
  return (
    <SharedGoogleAutocomplete
      classNameWrapper="form-group--wrapper"
      errors={errors}
      onChange={(data) => onChange({ [name]: data })}
      onSelect={(data) => onSelect({ [name]: data })}
      {...rest}
    />
  );
};

GoogleAutocomplete.propTypes = {
  name: T.string.isRequired,
  errors: T.object,
  onChange: T.func.isRequired,
  onSelect: T.func.isRequired,
};

export default GoogleAutocomplete;
