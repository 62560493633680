import * as React from "react";

function SvgGbFlag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g clipPath="url(#GB-flag_svg__clip0)">
        <path fill="#fff" d="M0 0h37v32H0z" />
        <path fill="#0A17A7" d="M0 0h37v32H0z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 21h14v11h9V21h14V11H23V0h-9v11H0v10z" fill="#fff" />
        <path d="M14 11L0 0M23 11L37 0M23 21l14 11M14 21L0 32" stroke="#fff" strokeWidth={5} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-.313.39l10 8 .625-.78-10-8-.625.78zm37.625 0l-10 8-.625-.78 10-8 .625.78zm-10 23.22l10 8-.624.78-10-8 .624-.78zm-27.625 8l10-8 .625.78-10 8-.625-.78zM16 19H0v-6h16V0h5l.143 13H37v6H21v13h-5V19z"
          fill="#E6273E"
        />
      </g>
      <path
        d="M0 0v-1h-1v1h1zm37 0h1v-1h-1v1zm0 32v1h1v-1h-1zM0 32h-1v1h1v-1zM0 1h37v-2H0v2zm36-1v32h2V0h-2zm1 31H0v2h37v-2zM1 32V0h-2v32h2z"
        fill="#F0F1F2"
      />
      <defs>
        <clipPath id="GB-flag_svg__clip0">
          <path d="M0 0h37v32H0V0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgGbFlag;
