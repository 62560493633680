import React from "react";
import T from "prop-types";

import { Select } from "../../../components";
import { useControlledInput, useTranslation } from "@tint_fe/hooks";

const normalizeCardNumber = (number) => {
  const _addSpace = (str) => {
    const result = [];

    for (let i = 0; i < str.length / 4; i++) {
      const start = i * 4;
      result.push(str.slice(start, start + 4));
    }

    return result.join(" ");
  };

  let result = [];
  const partsOFNumber = number.split(".").filter(Boolean);
  const length = partsOFNumber.reduce((acc, el) => acc + el.length, 0);
  const expectedLength = 16;

  const dots = Array(expectedLength - length)
    .fill("•")
    .join("");

  if (partsOFNumber.length === 2) {
    result = [partsOFNumber[0].replace(/[0-9]/g, "•"), dots, partsOFNumber[1]].join("");
  }

  if (partsOFNumber.length === 1) {
    result = [dots, partsOFNumber[0]].join("");
  }

  return _addSpace(result);
};

const SelectPaymentCard = ({ cards, control, onUseAnotherCard, ...rest }) => {
  const { t } = useTranslation();
  const useOtherCard = { value: null, label: t("paymentMethod.useAnotherCard") };
  const options = cards?.map((el) => {
    return { value: el.id, label: normalizeCardNumber(el.number) };
  });

  const {
    field: { onChange, value: controledValue, ...field },
    fieldState: { error },
  } = useControlledInput({
    control,
    name: "credit_card_id",
    defaultValue: options[0].value,
  });

  const handleChange = ({ value }) => {
    onUseAnotherCard(value);
    onChange(value);
  };

  return (
    <Select
      label={"formField.paymentMethod"}
      options={[...options, useOtherCard]}
      onChange={handleChange}
      error={error}
      isSearchable={false}
      showOptionFrom={0}
      value={options.find((el) => el.value === controledValue)}
      {...field}
      {...rest}
    />
  );
};

SelectPaymentCard.propTypes = {
  cards: T.array.isRequired,
  control: T.object.isRequired,
  onUseAnotherCard: T.func.isRequired,
};

export default SelectPaymentCard;
