import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import { isNull } from "lodash";

import { useTranslation } from "@tint_fe/hooks";
import { parseClasses } from "@tint_fe/helpers";
import { TimerIcon } from "../../assets/icons";

const format = (number) => `0${number}`.slice(-2);

const Timer = ({ onTimeExpired, expireAt, timeToShow, ratesUpdating, className }) => {
  const { t } = useTranslation();
  const timeout = useRef();
  const [time, setTime] = useState(null);
  const [showTime, setShowTimer] = useState(false);
  const [ratesExpired, setRatesExpired] = useState(false);

  const { mainCls, elCls } = parseClasses({ base: "timer", additional: [className] });

  const getTimeToNow = (d) => Math.round((d - Date.now()) / 1000);

  const handleTimeExpired = () => {
    if (onTimeExpired && !ratesUpdating && !isNull(time)) {
      onTimeExpired({
        onSuccess: () => {
          setShowTimer(false);
        },
      });

      setRatesExpired(true);
    } else {
      setRatesExpired(true);
    }
  };

  useEffect(() => {
    timeout.current && clearTimeout(timeout.current);

    const diff = typeof expireAt === "number" ? getTimeToNow(expireAt) : 0;

    if (timeToShow && expireAt - new Date().getTime() > timeToShow) {
      setShowTimer(false);
    }

    if (diff > 0 && timeToShow) {
      setRatesExpired(false);
      setTime(diff);
    }

    if (diff <= 0) {
      handleTimeExpired();
    }
  }, [expireAt, timeToShow]);

  useEffect(() => {
    if (time > 0) {
      timeout.current = setTimeout(() => {
        if (!timeToShow || expireAt - new Date().getTime() <= timeToShow) {
          setShowTimer(true);
        }

        setTime(() => getTimeToNow(expireAt));
      }, 1000);
    } else {
      handleTimeExpired();
      clearTimeout(timeout.current);
    }
  }, [time, timeToShow, expireAt]);

  useEffect(() => {
    return () => timeout.current && clearTimeout(timeout.current);
  }, []);

  const showExpired = ratesExpired && !ratesUpdating && time <= 0;
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  return (
    showTime && (
      <div className={mainCls}>
        {time > 0 && !ratesUpdating && (
          <div className={elCls("inner")}>
            <p className="mg-r-1">{"We’ll hold your order for another "}</p>
            <TimerIcon />
            <div className={elCls("time")}>{`${format(minutes)}:${format(seconds)}`}</div>
            <p className="mg-l-1">{" min"}</p>
          </div>
        )}
        {showExpired && <div className={elCls("expired")}>{t("hotelTimer.expired")}</div>}
        {ratesUpdating && <div className={elCls("expired")}>Updating rates...</div>}
      </div>
    )
  );
};

Timer.propTypes = {
  onTimeExpired: T.func,
  expireAt: T.number,
  timeToShow: T.number,
  ratesUpdating: T.bool,
  className: T.string,
};

export default Timer;
