import React, { useEffect } from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import TintCardSelectButton from "../TintCard/TintCardSelectButton";
import { Container } from "..";
import CloseButton from "./CloseButton";
import Modal from "./Modal";

const InfoModalLayout = ({
  closeModal,
  modalClass,
  containerClassName,
  children,
  isOpen,
  onShowInfoModal,
  openMoreInfoModal,
  onSelect,
  btnLabel,
  caption,
  price,
  priceIsFrom,
  selected,
  loading,
  showButtons,
  ...rest
}) => {
  const { mainCls, elCls } = parseClasses({
    base: "info-modal-container",
    classes: [onSelect && "in-top"],
    additional: modalClass,
  });

  const { t } = useTranslation();

  useEffect(() => {
    // we need to track when this modal is open for
    // set effect for other UI elements
    if (openMoreInfoModal !== isOpen) {
      onShowInfoModal && onShowInfoModal(isOpen);
    }
  }, [isOpen, onShowInfoModal]);

  return (
    <Modal drawer="bottom" containerClass={mainCls} closeModal={closeModal} lockBodyScroll isOpen={isOpen} {...rest}>
      <Container className={elCls("container", containerClassName)}>
        <CloseButton onClose={closeModal} className={elCls("close-btn")} />
        {children}
      </Container>
      {onSelect && !loading && (
        <Container className={elCls("control", "mg-t-2")}>
          {!!price && (
            <p className="mg-0 mg-b-1">
              {priceIsFrom && <span className="p2-1 mg-r-1 bold">{t("from")}</span>}
              <span className={elCls("price", "p2-1 bold p7-1")}>{price}</span>
              <span className="p2-1 bold"> / {t("perPerson").toLowerCase()}</span>
            </p>
          )}
          {showButtons && (
            <div className={elCls("btns")}>
              <span className={elCls("caption")}>{caption}</span>
              <TintCardSelectButton
                selected={selected}
                btnClasses={elCls(selected && "selected")}
                btnText={`${selected ? t("selected") : t("select")} ${t(btnLabel)}`}
                onSelect={onSelect}
                small
              />
            </div>
          )}
        </Container>
      )}
    </Modal>
  );
};

InfoModalLayout.defaultProps = {
  showButtons: true,
};

InfoModalLayout.propTypes = {
  closeModal: T.func.isRequired,
  containerClassName: T.string,
  children: T.node.isRequired,
  modalClass: T.string,
  isOpen: T.bool,
  onSelect: T.func,
  price: T.oneOfType([T.string, T.node]),
  btnLabel: T.string,
  caption: T.string,
  selected: T.bool,
  loading: T.bool,
  priceIsFrom: T.bool,
  onShowInfoModal: T.func,
  openMoreInfoModal: T.bool,
  showButtons: T.bool,
};

export default InfoModalLayout;
