import React, { useEffect, useMemo, useState } from "react";
import T from "prop-types";
import { v4 as uuidV4 } from "uuid";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import Picker from "rc-picker";
import { isEmpty } from "lodash";

import en_US from "rc-picker/lib/locale/en_US";
import de_DE from "rc-picker/lib/locale/de_DE";
import fr_FR from "rc-picker/lib/locale/fr_FR";
import it_IT from "rc-picker/lib/locale/it_IT";
import es_ES from "rc-picker/lib/locale/es_ES";
import he_IL from "rc-picker/lib/locale/he_IL";

import { parseClasses, dates, join } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

import { Preloader } from "..";
import { Calendar, DatePickerNextIcon, DatePickerPrevIcon } from "../../assets/icons";
import InputWrapper from "../Inputs/InputWrapper";

import "rc-picker/assets/index.css";

// this code is for fixing this issue - https://github.com/react-component/picker/issues/147
de_DE.locale = "de";
fr_FR.locale = "fr";
it_IT.locale = "it";
es_ES.locale = "es";
he_IL.locale = "he";

const LOCALES_MAP = {
  en: en_US,
  de: de_DE,
  fr: fr_FR,
  it: it_IT,
  es: es_ES,
  he: he_IL,
};

const formatPrice = (price) => {
  if (price >= 10000) {
    return `${Math.floor(price / 1000)}k`;
  }

  if (price >= 1000) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return price;
};

const DatePickerInput = ({
  classNameWrapper,
  hideSuffixIcon,
  disabled = false,
  errors,
  label,
  name,
  defaultMode = "date",
  remark,
  scrollIntoViewOnError,
  placeholder,
  processing,
  hideOutside = true,
  showToday = false,
  disabledDate,
  minPrices = {},
  isMinPricesMode = false,
  currencyChar = "$",
  value,
  defaultPickerValue,
  availableFrom,
  availableTo,
  dropdownAlign,
  format = "d MMM yyyy",
  onMonthChange,
  onChange,
  customPrevIcon,
  customNextIcon,
  defaultOpen = false,
}) => {
  const { i18n } = useTranslation();
  const wrapperId = useMemo(() => uuidV4(), []);
  const [currDate, setCurrDate] = useState(defaultPickerValue || value);
  const [currMode, setCurrMode] = useState(defaultMode);

  const handlePanelChange = (val, mode) => {
    if (mode !== "decade") {
      setCurrMode(mode);
    }

    if (mode === "date" && onMonthChange) {
      onMonthChange(val);
    }

    setCurrDate(val);
  };

  const handleDisabledDate = (current) => {
    if (disabledDate) {
      return disabledDate(current, currMode);
    }
  };

  const shouldNavIcon = (cornerDate) => {
    if (!cornerDate) {
      return false;
    }

    const currDateChecked = currDate || new Date();

    switch (currMode) {
      case "date":
        return dates.isSameMonth(cornerDate, currDateChecked, false);
      case "month":
        return dates.isSameYear(cornerDate, currDateChecked, false);
      case "year":
        return dates.isSameDecade(cornerDate, currDateChecked, false);
      default:
        return false;
    }
  };

  const hidePrevIcons = shouldNavIcon(availableFrom);
  const hideNextIcons = shouldNavIcon(availableTo);

  const { mainCls, elCls } = parseClasses({
    base: "date-picker-new",
    classes: [hideOutside && "hide-outside", processing && "processing", !showToday && "hide-today"],
    additional: [classNameWrapper],
  });

  const isAfterStrict = (current) => {
    const lastDayOfMonth = dates.lastDayOfMonth(currDate, false);

    return dates.isAfter(current, lastDayOfMonth) && !dates.isSameDay(current, lastDayOfMonth, false);
  };

  useEffect(() => {
    defaultPickerValue && setCurrDate(defaultPickerValue);
  }, [defaultPickerValue]);

  useEffect(() => {
    value && setCurrDate(value);
  }, [value]);

  return (
    <InputWrapper
      wrapperId={wrapperId}
      classNameWrapper={mainCls}
      name={name}
      label={label}
      error={errors}
      remark={remark}
      disabled={disabled}
      loading={processing}
      withoutOverflowTooltip
      scrollIntoViewOnError={scrollIntoViewOnError}
    >
      <Picker
        className="input"
        defaultPickerValue={defaultPickerValue}
        locale={LOCALES_MAP[i18n.language]}
        defaultOpen={defaultOpen}
        generateConfig={dateFnsGenerateConfig}
        inputReadOnly
        disabled={disabled}
        format={format}
        placeholder={placeholder}
        getPopupContainer={() => document.getElementById(wrapperId)}
        dropdownClassName={elCls("dropdown")}
        disabledDate={handleDisabledDate}
        showToday={showToday}
        onPanelChange={handlePanelChange}
        onChange={onChange}
        value={value}
        dropdownAlign={dropdownAlign}
        mode={currMode}
        {...(customPrevIcon && { prevIcon: customPrevIcon })}
        {...(customNextIcon && { nextIcon: customNextIcon })}
        {...(hidePrevIcons && { prevIcon: null })}
        {...((hidePrevIcons || currMode === "date") && { superPrevIcon: null })}
        {...(hideNextIcons && { nextIcon: null })}
        {...((hideNextIcons || currMode === "date") && { superNextIcon: null })}
        dateRender={(current) => {
          if (!isMinPricesMode) {
            return (
              <span className={join(["rc-picker-cell-inner", isAfterStrict(current) && "next-month"], " ")}>
                {new Date(current).getDate()}
              </span>
            );
          }

          const formattedDate = dates.format(current, "yyyy-MM-dd", false);

          return (
            <span
              className={join(
                ["rc-picker-cell-inner", isAfterStrict(current) && "next-month", "rc-picker-display-col"],
                " ",
              )}
              style={{ cursor: processing ? "wait" : "pointer" }}
            >
              {new Date(current).getDate()}
              {minPrices.hasOwnProperty(formattedDate) ? (
                <span className={elCls("price")}>
                  {currencyChar}
                  {formatPrice(minPrices[formattedDate].min_price)}
                </span>
              ) : processing ? (
                <span className={elCls("price")} style={{ filter: "blur(2.5px)" }}>
                  {currencyChar}
                  {formatPrice(1234)}
                </span>
              ) : (
                <span className={elCls("price")}>&nbsp;</span>
              )}
            </span>
          );
        }}
        panelRender={(panel) => (
          <>
            {panel}
            {processing && isEmpty(minPrices) && (
              <div className={elCls("dropdown-preloader")}>
                <Preloader />
              </div>
            )}
          </>
        )}
        {...(!hideSuffixIcon && { suffixIcon: <Calendar /> })}
      />
    </InputWrapper>
  );
};

DatePickerInput.defaultProps = {
  customPrevIcon: <DatePickerPrevIcon />,
  customNextIcon: <DatePickerNextIcon />,
};

DatePickerInput.propTypes = {
  classNameWrapper: T.string,
  hideSuffixIcon: T.bool,
  disabled: T.bool,
  errors: T.object,
  label: T.string,
  name: T.string,
  remark: T.string,
  defaultMode: T.oneOf(["date", "month", "year"]),
  scrollIntoViewOnError: T.bool,
  placeholder: T.string,
  processing: T.bool,
  hideOutside: T.bool,
  showToday: T.bool,
  disabledDate: T.func,
  minPrices: T.object,
  isMinPricesMode: T.bool,
  currencyChar: T.string,
  value: T.oneOfType([T.string, T.instanceOf(Date)]),
  defaultPickerValue: T.oneOfType([T.string, T.instanceOf(Date)]),
  availableFrom: T.oneOfType([T.string, T.instanceOf(Date)]),
  availableTo: T.oneOfType([T.string, T.instanceOf(Date)]),
  dropdownAlign: T.oneOfType([T.string, T.object]),
  format: T.string,
  onMonthChange: T.func,
  onChange: T.func,
  customPrevIcon: T.node,
  customNextIcon: T.node,
  defaultOpen: T.bool,
};

export default DatePickerInput;
