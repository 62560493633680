import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { selectProductSettings } from "../../../../redux/product/productSelectors";

const usePreselectDefaultLocation = (field) => {
  const { custom_pickup_point_strategy } = useSelector(selectProductSettings);

  const { setValue } = useFormContext();

  const setDefaultLocation = (cityId, points, altDefaultAddress) => {
    if (custom_pickup_point_strategy) {
      const defaultAddress = points.find((x) => x.city_id === cityId)?.default_address;
      if (defaultAddress || altDefaultAddress) {
        setValue(field, {
          lat: null,
          lon: null,
          name: defaultAddress || altDefaultAddress,
        });

        return defaultAddress || null;
      }
    }

    if (!cityId) {
      setValue(field, null);

      return null;
    }
  };

  return setDefaultLocation;
};

export default usePreselectDefaultLocation;
