import React from "react";
import T from "prop-types";
import { Trans } from "react-i18next";
import { MAX_TOTAL_PAX, MAX_TOTAL_ROOMS } from "@tint_fe/helpers";

const RoomsTooltipContent = ({ toggleContactUsModal, maxRooms }) => {
  return (
    <Trans i18nKey="room.overflowMsg" parent="p" style={{ margin: 0, wordBreak: "break-word" }}>
      We can book up to {{ maxPax: MAX_TOTAL_PAX }} people / {{ maxRooms: maxRooms || MAX_TOTAL_ROOMS }} rooms with the
      Journey Builder. To book an itinerary for larger groups, please
      {toggleContactUsModal ? (
        <button className="tint-link" onClick={() => toggleContactUsModal(true)}>
          contact our team
        </button>
      ) : (
        " contact our team"
      )}
    </Trans>
  );
};

RoomsTooltipContent.propTypes = {
  toggleContactUsModal: T.func,
  maxRooms: T.number,
};

export default RoomsTooltipContent;
