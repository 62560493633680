import React from "react";
import T from "prop-types";

const Switch = ({ name, value, disabled = false, onChange }) => {
  return (
    <div className={`widget-switch__wrapper${value ? " checked" : ""}`}>
      <label htmlFor={`switch-${name}`} className="widget-switch__bg">
        <input
          type="checkbox"
          id={`switch-${name}`}
          checked={!!value}
          disabled={disabled}
          onChange={(evt) => onChange(evt.target.checked)}
        />
        <div className="widget-switch__handle" />
      </label>
    </div>
  );
};

Switch.propTypes = {
  name: T.oneOfType([T.string, T.number]).isRequired,
  value: T.oneOfType([T.bool, T.number, T.string]).isRequired,
  disabled: T.bool,
  onChange: T.func.isRequired,
};

export default Switch;
