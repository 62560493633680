import { toUpFirstLater } from "@tint_fe/helpers";

import useWindowSize from "./useWindowSize";

const breakPoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1226,
  xxl: 1450,
};

const useBreakPoints = () => {
  const { width } = useWindowSize();

  return Object.keys(breakPoints).reduce((acc, key) => {
    acc[`is${toUpFirstLater(key)}`] = width < breakPoints[key];

    return acc;
  }, {});
};

export default useBreakPoints;
