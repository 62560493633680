import { useSelector } from "react-redux";
import { isEmpty, pickBy } from "lodash";

import { dates } from "@tint_fe/helpers";
import { PACKAGE_FORMAT_DATE } from "../../../../helpers/constants";

const getLineItemsAttributes = (hotelRooms) => {
  return Object.values(pickBy(hotelRooms, (val) => !!val.quantity));
};

const useSerializeHotelCheckoutFormData = ({ pid } = {}) => {
  const product = pid ? { id: pid } : useSelector((state) => state.product.data);
  const booking_code_expire_at = useSelector((state) => state.hotel.booking_code_expire_at);

  return (values) => {
    const lineItems = getLineItemsAttributes(values.hotel_line_items_attributes);

    if (isEmpty(lineItems)) {
      throw new Error("error.rooms");
    }

    return {
      product_id: product.id,
      expire_at: booking_code_expire_at,
      start_date: dates.format(values.start_date, PACKAGE_FORMAT_DATE, false),
      end_date: dates.format(values.end_date, PACKAGE_FORMAT_DATE, false),
      hotel_line_items_attributes: { ...lineItems },
    };
  };
};

export default useSerializeHotelCheckoutFormData;
