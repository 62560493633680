import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useReduxAction } from "@tint_fe/redux-utils/hooks";

import { sendDiscountCodeAction } from "../redux/order/orderActions";
import useSerializedTourAttributes from "../react/containers/Tour/hooks/useSerializedTourAttributes";
import { CHECKOUT } from "../react/router/routes";

const useSendDiscount = () => {
  const sendDiscountCode = useReduxAction(sendDiscountCodeAction);
  const serializedTourAttributes = useSerializedTourAttributes();

  const number = useSelector(({ order }) => order.data.number);

  const { pathname } = useLocation();
  const isCheckout = pathname.includes(CHECKOUT);

  return useCallback(
    (payload) => {
      const product_items_attributes = isCheckout
        ? serializedTourAttributes({}, { getExtrasFromFormContext: true })
        : null;

      const pricePayload = product_items_attributes
        ? { order: { number, product_items_attributes: { 0: product_items_attributes } } }
        : null;

      return sendDiscountCode({ ...payload, pricePayload });
    },
    [serializedTourAttributes, isCheckout],
  );
};

export default useSendDiscount;
