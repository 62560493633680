import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { Checkbox } from "@tint_fe/ui";
import { useTranslation } from "@tint_fe/hooks";

import ControllerInput from "../../../../components/Form/ControllerInput";
import {
  selectAvailableDropOffPoints,
  selectAvailablePickupPoints,
  selectProductSettings,
} from "../../../../../redux/product/productSelectors";
import usePreselectDefaultLocation from "../../hooks/usePreselectDefaultLocation";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";

const ReturnInDifferentLocationCheckbox = () => {
  const { t } = useTranslation();
  const { control, watch, setValue, getValues } = useFormContext();

  const availablePickupPoints = useSelector(selectAvailablePickupPoints);
  const availableDropOffPoints = useSelector(selectAvailableDropOffPoints);
  const { custom_dropoff_point_strategy, custom_pickup_point_strategy, display_end_location, different_end_location } =
    useSelector(selectProductSettings);

  const preselectDefaultLocation = usePreselectDefaultLocation("custom_dropoff_point");
  const updateTourPrice = useUpdateTourPrice();

  const startDateValue = watch("start_date");
  const cityId = watch("city_id");
  const pickup_point_id = watch("pickup_point_id");
  const dropoff_point_id = watch("dropoff_point_id");
  // const dropoff_point_id = 0;
  const selectedPickupPoint = availablePickupPoints.find((el) => el.id === pickup_point_id);
  const selectedDropOffPoints = availableDropOffPoints.find((el) => el.id === dropoff_point_id);

  const isAvailableSamePoints =
    !selectedPickupPoint ||
    availableDropOffPoints.some((dropOffPoint) => {
      return dropOffPoint.name === selectedPickupPoint.name;
    });

  const showCheckbox =
    !(display_end_location || different_end_location) &&
    ((availableDropOffPoints.length > 1 &&
      isAvailableSamePoints &&
      !custom_dropoff_point_strategy &&
      !custom_pickup_point_strategy) ||
      (custom_dropoff_point_strategy && custom_pickup_point_strategy && startDateValue));

  const setSameLocation = () => {
    if (custom_dropoff_point_strategy) {
      const customPickupPoint = getValues("custom_pickup_point");
      setValue("dropoff_city_id", cityId);
      updateTourPrice({ formData: { dropoff_city_id: cityId } });

      if (customPickupPoint?.name) {
        setValue("custom_dropoff_point", customPickupPoint);
      } else {
        preselectDefaultLocation(cityId, availableDropOffPoints);
      }
    } else {
      const sameDropOfLocation = availableDropOffPoints.find(({ name }) => name === selectedPickupPoint?.name);

      if (sameDropOfLocation) {
        setValue("dropoff_point_id", sameDropOfLocation.id);
        updateTourPrice({ formData: { dropoff_point_id: sameDropOfLocation.id } });
      }
    }
  };

  useEffect(() => {
    const prevReturnDifferentLocation = getValues("return_different_location");

    if (typeof prevReturnDifferentLocation === "undefined") {
      const returnDifferentLocation =
        availableDropOffPoints.length && availablePickupPoints.length && !isAvailableSamePoints;

      setValue("return_different_location", different_end_location || returnDifferentLocation);
    }

    // set to true if we don't have same pickup and dropoff points
    if (prevReturnDifferentLocation === false && !isAvailableSamePoints) {
      setValue("return_different_location", true);
    }
  }, [isAvailableSamePoints, different_end_location]);

  useEffect(() => {
    if (
      selectedPickupPoint?.name &&
      selectedDropOffPoints?.name &&
      selectedPickupPoint?.name !== selectedDropOffPoints?.name
    ) {
      setValue("return_different_location", true);
    }
  }, [selectedPickupPoint, selectedDropOffPoints]);

  useEffect(() => {
    if (
      typeof custom_pickup_point_strategy !== "undefined" &&
      typeof custom_dropoff_point_strategy !== "undefined" &&
      custom_pickup_point_strategy !== custom_dropoff_point_strategy
    ) {
      setValue("return_different_location", true);
    }
  }, [custom_pickup_point_strategy, custom_dropoff_point_strategy, startDateValue]);

  return showCheckbox ? (
    <ControllerInput
      control={control}
      name="return_different_location"
      render={({ field: { onChange, ...restField } }) => {
        return (
          <Checkbox
            className={"mg-b-6"}
            label={t("checkbox.returnDifferentLocation")}
            onClick={(value) => {
              const isChecked = value?.target?.checked;
              !isChecked && setSameLocation();

              return onChange(isChecked);
            }}
            {...restField}
          />
        );
      }}
    />
  ) : null;
};

export default ReturnInDifferentLocationCheckbox;
