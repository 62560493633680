import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "@tint_fe/hooks";
import { useReduxAction } from "@tint_fe/redux-utils";
import { API_DOWN_ERROR_STATUSES, parseClasses } from "@tint_fe/helpers";

import { Button, Container, GhostIcon, IceCreamBrokenSvg, SnapCarousel } from "@tint_fe/ui";
import { toggleContactUsModalAction } from "@tint_fe/redux-utils/src/global/globalActions";
import { selectError } from "../../../redux/sharedSelectors";
import { api } from "@tint_fe/api";
import CustomerForm from "./CustomerForm";

const POSSIBLE_404_UI = {
  products: "PRODUCTS",
  form: "FORM",
};

const getErrorMsg = (err) => {
  switch (true) {
    case err?.status && API_DOWN_ERROR_STATUSES.includes(err.status):
      return "error.500.page";
    default:
      return err?.messages?.join(" ") || "error.404.page";
  }
};

const UnavailableErrorPage = () => {
  const chargeCurrency = api.currency;
  const showContactUsModal = useReduxAction(toggleContactUsModalAction);
  const error = useSelector(selectError);
  const [activeUI, setActiveUI] = useState(POSSIBLE_404_UI.products);

  const { t } = useTranslation();
  const errorMsg = getErrorMsg(error);
  const downtimeError = error?.status && API_DOWN_ERROR_STATUSES.includes(error.status);

  const { mainCls, elCls } = parseClasses({
    base: "error-page",
  });

  const backupProducts = error?.backup_products;
  const hasBackupProducts = backupProducts && backupProducts.length > 0;

  useEffect(() => {
    if (!hasBackupProducts) {
      setActiveUI(POSSIBLE_404_UI.form);
    }
  }, [error]);

  const onGoBackPress = () => {
    history.back();
  };

  if (errorMsg?.toLowerCase()?.includes("not found")) {
    return (
      <div className={mainCls}>
        <Container className={elCls("container-unavailable")}>
          <div className={elCls("container-error")}>
            <GhostIcon width={48} height={48} />
            <div className={elCls("content")}>
              <p className="mg-0 p4 bold mg-b-1">{t("error.unavailable.title")}</p>
              {hasBackupProducts ? t("error.unavailable.text") : t("error.unavailable.text.no_similar")}

              <div className={elCls("buttons-container")}>
                {activeUI === POSSIBLE_404_UI.products ? (
                  <Button
                    className={elCls("contact-btn-unavailable")}
                    type="button"
                    onClick={() => setActiveUI(POSSIBLE_404_UI.form)}
                  >
                    {t("contactUs")}
                  </Button>
                ) : hasBackupProducts ? (
                  <Button
                    className={elCls("contact-btn-unavailable")}
                    type="button"
                    onClick={() => setActiveUI(POSSIBLE_404_UI.products)}
                  >
                    {"See products"}
                  </Button>
                ) : null}

                <Button className={elCls("go-back-btn-unavailable")} type="button" ghost onClick={onGoBackPress}>
                  {"Go back"}
                </Button>
              </div>
            </div>
          </div>
          {activeUI === POSSIBLE_404_UI.form && <CustomerForm />}
          {activeUI === POSSIBLE_404_UI.products && hasBackupProducts && (
            <div className={elCls("carousel-container")}>
              <SnapCarousel
                type={"tours-unavailable"}
                items={backupProducts}
                chargeCurrency={chargeCurrency}
                onSelectExtra={({ external_link }) => window.open(external_link, "_blank")}
                selectedIds={[]}
              />
            </div>
          )}
        </Container>
      </div>
    );
  }

  return (
    <div className={mainCls}>
      <Container className={elCls("container")}>
        <IceCreamBrokenSvg width={48} height={48} />
        <div className={elCls("content", "mg-l-4")}>
          <p className="mg-0 p4 bold mg-b-1">{downtimeError ? t("error.500.title") : t("error.title")}</p>
          {downtimeError ? `${t(errorMsg)} [${error.status}]` : t(errorMsg)}
          {!downtimeError && (
            <button onClick={showContactUsModal} className="tint-link mg-l-1 p4">
              {t("contactUs")}
            </button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default UnavailableErrorPage;
