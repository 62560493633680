import * as React from "react";

function SvgAlertIcon(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00012 17.5V25H24.0001V17.5C24.0001 16.5151 23.7932 15.5398 23.3912 14.6299C22.9891 13.7199 22.3998 12.8931 21.657 12.1967C20.9141 11.5003 20.0322 10.9478 19.0616 10.5709C18.091 10.194 17.0507 10 16.0001 10C14.9495 10 13.9093 10.194 12.9387 10.5709C11.968 10.9478 11.0861 11.5003 10.3433 12.1967C9.6004 12.8931 9.01112 13.7199 8.60909 14.6299C8.20705 15.5398 8.00012 16.5151 8.00012 17.5ZM13.8788 15.8787C14.4414 15.3161 15.2045 15 16.0001 15V13C14.674 13 13.4023 13.5268 12.4646 14.4645C11.5269 15.4021 11.0001 16.6739 11.0001 18H13.0001C13.0001 17.2044 13.3162 16.4413 13.8788 15.8787Z"
        fill="#D81F1F"
      />
      <path d="M8.00012 10L7.00012 9" stroke="#D81F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.0001 7V5" stroke="#D81F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.0001 10L25.0001 9" stroke="#D81F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.0001 10L25.0001 9" stroke="#D81F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.00012 25L8.00012 24H24.0001L27.0001 25V27H5.00012V25Z" fill="#D81F1F" />
    </svg>
  );
}

export default SvgAlertIcon;
