import React from "react";
import T from "prop-types";

import { Button, SubmitButton } from "@tint_fe/ui";
import { useTranslation } from "../../../../hooks";
import { steps } from "../../../../helpers/constants";
import StickyButtonsGroupWrapper from "../../../components/StickyButtonsGroupWrapper";

const HotelButtonsGroup = ({
  onClearRates,
  onSubmit,
  disabled,
  fetchingGetRooms,
  fetchingCreateOrder,
  fetchingUpdateOrder,
  step,
  isClearSelections,
  isExtra,
}) => {
  const { t } = useTranslation();

  const submitLabel = () => {
    switch (true) {
      case fetchingGetRooms:
        return `${t("loading")}...`;
      case fetchingCreateOrder:
        return `${t("creating")}...`;
      case fetchingUpdateOrder:
        return t("updating");
      case step === steps.EXTRAS:
        return t("continueBooking");
      case isExtra:
        return t("continue");
      default:
        return t("findRooms");
    }
  };

  return (
    <StickyButtonsGroupWrapper className="hotel-buttons__wrapper">
      {isClearSelections && (
        <Button ghost disabled={disabled} onClick={onClearRates}>
          {t("clearSelection")}
        </Button>
      )}
      <SubmitButton
        label={submitLabel()}
        loading={fetchingGetRooms || fetchingCreateOrder || fetchingUpdateOrder}
        disabled={disabled}
        onClick={onSubmit}
      />
    </StickyButtonsGroupWrapper>
  );
};

HotelButtonsGroup.propTypes = {
  onClearRates: T.func,
  onSubmit: T.func,
  disabled: T.bool,
  fetchingGetRooms: T.bool,
  fetchingCreateOrder: T.bool,
  fetchingUpdateOrder: T.bool,
  step: T.string,
  isClearSelections: T.bool,
  isExtra: T.bool,
};

export default HotelButtonsGroup;
