export * from "@tint_fe/redux-utils/src/helpers";
import { pick } from "lodash";

import { AB_TESTING_KEY } from "@tint_fe/helpers";

import { dates as datesUtils, QueryString } from "../utils";

export const sortPriceItems = (order) => {
  return {
    ...order,
    product_items: (order?.product_items || []).map((productItem) => {
      const priceBands = [];
      const productExtras = [];
      const other = [];

      productItem.line_items?.forEach((item) => {
        if (item.product_option_type === "PriceBand") {
          priceBands.push(item);
        } else if (item.product_option_type === "ProductExtra") {
          productExtras.push(item);
        } else {
          other.push(item);
        }
      });

      return {
        ...productItem,
        line_items: [...priceBands, ...other, ...productExtras],
      };
    }),
  };
};

export const serializeOrderProductDetails = (productItem) => {
  return {
    city_id: productItem.city_id || null,
    dropoff_city_id: productItem.dropoff_city_id || null,
    dropoff_location_name: productItem.dropoff_location_name || null,
    dropoff_point_id: productItem.dropoff_point ? productItem.dropoff_point.id : null,
    flight_number: productItem.flight_number || "",
    departure_flight_number: productItem.departure_flight_number || "",
    language_code: productItem.language_code || "",
    pickup_point_id: productItem.pickup_point ? productItem.pickup_point.id : null,
    start_time: (productItem.start_at || "").slice(11, 16).replace(/:/g, " : "),
    start_date: datesUtils.convertDateToUTC(productItem.start_at),
    start_from: productItem.start_from || "",
    custom_pickup_point: {
      name: productItem.custom_pickup_point || null,
      lat: productItem.custom_pickup_lat || null,
      lon: productItem.custom_pickup_lon || null,
    },
    custom_dropoff_point: {
      name: productItem.dropoff_location_name || null,
      lat: productItem.dropoff_location_lat || null,
      lon: productItem.dropoff_location_lon || null,
    },
  };
};

export const createNewOrderSearchParams = (searchString, number) => {
  const piDs = ["pid", "productId", "productid", "tourId", "tourid", "packageId"];
  const search = QueryString.parse(searchString);
  const newSearch = { ...pick(search, [...piDs, AB_TESTING_KEY]), order: number };

  return QueryString.stringify(newSearch) || "";
};

export const serializeOrderProductPriceBands = (productItem, availablePriceBand) => {
  const idsAvailablePriceBand = availablePriceBand?.length > 0 ? availablePriceBand.map(({ id }) => id) : [];

  const obj = {};

  (productItem.price_band_line_items || []).forEach((item) => {
    if (
      typeof item.quantity === "number" &&
      (idsAvailablePriceBand.length === 0 || idsAvailablePriceBand.includes(item.product_option_id))
    ) {
      obj[item.product_option_id] = item.quantity;
    }
  });

  return obj;
};

export const sortPointsByArr = (objArr, sortValArr, sortKey = "name") => {
  const output = [];
  const elementsChecked = [];
  for (const element of sortValArr) {
    for (const obj of objArr) {
      if (obj[sortKey] === element && !elementsChecked.includes(element)) {
        output.push(obj);
        elementsChecked.push(element);
      }
    }
  }

  return output;
};
