import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { SPCheckoutFormFields } from "../helpers";

const { ARRIVAL_EXTRA_NIGHTS, DEPARTURE_EXTRA_NIGHTS } = SPCheckoutFormFields;

const useSkipAccommodation = () => {
  const { getValues } = useFormContext?.() || {};
  const skipAccommodation = useSelector((state) => state.smartPackage.data.skip_accommodation);

  const arrival_extra_nights = getValues?.(ARRIVAL_EXTRA_NIGHTS);
  const departure_extra_nights = getValues?.(DEPARTURE_EXTRA_NIGHTS);
  const isOvernightsSelected = Number(arrival_extra_nights ?? 0) > 0 || Number(departure_extra_nights ?? 0) > 0;

  return {
    allowAccomodation: !skipAccommodation || isOvernightsSelected,
  };
};

export default useSkipAccommodation;
