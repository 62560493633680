import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { FeedbackRatingStar } from "../../assets/icons";

const RatingItem = ({ inRange, checked, name, value, onChange }) => {
  const { mainCls } = parseClasses({
    base: "rating-item",
    classes: [inRange && "in-range", checked && "checked"],
  });

  return (
    <label className={mainCls}>
      <input name={name} type="radio" value={value} onChange={() => onChange(value)} checked={checked} />
      <FeedbackRatingStar />
    </label>
  );
};

RatingItem.propTypes = {
  inRange: T.bool,
  checked: T.bool,
  name: T.string.isRequired,
  value: T.string.isRequired,
  onChange: T.func.isRequired,
};

export default RatingItem;
