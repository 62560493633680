import React from "react";
import T from "prop-types";
import { parseClasses } from "@tint_fe/helpers";
import { Preloader, SnapCarousel } from "@tint_fe/ui";

const StandaloneBookingExtras = ({
  className,
  loading,
  chargeCurrency,
  hasHotelsAsExtra,
  hasToursAsExtra,
  hasExtrasAsExtra,
  hotels,
  tours,
  extras,
  onSelectExtra,
  selectedIds,
}) => {
  const { elCls } = parseClasses({
    base: className,
  });

  const hasAny = hasHotelsAsExtra || hasToursAsExtra || hasExtrasAsExtra;

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      {hasAny && <span className={elCls("extras-title")}>{"Upgrade your trip"}</span>}
      {hasHotelsAsExtra && (
        <>
          <span className={elCls("extras-subtitle")}>
            {"Add recommended  accommodation at special prices before or after your tour"}
          </span>
          <span className={elCls("extras-sub-subtitle")}>
            {
              "We have special prices  available for recommended hotels for before or after your tour thanks to special agreements we make with hotels. See options below, you could even add hotel for your entire trip using these special rates!"
            }
          </span>
          <SnapCarousel
            type={"hotel"}
            items={hotels}
            chargeCurrency={chargeCurrency}
            onSelectExtra={onSelectExtra}
            selectedIds={selectedIds}
          />
        </>
      )}

      {hasToursAsExtra && (
        <>
          <span className={elCls("extras-subtitle")}>{"Add more tours"}</span>
          <span className={elCls("extras-sub-subtitle")}>
            {"We recommend adding these tours to enhance your experience!"}
          </span>
          <SnapCarousel
            type={"tour"}
            items={tours}
            chargeCurrency={chargeCurrency}
            onSelectExtra={onSelectExtra}
            selectedIds={selectedIds}
          />
        </>
      )}

      {hasExtrasAsExtra && (
        <>
          <span className={elCls("extras-subtitle")}>{"Recommended services"}</span>
          <span className={elCls("extras-sub-subtitle")}>
            {"These are our most popular servives, which will just make your experience better!"}
          </span>
          <SnapCarousel
            type={"extra"}
            items={extras}
            chargeCurrency={chargeCurrency}
            onSelectExtra={onSelectExtra}
            selectedIds={selectedIds}
          />
        </>
      )}
    </>
  );
};

StandaloneBookingExtras.propTypes = {
  className: T.string,
  loading: T.bool,
  chargeCurrency: T.string,
  hasHotelsAsExtra: T.bool,
  hasToursAsExtra: T.bool,
  hasExtrasAsExtra: T.bool,
  hotels: T.array,
  tours: T.array,
  extras: T.array,
  onSelectExtra: T.func,
  selectedIds: T.array,
};

export default StandaloneBookingExtras;
