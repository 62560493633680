import React, { useEffect } from "react";

import { toUpFirstLater, errorMsgTypes, API_DOWN_ERROR_STATUSES } from "@tint_fe/helpers";
import { notification } from "@tint_fe/ui/src/components";

import useTranslation from "./useTranslation";
import { buildNotificationHeading } from "@tint_fe/ui/src/components/Notification/helpers";

const errorTypeToNotificationType = {
  [errorMsgTypes.CRITICAL_ERRORS]: "errorBig",
  [errorMsgTypes.NEUTRAL]: "neutralBig",
  [errorMsgTypes.AVAILABILITY]: "availabilityBig",
  [errorMsgTypes.SPLIT_PAYMENT]: "splitPaymentBig",
  [errorMsgTypes.POSITIVE]: "success",
};

const handleInterpolateParameters = (err) => {
  if (
    err.messages.includes("errors.packages.smart.start_city") ||
    err.messages.includes("errors.packages.smart.end_city")
  ) {
    return { city: err.interpolate.city_name, alternative_city: err.interpolate.alternative_cities.join(", ") };
  }

  return err.interpolate;
};

const useShowApiError = ({ error, options, props }) => {
  const { t, i18n } = useTranslation();

  const normalizeErrorMessages = (err) => {
    if (err && typeof err.messages === "string") {
      return t(err.messages);
    }

    const msg = err?.messages
      ?.map((m) => (i18n.exists(m) ? t(m, handleInterpolateParameters(err)) : m))
      .map(toUpFirstLater)
      .join(". ");

    return msg;
  };

  useEffect(() => {
    if (error && !API_DOWN_ERROR_STATUSES.includes(error?.status) && error?.status !== 404) {
      const message = normalizeErrorMessages(error);
      if (!error.type) error.type = errorMsgTypes.NEUTRAL;

      if (error.type === errorMsgTypes.POSITIVE) {
        notification.success({ text: message, options });
      } else if (typeof notification[errorTypeToNotificationType[error.type]] === "function") {
        notification[errorTypeToNotificationType[error.type]]({
          props: {
            children: <p className="mg-0">{message}</p>,
            textBtn: error.contactUsButton ? "contactUs" : "",
            hideBtn: !error.contactUsButton,
            title: buildNotificationHeading(error.messages[0], t),
            message: message,
            ...props,
          },
          options,
        });
      }
    }
  }, [JSON.stringify(error)]);
};

export default useShowApiError;
