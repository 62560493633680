import { all, call, put, takeLatest, select } from "redux-saga/effects";
import lodash from "lodash";

import * as types from "./productActionTypes";
import {
  getProductApi,
  getTourPriceBandsApi,
  getTourAvailabilityApi,
  getPickupPointsByLocationApi,
  getScheduleActivityApi,
} from "../../api";
import { getCheckoutPathByBuildType } from "../../react/router/routes";
import { selectAvailableDropOffPoints, selectAvailablePickupPoints, getAvailableCities } from "./productSelectors";
import { dates } from "@tint_fe/helpers";
import { PACKAGE_FORMAT_DATE } from "../../helpers/constants";

function* getProductSaga(action) {
  const { id, replace } = action.payload;
  try {
    const response = yield call(getProductApi, id);
    const product = response.product || {};

    if (replace) {
      replace(getCheckoutPathByBuildType(product.klass));
    }

    yield put({ type: types.GET_PRODUCT_SUCCESS, payload: product });
  } catch (err) {
    yield put({ type: types.GET_PRODUCT_FAILED, payload: err });
  }
}

function* getTourPriceBandsSaga({ payload }) {
  try {
    const tourId = yield select((state) => state.product.data.id);
    const { query, onSuccess } = payload;
    const response = yield call(getTourPriceBandsApi, {
      tourId,
      query,
    });

    const priceBands = response.product?.available_price_bands || [];

    yield put({
      type: types.GET_TOUR_PRICE_BANDS_SUCCESS,
      payload: lodash.orderBy(priceBands, "name", "asc"),
    });

    if (onSuccess) {
      const availableDropOffPoints = yield select(selectAvailableDropOffPoints);
      const availablePickupPoints = yield select(selectAvailablePickupPoints);

      yield onSuccess && onSuccess(availableDropOffPoints, availablePickupPoints);
    }
  } catch (err) {
    yield put({
      type: types.REQUEST_PRODUCT_FAILED,
      payload: err,
    });
  }
}

function* getTourAvailabilitySaga({ payload }) {
  try {
    const { date, langCode, onSuccess } = payload;
    const singleProduct = yield select((state) => state.product.data);
    const tourId = singleProduct.id;
    const pickupPoints = singleProduct.pickup_points;

    const tourAvailability = yield call(getTourAvailabilityApi, {
      tourId,
      query: { start_date: date },
    });

    const availabilitiesLng = tourAvailability.product?.availabilities?.filter((x) => x.available_quantity > 0);
    const sortedLanguages = lodash.orderBy(availabilitiesLng, "name", "asc");

    const defaultLang = sortedLanguages.find((item) => item.code === (langCode || "en")) || sortedLanguages[0];

    const response = yield call(getTourPriceBandsApi, {
      tourId,
      query: { start_date: date, language_code: defaultLang?.code || "" },
    });

    const priceBands = response.product?.available_price_bands || [];

    // need to save this city severalty for Start From input
    const cities = getAvailableCities(priceBands, pickupPoints);

    yield put({
      type: types.GET_TOUR_AVAILABILITY_SUCCESS,
      payload: { availableLanguages: sortedLanguages, cities },
    });

    if (onSuccess) {
      const dropOffPoints = yield select(selectAvailableDropOffPoints);
      const pickUpPoints = yield select(selectAvailablePickupPoints);

      yield onSuccess({
        dropOffPoints,
        pickUpPoints,
        defaultLang: defaultLang || { code: "en" },
        cities,
      });
    }
  } catch (err) {
    yield put({ type: types.REQUEST_PRODUCT_FAILED, payload: err });
  }
}

function* getScheduleActivityRequest({ payload }) {
  try {
    const { date, pickup_point_id = null } = payload;
    const singleProduct = yield select((state) => state.product.data);
    const activityId = singleProduct.id;

    const activitySchedule = yield call(getScheduleActivityApi, {
      id: activityId,
      params: { date: dates.format(date, PACKAGE_FORMAT_DATE, false), ...(pickup_point_id && { pickup_point_id }) },
    });

    const res = activitySchedule?.product?.schedule || [{ groups: [] }];

    // { "error": "Cant find schedule for the day 2023-09-13 | Required 1 ticket(s) | Pickup n/a" }
    if (res.hasOwnProperty("error")) {
      yield put({
        type: types.GET_SCHEDULE_ACTIVITY_FAILURE,
        payload: {
          status: 400,
          fields: ["start_date"],
          messages: ["errors.activity.scheduleDateIsNotAvailable"],
          type: "availability",
          contactUsButton: false,
        },
      });

      return;
    }

    const groups = res[0].groups;
    yield put({
      type: types.GET_SCHEDULE_ACTIVITY_SUCCESS,
      payload: { groups },
    });
  } catch (err) {
    yield put({ type: types.GET_SCHEDULE_ACTIVITY_FAILURE, payload: err });
  }
}

function* getPickupPointsByLocationSaga({ payload }) {
  try {
    const { query, onSuccess } = payload;
    const productId = yield select(({ product }) => product.data.id);
    const availableCities = yield select(({ product }) => product.availability.cities);
    const response = yield call(getPickupPointsByLocationApi, { tourId: productId, query });
    const pointsByLocation = response.product?.pickup_points || [];

    const pickupPointsFromLocation = pointsByLocation.filter((x) => {
      return availableCities.find((y) => y.id === x.city_id);
    });

    yield put({
      type: types.GET_PICKUP_POINTS_BY_LOCATION_SUCCESS,
      payload: pickupPointsFromLocation,
    });

    onSuccess && onSuccess(pickupPointsFromLocation[0]);
  } catch (err) {
    console.error(err);
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_PRODUCT_REQUEST, getProductSaga),
    yield takeLatest(types.GET_TOUR_PRICE_BANDS_REQUEST, getTourPriceBandsSaga),
    yield takeLatest(types.GET_TOUR_AVAILABILITY_REQUEST, getTourAvailabilitySaga),
    yield takeLatest(types.GET_PICKUP_POINTS_BY_LOCATION_REQUEST, getPickupPointsByLocationSaga),
    yield takeLatest(types.GET_SCHEDULE_ACTIVITY_REQUEST, getScheduleActivityRequest),
  ]);
}
