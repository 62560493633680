import React, { useEffect, useState } from "react";
import T from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { useRequestWithGlobalError } from "@tint_fe/redux-utils/hooks";
import { useTranslation } from "@tint_fe/hooks";
import { updateUserFeedback } from "@tint_fe/api";

import { Preloader, SubmitButton } from "../../components";
import FeedbackForm from "./FeedbackForm";

const defaultFeedback = {
  rating: "",
  comment: "",
  referral_source: "",
};

const FeedbackPage = ({ onSuccess, product_item_id, rating }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const orderNumber = useSelector(({ order }) => order.data.number);
  const customerName = useSelector(({ order }) => order.data.customer?.first_name);
  const externalFeedbacksLink = useSelector(({ global }) => global.integration.external_feedbacks_link);

  const product = useSelector(({ order: { data } }) => {
    return product_item_id
      ? data.product_items?.find((el) => el.id === product_item_id)
      : data.product_items?.[0]?.display_name;
  });

  const feedback = product?.feedback || { ...defaultFeedback, rating };
  const productName = product?.display_name || "product";

  const { t } = useTranslation();
  const { handleSubmit, control, getValues } = useForm();

  const redirectToCompeted = () => {
    const ratingFromForm = getValues("rating");

    if (ratingFromForm >= 4 && externalFeedbacksLink) {
      window.location.href = externalFeedbacksLink;

      return;
    }

    onSuccess && onSuccess();
  };

  const { callToApi, loading } = useRequestWithGlobalError({
    apiCall: updateUserFeedback,
    shouldSetErrorInGlobalStore: true,
    onSuccess: () => redirectToCompeted,
  });

  const onSubmit = handleSubmit((val) => {
    callToApi({
      order: orderNumber,
      product: product_item_id,
      body: {
        product_item: {
          feedback_attributes: val,
        },
      },
    });
  });

  useEffect(() => {
    if (rating && orderNumber) {
      const sendInitialFeedback = async () => {
        await updateUserFeedback({
          order: orderNumber,
          product: product_item_id,
          body: {
            product_item: {
              feedback_attributes: { rating },
            },
          },
        });

        if (rating > 3) {
          document.location.href = externalFeedbacksLink;
        } else {
          setInitialLoading(false);
        }
      };

      sendInitialFeedback();
    }
  }, [orderNumber]);

  return (
    <div className="feedback-page">
      {!initialLoading ? (
        <>
          <FeedbackForm
            control={control}
            {...(feedback && { feedback })}
            productName={productName}
            customerName={customerName}
          />
          <SubmitButton loading={loading} disabled={loading} onClick={onSubmit} label={t("sendFeedback")} />
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

FeedbackPage.propTypes = {
  onSuccess: T.func,
  product_item_id: T.number,
  rating: T.number,
};

export default FeedbackPage;
