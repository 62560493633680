import { useRequest } from "@tint_fe/hooks";
import { getProductById } from "@tint_fe/api";
import { useEffect } from "react";

const useProductDetails = (productId, preventRequest) => {
  const { callToApi, res, loading } = useRequest({
    apiCall: getProductById,
  });

  useEffect(() => {
    if (preventRequest) return;

    callToApi(productId);
  }, [productId, preventRequest]);

  return { productDetails: res?.product, productLoading: loading };
};

export default useProductDetails;
