import { useEffect } from "react";

const useHandleESC = (cb, el, ...dependencies) => {
  function handleESC(event) {
    if (event.key === "Escape") {
      cb(event);
    }
  }

  useEffect(() => {
    if (el) {
      el.addEventListener("keydown", handleESC);

      return () => {
        el.removeEventListener("keydown", handleESC);
      };
    }
  }, [el, ...dependencies]);
};

export default useHandleESC;
