import * as React from "react";
const ConfirmationNumber = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#151209"
      d="M15.645 14.3c-.38.5-.99.75-1.83.75H12.45v-6h1.365c.84 0 1.45.255 1.83.765.39.5.585 1.245.585 2.235 0 .99-.195 1.74-.585 2.25Z"
    />
    <path
      fill="#151209"
      fillRule="evenodd"
      d="M3 1a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H3Zm4.675 16V7.1h-2.58V17h2.58Zm9.95-8.61c-.82-.86-2.005-1.29-3.555-1.29h-4.2V17h4.2c1.55 0 2.735-.43 3.555-1.29.83-.86 1.245-2.08 1.245-3.66s-.415-2.8-1.245-3.66Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ConfirmationNumber;
