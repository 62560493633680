import React from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import { parseClasses } from "@tint_fe/helpers";
import InputWrapper from "./InputWrapper";

const Textarea = React.forwardRef(
  ({ showValidateMark, label, error, className, classNameWrapper, disabled, ...rest }, ref) => {
    const { isRtl } = useTranslation();

    const { mainCls } = parseClasses({
      base: "input",
      additional: className,
    });

    return (
      <InputWrapper
        classNameWrapper={classNameWrapper}
        error={error}
        label={label}
        type="textarea"
        showValidateMark={showValidateMark}
        disabled={disabled}
      >
        <textarea dir={isRtl ? "rtl" : "auto"} className={mainCls} ref={ref} disabled={disabled} {...rest} />
      </InputWrapper>
    );
  },
);

Textarea.propTypes = {
  showValidateMark: T.bool,
  label: T.string,
  error: T.object,
  className: T.string,
  classNameWrapper: T.string,
  disabled: T.bool,
};

export default Textarea;
