import React, { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { PaymentForm, BackButton, SubmitButton, GPayButton, ApplePayBtn, Button } from "@tint_fe/ui";
import { QueryString, paymentMethods, dates, errorMsgTypes } from "@tint_fe/helpers";
import { useIntegrationCode, useGetCredoraxPaymentPlugin } from "@tint_fe/redux-utils/hooks";

import {
  useTranslation,
  useReduxAction,
  useHistoryNavigation,
  useSelectedPaymentMethod,
  useGetUser,
  useCustomerDataValidForPayment,
  useCreditCardDetails,
} from "../../../hooks";
import { requestPaymentAction, initPaymentRequestActions, getOrderRequest } from "../../../redux/actions";
import {
  clearErrorAction,
  partialPaymentAction,
  setErrorAction,
  setRefetchPricesDueCurrencyChange,
  setShouldPreselectSplitPaymentAction,
} from "../../../redux/global/globalActions";
import { getCustomerPathByBuildType } from "../../router";
import { selectOrderNumber } from "../../../redux/sharedSelectors";
import { selectOrderError } from "../../../redux/order/orderSelectors";
import FormContainer from "../../components/Form/FormContainer";
import { clearDataForPaymentAction, createUserAgentPaymentRequest } from "../../../redux/order/orderActions";
import { getPaymentsLinks, getSuccessPageUrl } from "../../../utils";
import { ga_add_payment_info } from "@tint_fe/helpers/services/google-analytics-v2";

const ACT_LIKE_EXTRA_PAYMENT_ORDER_STATE = ["passport", "completed", "confirmed"];

const Payment = () => {
  const requestPayment = useReduxAction(requestPaymentAction);
  const createUserAgentPayment = useReduxAction(createUserAgentPaymentRequest);
  const getOrder = useReduxAction(getOrderRequest);
  const initPaymentRequest = useReduxAction(initPaymentRequestActions);
  const clearDataForPayment = useReduxAction(clearDataForPaymentAction);
  const partialPayment = useReduxAction(partialPaymentAction);
  const setError = useReduxAction(setErrorAction);
  const clearError = useReduxAction(clearErrorAction);
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);
  const setShouldPreselectSplitPayment = useReduxAction(setShouldPreselectSplitPaymentAction);
  const { creditCardDetails, loading: creditCardsLoading } = useCreditCardDetails();

  const brandName = useSelector(({ global }) => global.integration.brand_name);
  const javascript_url = useSelector(({ order }) => order.dataForPayment.javascript_url);
  const priceToBeCharged = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);
  const partial_payments_enabled = useSelector(({ order }) => order.dataForPayment.partial_payments_enabled);
  const partial_payment_pay_now = useSelector(({ order }) => order.dataForPayment?.partial_payment_pay_now);
  const second_payment_charge_date = useSelector(({ order }) => order.dataForPayment?.second_payment_charge_date);
  const split_payment_enabled = useSelector(({ order }) => order.dataForPayment?.split_payment_enabled);
  const split_payment_amount = useSelector(
    ({ order }) => order.dataForPayment?.split_payment_amount || priceToBeCharged,
  );
  const start_date = useSelector(({ order }) => order.data.package_start_date);
  const chargeCurrency = useSelector(({ global }) => global.price?.charge_currency);
  const payment_uuid = useSelector(({ order }) => order.dataForPayment.payment_uuid);
  const gpMerchantId = useSelector(({ order }) => order.dataForPayment.gp_merchant_id);
  const number = useSelector(selectOrderNumber);
  const requestPaymentProcessing = useSelector(({ order }) => order.requestPaymentProcessing);
  const updatingRates = useSelector((state) => state.global.ratesWasExpired.updating);
  const error = useSelector(selectOrderError);
  const productError = useSelector(({ product }) => product.error);
  const productType = useSelector(({ order }) => order.data.build_type);
  const chargeStrategy = useSelector(({ order }) => order.data.charge_strategy);
  const customer = useSelector(({ order }) => order.data.customer);
  const integrationCode = useIntegrationCode();
  const { user } = useGetUser();
  const isAgent = user?.isAgent;
  const orderState = useSelector(({ order }) => order?.data?.state);
  const extraProductPayment = orderState && ACT_LIKE_EXTRA_PAYMENT_ORDER_STATE.includes(orderState);
  const shouldRefetchPrices = useSelector((state) => state.global.refetchPricesDueCurrencyChange);
  const shouldPreselectSplitPayment = useSelector((state) => state.global.shouldPreselectSplitPayment);

  // Analytics
  const product = useSelector((state) => state.product.data);
  const spProduct = useSelector((state) => state.smartPackage.data);
  const tint_charge_price = useSelector((state) => state.global?.price?.tint_charge_price);
  const order = useSelector((state) => state.order.data);

  // In case user missed the customer step or his data were not stored properly
  useCustomerDataValidForPayment(customer);

  const { payment_uuid: searchPaymentUUID, source: searchSource } = QueryString.parse(document.location.search);
  const isRequestPayment = searchSource === "request_payment";
  const paymentsLink = useMemo(
    () => getPaymentsLinks({ orderNumber: number, shouldBackToOriginSource: extraProductPayment }),
    [number],
  );

  const { t, i18n } = useTranslation();
  const { push } = useHistoryNavigation();
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      payment_method: paymentMethods.CREDIT_CARD,
    },
  });
  const paymentMethod = useWatch({ control, name: "payment_method" });

  useSelectedPaymentMethod(paymentMethod);

  const configuredProduct = productType === "smart_package" ? spProduct : product;
  useEffect(() => {
    setTimeout(() => {
      ga_add_payment_info(configuredProduct, order, tint_charge_price, paymentMethod);
    }, 1000);
  }, []);

  const onSuccessPayment = (url = getSuccessPageUrl()) => {
    push(url);
  };

  const checkIfCanGoBack = () => {
    return !searchPaymentUUID && !isRequestPayment;
  };
  const canGoBack = checkIfCanGoBack();

  const onSubmit = handleSubmit((val) => {
    requestPayment({
      ...val,
      ...(val.card_number && { card_number: val.card_number.replace(/-/g, "") }),
      ...(val.card_holder && { cardholder_name: val.card_holder }),
      onSuccess: onSuccessPayment,
    });
  });

  const onUserAgentSubmit = () => {
    createUserAgentPayment({
      onSuccess: onSuccessPayment,
    });
  };

  const onGPay = (val) => {
    requestPayment({
      token: val.paymentMethodData.tokenizationData.token,
      payment_method: "googlepay",
      onSuccess: onSuccessPayment,
      threeds_required: !val.paymentMethodData.info?.assuranceDetails?.cardHolderAuthenticated,
    });
  };

  const onSuccessApplePay = () => {
    getOrder({ orderNumber: number, onSuccess: () => onSuccessPayment() });
  };

  const onErrorApplePay = (err) => {
    setError(err);
    if (number) {
      initPaymentRequest({ number });
    }
  };

  const handleBack = () => {
    if (!canGoBack) return;

    push(getCustomerPathByBuildType(productType));
  };

  const partialPaymentsAllowed = partial_payments_enabled && !isAgent;
  const splitPaymentsAllowed = split_payment_enabled && !isAgent;

  const resetPartialPayment = () => {
    setValue("partial_payment", "full");
    partialPayment({ paymentType: "full" });
  };

  useEffect(() => {
    if (number && !javascript_url) {
      initPaymentRequest({ number });
    }
  }, [number]);

  useEffect(() => {
    if (shouldRefetchPrices) {
      // Manage partial payment flow
      const currentPaymentType = getValues("partial_payment");
      const onSuccess = () => {
        setRefetchPrices(false);
        if (["partial", "split"].includes(currentPaymentType)) {
          resetPartialPayment();
          const type = currentPaymentType;
          setTimeout(() => {
            setValue("partial_payment", type);
            partialPayment({ paymentType: type });
          }, 100);
        }
      };

      setTimeout(() => {
        getOrder({ orderNumber: number, onSuccess: () => initPaymentRequest({ number, onSuccess }) });
      }, 1000);
    }
  }, [shouldRefetchPrices]);

  useEffect(() => {
    if (shouldPreselectSplitPayment) {
      setShouldPreselectSplitPayment(false);
      setValue("partial_payment", "split");
      partialPayment({ paymentType: "split" });
    }
  }, [shouldPreselectSplitPayment]);

  useEffect(() => {
    if (split_payment_enabled && productError?.fail_payment) {
      setTimeout(() => {
        const errorBig = document.getElementById("errorBig");
        errorBig && errorBig.remove();
      }, 500);
      clearError();
      setError({
        status: 400,
        fields: ["split_payment_option_available"],
        messages: ["errors.payment.split_payment_option_available"],
        type: errorMsgTypes.SPLIT_PAYMENT,
        contactUsButton: false,
      });
    }
  }, [split_payment_enabled, productError]);

  useEffect(() => {
    return () => clearDataForPayment();
  }, []);

  useGetCredoraxPaymentPlugin(javascript_url);

  return (
    <FormContainer
      loading={requestPaymentProcessing || updatingRates || creditCardsLoading || shouldRefetchPrices}
      formContainerClassName="mg-md-b-8"
      onSubmit={onSubmit}
      errors={error}
    >
      <PaymentForm
        partial_payments_enabled={partialPaymentsAllowed}
        partial_payment_amount={partial_payment_pay_now}
        second_payment_charge_date={
          second_payment_charge_date || dates.sub(dates.parseDateFromApi(start_date), { days: 30 })
        }
        split_payment_enabled={splitPaymentsAllowed}
        split_payment_amount={Number(split_payment_amount)}
        cards={creditCardDetails}
        control={control}
        onPartialPayment={(paymentType) => partialPayment({ paymentType })}
        integrationCode={integrationCode}
        onSwitchPaymentMethod={resetPartialPayment}
        chargeCurrency={chargeCurrency}
      />

      <div className="buttons-group">
        {!extraProductPayment && <BackButton type="button" className="customer-back" onClick={handleBack} />}

        {paymentMethod === paymentMethods.CREDIT_CARD && (
          <SubmitButton
            className={extraProductPayment ? "payment-submit-btm-fullsize" : "payment-submit-btm"}
            type="submit"
            label={t(i18n.language === "de" ? "continueBooking" : "completeMyBooking")}
            disabled={requestPaymentProcessing}
            loading={requestPaymentProcessing || creditCardsLoading}
          />
        )}

        {paymentMethod === paymentMethods.G_PAY && (
          <GPayButton
            disabled={requestPaymentProcessing || updatingRates}
            onLoadPaymentData={onGPay}
            totalPrice={priceToBeCharged}
            merchantId={gpMerchantId}
            chargeCurrency={chargeCurrency}
          />
        )}
        {paymentMethod === paymentMethods.APPLE_PAY && (
          <ApplePayBtn
            payment_uuid={searchPaymentUUID || payment_uuid}
            onSuccessPayment={onSuccessApplePay}
            onErrorPayment={onErrorApplePay}
            totalPrice={priceToBeCharged}
            paymentLabel={brandName}
            order={number}
            paymentsLink={paymentsLink}
            chargeCurrency={chargeCurrency}
          />
        )}
      </div>

      {isAgent && chargeStrategy === "full_agent_credit" && (
        <div className="buttons-group buttons-group__justify-end">
          <Button
            className={"btn__submit user-agent-submit-btn"}
            type="button"
            onClick={onUserAgentSubmit}
            disabled={requestPaymentProcessing}
            processing={requestPaymentProcessing}
          >
            {"Complete booking using agent credit"}
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

export default Payment;
