import { orderAvailableStates } from "../constants";

export const checkIsPaidOrCanceled = (orderStatus) => {
  const paidStatus = [
    orderAvailableStates.PASSPORT,
    orderAvailableStates.CONFIRMED,
    orderAvailableStates.COMPLETED,
    orderAvailableStates.CANCELED,
  ];

  return paidStatus.includes(orderStatus);
};
