import React, { useState } from "react";
import T from "prop-types";

import { parseClasses, toUpFirstLater, checkIsIOS } from "@tint_fe/helpers";
import { Close } from "../../assets/icons";
import InputWrapper from "./InputWrapper";
import { useTranslation, useAfterUpdate } from "@tint_fe/hooks";

const Input = React.forwardRef(
  (
    {
      label,
      type,
      error,
      value,
      small,
      remark,
      outline,
      onBlur,
      onFocus,
      onReset,
      onChange,
      disabled,
      resetIcon,
      prevIcon,
      nextIcon,
      cardInput,
      className,
      afterUpdate,
      upFirstLater,
      showValidateMark,
      classNameWrapper,
      scrollIntoViewOnFocus,
      scrollIntoViewOnError,
      withoutOverflowTooltip,
      removeStartEndSpaces,
      useT,
      ...rest
    },
    outRef,
  ) => {
    const runAfterUpdate = useAfterUpdate();
    const { isRtl } = useTranslation();
    const [focus, setFocus] = useState(false);

    const { mainCls } = parseClasses({
      base: "input",
      additional: className,
    });

    const normalizedValue = upFirstLater ? toUpFirstLater(value) : value;

    const scrollIntoView = () => {
      setTimeout(() => {
        if (outRef.current && outRef.current.scrollIntoView) {
          outRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, 300);
    };

    return (
      <InputWrapper
        type="text"
        small={small}
        error={error}
        label={label}
        focus={focus}
        outline={outline}
        withValue={!!value}
        disabled={disabled}
        nextIcon={nextIcon}
        cardInput={cardInput}
        prevIcon={prevIcon}
        indent={!!nextIcon || onReset}
        showValidateMark={showValidateMark}
        classNameWrapper={classNameWrapper}
        scrollIntoViewOnError={scrollIntoViewOnError}
        withoutOverflowTooltip={withoutOverflowTooltip}
        remark={remark}
        useT={useT}
      >
        <input
          dir={isRtl ? "rtl" : "auto"}
          className={mainCls}
          style={{
            paddingLeft: prevIcon && 36,
            paddingRight: (onReset || nextIcon) && 36,
          }}
          onBlur={(e) => {
            setFocus(false);
            if (onBlur) {
              onBlur(e);
              removeStartEndSpaces && onChange({ ...e, ...{ target: { ...e.target, value: e.target.value.trim() } } });
            }
          }}
          onFocus={() => {
            setFocus(true);
            scrollIntoViewOnFocus && !checkIsIOS() && scrollIntoView();
            onFocus && onFocus();
          }}
          {...rest}
          {...(onChange && {
            onChange: (e) => {
              const prevEvtParams = { cursor: e.target.selectionStart, value: e.target.value };

              afterUpdate && runAfterUpdate(() => afterUpdate(e, prevEvtParams));
              onChange(e);
            },
          })}
          value={normalizedValue}
          ref={(node) => {
            if (outRef) {
              outRef.current = node;
            }
          }}
          type={type}
          disabled={disabled}
        />
        {onReset && (
          <button type="button" className="input-icon next-icon-reset" onClick={onReset}>
            {resetIcon ? resetIcon : <Close />}
          </button>
        )}
      </InputWrapper>
    );
  },
);

Input.defaultProps = {
  useT: true,
};

Input.propTypes = {
  className: T.string,
  prevIcon: T.node,
  nextIcon: T.node,
  cardInput: T.bool,
  resetIcon: T.node,
  onReset: T.func,
  classNameWrapper: T.string,
  label: T.string,
  type: T.oneOf(["phone", "textarea", "number", "tel", "password", "text"]),
  upFirstLater: T.bool,
  onChange: T.func,
  afterUpdate: T.func,
  showValidateMark: T.bool,
  onFocus: T.func,
  disabled: T.bool,
  small: T.bool,
  scrollIntoViewOnError: T.bool,
  withoutOverflowTooltip: T.bool,
  remark: T.string,
  scrollIntoViewOnFocus: T.bool,
  outline: T.bool,
  onBlur: T.func,
  error: T.object,
  value: T.string,
  removeStartEndSpaces: T.bool,
  useT: T.bool,
};

export default Input;
