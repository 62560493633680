export const GET_HOTEL_AVAILABILITY_REQUEST = "GET_HOTEL_AVAILABILITY_REQUEST";
export const GET_HOTEL_AVAILABILITY_SUCCESS = "GET_HOTEL_AVAILABILITY_SUCCESS";
export const GET_HOTEL_AVAILABILITY_FAILED = "GET_HOTEL_AVAILABILITY_FAILED";

export const UPDATE_HOTEL_PRODUCT_ITEM_REQUEST = "UPDATE_HOTEL_PRODUCT_ITEM_REQUEST";
export const UPDATE_HOTEL_PRODUCT_ITEM_SUCCESS = "UPDATE_HOTEL_PRODUCT_ITEM_SUCCESS";
export const UPDATE_HOTEL_PRODUCT_ITEM_FAILED = "UPDATE_HOTEL_PRODUCT_ITEM_FAILED";

export const SET_HOTEL_ERROR_ACTION = "SET_HOTEL_ERROR_ACTION";
export const CLEAR_HOTEL_AVAILABILITY_ACTION = "CLEAR_HOTEL_AVAILABILITY_ACTION";
