import { useSelector } from "react-redux";
import { flow, isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

import { dates, getTotalPaxInRooms } from "@tint_fe/helpers";
import { useRouterMatch } from "@tint_fe/hooks";

import { CHECKOUT } from "../../../router/routes";
import { checkCancellations } from "../../../../helpers/hotels";
import { bookingTypes } from "../../../../helpers/constants";
import useSelectedRooms from "./useSelectedRooms";
import useWatchHotelCheckoutForm from "./useWatchHotelCheckoutForm";
import i18n from "../../../../i18n";

const sortByCancellationDeadline = (array) => {
  return array.sort((a, b) => Date.parse(a.deadline) - Date.parse(b.deadline));
};

const getCancellations = (rooms) => {
  if (rooms.length) {
    const sortedRooms = sortByCancellationDeadline(rooms);

    if (checkCancellations(rooms)) {
      return { selectedRooms: sortedRooms, cancellation: sortedRooms[0].cancellation };
    }

    return {
      selectedRooms: sortedRooms,
      cancellation: i18n.t("summary.cancellation.nonRefundable"),
    };
  }

  return { selectedRooms: rooms };
};

const getNumberOfPeople = (rooms) => {
  const { adults, kids } = getTotalPaxInRooms(rooms);

  return { number: adults + kids, name: "numberOfPeople" };
};

const formatGuests = (room) => {
  const { adults, children } = room;
  const adultString = `${adults} adult${adults > 1 ? "s" : ""}`;
  let childrenAges = "";

  if (children.length === 1) {
    childrenAges = `1 child aged ${children[0]}`;
  } else if (children.length > 1) {
    const uniqueAges = [...new Set(children)];

    const childrenString = `${children.length} children`;

    childrenAges = `${childrenString} aged ${uniqueAges.join(", ").replace(",", " and")}`;
  }

  return `${adultString}${childrenAges ? ", " : ""}${childrenAges}`;
};

const groupByHotelName = (rooms, roomsDescription = []) => {
  if (!rooms?.length) return [];
  let generalIndex = 0;
  const res = [];
  rooms.forEach(({ room_name, quantity }) => {
    for (let i = 0; i < quantity; i++) {
      res.push({
        name: `Room ${generalIndex + 1} - ${room_name}`,
        number: 1,
        hideNumber: true,
        ...(roomsDescription?.[generalIndex] && { subtext: formatGuests(roomsDescription[generalIndex]) }),
      });
      generalIndex++;
    }
  });

  return res;
};

const getHotelSummaryInfoFromFormData = (formData) => {
  const startLocation = { date: dates.cpDateTransformer(formData.start_date) };
  const endLocation = { date: dates.cpDateTransformer(formData.end_date) };
  const roomsDescription = formData?.rooms || [];

  return flow(
    ({ selectedRooms }) => getCancellations(selectedRooms),
    ({ selectedRooms, ...rest }) => ({ lineItems: groupByHotelName(selectedRooms, roomsDescription), ...rest }),
    ({ lineItems, ...rest }) => ({
      lineItems: [getNumberOfPeople(formData.rooms), ...lineItems],
      ...rest,
    }),
    (data) => ({ ...data, startLocation, endLocation }),
  )(formData);
};

const getHotelSummaryInfoFromOrder = (order) => {
  const hotel = order.product_items?.[0] || {};
  const roomsDescription = order?.rooms_description || [];
  const startLocation = { date: dates.cpDateTransformer(hotel.start_at) };
  const endLocation = { date: dates.cpDateTransformer(hotel.end_at) };

  return flow(
    ({ hotel_line_items, ...rest }) => ({
      ...getCancellations(hotel_line_items),
      ...rest,
    }),
    ({ selectedRooms, ...rest }) => ({ lineItems: groupByHotelName(selectedRooms, roomsDescription), ...rest }),
    ({ lineItems, ...rest }) => ({
      lineItems: [{ number: hotel.pax_quantity, name: "numberOfPeople" }, ...lineItems],
      ...rest,
    }),
    (data) => ({ ...data, startLocation, endLocation }),
  )(hotel);
};

const getHotelSummaryInfo = (formData, order) => {
  if (!isEmpty(order)) {
    return getHotelSummaryInfoFromOrder(order);
  }

  if (formData?.start_date) {
    return getHotelSummaryInfoFromFormData(formData);
  }

  return {};
};

const getCheckoutTotalPrice = (selectedRooms) => {
  if (selectedRooms?.length) {
    return selectedRooms.reduce((acc, el) => acc + el.price * el.quantity, 0);
  }

  return null;
};

const calculateTotalTaxes = (selectedRooms, hli, pi) => {
  if (!isEmpty(pi)) {
    return {
      included_taxes: Math.round(pi.included_taxes) > 0 ? Math.round(pi.included_taxes) : null,
      excluded_taxes: Math.round(pi.excluded_taxes) > 0 ? Math.round(pi.excluded_taxes) : null,
    };
  }

  let includedTaxes = 0;
  let excludedTaxes = 0;

  const li = selectedRooms?.length ? selectedRooms : hli;

  for (const item of li) {
    includedTaxes += parseFloat(item.included_taxes) * item.quantity;
    excludedTaxes += parseFloat(item.excluded_taxes) * item.quantity;
  }

  return {
    included_taxes: Math.round(includedTaxes) > 0 ? Math.round(includedTaxes) : null,
    excluded_taxes: Math.round(excludedTaxes) > 0 ? Math.round(excludedTaxes) : null,
  };
};

const useGetHotelSummaryInfo = () => {
  const selectedRooms = useSelectedRooms();
  const formData = useWatchHotelCheckoutForm();
  const { pathname } = useLocation();
  const preventUseOrder = pathname.includes(CHECKOUT);
  const order = useSelector((state) => (preventUseOrder ? {} : state.order.data));
  const globalPrices = useSelector(({ global }) => global.price);
  const bookingType = useSelector(({ global }) => global.bookingType);
  const roundedTaxedComponentsPrice = useSelector((state) => state.global?.price?.rounded_taxed_components_price);
  const discountAmount = useSelector((state) => state.global.price?.discount_amount);
  const isCheckoutPage = useRouterMatch(CHECKOUT);
  const product = useSelector((state) => state.product.data);

  // Taxes
  const hotelLineItems = order.product_items?.[0]?.hotel_line_items || [];
  const productItemFromFetchPrices = globalPrices?.product_items?.[0] || [];
  const taxes = calculateTotalTaxes(selectedRooms, hotelLineItems, productItemFromFetchPrices);

  // Policies
  const { descriptions = {} } = product;

  if (bookingType !== bookingTypes.HOTEL) {
    return {
      lineItems: [],
    };
  }

  const checkoutTotalPrice = getCheckoutTotalPrice(selectedRooms);

  const getPrice = () => {
    if (typeof checkoutTotalPrice === "number" && !discountAmount) return checkoutTotalPrice;

    return !isCheckoutPage ? roundedTaxedComponentsPrice : null;
  };

  return {
    roundedTotalPrice: getPrice(),
    ...getHotelSummaryInfo({ ...formData, selectedRooms }, order),
    taxes,
    descriptions,
  };
};

export default useGetHotelSummaryInfo;
