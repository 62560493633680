import * as React from "react";

function SvgCalendar(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12.0002" cy="12" r="10.05" stroke="#D81F1F" strokeWidth="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75729 7.75736C7.4644 8.05025 7.4644 8.52513 7.75729 8.81802L10.9393 12L7.75729 15.182C7.4644 15.4749 7.4644 15.9497 7.75729 16.2426C8.05018 16.5355 8.52506 16.5355 8.81795 16.2426L11.9999 13.0607L15.1819 16.2426C15.4748 16.5355 15.9497 16.5355 16.2426 16.2426C16.5355 15.9497 16.5355 15.4749 16.2426 15.182L13.0606 12L16.2426 8.81802C16.5355 8.52513 16.5355 8.05025 16.2426 7.75736C15.9497 7.46447 15.4748 7.46447 15.1819 7.75736L11.9999 10.9393L8.81795 7.75736C8.52506 7.46447 8.05018 7.46447 7.75729 7.75736Z"
        fill="#D81F1F"
      />
    </svg>
  );
}

export default SvgCalendar;
