import { dates } from "../utils";

export const serializeProductItemsAttributes = (product, formData, settings) => {
  const { preventPriceBands } = settings || {};
  const { custom_pickup_point_strategy, custom_dropoff_point_strategy } = product.settings;

  const {
    start_date,
    language_code,
    city_id,
    pickup_point_id,
    custom_pickup_point,
    custom_dropoff_point,
    dropoff_point_id,
    start_time,
    start_from = "",
    dropoff_city_id,
    flight_number,
    departure_flight_number,
  } = formData.reservationDetails;

  const productItem = {
    product_id: product.product_id || product.id,
    ...(product.product_id && formData.extrasInfo && { id: product.id }),
    start_date: dates.format(start_date, "yyyy-MM-dd", false),
    language_code: language_code,
    city_id: city_id,
    start_time: start_time,
    dropoff_city_id,
    flight_number,
    departure_flight_number,
    start_from,
  };

  if (custom_pickup_point_strategy && custom_pickup_point) {
    productItem.custom_pickup_lat = custom_pickup_point.lat;
    productItem.custom_pickup_lon = custom_pickup_point.lon;
    productItem.custom_pickup_point = custom_pickup_point.name;
    delete productItem.start_from;
  } else {
    productItem.start_from = start_from;
    productItem.pickup_point_id = pickup_point_id;
  }

  if (custom_dropoff_point_strategy && custom_dropoff_point) {
    productItem.dropoff_location_lat = custom_dropoff_point.lat;
    productItem.dropoff_location_lon = custom_dropoff_point.lon;
    productItem.dropoff_location_name = custom_dropoff_point.name;
  } else {
    productItem.dropoff_point_id = dropoff_point_id;
  }

  const newPriceBandLineItems = {};

  if (formData.whoTraveling) {
    Object.entries(formData.whoTraveling).forEach(([id, quantity], index) => {
      newPriceBandLineItems[index] = {
        product_option_id: Number.parseInt(id, 10),
        quantity: preventPriceBands ? 0 : quantity,
      };
    });
  }

  productItem.price_band_line_items_attributes = newPriceBandLineItems;

  if (formData.extrasInfo) {
    productItem.extra_line_items_attributes = formData.extrasInfo;
  }

  return productItem;
};
