import React from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { transformArrayOfObjectsToOptions, validationRule } from "../../../../../utils";
import SelectField from "../../../../components/Form/SelectField";
import HiddenField from "../../../../components/Form/HiddenField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { selectProductSettings } from "../../../../../redux/product/productSelectors";
import { useTranslation } from "../../../../../hooks";
import useUpdatePriceBands from "../../hooks/useUpdatePriceBands";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";
import useBackToCheckoutStep from "../../hooks/useBackToCheckoutStep";
import usePreselectDefaultLocation from "../../hooks/usePreselectDefaultLocation";

const StartFrom = () => {
  const updatePriceBands = useUpdatePriceBands();
  const updateTourPrice = useUpdateTourPrice();
  const backToCheckoutStep = useBackToCheckoutStep();
  const preselectDefaultLocation = usePreselectDefaultLocation("custom_pickup_point");
  const preselectDropoffDefaultLocation = usePreselectDefaultLocation("custom_dropoff_point");

  const availableCities = useSelector(({ product }) => product.availability.cities) || [];
  const { custom_pickup_point_strategy, different_end_location } = useSelector(selectProductSettings);
  const product = useSelector((state) => state.product);
  const loading = product?.priceBandsFetching || product?.availabilityFetching;

  const {
    control,
    formState: { isSubmitted },
    watch,
    setValue,
    getValues,
  } = useFormContext();
  const startDateValue = watch("start_date");

  const { t } = useTranslation();

  // StartFrom - custom_pickup_point_strategy => if cities is 1 - preselect this field
  // StartFrom - !custom_pickup_point_strategy => if cities is 1 - hide this field
  const isCitiesSelect = custom_pickup_point_strategy ? availableCities.length > 0 : availableCities.length > 1;

  const cityOptions = transformArrayOfObjectsToOptions(availableCities);
  const label = "formField.startFrom";

  const handleChange = (val) => {
    setValue("start_time", "");
    setValue("dropoff_point_id", "");

    if (different_end_location) {
      setValue("dropoff_city_id", null);
    }

    updateTourPrice({ formData: val.city_id }, { preventPriceBands: true });

    updatePriceBands({
      ...val,
      onSuccess: (availableDropOffPoints, availablePickupPoints) => {
        const availableDefaultAddress = preselectDefaultLocation(val.city_id, availablePickupPoints);
        const endInDiffLocation = getValues("return_different_location");

        if (custom_pickup_point_strategy && !endInDiffLocation && !different_end_location) {
          preselectDropoffDefaultLocation(val.city_id, availableDropOffPoints, availableDefaultAddress);
          setValue("dropoff_city_id", val.city_id);
        }
      },
    });

    backToCheckoutStep();

    return val;
  };

  return (
    <ControllerInput
      control={control}
      name="city_id"
      render={({ field: { onChange, ...restField }, fieldState: { error } }) => {
        return isCitiesSelect ? (
          <SelectField
            label={t(label)}
            placeholder={t("formField.selectPoint")}
            disabled={!startDateValue}
            isPreselected={cityOptions.length === 1}
            options={cityOptions}
            errors={error?.message}
            isSearchable={false}
            isSubmitting={isSubmitted}
            noOptionsMessage={t("formField.startFrom.noOptionsMessage")}
            onChange={(val) => onChange(handleChange(val))}
            loading={loading}
            {...restField}
          />
        ) : (
          // Hidden input with city has been added for correctly validation
          <HiddenField {...restField} />
        );
      }}
      rules={{
        ...validationRule.required(label),
      }}
    />
  );
};

export default StartFrom;
