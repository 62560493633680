import { MAX_TOTAL_PAX, MAX_TOTAL_ROOMS, MAX_TOTAL_ROOMS_HOTEL } from "../constants";

export const getTotalPaxInRooms = (rooms, isHotel = false) => {
  if (rooms?.length === 0) {
    return { adults: 0, kids: 0, paxOverflow: false };
  }
  const MAX_ROOMS_ALLOWED = isHotel ? MAX_TOTAL_ROOMS_HOTEL : MAX_TOTAL_ROOMS;

  const adults = rooms.reduce((acc, el) => acc + Number(el.adults), 0);
  const kids = rooms.reduce((acc, el) => acc + el.children?.length || 0, 0);
  const paxOverflow = adults + kids >= MAX_TOTAL_PAX;
  const overflowRooms = rooms.length >= MAX_ROOMS_ALLOWED;

  return { adults, kids, paxOverflow, overflowRooms, maxRooms: MAX_ROOMS_ALLOWED };
};
