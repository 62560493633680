import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { FavoritesIcon } from "../../assets/icons";

const FavoriteBtn = ({ onChange, checked, style, className }) => {
  const { mainCls, elCls } = parseClasses({
    base: "favorite-btn",
    classes: [checked && "checked"],
    additional: [className],
  });

  const handleChange = () => {
    onChange(checked);
  };

  return (
    <label style={style} className={mainCls}>
      <input onChange={handleChange} checked={checked} type="checkbox" className={elCls("input")} />
      <span className={elCls("icon")}>
        <FavoritesIcon />
      </span>
    </label>
  );
};

FavoriteBtn.propTypes = {
  onChange: T.func.isRequired,
  checked: T.bool,
  style: T.object,
  className: T.string,
};

export default FavoriteBtn;
