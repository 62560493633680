import * as React from "react";
const Cross16 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#21AFE4"
      fillRule="evenodd"
      d="m9.414 8 4.293-4.293-1.414-1.414L8 6.586 3.708 2.293 2.293 3.707 6.586 8l-4.293 4.293 1.414 1.414L8 9.414l4.293 4.293 1.415-1.414L9.414 8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Cross16;
