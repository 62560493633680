import * as React from "react";

function SvgChanged(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <circle cx={24} cy={24} r={21} stroke="#219653" strokeWidth={2} />
      <path
        d="M14 13l10.5-1-1 9.5-3-2.5c-5 6-2.5 10-2.5 10-6-4-3.167-9.833-.5-13L14 13zM34.5 35L24 36l1-9.5 3 2.5c5-6 2.5-10 2.5-10 6 4 3.167 9.833.5 13l3.5 3z"
        stroke="#219653"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgChanged;
