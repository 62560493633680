import React, { useMemo } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";

import { TintPros as SharedTintPros } from "@tint_fe/ui";
import { useIntegrationCode } from "@tint_fe/redux-utils/hooks";
import { bookingTypes } from "../../helpers/constants";
import { selectTermsAndConditionLink } from "../../redux/sharedSelectors";

const TintProsBooking = ({ isFreeCancellation, className }) => {
  const integrationName = useSelector((state) => state.global.integration.name);
  const integrationCode = useIntegrationCode();
  const bookingType = useSelector((state) => state.global.bookingType);
  const skip_accomodation = useSelector((state) => state.smartPackage.data.skip_accommodation);
  const termsAndConditionsLink = useSelector(selectTermsAndConditionLink);
  const product = useSelector((state) => state.product.data);

  const list = useMemo(() => {
    const isHotel = bookingTypes.HOTEL === bookingType;
    const showChoseHotel = bookingType === bookingTypes.SMART_PACKAGE && !skip_accomodation;
    const refundMessage = product?.refund_message;
    const showRefundActivity = refundMessage !== "Non-refundable";
    const showPartialPayment = bookingType === bookingTypes.SMART_PACKAGE;

    const getCancellationInfo = () => {
      switch (bookingType) {
        case bookingTypes.HOTEL:
          return null;
        case bookingTypes.ACTIVITY:
          return showRefundActivity ? { id: uuidV4(), text: `${refundMessage}`, ignoreI18: true } : null;
        default:
          return {
            id: uuidV4(),
            text: "tintPros.easyCancellation",
            cancellationLink: termsAndConditionsLink || "https://www.withtint.com/terms_and_conditions/10",
            ...(isFreeCancellation && {
              additionalText:
                "We will ask for your credit card details as a security measure, but no charges will be made until 7 days before the end of the free cancellation period.",
            }),
          };
      }
    };

    const cancellationInfo = getCancellationInfo();

    return [
      showPartialPayment && {
        id: uuidV4(),
        text: "tintPros.partialPayment",
      },
      showChoseHotel && {
        id: uuidV4(),
        text: "tintPros.chooseHotel",
      },
      isHotel && { id: uuidV4(), text: "tintPros.handPicked" },
      isHotel && { id: uuidV4(), text: "tintPros.exclusiveRates" },
      { id: uuidV4(), text: "tintPros.noHiddenFees" },
      !isHotel && !showPartialPayment && { id: uuidV4(), text: "tintPros.bestPrice" },
      {
        id: uuidV4(),
        text: "tintPros.instantConfirmation",
        ...(bookingType === bookingTypes.HOTEL &&
          isFreeCancellation && {
            additionalText:
              "We will ask for your credit card details as a security measure, but no charges will be made until 7 days before the end of the free cancellation period.",
          }),
      },
      !!cancellationInfo && { ...cancellationInfo },
    ].filter(Boolean);
  }, [bookingType, integrationName, termsAndConditionsLink, isFreeCancellation]);

  return <SharedTintPros list={list} integrationCode={integrationCode} className={className} />;
};

TintProsBooking.propTypes = {
  isFreeCancellation: T.bool,
  className: T.string,
};

export default TintProsBooking;
