import * as sharedTypes from "@tint_fe/redux-utils/src/global/globalActionTypes";

import * as types from "./hotelActionTypes";
import * as orderTypes from "../order/orderActionTypes";

export const INIT_STATE = {
  hotelRooms: [],
  booking_code_expire_at: null,
  charge_currency: null,
  fetchingGetRooms: false,
  error: {},
};

const hotel = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_HOTEL_AVAILABILITY_REQUEST:
      return {
        ...state,
        fetchingGetRooms: true,
        error: {},
      };

    case orderTypes.CREATE_HOTEL_ORDER_REQUEST:
      return {
        ...state,
        error: {},
      };

    case orderTypes.UPDATE_ORDER_PRODUCT_ITEM_REQUEST:
      return {
        ...state,
        error: {},
      };

    case types.GET_HOTEL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        hotelRooms: action.payload?.hotelRooms,
        charge_currency: action.payload?.charge_currency,
        booking_code_expire_at: Date.now() + 1200000,
        fetchingGetRooms: false,
        error: {},
      };

    case orderTypes.CREATE_HOTEL_ORDER_SUCCESS:
    case orderTypes.UPDATE_ORDER_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        fetchingGetRooms: false,
        error: {},
      };

    case types.GET_HOTEL_AVAILABILITY_FAILED:
    case types.UPDATE_HOTEL_PRODUCT_ITEM_FAILED:
      return {
        ...state,
        fetchingGetRooms: false,
        error: action.payload,
      };

    case types.SET_HOTEL_ERROR_ACTION:
      return {
        ...state,
        error: action.payload,
      };

    case types.CLEAR_HOTEL_AVAILABILITY_ACTION:
      return {
        ...state,
        hotelRooms: [],
        fetchingGetRooms: false,
        booking_code_expire_at: null,
        error: {},
      };
    case sharedTypes.CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };

    default:
      return state;
  }
};

export default hotel;
