import { useSelector } from "react-redux";

import { useReduxAction } from "@tint_fe/redux-utils/hooks";
import { useShowApiMessage as useSharedShowApiMessage } from "@tint_fe/hooks";

import { selectError } from "../redux/sharedSelectors";
import {
  toggleContactUsModalAction,
  clearErrorAction,
  setShouldPreselectSplitPaymentAction,
} from "../redux/global/globalActions";
import { bookingTypes, errorMsgTypes, steps } from "../helpers/constants";
import { getHotelSessionStorage, getSmartPackageSessionStorage } from "../storage/sessionStorage";

const useShowApiMessage = () => {
  const toggleContactUsModal = useReduxAction(toggleContactUsModalAction);
  const clearError = useReduxAction(clearErrorAction);
  const error = useSelector(selectError);
  const bookingType = useSelector(({ global }) => global.bookingType);
  const { data: spData } = useSelector(({ smartPackage }) => smartPackage);
  const integration = useSelector(({ global }) => global.integration);
  const isErrorStep = useSelector(({ global }) => global.step === steps.ERROR);
  const buildType = useSelector(({ global }) => global.bookingType);
  const chargeCurrency = useSelector(({ global }) => global?.price?.charge_currency ?? "USD");
  const splitPaymentAmount = useSelector(({ order }) => order?.dataForPayment?.split_payment_amount ?? 0);
  const orderNumber = useSelector(({ global }) => global?.price?.number);

  const setShouldPreselectSplitPayment = useReduxAction(setShouldPreselectSplitPaymentAction);
  const isSplitPaymentError = error?.type === errorMsgTypes.SPLIT_PAYMENT;

  const handleBtnClick = () => {
    if (isSplitPaymentError) {
      setShouldPreselectSplitPayment(true);
    } else {
      toggleContactUsModal(true);
    }
  };

  // SP data for widget_issue
  const spSessionStorageData = getSmartPackageSessionStorage();
  const package_start_location_name =
    bookingType === bookingTypes.SMART_PACKAGE
      ? spData?.start_locations &&
        spData?.start_locations.find((el) => el.id === spSessionStorageData?.package_start_location_id)?.name
      : "";
  const package_end_location_name =
    bookingType === bookingTypes.SMART_PACKAGE
      ? spData?.end_locations &&
        spData?.end_locations.find((el) => el.id === spSessionStorageData?.package_end_location_id)?.name
      : "";

  // Hotel data for widget_issue
  const hotelSessionStorageData = bookingTypes.HOTEL && getHotelSessionStorage();
  const hotelProduct = useSelector((state) => state.product?.data);

  useSharedShowApiMessage({
    ...(!isErrorStep && { error }),
    options: { onClose: clearError },
    props: {
      handleClickOnBtn: () => handleBtnClick(),
      brandName: integration?.brand_name,
      buildType,
      refErrorCode: error?.messages[0],
      ...(bookingType === bookingTypes.SMART_PACKAGE && {
        productName: spData?.display_name,
        priceCustomerLastSeen: spData?.prices?.total,
        productConfiguration: { ...spSessionStorageData, package_start_location_name, package_end_location_name },
      }),
      ...(bookingType === bookingTypes.HOTEL && {
        productName: hotelProduct?.display_name,
        productConfiguration: hotelSessionStorageData,
      }),
      ...(chargeCurrency && { chargeCurrency }),
      ...(splitPaymentAmount && { splitPaymentAmount }),
      ...(orderNumber && { orderNumber }),
    },
  });
};

export default useShowApiMessage;
