import * as React from "react";
const ConfirmationTour = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#151209"
        fillRule="evenodd"
        d="M20.25 0a3.873 3.873 0 0 1 2.65 1.037A3.44 3.44 0 0 1 24 3.536c0 3.025-3.41 5.31-3.554 5.406a.357.357 0 0 1-.392 0C19.91 8.846 16.5 6.56 16.5 3.536a3.44 3.44 0 0 1 1.1-2.5A3.873 3.873 0 0 1 20.25 0ZM9.387 11.613A5.506 5.506 0 0 0 5.5 10 5.506 5.506 0 0 0 0 15.5c0 4.706 5 8.26 5.213 8.41a.501.501 0 0 0 .574 0C6 23.76 11 20.206 11 15.5a5.506 5.506 0 0 0-1.613-3.887ZM5.5 17a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm11-9a1 1 0 1 1 0 2H16a2 2 0 1 0 0 4h.5a4.5 4.5 0 0 1 4.5 4.5v.5a5 5 0 0 1-5 5h-5a1 1 0 1 1 0-2h5a3 3 0 0 0 3-3v-.5a2.5 2.5 0 0 0-2.5-2.5H16a4 4 0 0 1 0-8h.5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ConfirmationTour;
