import { useSelector } from "react-redux";

import { selectProductSettings } from "../../../../redux/product/productSelectors";
import { dates } from "../../../../utils";
import useUpdatePriceBands from "./useUpdatePriceBands";

const useHandleChangeTourAvailability = ({ setValue, getValues }) => {
  const { custom_pickup_point_strategy } = useSelector(selectProductSettings);
  const updatePriceBands = useUpdatePriceBands({ setValue, getValues });

  const updateCityId = (city_id) => {
    custom_pickup_point_strategy && setValue("dropoff_city_id", city_id);
    setValue("city_id", city_id);
    updatePriceBands({ city_id });
  };

  const handleChangeTourAvailability = ({ pickUpPoints, dropOffPoints, cities }, start_date, isInitialFetch) => {
    const point = pickUpPoints.length === 1 ? pickUpPoints[0] : null;
    const rawTimes = point?.raw_time_points || [];

    if (getValues && !getValues("start_date") && start_date) {
      setValue("start_date", dates.parse(start_date, "yyyy-MM-dd"));
    }

    // TODO: need to investigate if this code is relay useful
    //  we have similar logic in useUpdatePriceBands hooks
    if (!custom_pickup_point_strategy && point && cities.length === 1) {
      setValue("pickup_point_id", point.id);

      const return_different_location = getValues("return_different_location");
      const dropPoint = dropOffPoints.find((x) => x.name === point.name) || point;
      !return_different_location && setValue("dropoff_point_id", dropPoint?.id || null);

      if (rawTimes.length === 1) {
        setValue("start_time", (rawTimes[0] || "").replace(/:/g, " : "));
      }
    }

    if (cities.length === 1 && !isInitialFetch) {
      updateCityId(cities[0].id);
    }

    isInitialFetch && updatePriceBands({ isInitialFetch });
  };

  return handleChangeTourAvailability;
};

export default useHandleChangeTourAvailability;
