import React, { useState, useEffect } from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { SubmitButton } from "../";
import TermsAndConditions from "./TermsAndConditions";

const RequestPayment = ({ onSubmit, isError, termsAndConditionLink }) => {
  const { t } = useTranslation();
  const { control, handleSubmit: handleFormSubmit } = useForm({});

  const [isFailed, setIsFailed] = useState();

  const handleSetFailed = (e) => {
    if (!(e.target.id === "submit" || e.target.parentElement.id === "submit") && isFailed) {
      setIsFailed(false);
    }
  };

  const handleSubmit = handleFormSubmit(
    () => {
      onSubmit();
    },
    (error) => console.error(error),
  );

  useEffect(() => {
    if (isError) {
      setIsFailed(isError);
    }
  }, [isError]);

  return (
    <div onClick={handleSetFailed} className="request-payment">
      <TermsAndConditions
        name="accept_terms"
        control={control}
        rules={{ required: "error.policy" }}
        termsAndConditionLink={termsAndConditionLink}
      />
      <SubmitButton
        id="submit"
        disabled={isFailed}
        label={t("proceedToPayment")}
        loading={false}
        onClick={handleSubmit}
        className="mg-t-6"
      />
    </div>
  );
};

export default RequestPayment;

RequestPayment.propTypes = {
  termsAndConditionLink: T.string,
  onSubmit: T.func,
  isError: T.bool,
};

RequestPayment.defaultProps = {
  termsAndConditionLink: "",
  isError: false,
};
