import React, { useState } from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";

import FormHeader from "../../../components/Form/FormHeader";
import HotelRoomItem from "./HotelRoomItem";
import { Arrow } from "../../../../assets/icons";
import HotelRoomDetilsModal from "./HotelRoomDetailsModal";
import { isEmpty } from "lodash";
import { parseClasses } from "@tint_fe/helpers";

const getBedAndSizeInfo = (htmlString) => {
  var tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  const bedType = tempElement?.childNodes[0]?.textContent ?? "";
  const roomSize = tempElement?.childNodes[1]?.textContent ?? "";

  const restAmenities = tempElement?.childNodes?.length > 2 ? Array.from(tempElement?.childNodes).slice(2) : [];

  return {
    bedType,
    roomSize,
    restAmenities,
  };
};

const HotelRoomRatesForm = ({ hotelRooms, loading }) => {
  const { t } = useTranslation();
  const [roomDetailsModal, setRoomDetailsModal] = useState(null);
  const { elCls } = parseClasses({ base: "room-item" });

  const handleMoreRoomDetails = (room, bedType, roomSize, restAmenities) => {
    setRoomDetailsModal({ ...room, bedType, roomSize, restAmenities });
  };

  const closeRoomDetailsModal = () => {
    setRoomDetailsModal(null);
  };

  return (
    <>
      <FormHeader text={t("hotelDetails")} />
      <div className={elCls("list")}>
        {hotelRooms?.length ? (
          hotelRooms.map((room) => {
            const roomInfo = room?.room_info;
            const hasMoreDescription = roomInfo?.description?.length || roomInfo?.url_room?.length;
            const { bedType, roomSize, restAmenities } = getBedAndSizeInfo(roomInfo?.description);

            return (
              <div key={`room-${room.name}`} className="room-item">
                <p className={elCls("room-name")}>{room.name}</p>

                {hasMoreDescription && (
                  <button
                    onClick={() => handleMoreRoomDetails(room, bedType, roomSize, restAmenities)}
                    className="room-item__more-details"
                  >
                    <p>More Details</p>
                    <Arrow direction={"right"} width={12} height={12} />
                  </button>
                )}

                <div>
                  {!!room.rates?.length &&
                    room.rates?.map((rate) => {
                      return <HotelRoomItem key={`rate-${rate.id}`} loading={loading} {...rate} />;
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <div>{t("noRoomsFound")}</div>
        )}
      </div>
      <HotelRoomDetilsModal
        isOpen={!isEmpty(roomDetailsModal)}
        closeModal={closeRoomDetailsModal}
        room={roomDetailsModal}
      />
    </>
  );
};

HotelRoomRatesForm.propTypes = {
  hotelRooms: T.array.isRequired,
  loading: T.bool.isRequired,
};

export default HotelRoomRatesForm;
