import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import Rating from "./Rating";
import Comment from "./Comment";

const FeedbackForm = ({ control, customerName, productName, className, feedback }) => {
  const { mainCls, elCls } = parseClasses({ base: "feedback-form", additional: className });
  const { t } = useTranslation();

  return (
    <div className={mainCls}>
      <h1 className="h3 mg-b-6 mg-t-2">{t("feedback.title")}</h1>
      <p className={"p5 mg-0"}>{t("feedback.dear", { customerName })}</p>
      <p className="p5 mg-0">{t("feedback.joinToProduct", { productName })}</p>
      <p className="p5 mg-t-4">{t("feedback.text")}</p>
      <div className={elCls("inputs")}>
        <Rating control={control} defaultValue={feedback.rating} />
        <Comment control={control} defaultValue={feedback.comment} />
      </div>
    </div>
  );
};

FeedbackForm.defaultProps = {
  feedback: {
    rating: "",
    comment: "",
    referral_source: "",
  },
};

FeedbackForm.propTypes = {
  control: T.object.isRequired,
  className: T.string,
  customerName: T.string,
  productName: T.string,
  feedback: T.shape({
    rating: T.string,
    comment: T.string,
    referral_source: T.string,
  }),
};

export default FeedbackForm;
