import * as React from "react";

function SvgIsraelFlag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g clipPath="url(#Israel-flag_svg__clip0)">
        <path d="M0 0h38.321v32.139H0V0z" fill="#fff" />
        <path
          d="M0 3h37v4H0V3zM0 25h37v4H0v-4zM18.5 23l-2.167-3.5H12l2.167-3.5L12 12.5h4.333L18.5 9l2.167 3.5H25L22.833 16 25 19.5h-4.333L18.5 23zm-.789-3.5l.789 1.277.789-1.277H17.71zm3.645-1.111h1.581l-.788-1.278-.793 1.278zm-4.334 0h2.956L21.455 16l-1.477-2.389h-2.956L15.545 16l1.477 2.389zm-2.96 0h1.582l-.788-1.278-.793 1.278zm7.294-4.778l.788 1.278.789-1.278h-1.577zm-7.293 0l.788 1.278.789-1.278h-1.577zM17.71 12.5h1.582l-.793-1.277-.789 1.277z"
          fill="#0038B8"
        />
      </g>
      <path
        d="M0 0v-1h-1v1h1zm37 0h1v-1h-1v1zm0 32v1h1v-1h-1zM0 32h-1v1h1v-1zM0 1h37v-2H0v2zm36-1v32h2V0h-2zm1 31H0v2h37v-2zM1 32V0h-2v32h2z"
        fill="#F0F1F2"
      />
      <defs>
        <clipPath id="Israel-flag_svg__clip0">
          <path d="M0 0h37v32H0V0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIsraelFlag;
