import React from "react";
import T from "prop-types";

import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";
import { ArrowRight, Button, Delete } from "@tint_fe/ui";
import { useDeleteExtraProduct } from "./hooks";

const getPriceByKlass = (pi) => {
  switch (pi.klass) {
    case "hotel":
      return Number(pi?.hotel_line_items?.[0]?.price) ?? 0;
    case "tour":
      return Number(pi?.tour_line_items?.[0]?.price) ?? 0;
    case "activity":
      return Number(pi?.activity_line_items?.[0]?.price) ?? 0;
    default:
      return 0;
  }
};

const SelectedExtras = ({
  orderNumber,
  selectedExtras,
  setSelectedExtras,
  selectedProductExtras,
  setSelectedProductExtras,
  onDeleteExtra,
  onPaymentPress,
  chargeCurrency,
}) => {
  const { mainCls, elCls } = parseClasses({ base: "selected-extras" });
  const currencyChar = checkCurrencyChar(chargeCurrency);

  const { patcher: onDeleteProductExtra } = useDeleteExtraProduct();

  const onDeletePress = ({ type, pi_id, extra_id }) => {
    if (type === "extra") {
      onDeleteExtra({
        product_item_id: pi_id,
        product_extra_id: extra_id,
        quantity: 0,
      });
      setSelectedExtras((prev) => prev.filter((extra) => extra.product_option_id !== extra_id));
    } else {
      onDeleteProductExtra({ orderNumber, productItemId: pi_id });
      setSelectedProductExtras((prev) => prev.filter((pi_extra) => pi_extra.id !== pi_id));
    }
  };

  const totalExtrasSum = selectedExtras.reduce(
    (acc, extra) => acc + Number(extra.calculated_item_price) * Number(extra.quantity),
    0,
  );

  const totalProductsSum = selectedProductExtras.reduce((acc, extra_pi) => acc + getPriceByKlass(extra_pi), 0);

  if (!selectedExtras.length && !selectedProductExtras.length) {
    return null;
  }

  return (
    <div className={mainCls}>
      <p className={elCls("title")}>{"Order summary: Upgrades"}</p>
      {selectedExtras.map((extra) => {
        return (
          <div key={extra.id} className={elCls("extra-item")}>
            <div className={elCls("extra-descr")}>
              <p className={elCls("extra-name", "mg-t-0 mg-b-1")}>{extra.display_name}</p>
              <p className={elCls("extra-quantity", "mg-t-0 mg-b-0")}>
                {`${currencyChar}${Number(extra.calculated_item_price).toFixed(2)} × ${extra.quantity}`}
              </p>
            </div>
            <div className={elCls("extra-pricing")}>
              <p className={elCls("extra-price", "mg-t-0 mg-b-0")}>
                {`${currencyChar}${(Number(extra.calculated_item_price) * Number(extra.quantity)).toFixed(2)}`}
              </p>
              <div
                onClick={() => {
                  onDeletePress({ type: "extra", pi_id: extra.product_item_id, extra_id: extra.product_option_id });
                }}
                className={elCls("delete-btn")}
              >
                <Delete />
              </div>
            </div>
          </div>
        );
      })}
      {selectedProductExtras.map((pi_extra) => {
        return (
          <div key={pi_extra.id} className={elCls("extra-item")}>
            <div className={elCls("extra-descr")}>
              <p className={elCls("extra-name", "mg-t-0 mg-b-1")}>{pi_extra.display_name}</p>
            </div>
            <div className={elCls("extra-pricing")}>
              <p className={elCls("extra-price", "mg-t-0 mg-b-0")}>
                {`${currencyChar}${getPriceByKlass(pi_extra).toFixed(2)}`}
              </p>
              <div
                onClick={() => {
                  onDeletePress({
                    type: "product",
                    pi_id: pi_extra.id,
                  });
                }}
                className={elCls("delete-btn")}
              >
                <Delete />
              </div>
            </div>
          </div>
        );
      })}
      <div className={elCls("total-sum-row")}>
        <p className={elCls("total-sum-text", "mg-t-0 mg-b-0")}>{"Total price of additional services"}</p>
        <p className={elCls("extra-price", "mg-t-0 mg-b-0")}>{`${currencyChar}${(
          Number(totalExtrasSum) + Number(totalProductsSum)
        ).toFixed(2)}`}</p>
      </div>
      <Button className={elCls("btn")} onClick={() => onPaymentPress()} nextIcon={<ArrowRight />}>
        Complete my booking
      </Button>
    </div>
  );
};

SelectedExtras.propTypes = {
  orderNumber: T.string,
  selectedExtras: T.array,
  setSelectedExtras: T.func,
  selectedProductExtras: T.array,
  setSelectedProductExtras: T.func,
  order: T.object,
  onDeleteExtra: T.func,
  onPaymentPress: T.func,
  chargeCurrency: T.string,
};

export default SelectedExtras;
