import React from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";

import SelectField from "../../../components/Form/SelectField";
import { mealPlans } from "../../../../helpers/constants";

const SelectMealPlan = ({ name }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const options = Object.values(mealPlans).map((el) => ({
    value: el.value,
    label: t(el.label),
  }));

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...restField }, fieldState: { error } }) => {
        return (
          <SelectField
            label={t("formField.mealPlan")}
            remark={t("selectMealPlan.remark")}
            placeholder={t("formField.mealPlan")}
            options={options}
            onChange={(val) => onChange(val.value)}
            error={error}
            {...restField}
          />
        );
      }}
    />
  );
};

SelectMealPlan.propTypes = {
  name: T.string.isRequired,
};

export default SelectMealPlan;
