import * as React from "react";

function SvgClear(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.207 6.293a1 1 0 00-1.414 1.414L11.086 12l-4.293 4.293a1 1 0 101.414 1.414l4.293-4.293 4.293 4.293a1 1 0 001.414-1.414L13.914 12l4.293-4.293a1 1 0 00-1.414-1.414L12.5 10.586 8.207 6.293z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgClear;
