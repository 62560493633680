import React from "react";
import T from "prop-types";

import { parseClasses, capitalizeLater } from "@tint_fe/helpers/utils";
import { useTranslation } from "@tint_fe/hooks";

import { Location, SvgMap } from "../../assets/icons";
import { Button } from "..";

const Address = ({ className, onClick, address, open }) => {
  const { t } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "address", additional: className });

  const { mainCls: btnClasses } = parseClasses({
    base: elCls("btn"),
    classes: [open && "open"],
    additional: "mg-l-4",
  });

  return (
    <div className={mainCls}>
      <div className={elCls("text")}>
        <Location data-testid="location-icon" className="mg-r-2" />
        <span>{capitalizeLater(address)}</span>
      </div>
      {onClick && (
        <Button data-testid="address-button" prevIcon={<SvgMap />} round className={btnClasses} onClick={onClick}>
          {open ? t("hideMap") : t("map")}
        </Button>
      )}
    </div>
  );
};

Address.propTypes = {
  className: T.string,
  onClick: T.func,
  address: T.string,
  btnClasses: T.string,
  open: T.bool,
};

export default Address;
