import React, { useState } from "react";
import T from "prop-types";
import ReactPhoneInput from "react-phone-input-2";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import InputWrapper from "./InputWrapper";

const PhoneInput = React.forwardRef(
  (
    {
      value,
      name,
      className,
      classNameWrapper,
      error,
      label,
      inputProps,
      showValidateMark,
      disabled,
      countryCodeSetter,
      ...rest
    },
    // eslint-disable-next-line no-unused-vars
    outRef,
  ) => {
    const { isRtl, t } = useTranslation();
    const { mainCls } = parseClasses({
      base: "input",
      additional: className,
      classes: [error && "error"],
    });
    const matches = (code, input) => String(input).slice(0, code.length) === code;
    const [dialNumbers, setDialNumbers] = useState([]);

    return (
      <InputWrapper
        classNameWrapper={classNameWrapper}
        showValidateMark={showValidateMark}
        error={error}
        label={label}
        type="phone"
        disabled={disabled}
        withoutOverflowTooltip
      >
        <ReactPhoneInput
          enableSearch={true}
          disableSearchIcon={true}
          searchPlaceholder={t("search")}
          searchNotFound="No countries to show"
          {...rest}
          value={value}
          inputProps={{
            ...inputProps,
            disabled: disabled,
            dir: "ltr",
            name: name,
            className: mainCls,
            ...(isRtl && { style: { textAlign: "right" } }),
          }}
          isValid={(inputNumber, _country, countries) => {
            if (!dialNumbers.length) {
              setDialNumbers(countries.map((c) => c.dialCode));
            }
            if (typeof countryCodeSetter === "function") {
              const isValid = inputNumber.length < 3 || dialNumbers.some((el) => matches(el, inputNumber));
              countryCodeSetter(isValid);
            }
          }}
        />
      </InputWrapper>
    );
  },
);

PhoneInput.propTypes = {
  value: T.string,
  name: T.string,
  className: T.string,
  classNameWrapper: T.string,
  error: T.object,
  label: T.string,
  inputProps: T.object,
  showValidateMark: T.bool,
  disabled: T.bool,
  countryCodeSetter: T.func,
};

export default PhoneInput;
