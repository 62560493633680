import React, { useEffect } from "react";
import T from "prop-types";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { Preloader } from "@tint_fe/ui";
import SuccessPage from "../containers/SuccessPage/SuccessPage";
import RequestPayment from "../containers/RequestPayment/RequestPayment";
import ErrorPage from "../containers/ErrorPage/ErrorPage";
import UnavailableErrorPage from "../containers/UnavailableErrorPage/UnavailableErrorPage";
import BookingFeedbackPage from "../containers/FeedbackPage/BookingFeedbackPage";
import SmartPackageRouter from "./SmartPackageRouter";
import HotelRouter from "./HotelRouter";
import TourRouter from "./TourRouter";
import BundleRouter from "./BundleRouter";
import ActivityRouter from "./ActivityRouter";
import {
  ADDITIONAL_PAYMENT,
  SMART_PACKAGE,
  ERROR,
  PASSPORT,
  COMPLETED,
  CONFIRMED,
  FEEDBACK,
  HOTEL,
  TOUR,
  BUNDLE,
  ACTIVITY,
  UNAVAILABLE_ERROR,
} from "./routes";
import useGetInitProduct from "./useGetInitProduct";
import { useHistoryNavigation } from "../../hooks";

function AppRoutes({ searchParams, integration }) {
  const { replace } = useHistoryNavigation();
  const loadingProduct = useSelector((state) => state.global.loadingProduct);
  const updatingRates = useSelector((state) => state.global?.ratesWasExpired?.updating);
  const error = useSelector((state) => state.global.error);
  const { loadingOfProductCreationFromUrl } = useGetInitProduct(searchParams);

  useEffect(() => {
    if (!isEmpty(error) && !error.fail_payment && searchParams.source !== "feedback_email") {
      if (error.status === 404) {
        replace(UNAVAILABLE_ERROR);
      } else {
        replace(ERROR);
      }
    }
  }, [error]);

  // useGoogleAnalyticsEvent();

  if (loadingProduct || loadingOfProductCreationFromUrl || updatingRates) {
    return (
      <div className="booking-form preloader-container" id="rc-tooltip-container">
        <Preloader
          style={{ background: "none" }}
          text="Loading your booking experience"
          useInformativeLoader={updatingRates}
          integrationName={integration}
        />
      </div>
    );
  }

  return (
    <div className="booking-form" id="rc-tooltip-container">
      <Switch>
        <Route path={ADDITIONAL_PAYMENT} component={RequestPayment} />
        <Route path={SMART_PACKAGE} component={SmartPackageRouter} />
        <Route path={HOTEL} component={HotelRouter} />
        <Route path={TOUR} component={TourRouter} />
        <Route path={BUNDLE} component={BundleRouter} />
        <Route path={ACTIVITY} component={ActivityRouter} />
        <Route path={FEEDBACK} component={BookingFeedbackPage} />
        <Route path={[PASSPORT, CONFIRMED, COMPLETED]} component={SuccessPage} />
        <Route path={ERROR} component={ErrorPage} />
        <Route path={UNAVAILABLE_ERROR} component={UnavailableErrorPage} />
      </Switch>
    </div>
  );
}

AppRoutes.propTypes = {
  searchParams: T.object.isRequired,
  integration: T.string.isRequired,
};

export default AppRoutes;
