import React from "react";
import T from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import RoomsForm from "../../../components/RoomsForm";

const HotelsRoomsForm = ({ handleChange }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="rooms"
      control={control}
      // eslint-disable-next-line no-unused-vars
      render={({ field: { onChange, value, ref, ...fieldRest } }) => {
        return (
          <RoomsForm
            rooms={value || []}
            handleChange={(rooms) => {
              if (rooms) {
                handleChange();
                onChange(rooms);
              }
            }}
            {...fieldRest}
            isHotel
          />
        );
      }}
    />
  );
};

HotelsRoomsForm.propTypes = {
  handleChange: T.func,
};

export default HotelsRoomsForm;
