import { isEmpty } from "lodash";

import useHistoryNavigation from "./useHistoryNavigation";
import { SMART_PACKAGE_CHECKOUT } from "../react/router";
import useGetSPCheckoutFromData from "./useGetSPCheckoutFromData";
import { setSmartPackageSessionStorage } from "../storage";

const useGetAccomodation = () => {
  const history = useHistoryNavigation();
  const getSPCheckoutFromData = useGetSPCheckoutFromData();

  return ({ formData }) => {
    const checkoutFormData = getSPCheckoutFromData(formData);

    if (!isEmpty(checkoutFormData)) {
      setSmartPackageSessionStorage(checkoutFormData);
    }

    return history.replace(SMART_PACKAGE_CHECKOUT);
  };
};

export default useGetAccomodation;
