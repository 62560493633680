import React, { useState } from "react";
import T from "prop-types";

import { parseClasses, QueryString } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Container, InfoMsg, HotelSuccessSummary, Loader } from "../../components";
import { SuccessIcon } from "../../assets/icons";
import { PassportInfo, SuggestedProducts, DiscountBanner } from "..";

const checkIfKeyExist = (objectName, keyName) => {
  const keyExist = Object.keys(objectName).some((key) => key === keyName);

  return keyExist;
};

const SharedSuccessPage = ({ updatePassportDetails, order, product, price, brandName, hideFooter }) => {
  const search = QueryString.parse(document.location.search);
  const isPassportRequest = search.source === "passport_email";
  const isOrderNeedPassport = order?.state === "passport" || order?.need_passport;
  const isPassportRequestButAlreadyFilled = isPassportRequest && !isOrderNeedPassport;

  const { t } = useTranslation();
  const [showPassportFields, setShowPassportFields] = useState(isPassportRequest);
  const productName = order?.product_items?.length === 1 ? order.product_items[0].display_name : "";
  const isHotel = order?.build_type === "hotel";

  const { mainCls, elCls } = parseClasses({
    base: "success-page",
    additional: "pg-wrapper",
  });

  const discountBanner = order?.confirmation_banner ?? {};
  const showDiscountBanner = checkIfKeyExist(discountBanner, "image") && checkIfKeyExist(discountBanner, "url");

  const handleShowPassport = (val) => {
    if (!val) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    setShowPassportFields(val);
  };

  return (
    <>
      <Container className={mainCls}>
        <div className={elCls("content")}>
          <SuccessIcon height={158} width={158} />
          <h3 className={elCls("title", "h3", "second-font", "mg-t-8", "mg-b-6")}>
            {t("successPage.title", { brand_name: brandName })}
          </h3>
          <p className={elCls("name", "p5", "mg-0", "mg-b-1")}>{t("successPage.text")}</p>

          {/* [3779] Use new layout for hotel success (+companions) */}
          {isHotel ? (
            <HotelSuccessSummary order={order} price={price} />
          ) : (
            <div className={elCls("info", "mg-0", "p5")}>
              {t("successPage.textNumber")}
              <div className={elCls("number", "bold", "mg-l-2")}>
                {order?.number ? (
                  order.number
                ) : (
                  <Loader data-testid="order-loader" style={{ display: "inline-block" }} />
                )}
              </div>
            </div>
          )}
          {product?.meeting_point && (
            <InfoMsg bold={false} centered className="mg-t-4 col col-lg-8">
              {product.meeting_point}
            </InfoMsg>
          )}
        </div>
        {isOrderNeedPassport && (
          <>
            <div className="success-page__left-passport">
              <p className="success-page__left-passport-attention">
                <span>!</span>&nbsp; {t("order.justOneMoreThing")}
              </p>
              <p className="success-page__left-passport-info">{t("order.enterPassportsDetailsArrangements")}</p>
              {isOrderNeedPassport && !showPassportFields && (
                <button
                  className="success-page__left-passport-button p4"
                  onClick={() => setShowPassportFields((prev) => !prev)}
                >
                  {t("order.enterPassportsDetails")}&gt;
                </button>
              )}
            </div>
            {showPassportFields && (
              <PassportInfo
                scrollIntoView={isPassportRequest}
                updatePassportDetails={updatePassportDetails}
                setShowPassportInfo={handleShowPassport}
                order={order}
              />
            )}
          </>
        )}

        {isPassportRequestButAlreadyFilled && (
          <div className="success-page__left-passport-provided">
            <p className="success-page__left-passport-provided-attention">
              <span>!</span>
            </p>
            <p className="success-page__left-passport-provided-attention">{t("order.passportAlreadyProvided")}</p>
          </div>
        )}

        {showDiscountBanner && <DiscountBanner {...discountBanner} />}

        {order?.number && <SuggestedProducts productName={productName} orderNumber={order.number} />}
      </Container>
      {!hideFooter && (
        <footer className={elCls("footer")}>
          <Container>
            <p className="mg-0 p3">&#169; {`${new Date().getFullYear()} ${brandName}`}</p>
          </Container>
        </footer>
      )}
    </>
  );
};

SharedSuccessPage.propTypes = {
  updatePassportDetails: T.func,
  order: T.object,
  price: T.object,
  product: T.object,
  brandName: T.string,
  hideFooter: T.bool,
};

export default SharedSuccessPage;
