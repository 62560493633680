export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILED = "GET_ORDER_FAILED";

export const UPDATE_PASSPORT_DETAILS_REQUEST = "UPDATE_PASSPORT_DETAILS_REQUEST";
export const UPDATE_PASSPORT_DETAILS_SUCCESS = "UPDATE_PASSPORT_DETAILS_SUCCESS";
export const UPDATE_PASSPORT_DETAILS_FAILED = "UPDATE_PASSPORT_DETAILS_FAILED";

export const GOOGLE_PAY = "GOOGLE_PAY";
export const GOOGLE_PAY_SUCCESS = "GOOGLE_PAY_SUCCESS";
export const GOOGLE_PAY_FAILED = "GOOGLE_PAY_FAILED";
