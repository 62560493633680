import * as React from "react";

function SvgChecked(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <circle cx={24} cy={24} r={21} stroke="#0C3164" strokeWidth={2} />
      <path
        d="M22.273 26.712C26.304 20.424 30.573 16.144 35 13L22.194 33C19.11 29.856 16.739 25.751 15 20.598l7.273 6.114z"
        fill="#0C3164"
      />
    </svg>
  );
}

export default SvgChecked;
