import React, { useState, useRef, useMemo } from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";

import placeholder from "../../assets/images/placeholder.svg";

// TODO: need to fix it on build config
const replaceDoubleProtocol = (src) => {
  if (!src || !src?.length) return src;
  const splitted = src.split("//");

  if (splitted.length > 2) {
    return `https://${splitted[splitted.length - 1]}`;
  }

  return src;
};

const Img = ({ src, alt, withBorder, className, ...rest }) => {
  const imgErrorRef = useRef(false);
  const [isBorder, setBorder] = useState(withBorder);
  const { mainCls } = parseClasses({
    base: "img",
    classes: [isBorder && "border"],
    additional: className,
  });

  const handleImgError = (e) => {
    if (!imgErrorRef.current) {
      setBorder(true);
      imgErrorRef.current = true;
      e.target.src = placeholder;
    }
  };

  const memoizedSrc = useMemo(() => replaceDoubleProtocol(src || placeholder), [src]);

  return <img src={memoizedSrc} onError={handleImgError} alt={alt} className={mainCls} {...rest} />;
};

Img.propTypes = {
  src: T.string,
  alt: T.string,
  withBorder: T.bool,
  className: T.string,
};

export default Img;
