import { createSmartPackageOrderRequest } from "../redux/order/orderActions";
import { useGetAccomodation, useHistoryNavigation, useReduxAction } from ".";
import { setSessionStorageProductId, setSessionStorageSourceLink } from "../storage/sessionStorage";
import { steps } from "../helpers/constants";
import { getSmartPackageRequest } from "../redux/smartPackage/smartPackageAction";

const useCreateSpOrderFromQueryParams = () => {
  const createSmartPackageOrder = useReduxAction(createSmartPackageOrderRequest);
  const getSmartPackage = useReduxAction(getSmartPackageRequest);
  const history = useHistoryNavigation();
  const getAccomodation = useGetAccomodation();

  return (product_id) => {
    let spInfo = {};

    try {
      const search = new URLSearchParams(document.location.search);
      const sp = search.get("sp");

      search.delete("sp");

      spInfo = sp ? JSON.parse(atob(sp)) : {};
      const spType = spInfo?.type; // auto means that need to create order

      setSessionStorageProductId(spInfo.product_id);
      setSessionStorageSourceLink(spInfo.source_link);

      if (spType === "auto") {
        createSmartPackageOrder({
          body: {
            order: {
              ...spInfo,
            },
            productType: steps.SMART_PACKAGE_REDIRECT,
          },
          push: history.push,
        });
      } else {
        getSmartPackage({ id: product_id, replace: history.replace, forceSelectHotels: true });
        getAccomodation({ formData: { ...spInfo, hotel_level: null } }, false, true);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export default useCreateSpOrderFromQueryParams;
