import { useReduxAction } from "@tint_fe/redux-utils";
import { useEffect } from "react";
import { saveSelectedPaymentMethod } from "../redux/global/globalActions";

const useSelectedPaymentMethod = (paymentMethod) => {
  const saveSelectedPaymentMethodAction = useReduxAction(saveSelectedPaymentMethod);

  useEffect(() => {
    saveSelectedPaymentMethodAction(paymentMethod);

    return () => saveSelectedPaymentMethodAction("");
  }, [paymentMethod]);
};

export default useSelectedPaymentMethod;
