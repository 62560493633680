import * as React from "react";

function AmexLogo34(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={24} fill="none" {...props}>
      <rect width={34} height={24} x={0.98} fill="#1F72CD" rx={2} />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m7.076 8.5-3.181 7.247h3.808l.472-1.156h1.079l.472 1.156h4.192v-.882l.373.882h2.169l.373-.9v.9h8.718l1.06-1.126.993 1.126 4.477.01-3.19-3.613L32.08 8.5h-4.408l-1.032 1.105L25.68 8.5h-9.484l-.814 1.87-.834-1.87h-3.8v.852l-.423-.852h-3.25Zm.737 1.029h1.856l2.11 4.914V9.53h2.033l1.63 3.523 1.502-3.523h2.024v5.2h-1.232l-.01-4.075-1.795 4.075H14.83l-1.805-4.075v4.075h-2.533l-.48-1.166H7.418l-.48 1.165H5.583l2.23-5.199Zm17.287 0h-5.006v5.197h4.929l1.588-1.722 1.531 1.722h1.6l-2.326-2.583 2.327-2.614h-1.531l-1.58 1.703L25.1 9.529Zm-16.384.88-.855 2.077h1.708l-.853-2.077Zm12.614 1.146v-.95h3.124l1.363 1.518-1.424 1.526H21.33v-1.036h2.731v-1.058h-2.73Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default AmexLogo34;
