import { createTourOrderRequest } from "../../../../redux/order/orderActions";
import { useReduxAction } from "../../../../hooks";
import { getSourceLink } from "../../../../utils";

const useCreateTourOrder = (history) => {
  const createTourOrder = useReduxAction(createTourOrderRequest);

  return (productItemsAttributes) => {
    createTourOrder({
      body: {
        order: {
          product_items_attributes: { 0: productItemsAttributes },
          source_link: getSourceLink(),
        },
      },
      history,
    });
  };
};

export default useCreateTourOrder;
