import * as React from "react";

function SvgCreditCard(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_174_1745)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6V7H2V6ZM0 7.26756C-0.597801 7.61337 -1 8.25972 -1 9C-1 9.74028 -0.597801 10.3866 0 10.7324V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V10.7324C24.5978 10.3866 25 9.74028 25 9C25 8.25972 24.5978 7.61337 24 7.26756V6C24 4.34315 22.6569 3 21 3H3C1.34315 3 0 4.34315 0 6V7.26756ZM22 11V18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18V11H22ZM7 16C7 16.5523 6.55228 17 6 17H5C4.44772 17 4 16.5523 4 16C4 15.4477 4.44772 15 5 15H6C6.55228 15 7 15.4477 7 16ZM11 17C11.5523 17 12 16.5523 12 16C12 15.4477 11.5523 15 11 15H10C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17H11Z"
          fill="#434345"
        />
      </g>
      <defs>
        <clipPath id="clip0_174_1745">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCreditCard;
