import { QueryString } from "@tint_fe/helpers";
import useUser from "./useUser";

const CHARGE_STRATEGIES = {
  ftc: "full_tint_credit",
  fac: "full_agent_credit",
  pac: "partial_agent_credit",
};

const useAgentCommission = ({ order = {} }) => {
  const { user } = useUser();
  const isAgent = user?.isAgent;
  const { source } = QueryString.parse(document.location.search);
  const isStaffPayment = source === "staff_payment";
  const chargeStrategy = order?.charge_strategy;

  return {
    showAgentCommission: isAgent || isStaffPayment,
    showAgentPays: chargeStrategy === CHARGE_STRATEGIES.pac,
    user,
  };
};

export default useAgentCommission;
