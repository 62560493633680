export * from "@tint_fe/redux-utils/src/order/orderActionTypes";

export const ORDER_PROCESSING = "ORDER_PROCESSING";
export const ORDER_REQUEST_FAILED = "ORDER_REQUEST_FAILED";

export const RESET_ORDER = "RESET_ORDER";

export const GET_ORDER_WITH_PRODUCTS_REQUEST = "GET_ORDER_WITH_PRODUCTS_REQUEST";
export const GET_ORDER_WITH_PRODUCTS_SUCCESS = "GET_ORDER_WITH_PRODUCTS_SUCCESS";
export const GET_ORDER_WITH_PRODUCTS_FAILED = "GET_ORDER_WITH_PRODUCTS_FAILED";

export const GET_AVAILABLE_EXTRAS_REQUEST = "GET_AVAILABLE_EXTRAS_REQUEST";
export const GET_AVAILABLE_EXTRAS_SUCCESS = "GET_AVAILABLE_EXTRAS_SUCCESS";
export const GET_AVAILABLE_EXTRAS_FAILED = "GET_AVAILABLE_EXTRAS_FAILED";

export const CREATE_TOUR_ORDER_REQUEST = "CREATE_TOUR_ORDER_REQUEST";
export const CREATE_TOUR_ORDER_SUCCESS = "CREATE_TOUR_ORDER_SUCCESS";

export const CREATE_ACTIVITY_ORDER_REQUEST = "CREATE_ACTIVITY_ORDER_REQUEST";
export const CREATE_ACTIVITY_ORDER_SUCCESS = "CREATE_ACTIVITY_ORDER_SUCCESS";

export const CREATE_HOTEL_ORDER_REQUEST = "CREATE_HOTEL_ORDER_REQUEST";
export const CREATE_HOTEL_ORDER_SUCCESS = "CREATE_HOTEL_ORDER_SUCCESS";

export const CREATE_SMART_PACKAGE_ORDER_REQUEST = "CREATE_SMART_PACKAGE_ORDER_REQUEST";
export const CREATE_SMART_PACKAGE_ORDER_SUCCESS = "CREATE_SMART_PACKAGE_ORDER_SUCCESS";

export const UPDATE_EXTRAS_REQUEST = "UPDATE_EXTRAS_REQUEST";
export const UPDATE_EXTRAS_SUCCESS = "UPDATE_EXTRAS_SUCCESS";
export const UPDATE_EXTRAS_FAILED = "UPDATE_EXTRAS_FAILED";

export const UPDATE_ORDER_PRODUCT_ITEM_REQUEST = "UPDATE_ORDER_PRODUCT_ITEM_REQUEST";
export const UPDATE_ORDER_PRODUCT_ITEM_SUCCESS = "UPDATE_ORDER_PRODUCT_ITEM_SUCCESS";
export const UPDATE_ORDER_PRODUCT_ITEM_FAILED = "UPDATE_ORDER_PRODUCT_ITEM_FAILED";

export const UPDATE_CUSTOMER_DETAILS_REQUEST = "UPDATE_CUSTOMER_DETAILS_REQUEST";
export const UPDATE_CUSTOMER_DETAILS_SUCCESS = "UPDATE_CUSTOMER_DETAILS_SUCCESS";
export const UPDATE_CUSTOMER_DETAILS_FAILED = "UPDATE_CUSTOMER_DETAILS_FAILED";

export const CREATE_PAYMENT_PROCESSING = "CREATE_PAYMENT_PROCESSING";
export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_FAILED = "CREATE_PAYMENT_FAILED";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";

export const INIT_PAYMENT_REQUEST = "INIT_PAYMENT_REQUEST";
export const CLEAR_DATA_FOR_PAYMENT = "CLEAR_DATA_FOR_PAYMENT";

export const REQUEST_PAYMENT_PROCESSING = "REQUEST_PAYMENT_PROCESSING";
export const REQUEST_PAYMENT = "REQUEST_PAYMENT";
export const REQUEST_PAYMENT_FAILED = "REQUEST_PAYMENT_FAILED";
export const REQUEST_PAYMENT_SUCCESS = "REQUEST_PAYMENT_SUCCESS";

export const REQUEST_PAYMENT_CONTINUE_REQUEST = "REQUEST_PAYMENT_CONTINUE_REQUEST";
export const REQUEST_PAYMENT_CONTINUE_FAILED = "REQUEST_PAYMENT_CONTINUE_FAILED";

export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";

export const SEND_DISCOUNT_CODE = "SEND_DISCOUNT_CODE";

export const CREATE_USER_AGENT_PAYMENT_REQUEST = "CREATE_USER_AGENT_PAYMENT_REQUEST";
export const CREATE_USER_AGENT_PAYMENT_SUCCESS = "CREATE_USER_AGENT_PAYMENT_SUCCESS";
export const CREATE_USER_AGENT_PAYMENT_FAILED = "CREATE_USER_AGENT_PAYMENT_FAILED";
