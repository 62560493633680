import React from "react";
import T from "prop-types";

import { INTEGRATIONS_ENUM } from "../helpers/constants";
import { useTranslation } from "../hooks";

const TiTheme = React.lazy(() => import("./Ti"));
const TjTheme = React.lazy(() => import("./Tj"));
const PiTheme = React.lazy(() => import("./Pi"));

const themeClasses = {
  [INTEGRATIONS_ENUM.TI]: "ti",
  [INTEGRATIONS_ENUM.TJ]: "tj",
  [INTEGRATIONS_ENUM.PI]: "pi",
};

const Theme = ({ integration, children }) => {
  const { isRtl, i18n } = useTranslation();

  const getTheme = () => {
    switch (integration) {
      case INTEGRATIONS_ENUM.TJ:
        return <TjTheme />;
      case INTEGRATIONS_ENUM.PI:
        return <PiTheme />;
      default:
        return <TiTheme />;
    }
  };

  return (
    <React.Suspense fallback={<></>}>
      {getTheme()}
      <div className={`booking-widget parent-portal${isRtl ? " rtl" : ""} ${themeClasses[integration] || "ti"}`}>
        <div className={`${i18n.language}`}>{children}</div>
      </div>
    </React.Suspense>
  );
};

Theme.propTypes = {
  integration: T.string,
  children: T.node.isRequired,
};

export default Theme;
