import * as React from "react";

function CheckIn(props) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1H0v2h1v5.5h3V6l5 4-5 4v-2.5H1V15h14V3h1V1ZM3 3h2v2H3V3Zm10 4h-2v2h2V7ZM7 3h2v2H7V3Zm4 0h2v2h-2V3Zm2 8h-2v2h2v-2Z"
        fill="#0C3164"
      />
    </svg>
  );
}

export default CheckIn;
