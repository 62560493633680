import * as React from "react";

function SvgGermanyFlag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path d="M37 0H0v11h37V0z" fill="#000" />
      <path d="M37 11H0v10h37V11z" fill="#D00" />
      <path d="M37 21H0v11h37V21z" fill="#FFCE00" />
      <path
        d="M0 0v-1h-1v1h1zm37 0h1v-1h-1v1zm0 32v1h1v-1h-1zM0 32h-1v1h1v-1zM0 1h37v-2H0v2zm36-1v32h2V0h-2zm1 31H0v2h37v-2zM1 32V0h-2v32h2z"
        fill="#F0F1F2"
      />
    </svg>
  );
}

export default SvgGermanyFlag;
