import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";

import { getCurrAccommodationPrice } from "../helpers";

export const useSPSelectHotelsPrice = () => {
  const selectedHotels = useSelector((state) => state.smartPackage.selectedHotels);
  const selectHotelPrice = useSelector(({ smartPackage }) => smartPackage.data.prices || {});
  const { watch } = useFormContext() || {};

  const children = watch ? watch("children") : 0;
  const adults = watch ? watch("adults") : 0;

  if (isEmpty(selectHotelPrice)) {
    return { children, adults };
  }

  const currAccommodation = getCurrAccommodationPrice(selectedHotels);
  const accomodationPerPerson = Math.floor(Number(currAccommodation / (children + adults)));

  const totalPrice =
    (Number(selectHotelPrice.price_per_adult) + accomodationPerPerson) * adults +
    (Number(selectHotelPrice.price_per_child) + accomodationPerPerson) * children;
  // const roundedTotalPrice = Math.ceil(Number(totalPrice));

  const price_per_person = Math.ceil(
    totalPrice / (Number(selectHotelPrice.adults) + Number(selectHotelPrice.children)),
  );

  const price_per_adult = Math.ceil(Number(selectHotelPrice.price_per_adult)) + accomodationPerPerson;
  const price_per_child = Math.ceil(Number(selectHotelPrice.price_per_child)) + accomodationPerPerson;
  const roundedTotalPrice = price_per_adult * adults + price_per_child * children;

  return {
    price_per_adult,
    price_per_child,
    roundedTotalPrice,
    totalPrice,
    children,
    adults,
    price_per_person,
  };
};
