import React from "react";
import T from "prop-types";
import { useFormContext } from "react-hook-form";

import Overnights from "../../../components/Overnights";
import { SPCheckoutFormFields } from "../helpers";

const SPOvernights = ({ packageNights }) => {
  const { watch } = useFormContext();
  const formStartDate = watch(SPCheckoutFormFields.START_DATE);
  if (!formStartDate) {
    return null;
  }

  return (
    <Overnights
      formStartDate={formStartDate}
      className={"extra-nights"}
      packageNights={packageNights}
      names={{
        arrival: SPCheckoutFormFields.ARRIVAL_EXTRA_NIGHTS,
        departure: SPCheckoutFormFields.DEPARTURE_EXTRA_NIGHTS,
      }}
    />
  );
};

SPOvernights.propTypes = {
  packageNights: T.number.isRequired,
};

export default SPOvernights;
