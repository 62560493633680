import React from "react";
import T from "prop-types";

import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Address, ReplaceItemCard } from "..";
import { CommissionLevelIcon, Food, Star } from "../../assets/icons";

const generateCommissionIcons = (level) => {
  switch (level) {
    case "low":
    case "regular":
      return null;
    case "high":
      return <CommissionLevelIcon />;
    case "very high":
      return (
        <>
          <CommissionLevelIcon />
          <CommissionLevelIcon />
        </>
      );
    case "best":
      return (
        <>
          <CommissionLevelIcon />
          <CommissionLevelIcon />
          <CommissionLevelIcon />
        </>
      );

    default:
      return null;
  }
};

const generateCommissionText = (level) => {
  switch (level) {
    case "low":
    case "regular":
      return null;
    case "high":
      return "Top Pick";
    case "very high":
      return "Traveler Favorite";
    case "best":
      return "Highly Recommended";
    default:
      return null;
  }
};

const generateRatingStars = (rating) => {
  const ratingNumber = Number(rating);
  if (!ratingNumber) {
    return null;
  }

  return (
    <>
      {Array.from(Array(ratingNumber)).map((_el, s_key) => (
        <Star key={s_key} height={16} width={16} className="mg-r-1" />
      ))}
    </>
  );
};

const HotelCard = ({
  images,
  price,
  name,
  address,
  selected,
  changeSelectedHotel,
  description,
  onShowMoreInfo,
  showLocation,
  onShowLocation,
  breakfastIncluded,
  isPriceAbsolute,
  caption,
  overall_rating,
  overall_ratings_total,
  className,
  hidePrice,
  from,
  currency,
  level,
  rating,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isRtl } = useTranslation();
  const { mainCls, elCls } = parseClasses({
    base: "hotel-card",
    classes: [selected && "selected", isRtl && "rtl"],
    additional: [className],
  });
  const currencyChar = checkCurrencyChar(currency);

  const getPrice = () => {
    const absPrice = `${currencyChar}${Math.abs(Number(price))}`;
    if (isPriceAbsolute) return absPrice;

    return `${price > 0 ? "+" : "-"} ${absPrice}`;
  };

  const badge = overall_ratings_total && overall_rating && (
    <>
      <span className={elCls("badge-reviews")}>
        {overall_ratings_total} {overall_ratings_total > 1 ? "reviews" : "review"}
      </span>
      <span className={elCls("badge-score")}>{overall_rating}</span>
    </>
  );

  const commissionIcons = generateCommissionIcons(level);
  const commissionText = generateCommissionText(level);
  const starsRating = generateRatingStars(rating);

  return (
    <ReplaceItemCard
      className={mainCls}
      name={name}
      onSelect={changeSelectedHotel}
      onShowMoreInfo={onShowMoreInfo}
      selected={selected}
      imgs={images}
      badge={badge}
      commissionLevel={level}
      {...rest}
    >
      {(!!commissionText || React.isValidElement(starsRating)) && (
        <div className={elCls("commission")}>
          {React.isValidElement(starsRating) ? <div className={elCls("stars-inner")}>{starsRating}</div> : null}
          {!!commissionText && React.isValidElement(starsRating) && <div className={elCls("commisssion-divider")} />}
          {!!commissionText && (
            <div className={elCls("commission-inner")}>
              {commissionIcons}
              <span>{commissionText}</span>
            </div>
          )}
        </div>
      )}

      <Address className={elCls("address")} address={address} open={showLocation} onClick={onShowLocation} />

      {breakfastIncluded && (
        <div className={elCls("food")}>
          <Food />
          <span>{t("hotelCard.breakfast")}</span>
        </div>
      )}

      <div className={elCls("description")}>{description}</div>

      {hidePrice ? (
        <div className={elCls("price-wrapper")}></div>
      ) : (
        <>
          <div className={elCls("price-wrapper")}></div>
          <span className={elCls("price-content")}>
            {from && <span className="p2-1 bold">{t("from")}</span>}
            <span className={elCls("price", "bold")}>{getPrice()}</span>
            <span className={elCls("price-caption", "p2-1", "bold")}>{caption ? t(caption) : t("perPerson")}</span>
          </span>
        </>
      )}
    </ReplaceItemCard>
  );
};

HotelCard.propTypes = {
  images: T.array,
  rating: T.oneOfType([T.number, T.string]),
  price: T.oneOfType([T.number, T.string]),
  name: T.string,
  facilities: T.array,
  city: T.string,
  selected: T.bool,
  changeSelectedHotel: T.func,
  address: T.string,
  description: T.string,
  onShowMoreInfo: T.func,
  showLocation: T.bool,
  onShowLocation: T.func,
  breakfastIncluded: T.bool,
  isPriceAbsolute: T.bool,
  overall_rating: T.number,
  overall_ratings_total: T.number,
  hidePrice: T.bool,
  className: T.string,
  caption: T.string,
  from: T.bool,
  currency: T.string,
  level: T.oneOf(["low", "regular", "high", "very high", "best"]),
};

export default HotelCard;
