import { dates } from "@tint_fe/helpers";
import { TINT_GENERIC_KEY } from "@tint_fe/ui/src/containers/CustomerExtraForms/CustomerExtraForm";

export const POSSIBLE_STEPS = {
  customerData: "CUSTOMER_DATA",
  customerExtraData: "CUSTOMER_EXTRA_DATA",
  participantInfos: "PARTICIPANT_INFOS",
};

export const PARTICIPANT_INFOS_KEY = "participantInfos";

export const generateSteps = (hasCustomerExtraSchema, hasParticipantsSchema) => {
  const steps = [POSSIBLE_STEPS.customerData];
  if (hasCustomerExtraSchema) {
    steps.push(POSSIBLE_STEPS.customerExtraData);
  }
  if (hasParticipantsSchema) {
    steps.push(POSSIBLE_STEPS.participantInfos);
  }

  return steps;
};

const removeTintGenericKeys = (obj) => {
  const newObject = {};

  for (const key in obj) {
    if (!key.includes("tint_generic_key")) {
      newObject[key] = obj[key];
    }
  }

  return newObject;
};

const removeTintGenericKeysNested = (obj) => {
  const newObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObject[key] = {};
      for (const subKey in obj[key]) {
        if (!subKey.includes("tint_generic_key")) {
          newObject[key][subKey] = obj[key][subKey];
        }
      }
    }
  }

  return newObject;
};

const formatDatesInCustomerExtraData = (c_data) => {
  const result = c_data;
  Object.keys(result).forEach((key) => {
    if (result[key] instanceof Object || String(result[key]).match(/^\d{4}-\d{2}-\d{2}/)) {
      if (result[key].hasOwnProperty("value")) {
        result[key.replace(TINT_GENERIC_KEY, "")] = result[key].value;
      } else {
        result[key.replace(TINT_GENERIC_KEY, "")] = dates.formatForApi(new Date(result[key]), false);
      }
    } else {
      result[key.replace(TINT_GENERIC_KEY, "")] = result[key];
    }
  });

  const finalResult = removeTintGenericKeys(result);

  return finalResult;
};

export const formatCustomerDetailsBody = (values) => {
  const withFormattedDates = formatDatesInCustomerExtraData(values);

  return {
    order: {
      extra_customer_data: {
        ...withFormattedDates,
      },
    },
  };
};

const formatDatesInParticipantInfos = (infos) => {
  const result = { ...infos };

  Object.keys(infos).forEach(function (key) {
    for (const [field, value] of Object.entries(infos[key])) {
      if (value instanceof Object || String(value).match(/^\d{4}-\d{2}-\d{2}/)) {
        if (value.hasOwnProperty("value")) {
          result[key][field.replace(TINT_GENERIC_KEY, "")] = value.value;
        } else {
          result[key][field.replace(TINT_GENERIC_KEY, "")] = dates.formatForApi(new Date(value), false);
        }
      } else {
        result[key][field.replace(TINT_GENERIC_KEY, "")] = value;
      }
    }
  });

  const finalResult = removeTintGenericKeysNested(result);

  return finalResult;
};

export const formatParticipantInfosBody = (values) => {
  const withFormattedDates = formatDatesInParticipantInfos(values[PARTICIPANT_INFOS_KEY]);

  return {
    order: {
      participant_infos: {
        ...withFormattedDates,
      },
    },
  };
};
