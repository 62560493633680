import * as React from "react";

function SvgRoundSpinner(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon spinner"
      {...props}
    >
      <path
        d="M8.892 13.918c.084.554.605.943 1.141.78a7 7 0 10-8.964-5.716c.079.555.65.863 1.189.705.538-.158.834-.724.788-1.282a4.97 4.97 0 116.676 4.257c-.525.194-.913.702-.83 1.256z"
        fill="#151209"
      />
    </svg>
  );
}

export default SvgRoundSpinner;
