import { useState, useCallback } from "react";
import useScript from "./useScript";

const useLoadApplePayBtn = () => {
  const allScripts = document.getElementsByTagName("script");
  const url = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
  const isPresent = Array.from(allScripts).some((s) => s.src === url);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(isPresent);

  useScript(
    url,
    useCallback(() => {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        setIsApplePayAvailable(true);
      }
    }, []),
  );

  return isApplePayAvailable;
};

export default useLoadApplePayBtn;
