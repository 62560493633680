import * as React from "react";

function SvgInfo2(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 108 0a8 8 0 000 16zM9 6H6v2h1.5v2H6v2h4v-2H9V6zm0-2c0 1.333-2 1.333-2 0s2-1.333 2 0z"
        fill="#8F8F90"
      />
    </svg>
  );
}

export default SvgInfo2;
