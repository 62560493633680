import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { dates, QueryString } from "@tint_fe/helpers";
import * as types from "./smartPackageTypes";
import * as orderTypes from "../order/orderActionTypes";
import * as globalTypes from "../global/globalActionTypes";
import { selectOrderExpiredTime } from "../order/orderSelectors";
import { getAvailableDatesSmartPackage, getPackageById, getSmartPackagesAccommodation } from "../../api";
import { getInitialSelectedHotels } from "./helpers";
import { SMART_PACKAGE_CHECKOUT, SMART_PACKAGE_SELECT_HOTELS } from "../../react/router/routes";
import { isArray } from "lodash";
import { PACKAGE_FORMAT_DATE, steps } from "../../helpers/constants";

function* getSmartPackageSaga(action) {
  try {
    const { replace, location, forceSelectHotels = false, ...rest } = action.payload;
    const response = yield call(getPackageById, rest);

    if (replace) {
      if (forceSelectHotels) {
        // eslint-disable-next-line no-unused-vars
        const { sp, ...restQuery } = yield QueryString.parse(document.location.search);
        yield put({ type: globalTypes.SET_STEP_ACTION, payload: steps.SELECT_HOTELS }); // keep to turn off loader
        yield replace({ pathname: SMART_PACKAGE_SELECT_HOTELS, search: QueryString.stringify(restQuery) });
      } else if (location.pathname.includes(SMART_PACKAGE_SELECT_HOTELS)) {
        yield replace(SMART_PACKAGE_SELECT_HOTELS);
      } else {
        yield replace(SMART_PACKAGE_CHECKOUT);
      }
    }

    yield put({
      type: types.GET_SMART_PACKAGE_SUCCESS,
      payload: {
        package: { ...response.package },
      },
    });
  } catch (err) {
    console.error(err);
    yield put({ type: globalTypes.SET_ERROR_ACTION, payload: err });
  }
}

export function* getSmartPackageAccommodationSaga(action) {
  yield put({ type: types.SMART_PACKAGE_FETCHING });
  const { history, overridenHistory, body, isOnlyOneStartPoint = false } = action.payload;
  const { push } = history || {};

  try {
    const response = yield call(getSmartPackagesAccommodation, { query: { order: body } });
    const orderExpiredTime = yield select(selectOrderExpiredTime);

    yield put({
      type: types.GET_SMART_PACKAGE_ACCOMMODATION_SUCCESS,
      payload: {
        ...response,
        // if we already have orderExpiredTime, we should keep it,
        // we need it for keeping expire time when user navigate between
        // select hotel page and customer page but don't  click continue
        expire_at: orderExpiredTime || response.expire_at,
        // we need save this time whe for update accomodation_expired_at when user click continue,
        // when user navigate between select hotel page and customer page
        ...(orderExpiredTime && { cashed_expire_at: response.expire_at }),
      },
    });

    if (response?.accommodation?.length) {
      yield put({
        type: types.UPDATE_SELECTED_HOTELS,
        payload: getInitialSelectedHotels(response.accommodation),
      });
      if (push) {
        yield put({ type: orderTypes.RESET_ORDER });

        // eslint-disable-next-line no-unused-vars
        const { order, ...rest } = yield QueryString.parse(document.location.search);
        yield push({ pathname: SMART_PACKAGE_SELECT_HOTELS, search: QueryString.stringify(rest) });
      }
    } else {
      yield put({
        type: orderTypes.CREATE_SMART_PACKAGE_ORDER_REQUEST,
        payload: {
          body: {
            order: {
              ...body,
              rooms: {
                ...body.rooms,
              },
            },
          },
          push,
        },
      });
    }
  } catch (err) {
    console.error(err);

    // TINT-5143 - START
    if (err.messages.includes("errors.packages.smart.start_city")) {
      const availableDates = yield call(getAvailableDatesSmartPackage, {
        id: body?.package_id,
        params: {
          // useGetSPCheckoutFromData
          start_date: dates.format(body?.package_start_date, PACKAGE_FORMAT_DATE, false),
          force: true,
        },
      });

      const disabledDates = availableDates?.package?.disabled_dates ?? [
        // In case where disabled_dates is not defined, we should allow user to proceed
        dates.format(body?.package_start_date, "dd/MM/yyyy", false),
      ];
      const isPreselectedDateDisabled = disabledDates?.includes(
        dates.format(body?.package_start_date, "dd/MM/yyyy", false),
      );

      if (isPreselectedDateDisabled) {
        const { push: overridenReplace } = overridenHistory || {};
        if (overridenReplace) {
          yield put({ type: orderTypes.RESET_ORDER });
          yield overridenReplace({ pathname: SMART_PACKAGE_CHECKOUT });
        }

        yield put({
          type: types.GET_SMART_PACKAGE_ACCOMMODATION_FAILED,
          payload: {
            status: 400,
            fields: ["datepicker"],
            type: "availability",
            contactUsButton: false,
            messages: ["errors.packages.smart.start_date"],
          },
        });

        return;
      }
    }
    // TINT-5143 - END

    // TINT-4851
    if (
      isArray(err.messages) &&
      (err.messages.includes("errors.packages.smart.no_rooms_available") ||
        err.messages.includes("errors.packages.smart.start_city") ||
        err.messages.includes("errors.packages.smart.no_rooms_available_multiple"))
    ) {
      const { push: overridenReplace } = overridenHistory || {};
      if (overridenReplace) {
        yield put({ type: orderTypes.RESET_ORDER });
        yield overridenReplace({ pathname: SMART_PACKAGE_CHECKOUT });
      }
    }
    // TINT-4736
    if (isOnlyOneStartPoint && isArray(err.messages) && err.messages.includes("errors.packages.smart.start_city")) {
      yield put({
        type: types.GET_SMART_PACKAGE_ACCOMMODATION_FAILED,
        payload: { ...err, messages: ["errors.packages.smart.no_rooms_available"] },
      });
    } else {
      yield put({
        type: types.GET_SMART_PACKAGE_ACCOMMODATION_FAILED,
        payload: err,
      });
    }
  }
}

// should be run only on create smart package order
export function* restoreCashedSPExpiredTime() {
  const spCashedExpiredTime = yield select((state) => state.smartPackage.data.cashed_expire_at);

  if (spCashedExpiredTime) {
    yield put({ type: types.UPDATE_SMART_PACKAGE_EXPIRED_AT, payload: spCashedExpiredTime });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_SMART_PACKAGE_REQUEST, getSmartPackageSaga),
    yield takeLatest(types.GET_SMART_PACKAGE_ACCOMMODATION_REQUEST, getSmartPackageAccommodationSaga),
  ]);
}
