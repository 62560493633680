import React from "react";
import { v4 as uuidV4 } from "uuid";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import BookingNavItem from "../../components/BookingNav/BookingNavItem";

export const steps = [
  {
    label: "steps.orderDetails",
    path: "/",
  },
  {
    label: "steps.payment",
    path: "/",
  },
];

// Rewrited from booking nav
const ModalNav = ({ step }) => {
  const { i18n, isRtl } = useTranslation();

  const { mainCls, elCls } = parseClasses({
    base: "modal-nav",
    classes: [`number-fo-steps-${steps.length}`, `lang-${i18n.language}`],
  });

  const getContent = () => {
    return (
      <div dir={isRtl ? "rtl" : "auto"} className={mainCls}>
        {steps.map((item, index) => {
          return (
            <BookingNavItem
              path={item.path}
              key={uuidV4()}
              current={step === index}
              isAllowed={step > index}
              number={index + 1}
              title={item.label}
            />
          );
        })}
      </div>
    );
  };

  return <div className={elCls("wrapper", "col col-12")}>{getContent()}</div>;
};

ModalNav.propTypes = {
  step: T.number.isRequired,
};

export default ModalNav;
