import * as React from "react";

function SvgPlus(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.00059C6.69176 4.00059 2.09851 7.24188 1.02687 11.7709C0.991043 11.9223 0.991043 12.08 1.02687 12.2314C2.09851 16.7605 6.69176 20.0018 12 20.0018C17.3082 20.0018 21.9015 16.7605 22.9731 12.2314C23.009 12.08 23.009 11.9223 22.9731 11.7709C21.9015 7.24188 17.3082 4.00059 12 4.00059ZM12 18.0018C7.53602 18.0018 3.95436 15.3518 3.03205 12.0012C3.95436 8.65059 7.53602 6.00059 12 6.00059C16.464 6.00059 20.0456 8.65059 20.968 12.0012C20.0456 15.3518 16.464 18.0018 12 18.0018ZM10.0372 11.9996C10.0372 10.9156 10.916 10.0368 12 10.0368C13.084 10.0368 13.9628 10.9156 13.9628 11.9996C13.9628 13.0836 13.084 13.9624 12 13.9624C10.916 13.9624 10.0372 13.0836 10.0372 11.9996ZM12 8.03681C9.81141 8.03681 8.0372 9.81102 8.0372 11.9996C8.0372 14.1882 9.81141 15.9624 12 15.9624C14.1886 15.9624 15.9628 14.1882 15.9628 11.9996C15.9628 9.81102 14.1886 8.03681 12 8.03681Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgPlus;
