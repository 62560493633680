import * as React from "react";
const RoomAreaIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#212121"
      d="M16.09 17.727h1.637v1.636h-1.636v-1.636Zm-3.272 0h1.636v1.636h-1.636v-1.636Zm-9.818 0c0 .9.736 1.636 1.636 1.636h6.546v-4.909H3v3.273Zm6.545-13.09h1.637v1.636H9.545V4.636Zm-3.272 0h1.636v1.636H6.273V4.636Zm9.818 0h1.636v1.636h-1.636V4.636ZM3 11.181h1.636v1.636H3v-1.636Zm0-3.273h1.636v1.636H3V7.91Zm1.636-3.273C3.818 4.636 3 5.454 3 6.273h1.636V4.636Zm8.182 0h1.636v1.637h-1.636V4.636Zm6.546 14.727c.818 0 1.636-.818 1.636-1.636h-1.636v1.636Zm0-8.181H21v1.636h-1.636v-1.636Zm0-6.546v1.637H21c0-.819-.818-1.637-1.636-1.637Zm0 9.818H21v1.637h-1.636v-1.637Zm0-6.545H21v1.636h-1.636V7.91Z"
    />
  </svg>
);
export default RoomAreaIcon;
