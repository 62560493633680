import React from "react";
import T from "prop-types";

const SummaryHotelsItems = ({ hotels, classNameItem }) => {
  return (
    hotels.length > 0 &&
    hotels.map((el) => (
      <div className={classNameItem} key={`${el.id || el.name}-${el.days}`}>
        {el.days} {el.days > 1 ? "nights" : "night"} at {el.name}
      </div>
    ))
  );
};

SummaryHotelsItems.defaultProps = {
  hotels: [],
};

SummaryHotelsItems.propTypes = {
  hotels: T.array,
  classNameItem: T.string,
};

export default SummaryHotelsItems;
