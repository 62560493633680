const credoraxKeyCreation = async ({
  merchant_id,
  static_key,
  payment_uuid,
  card_number,
  card_expired,
  cvc,
  card_holder,
}) => {
  const requestId = `${payment_uuid}-${new Date().getTime()}`;
  const month = card_expired.split("/")[0];
  const year = card_expired.split("/")[1];
  const number = card_number.replace(/ /g, "");

  const response = await window.keyCreation(merchant_id, requestId, static_key, number, month, year, cvc, card_holder);

  return JSON.parse(response);
};

export default credoraxKeyCreation;
