import { PAYMENT } from "../../router";
import { bookingTypes, steps } from "../../../helpers/constants";

import {
  SMART_PACKAGE_SELECT_HOTELS,
  getCheckoutPathByBuildType,
  getCustomerPathByBuildType,
  getPaymentPathByBuildType,
} from "../../router/routes";

export const checkIfNextStepAllowed = (currSteps, stepIndex, orderState) => {
  if (!orderState) return false;
  if (currSteps[stepIndex].path.includes(PAYMENT)) return false;

  const stepsToCheck = currSteps.slice(stepIndex);

  return !!stepsToCheck.find((el) => el.path.includes(orderState));
};

export const getSteps = (bookingType, skipAccommodation, isAgent, isCustomPrice, isActivityOnPayment) => {
  const isSelectHotelsAvailable = bookingTypes.SMART_PACKAGE === bookingType && !skipAccommodation;

  return [
    {
      title: "yourBooking",
      label: "steps.orderDetails",
      value: steps.CHECKOUT,
      path: getCheckoutPathByBuildType(bookingType),
      disabled: bookingType === bookingTypes.BUNDLE || isCustomPrice,
    },
    isSelectHotelsAvailable && {
      label: "steps.hotelSelection",
      value: steps.SELECT_HOTELS,
      path: SMART_PACKAGE_SELECT_HOTELS,
      disabled: isCustomPrice,
    },
    {
      title: isAgent ? "travelerDetails" : "yourDetails",
      label: "steps.personalDetails",
      value: steps.CUSTOMER,
      path: getCustomerPathByBuildType(bookingType),
      disabled: isActivityOnPayment,
    },
    {
      label: "steps.payment",
      value: steps.PAYMENT,
      path: getPaymentPathByBuildType(bookingType),
    },
  ].filter(Boolean);
};
