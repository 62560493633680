import * as React from "react";

function SvgFavoritesIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 20C12 20 3 14.9091 3 8.72728C3.00018 7.63467 3.37499 6.57585 4.06069 5.73084C4.74639 4.88582 5.70067 4.30677 6.76127 4.09214C7.82187 3.8775 8.92334 4.04052 9.87839 4.55349C10.8334 5.06646 11.5831 5.89771 12 6.90591L12 6.90592C12.4169 5.89772 13.1666 5.06647 14.1216 4.5535C15.0767 4.04053 16.1781 3.8775 17.2387 4.09214C18.2993 4.30677 19.2536 4.88582 19.9393 5.73084C20.625 6.57585 20.9998 7.63467 21 8.72728C21 14.9091 12 20 12 20Z"
        stroke="#151209"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20C12 20 3 14.9091 3 8.72728C3.00018 7.63467 3.37499 6.57585 4.06069 5.73084C4.74639 4.88582 5.70067 4.30677 6.76127 4.09214C7.82187 3.8775 8.92334 4.04052 9.87839 4.55349C10.8334 5.06646 11.5831 5.89771 12 6.90591L12 6.90592C12.4169 5.89772 13.1666 5.06647 14.1216 4.5535C15.0767 4.04053 16.1781 3.8775 17.2387 4.09214C18.2993 4.30677 19.2536 4.88582 19.9393 5.73084C20.625 6.57585 20.9998 7.63467 21 8.72728C21 14.9091 12 20 12 20Z"
        fill="#151209"
      />
    </svg>
  );
}

export default SvgFavoritesIcon;
