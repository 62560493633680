export const getListOfChangedHotels = (changedHotels, t) => {
  return changedHotels?.reduce((acc, el) => {
    let hotels = `${el.refreshed_name} ${t("to")} ${el.name}`;

    switch (true) {
      case el.days?.length > 2:
        hotels = `${hotels} ${t("from")} ${el.days[0]} ${t("to")} ${el.days[el.days.length - 1]} ${t("days")}`;
        break;
      case el.days?.length === 2:
        hotels = `${hotels} ${t("onDay")} ${el.days.join(` ${t("and")} `)}`;
        break;
      case !el?.days:
        hotels = `${hotels}`;
        break;
      default:
        hotels = `${hotels} ${t("onDays")} ${el.days}`;
    }

    return [...acc, hotels];
  }, []);
};
