import { dates as datesUtils } from "@tint_fe/helpers";

export const getDayJourney = (journeyDates, dates) => {
  const days = [];
  const startDay = datesUtils.differenceInCalendarDays(dates.start_at, journeyDates.package_start_date);
  const lastDay = datesUtils.differenceInCalendarDays(dates.end_at, dates.start_at);

  for (let i = startDay; i <= lastDay; i++) {
    days.push(i + 1);
  }

  return days;
};
