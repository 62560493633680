import { useCallback, useEffect } from "react";

import { useHistoryNavigation } from "../../../hooks";

const useNavigationByKeyboard = (steps, checkIfNextStepAllowed, currentIndex, preventNavigate) => {
  const { push, replace } = useHistoryNavigation();

  const onLeft = useCallback(() => {
    if (currentIndex !== 0 && !preventNavigate && !steps[currentIndex - 1].disabled) {
      replace(steps[currentIndex - 1].path);
    }
  }, [steps, currentIndex, replace]);

  const onRight = useCallback(() => {
    const nextStepIndex = currentIndex + 1;

    if (checkIfNextStepAllowed(nextStepIndex) && !steps[currentIndex + 1].disabled) {
      return push(steps[currentIndex + 1].path);
    }
  }, [steps, currentIndex, push, checkIfNextStepAllowed]);

  useEffect(() => {
    const handler = (event) => {
      switch (event.keyCode) {
        case 37:
          return onLeft();
        case 39:
          return onRight();
        default:
        // do nothing
      }
    };

    document.addEventListener("keydown", handler);

    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [onLeft, onRight]);
};

export default useNavigationByKeyboard;
