import React, { useState } from "react";
import T from "prop-types";
import { isEmpty } from "lodash";

import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { ConfirmationHotel, ConfirmationNumber, ConfirmationPax, ConfirmationPriceTag } from "../../assets/icons";
import {
  generateImportantNotes,
  generatePaxInfo,
  generateTaxInfo,
  generateTaxInfoString,
  generateRoomsInfo,
} from "./helpers";
import Companions from "../Companions/Companions";

const HotelSuccessSummary = ({ className, order, price }) => {
  const { t, isRtl } = useTranslation();
  const { mainCls, elCls } = parseClasses({
    base: "hotel-success-summary",
    classes: [isRtl && "rtl"],
    additional: className,
  });
  const [hideCompanions, setHideCompanions] = useState(!isEmpty(order?.companions) || order?.pax_quantity === 1);

  const paxInfo = generatePaxInfo(order?.rooms_description);
  const { totalIncludedTaxes, totalExcludedTaxes } = generateTaxInfo(order.product_items[0]);
  const roomsInfo = generateRoomsInfo(order.product_items[0]);
  const currencyChar = checkCurrencyChar(price?.charge_currency || "USD");

  const lines = [
    {
      icon: <ConfirmationNumber />,
      text: t("hotels.success.bookingNumber"),
      value: order.number,
    },
    {
      icon: <ConfirmationHotel />,
      text: t("hotels.success.hotel"),
      value: order?.product_items && order.product_items[0].display_name,
      extraText: `(${roomsInfo})`,
    },
    {
      icon: <ConfirmationPax />,
      text: "",
      value: paxInfo,
    },
    {
      icon: <ConfirmationPriceTag />,
      text: t("summary.totalCharge"),
      value: `${currencyChar}${Number(price?.tint_charge_price || 0)}`,
      extraText: generateTaxInfoString(totalIncludedTaxes, totalExcludedTaxes, currencyChar),
    },
  ];

  const hints = generateImportantNotes();

  return (
    <div className={mainCls}>
      {lines.map((line, index) => (
        <div key={index} className={elCls("line")}>
          <div className={elCls("icon")}>{line.icon}</div>
          <p className={elCls("text", "p5", "mg-0")}>
            {line.text ? `${line.text}: ` : ""}
            <span className={line.text && "bold"}>{line.value}</span> {line?.extraText && line.extraText}
          </p>
        </div>
      ))}
      {!hideCompanions && (
        <Companions
          orderNumber={order.number}
          paxQuantity={order?.pax_quantity}
          hideSection={() => setHideCompanions(true)}
        />
      )}
      <ul className={elCls("hints-list")}>
        {hints.map((hint, index) => (
          <li key={index} className={elCls("hint", "p4-1", "mg-0")}>
            {t(hint)}
          </li>
        ))}
      </ul>
    </div>
  );
};

HotelSuccessSummary.propTypes = {
  className: T.string,
  order: T.object.isRequired,
  price: T.object.isRequired,
};

export default HotelSuccessSummary;
