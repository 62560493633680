import { getSavedCreditCardDetails } from "@tint_fe/api";

import useRequest from "./useRequest";
import { useEffect, useState } from "react";
import useIsLogged from "./useIsLogged";

const useCreditCardDetails = () => {
  const [creditCardDetails, setCreditCardDetails] = useState([]);
  const isLogged = useIsLogged();

  const { loading, error, callToApi } = useRequest({
    apiCall: getSavedCreditCardDetails,
    onSuccess: (res) => {
      setCreditCardDetails(res?.user?.saved_credit_card_details ?? []);
    },
  });

  useEffect(() => {
    if (isLogged) {
      callToApi();
    } else {
      setCreditCardDetails([]);
    }
  }, [isLogged]);

  return {
    creditCardDetails,
    loading,
    error,
  };
};

export default useCreditCardDetails;
