import * as React from "react";
const FlagGB = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path fill="#fff" d="M.667 0h18.667v16H.667z" />
      <path fill="#0A17A7" d="M.667 0h18.667v16H.667z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.667 10.5H7.73V16h4.54v-5.5h7.063v-5H12.27V0H7.73v5.5H.667v5Z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeWidth={3.784}
        d="M7.73 5.5.665 0M12.27 5.5 19.333 0M12.27 10.5l7.063 5.5M7.73 10.5.665 16"
      />
      <path
        fill="#F93939"
        fillRule="evenodd"
        d="m.432.296 5.045 4 .47-.593-5.045-4-.47.593Zm19.137 0-5.046 4-.47-.593 5.045-4 .47.593Zm-5.046 11.407 5.046 4-.47.594-5.046-4 .47-.594Zm-14.091 4 5.045-4 .47.594-5.045 4-.47-.594ZM8.739 9.5H.667v-3h8.072V0h2.522l.072 6.5h8v3h-8.072V16H8.74V9.5Z"
        clipRule="evenodd"
      />
    </g>
    <path stroke="#fff" d="M1.167.5h17.667v15H1.167z" />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.667 0h18.667v16H.667z" />
      </clipPath>
    </defs>
  </svg>
);
export default FlagGB;
