import { useSelector } from "react-redux";

import { updateExtrasRequest } from "../../../../redux/order/orderActions";
import { useReduxAction } from "../../../../hooks";
import { selectOderProductExtraLineItems } from "../../../../redux/order/orderSelectors";
import { serializeExtras } from "../helpers";

const useUpdateExtras = (history) => {
  const updateExtras = useReduxAction(updateExtrasRequest);
  const orderProductExtraLineItems = useSelector(selectOderProductExtraLineItems);

  return (extras, reset) => {
    updateExtras({
      body: {
        product_item: {
          extra_line_items_attributes: serializeExtras(orderProductExtraLineItems, { ...extras }, reset),
        },
      },
      reset,
      history,
    });
  };
};

export default useUpdateExtras;
