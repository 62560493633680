import * as React from "react";
import PropTypes from "prop-types";

function SvgArrowRight({ rotate, ...rest }) {
  return (
    <svg
      style={{ transform: `rotate(${rotate}deg)` }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 19L5 12M5 12L12 5M5 12L20 12"
        stroke="#151209"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgArrowRight.propTypes = {
  rotate: PropTypes.number,
};

export default SvgArrowRight;
