import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { QueryString, getLngLocalStorage } from "@tint_fe/helpers";

import sharedEn from "@tint_fe/helpers/translations/en.json";
import sharedDe from "@tint_fe/helpers/translations/de.json";
import sharedEs from "@tint_fe/helpers/translations/es.json";
import sharedFr from "@tint_fe/helpers/translations/fr.json";
import sharedHe from "@tint_fe/helpers/translations/he.json";
import sharedIt from "@tint_fe/helpers/translations/it.json";

import en from "./translations/en.json";
import de from "./translations/de.json";
import es from "./translations/es.json";
import fr from "./translations/fr.json";
import he from "./translations/he.json";
import it from "./translations/it.json";

const resources = {
  en: {
    translation: { ...en, ...sharedEn },
  },
  de: {
    translation: { ...de, ...sharedDe },
  },
  es: {
    translation: { ...es, ...sharedEs },
  },
  fr: {
    translation: { ...fr, ...sharedFr },
  },
  he: {
    translation: { ...he, ...sharedHe },
  },
  it: {
    translation: { ...it, ...sharedIt },
  },
};

const { language_code } = QueryString.parse(document.location.search);

const defaultLng = Object.keys(resources).includes(language_code) ? language_code : getLngLocalStorage() || "en";

window.__localeId__ = defaultLng;

i18n.use(initReactI18next).init({
  lng: defaultLng,
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
