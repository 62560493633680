import React, { useState, useEffect } from "react";
import T from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { useFormContext } from "react-hook-form";

import { parseClasses } from "@tint_fe/helpers";
import { Button } from "../../components";
import Document from "./Document";
import { getDocuments } from "./helpers";

const defVal = {
  first_name: "",
  last_name: "",
  date_of_birth: null,
  expiry_date: null,
  start_date: null,
  nationality: null,
  number: "",
};

const Documents = ({ order, paxQuantity, singleProduct }) => {
  const { mainCls, elCls } = parseClasses({ base: "documents" });
  const [documents, setDocuments] = useState([]);
  const { getValues, setValue } = useFormContext();

  const addDocument = () => {
    setDocuments((prevValue) => [...prevValue, { key: uuidV4() }]);
  };

  const removeDocument = ({ key }, index) => {
    const products = { ...getValues("products") };

    Object.keys(products).forEach((el) => {
      const prodIndex = products[el].findIndex((x) => x === key);

      if (prodIndex !== -1) {
        setValue(`products.${el}.${prodIndex}`, null);
      }
    });

    setValue(`documents.${index}`, defVal);

    setDocuments((prevValue) => {
      const newValues = prevValue.filter((el) => el.key !== key);

      return newValues;
    });
  };

  useEffect(() => {
    if (order.product_items) {
      setDocuments(getDocuments(order, paxQuantity));
    }
  }, []);

  return (
    <div className={mainCls}>
      {documents?.map((item, index) => (
        <Document
          key={item.key}
          singleProduct={singleProduct}
          document={{ ...item, number: index, name: `documents.${index}` }}
          {...(documents.length > paxQuantity && { onRemove: () => removeDocument(item, index) })}
        />
      ))}
      {!singleProduct && (
        <Button className={elCls("btn")} ghost onClick={addDocument}>
          Add document
        </Button>
      )}
    </div>
  );
};

Documents.propTypes = {
  order: T.object,
  paxQuantity: T.number,
  singleProduct: T.bool,
};

export default Documents;
