import React from "react";
import { omit } from "lodash";

import { FeedbackPage } from "@tint_fe/ui";
import { QueryString } from "@tint_fe/helpers";

import { useHistoryNavigation } from "../../../hooks";
import { COMPLETED } from "../../router";

const BookingFeedbackPage = () => {
  const { replace } = useHistoryNavigation();
  const search = QueryString.parse(document.location.search);

  const onSuccess = () => {
    replace({
      pathname: COMPLETED,
      search: QueryString.stringify(omit(search, ["source", "rating"])),
    });
  };

  return (
    <FeedbackPage
      onSuccess={onSuccess}
      product_item_id={Number(search.product_item_id)}
      rating={Number(search.rating)}
    />
  );
};

export default BookingFeedbackPage;
