import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

const ExternalMethodInfo = ({ baseClassName, name, caption }) => {
  const { elCls } = parseClasses({ base: baseClassName });
  const { t } = useTranslation();

  return (
    <div className={elCls("info")}>
      <h4 className={elCls("info-title", "mg-0 mg-b-3 p4")}>Continue using {name}</h4>
      <p className="mg-0  p2">{t("paymentFrom.externalMethodInfo", { name })}</p>
      {caption && (
        <p className={elCls("info-caption", "mg-0 mg-t-6 p1")}>{t("paymentFrom.externalMethodInfo.caption")}</p>
      )}
    </div>
  );
};

ExternalMethodInfo.propTypes = {
  baseClassName: T.string,
  name: T.string.isRequired,
  caption: T.string,
};

export default ExternalMethodInfo;
