import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  validationRule,
  dates,
  getExtremeDate,
  getMinDateTourAvailability,
  checkIsDateAvailableThroughSupplier,
} from "@tint_fe/helpers";
import { DatePickerInput } from "@tint_fe/ui";

import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation, useReduxAction } from "../../../../../hooks";
import { PACKAGE_FORMAT_DATE, TOUR_DETAILS_FORM } from "../../../../../helpers/constants";
import { getTourAvailabilityAction } from "../../../../../redux/product/productActions";

import useHandleChangeTourAvailability from "../../hooks/useHandleChangeTourAvailability";
import useBackToCheckoutStep from "../../hooks/useBackToCheckoutStep";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";

const ReservationDetailsDatePicker = () => {
  const { control, reset, setValue, getValues } = useFormContext();

  const getTourAvailability = useReduxAction(getTourAvailabilityAction);
  const backToCheckoutStep = useBackToCheckoutStep();
  const handleChangeTourAvailability = useHandleChangeTourAvailability({ setValue, getValues });
  const updateTourPrice = useUpdateTourPrice();

  const { t } = useTranslation();

  const availableDates = useSelector((state) => state.product.availableDates);

  const checkIsDateDisabled = (date) => checkIsDateAvailableThroughSupplier(date, availableDates);
  const minDate = useMemo(() => getExtremeDate(availableDates, "start_time"), [availableDates]);
  const minDateWithOverrides = useMemo(
    () => availableDates && getMinDateTourAvailability(availableDates, minDate, checkIsDateDisabled),
    [availableDates],
  );

  const handleChangeStartDate = (date) => {
    getTourAvailability({
      date: dates.format(date, PACKAGE_FORMAT_DATE, false),
      onSuccess: ({ defaultLang, ...data }) => {
        reset({
          ...TOUR_DETAILS_FORM,
          start_date: date,
          ...(defaultLang?.code && { language_code: defaultLang.code }),
        });
        handleChangeTourAvailability(data);
        updateTourPrice({ formData: {} }, { preventPriceBands: true, preventSendingProductItems: true });
      },
    });

    backToCheckoutStep();

    return date;
  };

  return (
    <ControllerInput
      control={control}
      name="start_date"
      render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
        <DatePickerInput
          label={t("formField.chooseDate")}
          placeholder={t("formField.selectDate")}
          classNameWrapper="form-group--wrapper"
          availableFrom={minDate}
          minDate={minDateWithOverrides}
          defaultPickerValue={value || minDateWithOverrides}
          disabledDate={checkIsDateDisabled}
          errors={error?.message}
          onChange={(date) => onChange(handleChangeStartDate(date))}
          value={value}
          {...rest}
        />
      )}
      rules={{ ...validationRule.required("formField.chooseDate") }}
    />
  );
};

export default ReservationDetailsDatePicker;
