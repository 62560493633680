import React, { useState, useEffect } from "react";
import T from "prop-types";

import { useIsLogged } from "@tint_fe/hooks";
import { parseClasses, join } from "@tint_fe/helpers";

import CardNumberInput from "./CardNumberInput";
import DateInput from "./DateInput";
import CvvInput from "./CvvInput";
import CardHolderInput from "./CardHolderInput";
import SelectPaymentCard from "./SelectPaymentCard";
import SavePaymentCard from "./SavePaymentCard";
import PartialPayment from "./PartialPayment";

const CreditCardPaymentMethod = ({
  control,
  partial_payments_enabled,
  partial_payment_amount,
  second_payment_charge_date,
  split_payment_enabled,
  split_payment_amount,
  cards = [],
  onPartialPayment,
  baseClassName,
  chargeCurrency,
}) => {
  const isLogged = useIsLogged();

  const [useNewCard, setUseNewCard] = useState(cards?.length === 0 || !isLogged);

  const { elCls } = parseClasses({ base: baseClassName });

  const onUseAnotherCard = (value) => {
    !value ? setUseNewCard(true) : setUseNewCard(false);
  };

  useEffect(() => {
    setUseNewCard(cards?.length === 0 || !isLogged);
  }, [isLogged, cards]);

  return (
    <>
      {(partial_payments_enabled || split_payment_enabled) && (
        <PartialPayment
          onPartialPayment={onPartialPayment}
          partial_payments_enabled={partial_payments_enabled}
          partial_payment_amount={partial_payment_amount}
          split_payment_enabled={split_payment_enabled}
          second_payment_charge_date={second_payment_charge_date}
          className={join(["w-100", "mg-b-4"], " ")}
          control={control}
          chargeCurrency={chargeCurrency}
          split_payment_amount={split_payment_amount}
        />
      )}
      {cards?.length > 0 && isLogged && (
        <SelectPaymentCard
          classNameWrapper={elCls("select-card")}
          control={control}
          cards={cards}
          onUseAnotherCard={onUseAnotherCard}
        />
      )}
      {useNewCard && (
        <>
          <div className={elCls("first-row")}>
            <CardNumberInput classNameWrapper={elCls("card-number")} control={control} />
            <DateInput classNameWrapper={elCls("date", "mg-r-4")} control={control} />
            <CvvInput classNameWrapper={elCls("cvv")} control={control} />
          </div>
          <CardHolderInput classNameWrapper={elCls("holder", isLogged && "mg-b-4")} control={control} />
          {isLogged && <SavePaymentCard className="mg-b-4 w-100" control={control} />}
        </>
      )}
    </>
  );
};

CreditCardPaymentMethod.propTypes = {
  control: T.object.isRequired,
  partial_payments_enabled: T.bool,
  partial_payment_amount: T.number,
  split_payment_enabled: T.bool,
  second_payment_charge_date: T.oneOfType([T.string, T.instanceOf(Date)]),
  cards: T.array,
  onPartialPayment: T.func,
  baseClassName: T.string,
  chargeCurrency: T.string,
  split_payment_amount: T.number,
};

export default CreditCardPaymentMethod;
