class FreshChat {
  loadChat({ onLoad, elId = "Freshchat-js-sdk" } = {}) {
    const freshChat = document.createElement("script");
    freshChat.id = elId;
    freshChat.async = !0;
    freshChat.src = "https://wchat.eu.freshchat.com/js/widget.js";
    onLoad && (freshChat.onload = onLoad);
    document.head.appendChild(freshChat);
  }

  initFreshChat(settings, user) {
    window.fcWidget?.init({
      token: process.env.REACT_APP_FRESHCHAT_TOKEN,
      host: "https://wchat.eu.freshchat.com",
      ...user,
      ...settings,
    });

    window.fcWidget.user.get(function () {
      window.fcWidget.user.setProperties(user);
    });
  }

  isLoaded() {
    return !!window.fcWidget;
  }

  onLoaded(cb = () => null) {
    window.fcWidget?.on("widget:loaded", cb);
  }

  onOpen(cb = () => null) {
    window.fcWidget?.on("widget:opened", cb);
  }

  onClose(cb = () => null) {
    window.fcWidget?.on("widget:closed", cb);
  }

  clearUser() {
    window.fcWidget?.user.clear();
  }

  open() {
    if (this.isInitialized()) {
      window.fcWidget.open();
    }
  }

  isInitialized() {
    return window.fcWidget && window.fcWidget.isInitialized();
  }

  destroy() {
    if (this.isInitialized()) {
      window.fcWidget.destroy();
    }
  }

  onNotify(cb) {
    if (this.isInitialized()) {
      window.fcWidget.on("unreadCount:notify", cb);
    }
  }
}

export default new FreshChat();
