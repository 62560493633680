import React from "react";
import T from "prop-types";
import Select, { components } from "react-select";

import { parseClasses, setLngLocalStorage } from "@tint_fe/helpers";
import { googleAnalyticsChangeLng } from "@tint_fe/helpers/services/google-analytics";

import { GbFlag, ItalyFlag, SpainFlag, GermanyFlag, IsraelFlag, FranceFlag } from "../../assets/icons";
import { useTranslation } from "@tint_fe/hooks";

const getCustomStyles = ({ menuPosition = { right: 0 } }) => ({
  menu: (provided) => ({
    ...provided,
    ...(menuPosition || {}),
    width: 188,
  }),
  valueContainer: (provided) => ({
    ...provided,
    visibility: "hidden",
    position: "absolute",
    zIndex: -1,
  }),
});

const defaultLangs = [
  {
    value: "en",
    label: "English",
    icon: GbFlag,
    shortLabel: "eng",
  },
  {
    value: "de",
    label: "Deutsche",
    icon: GermanyFlag,
    shortLabel: "deu",
  },
  {
    value: "fr",
    label: "Français",
    icon: FranceFlag,
    shortLabel: "fra",
  },
  {
    value: "it",
    label: "Italiano",
    icon: ItalyFlag,
    shortLabel: "ita",
  },
  {
    value: "es",
    label: "Español",
    icon: SpainFlag,
    shortLabel: "spa",
  },
  {
    value: "he",
    label: "עברית",
    icon: IsraelFlag,
    shortLabel: "heb",
  },
];

const Option = ({ data, ...rest }) => {
  const Flag = data.icon;

  return (
    <components.Option {...rest} className={"select-lang__option"}>
      <Flag className="flag" />
      <span className="main-text">{data.label} </span>
    </components.Option>
  );
};

Option.propTypes = {
  children: T.node,
  data: T.object,
};

const Control = ({ selectProps, children, ...rest }) => {
  const { icon: Flag, shortLabel } = selectProps.value;

  return (
    <components.Control selectProps={selectProps} className={"select-lang__control"} {...rest}>
      <Flag className="flag" />
      <span className="select-lang__value-text">{shortLabel}</span>
      {children}
    </components.Control>
  );
};

Control.propTypes = {
  selectProps: T.object,
  children: T.node,
};

const SelectLang = ({ onChange, hideIndicatorsContainer, className, menuPosition, langs }) => {
  const { i18n, isRtl } = useTranslation();

  const { mainCls } = parseClasses({
    base: "select-lang",
    classes: [isRtl && "rtl"],
    additional: [className, "select"],
  });

  const customStyles = getCustomStyles({ menuPosition });

  const handleChange = ({ value }) => {
    googleAnalyticsChangeLng(value);
    setLngLocalStorage(value);
    window.__localeId__ = value;
    i18n.changeLanguage(value);
    onChange && onChange();
  };

  return (
    <div className={mainCls}>
      <Select
        isRtl={isRtl}
        inputId="lang"
        classNamePrefix="select-lang"
        styles={customStyles}
        defaultValue={langs.find(({ value }) => value === i18n.language)}
        options={langs}
        onChange={handleChange}
        // menuIsOpen
        components={{
          Option,
          Control,
          IndicatorSeparator: () => null,
          IndicatorsContainer: hideIndicatorsContainer ? () => null : components.IndicatorsContainer,
          Input: (inputProps) => <components.Input {...inputProps} readOnly />,
        }}
      />
    </div>
  );
};

SelectLang.defaultProps = {
  langs: defaultLangs,
};

SelectLang.propTypes = {
  onChange: T.func,
  className: T.string,
  hideIndicatorsContainer: T.bool,
  menuPosition: T.object,
  langs: T.array,
};

export default SelectLang;
