import * as React from "react";

function SvgUserTraveler(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="4.5" r="3.5" fill="#56483A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.68942 9H4.81188C4.65383 9 4.49803 9.03746 4.35726 9.10931L1.63614 10.4982C0.632022 11.0107 0 12.0429 0 13.1703V16H16V13.1703C16 12.0429 15.368 11.0107 14.3639 10.4982L11.6427 9.10931C11.502 9.03746 11.3462 9 11.1881 9H10.3106C9.63674 10.0882 8.6238 11 8 11C7.3762 11 6.36326 10.0882 5.68942 9Z"
        fill="#56483A"
      />
    </svg>
  );
}

export default SvgUserTraveler;
