import * as React from "react";
const TimerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#219653"
      fillRule="evenodd"
      d="M5.332.667a.667.667 0 1 0 0 1.333h1.333v.333a.667.667 0 0 0 1.334 0V2h1.333a.667.667 0 0 0 0-1.333h-4Zm6.667 8.666a4.667 4.667 0 1 1-9.334 0 4.667 4.667 0 0 1 9.334 0Zm1.333 0a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM12.194 3.53c.26-.26.682-.26.943 0l.666.666a.667.667 0 1 1-.942.943l-.667-.667a.667.667 0 0 1 0-.942ZM5.48 6.562A3.333 3.333 0 0 1 7.332 6v3.333h3.333A3.333 3.333 0 1 1 5.48 6.562Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TimerIcon;
