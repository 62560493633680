import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useCustomerDataValidForPayment = (customer = {}) => {
  const { first_name = "", last_name = "", email = "", phone = "", citizenship = "" } = customer;
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const isNotSet = (field) => field === "";

  useEffect(() => {
    if ([first_name, last_name, email, phone, citizenship].some(isNotSet)) {
      replace(`${pathname.replace("payment", "customer")}`);
    }
  }, [first_name, last_name, email, phone, citizenship]);
};

export default useCustomerDataValidForPayment;
