import * as React from "react";

function ShoppingBasketSvg(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99979 2C4.56936 2 4.18722 2.27543 4.0511 2.68377L2.27903 8H2.21958C1.58853 8 1.11524 8.57733 1.239 9.19612L2.839 17.1961C2.93248 17.6635 3.3429 18 3.81958 18H16.18C16.6567 18 17.0671 17.6635 17.1606 17.1961L18.7606 9.19612C18.8843 8.57732 18.411 8 17.78 8H17.7205L15.9485 2.68377C15.8124 2.27543 15.4302 2 14.9998 2H4.99979ZM15.6124 8L14.279 4H5.72055L4.38721 8H15.6124ZM4.99977 12C4.99977 11.4477 5.44749 11 5.99977 11C6.55206 11 6.99977 11.4477 6.99977 12V14C6.99977 14.5523 6.55206 15 5.99977 15C5.44749 15 4.99977 14.5523 4.99977 14V12ZM9.99977 11C9.44749 11 8.99977 11.4477 8.99977 12V14C8.99977 14.5523 9.44749 15 9.99977 15C10.5521 15 10.9998 14.5523 10.9998 14V12C10.9998 11.4477 10.5521 11 9.99977 11ZM12.9998 12C12.9998 11.4477 13.4475 11 13.9998 11C14.5521 11 14.9998 11.4477 14.9998 12V14C14.9998 14.5523 14.5521 15 13.9998 15C13.4475 15 12.9998 14.5523 12.9998 14V12Z"
        fill="#56483A"
      />
    </svg>
  );
}

export default ShoppingBasketSvg;
