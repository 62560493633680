import React from "react";
import { useSelector } from "react-redux";
import T from "prop-types";
import { v4 as uuidV4 } from "uuid";

import { Rooms } from "@tint_fe/ui";
import { useReduxAction } from "../../hooks";
import { toggleContactUsModalAction } from "../../redux/global/globalActions";
import { MAX_KIDS_AGE, bookingTypes, integrationsNames } from "../../helpers/constants";

const RoomsForm = ({ rooms, handleChange, childrenNotAvailable, minChildAge, maxChildAge, isHotel }) => {
  const toggleContactUsModal = useReduxAction(toggleContactUsModalAction);
  const { bookingType, integration } = useSelector((state) => state.global);
  const integrationName = integration?.name;

  const isSP = bookingType === bookingTypes.SMART_PACKAGE;
  const isJP = integrationName === integrationsNames.TOURIST_JAPAN;

  const handleAddRoom = () => {
    handleChange([...rooms, { id: uuidV4(), adults: 1, children: [] }]);
  };

  const handleRemoveRoom = (id) => {
    handleChange(rooms.filter((el) => el.id !== id));
  };

  const handleUpdateRoom = (room) => {
    return handleChange(rooms.map((el) => (el.id === room.id ? { ...el, ...room } : el)));
  };

  return (
    <Rooms
      rooms={rooms}
      handleChange={handleUpdateRoom}
      handleRemove={handleRemoveRoom}
      handleAdd={handleAddRoom}
      childrenNotAvailable={childrenNotAvailable}
      toggleContactUsModal={toggleContactUsModal}
      preventScrollIntoView={isSP}
      minChildAge={minChildAge}
      maxChildAge={maxChildAge}
      isHotel={isHotel}
      isJP={isJP}
    />
  );
};

RoomsForm.defaultProps = {
  minChildAge: 0,
  maxChildAge: MAX_KIDS_AGE,
};

RoomsForm.propTypes = {
  rooms: T.array,
  handleChange: T.func,
  childrenNotAvailable: T.bool,
  minChildAge: T.number,
  maxChildAge: T.number,
  isHotel: T.bool,
};

export default RoomsForm;
