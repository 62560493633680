import React, { useEffect } from "react";
import T from "prop-types";
import { v4 as uuid } from "uuid";

import { useRequest } from "@tint_fe/hooks";
import { getSuggestedProductsApi } from "@tint_fe/api";
import { parseClasses } from "@tint_fe/helpers";
import { Loader, SuggestedCard } from "../../components";
import { googleAnalyticsSuggestedClick } from "@tint_fe/helpers/services/google-analytics";

const SuggestedProducts = ({ orderNumber, productName }) => {
  const { mainCls, elCls } = parseClasses({ base: "suggested-products" });

  const { callToApi, loading, res, error } = useRequest({
    apiCall: getSuggestedProductsApi,
    initialLoading: true,
    onSuccess: (data) => {
      return data.map(({ display_name, external_link, image_url }) => ({
        id: uuid(),
        img: image_url,
        link: external_link,
        name: display_name,
      }));
    },
  });

  useEffect(() => {
    if (orderNumber) {
      callToApi({ number: orderNumber });
    }
  }, [orderNumber]);

  const trackAnalytics = (item) => {
    googleAnalyticsSuggestedClick({ orderNumber, productName: item?.name, clickSource: "completed_order" });
  };

  if ((!res?.length && !loading) || error) {
    return null;
  }

  return (
    <div className={mainCls}>
      {loading || !res ? (
        <Loader data-testid="suggested-loader" />
      ) : (
        <>
          <p className="p6 bold mg-v-5">People who booked {productName ? productName : "this journey"} also chose</p>
          <ul className={elCls("content")}>
            {res.map(({ link, id, ...rest }) => (
              <SuggestedCard
                key={id}
                link={link || document.location.origin}
                onClick={() => trackAnalytics({ link, id, ...rest })}
                {...rest}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

SuggestedProducts.propTypes = {
  productName: T.string,
  orderNumber: T.string,
  buildType: T.string,
};

export default SuggestedProducts;
