import * as React from "react";

function ApplePayBtnIcon(props) {
  return (
    <svg width="50" height="32" viewBox="0 0 50 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M45.5223 0H4.47773C4.30679 0 4.13554 0 3.9649 0.000996555C3.82065 0.00202331 3.67675 0.00362383 3.53284 0.00754966C3.21898 0.0160355 2.90243 0.0345472 2.5925 0.0902335C2.27762 0.146886 1.9846 0.239294 1.69875 0.384791C1.41773 0.52766 1.16045 0.71453 0.93749 0.937516C0.714433 1.1605 0.527509 1.41728 0.384627 1.69852C0.239056 1.98429 0.14656 2.27731 0.0902834 2.59234C0.0342814 2.90227 0.0156403 3.21866 0.00718217 3.53209C0.00331559 3.67596 0.00165719 3.81982 0.000717733 3.96366C-0.000279119 4.13461 5.31654e-05 4.30545 5.31654e-05 4.4767V27.5236C5.31654e-05 27.6949 -0.000279119 27.8654 0.000717733 28.0367C0.00165719 28.1805 0.00331559 28.3244 0.00718217 28.4683C0.0156403 28.7814 0.0342814 29.0978 0.0902834 29.4077C0.14656 29.7228 0.239056 30.0157 0.384627 30.3015C0.527509 30.5827 0.714433 30.8399 0.93749 31.0625C1.16045 31.2859 1.41773 31.4727 1.69875 31.6152C1.9846 31.7611 2.27762 31.8535 2.5925 31.9102C2.90243 31.9655 3.21898 31.9843 3.53284 31.9928C3.67675 31.9961 3.82065 31.998 3.9649 31.9987C4.13554 32 4.30679 32 4.47773 32H45.5223C45.693 32 45.8642 32 46.0348 31.9987C46.1787 31.998 46.3226 31.9961 46.4672 31.9928C46.7804 31.9843 47.0969 31.9655 47.4076 31.9102C47.722 31.8535 48.0151 31.7611 48.301 31.6152C48.5823 31.4727 48.8388 31.2859 49.0623 31.0625C49.285 30.8399 49.4719 30.5827 49.6151 30.3015C49.761 30.0157 49.8534 29.7228 49.9094 29.4077C49.9655 29.0978 49.9837 28.7814 49.9921 28.4683C49.9961 28.3244 49.998 28.1805 49.9987 28.0367C50 27.8654 50 27.6949 50 27.5236V4.4767C50 4.30545 50 4.13461 49.9987 3.96366C49.998 3.81982 49.9961 3.67596 49.9921 3.53209C49.9837 3.21866 49.9655 2.90227 49.9094 2.59234C49.8534 2.27731 49.761 1.98429 49.6151 1.69852C49.4719 1.41728 49.285 1.1605 49.0623 0.937516C48.8388 0.71453 48.5823 0.52766 48.301 0.384791C48.0151 0.239294 47.722 0.146886 47.4076 0.0902335C47.0969 0.0345472 46.7804 0.0160355 46.4672 0.00754966C46.3226 0.00362383 46.1787 0.00202331 46.0348 0.000996555C45.8642 0 45.693 0 45.5223 0Z"
        fill="black"
      />
      <path
        d="M45.5223 1.06659L46.0272 1.06756C46.164 1.06852 46.3007 1.07 46.4383 1.07375C46.6775 1.08021 46.9574 1.09316 47.2183 1.13991C47.445 1.18074 47.6352 1.24283 47.8177 1.33572C47.9979 1.42725 48.163 1.5472 48.3072 1.6911C48.4519 1.83596 48.572 2.00127 48.6648 2.18339C48.7572 2.36444 48.8189 2.55366 48.8595 2.78202C48.9061 3.03998 48.919 3.32052 48.9256 3.56118C48.9293 3.69701 48.9311 3.83284 48.9317 3.97191C48.933 4.14008 48.933 4.30817 48.933 4.47668V27.5236C48.933 27.6921 48.933 27.8599 48.9317 28.0317C48.9311 28.1675 48.9293 28.3033 48.9255 28.4394C48.919 28.6797 48.9061 28.9601 48.8589 29.2211C48.8189 29.4463 48.7572 29.6355 48.6643 29.8175C48.5718 29.9992 48.4518 30.1643 48.3078 30.3082C48.1628 30.4532 47.9983 30.5728 47.8159 30.6652C47.6348 30.7576 47.4449 30.8196 47.2204 30.86C46.9542 30.9074 46.6626 30.9205 46.443 30.9264C46.3048 30.9296 46.1673 30.9314 46.0265 30.9321C45.8586 30.9334 45.6902 30.9334 45.5223 30.9334H4.47767C4.47543 30.9334 4.47326 30.9334 4.47099 30.9334C4.30503 30.9334 4.13874 30.9334 3.96976 30.9321C3.83198 30.9314 3.69451 30.9296 3.56159 30.9266C3.33697 30.9205 3.04519 30.9075 2.78121 30.8603C2.55474 30.8196 2.36489 30.7576 2.18134 30.664C2.00074 30.5724 1.83631 30.4529 1.69129 30.3077C1.54738 30.1641 1.42779 29.9995 1.33532 29.8175C1.24276 29.6358 1.18084 29.4459 1.14012 29.218C1.09305 28.9575 1.08013 28.6782 1.07366 28.4396C1.06998 28.3029 1.06844 28.1663 1.06756 28.0305L1.06689 27.6295L1.06692 27.5236V4.47668L1.06689 4.3708L1.06753 3.97064C1.06844 3.83399 1.06998 3.69737 1.07366 3.56084C1.08013 3.32194 1.09305 3.04255 1.14051 2.77988C1.18087 2.55402 1.24276 2.36419 1.3358 2.18152C1.42754 2.00096 1.54735 1.83614 1.69201 1.69155C1.8361 1.54744 2.00107 1.42764 2.18282 1.33524C2.3644 1.2428 2.55462 1.18074 2.78109 1.14C3.04202 1.09313 3.32208 1.08021 3.56192 1.07372C3.69864 1.07 3.83536 1.06852 3.97106 1.06759L4.47767 1.06659H45.5223Z"
        fill="white"
      />
      <path
        d="M13.6495 10.763C14.0776 10.2276 14.3682 9.50886 14.2915 8.77435C13.6648 8.80551 12.9001 9.18768 12.4573 9.72342C12.0597 10.1822 11.7079 10.9311 11.7996 11.6348C12.5031 11.6958 13.206 11.2832 13.6495 10.763Z"
        fill="black"
      />
      <path
        d="M14.2836 11.7722C13.2619 11.7114 12.3933 12.3519 11.9054 12.3519C11.4172 12.3519 10.6701 11.8029 9.86202 11.8177C8.81026 11.8331 7.83435 12.4276 7.30063 13.3731C6.20285 15.2646 7.01092 18.0704 8.07846 19.611C8.59687 20.3731 9.22166 21.2123 10.0449 21.1822C10.8227 21.1517 11.1276 20.6787 12.0731 20.6787C13.0179 20.6787 13.2925 21.1822 14.1159 21.1669C14.9698 21.1516 15.5036 20.4044 16.022 19.6415C16.6167 18.7727 16.8602 17.9338 16.8755 17.8877C16.8602 17.8725 15.229 17.2469 15.2139 15.3711C15.1985 13.8004 16.4945 13.0534 16.5555 13.007C15.8236 11.9249 14.68 11.8029 14.2836 11.7722Z"
        fill="black"
      />
      <path
        d="M23.1792 9.64661C25.3998 9.64661 26.9461 11.1768 26.9461 13.4046C26.9461 15.6404 25.368 17.1786 23.1236 17.1786H20.665V21.0872H18.8887V9.64661H23.1792V9.64661ZM20.665 15.688H22.7032C24.2498 15.688 25.13 14.8556 25.13 13.4126C25.13 11.9697 24.2498 11.1451 22.7111 11.1451H20.665V15.688Z"
        fill="black"
      />
      <path
        d="M27.4102 18.7167C27.4102 17.2578 28.5284 16.3619 30.5112 16.2509L32.7951 16.1162V15.474C32.7951 14.5464 32.1685 13.9914 31.1219 13.9914C30.1303 13.9914 29.5117 14.467 29.3612 15.2124H27.7433C27.8385 13.7059 29.1231 12.5959 31.1852 12.5959C33.2075 12.5959 34.5001 13.6663 34.5001 15.3392V21.0872H32.8584V19.7156H32.819C32.3353 20.6433 31.2804 21.2299 30.186 21.2299C28.5522 21.2299 27.4102 20.2151 27.4102 18.7167ZM32.7951 17.9635V17.3055L30.741 17.4323C29.7179 17.5037 29.1391 17.9556 29.1391 18.6691C29.1391 19.3985 29.7418 19.8742 30.6617 19.8742C31.8592 19.8742 32.7951 19.0496 32.7951 17.9635Z"
        fill="black"
      />
      <path
        d="M36.0502 24.1556V22.768C36.1768 22.7997 36.4623 22.7997 36.6052 22.7997C37.3982 22.7997 37.8265 22.4668 38.0881 21.6105C38.0881 21.5946 38.2389 21.1031 38.2389 21.0952L35.2253 12.7466H37.0809L39.1907 19.5333H39.2222L41.332 12.7466H43.1402L40.0152 21.5232C39.3018 23.5451 38.4769 24.1952 36.748 24.1952C36.6052 24.1952 36.1768 24.1793 36.0502 24.1556Z"
        fill="black"
      />
    </svg>
  );
}

export default ApplePayBtnIcon;
