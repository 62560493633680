import { useFormContext } from "react-hook-form";
import { SPCheckoutFormFields } from "../helpers";

const { ADULTS, CHILDREN } = SPCheckoutFormFields;

const useGetPersons = () => {
  const { getValues } = useFormContext();

  return () => {
    const adults = getValues(ADULTS);
    const children = getValues(CHILDREN);

    return adults + children;
  };
};

export default useGetPersons;
