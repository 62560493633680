import * as types from "./smartPackageTypes";
import * as sharedTypes from "@tint_fe/redux-utils/src/global/globalActionTypes";

export const INIT_STATE = {
  fetching: false,
  data: {},
  accommodation: [],
  charge_currency: null,
  selectedHotels: [],
  updatingAvailableDate: false,
  error: {},
  replaceHotelId: null,
  replaceProductItemId: null,
};

const smartPackage = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case types.SMART_PACKAGE_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case types.GET_SMART_PACKAGE_SUCCESS:
      return {
        ...state,
        error: {},
        data: {
          ...state.data,
          ...payload.package,
        },
        gettingSmartPackage: false,
      };
    case types.UPDATE_SELECTED_HOTELS:
      return {
        ...state,
        selectedHotels: [...payload],
      };
    case types.UPDATE_SMART_PACKAGE_FETCHING:
      return {
        ...state,
        updatingAvailableDate: true,
      };

    case types.GET_SMART_PACKAGE_ACCOMMODATION_SUCCESS:
      return {
        ...state,
        error: {},
        accommodation: [...payload.accommodation],
        charge_currency: payload?.charge_currency,
        data: {
          ...state.data,
          accomodation_expire_at: payload.expire_at,
          prices: payload.prices,
          sequence_code: payload.sequence_code,
          cashed_expire_at: payload.cashed_expire_at,
        },
        fetching: false,
      };
    case types.UPDATE_SMART_PACKAGE_EXPIRED_AT:
      return {
        ...state,
        data: {
          ...state.data,
          accomodation_expire_at: payload,
        },
      };
    case types.SET_REPLACE_HOTEL_ID:
      return {
        ...state,
        replaceHotelId: payload.replaceHotelId,
        replaceProductItemId: payload.replaceProductItemId,
      };
    case types.SET_SMART_PACKAGE_ERROR:
    case types.GET_SMART_PACKAGE_FAILED:
    case types.GET_SMART_PACKAGE_ACCOMMODATION_FAILED:
      return {
        ...state,
        error: { ...payload },
        gettingSmartPackage: false,
        fetching: false,
      };
    case sharedTypes.CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};

export default smartPackage;
