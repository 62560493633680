import React from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import FlightNumber from "../../../components/Form/FlightNumber";
import { SPCheckoutFormFields } from "../helpers";

const SPFlightNumber = ({ label, name, dependOn = SPCheckoutFormFields.START_LOCATION }) => {
  const SPlocations = useSelector(({ smartPackage }) => {
    return dependOn === SPCheckoutFormFields.START_LOCATION
      ? smartPackage.data.start_locations
      : smartPackage.data.end_locations;
  });
  const { control, watch, setValue } = useFormContext();

  const locationId = watch(dependOn);
  const locationName = locationId ? SPlocations?.find((el) => el.id === locationId).name : "";

  return (
    <FlightNumber
      name={name}
      label={label}
      control={control}
      dependOnLocation={locationName}
      onSelectNotAirport={() => setValue(name, "")}
    />
  );
};

SPFlightNumber.propTypes = {
  label: T.string.isRequired,
  name: T.string.isRequired,
  dependOn: T.oneOf([SPCheckoutFormFields.START_LOCATION, SPCheckoutFormFields.END_LOCATION]),
};

export default SPFlightNumber;
