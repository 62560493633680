import { useSelector } from "react-redux";

import { dates } from "@tint_fe/helpers";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import { updateRatesAction } from "../../../../redux/global/globalActions";
import { createSmartPackageOrderRequest } from "../../../../redux/order/orderActions";
import { selectAccommodationExpiredTime } from "../../../../redux/smartPackage/smartPackageSelectors";
import { updateRatesTypes } from "../../../../helpers/constants";
import useGetSPCheckoutFromData from "./useGetSPCheckoutFromData";

const normalizeSelectedHotel = (hotels) => {
  return hotels.reduce((acc, el) => {
    return [
      ...acc,
      ...el.dates.map(({ from, to }) => ({
        start_at: from,
        end_at: to,
        product_id: el.product_id,
        city_id: el.city_id,
      })),
    ];
  }, []);
};

export const normalizeDataForCreateOrder = (orderDetails, selectedHotels) => {
  return {
    order: {
      ...orderDetails,
      package_start_date: dates.format(orderDetails.package_start_date, "yyyy-MM-dd"),
      rooms: {
        // eslint-disable-next-line no-unused-vars
        ...orderDetails.rooms.map(({ key, children, ...rest }) => ({
          ...rest,
          ...(children.length && { children }),
        })),
      },
      package_start_location_id: String(orderDetails.package_start_location_id),
      product_items_attributes: {
        // we destructuring array in an object because
        // we should send to API an object with keys like in array (0, 1, 2...)
        ...normalizeSelectedHotel(selectedHotels),
      },
    },
  };
};

const useCreateSPOrder = () => {
  const { push } = useHistoryNavigation();
  const updateRates = useReduxAction(updateRatesAction);
  const createSmartPackageOrder = useReduxAction(createSmartPackageOrderRequest);
  const getSPCheckoutFromData = useGetSPCheckoutFromData();

  const accommodationExpiredTime = useSelector(selectAccommodationExpiredTime);

  return (selectedHotels) => {
    const checkoutFormData = getSPCheckoutFromData();

    if (accommodationExpiredTime < new Date().getTime()) {
      updateRates({
        updateType: updateRatesTypes.SELECT_HOTEL,
        smartPackageBody: checkoutFormData,
      });

      return;
    }

    const normalizedData = normalizeDataForCreateOrder(checkoutFormData, selectedHotels);
    createSmartPackageOrder({ body: normalizedData, push });
  };
};

export default useCreateSPOrder;
