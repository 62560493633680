import React, { useEffect } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";

import { DatePickerInput } from "@tint_fe/ui";
import { checkCurrencyChar, dates, validationRule } from "@tint_fe/helpers";
import { useAvailableDatesForSmartPackage } from "@tint_fe/hooks";
import { useReduxAction, useTranslation } from "../../../../hooks";
import { SPCheckoutFormFields } from "../helpers";
import { omit } from "lodash";
import { api } from "@tint_fe/api";
import { setRefetchPricesDueCurrencyChange } from "../../../../redux/global/globalActions";

const SPDatePiker = ({ name }) => {
  const { control, watch, setValue } = useFormContext();
  const { id: packageId } = useSelector((state) => state.smartPackage.data);
  const formStartDate = watch(SPCheckoutFormFields.START_DATE);
  const shouldRefetchPrices = useSelector((state) => state.global.refetchPricesDueCurrencyChange);
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);

  const currency = api.currency;
  const currencyChar = checkCurrencyChar(currency);

  const { loading, startDate, handleGetAvailableDates, checkIsDisabled, currDateToSearch, minPrices } =
    useAvailableDatesForSmartPackage({
      packageId,
      preventGetAvailableDateOnInit: true,
      canUseRedux: true,
    });
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof formStartDate !== "undefined") {
      const initialData = formStartDate === null ? startDate : formStartDate;

      initialData && handleGetAvailableDates(initialData);
    }
  }, [startDate?.getDate()]);

  useEffect(() => {
    if (shouldRefetchPrices) {
      setRefetchPrices(false);
      handleGetAvailableDates(currDateToSearch);
    }
  }, [shouldRefetchPrices]);

  // TINT-4736: Cleanup disabled date after getAvailableDates
  useEffect(() => {
    const isDisabled = formStartDate ? checkIsDisabled(formStartDate) : false;
    const isSameMonth = dates.isSameMonth(formStartDate, currDateToSearch, false);

    if (isDisabled && !loading && isSameMonth) {
      setValue(SPCheckoutFormFields.START_DATE, null);
    }
  }, [formStartDate, loading]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
        return (
          <DatePickerInput
            label={t("formField.chooseDate")}
            placeholder={t("formField.selectDate")}
            classNameWrapper="form-group--wrapper"
            disabledDate={checkIsDisabled}
            processing={loading}
            errors={error?.message}
            onChange={loading ? null : onChange}
            onMonthChange={handleGetAvailableDates}
            onModeChange={handleGetAvailableDates}
            minPrices={minPrices}
            isMinPricesMode
            currencyChar={currencyChar}
            defaultPickerValue={value || startDate}
            availableFrom={new Date()}
            value={value}
            {...omit(rest, "ref")}
          />
        );
      }}
      rules={{ ...validationRule.required("formField.chooseDate") }}
    />
  );
};

SPDatePiker.propTypes = {
  name: T.string.isRequired,
};

export default SPDatePiker;
