import { createSelector } from "reselect";

export const selectHotelExpiredAt = createSelector(
  (state) => state.order?.data,
  (singleOrder) => singleOrder?.product_items?.[0].expire_at,
);

export const selectDiscountPercent = createSelector(
  (state) => state.global.price,
  (price) => {
    const item = price?.product_items?.[0]?.line_items.find((el) => !!Number(el.discount_percent));

    return Number(item?.discount_percent);
  },
);
