import React, { useMemo } from "react";
import T from "prop-types";

import { ArrowRight, Button, Carousel, Img, Star } from "@tint_fe/ui";
import { capitalizeLater, parseClasses } from "@tint_fe/helpers";
import SvgLocation from "../../../assets/icons/Location";

const generateRatingStars = (rating) => {
  const ratingNumber = Number(rating);
  if (!ratingNumber) {
    return null;
  }

  return (
    <>
      {Array.from(Array(ratingNumber)).map((_el, s_key) => (
        <Star key={s_key} height={16} width={16} className="mg-r-1" />
      ))}
    </>
  );
};

const ProductDetails = ({ product }) => {
  const { mainCls, elCls } = parseClasses({ base: "modal-extra-product-details" });

  const isHotel = product?.klass === "hotel";
  const isTour = product?.klass === "tour";
  const images = product?.images || [];

  const carouselImages = useMemo(() => {
    return images?.map((el) => {
      return <Img key={el?.alt_text + el?.image_url} className={elCls("img")} alt={el?.alt_text} src={el?.image_url} />;
    });
  }, [product?.images]);
  const starsRating = generateRatingStars(Number.parseInt(product?.rating?.replace("*", ""), 10));

  return (
    <div className={mainCls}>
      <div className={elCls("carousel-wrapper")}>
        {isHotel ? (
          <Carousel
            className={elCls("carousel")}
            config={{
              perView: 1,
              gap: 8,
            }}
            list={carouselImages}
          />
        ) : (
          <Img className={elCls("img")} alt={product?.cover_image} src={product?.cover_image} />
        )}
      </div>
      <div className={elCls("text-container")}>
        <div className={elCls("container-margin")}>
          {product?.display_name && (
            <span className={elCls("display-name", "second-font")}>{product?.display_name}</span>
          )}
          {React.isValidElement(starsRating) ? <div className={elCls("stars-inner")}>{starsRating}</div> : null}
          {product?.address && (
            <div className={elCls("address")}>
              <SvgLocation className="mg-r-2" />
              <span className={elCls("address-text")}>{capitalizeLater(product?.address)}</span>
            </div>
          )}
        </div>
        {isTour && !!product?.external_link && (
          <div className={elCls("button-container")}>
            <Button
              className={elCls("btn")}
              as={"a"}
              target="_blank"
              href={product?.external_link}
              nextIcon={<ArrowRight />}
            >
              See details
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

ProductDetails.propTypes = {
  product: T.object,
};

export default ProductDetails;
