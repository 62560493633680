import * as React from "react";
const FlagUS = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} fill="none" {...props}>
    <g clipPath="url(#a)">
      <rect width={20.533} height={14.667} x={1.333} y={0.667} fill="#fff" rx={2.2} />
      <path
        fill="#F93939"
        fillRule="evenodd"
        d="M21.866.667H1.333v.977h20.533V.667Zm0 1.955H1.333V3.6h20.533v-.978ZM1.333 4.578h20.533v.978H1.333v-.978Zm20.533 1.955H1.333v.978h20.533v-.978ZM1.333 8.49h20.533v.978H1.333v-.978Zm20.533 1.955H1.333v.978h20.533v-.978ZM1.333 12.4h20.533v.978H1.333V12.4Zm20.533 1.955H1.333v.978h20.533v-.978Z"
        clipRule="evenodd"
      />
      <path fill="#1A47B8" d="M1.333.667h8.8v6.844h-8.8z" />
      <g filter="url(#b)">
        <path
          fill="url(#c)"
          fillRule="evenodd"
          d="M5.244 2.133a.489.489 0 1 1-.978 0 .489.489 0 0 1 .978 0Zm-1.956 0a.489.489 0 1 1-.977 0 .489.489 0 0 1 .977 0Zm3.423.49a.489.489 0 1 0 0-.979.489.489 0 0 0 0 .978Zm2.444-.49a.489.489 0 1 1-.978 0 .489.489 0 0 1 .978 0ZM3.777 3.6a.489.489 0 1 0 0-.978.489.489 0 0 0 0 .978Zm2.445-.489a.489.489 0 1 1-.978 0 .489.489 0 0 1 .978 0Zm1.466.489a.489.489 0 1 0 0-.978.489.489 0 0 0 0 .978Zm1.467.489a.489.489 0 1 1-.978 0 .489.489 0 0 1 .978 0Zm-2.444.489a.489.489 0 1 0 0-.978.489.489 0 0 0 0 .978Zm-1.467-.49a.489.489 0 1 1-.978 0 .489.489 0 0 1 .978 0Zm-2.445.49a.489.489 0 1 0 0-.978.489.489 0 0 0 0 .978Zm1.467.489a.489.489 0 1 1-.978 0 .489.489 0 0 1 .978 0Zm1.467.489a.489.489 0 1 0 0-.978.489.489 0 0 0 0 .978Zm2.444-.49a.489.489 0 1 1-.978 0 .489.489 0 0 1 .978 0Zm.49 1.467a.489.489 0 1 0 0-.977.489.489 0 0 0 0 .977Zm-1.468-.489a.489.489 0 1 1-.977 0 .489.489 0 0 1 .977 0Zm-2.444.49a.489.489 0 1 0 0-.978.489.489 0 0 0 0 .977Zm-1.467-.49a.489.489 0 1 1-.977 0 .489.489 0 0 1 .977 0Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <path stroke="#fff" d="M1.167.5h17.667v15H1.167z" />
    <defs>
      <linearGradient id="c" x1={2.311} x2={2.311} y1={1.644} y2={6.533} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#F0F0F0" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M.667 0h18.667v16H.667z" />
      </clipPath>
      <filter
        id="b"
        width={6.844}
        height={5.989}
        x={2.311}
        y={1.644}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1.1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_12285_2132" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_12285_2132" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default FlagUS;
