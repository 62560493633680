import React from "react";
import T from "prop-types";
import { useTranslation } from "@tint_fe/hooks";

import { Food } from "../../assets/icons";

const SummaryBreakfast = ({ breakfastIncluded }) => {
  const { t } = useTranslation();

  if (breakfastIncluded) {
    return (
      <div className={"summary__breakfast"}>
        <Food />
        <span>{t("hotelCard.breakfast")}</span>
      </div>
    );
  }

  return null;
};

SummaryBreakfast.propTypes = {
  breakfastIncluded: T.bool,
};

export default SummaryBreakfast;
