import { useEffect } from "react";

const useScript = (url, onLoad) => {
  useEffect(() => {
    if (url) {
      // Prevent script from loading multiple times
      const allScripts = document.getElementsByTagName("script");
      const isPresent = Array.from(allScripts).some((s) => s.src === url);
      if (isPresent) {
        return;
      }

      const script = document.createElement("script");

      script.src = url;
      script.async = true;

      if (onLoad) {
        script.onload = onLoad;
      }

      document.body.appendChild(script);
    }
  }, [url, onLoad]);
};

export default useScript;
