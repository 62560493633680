import React, { useEffect } from "react";
import T from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { omit } from "lodash";

import { dates } from "@tint_fe/helpers";
import { DatePickerInput } from "@tint_fe/ui";
import { useTranslation } from "@tint_fe/hooks";

const HotelSelectDates = ({ handleChange, initialDate }) => {
  const { control, getValues, setValue } = useFormContext();
  const { t } = useTranslation();

  const dateNow = new Date();

  const onChangeStartDate = (val) => {
    const newDate = dates.cpDateTransformer(val);
    setValue("end_date", dates.add(newDate, { days: 2 }, false));

    return newDate;
  };

  const disabledStartDates = (current) => {
    return (
      (dates.isBefore(current, new Date()) && !dates.isSameDay(current, new Date())) ||
      dates.isAfter(current, dates.add(dateNow, { years: 4 }))
    );
  };

  const disabledEndDates = (current) => {
    const startDate = getValues("start_date");

    if (dates.isBefore(current, startDate, false)) return true;
    if (dates.isAfter(current, dates.add(startDate, { months: 1 }), false)) return true;

    return false;
  };

  useEffect(() => {
    if (initialDate) {
      const newDate = dates.cpDateTransformer(initialDate);
      setValue("start_date", newDate);
      setValue("end_date", dates.add(newDate, { days: 2 }, false));
    }
  });

  return (
    <>
      <div className="row">
        <div className="col col-12 col-md-6">
          <Controller
            control={control}
            name="start_date"
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              return (
                <DatePickerInput
                  label={t("formField.checkInDate")}
                  placeholder={t("formField.chooseDate")}
                  classNameWrapper="mg-b-4 mg-md-b-0"
                  value={value}
                  disabledDate={disabledStartDates}
                  errors={error}
                  onChange={(val) => {
                    onChangeStartDate(val);
                    handleChange();
                    onChange(val);
                  }}
                  {...omit(rest, "ref")}
                />
              );
            }}
          />
        </div>
        <div className="col col-12 col-md-6">
          <Controller
            control={control}
            name="end_date"
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              return (
                <DatePickerInput
                  label={t("formField.checkOutDate")}
                  placeholder={t("formField.chooseDate")}
                  value={value}
                  disabledDate={disabledEndDates}
                  errors={error}
                  onChange={(val) => {
                    handleChange();
                    onChange(val);
                  }}
                  {...omit(rest, "ref")}
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

HotelSelectDates.propTypes = {
  handleChange: T.func,
  initialDate: T.string,
};

export default HotelSelectDates;
