import * as types from "./globalActionTypes";

export const getWidgetIntegrationRequest = (payload) => ({
  payload,
  type: types.GET_WIDGET_INTEGRATION_REQUEST,
});

export const saveRootIdAction = (payload) => ({
  payload,
  type: types.SAVE_ROOT_ID,
});

export const clearErrorAction = () => ({
  type: types.CLEAR_ERROR,
});

export const setErrorAction = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const toggleContactUsModalAction = (payload) => ({
  type: types.TOGGLE_CONTACT_US_MODAL,
  payload,
});

export const toggleShowMoreInfoModalAction = (payload) => ({
  type: types.SHOW_MORE_INFO_MODAL,
  payload,
});

export const toggleShowAllPoliciesModalAction = (payload) => ({
  type: types.SHOW_ALL_POLICIES_MODAL,
  payload,
});

export const partialPaymentAction = (payload) => {
  return {
    type: types.PARTIAL_PAYMENT,
    payload,
  };
};
