// our A/B testing strategy build on google redirect testing
// we use only url query parameters for this
// this query parameters should match to this pattern - assumptions[feature-name]=on
// it will on this feature-name
import { camelCase } from "lodash";

import QueryString from "./QueryString";
import { AB_TESTING_KEY } from "../constants";

// this function will return an object where the key is the feature name
// and value true/false which indicate if this feature should be on/off
export const handleABTestingFeatures = () => {
  // list of features that could be turn on depending on "assumptions" query parameters
  const assumptionsList = {
    countdown: false,
    "where-are-you-staying": false,
    summary: true, // Test ended, always show ABSummary for following logic
    popular: false,
    "next-available-date": false,
    "auto-accommodation": false,
    "post-extras": false,
    calendar: false,
  };
  const { [AB_TESTING_KEY]: queryAssumptions } = QueryString.parse(document.location.search);
  const result = {};

  if (!queryAssumptions) return result;

  for (const assumption in assumptionsList) {
    if (Object.prototype.hasOwnProperty.call(assumptionsList, assumption)) {
      const queryAssumptionKey = Object.keys(queryAssumptions).find((el) => el === assumption);
      if (!queryAssumptionKey) continue;

      if (queryAssumptions[queryAssumptionKey] === "on") {
        result[camelCase(assumption)] = true;
      }
      if (queryAssumptions[queryAssumptionKey] === "off") {
        result[camelCase(assumption)] = false;
      }
    }
  }

  return result;
};
