import { useFormContext } from "react-hook-form";
import { mealPlans } from "../../../../helpers/constants";

import { SPCheckoutFormFields } from "../helpers";

const { MEAL_PLAN } = SPCheckoutFormFields;

const useCheckIfBreakfastIncluded = () => {
  const { getValues } = useFormContext();

  return () => {
    const mealPlan = getValues(MEAL_PLAN);

    return mealPlan === mealPlans.BREAKFAST_INCLUDED.value;
  };
};

export default useCheckIfBreakfastIncluded;
