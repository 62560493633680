import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { getSmartPackageAccommodationRequest } from "../../../../redux/smartPackage/smartPackageAction";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import { SMART_PACKAGE_CHECKOUT } from "../../../router";
import useGetSPCheckoutFromData from "./useGetSPCheckoutFromData";

const useGetAccomodation = () => {
  const getSmartPackageAccommodation = useReduxAction(getSmartPackageAccommodationRequest);
  const history = useHistoryNavigation();
  const getSPCheckoutFromData = useGetSPCheckoutFromData();
  const startLocations = useSelector((state) => state.smartPackage.data?.start_locations);
  const isOnlyOneStartPoint = startLocations?.length === 1;

  return ({ formData, preventRedirect }) => {
    const checkoutFormData = getSPCheckoutFromData(formData);

    if (!isEmpty(checkoutFormData)) {
      return getSmartPackageAccommodation({
        body: checkoutFormData,
        history: !preventRedirect && history,
        overridenHistory: history,
        isOnlyOneStartPoint,
      });
    }

    return history.replace(SMART_PACKAGE_CHECKOUT);
  };
};

export default useGetAccomodation;
