import React from "react";
import T from "prop-types";

import { Close } from "../../assets/icons";

const CloseButton = ({ onClose, svgSizes, className }) => {
  return (
    <button className={className} onClick={onClose}>
      <Close {...svgSizes} width={24} height={24} />
    </button>
  );
};

CloseButton.defaultProps = {
  svgSizes: { width: 24, height: 24 },
};

CloseButton.propTypes = {
  onClose: T.func.isRequired,
  svgSizes: T.object,
  className: T.string,
};

export default CloseButton;
