import React, { useMemo } from "react";
import T from "prop-types";
import { useFormContext } from "react-hook-form";

import { join } from "@tint_fe/helpers";
import Product from "./Product";
import { getTravelers } from "./helpers";

const getOptionsFromDocuments = (documents) => {
  return documents
    ?.map((el) => {
      const { key, first_name, last_name, nationality, number } = el || {};
      const label = join([`${first_name} ${last_name}`, nationality?.label, number], ", ").trim();

      if (!label) return null;

      return { value: key, label };
    })
    ?.filter(Boolean);
};

const Products = ({ productItems, maxPaxQuantity }) => {
  const travelers = useMemo(() => getTravelers(maxPaxQuantity), [maxPaxQuantity]);
  const { getValues, watch } = useFormContext();

  const documents = watch("documents") || getValues("documents");

  return (
    <div className="products">
      {productItems?.map((item) => (
        <Product
          key={`product-${item.id}`}
          id={String(item.id)}
          productName={item.display_name}
          travelers={travelers}
          documents={getOptionsFromDocuments(documents)}
        />
      ))}
    </div>
  );
};

Products.propTypes = {
  productItems: T.array.isRequired,
  maxPaxQuantity: T.number,
};

export default Products;
