import { rest } from "msw";

export const pricesByOrderNumber = {
  TY03237960: {
    order: {
      number: "TY03237960",
      agent_fee_amount: "0.0",
      citizenship_tax_amount: "0.0",
      components_price: "80.0",
      discount_amount: "0.0",
      product_items: [
        {
          id: 173364,
          components_price: "80.0",
          components_price_per_person: "80.0",
          discount_amount: "0.0",
          extras_price: "0.0",
          line_items: [
            {
              id: 1969459,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Vegan lunch",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "0.0",
              product_option_id: 2333,
              product_option_type: "ProductExtra",
              quantity: 0,
            },
            {
              id: 1969458,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Guide",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "15.0",
              product_option_id: 2321,
              product_option_type: "ProductExtra",
              quantity: 0,
            },
            {
              id: 1969457,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Test Extra transportation",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "20.0",
              product_option_id: 1847,
              product_option_type: "ProductExtra",
              quantity: 0,
            },
            {
              id: 1969456,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Extra accommodation",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "50.0",
              product_option_id: 1846,
              product_option_type: "ProductExtra",
              quantity: 0,
            },
            {
              id: 1969455,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: null,
              item_agent_fee_amount: "0.0",
              original_item_price: "0.0",
              original_price_per_person: "0.0",
              product_option_id: null,
              product_option_type: "DropoffAdjustment",
              quantity: 1,
            },
            {
              id: 1969454,
              calculated_item_discount: "0.0",
              calculated_item_price: "10.0",
              discount_percent: "0.0",
              display_name: null,
              item_agent_fee_amount: "0.0",
              original_item_price: "10.0",
              original_price_per_person: "10.0",
              product_option_id: null,
              product_option_type: "SingleSupplement",
              quantity: 1,
            },
            {
              id: 1969453,
              calculated_item_discount: "0.0",
              calculated_item_price: "70.0",
              discount_percent: "0.0",
              display_name: "Adult",
              item_agent_fee_amount: "0.0",
              original_item_price: "70.0",
              original_price_per_person: "70.0",
              product_option_id: 7846,
              product_option_type: "PriceBand",
              quantity: 1,
            },
            {
              id: 1969452,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Children",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "4.04",
              product_option_id: 2312,
              product_option_type: "PriceBand",
              quantity: 0,
            },
            {
              id: 1969451,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Children",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "44.4",
              product_option_id: 7990,
              product_option_type: "PriceBand",
              quantity: 0,
            },
            {
              id: 1969450,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Adult",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "60.0",
              product_option_id: 3072,
              product_option_type: "PriceBand",
              quantity: 0,
            },
            {
              id: 1969449,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Adult",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "50.0",
              product_option_id: 7493,
              product_option_type: "PriceBand",
              quantity: 0,
            },
            {
              id: 1969448,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Students",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "2.25",
              product_option_id: 7991,
              product_option_type: "PriceBand",
              quantity: 0,
            },
            {
              id: 1969447,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Adult",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "60.0",
              product_option_id: 2311,
              product_option_type: "PriceBand",
              quantity: 0,
            },
            {
              id: 1969446,
              calculated_item_discount: "0.0",
              calculated_item_price: "0.0",
              discount_percent: "0.0",
              display_name: "Override",
              item_agent_fee_amount: "0.0",
              original_item_price: 0,
              original_price_per_person: "0.0",
              product_option_id: 4932,
              product_option_type: "PriceBand",
              quantity: 0,
            },
          ],
          pax_quantity: 1,
          tint_charge_price: "80.0",
          tint_charge_price_per_person: "80.0",
        },
      ],
      tint_charge_price: "80.0",
    },
  },
};

export const pricesHandlers = {
  fetchPrices: rest.patch("/orders/fetch_prices", (req, res, ctx) => {
    const orderNumber = req.body.number;

    const price = pricesByOrderNumber[orderNumber];

    if (!price) {
      return res(
        ctx.status(404),
        ctx.json({
          errors: {
            messages: ["Product unavailable"],
          },
        }),
      );
    }

    return res(ctx.json(price));
  }),
};
