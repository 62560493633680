import * as React from "react";
const GhostIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none" {...props}>
    <path stroke="#F5854D" strokeLinecap="round" strokeWidth={2} d="M13 22v0a11.08 11.08 0 0 0 9 0v0" />
    <path
      stroke="#F5854D"
      strokeWidth={2}
      d="m29.034 19.183-.749.267.09.79c.331 2.88.978 5.098 1.762 6.718.77 1.593 1.723 2.703 2.727 3.206.074.037.12.112.12.193 0 .609-.386 1.258-1.104 1.806-.718.548-1.571.837-2.142.837a4.523 4.523 0 0 1-2.399-.685 3.5 3.5 0 0 0-3.693 0c-1.432.893-3.369.893-4.797 0a3.5 3.5 0 0 0-3.694 0c-1.432.893-3.369.893-4.797 0a3.5 3.5 0 0 0-3.694 0A4.523 4.523 0 0 1 4.265 33c-.57 0-1.424-.289-2.142-.837S1.02 30.966 1.02 30.357c0-.082.046-.156.117-.191 1.005-.504 1.957-1.614 2.728-3.207.784-1.62 1.43-3.838 1.761-6.718l.09-.79-.748-.266c-1.217-.433-2.473-1.327-3.243-2.353-.774-1.032-.914-1.966-.48-2.718.446-.774 1.695-1.27 3.539-.748l1.14.323.126-1.178c.557-5.158 2.428-7.98 4.498-9.539C12.646 1.393 15.098 1 17.002 1c1.903 0 4.355.393 6.454 1.972 2.069 1.558 3.94 4.38 4.497 9.538l.127 1.177 1.139-.322c1.843-.52 3.093-.025 3.539.749.428.742.292 1.674-.479 2.708-.765 1.027-2.018 1.925-3.245 2.361Z"
    />
    <path
      stroke="#F5854D"
      strokeLinecap="round"
      strokeWidth={1.917}
      d="m11 14 3-3M14.008 14l-3-3M19.797 14l3-3M22.805 14l-3-3"
    />
  </svg>
);
export default GhostIcon;
