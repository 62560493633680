import * as React from "react";

function RemoveBtnIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM3.75736 5.17157L5.17157 3.75736L8 6.58579L10.8284 3.75736L12.2426 5.17157L9.41421 8L12.2426 10.8284L10.8284 12.2426L8 9.41421L5.17157 12.2426L3.75736 10.8284L6.58579 8L3.75736 5.17157Z"
        fill="#151209"
      />
    </svg>
  );
}

export default RemoveBtnIcon;
