import * as React from "react";

function SvgPlus(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6254 9.21971C22.0564 9.56512 22.1257 10.1945 21.7803 10.6254C21.338 11.1773 20.7947 11.7722 20.1444 12.3493L21.8636 15.3501C22.1381 15.8293 21.9722 16.4403 21.493 16.7149C21.0138 16.9894 20.4028 16.8235 20.1282 16.3443L18.5373 13.5675C17.6846 14.113 16.7086 14.5896 15.6021 14.9268L16.11 17.828C16.2053 18.372 15.8415 18.8902 15.2975 18.9854C14.7534 19.0807 14.2352 18.7169 14.14 18.1729L13.6452 15.3464C13.122 15.4137 12.5755 15.45 12.005 15.45C11.4316 15.45 10.8824 15.4134 10.3568 15.3453L9.86198 18.1719C9.76674 18.7159 9.24852 19.0797 8.70451 18.9845C8.1605 18.8892 7.7967 18.371 7.89194 17.827L8.40009 14.9243C7.29505 14.5867 6.32027 14.11 5.46862 13.5646L3.86772 16.3593C3.5932 16.8385 2.98217 17.0045 2.50294 16.73C2.02372 16.4555 1.85777 15.8444 2.13229 15.3652L3.86198 12.3457C3.21352 11.7697 2.67153 11.1763 2.22992 10.6257C1.88437 10.1948 1.95352 9.56545 2.38435 9.2199C2.81518 8.87436 3.44456 8.9435 3.79011 9.37434C4.2942 10.0029 4.93534 10.6806 5.72689 11.2995C5.75011 11.316 5.77245 11.3333 5.79388 11.3514C7.30004 12.5103 9.34068 13.45 12.005 13.45C12.7945 13.45 13.5293 13.3675 14.2116 13.2212C14.2333 13.2157 14.2553 13.2109 14.2775 13.2068C15.8622 12.8559 17.1617 12.1609 18.2051 11.3602C18.2311 11.3378 18.2585 11.3166 18.2872 11.2967C19.077 10.6785 19.7168 10.002 20.2197 9.37458C20.5651 8.94364 21.1945 8.8743 21.6254 9.21971Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgPlus;
