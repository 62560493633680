import React from "react";
import T from "prop-types";

import { parseClasses, toUpFirstLater, validationRule } from "@tint_fe/helpers";
import { ControlledInput, DatePickerInput, Select } from "../../components";
import { Controller } from "react-hook-form";

export const TINT_GENERIC_KEY = "tint_generic_key";

const CustomerExtraForm = ({ control, schema, fieldsPrefix, position }) => {
  const { mainCls, elCls } = parseClasses({ base: "customer-form", additional: ["mg-b-6"] });

  const schemeSorted = Object.entries(schema)
    .map(([key, value]) => ({ key, ...value }))
    .sort((a, b) => a.propertyOrder - b.propertyOrder);

  const renderAppropriateInput = (row_field, name) => {
    const { title = "", pattern, format } = row_field;
    const upTitle = toUpFirstLater(title);
    const hasPattern = !!pattern;
    const formattedPattern = hasPattern ? new RegExp(pattern) : "";
    const propperName = Number(name) ? name + TINT_GENERIC_KEY : name;
    const prefixedName = `${fieldsPrefix}${propperName}`;

    const isMultipleOptionsPresent = Object(row_field).hasOwnProperty("enum");

    switch (format) {
      case "email":
        return (
          <div className={elCls("row")} key={prefixedName + title}>
            <ControlledInput
              control={control}
              name={prefixedName}
              label={`${upTitle}*`}
              removeStartEndSpaces
              useT={false}
              rules={{
                ...validationRule.required(upTitle),
                ...(hasPattern && {
                  pattern: {
                    value: formattedPattern,
                    message: { text: "error.custom.message" },
                  },
                }),
                ...validationRule.email,
              }}
            />
          </div>
        );
      case "date":
        return (
          <div className={elCls("row")} key={prefixedName + title}>
            <Controller
              control={control}
              name={prefixedName}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <DatePickerInput
                    label={`${upTitle}*`}
                    placeholder={upTitle}
                    classNameWrapper="form-group--wrapper"
                    errors={error?.message}
                    {...rest}
                  />
                );
              }}
              rules={{ ...validationRule.required("formField.chooseDate") }}
            />
          </div>
        );
      default:
        if (isMultipleOptionsPresent) {
          return (
            <div className={elCls("row")} key={prefixedName + title}>
              <Controller
                control={control}
                name={prefixedName}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Select
                      label={`${upTitle}*`}
                      error={error}
                      placeholder={upTitle}
                      withoutOverflowTooltip
                      showOptionFrom={0}
                      useT={false}
                      {...field}
                      options={row_field.enum?.map((el, el_index) => ({
                        value: el,
                        label: row_field?.enum_titles[el_index] || el,
                      }))}
                    />
                  );
                }}
                rules={{ ...validationRule.required(upTitle) }}
              />
            </div>
          );
        }

        return (
          <div className={elCls("row")} key={prefixedName + title}>
            <ControlledInput
              control={control}
              name={prefixedName}
              label={`${upTitle}*`}
              placeholder={upTitle}
              removeStartEndSpaces
              useT={false}
              rules={{
                ...validationRule.required(upTitle),
                ...(hasPattern && {
                  pattern: {
                    value: formattedPattern,
                    message: { text: "error.custom.message" },
                  },
                }),
              }}
            />
          </div>
        );
    }
  };

  if (!schema) return null;

  return (
    <div className={mainCls}>
      {!!position && (
        <div className={elCls("row")}>
          <p className="p5 mg-0 bold">{`Participant ${position}`}</p>
        </div>
      )}
      {schemeSorted.map((el) => renderAppropriateInput(el, el.key))}
    </div>
  );
};

CustomerExtraForm.defaultProps = {
  fieldsPrefix: "",
};

CustomerExtraForm.propTypes = {
  control: T.object.isRequired,
  schema: T.object.isRequired,
  fieldsPrefix: T.string,
  position: T.number,
};

export default CustomerExtraForm;
