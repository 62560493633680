import * as React from "react";
const Diamond20 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="#21AFE4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.084 7.5h15.833m-9.583-5-1.667 5 3.334 9.583L13.334 7.5l-1.667-5m-1.154 14.385 7.465-8.958c.127-.152.19-.228.214-.313a.417.417 0 0 0 0-.228c-.024-.085-.087-.161-.214-.313l-3.61-4.333a.902.902 0 0 0-.156-.164.416.416 0 0 0-.132-.062c-.053-.014-.11-.014-.225-.014H6.146a.9.9 0 0 0-.225.014.417.417 0 0 0-.132.062.9.9 0 0 0-.155.164L2.023 7.073c-.127.152-.19.228-.214.313a.417.417 0 0 0 0 .228c.024.085.087.161.214.313l7.465 8.958c.176.211.264.317.37.355a.416.416 0 0 0 .286 0c.105-.038.193-.143.369-.355Z"
    />
  </svg>
);
export default Diamond20;
