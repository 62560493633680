import { all } from "redux-saga/effects";

import globalSagas from "./global/globalSagas";
import orderSagas from "./order/orderSagas";
import productSagas from "./product/productSagas";
import hotelSagas from "./hotel/hotelSagas";
import smartPackageSagas from "./smartPackage/smartPackageSagas";

export default function* () {
  yield all([globalSagas(), orderSagas(), productSagas(), hotelSagas(), smartPackageSagas()]);
}
