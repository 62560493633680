import { useEffect } from "react";

import { scrollToError } from "../utils";

const useScrollToError = (errors) => {
  useEffect(() => {
    scrollToError();
  }, [errors]);
};

export default useScrollToError;
