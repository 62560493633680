import React from "react";
import T from "prop-types";
import { useController } from "react-hook-form";

import { useTranslation } from "@tint_fe/hooks";
import { Checkbox } from "..";

const PolicyCheckbox = React.forwardRef(({ control, rules, name, termsAndConditionLink, isExpedia, ...rest }, ref) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {
    field,
    fieldState: { error },
  } = useController({
    rules,
    control,
    name,
    defaultValue: false,
  });

  return (
    <Checkbox
      errorClasses="bold uppercase"
      error={error}
      {...field}
      {...rest}
      ref={ref}
      label={
        <span>
          {t("formField.agreeTo")}{" "}
          <a href={termsAndConditionLink} className="customer-form__accept-terms-link" target="_blank" rel="noreferrer">
            {t("formField.termsConditionsPolicy")}{" "}
          </a>
          {isExpedia && (
            <>
              {t("and")}{" "}
              <a
                href={`https://developer.expediapartnersolutions.com/terms/${
                  currentLanguage === "he" ? "en" : currentLanguage
                }`}
                className="customer-form__accept-terms-link"
                target="_blank"
                rel="noreferrer"
              >
                {t("formField.supplierTerms")}
              </a>
            </>
          )}
        </span>
      }
    />
  );
});

PolicyCheckbox.propTypes = {
  control: T.object,
  rules: T.object,
  name: T.string.isRequired,
  termsAndConditionLink: T.string,
  isExpedia: T.bool,
};

export default PolicyCheckbox;
