import React from "react";
import T from "prop-types";
import { pick } from "lodash";

import { useTranslation } from "@tint_fe/hooks";
import { API_DOWN_ERROR_STATUSES, QueryString, parseClasses } from "@tint_fe/helpers";
import { Button, Container } from "../../components";
import { IceCreamBrokenSvg, IceCreamMeltsSvg } from "../../assets/icons";

const getErrorMsg = (err) => {
  switch (true) {
    case err?.status && API_DOWN_ERROR_STATUSES.includes(err.status):
      return "error.500.page";
    default:
      return err?.messages?.join(" ") || "error.404.page";
  }
};

const createNewSearchParams = (searchString) => {
  const piDs = ["pid", "productId", "productid", "tourId", "tourid", "packageId", "hdf", "hdt", "rooms"];
  const search = QueryString.parse(searchString);
  const newSearch = { ...pick(search, ...piDs) };

  return QueryString.stringify(newSearch) || "";
};

const GlobalError = ({ showContactUsModal, error }) => {
  const { t } = useTranslation();
  const { search, href } = document.location;
  const errorMsg = getErrorMsg(error);
  const downtimeError = error?.status && API_DOWN_ERROR_STATUSES.includes(error.status);

  const { mainCls, elCls } = parseClasses({
    base: "error-page",
  });

  const onGoBackPress = () => {
    const newSearch = createNewSearchParams(search);
    const originPath = href.split("?")[0];
    document.location = `${originPath}?${newSearch}`;
  };

  if (errorMsg?.toLowerCase()?.includes("rates")) {
    return (
      <div className={mainCls}>
        <Container className={elCls("container-rates")}>
          <IceCreamMeltsSvg width={48} height={48} />
          <div className={elCls("content", "mg-l-4")}>
            <p className="mg-0 p4 bold mg-b-1">{t("error.title")}</p>
            {t(errorMsg)}
            <button onClick={showContactUsModal} className="tint-link mg-l-1 p4">
              {t("contactUs")}
            </button>
            <Button className={elCls("go-back-btn")} type="button" onClick={onGoBackPress}>
              {"Refresh hotel search"}
            </Button>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className={mainCls}>
      <Container className={elCls("container")}>
        <IceCreamBrokenSvg width={48} height={48} />
        <div className={elCls("content", "mg-l-4")}>
          <p className="mg-0 p4 bold mg-b-1">{downtimeError ? t("error.500.title") : t("error.title")}</p>
          {downtimeError ? `${t(errorMsg)} [${error.status}]` : t(errorMsg)}
          {!downtimeError && (
            <button onClick={showContactUsModal} className="tint-link mg-l-1 p4">
              {t("contactUs")}
            </button>
          )}
        </div>
      </Container>
    </div>
  );
};

GlobalError.propTypes = {
  showContactUsModal: T.func,
  error: T.object,
};

export default GlobalError;
