import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";

import { getSourceLink } from "../../../../utils";
import { clearHotelAvailabilityAction, setHotelErrorAction } from "../../../../redux/hotel/hotelActions";
import { useReduxAction } from "../../../../hooks";

import FormHeader from "../../../components/Form/FormHeader";
import HotelSelectDates from "./HotelSelectDates";
import HotelsRoomsForm from "./HotelsRoomsForm";
import HotelRoomRatesForm from "./HotelRoomRatesForm";
import HotelButtonsGroup from "./HotelButtonsGroup";

import useGetHotelsAvailability from "../hooks/useGetHotelsAvailability";
import useSerializeHotelCheckoutFormData from "../hooks/useSerializeHotelCheckoutFormData";

const ExtraHotelCheckout = ({ pid, orderNumber, createHotelProductItem, createLoading, startDate }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();

  const clearHotelAvailability = useReduxAction(clearHotelAvailabilityAction);
  const setHotelError = useReduxAction(setHotelErrorAction);

  const step = useSelector((state) => state.global.step);
  const hotelRooms = useSelector((state) => state.hotel.hotelRooms);
  const fetchingGetRooms = useSelector((state) => state.hotel.fetchingGetRooms);

  const getHotelsAvailability = useGetHotelsAvailability({ pid });
  const serializeHotelCheckoutFormData = useSerializeHotelCheckoutFormData({ pid });

  const isFetching = fetchingGetRooms;

  const returnToCheckoutStep = () => {
    hotelRooms.length && clearHotelAvailability();
  };

  const onSubmit = handleSubmit((values) => {
    if (!hotelRooms.length) {
      return getHotelsAvailability(values);
    }

    try {
      const productItemsAttributes = serializeHotelCheckoutFormData(values);

      createHotelProductItem({
        orderNumber,
        body: {
          product_item: {
            ...productItemsAttributes,
            source_link: getSourceLink(),
          },
        },
      });
    } catch (error) {
      setHotelError({ messages: [error.message] });
    }
  });

  useEffect(() => {
    return () => {
      clearHotelAvailability();
    };
  }, []);

  return (
    <>
      <div className="hotels-container">
        <FormHeader text={t("hotelDetails")} />
        <HotelSelectDates handleChange={returnToCheckoutStep} initialDate={startDate} />
        <HotelsRoomsForm handleChange={returnToCheckoutStep} />

        {!!hotelRooms.length && <HotelRoomRatesForm hotelRooms={hotelRooms} loading={isFetching} />}
      </div>
      <HotelButtonsGroup
        onSubmit={onSubmit}
        disabled={isFetching}
        fetchingCreateOrder={createLoading}
        fetchingGetRooms={fetchingGetRooms}
        step={step}
        isExtra
      />
    </>
  );
};

ExtraHotelCheckout.propTypes = {
  pid: T.number.isRequired,
  orderNumber: T.string.isRequired,
  createHotelProductItem: T.func,
  createLoading: T.bool,
  startDate: T.string,
};

export default ExtraHotelCheckout;
