import React from "react";
import T from "prop-types";

import { Preloader } from "@tint_fe/ui";
import FormHeader from "./FormHeader";
import { useTranslation, useScrollToError } from "../../../hooks";

const FormContainer = ({
  title,
  subtitle,
  errors,
  children,
  loading,
  loadingMessage,
  titleClassName,
  scrollLoaderIntoView,
  formContainerClassName,
  scrollOnLoadingToTop,
  onSubmit,
  useInformativeLoader,
  integrationName,
}) => {
  const { isRtl } = useTranslation();
  useScrollToError(errors);

  return (
    <form
      className={`form-container${formContainerClassName ? ` ${formContainerClassName}` : ""}`}
      onSubmit={onSubmit ? onSubmit : (e) => e.preventDefault()}
    >
      {title && <FormHeader className={titleClassName} text={title} />}
      {subtitle && <p className={`booking-form__subtitle ${isRtl ? " rtl" : ""}`}>{subtitle}</p>}
      {loading && (
        <Preloader
          text={loadingMessage}
          className="form-container__loader"
          scrollIntoView={scrollLoaderIntoView}
          scrollOnLoadingToTop={scrollOnLoadingToTop}
          useInformativeLoader={useInformativeLoader}
          integrationName={integrationName}
        />
      )}
      {children}
    </form>
  );
};

FormContainer.propTypes = {
  title: T.string,
  subtitle: T.string,
  errors: T.oneOfType([T.object, T.bool]),
  children: T.oneOfType([T.node, T.arrayOf(T.node)]),
  loading: T.bool,
  loadingMessage: T.string,
  titleClassName: T.string,
  formContainerClassName: T.string,
  scrollLoaderIntoView: T.bool,
  scrollOnLoadingToTop: T.bool,
  onSubmit: T.func,
  useInformativeLoader: T.bool,
  integrationName: T.string,
};

export default FormContainer;
