import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";

const Radio = React.forwardRef(
  ({ className, label, error, onClick, value, onChange, selected, disabled, ...rest }, ref) => {
    const { mainCls } = parseClasses({
      base: "radio",
      additional: className,
      classes: [error && "error", selected && "selected", disabled && "disabled"],
    });

    return (
      <label className={mainCls}>
        <input
          ref={ref}
          type="radio"
          onChange={(e) => onChange && onChange(e)}
          onClick={(e) => onClick && onClick(e)}
          value={value}
          disabled={disabled}
          {...rest}
        />
        <span className="radio__checkmark mg-r-3" />
        {label && <span className="radio__label">{label}</span>}
      </label>
    );
  },
);

Radio.propTypes = {
  label: T.node,
  className: T.string,
  error: T.bool,
  onClick: T.func,
  onChange: T.func,
  value: T.string,
  selected: T.bool,
  disabled: T.bool,
};

export default Radio;
