import React from "react";
import T from "prop-types";

import { FreshChat } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Button } from "../..";

const OpenChatButton = ({ onOpenChat, ...rest }) => {
  const { t } = useTranslation();
  const handleOpenChat = () => {
    onOpenChat && onOpenChat();
    FreshChat.open();
  };

  return FreshChat.isInitialized() ? (
    <Button onClick={handleOpenChat} {...rest}>
      {t("chat")}
    </Button>
  ) : null;
};

OpenChatButton.propTypes = {
  onOpenChat: T.func,
};

export default OpenChatButton;
