import React, { useEffect } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { useDidMount } from "@tint_fe/hooks";

import FormHeader from "../../../../components/Form/FormHeader";
import TourExtrasItem from "./TourExtrasItem";
import ControllerInput from "../../../../components/Form/ControllerInput";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";
import { serializeExtras } from "../../helpers";
import { ga_add_to_cart_extras } from "@tint_fe/helpers/services/google-analytics-v2";

const sortExtras = (extras) => {
  return extras?.sort((a, b) => {
    switch (true) {
      case (!a.images.length && !!b.images.length) || (!a.descriptions.length && !!b.descriptions.length):
        return 1;
      case (!!a.images.length && !b.images.length) || (!!a.descriptions.length && !b.descriptions.length):
        return -1;
      default:
        return 0;
    }
  });
};

const getPriceForExtra = (priceLineItems, id) => {
  const priceLineItem = priceLineItems.find((x) => x.product_option_id === id);

  return priceLineItem
    ? Math.ceil(Number.parseFloat(priceLineItem.rounded_price_per_person || priceLineItem.original_price_per_person))
    : 0;
};

const TourExtras = ({ availableExtras, orderProductExtraLineItems }) => {
  const updateTourPrice = useUpdateTourPrice();

  const { control, getValues, setValue } = useFormContext();

  const product = useSelector((state) => state.product.data);
  const order = useSelector((state) => state.order.data);
  const orderProductItem = order?.product_items?.[0];
  const price = useSelector((state) => state.global.price);
  const priceLineItems = price?.product_items?.[0]?.line_items || [];

  const { t } = useTranslation();
  const mounted = useDidMount();

  const getDefaultValue = (id) => {
    return orderProductExtraLineItems.find((x) => x.product_option_id === id)?.quantity;
  };

  const updateExtrasHandler = (data, id, capacity_values = [], by_item = false, min_price = 0) => {
    let updatedValue = data[id];
    const extrasInfo = getValues("extrasInfo");

    if (extrasInfo[id] === 0 && capacity_values.includes(orderProductItem.pax_quantity) && !by_item) {
      updatedValue = orderProductItem.pax_quantity;
    }

    extrasInfo[id] = updatedValue;

    updateTourPrice(
      {
        extrasInfo: serializeExtras(orderProductExtraLineItems, extrasInfo),
      },
      { sendOrderNumber: true },
    );

    const approx_price = Number(min_price)
      ? Number(min_price) * updatedValue + Number(price?.tint_charge_price)
      : Number(price?.tint_charge_price);
    ga_add_to_cart_extras(product, order, approx_price);

    return updatedValue;
  };

  useEffect(() => {
    if (mounted) {
      availableExtras.forEach(({ id: el_id }) => {
        setValue(`extrasInfo.${el_id}`, getDefaultValue(el_id));
      });
    }
  }, [mounted]);

  return (
    <>
      {availableExtras.length > 0 && <FormHeader text={t("upgradeYourExperience")} />}
      {!isEmpty(orderProductExtraLineItems) &&
        sortExtras(availableExtras).map((extra) => (
          <ControllerInput
            key={`extra-info-${extra.id}`}
            name={`extrasInfo.${extra.id}`}
            control={control}
            shouldUnregister
            defaultValue={getDefaultValue(extra.id)}
            render={({ field: { value, onChange, ...rest } }) => (
              <TourExtrasItem
                extra={extra}
                price={getPriceForExtra(priceLineItems, extra.id)}
                quantity={typeof value === "undefined" ? getDefaultValue(extra.id) : value}
                max={orderProductItem.pax_quantity}
                updateExtrasHandler={(val) => {
                  onChange(updateExtrasHandler(val, extra.id, extra.capacity_values, extra?.by_item, extra?.min_price));
                }}
                currency={price?.charge_currency}
                {...rest}
              />
            )}
          />
        ))}
    </>
  );
};

TourExtras.propTypes = {
  availableExtras: T.array.isRequired,
  orderProductExtraLineItems: T.array,
};

export default TourExtras;
