import * as React from "react";

function CheckOut(props) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1H0v2h1v12h14V3h1V1H1Zm14 9-5 4v-2.5H7v-3h3V6l5 4ZM3 3h2v2H3V3Zm2 4H3v2h2V7Zm4-4H7v2h2V3Zm-6 8h2v2H3v-2Zm10-8h-2v2h2V3Z"
        fill="#0C3164"
      />
    </svg>
  );
}

export default CheckOut;
