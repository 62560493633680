import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { Info3 } from "../../assets/icons";
import { TintCard } from "..";

const ReplaceItemCard = ({
  selected,
  onShowMoreInfo,
  onSelect,
  imgs,
  name,
  children,
  className,
  btnText,
  showMoreText,
  ...rest
}) => {
  const { t } = useTranslation();
  const { mainCls, elCls } = parseClasses({
    base: "replace-item-card",
    classes: [selected && "selected"],
    additional: className,
  });

  return (
    <TintCard
      className={mainCls}
      title={name}
      imgs={imgs}
      btnText={`${selected ? t("selected") : t("select")} ${t(btnText)}`}
      onSelect={onSelect}
      selected={selected}
      {...rest}
    >
      {children}
      <div onClick={onShowMoreInfo} className={elCls("show-info", "bold")}>
        <Info3 height={24} width={24} />
        <span className="mg-l-2">{t(showMoreText)}</span>
      </div>
    </TintCard>
  );
};

ReplaceItemCard.defaultProps = {
  btnText: "hotel",
  showMoreText: "hotelCard.showMoreInfo",
};

ReplaceItemCard.propTypes = {
  selected: T.bool,
  onShowMoreInfo: T.func,
  onSelect: T.func,
  imgs: T.oneOfType([T.string, T.array]),
  name: T.string,
  children: T.node,
  className: T.string,
  btnText: T.string,
  showMoreText: T.string,
};

export default ReplaceItemCard;
