import React from "react";
import T from "prop-types";

import {
  paymentMethods,
  checkIsHttpsProtocol,
  join,
  checkIsSafari,
  checkIsExternalPaymentMethodAllowed,
} from "@tint_fe/helpers";
import { useControlledInput, useLoadApplePayBtn } from "@tint_fe/hooks";

import { Radio } from "../../components";
import { GooglePayMarkIcon, ApplePayBtnIcon } from "../../assets/icons";

const PaymentMethodSwitcher = ({ control, className, radioClassName, onSwitch, integrationCode }) => {
  const isApplePayAvailable = useLoadApplePayBtn();
  const isHttpsProtocol = checkIsHttpsProtocol();
  const IsExternalPaymentMethodsAllow = checkIsExternalPaymentMethodAllowed(integrationCode);
  const isSafari = checkIsSafari();
  const showGooglePaySwitcher = isHttpsProtocol && IsExternalPaymentMethodsAllow;
  const showApplePaySwitcher = isHttpsProtocol && isSafari && IsExternalPaymentMethodsAllow;
  const showSwithers = showGooglePaySwitcher || showApplePaySwitcher;

  const {
    field: { value, onChange, ...field },
  } = useControlledInput({
    control,
    name: "payment_method",
    defaultValue: paymentMethods.CREDIT_CARD,
  });

  const handleChange = (e) => {
    onChange(e);
    onSwitch?.(e);
  };

  return (
    <div className={join([className, showSwithers ? "mg-b-6 mg-md-t-6 mg-t-0" : "mg-0"], " ")}>
      {showGooglePaySwitcher && (
        <Radio
          name="payment_method"
          label={"Credit Card"}
          value={paymentMethods.CREDIT_CARD}
          selected={paymentMethods.CREDIT_CARD === value}
          className={radioClassName}
          onChange={handleChange}
          {...field}
        />
      )}
      {showGooglePaySwitcher && (
        <Radio
          name="payment_method"
          label={<GooglePayMarkIcon />}
          value={paymentMethods.G_PAY}
          selected={paymentMethods.G_PAY === value}
          className={radioClassName}
          onChange={handleChange}
          {...field}
        />
      )}

      {showApplePaySwitcher && (
        <Radio
          disabled={!isApplePayAvailable}
          name="payment_method"
          label={<ApplePayBtnIcon />}
          value={paymentMethods.APPLE_PAY}
          selected={paymentMethods.APPLE_PAY === value}
          className={radioClassName}
          onChange={handleChange}
          {...field}
        />
      )}
    </div>
  );
};

PaymentMethodSwitcher.propTypes = {
  control: T.object,
  className: T.string,
  radioClassName: T.string,
  integrationCode: T.string,
  onSwitch: T.func,
};

export default PaymentMethodSwitcher;
