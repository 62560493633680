import * as React from "react";

function SvgLocation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98166 15.0259C4.15106 15.7581 3 16.8193 3 18C3 20.2092 7.02944 22 12 22C16.9706 22 21 20.2092 21 18C21 16.8193 19.8489 15.7581 18.0183 15.0259C17.5422 15.6028 17.0424 16.1379 16.5459 16.6258C16.9124 16.7393 17.249 16.8647 17.5517 16.9992C18.2133 17.2933 18.6248 17.5916 18.8439 17.8218C18.9219 17.9037 18.9634 17.963 18.9848 18C18.9634 18.0371 18.9219 18.0964 18.8439 18.1782C18.6248 18.4085 18.2133 18.7068 17.5517 19.0008C16.234 19.5865 14.2737 19.9998 12.0022 20C12.0014 20 12.0007 20 12 20C9.72757 20 7.76643 19.5867 6.44832 19.0008C5.78673 18.7068 5.37516 18.4085 5.15605 18.1782C5.07812 18.0964 5.03659 18.0371 5.01516 18C5.03659 17.963 5.07812 17.9037 5.15605 17.8218C5.37516 17.5916 5.78673 17.2933 6.44832 16.9992C6.75101 16.8647 7.08761 16.7393 7.45411 16.6258C6.95763 16.1379 6.45785 15.6028 5.98166 15.0259ZM19.006 17.9534C19.0063 17.9534 19.0056 17.9568 19.003 17.9636C19.0043 17.9568 19.0056 17.9534 19.006 17.9534ZM4.99402 17.9534C4.99436 17.9534 4.99567 17.9568 4.99699 17.9636C4.99435 17.9568 4.99369 17.9534 4.99402 17.9534ZM4.99402 18.0466C4.99369 18.0466 4.99435 18.0433 4.99699 18.0365C4.99567 18.0432 4.99436 18.0466 4.99402 18.0466ZM19.003 18.0365C19.0056 18.0433 19.0063 18.0467 19.006 18.0466C19.0056 18.0466 19.0043 18.0432 19.003 18.0365Z"
        fill="#151209"
        className="color"
      />
      <path
        d="M8.4828 4.5337C9.42403 3.54765 10.6899 3.00197 12 3C13.3101 3.00197 14.576 3.54765 15.5172 4.5337C16.4601 5.52145 16.9982 6.87009 17 8.28605C16.9999 10.6418 15.7979 12.781 14.4685 14.4126C13.48 15.6258 12.4749 16.492 12 16.8743C11.5251 16.492 10.52 15.6258 9.53154 14.4126C8.20214 12.7811 7.00019 10.642 7 8.28627C7.00175 6.87023 7.5399 5.52151 8.4828 4.5337Z"
        stroke="#151209"
        strokeWidth="2"
        className="stroke-color"
      />
      <circle cx="12" cy="8" r="2" className="color" fill="#151209" />
    </svg>
  );
}

export default SvgLocation;
