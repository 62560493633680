import React from "react";
import T from "prop-types";

import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

const getPriceByKlass = (pi) => {
  switch (pi.klass) {
    case "hotel":
      return Number(pi?.hotel_line_items?.[0]?.price) ?? 0;
    case "tour":
      return Number(pi?.tour_line_items?.[0]?.price) ?? 0;
    case "activity":
      return Number(pi?.activity_line_items?.[0]?.price) ?? 0;
    default:
      return 0;
  }
};

const ExtraOrderSummary = ({ selectedExtras, selectedProductExtras, chargeCurrency }) => {
  const { mainCls, elCls } = parseClasses({ base: "summary-extras" });
  const { t } = useTranslation();
  const currencyChar = checkCurrencyChar(chargeCurrency);

  const totalExtrasSum = selectedExtras.reduce(
    (acc, extra) => acc + Number(extra.calculated_item_price) * Number(extra.quantity),
    0,
  );

  const totalProductsSum = selectedProductExtras.reduce((acc, extra_pi) => acc + getPriceByKlass(extra_pi), 0);

  if (!selectedExtras.length && !selectedProductExtras.length) {
    return null;
  }

  return (
    <div className={mainCls}>
      <p className={elCls("title")}>{"ORDER SUMMARY:"}</p>
      {selectedExtras.map((extra) => {
        return (
          <div key={extra.id} className={elCls("extra-item")}>
            <p className={elCls("extra-name", "mg-t-0 mg-b-1")}>
              {`${extra.quantity} ${extra.display_name}`}
              <b>{` × ${currencyChar}${(Number(extra.calculated_item_price) * Number(extra.quantity)).toFixed(2)}`}</b>
            </p>
          </div>
        );
      })}
      {selectedProductExtras.map((pi_extra) => {
        return (
          <div key={pi_extra.id} className={elCls("extra-item")}>
            <p className={elCls("extra-name", "mg-t-0 mg-b-1")}>
              {`${pi_extra.display_name}`}
              <b>{` x ${currencyChar}${getPriceByKlass(pi_extra).toFixed(2)}`}</b>
            </p>
          </div>
        );
      })}
      <div className={elCls("total-sum", "bold uppercase mg-t-3")}>
        <span>{t("summary.totalCharge")}</span>
        <span>{`${currencyChar}${(Number(totalExtrasSum) + Number(totalProductsSum)).toFixed(2)}`}</span>
      </div>
    </div>
  );
};

ExtraOrderSummary.propTypes = {
  selectedExtras: T.array,
  selectedProductExtras: T.array,
  chargeCurrency: T.string,
};

export default ExtraOrderSummary;
