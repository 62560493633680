import React, { useEffect } from "react";
import T from "prop-types";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { getListOfChangedHotels } from "@tint_fe/helpers";
import { notification, Timer } from "@tint_fe/ui";
import { useRouterMatch } from "@tint_fe/hooks";

import { useReduxAction, useTranslation } from "../../hooks";
import { setChangedRatesAction } from "../../redux/global/globalActions";
import { selectChangedProducts } from "../../redux/sharedSelectors";
import { PAYMENT } from "../router/routes";

const RoomsTimer = ({ expireAt, timeToShow, onTimeExpired, preventShowNotify, hideTimer }) => {
  const setChangedRates = useReduxAction(setChangedRatesAction);

  const { changedProducts } = useSelector(selectChangedProducts);
  const ratesWasExpired = useSelector(({ global }) => global.ratesWasExpired.data);
  const ratesUpdating = useSelector(({ global }) => global.ratesWasExpired.updating);

  const { t } = useTranslation();

  const listOfChangedHotels = getListOfChangedHotels(ratesWasExpired?.changedHotels, t);
  const isPaymentPage = useRouterMatch(PAYMENT);

  const handleExpired = (data = {}) => {
    onTimeExpired({
      isPaymentPage,
      ...data,
    });
  };

  const handleCloseAlert = () => {
    setChangedRates(null);
  };

  useEffect(() => {
    if (changedProducts?.length) {
      setChangedRates({ changedHotels: changedProducts });
    }
  }, [changedProducts?.length]);

  useEffect(() => {
    if (
      ratesWasExpired &&
      !ratesWasExpired?.priceIsChanged &&
      !ratesWasExpired?.changedHotels?.length &&
      !preventShowNotify
    ) {
      notification.infoBig({
        options: {
          onClose: handleCloseAlert,
        },
        props: {
          title: "timer.alert.priceIsSame.title",
          children: <p className="mg-0 p3">{t("timer.alert.priceIsSame.text")}</p>,
        },
      });
    }

    if (
      ratesWasExpired &&
      (!!ratesWasExpired?.priceIsChanged || !!ratesWasExpired?.changedHotels?.length) &&
      !preventShowNotify
    ) {
      notification.successBig({
        options: {
          onClose: handleCloseAlert,
        },
        props: {
          title: "timer.alert.hotelsChanged.title",
          children: (
            <>
              {!isEmpty(listOfChangedHotels) && (
                <Trans parent="p" i18nKey="timer.alert.hotelsChanged.text.changedHotel" className="mg-0 mg-b-1 p3">
                  Hotel availability and rates are constantly changing. We've re-checked the hotels included in your
                  package and have changed {{ list: listOfChangedHotels?.join(", ") }}. We've updated the total price
                  and hope you like the change. If not, you can always use the change hotel feature to select a hotel
                  you will prefer.
                </Trans>
              )}

              {ratesWasExpired?.priceIsChanged && isEmpty(listOfChangedHotels) && (
                <p className="mg-0 p3">{t("timer.alert.hotelsChanged.text.noChangedHotel")}</p>
              )}
            </>
          ),
        },
      });
    }
  }, [ratesWasExpired, preventShowNotify]);

  return (
    !hideTimer && (
      <Timer
        onTimeExpired={handleExpired}
        expireAt={expireAt}
        timeToShow={timeToShow}
        ratesUpdating={ratesUpdating}
        className="mg-b-4"
      />
    )
  );
};

RoomsTimer.propTypes = {
  expireAt: T.number,
  showTimer: T.bool,
  timeToShow: T.number,
  onTimeExpired: T.func,
  preventShowNotify: T.bool,
  hideTimer: T.bool,
};

export default RoomsTimer;
