import React from "react";
import T from "prop-types";
import { useFormContext, Controller } from "react-hook-form";

import { parseClasses, validationRule } from "@tint_fe/helpers";
import { Product as ProductIcon } from "../../assets/icons";
import { Select } from "../../components";

const Product = ({ productName, id, travelers, documents }) => {
  const { mainCls, elCls } = parseClasses({ base: "product" });
  const { control, getValues, clearErrors, setValue } = useFormContext();

  const handleChange = ({ value }, name) => {
    clearErrors();
    const values = getValues(`products.${id}`);

    values.forEach((el, index) => {
      if (el === value) {
        setValue(`products.${id}.${index}`, null);
      }
    });

    setValue(name, value);

    return value;
  };

  return (
    <div className={mainCls}>
      <div className={elCls("name", "bold")}>
        <ProductIcon />
        <span className="mg-r-3 p6">{productName}</span>
      </div>
      {travelers?.map((item) => (
        <Controller
          key={item.key}
          control={control}
          defaultValue={null}
          name={`products.${id}.${item.number}`}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { onChange, value, ...restField }, fieldState }) => {
            return (
              <Select
                isSearchable={false}
                label={`Traveler ${item.number + 1}`}
                options={documents}
                showOptionFrom={0}
                onChange={(val) => handleChange(val, `products.${id}.${item.number}`)}
                value={documents?.find((el) => el.value === value) || null}
                {...restField}
                {...fieldState}
              />
            );
          }}
          rules={{ ...validationRule.required(`Traveler ${item.number + 1}`) }}
        />
      ))}
    </div>
  );
};

Product.propTypes = {
  productName: T.string,
  travelers: T.array,
  documents: T.array,
  id: T.string,
};

export default Product;
