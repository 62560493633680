import * as React from "react";

const BadgePopular = () => (
  <svg width={48} height={11} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1a1 1 0 0 1 1-1h46a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1Z" fill="var(--extra-color-50)" />
    <path
      d="M2.921 9h1.42V6.16h.955c1.57 0 2.4-.725 2.4-2.095 0-1.365-.81-2.035-2.4-2.035H2.921V9Zm1.42-4.115V3.31h.81c.75 0 1.115.185 1.115.77 0 .615-.41.805-1.115.805h-.81Zm7.324 4.285c2 0 3.386-1.455 3.386-3.65 0-2.205-1.366-3.66-3.386-3.66-2 0-3.374 1.465-3.374 3.66 0 2.185 1.37 3.65 3.374 3.65Zm-1.94-3.65c0-1.43.755-2.29 1.94-2.29 1.19 0 1.94.88 1.94 2.29 0 1.4-.75 2.28-1.94 2.28-1.184 0-1.94-.86-1.94-2.28ZM16.037 9h1.42V6.16h.955c1.57 0 2.4-.725 2.4-2.095 0-1.365-.81-2.035-2.4-2.035h-2.375V9Zm1.42-4.115V3.31h.81c.75 0 1.115.185 1.115.77 0 .615-.41.805-1.115.805h-.81Zm6.88 4.285c1.79 0 2.72-.91 2.72-2.74v-4.4h-1.43v4.3c0 1.005-.25 1.525-1.29 1.525-1.05 0-1.285-.51-1.285-1.54V2.03h-1.43v4.45c0 1.8.92 2.69 2.715 2.69ZM28.253 9h4.345V7.72h-2.915V2.03h-1.43V9Zm4.488 0h1.56l.48-1.27h2.68L37.936 9h1.56l-2.845-6.97h-1.06L32.741 9Zm2.525-2.55.865-2.295.855 2.295h-1.72ZM40.176 9h1.43V6.18H42.293L43.927 9h1.655l-1.835-3.015c.775-.3 1.205-.94 1.205-1.915 0-1.235-.675-2.04-2.4-2.04h-2.375V9Zm1.43-4.1V3.31h.8c.57 0 1.116.09 1.116.78 0 .69-.535.81-1.115.81h-.8Z"
      fill="#fff"
    />
  </svg>
);

export default BadgePopular;
