import * as React from "react";

function SvgStar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M13.948 5.088a1.023 1.023 0 00-.908-.71l-3.712-.26L7.951.651A1.02 1.02 0 007.001 0h-.002a1.02 1.02 0 00-.949.65L4.653 4.139l-3.692.24a1.023 1.023 0 00-.908.71 1.043 1.043 0 00.322 1.13l2.84 2.4-.845 3.322a1.149 1.149 0 00.439 1.22 1.115 1.115 0 001.254.04l2.93-1.857c.003-.003.007-.005.016 0l3.152 1.997a1.008 1.008 0 001.138-.035 1.04 1.04 0 00.398-1.105l-.898-3.63 2.827-2.353a1.043 1.043 0 00.322-1.129z"
        fill="#151209"
      />
    </svg>
  );
}

export default SvgStar;
