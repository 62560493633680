import React, { useEffect, useMemo, useState, useRef } from "react";
import T from "prop-types";
import { v1 as uuidv1 } from "uuid";
import RcSelect, { components as rcComponents } from "react-select";

import { parseClasses, checkIfContentOverflow, checkIsIOS } from "@tint_fe/helpers";
import { useBreakPoints, useTranslation } from "@tint_fe/hooks";
import { InputWrapper } from "../..";
import SelectOption from "./SelectOption";
import SelectClearValue from "./SelectClearValue";
import SelectDropdownIndicator from "./SelectDropdownIndicator";

const Select = React.forwardRef(
  (
    {
      autoFocus,
      classNameWrapper,
      clearValueOnSelect,
      components,
      error,
      hideDropdownIndicator,
      isDisabled,
      isPreselected,
      label,
      nextIcon,
      onReset,
      onSelect,
      openOnHover,
      options,
      outline,
      prevIcon,
      remark,
      scrollIntoViewOnError,
      showOptionFrom,
      styles,
      value,
      withoutOverflowTooltip,
      onFocus,
      onBlur,
      loading,
      clickOnMount,
      useT,
      ...rest
    },
    ref,
  ) => {
    const timerRef = useRef();
    const { isRtl } = useTranslation();
    const { isSm, isLg } = useBreakPoints();
    const [isContentOverflow, setContentOverflow] = useState(false);
    const id = useMemo(() => uuidv1().replace(/-/g, "").replace(/[0-9]/g, ""), []);

    const inputRef = useRef({});

    const [openMenu, setOpenMenu] = useState(false);
    const [showOption, setShowOption] = useState();
    const [focus, setFocus] = useState(false);

    const isHandleHover = openOnHover && !isLg;

    const willHaveDropdownIcon = !!options?.length && !isDisabled;

    const { mainCls, elCls } = parseClasses({
      base: "select",
      classes: [
        prevIcon && "with-prev-icon",
        focus && "focused",
        value && "with-value",
        nextIcon && "with-next-icon",
        isRtl && "rtl",
        isLg && isContentOverflow && "with-overflow-icon-tooltip",
        isPreselected && "preselected",
      ],
      additional: classNameWrapper,
    });

    const handleIfContentOverflow = () => {
      timerRef.current = setTimeout(() => {
        const content = document.querySelector(`#${id} .rc-select__single-value`);

        setContentOverflow(checkIfContentOverflow(content));
      }, 100);
    };

    const handleChange = (val, { action }) => {
      switch (action) {
        case "clear":
          return onReset();
        case "select-option":
          onSelect && onSelect(val);
          rest.onChange && rest.onChange(val);

          return;
        default:
          return val;
      }
    };

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus]);

    useEffect(() => {
      if (showOptionFrom === 0) {
        setShowOption(true);
      }
    }, [showOptionFrom]);

    useEffect(() => {
      if (!withoutOverflowTooltip) {
        handleIfContentOverflow();

        return () => {
          clearTimeout(timerRef.current);
        };
      }
    }, [value]);

    return (
      <InputWrapper
        classNameWrapper={mainCls}
        disabled={isDisabled}
        error={error}
        focus={focus}
        id={id}
        isContentOverflow={isContentOverflow}
        label={label}
        outline={outline}
        prevIcon={prevIcon}
        ref={inputRef}
        remark={remark}
        scrollIntoViewOnError={scrollIntoViewOnError}
        showValidateMark={false}
        value={value?.label}
        withValue={!!value?.label}
        withoutOverflowTooltip={withoutOverflowTooltip}
        willHaveDropdownIcon={willHaveDropdownIcon}
        useT={useT}
        {...(isHandleHover && {
          onMouseEnter: () => {
            setOpenMenu(true);
          },
          onMouseLeave: () => {
            setOpenMenu(false);
          },
        })}
      >
        <RcSelect
          id={id}
          ref={(selectRef) => {
            if (ref) {
              ref.current = selectRef?.current;
            }
            inputRef.current = selectRef;
          }}
          isRtl={isRtl}
          cacheOptions
          className="rc-select"
          classNamePrefix={`${checkIsIOS(true) ? "rc-select" : "win-rc-select rc-select"}`}
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: 0,
              ...styles,
            }),
            input: (base) => ({
              ...base,
              margin: 0,
              paddingBottom: 0,
              paddingTop: 0,
            }),
          }}
          components={{
            Option: SelectOption,
            DropdownIndicator: (props) => {
              if (loading) {
                return (
                  <div className={`circle-loader  ${isRtl}`}>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="46" />
                    </svg>
                  </div>
                );
              }

              return (
                <SelectDropdownIndicator
                  nextIcon={nextIcon}
                  onReset={onReset}
                  hideDropdownIndicator={hideDropdownIndicator}
                  elCls={elCls}
                  {...props}
                />
              );
            },
            IndicatorSeparator: () => null,
            ClearIndicator: SelectClearValue,
            // eslint-disable-next-line react/prop-types
            SingleValue: ({ children, ...props }) => (
              <rcComponents.SingleValue {...props}>
                <span dir="auto">{children}</span>
              </rcComponents.SingleValue>
            ),
            GroupHeading: ({ ...props }) => {
              // eslint-disable-next-line react/prop-types
              if (!props?.selectProps?.options[0]?.label) {
                return null;
              }

              return (
                <rcComponents.GroupHeading {...props}>
                  {/* eslint-disable-next-line react/prop-types */}
                  <span dir="auto">{props?.selectProps?.options[0]?.label}</span>
                </rcComponents.GroupHeading>
              );
            },
            ...components,
          }}
          value={value}
          {...rest}
          onChange={handleChange}
          isClearable={!!onReset}
          {...(clearValueOnSelect && { value: null })}
          onFocus={() => {
            setFocus(true);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setFocus(false);
            onBlur && onBlur();
          }}
          onInputChange={(val) => {
            val.length > showOptionFrom && setShowOption(true);
          }}
          onMenuClose={() => {
            setShowOption(false);
          }}
          options={showOption || showOptionFrom === 0 || openOnHover ? options : []}
          openMenuOnFocus={!isSm && clickOnMount}
          defaultMenuIsOpen={isSm && clickOnMount}
          blurInputOnSelect={isSm}
          noOptionsMessage={() => (showOption ? "No Options" : null)}
          maxMenuHeight={isSm ? 150 : 300}
          isDisabled={isDisabled || loading}
          {...(openOnHover && { menuIsOpen: !isLg ? openMenu : false })}
          {...(openOnHover && { openMenuOnFocus: true })}
        />
      </InputWrapper>
    );
  },
);

Select.defaultProps = {
  showOptionFrom: 2,
  styles: {},
  useT: true,
};

Select.propTypes = {
  autoFocus: T.bool,
  classNameWrapper: T.string,
  clearValueOnSelect: T.bool,
  components: T.object,
  error: T.object,
  hideDropdownIndicator: T.bool,
  isDisabled: T.bool,
  isPreselected: T.bool,
  label: T.string,
  nextIcon: T.node,
  onReset: T.func,
  onSelect: T.func,
  openOnHover: T.bool,
  options: T.array,
  outline: T.bool,
  prevIcon: T.node,
  remark: T.string,
  scrollIntoViewOnError: T.bool,
  showOptionFrom: T.number,
  styles: T.object,
  value: T.oneOfType([T.object, T.number, T.string]),
  withoutOverflowTooltip: T.bool,
  onFocus: T.func,
  onBlur: T.func,
  loading: T.bool,
  clickOnMount: T.bool,
  useT: T.bool,
};

export default Select;
