import React from "react";
import T from "prop-types";

import { BookingLayout } from "../../../Layouts";
import ActivitySummary from "../ActivitySummary/ActivitySummary";

const ActivityLayout = ({ children }) => {
  return (
    <BookingLayout dataTestid="tour-details-form" summary={<ActivitySummary />}>
      {children}
    </BookingLayout>
  );
};

ActivityLayout.propTypes = {
  children: T.any.isRequired,
};

export default ActivityLayout;
