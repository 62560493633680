import { dates, toBinaryStr } from "../utils";

export const serializeActivityOrderBody = (product, formData) => {
  const { product_id, pickup_point_id, custom_pickup_point, start_date, start_time, ticket_option, language_code } =
    formData.reservationDetails;

  const productItem = {
    product_id: product.product_id || product.id || product_id,
    start_date: dates.format(start_date, "yyyy-MM-dd", false),
    language_code,
    start_time,
    ticket_option,
  };

  productItem.custom_pickup_lat = custom_pickup_point?.lat;
  productItem.custom_pickup_lon = custom_pickup_point?.lon;
  productItem.custom_pickup_point = custom_pickup_point?.name;
  productItem.city_id = product?.pickup_points?.find((point) => point.id === pickup_point_id)?.city_id;

  productItem.pickup_point_id = pickup_point_id;

  const newPriceBandLineItems = {};

  if (formData.whoTraveling) {
    let i = 0;
    Object.entries(formData.whoTraveling).forEach(([id, quantity]) => {
      const booking_code = id;
      if (booking_code && quantity) {
        newPriceBandLineItems[i] = {
          booking_code: toBinaryStr(booking_code),
          quantity: quantity,
        };
      }
      i++;
    });
  }

  productItem.activity_line_items_attributes = newPriceBandLineItems;

  return productItem;
};

export const formatActivityOrderBody = (productItem, custom_pickup_point_strategy) => {
  const payload_pi = { ...productItem };
  if (custom_pickup_point_strategy) {
    delete payload_pi.pickup_point_id;
  } else {
    delete payload_pi.city_id;
    delete payload_pi.custom_pickup_lat;
    delete payload_pi.custom_pickup_lon;
    delete payload_pi.custom_pickup_point;
  }
  payload_pi.start_from = productItem.custom_pickup_point;

  return payload_pi;
};
