import React, { useEffect, useRef } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { isEmpty, isObject } from "lodash";

import { QueryString, formatPricePerItem } from "@tint_fe/helpers";
import FormHeader from "../../../../components/Form/FormHeader";
import ControllerInput from "../../../../components/Form/ControllerInput";
import NumericField from "../../../../components/Form/NumericField";
import { useTranslation } from "../../../../../hooks";
import useBackToCheckoutStep from "../../hooks/useBackToCheckoutStep";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";
import { selectProductSettings, selectAvailableLangs } from "../../../../../redux/product/productSelectors";

const WhoTravelingForm = ({ defaultWhoTraveling, showExtras }) => {
  const updateTourPrice = useUpdateTourPrice();
  const goBackToCheckoutStep = useBackToCheckoutStep();
  const { control, getValues, trigger, watch, setValue } = useFormContext();

  const priceBands = useSelector((state) => state.product.priceBands);
  const availableLangs = useSelector(selectAvailableLangs);
  const { custom_pickup_point_strategy } = useSelector(selectProductSettings);
  const fields = useSelector((state) => state.product.priceBands);
  const price = useSelector((state) => state.global.price);

  const pickup_point_id = watch("pickup_point_id");
  const city_id = watch("city_id");
  const language_code = watch("language_code");

  const { t } = useTranslation();
  const defValueUpdated = useRef(false);

  const availableQuantity = (availableLangs.find((x) => x.code === language_code) || {}).available_quantity;

  const showPriceBands = !!(priceBands.length > 0 && (pickup_point_id || (custom_pickup_point_strategy && city_id)));

  const priceLineItems = price?.product_items?.[0]?.line_items || [];

  const isDisabled = Object.values(fields).reduce((acc, cur) => acc + cur, 0) >= availableQuantity;

  const isAllQuantityFilled = () => {
    const who_traveling = getValues && getValues("who_traveling");

    return Object.values(who_traveling ?? {}).reduce((a, b) => a + b, 0) >= availableQuantity;
  };

  const revalidatePriceBands = (id) => {
    priceBands.forEach((el) => {
      if (id !== el.id) {
        trigger(`who_traveling.${el.id}`);
      }
    });
  };

  const onChangeHandler = (data, id) => {
    defValueUpdated.current = true;
    const val = data[`who_traveling.${id}`];
    updateTourPrice({ who_traveling: { [id]: val } }, { getExtrasFromFormContext: true });
    revalidatePriceBands();

    goBackToCheckoutStep();
  };

  useEffect(() => {
    const who_traveling = getValues && getValues("who_traveling");
    const isWhoTravelingSelected = isObject(who_traveling) && Object.values(who_traveling).some(Boolean);

    if (showPriceBands && !showExtras && !isWhoTravelingSelected) {
      const { order: orderNumber } = QueryString.parse(document.location.search);

      setTimeout(() => {
        updateTourPrice(
          {},
          {
            preventSendingProductItems: !!orderNumber && isWhoTravelingSelected,
            sendOrderNumber: isWhoTravelingSelected && orderNumber,
          },
        );
      }, 300);
    }
  }, [showPriceBands, showExtras]);

  useEffect(() => {
    if (!isEmpty(defaultWhoTraveling) && !defValueUpdated.current) {
      defValueUpdated.current = true;

      Object.keys(defaultWhoTraveling).forEach((id) => {
        setValue(`who_traveling.${id}`, defaultWhoTraveling[id]);
      });
    }
  }, [defaultWhoTraveling]);

  if (!showPriceBands) {
    return null;
  }

  return (
    <>
      <FormHeader text={t("numberOfPeople")} />
      {priceBands.map(({ id, name: label, capacity_values, range_price }) => {
        const priceItem = priceLineItems.find((item) => id === item.product_option_id);

        return (
          <ControllerInput
            key={`price-band-${id}`}
            control={control}
            name={`who_traveling.${id}`}
            shouldUnregister
            defaultValue={0}
            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => {
              return (
                <NumericField
                  id={id}
                  price={formatPricePerItem(priceItem?.rounded_price_per_person)}
                  label={label}
                  max={availableQuantity}
                  incrementDisabled={isDisabled || isAllQuantityFilled()}
                  options={capacity_values}
                  onChange={(val) => {
                    onChange(val);
                    onChangeHandler(val, id);
                  }}
                  preventToShowPrice={range_price && priceItem?.quantity === 0}
                  errors={error?.message}
                  value={value || 0}
                  currency={price?.charge_currency}
                  {...restField}
                />
              );
            }}
            rules={{
              validate: () => {
                const whoTraveling = getValues("who_traveling");
                const notEmpty = whoTraveling && Object.values(whoTraveling).some((el) => el > 0);

                return notEmpty || "error.adults";
              },
            }}
          />
        );
      })}
    </>
  );
};

WhoTravelingForm.propTypes = {
  defaultWhoTraveling: T.object,
  showExtras: T.bool,
};

export default WhoTravelingForm;
