import React from "react";
import { useFormContext } from "react-hook-form";
import T from "prop-types";

import { normalizedValidateError, validationRule } from "@tint_fe/helpers";

import { useTranslation } from "../../../../../hooks";
import ControllerInput from "../../../../components/Form/ControllerInput";
import GoogleAutocomplete from "../../../../components/Form/GoogleAutocomplete";
import { Checkbox } from "@tint_fe/ui";

const GuestAccomodation = ({ isRequired }) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { isSubmitted },
    setValue,
  } = useFormContext();

  return (
    <>
      <ControllerInput
        control={control}
        name="custom_pickup_point"
        render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
          <GoogleAutocomplete
            label={t("formField.location")}
            placeholder={t("formField.whereAreYouStaying")}
            disabled={false}
            errors={normalizedValidateError(error, "formField.startLocation")}
            showValidateMark={isSubmitted}
            onChange={onChange}
            onSelect={onChange}
            classNameWrapper={"mg-b-2"}
            {...rest}
          />
        )}
        rules={{
          ...(isRequired && validationRule.required("formField.startLocation")),
          ...(isRequired && { validate: validationRule.validateGoogleAutocompleteRequired() }),
        }}
      />
      {!isRequired && (
        <ControllerInput
          control={control}
          name="did_not_booked_yet"
          render={({ field: { onChange, ...restField } }) => {
            return (
              <Checkbox
                className={"mg-b-6"}
                label={t("formField.didNotBookedYet")}
                onClick={() => {
                  if (restField.value) {
                    setValue("custom_pickup_point", { lat: null, lon: null, name: "" });
                  } else {
                    setValue("custom_pickup_point", { lat: null, lon: null, name: "I didn't book accommodation yet" });
                  }

                  return onChange(!restField.value);
                }}
                {...restField}
              />
            );
          }}
        />
      )}
    </>
  );
};

GuestAccomodation.propTypes = {
  isRequired: T.bool,
};

export default GuestAccomodation;
