import { combineReducers } from "redux";

import globalReducer from "./global/globalReducer";
import order from "./order/orderReducer";
import product from "./product/productReducer";
import hotel from "./hotel/hotelReducer";
import smartPackage from "./smartPackage/smartPackageReducer";

export default combineReducers({
  global: globalReducer,
  order,
  product,
  hotel,
  smartPackage,
});
