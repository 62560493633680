import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";

import { TintProsCheckMark, TintProsCrossMark } from "../../assets/icons";

const SummaryHint = ({ hint, small, className }) => {
  const { mainCls } = parseClasses({
    base: "hint",
    classes: [small && "small"],
    additional: [className],
  });

  if (hint && typeof hint === "string") {
    return (
      <div className={mainCls}>
        {hint.includes("Non-Refundable") ? <TintProsCrossMark /> : <TintProsCheckMark />}
        <span className={"mg-0 p4 mg-l-2"}>{hint}</span>
      </div>
    );
  }

  if (Array.isArray(hint) && hint.length) {
    return hint.map((hintItem) => (
      <div key={hintItem} className={mainCls}>
        {hintItem.includes("Non-Refundable") ? <TintProsCrossMark /> : <TintProsCheckMark />}
        <span className={"mg-0 p4 mg-l-2"}>{hintItem}</span>
      </div>
    ));
  }

  return null;
};

SummaryHint.propTypes = {
  hint: T.oneOfType([T.string, T.arrayOf(T.string)]),
  small: T.bool,
  className: T.string,
};

export default SummaryHint;
