import { useEffect } from "react";
import { isEmpty, isNumber } from "lodash";
import { useSelector } from "react-redux";

import { dates } from "@tint_fe/helpers";
import { getOrderWithProductsRequest } from "../../redux/order/orderActions";
import { getSmartPackageRequest } from "../../redux/smartPackage/smartPackageAction";
import { setErrorAction, setStepAction, setLoadingAction } from "../../redux/global/globalActions";
import {
  useReduxAction,
  useHistoryNavigation,
  useCreateHotelOrderFromQueryParams,
  useCreateSpOrderFromQueryParams,
  useCreateActivityOrderFromQueryParams,
  useCreateTourOrderFromQueryParams,
} from "../../hooks";
import { steps, PACKAGE_FORMAT_DATE } from "../../helpers/constants";
import { getProductRequest } from "../../redux/product/productActions";
import { getNumberFromStr } from "../../utils";

const parseProductId = (id) => {
  return isNumber(id) ? id : getNumberFromStr(id);
};

const useGetInitProduct = (searchParams) => {
  const getOrderWithProducts = useReduxAction(getOrderWithProductsRequest);
  const getSmartPackage = useReduxAction(getSmartPackageRequest);
  const setStep = useReduxAction(setStepAction);
  const setError = useReduxAction(setErrorAction);
  const getProduct = useReduxAction(getProductRequest);
  const turnOffLoader = useReduxAction(setLoadingAction);
  const createHotelOrderFromQueryParams = useCreateHotelOrderFromQueryParams();
  const createActivityOrderFromQueryParams = useCreateActivityOrderFromQueryParams();
  const createSpOrderFromQueryParams = useCreateSpOrderFromQueryParams();
  const createTourOrderFromQueryParams = useCreateTourOrderFromQueryParams();

  const step = useSelector((state) => state.global.step);
  const { sp: hasSP } = searchParams;
  const loadingOfProductCreationFromUrl = !step && !!hasSP;

  const integration = useSelector(({ global }) => global.integration);

  const { replace, location } = useHistoryNavigation();

  /* Get order or product only when component is mounted */
  useEffect(() => {
    if (!isEmpty(integration)) {
      const {
        hotel,
        tour,
        activity,
        sp,
        order,
        packageId,
        productId,
        pid,
        tourId,
        tourid,
        activityId,
        activityid,
        ...rest
      } = searchParams;

      const handledProductId = productId || pid || tourId || tourid || activityId || activityid;

      switch (true) {
        case !!hotel:
          createHotelOrderFromQueryParams();
          break;
        case !!tour:
          createTourOrderFromQueryParams();
          break;
        case !!activity:
          createActivityOrderFromQueryParams();
          break;
        case !!sp:
          createSpOrderFromQueryParams(parseProductId(packageId || handledProductId));
          break;
        case !!order:
          getOrderWithProducts({
            orderId: order,
            location,
            replace,
            ...rest,
          });
          break;
        case !!packageId || handledProductId?.includes("PK"):
          getSmartPackage({
            id: parseProductId(packageId || handledProductId),
            query: { start_date: dates.format(new Date(), PACKAGE_FORMAT_DATE) },
            replace,
            location,
          });
          break;
        case !!handledProductId:
          getProduct({ id: parseProductId(handledProductId), replace });
          setStep(steps.CHECKOUT);
          break;
        default:
          turnOffLoader();
          setError({ messages: ["Product/order ID not found."] });
          break;
      }
    }
  }, [integration]);

  return { loadingOfProductCreationFromUrl };
};

export default useGetInitProduct;
