import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";
import { Img } from "../../components";
import { useBreakPoints } from "@tint_fe/hooks";

const DiscountBanner = ({ image, image_mobile, name, url }) => {
  const { mainCls, elCls } = parseClasses({ base: "discount-banner" });
  const { isMd } = useBreakPoints();

  const openDiscountUrl = () => {
    window.open(url, "_blank");
  };

  const src = isMd ? image_mobile || image : image;

  return (
    <div onClick={openDiscountUrl} className={mainCls}>
      <Img className={elCls("image")} alt={name || "discount banner"} withBorder={!image} src={src} />
    </div>
  );
};

DiscountBanner.propTypes = {
  image: T.string,
  name: T.string,
  url: T.string,
  image_mobile: T.string,
};

export default DiscountBanner;
