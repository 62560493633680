import { useController } from "react-hook-form";

import { isEmpty } from "lodash";

const useControlledInput = (params) => {
  const {
    field,
    fieldState: { error, ...fieldState },
    formState: { isSubmitting, isSubmitSuccessful, ...formState },
  } = useController({ ...params, defaultValue: params.defaultValue || "" });

  return {
    field,
    fieldState: { error, ...fieldState },
    formState: { isSubmitting, isSubmitSuccessful, ...formState },
    showValidateMark: !isEmpty(error) || isSubmitting || isSubmitSuccessful,
  };
};

export default useControlledInput;
