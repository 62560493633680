import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import Glide from "@glidejs/glide";
import { v4 as uuidV4 } from "uuid";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

import { CarouselArrow } from "../../assets/icons";

const defaultConfig = { perView: 1, startAt: 0, type: "carousel", gap: 8 };

// Issue => on IOS Google Chrome => case when Carousel covered modal => modal close => image blur.
// To resolve it we must force one more render, for it we have forceUpdateCarousel props

// TODO: need to refactor it and prevent to use strange hacks like setTimeout and delay prop
const Carousel = ({ list, config = {}, className, forceUpdateCarousel, delay }) => {
  const [mount, setMount] = useState();
  const { isRtl } = useTranslation();
  const ref = useRef();
  const { mainCls, elCls } = parseClasses({ base: "glide", additional: className });

  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    if (ref.current && mount) {
      let slider = {};

      // TODO: need to investigate howe to prevent using setTimeout
      setTimeout(() => {
        slider = new Glide(ref.current, {
          ...defaultConfig,
          ...config,
          bound: true,
          direction: isRtl ? "rtl" : "ltr",
        });

        slider.mount();
      }, delay);

      return () => slider?.destroy && slider.destroy();
    }
  }, [isRtl, mount, forceUpdateCarousel]);

  return (
    <div data-testid={"test-carousel-container"} ref={ref} className={mainCls}>
      <div className={elCls("track", "slider__track")} data-glide-el="track">
        <ul className={elCls("slides")}>
          {list?.map((el) => (
            <li data-testid={"test-carousel-img"} key={el.key || uuidV4()} className={elCls("slide")}>
              {el}
            </li>
          ))}
        </ul>
      </div>
      <div className={elCls("arrows")} data-glide-el="controls">
        <button className={elCls("arrow", "glide__arrow--left")} data-glide-dir="<">
          <CarouselArrow width={24} height={24} />
        </button>
        <button className={elCls("arrow", "glide__arrow--right")} data-glide-dir=">">
          <CarouselArrow width={24} height={24} />
        </button>
      </div>
    </div>
  );
};

Carousel.defaultProps = {
  delay: 300,
};

Carousel.propTypes = {
  list: T.array.isRequired,
  config: T.object,
  className: T.string,
  forceUpdateCarousel: T.bool,
  delay: T.number,
};

export default Carousel;
