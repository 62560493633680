import React from "react";
import T from "prop-types";

const Badge = ({ children }) => {
  return <div className="badge">{children}</div>;
};

Badge.propTypes = {
  children: T.node,
};

export default Badge;
