import { api } from "@tint_fe/api";

export const patchPostBookingExtraAsExtra = ({ orderNumber, product_item_id, body }) => {
  return api.patch({
    path: `/orders/${orderNumber}/product_items/${product_item_id}/extra_line_item`,
    body,
    withUuid: true,
  });
};

export const createPostBookingProductAsExtra = ({ orderNumber, body }) => {
  return api.post({
    path: `/orders/${orderNumber}/product_items`,
    body,
    withUuid: true,
  });
};

export const deleteProductItem = ({ orderNumber, productItemId }) => {
  return api.delete({
    path: `/orders/${orderNumber}/product_items/${productItemId}`,
    withUuid: true,
  });
};
