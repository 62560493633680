import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import { parseClasses, checkIfContentOverflow } from "@tint_fe/helpers";
import { InputWrapper } from "..";

const formatPlaceName = (place) => {
  if (place.formatted_address.includes(place.name)) return place.formatted_address;

  return `${place.name}, ${place.formatted_address}`;
};

const GoogleAutocomplete = ({
  label,
  placeholder,
  remark,
  value = {},
  disabled = false,
  errors,
  onChange,
  onSelect,
  classNameWrapper,
  showValidateMark,
  prevIcon,
}) => {
  const refInput = useRef({});
  const refAutocomplete = useRef({});
  const { isRtl } = useTranslation();
  const [isContentOverflow, setIsContentOverflow] = useState(false);

  const { mainCls } = parseClasses({
    base: "google-autocomplete",
    classes: [isContentOverflow && "content-overflow"],
    additional: [classNameWrapper],
  });

  const placeChangedTrigger = () => {
    const place = refAutocomplete.current.getPlace();
    const coordinates = !!place && !!place.geometry && place.geometry.location;

    if (coordinates) {
      onSelect({
        lat: coordinates.lat(),
        lon: coordinates.lng(),
        name: formatPlaceName(place),
      });
    }
  };

  useEffect(() => {
    const google = window.google;

    if (refInput.current && google) {
      refAutocomplete.current = new google.maps.places.Autocomplete(refInput.current);
      // Set the data fields to return when the user selects a place.
      refAutocomplete.current.setFields(["address_components", "geometry", "icon", "name", "formatted_address"]);

      const eventListener = refAutocomplete.current.addListener("place_changed", placeChangedTrigger);

      return () => {
        google.maps.event.removeListener(eventListener);
        google.maps.event.clearInstanceListeners(refAutocomplete.current);
        document.querySelector(".pac-container")?.remove();
      };
    }
  }, []);

  useEffect(() => {
    setIsContentOverflow(checkIfContentOverflow(refInput.current));
  }, [value]);

  return (
    <InputWrapper
      type="text"
      isContentOverflow={isContentOverflow}
      classNameWrapper={mainCls}
      label={label}
      error={errors}
      remark={remark}
      value={value?.name}
      disabled={disabled}
      showValidateMark={showValidateMark}
      prevIcon={prevIcon ?? null}
    >
      <input
        type="text"
        className="input"
        ref={refInput}
        placeholder={placeholder}
        value={value?.name || ""}
        disabled={disabled}
        dir={isRtl ? "rtl" : "auto"}
        onChange={(evt) => {
          if (value?.name !== evt.target.value) {
            onChange({ lat: null, lon: null, name: evt.target.value });
          }
        }}
      />
    </InputWrapper>
  );
};

GoogleAutocomplete.propTypes = {
  label: T.string,
  placeholder: T.string,
  remark: T.string,
  value: T.object,
  errors: T.object,
  disabled: T.bool,
  isSubmitting: T.bool,
  onChange: T.func.isRequired,
  onSelect: T.func.isRequired,
  classNameWrapper: T.string,
  showValidateMark: T.bool,
  prevIcon: T.node,
};

export default GoogleAutocomplete;
