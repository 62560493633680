import lodash, { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { serializeOrderProductDetails, serializeOrderProductPriceBands, sortPointsByArr } from "../helpers";

export const selectProduct = createSelector(
  [
    (state) => state.product.data,
    (state) => state.smartPackage.data,
    (state) => state.global.bookingType,
    (state) => state.order.data,
    (state) => state.smartPackage.order,
  ],
  (singleProduct = {}, smartPackage = {}, type, singleOrder = {}, smartPackageOrder = {}) => {
    const productFromOrder = singleOrder.product_items?.[0];

    return { ...singleProduct, ...smartPackage, type, ...productFromOrder, ...smartPackageOrder };
  },
);

export const selectSingleProduct = createSelector(
  (state) => state,
  (state) => {
    return state.product;
  },
);

export const makeSelectAvailablePriceBands = () => {
  return createSelector(
    selectSingleProduct,
    (_, city_id) => city_id,
    ({ priceBands }, city_id) => {
      if (isEmpty(priceBands)) return [];
      const availablePriceBands = city_id
        ? priceBands.filter((el) => el.cities.map((city) => city.id).includes(city_id))
        : priceBands;

      return lodash.orderBy(availablePriceBands, "name", "asc");
    },
  );
};

export const selectAvailableLangs = createSelector(selectSingleProduct, ({ availability }) => {
  return availability.availableLanguages || [];
});

export const getAvailableCities = (priceBands, pickupPoints = []) => {
  if (isEmpty(priceBands)) return [];

  const pickupPointsOrderArr = pickupPoints.map((point) => point.city_name);
  const availableCities = lodash
    .uniqBy(
      lodash.flatMap(priceBands, (x) => x.cities),
      "id",
    )
    .filter((city) => pickupPoints.some((point = {}) => point.city_id === city.id));

  return sortPointsByArr(availableCities, pickupPointsOrderArr);
};

export const selectAvailableCities = createSelector(selectSingleProduct, ({ priceBands, data: { pickup_points } }) => {
  return getAvailableCities(priceBands, pickup_points);
});

export const selectAvailablePickupPoints = createSelector(
  [selectSingleProduct, selectAvailableCities],
  ({ data: { pickup_points = [] }, priceBands }, availableCities) => {
    if (isEmpty(priceBands)) return [];

    const availablePickupPoints = pickup_points.filter((x) => {
      return availableCities.find((y) => y.id === x.city_id);
    });

    return availablePickupPoints;
  },
);

export const selectAvailableDropOffPoints = createSelector(
  selectSingleProduct,
  ({ data: { dropoff_points = [] }, priceBands }) => {
    if (isEmpty(priceBands)) return [];
    const availableDropOffPoints = dropoff_points.filter(Boolean);

    return availableDropOffPoints;
  },
);

export const selectProductSettings = createSelector(selectSingleProduct, (product) => product.data.settings);

export const selectProductValuesFromOrder = createSelector(
  [(state) => state.order.data, selectProductSettings, (state) => state.product.priceBands],
  (order, { custom_pickup_point_strategy }, priceBands) => {
    const productItem = order?.product_items?.[0];

    if (productItem) {
      const serializedProductDetails = serializeOrderProductDetails(productItem);
      const serializedProductPriceBands = serializeOrderProductPriceBands(productItem, priceBands);

      return {
        ...serializedProductDetails,
        ...(!custom_pickup_point_strategy && {
          location: { name: serializedProductDetails.start_from },
          did_not_booked_yet: serializedProductDetails.start_from === "I didn't book accommodation yet",
        }),
        who_traveling: serializedProductPriceBands,
      };
    }

    return null;
  },
);
