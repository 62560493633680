import { QueryString } from "@tint_fe/helpers";

export const getInitialSelectedHotels = (accommodation) => {
  return accommodation.map(({ city_id, hotels, dates }) => {
    const minPriceHotel = hotels.find((el) => el.min);

    return {
      dates,
      city_id,
      product_id: minPriceHotel.hotel_id,
      price: minPriceHotel.price,
      display_name: minPriceHotel.display_name,
    };
  });
};

export const getAccommodationQuery = (formData) => {
  const roomsString = formData.rooms && QueryString.stringifyRooms(formData.rooms);

  const data = {
    ...formData,
    ...(roomsString && { rooms: roomsString?.split("&rooms").filter((el) => !!el) }),
  };

  const parseRooms = (acc, rooms, isFirst) => {
    return `${acc}${rooms.reduce((roomsAcc, room, index) => {
      if (isFirst && index === 0) {
        return `?order[rooms]${room}`;
      }

      return `${roomsAcc}&order[rooms]${room}`;
    }, "")}`;
  };

  return Object.keys(data)
    .reduce((acc, key, index) => {
      switch (true) {
        case index === 0:
          if (key === "rooms") return `?${parseRooms(acc, data[key])}`;

          return `?order[${key}]=${data[key]}`;
        case key === "rooms":
          return `${parseRooms(acc, data[key])}`;
        default:
          return `${acc}&order[${key}]=${data[key]}`;
      }
    }, "")
    .replace(/" "/g, "+");
};
