import React from "react";
import T from "prop-types";

const CollapseHeadingSuccess = ({ icon, text }) => {
  return (
    <div className="collapse-heading">
      <div className="collapse-heading__icon">{icon}</div>
      <p className="collapse-heading__text">{text}</p>
    </div>
  );
};

CollapseHeadingSuccess.propTypes = {
  icon: T.node,
  text: T.string,
};

export default CollapseHeadingSuccess;
