import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import SelectField from "../../../../components/Form/SelectField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { useTranslation } from "../../../../../hooks";
import { validationRule } from "../../../../../utils";
import HiddenField from "../../../../components/Form/HiddenField";

const StartTime = () => {
  const { t } = useTranslation();
  const product = useSelector((state) => state.product);
  const { scheduleGroups, scheduleGroupsFetching } = product;

  const {
    control,
    formState: { isSubmitted },
    watch,
    setValue,
  } = useFormContext();
  const start_date = watch("start_date");
  const ticket_option = watch("ticket_option");

  const timeOptions = useMemo(
    () => scheduleGroups?.find((el) => el.name === ticket_option)?.slots ?? [],
    [scheduleGroups, ticket_option],
  );

  useEffect(() => {
    if (timeOptions?.length === 1) {
      const option = timeOptions[0];
      setValue("start_time", option.time);
    }
  }, [timeOptions]);

  return (
    <ControllerInput
      control={control}
      name="start_time"
      render={({ field, fieldState: { error } }) => {
        if (timeOptions.length > 1 || !timeOptions.length) {
          return (
            <SelectField
              isSubmitting={isSubmitted}
              disabled={!start_date || !ticket_option}
              isSearchable={false}
              isPreselected={timeOptions?.length === 1}
              placeholder={t("formField.selectTime")}
              label={t("formField.startTime")}
              options={timeOptions ? timeOptions.map((x) => ({ value: x.time, label: x.time })) : []}
              errors={error?.message}
              loading={scheduleGroupsFetching}
              {...field}
            />
          );
        }

        return <HiddenField value={timeOptions[0]?.value} {...field} />;
      }}
      rules={{
        ...validationRule.required("formField.selectTime"),
      }}
    />
  );
};

export default StartTime;
