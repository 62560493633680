import * as React from "react";

const TintProsCrossMark = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#F48D2E"
        fillRule="evenodd"
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16ZM7.556 6H9v4h1v2H6v-2h1.5V8H6V6h1.556ZM9 4c0 1.333-2 1.333-2 0s2-1.333 2 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default TintProsCrossMark;
