import React from "react";
import T from "prop-types";
import { isObject } from "lodash";

import { SubmitButton } from "..";

const TintCardLink = ({ href, btnText, btnClasses }) => {
  const isInternal = isObject(href) || !href?.includes("http");

  return href ? (
    <SubmitButton
      className={btnClasses}
      as={isInternal ? "link" : "a"}
      {...(isInternal && { to: href })}
      {...(!isInternal && { href })}
    >
      {btnText}
    </SubmitButton>
  ) : null;
};

TintCardLink.propTypes = {
  btnText: T.string,
  btnClasses: T.string,
  href: T.oneOfType([T.object, T.string]),
};

export default TintCardLink;
