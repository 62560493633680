import React from "react";
import T from "prop-types";

import { fetchUserIpApi, getApplePaymentSession, requestProcessPaymentForm } from "@tint_fe/api";

async function onApplePayButtonClicked({
  amount,
  label,
  order,
  payment_uuid,
  paymentsLink,
  onSuccessPayment,
  onErrorPayment,
  chargeCurrency,
} = {}) {
  // eslint-disable-next-line no-undef
  if (!ApplePaySession) {
    return;
  }

  const request = {
    countryCode: "US",
    currencyCode: chargeCurrency ?? "USD",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "masterCard", "amex", "discover"],
    total: {
      label: label,
      type: "final",
      amount: amount,
    },
  };

  // eslint-disable-next-line no-undef
  const session = new ApplePaySession(3, request);

  session.onvalidatemerchant = async (event) => {
    const merchantSession = await getApplePaymentSession(order, {
      order: {
        payment_uuid,
        validation_url: event.validationURL,
      },
    });

    try {
      session.completeMerchantValidation(merchantSession);
    } catch (error) {
      console.error(error);
    }
  };

  session.onpaymentmethodselected = () => {
    const update = { newTotal: { label, amount } };
    session.completePaymentMethodSelection(update);
  };

  session.onpaymentauthorized = async (event) => {
    const result = {};
    const ip_address = await fetchUserIpApi();
    const screen_height = window.screen.height;
    const screen_width = window.screen.width;

    try {
      await requestProcessPaymentForm({
        orderNumber: order,
        payment_uuid,
        body: {
          order: {
            z2: 0,
            payment_method: "applepay",
            token: JSON.stringify(event.payment.token),
            ip_address,
            screen_height,
            screen_width,
            ...paymentsLink,
          },
        },
      });

      // eslint-disable-next-line no-undef
      result.status = ApplePaySession.STATUS_SUCCESS;
      onSuccessPayment && onSuccessPayment();
    } catch (error) {
      // eslint-disable-next-line no-undef
      result.status = ApplePaySession.STATUS_FAILURE;
      onErrorPayment && onErrorPayment(error);
    }

    session.completePayment(result);
  };

  session.begin();
}

const ApplePayBtn = ({
  payment_uuid,
  onLoadPaymentData,
  totalPrice,
  paymentLabel,
  order,
  paymentsLink,
  onSuccessPayment,
  onErrorPayment,
  chargeCurrency,
}) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onApplePayButtonClicked({
          amount: totalPrice,
          label: paymentLabel,
          onLoadPaymentData,
          payment_uuid,
          order,
          paymentsLink,
          onSuccessPayment,
          onErrorPayment,
          chargeCurrency,
        });
      }}
      className="apple-pay-button-wrapper btn"
    >
      <span
        lang={window.__localeId__}
        style={{
          WebkitAppearance: "-apple-pay-button",
          applePayButtonType: "plain",
        }}
        className="apple-pay-button apple-pay-button-black"
      />
    </button>
  );
};

ApplePayBtn.propTypes = {
  payment_uuid: T.string,
  onLoadPaymentData: T.func,
  totalPrice: T.oneOfType([T.string, T.number]),
  paymentLabel: T.string,
  order: T.string,
  paymentsLink: T.string,
  onSuccessPayment: T.func,
  onErrorPayment: T.func,
  chargeCurrency: T.string,
};

export default ApplePayBtn;
