import React from "react";
import T from "prop-types";

import { components } from "react-select";

const SelectDropdownIndicator = ({ nextIcon, onReset, hideDropdownIndicator, elCls, ...props }) => {
  switch (true) {
    case !!nextIcon && !onReset:
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={elCls("icon", "next-icon", "input-icon")}
        >
          {nextIcon}
        </div>
      );
    case !!onReset || hideDropdownIndicator:
      return null;
    default:
      return <components.DropdownIndicator {...props} />;
  }
};

SelectDropdownIndicator.propTypes = {
  nextIcon: T.node,
  onReset: T.func,
  hideDropdownIndicator: T.bool,
  elCls: T.func,
};

export default SelectDropdownIndicator;
