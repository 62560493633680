import { useSelector } from "react-redux";

import useScript from "@tint_fe/hooks/src/useScript";

// TODO: move to redux-redux/hooks
const useGetCredoraxPaymentPlugin = (js_plugin_url) => {
  const { payment_provider } = useSelector(({ global }) => global.integration);
  const isCredorax = payment_provider === "credorax";

  useScript(isCredorax && "https://code.jquery.com/jquery-3.6.0.min.js");
  useScript(js_plugin_url);
};

export default useGetCredoraxPaymentPlugin;
