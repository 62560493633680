import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { omit } from "lodash";

import { useRouterMatch, useBreakPoints } from "@tint_fe/hooks";
import { handleABTestingFeatures, join } from "@tint_fe/helpers";
import { useIntegrationCode } from "@tint_fe/redux-utils/hooks";

import TintProsBooking from "../../../components/TintProsBooking";
import RoomsTimer from "../../../components/RoomsTimer";
import { useReduxAction, usePaymentLayout, useRedirectFromPayment, useDiscountInfo } from "../../../../hooks";
import { PAYMENT, SMART_PACKAGE_CHECKOUT, SMART_PACKAGE_SELECT_HOTELS } from "../../../router";
import { selectAccommodationExpiredTime } from "../../../../redux/smartPackage/smartPackageSelectors";
import { selectOrderExpiredTime } from "../../../../redux/order/orderSelectors";
import { timeToShowTimer, updateRatesTypes } from "../../../../helpers/constants";
import { updateRatesAction } from "../../../../redux/global/globalActions";
import { checkIsPaidOrCanceled } from "../../../../utils";
import SPOrderSummary from "./SPOrderSummary";
import { useGetSPCheckoutFromData } from "../hooks";

const shouldPreventNotify = (skipAccommodation, arrival_extra_nights, departure_extra_nights) => {
  if ([skipAccommodation, arrival_extra_nights, departure_extra_nights].includes("undefined")) {
    return true;
  }
  const isOvernightsSelected = Number(arrival_extra_nights ?? 0) > 0 || Number(departure_extra_nights ?? 0) > 0;

  return skipAccommodation && !isOvernightsSelected;
};

const shouldPreventTimer = (preventNotification, isPayment) => {
  return isPayment ? false : preventNotification;
};

const SmartPackageSummary = () => {
  const updateRates = useReduxAction(updateRatesAction);
  const getSPCheckoutFromData = useGetSPCheckoutFromData();

  const {
    number: orderNumber,
    state,
    arrival_extra_nights,
    departure_extra_nights,
  } = useSelector(({ order }) => order.data);
  const skipAccommodation = useSelector(({ smartPackage }) => smartPackage.data.skip_accommodation);

  const accommodationExpiredTime = useSelector(selectAccommodationExpiredTime);
  const expiredTime = useSelector(selectOrderExpiredTime);
  const integrationCode = useIntegrationCode();

  const { showDiscount } = useDiscountInfo();
  const { promotion_image_url = "" } = useSelector(({ global }) => global?.integration);
  const isOrderPaidOrCanceled = checkIsPaidOrCanceled(state);
  const isCheckout = useRouterMatch(SMART_PACKAGE_CHECKOUT);
  const isSelectHotelPage = useRouterMatch(SMART_PACKAGE_SELECT_HOTELS);
  const isPaymentPage = useRouterMatch(PAYMENT);
  const isPaymentLayout = usePaymentLayout();
  const { isMd } = useBreakPoints();
  const { goToPrevPath } = useRedirectFromPayment();

  const preventShowNotification = shouldPreventNotify(skipAccommodation, arrival_extra_nights, departure_extra_nights);
  const preventShowTimer = shouldPreventTimer(preventShowNotification, isPaymentPage);

  // In case both accommodationExpiredTime and expiredTime is not set
  const paymentExpireAt = useMemo(() => new Date().getTime() + timeToShowTimer.PAYMENT, [isPaymentPage]);

  const { countdown: ABTestCountdown } = handleABTestingFeatures();
  const showTimer =
    typeof ABTestCountdown !== "undefined" && isPaymentPage ? ABTestCountdown : !isCheckout && !isOrderPaidOrCanceled;

  const getTimerExpiredHandler = () => {
    const smartPackageBody = getSPCheckoutFromData();

    if (isPaymentPage) {
      return (data) => {
        goToPrevPath();

        updateRates({
          updateType: updateRatesTypes.ORDER,
          orderNumber,
          smartPackageBody,
          ...omit(data, "isPaymentPage"),
        });
      };
    }

    return (data) => {
      updateRates({
        updateType: isSelectHotelPage ? updateRatesTypes.SELECT_HOTEL : updateRatesTypes.ORDER,
        orderNumber,
        smartPackageBody,
        ...data,
      });
    };
  };

  return (
    <div className={join(["order-summary__sticky", (isPaymentLayout || isMd) && "payment-page"], "")}>
      {!isPaymentLayout && !isMd && <SPOrderSummary />}

      <RoomsTimer
        onTimeExpired={getTimerExpiredHandler()}
        expireAt={
          accommodationExpiredTime > new Date().getTime() ? accommodationExpiredTime : expiredTime ?? paymentExpireAt
        }
        timeToShow={isPaymentPage ? timeToShowTimer.PAYMENT : timeToShowTimer.ORDER}
        hideTimer={!showTimer || preventShowTimer}
        preventShowNotify={preventShowNotification ?? true}
      />

      {showDiscount && isMd && !!promotion_image_url && (
        <div className={"order-summary__svg-banner"}>
          <img src={promotion_image_url} alt="promotion" />
        </div>
      )}

      <TintProsBooking {...(isPaymentLayout && { className: "payment-page" })} integrationCode={integrationCode} />
    </div>
  );
};

export default SmartPackageSummary;
