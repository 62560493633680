import { useRequest } from "@tint_fe/hooks";
import { patchPostBookingExtraAsExtra } from "../../../../api";

const useExtraAsExtra = (onSuccess) => {
  const { callToApi, res, error, loading } = useRequest({
    apiCall: patchPostBookingExtraAsExtra,
    ...(onSuccess && { onSuccess: onSuccess }),
  });

  return { patcher: callToApi, res, error, loading };
};

export default useExtraAsExtra;
