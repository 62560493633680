import React from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";

import { useBreakPoints } from "@tint_fe/hooks";

import Button from "./Button";

const SubmitButton = ({ className, disabled, label, loading, onClick, type, children, ...rest }) => {
  const { isSm } = useBreakPoints();
  const { t } = useTranslation();
  const text = isSm ? t("continue") : label || t("continue");

  return (
    <Button
      className={`btn__submit${className ? ` ${className}` : ""}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      processing={loading}
      {...rest}
    >
      {children ? children : text}
    </Button>
  );
};

SubmitButton.defaultProps = {
  type: "button",
};

SubmitButton.propTypes = {
  className: T.string,
  label: T.string,
  disabled: T.bool,
  loading: T.bool,
  onClick: T.func,
  type: T.string,
  children: T.node,
};

export default SubmitButton;
