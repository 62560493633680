import React from "react";
import T from "prop-types";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { useTranslation } from "../../../../../hooks";
import {
  selectProductSettings,
  selectAvailablePickupPoints,
  selectAvailableDropOffPoints,
} from "../../../../../redux/product/productSelectors";
import FlightNumber from "../../../../components/Form/FlightNumber";
import useIsCityIsAirport from "../../hooks/useIsCityIsAirport";

const TourFlightNumber = () => {
  const { t } = useTranslation();

  const availablePickupPoints = useSelector(selectAvailablePickupPoints);
  const availableDropOffPoints = useSelector(selectAvailableDropOffPoints);
  const { custom_pickup_point_strategy } = useSelector(selectProductSettings);

  const { control, watch, setValue } = useFormContext();

  const startDateValue = watch("start_date");
  const city_id = watch("city_id");
  const location = watch(custom_pickup_point_strategy ? "custom_pickup_point" : "pickup_point_id");
  const [isCityIsAirport, cityName] = useIsCityIsAirport(city_id);

  const isEndLocationHidden =
    availableDropOffPoints?.length === 1 &&
    availablePickupPoints?.length === 1 &&
    availableDropOffPoints[0].name === availablePickupPoints[0].name;

  const getDependOn = () => {
    if (isCityIsAirport) return cityName;
    if (custom_pickup_point_strategy) return location?.name;

    return availablePickupPoints.find((el) => el.id === location)?.name;
  };

  return (
    <FlightNumber
      control={control}
      name="flight_number"
      label={isEndLocationHidden ? t("formField.flightNumber") : t("formField.flightNumber.arrival")}
      disabled={!startDateValue}
      dependOnLocation={getDependOn() || ""}
      onSelectNotAirport={() => setValue("flight_number", "")}
    />
  );
};

TourFlightNumber.propTypes = {
  dependOn: T.string,
};

export default TourFlightNumber;
