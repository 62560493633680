import { saveUserToSessionStorage, getUserFromSessionStorage } from "@tint_fe/helpers";
import { getUserApi } from "@tint_fe/api";

import useRequest from "./useRequest";
import useUser from "./useUser";

const useGetUser = ({ onError, onSuccess } = {}) => {
  const { user, setUser } = useUser();
  const { loading, error, callToApi } = useRequest({
    apiCall: getUserApi,
    onSuccess: (data) => {
      saveUserToSessionStorage(data.user);

      return onSuccess ? onSuccess(data) : data;
    },
    onError,
  });

  const getUser = ({ force } = {}) => {
    const userFromSessionStorage = getUserFromSessionStorage();

    if (force) {
      return callToApi();
    }

    if (userFromSessionStorage) {
      return setUser(userFromSessionStorage);
    }

    return callToApi();
  };

  return {
    user,
    getUser,
    loading,
    error,
  };
};

export default useGetUser;
