import { useEffect, useState } from "react";

const useSticky = (el, stickyTo = "top", stickyInWrapperOnly) => {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    setSticky((prevSticky) => {
      if (el) {
        const rec = el.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rec.bottom;

        if (stickyTo === "bottom") {
          if (spaceBelow < -50 && !prevSticky) {
            setSticky(true);
          }

          if (spaceBelow > 50 && prevSticky) {
            if (stickyInWrapperOnly && rec.top > 0 && rec.height + rec.top > 0) {
              setSticky(false);
            } else {
              setSticky(false);
            }
          }
        }

        if (stickyTo === "top") {
          if (rec.top <= 50 && !prevSticky) {
            setSticky(true);
          }

          if (rec.top > 50 && prevSticky) {
            if (stickyInWrapperOnly && rec.top < 0 && rec.height + rec.top < 0) {
              setSticky(false);
            } else {
              setSticky(false);
            }
          }
        }
      }

      return prevSticky;
    });
  };

  useEffect(() => {
    setSticky(true);
    handleScroll();
    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, [el]);

  return isSticky;
};

export default useSticky;
