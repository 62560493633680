import React, { useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import checkIfIsCovidTest from "../../../../../helpers/checkIfIsCovidTest";
import { useTranslation } from "../../../../../hooks";
import {
  selectProductSettings,
  selectAvailablePickupPoints,
  selectAvailableDropOffPoints,
} from "../../../../../redux/product/productSelectors";
import SelectField from "../../../../components/Form/SelectField";
import GoogleAutocomplete from "../../../../components/Form/GoogleAutocomplete";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { transformArrayOfObjectsToOptions, validationRule, normalizedValidateError } from "../../../../../utils";

const StartLocations = () => {
  const { t } = useTranslation();

  const { custom_pickup_point_strategy, different_end_location, display_end_location } =
    useSelector(selectProductSettings);
  const availablePickupPoints = useSelector(selectAvailablePickupPoints);
  const availableDropOffPoints = useSelector(selectAvailableDropOffPoints);
  const product = useSelector((state) => state.product);
  const loading = product?.priceBandsFetching || product?.availabilityFetching;

  const {
    control,
    formState: { isSubmitted },
    watch,
    setValue,
    getValues,
  } = useFormContext();
  const startDateValue = watch("start_date");
  const city_id = watch("city_id");
  const language_code = watch("language_code");
  const customPickupPoint = watch("custom_pickup_point");
  const pickupPointId = watch("pickup_point_id");

  const { isCovidTest1, isCovidTest3, isCovidTest } = checkIfIsCovidTest();

  const pickupPointOptions = useMemo(() => {
    return transformArrayOfObjectsToOptions(
      city_id ? availablePickupPoints.filter((x) => x.city_id === city_id) : availablePickupPoints,
    );
  }, [city_id, startDateValue, language_code, availablePickupPoints]);

  const label = isCovidTest ? "test.address" : "formField.startLocation";

  useEffect(() => {
    if (pickupPointOptions.length === 1 && !custom_pickup_point_strategy && city_id && getValues("city_id")) {
      setValue("pickup_point_id", pickupPointOptions[0].value);
    }
  }, [pickupPointOptions, custom_pickup_point_strategy, city_id]);

  useEffect(() => {
    if (custom_pickup_point_strategy) {
      if (availablePickupPoints.length === 1 && availablePickupPoints[0].default_address && !customPickupPoint) {
        setValue("custom_pickup_point", { name: availablePickupPoints[0].default_address });
      }
    } else if (availablePickupPoints.length === 1 && availablePickupPoints[0].default_address && !pickupPointId) {
      setValue("pickupPointId", { name: availablePickupPoints[0].default_address });
    }
  }, [availablePickupPoints]);

  const updateStartTime = (val) => {
    if (val.pickup_point_id) {
      const selectedPickupPoint = availablePickupPoints.find((x) => x.id === val.pickup_point_id);

      const timesArray = (selectedPickupPoint?.raw_time_points || []).map((item) => {
        return item.replace(/:/g, " : ");
      });

      if (timesArray?.length === 1) {
        return setValue("start_time", timesArray[0]);
      }
    }
    setValue("start_time", "");
  };

  const handleDiffLocation = (val) => {
    const diffLocation = getValues("return_different_location");

    // don't preset custom_dropoff_point if different_end_location or diffLocation set as true or
    if (!diffLocation && !(different_end_location || display_end_location)) {
      setValue("custom_dropoff_point", val.custom_pickup_point);
    }

    return val;
  };

  const handleSelect = (val) => {
    updateStartTime(val);
    !different_end_location && handleDiffLocation(val);

    // don't preset dropoff point if different_end_location set as true
    if (val.pickup_point_id && !different_end_location) {
      const pickUpPoint = availablePickupPoints.find((el) => el.id === val.pickup_point_id);
      const dropOff = availableDropOffPoints.find((el) => el.name === pickUpPoint?.name);
      const newVal = dropOff?.id || availableDropOffPoints[0]?.id;

      setValue("dropoff_point_id", newVal);
    }

    // reset dropoff_point_id if different_end_location is true
    // and we have the same dropoff point as pickup point
    if (different_end_location) {
      const currentDropOffId = getValues("dropoff_point_id");
      const currentDropOff = availableDropOffPoints.find((el) => el.id === currentDropOffId);

      currentDropOff?.name === val.label && setValue("dropoff_point_id", null);
    }

    return val;
  };

  // This useEffect is to fix following issue: [TINT-3619]
  useEffect(() => {
    if (custom_pickup_point_strategy) {
      handleDiffLocation({ custom_pickup_point: customPickupPoint });
    }
  }, [custom_pickup_point_strategy, customPickupPoint]);

  return (
    <ControllerInput
      control={control}
      name={custom_pickup_point_strategy ? "custom_pickup_point" : "pickup_point_id"}
      render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
        return custom_pickup_point_strategy ? (
          <GoogleAutocomplete
            label={t(label)}
            {...((isCovidTest1 || isCovidTest3) && { remark: t("test.address.remark") })}
            placeholder={t("formField.enterLocation")}
            disabled={!startDateValue}
            errors={!value?.name && error?.message ? normalizedValidateError(error, label) : null}
            showValidateMark={isSubmitted}
            onChange={(val) => onChange(handleDiffLocation(val))}
            onSelect={(val) => onChange(handleSelect(val))}
            value={value}
            {...rest}
          />
        ) : (
          <SelectField
            label={t(label)}
            {...((isCovidTest1 || isCovidTest3) && { remark: t("test.address.remark") })}
            placeholder={t("formField.selectPoint")}
            disabled={!startDateValue}
            isPreselected={pickupPointOptions.length === 1}
            options={pickupPointOptions}
            errors={!rest?.value && error?.message ? error.message : null}
            noOptionsMessage={t("formField.startFrom.noOptionsMessage")}
            isSubmitting={isSubmitted}
            onChange={(val) => onChange(handleSelect(val))}
            loading={loading}
            value={value}
            {...rest}
          />
        );
      }}
      rules={{
        ...validationRule.required(label),
        ...validationRule.minLength(label, 1),
        ...(custom_pickup_point_strategy && {
          validate: validationRule.validateGoogleAutocompleteRequired(),
        }),
      }}
    />
  );
};

export default StartLocations;
