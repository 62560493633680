import React, { useEffect, useState } from "react";
import T from "prop-types";
import { useWatch } from "react-hook-form";

import { validationRule } from "@tint_fe/helpers";
import { useControlledInput } from "@tint_fe/hooks";
import { Input } from "../../../components";
import { AmexLogo34, CreditCard, MasterCardLogo34, VisaLogo34 } from "../../../assets/icons";

const cardTypes = {
  amex: "American Express",
  visa: "Visa",
  mastercard: "MasterCard",
};

const normalizeCardNumber = (value) => {
  return (
    value
      .replace(/\s/g, "")
      .replace(/[^0-9]/g, "")
      .match(/.{1,4}/g)
      ?.join("-")
      .substr(0, 19) || ""
  );
};

const makeKeepingCursorPosition = () => {
  let prevValue = "";

  return (evt, prevEvtParams) => {
    const newValue = evt.target.value;
    const oldCursor = prevEvtParams.cursor;

    const isDeleting = prevValue.length > newValue.length;
    const isFifth = oldCursor % 5 === 0 && oldCursor !== 0;

    if (isFifth && !isDeleting) {
      evt.target.selectionStart = oldCursor + 1;
      evt.target.selectionEnd = oldCursor + 1;

      return (prevValue = newValue);
    }

    if (isFifth && isDeleting) {
      evt.target.selectionStart = oldCursor - 1;
      evt.target.selectionEnd = oldCursor - 1;

      return (prevValue = newValue);
    }

    evt.target.selectionStart = oldCursor;
    evt.target.selectionEnd = oldCursor;

    return (prevValue = newValue);
  };
};

const CardNumberInput = ({ control, defaultValue, ...rest }) => {
  const keepCursorPosition = makeKeepingCursorPosition();
  const {
    field: { onChange, ...field },
    fieldState: { error },
    showValidateMark,
  } = useControlledInput({
    name: "card_number",
    defaultValue: defaultValue || "",
    control,
    rules: {
      ...validationRule.required("paymentForm.cardNumber"),
      ...validationRule.minLength("paymentForm.cardNumber", 16),
    },
  });
  const fieldValue = useWatch({ control, name: "card_number" });

  // Validate card issuer: Visa, MasterCard, American Express
  const [cardBank, setCardBank] = useState(null);

  const revalidateCardBank = (value) => {
    if (value.startsWith("34") || value.startsWith("37")) {
      return setCardBank(cardTypes.amex);
    }

    if (value.startsWith("4")) {
      return setCardBank(cardTypes.visa);
    }

    if (value.startsWith("5")) {
      return setCardBank(cardTypes.mastercard);
    }

    setCardBank(null);
  };

  const getCardBankIcon = () => {
    switch (cardBank) {
      case cardTypes.amex:
        return <AmexLogo34 />;
      case cardTypes.visa:
        return <VisaLogo34 />;
      case cardTypes.mastercard:
        return <MasterCardLogo34 />;
      default:
        return <CreditCard />;
    }
  };

  // When component may be unmounted, and then mounted with non-empty value
  useEffect(() => {
    fieldValue && revalidateCardBank(fieldValue);
  }, []);

  return (
    <Input
      nextIcon={getCardBankIcon()}
      cardInput
      label="paymentForm.cardNumber"
      type="tel"
      placeholder="XXXX-XXXX-XXXX-XXXX"
      inputMode="numeric"
      autoComplete="cc-number"
      onChange={(event) => {
        const { value } = event.target || {};
        event.target.value = normalizeCardNumber(value);
        revalidateCardBank(value.replace(/-/g, ""));

        return onChange(event);
      }}
      afterUpdate={keepCursorPosition}
      error={error}
      showValidateMark={showValidateMark}
      {...rest}
      {...field}
    />
  );
};

CardNumberInput.propTypes = {
  control: T.object.isRequired,
  name: T.string,
  defaultValue: T.object,
};

export default CardNumberInput;
