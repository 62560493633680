import * as React from "react";

function VisaLogo34(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={24} fill="none" {...props}>
      <rect width={33} height={23} x={1.48} y={0.5} fill="#fff" stroke="#D9D9D9" rx={1.5} />
      <path
        fill="#172B85"
        fillRule="evenodd"
        d="M11.732 15.858h-2.06L8.127 9.792c-.073-.279-.229-.525-.458-.642a6.573 6.573 0 0 0-1.888-.642v-.233H9.1c.458 0 .802.35.859.758l.801 4.376 2.06-5.134h2.002l-3.09 7.583Zm4.234 0H14.02l1.602-7.583h1.945l-1.601 7.583Zm4.118-5.482c.057-.409.4-.642.801-.642a3.535 3.535 0 0 1 1.888.35l.344-1.633a4.8 4.8 0 0 0-1.774-.351c-1.888 0-3.262 1.05-3.262 2.508 0 1.11.974 1.692 1.66 2.042.744.35 1.03.584.973.934 0 .524-.573.758-1.144.758a4.788 4.788 0 0 1-2.003-.467l-.343 1.633c.687.292 1.43.409 2.117.409 2.117.058 3.432-.992 3.432-2.567 0-1.984-2.689-2.1-2.689-2.974Zm9.497 5.482-1.544-7.583h-1.66a.862.862 0 0 0-.8.583l-2.86 7h2.002l.4-1.108h2.46l.229 1.108h1.773Zm-2.917-5.54.571 2.857h-1.601l1.03-2.858Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default VisaLogo34;
