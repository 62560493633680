export const join = (arr, separator) => arr?.filter(Boolean).join(separator);

export const parseClasses = ({ base, classes, additional }) => {
  const classList = classes
    ?.filter((el) => el)
    .map((el) => `${base}__${el}`)
    .join(" ");

  const parseAdditional = (data) => {
    if (data) {
      return typeof data === "string" ? ` ${data}` : ` ${join(data, " ")}`;
    }

    return "";
  };

  const mainCls = `${base}${classList ? ` ${classList}` : ""}${parseAdditional(additional)}`;

  const getElementClass = (name, ...rest) => {
    const restClasses = join(rest, " ");

    return `${base}__${name}${restClasses ? ` ${restClasses}` : ""}`;
  };

  return { mainCls, elCls: getElementClass };
};

export const checkIsIOS = (rough = false) => {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
    (navigator.userAgent.includes("Mac") && rough)
  );
};

export const checkIsSafari = () => {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") === -1 &&
    navigator.userAgent.indexOf("FxiOS") === -1
  );
};

export const checkIsMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const toUpFirstLater = (str) => {
  if (str && typeof str === "string") {
    return `${str[0].toUpperCase()}${str.slice(1)}`;
  }

  return str;
};

export const capitalizeLater = (str) => {
  if (typeof str === "string") {
    return str
      .split(" ")
      .map((el) => {
        if (!el.trim()) return "";

        return `${el[0]?.toUpperCase()}${el.slice(1) ? el.slice(1)?.toLowerCase() : ""}`;
      })
      .join(" ");
  }
};

export const hasValues = (obj, arr) => {
  return arr.every((el) => !!obj[el]);
};

// polyfill for ios older than 12
export const flat = (arr) => {
  return arr.reduce((acc, cur) => {
    return Array.isArray(cur) ? acc.concat(flat(cur)) : acc.concat(cur);
  }, []);
};

export const checkIfContentOverflow = (el) => {
  if (!el) return false;

  return el.scrollWidth > el.offsetWidth;
};

export const addStrEndSign = (str) => {
  if (typeof str === "string") {
    const trimmedStr = str.trim();
    const lastSymbol = trimmedStr[trimmedStr.length - 1];

    return [".", "!", "?"].includes(lastSymbol) ? trimmedStr : `${trimmedStr}.`;
  }

  return "";
};
