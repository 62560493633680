import { omit, omitBy, isNil } from "lodash";

import { QueryString, getUuidFromSessionStorage, AB_TESTING_KEY } from "@tint_fe/helpers";
import { COMPLETED } from "../react/router";

export const updateUrl = (url, typeUpdate, state = {}) => {
  if (typeUpdate === "replace") {
    window.history.replaceState(state, "", url);

    return;
  }

  window.history.pushState(state, "", url);
};

export const getSourceLink = () => {
  return `${window.location.origin}${window.location.pathname}${window.location.search}`.replace(/#.*/, "");
};

export const getPaymentsLinks = ({ orderNumber, paymentMethod, shouldBackToOriginSource }) => {
  const { search } = document.location;
  const { packageId, productId, pid, tourId, tourid, success_url, payment_uuid, source, ...rest } =
    QueryString.parse(search);
  const id = pid || productId || tourId || tourid || packageId;
  const uuid = getUuidFromSessionStorage();
  const failPaymentShouldBePresent = !shouldBackToOriginSource || source === "request_payment";

  const baseUrl = `${window.location.origin}${window.location.pathname}`;

  const newSearchObj = omitBy(
    {
      pid: id,
      order: orderNumber,
      ...(source === "staff_payment" && { source }),
      uuid,
      success_url,
      payment_uuid,
      payment_method: paymentMethod,
      utm_nooverride: 1,
      ...(rest[AB_TESTING_KEY] && { [AB_TESTING_KEY]: rest[AB_TESTING_KEY] }),
    },
    isNil,
  );

  const backSearch = QueryString.stringify({
    ...newSearchObj,
    ...(source === "request_payment" && { source }),
    payment_cancelled: `${process.env.REACT_APP_SECRET_PURCHASE_BACK}${uuid}`,
  });

  const successSearch = QueryString.stringify({
    ...newSearchObj,
    success_payment: `${process.env.REACT_APP_SECRET_PURCHASE_SUCCESS}${uuid}`,
  });

  const failSearch = QueryString.stringify({
    ...newSearchObj,
    ...(source === "request_payment" && { source }),
    ...(failPaymentShouldBePresent && { fail_payment: "failed" }),
  });

  return {
    back_url: `${baseUrl}?${backSearch}`,
    success_url: `${baseUrl}?${successSearch}`,
    fail_url: `${baseUrl}?${failSearch}`,
  };
};

export const getSuccessPageUrl = () => {
  const query = omit(QueryString.parse(document.location.search), ["success_url", "source"]);

  return { search: QueryString.stringify(query), pathname: COMPLETED };
};
