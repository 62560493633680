import React, { useEffect } from "react";
import T from "prop-types";
import { useFormContext, Controller } from "react-hook-form";

import SelectField from "../../../components/Form/SelectField";

const normalizeOptions = (options) => {
  return options?.map(({ name, id, ...rest }) => ({
    label: name,
    value: Number(id),
    ...rest,
  }));
};

const SPSelectCity = ({ options, name, rules, typeLocationFieldName, ...rest }) => {
  const { control, setValue } = useFormContext();

  const normalizedOptions = normalizeOptions(options);

  const handleChange = (val) => {
    setValue(typeLocationFieldName, val.type);

    return val.value;
  };

  useEffect(() => {
    if (options?.length === 1) {
      setTimeout(() => {
        setValue(name, normalizedOptions[0].value);
        setValue(typeLocationFieldName, normalizedOptions[0].type);
      }, 0);
    }
  }, [options]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...restFiled }, fieldState: { error } }) => {
        return (
          <SelectField
            isPreselected={options?.length === 1}
            options={normalizeOptions(options)}
            error={error}
            onChange={(val) => onChange(handleChange(val))}
            {...restFiled}
            {...rest}
          />
        );
      }}
      rules={rules}
    />
  );
};

SPSelectCity.propTypes = {
  options: T.array,
  name: T.string,
  rules: T.object,
  typeLocationFieldName: T.string,
};

export default SPSelectCity;
