import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { useReduxAction } from "../../../../hooks";
import { getPriceBandsRequest } from "../../../../redux/product/productActions";
import { selectProduct, selectProductSettings } from "../../../../redux/product/productSelectors";
import { dates } from "../../../../utils";
import { PACKAGE_FORMAT_DATE } from "../../../../helpers/constants";

/* Get available price bands when city is changed */
const useUpdatePriceBands = (methods) => {
  const { custom_pickup_point_strategy, different_end_location } = useSelector(selectProductSettings);
  const { id: productId } = useSelector(selectProduct);
  const getPriceBands = useReduxAction(getPriceBandsRequest);
  const { getValues, setValue } = methods || useFormContext();

  const resetWhoTraveling = () => {
    const whoTraveling = getValues("who_traveling");

    Object.keys(whoTraveling).forEach((id) => {
      setValue(`who_traveling.${id}`, 0);
    });
  };

  const getDefaultDropOff = (dropOfPoints, point) => {
    if (dropOfPoints.length === 1) return different_end_location ? null : dropOfPoints[0];

    return different_end_location
      ? dropOfPoints.find((x) => x?.name !== point.name)
      : dropOfPoints.find((x) => x?.name === point.name) || dropOfPoints[0];
  };

  const updatePoints = (pickupPoints, dropOfPoints) => {
    if (custom_pickup_point_strategy) return;
    const point = pickupPoints?.length === 1 ? pickupPoints[0] : null;

    if (point?.id) {
      const { raw_time_points = [] } = point;
      const startTime = raw_time_points.length === 1 ? raw_time_points[0].replace(/:/g, " : ") : "";
      const dropOfPoint = getDefaultDropOff(dropOfPoints, point);

      setValue("pickup_point_id", point.id);
      setValue("start_time", startTime);

      dropOfPoint?.id && setValue("dropoff_point_id", dropOfPoint.id);
    } else {
      setValue("pickup_point_id", null);
      setValue("start_time", "");
      setValue("dropoff_point_id", null);
    }
  };

  const handleUpdatePriceBands = useCallback(
    (data) => {
      const startDate = getValues("start_date");
      const city_id = getValues("city_id");
      const language_code = getValues("language_code");
      const { onSuccess, pointFromGoogleAutocomplete, isInitialFetch, ...values } = data || {};
      const cityId = values?.city_id || city_id;

      if (cityId) {
        getPriceBands({
          tourId: productId,
          query: {
            start_date: dates.format(startDate, PACKAGE_FORMAT_DATE, false),
            city_id: cityId,
            language_code,
            ...values,
          },
          onSuccess: (availableDropOffPoints, availablePickupPoints) => {
            if (!isInitialFetch) {
              const pickupPoints = pointFromGoogleAutocomplete
                ? [pointFromGoogleAutocomplete]
                : availablePickupPoints.filter((el) => el.city_id === cityId);
              const dropOffPoints = availableDropOffPoints.filter((el) => el.city_id === cityId);

              updatePoints(pickupPoints, dropOffPoints);
              resetWhoTraveling();
            }

            onSuccess && onSuccess(availableDropOffPoints, availablePickupPoints);
          },
        });
      }
    },
    [getValues, getPriceBands],
  );

  return handleUpdatePriceBands;
};

export default useUpdatePriceBands;
