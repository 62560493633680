import React from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { dates, paymentMethods } from "@tint_fe/helpers";
import { useRouterMatch } from "@tint_fe/hooks";

import HotelSummary from "../HotelSummary/HotelSummary";
import { selectHotelExpiredAt } from "../../../../redux/global/globalSelectors";

import { timeToShowTimer, steps } from "../../../../helpers/constants";
import { CHECKOUT } from "../../../router/routes";
import { BookingLayout } from "../../../Layouts";

import useGetHotelsAvailability from "../hooks/useGetHotelsAvailability";
import useSelectedRooms from "../hooks/useSelectedRooms";
import PolicyModal from "../../../components/PolicyBtnModal/PolicyModal";
import { useIntegrationCode } from "@tint_fe/redux-utils";

const isFarThenWeekInFuture = (deadline) => {
  return dates.differenceInCalendarDays(dates.parseDateFromApi(deadline), new Date(), false) > 7;
};

const checkEachRoomIfFreeCancellation = (rooms) => {
  if (!rooms.length) return false;

  return rooms.every((room) => {
    return room.cancellation?.startsWith("Free cancellation") && isFarThenWeekInFuture(room.deadline);
  });
};

const HotelLayout = ({ children }) => {
  const booking_code_expire_at = useSelector((state) => state.hotel.booking_code_expire_at);
  const hotelExpiredAt = useSelector(selectHotelExpiredAt);
  const hotel_line_items = useSelector(({ order }) => order.data?.product_items?.[0]?.hotel_line_items);
  const selectedPaymentMethod = useSelector((state) => state.global.selectedPaymentMethod);
  const isCheckoutStep = useSelector(({ global }) => global.step === steps.CHECKOUT);
  const isExtrasStep = useSelector(({ global }) => global.step === steps.EXTRAS);
  const openAllPoliciesModal = useSelector(({ global }) => global.openAllPoliciesModal);
  const product = useSelector((state) => state.product?.data || {});
  const { descriptions = {}, check_in = null, check_out = null } = product || {};
  const integrationCode = useIntegrationCode();

  const getHotelsAvailability = useGetHotelsAvailability();
  const { getValues } = useFormContext();

  const isCheckoutPage = useRouterMatch(CHECKOUT);
  const selectedRooms = useSelectedRooms();

  const checkIfFreeCancellation = () => {
    const isCreditCard = ![paymentMethods.APPLE_PAY, paymentMethods.G_PAY].includes(selectedPaymentMethod);

    if (hotel_line_items && !isCheckoutPage) {
      return checkEachRoomIfFreeCancellation(hotel_line_items) && isCreditCard;
    }
    if (selectedRooms?.length) {
      return checkEachRoomIfFreeCancellation(selectedRooms) && isCreditCard;
    }

    return false;
  };

  const onTimeExpired = (data) => {
    const { end_date, start_date, rooms } = getValues();

    getHotelsAvailability({ ...data, end_date, start_date, rooms });
  };

  const getExpireAt = () => {
    switch (true) {
      case isCheckoutStep:
        return null;
      case isExtrasStep:
        return booking_code_expire_at;
      default:
        return hotelExpiredAt;
    }
  };

  return (
    <BookingLayout
      dataTestid="tour-details-form"
      expireAt={getExpireAt()}
      isFreeCancellation={checkIfFreeCancellation()}
      summary={<HotelSummary selectedRooms={selectedRooms} />}
      {...(isCheckoutPage && { onTimeExpired })}
      {...(isCheckoutPage && {
        timeToShow: timeToShowTimer.ACCOMMODATION,
      })}
    >
      {openAllPoliciesModal && (
        <PolicyModal
          integrationCode={integrationCode}
          descriptions={descriptions}
          check_in={check_in}
          check_out={check_out}
        />
      )}
      {children}
    </BookingLayout>
  );
};

HotelLayout.propTypes = {
  children: T.any.isRequired,
  formData: T.object,
  expireAt: T.number,
  timeToShow: T.number,
  onTimeExpired: T.func,
  dataTestid: T.string,
  totalPrice: T.number,
  hotelRooms: T.array,
};

export default HotelLayout;
