import React from "react";

const Cross = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="150 150 200 300" fill="#0baee6">
    <g>
      <path d="M 169.72656,371.38672 L 238.67188,302.44141 L 169.92188,233.69141 L 181.25,222.36328 L 250,291.11328 L 318.75,222.36328 L 329.6875,233.49609 L 260.9375,302.24609 L 330.07812,371.38672 L 318.94531,382.71484 L 249.80469,313.57422 L 180.85938,382.51953 L 169.72656,371.38672 z" />
    </g>
  </svg>
);

export default Cross;
