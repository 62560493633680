export const checkCurrencyChar = (currency = "USD") => {
  switch (currency) {
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    default:
      return "$";
  }
};
