import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useBreakPoints } from "@tint_fe/hooks";

import MobileSummary from "../../../components/BookingNav/MobileBookingSummary";
import { scrollToError, transformArrayOfObjectsToOptions } from "../../../../utils";
import StickyButtonGroup from "../../../components/StickyButtonGroup";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import FormContainer from "../../../components/Form/FormContainer";
import {
  PickupPoint,
  PickupPointCustom,
  StartCityCustom,
  StartDate,
  TicketOption,
  StartTime,
  ActivityLanguage,
  WhoTravelingForm,
  GuestAccomodation,
} from "./FormComponents";
import { getScheduleActivityAction } from "../../../../redux/product/productActions";
import { formatActivityOrderBody, serializeActivityOrderBody } from "../../../../helpers/serializeActivityOrderBody";
import useCreateActivityOrder from "../hooks/useCreateActivityOrder";
import { setActivitySessionStorage } from "../../../../storage";
import { ga_begin_checkout } from "@tint_fe/helpers/services/google-analytics-v2";
import { setRefetchPricesDueCurrencyChange } from "../../../../redux/global/globalActions";

const ActivityCheckoutForm = () => {
  const { isMd } = useBreakPoints();
  const { handleSubmit, watch } = useFormContext();
  const getScheduleActivity = useReduxAction(getScheduleActivityAction);
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);
  const history = useHistoryNavigation();
  const createActivityOrder = useCreateActivityOrder(history);

  const product = useSelector((state) => state.product.data);
  const shouldRefetchPrices = useSelector((state) => state.global.refetchPricesDueCurrencyChange);
  const { open: isOpenDate = false, settings = {} } = product;
  const scheduleGroups = useSelector((state) => state.product.scheduleGroups);
  const loading = useSelector((state) => state.order.loading);
  const { custom_pickup_point_strategy, mandatory_custom_pickup } = settings;

  const pickup_point_id = watch("pickup_point_id");
  const start_date = watch("start_date");
  const ticket_option = watch("ticket_option");

  useEffect(() => {
    start_date &&
      getScheduleActivity({
        date: start_date,
        ...(pickup_point_id && { pickup_point_id }),
      });
  }, [start_date, pickup_point_id]);

  useEffect(() => {
    setTimeout(() => {
      ga_begin_checkout(product);
    }, 1000);
  }, []);

  useEffect(() => {
    if (shouldRefetchPrices && start_date) {
      setRefetchPrices(false);
      getScheduleActivity({
        date: start_date,
        ...(pickup_point_id && { pickup_point_id }),
      });
    }
  }, [shouldRefetchPrices]);

  const getProductItemsAttribute = (values) => {
    const { who_traveling: whoTraveling, ...reservationDetails } = values;

    return serializeActivityOrderBody(
      product,
      {
        reservationDetails: {
          ...reservationDetails,
          start_from: reservationDetails.location?.name,
        },
        whoTraveling,
      },
      custom_pickup_point_strategy,
    );
  };

  const onSubmit = (values) => {
    const activityProductItem = getProductItemsAttribute(values);
    const activityOrderBody = formatActivityOrderBody(activityProductItem, custom_pickup_point_strategy);
    setActivitySessionStorage({ ...activityProductItem, product_id: product.id });
    createActivityOrder(activityOrderBody);
  };

  const onError = (e) => {
    console.error(e);
    scrollToError();
  };

  const submitHandler = handleSubmit(onSubmit, onError);

  const ticketOptions = useMemo(
    () => transformArrayOfObjectsToOptions(scheduleGroups, "name", "name"),
    [start_date, scheduleGroups],
  );

  const timeOptions = useMemo(
    () => scheduleGroups?.find((el) => el.name === ticket_option)?.slots ?? [],
    [scheduleGroups, ticket_option],
  );

  const ticketOrTimeFullWidth = ticketOptions.length === 1 || timeOptions.length === 1;

  return (
    <>
      <FormContainer>
        {custom_pickup_point_strategy ? (
          <>
            <StartCityCustom />
            <PickupPointCustom isRequired={mandatory_custom_pickup} />
          </>
        ) : (
          <>
            <PickupPoint />
            <GuestAccomodation isRequired={mandatory_custom_pickup} />
          </>
        )}
        <StartDate isOpenDate={isOpenDate} />
        <div className="row">
          <div className={`col ${!ticketOrTimeFullWidth && "col-sm-8 col-md-7 col-lg-8"}`}>
            <TicketOption />
          </div>
          <div className={`col ${!ticketOrTimeFullWidth && "col-sm-4 col-md-5 col-lg-4"}`}>
            <StartTime />
          </div>
        </div>
        <ActivityLanguage />
        <WhoTravelingForm />
      </FormContainer>
      {isMd && <MobileSummary />}
      <StickyButtonGroup loading={loading} onSubmit={submitHandler} />
    </>
  );
};

export default ActivityCheckoutForm;
