import { useEffect, useState } from "react";
import { subscribeJWTChange, getTintJwtLocalStorage, removeUserToSessionStorage, TINT_JWT } from "@tint_fe/helpers";

const useIsLogged = ({ onLogout } = {}) => {
  const [isLogged, setIsLogged] = useState(!!getTintJwtLocalStorage());

  useEffect(() => {
    subscribeJWTChange((data) => {
      setIsLogged((prevValue) => {
        if (!data && prevValue) {
          removeUserToSessionStorage();
          onLogout && onLogout();
        }

        return prevValue === !!data ? prevValue : !!data;
      });
    });
  }, []);

  useEffect(() => {
    const onChangeTINT_JWTToken = (event) => {
      if (event.key === TINT_JWT) {
        setIsLogged(!!event.newValue);
      }
    };
    window.addEventListener("storage", onChangeTINT_JWTToken);

    return () => {
      window.removeEventListener("storage", onChangeTINT_JWTToken);
    };
  }, []);

  return isLogged;
};

export default useIsLogged;
