export const SMART_PACKAGE_FETCHING = "SMART_PACKAGE_FETCHING";
export const UPDATE_SMART_PACKAGE_FETCHING = "UPDATE_SMART_PACKAGE_FETCHING";

export const GET_SMART_PACKAGE_REQUEST = "GET_SMART_PACKAGE_REQUEST";
export const GET_SMART_PACKAGE_SUCCESS = "GET_SMART_PACKAGE_SUCCESS";
export const GET_SMART_PACKAGE_FAILED = "GET_SMART_PACKAGE_FAILED";

export const UPDATE_SELECTED_HOTELS = "UPDATE_SELECTED_HOTELS";

export const GET_SMART_PACKAGE_FIRST_AVAILABLE_DATE_REQUEST = "GET_SMART_PACKAGE_FIRST_AVAILABLE_DATE_REQUEST";
export const GET_SMART_PACKAGE_FIRST_AVAILABLE_DATE_SUCCESS = "GET_SMART_PACKAGE_FIRST_AVAILABLE_DATE_SUCCESS";
export const GET_SMART_PACKAGE_FIRST_AVAILABLE_DATE_FAILED = "GET_SMART_PACKAGE_FIRST_AVAILABLE_DATE_FAILED";

export const GET_SMART_PACKAGE_ACCOMMODATION_REQUEST = "GET_SMART_PACKAGE_ACCOMMODATION_REQUEST";
export const GET_SMART_PACKAGE_ACCOMMODATION_SUCCESS = "GET_SMART_PACKAGE_ACCOMMODATION_SUCCESS";
export const GET_SMART_PACKAGE_ACCOMMODATION_FAILED = "GET_SMART_PACKAGE_ACCOMMODATION_FAILED";

export const UPDATE_SMART_PACKAGE_EXPIRED_AT = "UPDATE_SMART_PACKAGE_EXPIRED_AT";

export const CLEAR_SMART_PACKAGE_ORDER = "CLEAR_SMART_PACKAGE_ORDER";

export const SET_SMART_PACKAGE_ERROR = "SET_SMART_PACKAGE_ERROR";
export const CLEAR_SMART_PACKAGE_ERROR = "CLEAR_SMART_PACKAGE_ERROR";

export const SET_REPLACE_HOTEL_ID = "SET_REPLACE_HOTEL_ID";
