import React from "react";
import T from "prop-types";

import { BookingLayout } from "../../../Layouts";
import TourSummary from "../TourSummary/TourSummary";

const TourLayout = ({ children }) => {
  return (
    <BookingLayout dataTestid="tour-details-form" summary={<TourSummary />}>
      {children}
    </BookingLayout>
  );
};

TourLayout.propTypes = {
  children: T.any.isRequired,
};

export default TourLayout;
