import * as React from "react";
const RocketIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#FEEF43"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10.003 12.5-2.5-2.5m2.5 2.5a18.625 18.625 0 0 0 3.333-1.667M10.003 12.5v4.167s2.525-.459 3.333-1.667c.9-1.35 0-4.167 0-4.167M7.503 10a18.335 18.335 0 0 1 1.666-3.292 10.734 10.734 0 0 1 9.167-5.041c0 2.266-.65 6.25-5 9.166M7.503 10H3.336s.458-2.525 1.667-3.333c1.35-.9 4.166 0 4.166 0M3.753 13.75c-1.25 1.05-1.667 4.167-1.667 4.167s3.117-.417 4.167-1.667c.591-.7.583-1.775-.075-2.425a1.817 1.817 0 0 0-2.425-.075Z"
    />
  </svg>
);
export default RocketIcon;
