import * as React from "react";

function SvgMinus(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon" {...props}>
      <path fill="#F48D2E" d="M2 10h20v4H2z" />
    </svg>
  );
}

export default SvgMinus;
