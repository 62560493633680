import React, { useRef } from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { BackButton, SubmitButton } from "@tint_fe/ui";
import { useSticky } from "@tint_fe/hooks";
import { checkCurrencyChar } from "@tint_fe/helpers";

import { useSPSummaryInfo } from "../hooks";
import { useBreakPoints } from "../../../../hooks";

const ButtonsGroup = ({ onBack, onSubmit, loading, submitTitleOverride }) => {
  const { t } = useTranslation();
  const { isSm } = useBreakPoints();

  const ref = useRef();
  const isSticky = useSticky(ref.current, "bottom");
  const { pricePerPerson } = useSPSummaryInfo();
  const currencySP = useSelector(({ smartPackage }) => smartPackage?.charge_currency);
  const currencyIntegration = useSelector(({ global }) => global?.integration?.charge_currency);
  const currencyChar = checkCurrencyChar(currencySP ?? currencyIntegration);
  const submitLabel = submitTitleOverride ? submitTitleOverride : isSm ? t("continue") : t("continueBooking");

  return (
    <div ref={ref} className="button-group-wrapper">
      <div className={`buttons-group ${isSticky ? " select-hotels__button-group__sticky buttons-group__sticky" : ""}`}>
        {isSticky ? (
          <div className="booking-form sticky">
            <div className="col-md-7 sticky__content">
              <div className="sticky__row">
                <span className="select-hotels__button-group-package-price">
                  {!!pricePerPerson && (
                    <>
                      {currencyChar}
                      {pricePerPerson}
                      <span className="select-hotels__button-group-package-price-text"> / {t("person")}</span>
                    </>
                  )}
                </span>
                <BackButton
                  processing={loading}
                  type="button"
                  className="btn booking-large btn-back customer-back"
                  onClick={onBack}
                >
                  {t("back")}
                </BackButton>
                <SubmitButton label={submitLabel} className="mg-l-3" loading={loading} onClick={onSubmit} />
              </div>
            </div>
          </div>
        ) : (
          <>
            <BackButton type="button" className="btn booking-large btn-back customer-back" onClick={onBack}>
              {t("back")}
            </BackButton>
            <SubmitButton label={submitLabel} className="mg-l-3" loading={loading} onClick={onSubmit} />
          </>
        )}
      </div>
    </div>
  );
};

ButtonsGroup.propTypes = {
  onBack: T.func.isRequired,
  onSubmit: T.func.isRequired,
  loading: T.bool.isRequired,
  submitTitleOverride: T.string,
};

export default ButtonsGroup;
