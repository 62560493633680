import * as React from "react";
const TintProsChooseHotel = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#21AFE4"
      fillRule="evenodd"
      d="M12.17 17.927c-.284 0-.563-.014-.84-.041l-2.019 4.58a.895.895 0 0 1-1.64-.007l-1.028-2.386-2.46.836A.894.894 0 0 1 3.075 19.7l2.297-5.207a8.404 8.404 0 0 1-1.646-5.01C3.727 4.828 7.5 1 12.169 1c4.67 0 8.443 3.828 8.443 8.484a8.404 8.404 0 0 1-1.647 5.01l2.297 5.207a.895.895 0 0 1-1.106 1.208l-2.46-.836-1.03 2.387a.895.895 0 0 1-1.64.006l-2.019-4.58a8.535 8.535 0 0 1-.838.04Zm-1.246-1.906a6.689 6.689 0 0 0 2.49 0 6.647 6.647 0 0 0 3.852-2.26 6.627 6.627 0 0 0 1.557-4.277c0-3.68-2.986-6.695-6.654-6.695-3.668 0-6.653 3.014-6.653 6.695A6.627 6.627 0 0 0 7.105 13.8a6.647 6.647 0 0 0 3.82 2.221ZM9.541 17.51a8.427 8.427 0 0 1-2.84-1.593L5.535 18.56l1.315-.446a.895.895 0 0 1 1.109.493l.543 1.26 1.04-2.357Zm5.256 0 1.04 2.357.543-1.26a.895.895 0 0 1 1.109-.493l1.314.446-1.166-2.643a8.429 8.429 0 0 1-2.84 1.593Zm-4.43-4.18a.894.894 0 0 1-1.298-.944l.344-2.006-1.458-1.421a.895.895 0 0 1 .496-1.526l2.015-.293.9-1.826a.895.895 0 0 1 1.605 0l.901 1.826 2.015.293a.895.895 0 0 1 .496 1.526l-1.458 1.42.344 2.007a.894.894 0 0 1-1.298.943l-1.802-.947-1.802.947Zm.89-3.111-.118.683.614-.322a.894.894 0 0 1 .832 0l.614.322-.117-.683a.894.894 0 0 1 .257-.792l.497-.484-.686-.1a.895.895 0 0 1-.674-.49l-.307-.621-.307.622a.895.895 0 0 1-.673.489l-.687.1.497.484c.21.205.307.501.257.792Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TintProsChooseHotel;
