import { formatForApi } from "@tint_fe/helpers/utils/dates";

export const SPCheckoutFormFields = {
  START_DATE: "package_start_date",
  START_LOCATION: "package_start_location_id",
  START_LOCATION_TYPE: "package_start_location_type",
  END_LOCATION: "package_end_location_id",
  END_LOCATION_TYPE: "package_end_location_type",
  MEAL_PLAN: "meal_plan",
  HOTEL_LEVEL: "hotel_level",
  ROOMS: "rooms",
  CHILDREN: "children",
  ADULTS: "adults",
  FLIGHT_NUMBER: "flight_number",
  DEPARTURE_FLIGHT_NUMBER: "departure_flight_number",
  ARRIVAL_EXTRA_NIGHTS: "arrival_extra_nights",
  DEPARTURE_EXTRA_NIGHTS: "departure_extra_nights",
};

export const getCurrAccommodationPrice = (selectedHotels) => {
  return selectedHotels.reduce((acc, el) => acc + Math.ceil(Number(el.price)), 0);
};

export const getTotalPersonsFromRooms = (rooms) => {
  if (rooms?.length > 0) {
    const adults = rooms.reduce((acc, el) => acc + Number(el.adults), 0);
    const children = rooms.reduce((acc, el) => acc + el.children.length, 0);

    return { adults, children };
  }

  return { adults: 0, children: 0 };
};

export const getItemsWithHotels = (pi) => {
  return pi.filter((value, index, self) => {
    return (
      index ===
      self.findIndex(
        (t) => value.klass === "hotel" && t.display_name === value.display_name && t.product_id === value.product_id,
      )
    );
  });
};

export const getHotelsFromPi = (pi) => {
  return pi.filter((value) => value.klass === "hotel");
};

export const formatPiToSelectedHotel = (productItems = []) => {
  return productItems.map((pi) => ({
    city_id: pi.city_id,
    dates: {
      from: formatForApi(pi.start_date),
      to: formatForApi(pi.end_date),
    },
    display_name: pi.display_name,
    price: pi?.hotel_line_items?.reduce((acc, el) => acc + Number(el?.calculated_item_price), 0) ?? 0,
    product_id: pi.product_id,
  }));
};
