import React, { useEffect, useState } from "react";
import T from "prop-types";

const Fade = ({ show, animationIn, animationOut, duration, children }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return render ? (
    <div
      style={{
        animation: `${show ? animationIn : animationOut} ${duration}s`,
      }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
};

Fade.defaultProps = {
  duration: 0.3,
  animationIn: "fadeIn",
  animationOut: "fadeOut",
};

Fade.propTypes = {
  show: T.bool,
  animationIn: T.string,
  animationOut: T.string,
  duration: T.number,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default Fade;
