import React from "react";
import T from "prop-types";

import { useControlledInput } from "@tint_fe/hooks";
import { validationRule } from "@tint_fe/helpers";
import { Input } from "../../../components";
import { Lock } from "../../../assets/icons";

const normalizeCVC = (value) => {
  return (
    value
      .replace(/\s/g, "")
      .replace(/[^0-9]/g, "")
      .substr(0, 4) || ""
  );
};

const CvvInput = ({ control, ...rest }) => {
  const {
    field: { onChange, ...field },
    fieldState: { error },
    showValidateMark,
  } = useControlledInput({
    control,
    name: "cvc",
    rules: {
      ...validationRule.required("CVC"),
      ...validationRule.minLength("CVC", 3),
    },
  });

  return (
    <Input
      nextIcon={<Lock />}
      label="CVC"
      name="cvc"
      placeholder="CVC"
      inputMode="numeric"
      autoComplete="cc-csc"
      type="password"
      showValidateMark={showValidateMark}
      error={error}
      onChange={(event) => {
        const { value } = event.target;
        event.target.value = normalizeCVC(value);

        return onChange(event);
      }}
      {...rest}
      {...field}
    />
  );
};

CvvInput.propTypes = {
  control: T.object.isRequired,
};

export default CvvInput;
