import React, { useEffect } from "react";
import T from "prop-types";
import { isEmpty } from "lodash";

import { parseClasses } from "@tint_fe/helpers";
import { getProductById } from "@tint_fe/api";
import { useTranslation, useRequest } from "@tint_fe/hooks";
import { Rating, Map, Carousel, Img, Loader, HotelReviews } from "..";
import InfoModalLayout from "./InfoModalLayout";
import { Food } from "../../assets/icons";

const HotelInfoModal = ({
  isOpen,
  closeModal,
  isMapOpen,
  product_id,
  existingHotelInfo,
  onError,
  onSelect,
  ...rest
}) => {
  const { callToApi, loading, res } = useRequest({
    apiCall: getProductById,
    onError,
  });
  const fullHotelInfo = res
    ? { ...res.product, ...existingHotelInfo, images: [...res.product.images] }
    : existingHotelInfo;

  const { t, isRtl } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "hotel-info", classes: [isRtl && "rtl"] });

  useEffect(() => {
    isOpen && callToApi(product_id);
  }, [isOpen, callToApi, product_id]);

  const imgs = fullHotelInfo?.images?.map((el) => {
    return <Img key={el.alt_text + el.image_url} className={elCls("img")} alt={el.alt_text} src={el.image_url} />;
  });

  return (
    <InfoModalLayout
      isOpen={isOpen}
      closeModal={closeModal}
      className={mainCls}
      btnLabel={t("hotel")}
      onSelect={onSelect}
      loading={loading}
      {...rest}
    >
      <div dir={isRtl ? "rtl" : "ltr"} className={elCls("header", "mg-b-4")}>
        <h3 className={elCls("title", "second-font", "mg-md-r-4")}>{fullHotelInfo.display_name}</h3>
        <Rating className={elCls("rating")} number={fullHotelInfo.rating} />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <HotelReviews
            score={fullHotelInfo.expedia_guest_average}
            reviews={fullHotelInfo.expedia_guest_count}
            className="mg-b-2"
          />
          <Map
            hotelName={fullHotelInfo.display_name}
            address={fullHotelInfo.address}
            className={elCls("map")}
            locationObj={{
              lat: Number(fullHotelInfo.location?.lat),
              lng: Number(fullHotelInfo.location?.lon),
            }}
            isMapOpen={isMapOpen}
          />
          {fullHotelInfo?.breakfast_included && (
            <div className={elCls("pickup_info", "mg-t-4")}>
              <Food />
              <span>{t("hotelCard.breakfast")}</span>
            </div>
          )}
          {fullHotelInfo.pickup_info && (
            <div className={elCls("pickup_info", "mg-t-4")}>{fullHotelInfo.pickup_info}</div>
          )}
          <div className={elCls("description", "mg-t-4")}>{fullHotelInfo.description}</div>
        </>
      )}

      {!isEmpty(imgs) && !loading && (
        <Carousel
          config={{
            perView: 3,
            gap: 8,
            breakpoints: { 768: { perView: 2 }, 476: { perView: 1 } },
          }}
          list={imgs}
          delay={0}
        />
      )}
    </InfoModalLayout>
  );
};

HotelInfoModal.defaultProps = {
  existingHotelInfo: {},
};

HotelInfoModal.propTypes = {
  isOpen: T.bool,
  closeModal: T.func,
  isMapOpen: T.bool,
  product_id: T.oneOfType([T.string, T.number]),
  existingHotelInfo: T.object,
  onError: T.func,
  onSelect: T.func,
};

export default HotelInfoModal;
