import React from "react";
import T from "prop-types";
import { useTranslation } from "../../hooks";

import { join } from "@tint_fe/helpers";
import { SelectLang } from "@tint_fe/ui";

const LangsSelect = ({ className }) => {
  const { isRtl } = useTranslation();

  return (
    <SelectLang
      menuPosition={isRtl ? { left: 0 } : { right: 0 }}
      className={join(["booking-select-lng input-wrapper", className], " ")}
    />
  );
};

LangsSelect.propTypes = {
  className: T.string,
};

export default LangsSelect;
