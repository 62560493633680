import React from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import T from "prop-types";

import { scrollToError } from "../../../../utils";
import StickyButtonGroup from "../../../components/StickyButtonGroup";
import ReservationDetailsForm from "./ReservationDetails/ReservationDetailsForm";
import WhoTravelingForm from "./TourWhoTraveling/WhoTravelingForm";
import { serializeProductItemsAttributes } from "../../../../helpers/serializeOrderBody";
import { selectProductValuesFromOrder } from "../../../../redux/product/productSelectors";

const ExtraTourCheckoutForm = ({ createTourProductItem, createLoading, orderNumber, product }) => {
  const { handleSubmit, ...methods } = useFormContext();

  const loading = useSelector((state) => state.order.loading);
  const productValuesFromOrder = useSelector(selectProductValuesFromOrder);

  const getProductItemsAttribute = (values) => {
    const return_different_location = methods.getValues("return_different_location");
    const { who_traveling: whoTraveling, ...reservationDetails } = values;

    return serializeProductItemsAttributes(product, {
      reservationDetails: {
        ...(!return_different_location && {
          departure_flight_number: productValuesFromOrder?.departure_flight_number,
        }),
        ...reservationDetails,
        start_from: reservationDetails.location?.name,
      },
      whoTraveling,
    });
  };

  const onSubmit = (values) => {
    const productItemsAttributes = getProductItemsAttribute(values);
    createTourProductItem({ orderNumber, body: { product_item: { ...productItemsAttributes } } });
  };

  const onError = (e) => {
    console.error(e);
    scrollToError();
  };

  const submitHandler = handleSubmit(onSubmit, onError);

  // useEffect(() => {
  //   if (startDate) {
  //     const newDate = dates.cpDateTransformer(startDate);
  //     setValue("start_date", newDate, { shouldValidate: true });
  //   }
  // }, [startDate]);

  return (
    <>
      <ReservationDetailsForm />
      <WhoTravelingForm defaultWhoTraveling={productValuesFromOrder?.who_traveling} showExtras={false} />
      <StickyButtonGroup loading={loading || createLoading} onSubmit={submitHandler} />
    </>
  );
};

ExtraTourCheckoutForm.propTypes = {
  createTourProductItem: T.func.isRequired,
  createLoading: T.bool.isRequired,
  startDate: T.string.isRequired,
  product: T.object.isRequired,
  orderNumber: T.string.isRequired,
};

export default ExtraTourCheckoutForm;
