import { useCallback } from "react";
import { useSelector } from "react-redux";

import { setStepAction } from "../../../../redux/global/globalActions";
import { useReduxAction } from "../../../../hooks";
import { steps } from "../../../../helpers/constants";
import useUpdateExtras from "./useUpdateExtras";
import { allowShowExtras } from "../helpers";

const useBackToCheckoutStep = () => {
  const setStep = useReduxAction(setStepAction);
  const updateExtras = useUpdateExtras();

  const step = useSelector(({ global }) => global.step);

  return useCallback(() => {
    if (allowShowExtras(step)) {
      updateExtras({}, true);
      setStep(steps.CHECKOUT);
    }
  }, [step]);
};

export default useBackToCheckoutStep;
