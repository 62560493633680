import * as types from "./smartPackageTypes";

export const getSmartPackageRequest = (payload) => ({
  type: types.GET_SMART_PACKAGE_REQUEST,
  payload,
});

export const updateSelectedHotelsAction = (payload) => ({
  type: types.UPDATE_SELECTED_HOTELS,
  payload,
});

export const getSmartPackageAccommodationRequest = (payload) => ({
  type: types.GET_SMART_PACKAGE_ACCOMMODATION_REQUEST,
  payload,
});

export const setSmartPackageError = (payload) => ({
  type: types.SET_SMART_PACKAGE_ERROR,
  payload,
});

export const setReplaceHotelIdAction = (payload) => ({
  type: types.SET_REPLACE_HOTEL_ID,
  payload,
});
