import React from "react";

import { parseClasses } from "@tint_fe/helpers";
import useHandleIsOpenAllPoliciesModal from "./useHandleIsOpenAllPoliciesModal";

const PolicyBtn = () => {
  const { elCls } = parseClasses({
    base: "policy-btn-modal",
  });
  const handleIsOpenInfoModal = useHandleIsOpenAllPoliciesModal();

  return (
    <span onClick={() => handleIsOpenInfoModal(true)} className={elCls("btn")}>
      {"See all policies"}
    </span>
  );
};

export default PolicyBtn;
