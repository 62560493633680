import React, { useState } from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import { CircleItinerary, Collapse, FlagItinerary, SnapCarousel } from "@tint_fe/ui";
import { parseClasses } from "../../../utils";

const Header = ({ number, dayTitle }) => {
  return (
    <div className={"itinerary-item__header"}>
      Day {number}
      {dayTitle ? ` - ${dayTitle}` : ""}
    </div>
  );
};

Header.propTypes = {
  number: T.number,
  dayTitle: T.string,
};

const ItineraryItem = ({
  isLast,
  isFirst,
  number,
  initOpen,
  dayTitle,
  extras,
  chargeCurrency,
  onSelectExtra,
  onDirectAddExtra,
  extraLineItems,
  highlights,
  selectedIds,
  numberOfPeople,
}) => {
  const { isRtl } = useTranslation();
  const { mainCls, elCls } = parseClasses({
    base: "itinerary-item",
    classes: [isRtl && "rtl"],
  });

  const [open, setOpen] = useState(initOpen);
  const hasHighlights = highlights?.length > 0;

  const toggleOpen = () => setOpen((prevVal) => !prevVal);

  return (
    <div onClick={toggleOpen} className={mainCls}>
      <div className={elCls("point-icon")}>{isFirst || isLast ? <FlagItinerary /> : <CircleItinerary />}</div>
      <Collapse
        className={elCls("collapse")}
        header={<Header number={number} dayTitle={dayTitle} />}
        open={open}
        initialHeight={65}
      >
        {hasHighlights && (
          <ul className={elCls("itinerary-points")}>
            {highlights.map((ip, index) => {
              return (
                <li key={index} className={elCls("itinerary-point", "p5")}>
                  {ip}
                </li>
              );
            })}
          </ul>
        )}
        <SnapCarousel
          type={`extra-${number}`}
          items={extras}
          extraLineItems={extraLineItems}
          chargeCurrency={chargeCurrency}
          onSelectExtra={onSelectExtra}
          onDirectAddExtra={onDirectAddExtra}
          selectedIds={selectedIds}
          numberOfPeople={numberOfPeople}
        />
      </Collapse>
    </div>
  );
};

ItineraryItem.propTypes = {
  isLast: T.bool,
  isFirst: T.bool,
  number: T.number,
  initOpen: T.bool,
  dayTitle: T.string,
  extras: T.array,
  chargeCurrency: T.string,
  onSelectExtra: T.func,
  onDirectAddExtra: T.func,
  extraLineItems: T.array,
  highlights: T.array,
  selectedIds: T.array,
  numberOfPeople: T.number,
};

export default ItineraryItem;
