import React from "react";
import T from "prop-types";
import { Link } from "react-router-dom";

import { useTranslation } from "../../../hooks";
import { parseClasses } from "@tint_fe/helpers";

const BookingNavItem = ({ number, title, current, isAllowed, path }) => {
  const { mainCls, elCls } = parseClasses({
    base: "booking-nav-item",
    classes: [current && "active", isAllowed && "allowed"],
  });
  const { t, i18n } = useTranslation();

  return (
    <Link onClick={(e) => !isAllowed && e.preventDefault()} to={path} className={mainCls}>
      <span className={elCls("number")}>{number}.</span>
      <span className={elCls("name")} style={{ wordBreak: i18n.language === "de" ? "break-all" : "inherit" }}>
        {t(title)}
      </span>
    </Link>
  );
};

BookingNavItem.propTypes = {
  number: T.number.isRequired,
  title: T.string.isRequired,
  current: T.bool.isRequired,
  isAllowed: T.bool.isRequired,
  path: T.string.isRequired,
};

export default BookingNavItem;
