import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";

import LoaderEarth from "../../assets/images/loader-earth.gif";

const loadingFacts = {
  touristisrael: [
    "The city of Haifa in Israel is home to the stunning Baha'i Gardens, a UNESCO World Heritage Site.",
    "The Dead Sea, located between Israel and Jordan, is the lowest point on Earth.",
    "Israel has the highest number of museums per capita in the world.",
    "Israel's national bird is the Hoopoe, a colorful and distinctive bird.",
    "Tel Aviv has the largest number of vegan restaurants per capita in the world.",
    "Israel is home to the world's only underwater restaurant in Eilat",
  ],
  touristjordan: [
    "Lawrence of Arabia was based on the real-life British officer T.E. Lawrence, who played a key role in the Arab Revolt against the Ottoman Empire during World War I.",
    "Petra, an ancient city carved into the red rock cliffs, is one of the New Seven Wonders of the World.",
    "Jordan has a rich tradition of hospitality, with guests considered a blessing and treated with great respect.",
    "The Jordanian flag is one of only two national flags that do not contain red, white, or blue colors.",
    "Jordan is home to the Dead Sea, known for its high salt content, making it easy to float on the water.",
    "Amman, the capital city, is one of the oldest continuously inhabited cities in the world.",
  ],
  petrafromisrael: [
    "Lawrence of Arabia was based on the real-life British officer T.E. Lawrence, who played a key role in the Arab Revolt against the Ottoman Empire during World War I.",
    "Petra, an ancient city carved into the red rock cliffs, is one of the New Seven Wonders of the World.",
    "Jordan has a rich tradition of hospitality, with guests considered a blessing and treated with great respect.",
    "The Jordanian flag is one of only two national flags that do not contain red, white, or blue colors.",
    "Jordan is home to the Dead Sea, known for its high salt content, making it easy to float on the water.",
    "Amman, the capital city, is one of the oldest continuously inhabited cities in the world.",
  ],
  touristegypt: [
    "The Great Pyramid of Giza is the only one of the Seven Wonders of the Ancient World still standing.",
    "The ancient Egyptian calendar had 12 months of 30 days each, plus 5 extra days at the end of the year.",
    "The Sphinx, with the body of a lion and the head of a pharaoh, is a symbol of ancient Egyptian civilization.",
    "Egypt is home to the Nile River, the longest river in the world.",
    "The ancient Egyptians invented one of the earliest forms of writing, hieroglyphics.",
    "Cairo, the capital city, is the largest city in Africa and the Middle East.",
  ],
  touristsaudiarabia: [
    "Saudi Arabia has a coastline along the Red Sea and the Arabian Gulf, offering diverse marine life and coral reefs.",
    "The traditional dress for Saudi men is the thobe, a long white robe, and for women, it is the abaya, a black cloak.",
    "Saudi Arabia is home to the world's largest continuous sand desert, the Rub' al Khali or Empty Quarter.",
    "Mecca, located in Saudi Arabia, is the holiest city in Islam and the destination of the annual Hajj pilgrimage.",
    "The country is home to the largest oil reserves in the world.",
    "Riyadh, the capital city, is one of the fastest-growing cities in the world.",
  ],
  touristitaly: [
    "The Vatican City, an independent city-state surrounded by Rome, is the smallest country in the world.",
    "Italy is famous for its fashion industry, with Milan being one of the fashion capitals of the world.",
    "The Leaning Tower of Pisa is not the only leaning tower in Italy; there are several others across the country.",
    "Italy is home to the highest number of UNESCO World Heritage Sites in the world.",
    "The Italian language has more than 20 different regional dialects.",
    "Pizza originated in Naples, Italy, in the 18th century.",
  ],
  touristjapan: [
    "Cherry blossom season, known as 'Sakura,' is a major event in Japan, with people celebrating by having picnics under the blooming trees.",
    "Japan has the highest life expectancy in the world, with women living on average to 87 years and men to 81 years.",
    "Japan is home to the world's oldest company, Nishiyama Onsen Keiunkan, founded in 705 AD.",
    "Sumo wrestling is Japan's national sport and has a history dating back over 1,500 years.",
    "The bullet train, known as the Shinkansen, can reach speeds of up to 320 km/h (200 mph).",
    "Japan has more than 6,800 islands, though only about 430 are inhabited.",
  ],
  touristjourney: [
    "Lawrence of Arabia was based on the real-life British officer T.E. Lawrence, who played a key role in the Arab Revolt against the Ottoman Empire during World War I.",
    "The Great Pyramid of Giza is the only one of the Seven Wonders of the Ancient World still standing.",
    "Saudi Arabia has a coastline along the Red Sea and the Arabian Gulf, offering diverse marine life and coral reefs.",
    "The Vatican City, an independent city-state surrounded by Rome, is the smallest country in the world.",
    "Cherry blossom season, known as 'Sakura,' is a major event in Japan, with people celebrating by having picnics under the blooming trees.",
    "The ancient Egyptians invented one of the earliest forms of writing, hieroglyphics.",
  ],
};

const countryNames = {
  touristisrael: "Israel",
  touristjordan: "Jordan",
  touristegypt: "Egypt",
  touristsaudiarabia: "Saudi Arabia",
  touristitaly: "Italy",
  touristjapan: "Japan",
  touristjourney: "countries",
};

const Preloader = ({
  text,
  className,
  scrollIntoView,
  scrollOnLoadingToTop,
  dataTestId = "preloader",
  useInformativeLoader,
  integrationName = "touristisrael",
  ...rest
}) => {
  const ref = useRef();
  const refContainer = useRef();
  const facts = loadingFacts[integrationName] || loadingFacts.touristisrael;
  const countryName = countryNames[integrationName] || countryNames.touristisrael;
  const [fact, setFact] = useState(facts[0]);
  const loaderTitle =
    integrationName === "touristjourney"
      ? "We’re now building your package, it might take 20-30 seconds, so in the meantime here are a few facts about various countries around the world."
      : `We’re now building your package, it might take 20-30 seconds, so in the meanwhile here are a few facts about ${countryName}`;

  useEffect(() => {
    if (scrollIntoView) {
      ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [scrollIntoView]);

  useEffect(() => {
    if (scrollOnLoadingToTop || useInformativeLoader) {
      refContainer.current?.scrollIntoView?.({ behavior: "smooth" });
    }
  }, [scrollOnLoadingToTop, useInformativeLoader]);

  useEffect(() => {
    if (useInformativeLoader) {
      let index = 0;
      const interval = setInterval(() => {
        index = (index + 1) % facts.length;
        setFact(facts[index]);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [useInformativeLoader]);

  const ContainerDiv = ({ children }) => (
    <div
      data-testid={dataTestId}
      ref={refContainer}
      className={`booking-widget--preloader${className ? ` ${className}` : ""}`}
      {...rest}
    >
      {children}
    </div>
  );

  ContainerDiv.propTypes = {
    children: T.node,
  };

  if (useInformativeLoader) {
    return (
      <ContainerDiv>
        <div className={"booking-widget--preloader-container"}>
          <span className={"booking-widget--preloader-title"}>{loaderTitle}</span>
          <span className={"booking-widget--preloader-subtitle"}>{`"${fact}"`}</span>
          <img src={LoaderEarth} height={120} width={120} alt="Loading..." />
        </div>
      </ContainerDiv>
    );
  }

  return (
    <ContainerDiv>
      <div ref={ref} className="booking-widget--preloader-animation">
        <svg width="110" height="110" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <linearGradient id="linearColors" x1="50" y1="0" x2="50" y2="1">
            <stop offset="0%" stopColor="currentColor" />
            <stop offset="25%" stopColor="currentColor" />
            <stop offset="50%" stopColor="currentColor" stopOpacity="0.8" />
            <stop offset="75%" stopColor="currentColor" stopOpacity="0.5" />
            <stop offset="90%" stopColor="white" stopOpacity="0.1" />
            <stop offset="100%" stopColor="white" stopOpacity="0.05" />
          </linearGradient>
          <path d="M50 7 A 30 30, 0, 0, 0, 50 93 M50 100" fill="none" stroke="url(#linearColors)" strokeWidth="14" />
        </svg>
      </div>
      {text && <div className="booking-widget--preloader-text">{text}</div>}
    </ContainerDiv>
  );
};

Preloader.propTypes = {
  text: T.string,
  className: T.string,
  scrollIntoView: T.bool,
  scrollOnLoadingToTop: T.bool,
  dataTestId: T.string,
  useInformativeLoader: T.bool,
  integrationName: T.string,
};

export default Preloader;
