import { useRouterMatch, useBreakPoints } from "@tint_fe/hooks";

// for payment form we have some special layout
// this hooks will trigger when we should hide/show
// some special elements for this form
const usePaymentLayout = () => {
  const isPayment = useRouterMatch("payment");
  const { isMd } = useBreakPoints();

  return isPayment && isMd;
};

export default usePaymentLayout;
