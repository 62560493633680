import { useSelector } from "react-redux";

import { isAirport } from "@tint_fe/helpers";

const useIsCityIsAirport = (cityId) => {
  const cities = useSelector(({ product }) => product.availability.cities);
  const cityName = cities?.find((el) => el.id === cityId)?.name || "";

  return [isAirport(cityName), cityName, cities || []];
};

export default useIsCityIsAirport;
