import React from "react";
import { useSelector } from "react-redux";

import { HotelInfoModal } from "@tint_fe/ui";
import { useReduxAction } from "../../hooks";
import { setErrorAction } from "../../redux/global/globalActions";

const BookingHotelInfoModal = ({ ...rest }) => {
  const serErrorToGlobal = useReduxAction(setErrorAction);

  const rootId = useSelector(({ global }) => global.rootId);

  const onError = (err) => {
    serErrorToGlobal(err);

    return err;
  };

  return <HotelInfoModal lockBodyScroll onError={onError} rootId={rootId} {...rest} />;
};

export default BookingHotelInfoModal;
