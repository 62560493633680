import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { useSticky } from "@tint_fe/hooks";

import { HotelCard, Button, Preloader } from "@tint_fe/ui";
import BookingHotelInfoModal from "./BookingHotelInfoModal";
import { checkCurrencyChar } from "@tint_fe/helpers";

const HotelsBucket = ({
  city,
  index,
  hotels,
  selectedHotel,
  changeSelectedHotel,
  persons,
  breakfastIncluded,
  currency,
  initShowMore,
  accommodationPrice,
  toursPrice,
}) => {
  const { t, isRtl } = useTranslation();
  const [showMore, setShowMore] = useState(isInitialShowMore() || initShowMore);
  const [showingMore, setShowingMore] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState({});

  const currencySP = useSelector(({ smartPackage }) => smartPackage?.charge_currency);
  const price = useSelector((state) => state.global.price);
  const currencyChar = checkCurrencyChar(price?.charge_currency ?? currencySP);

  const ref = useRef();
  const isSticky = useSticky(ref.current, "top", true);
  const normalizeHotels = showMore ? [...hotels].sort((a, b) => a.price - b.price) : hotels.slice(0, 2);

  function isInitialShowMore() {
    let isInitShowMore = false;
    hotels?.forEach((element, ind) => {
      if (element.hotel_id === selectedHotel?.product_id) {
        isInitShowMore = ind > 1;
      }
    });

    return isInitShowMore;
  }

  const getPrice = (hotelPrice) => {
    if (!hotelPrice) return;

    return Math.ceil(
      (Number(toursPrice) + (Number(accommodationPrice) - Number(selectedHotel?.price)) + Number(hotelPrice)) /
        Number(persons),
    );
  };

  const closeMoreInfoModal = () => setShowMoreInfo({});

  const onSelectFromModal = () => {
    closeMoreInfoModal();

    if (selectedHotel?.product_id !== showMoreInfo.hotel_id) {
      changeSelectedHotel(showMoreInfo);
    }
  };

  useEffect(() => {
    if (showingMore) {
      const timer = setTimeout(() => {
        setShowingMore(false);
        setShowMore(true);
      }, 500);

      setTimeout(() => {
        const el = document.querySelector(`#hotel-bucket-${index} .hotel-card__selected`);
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showingMore]);

  return (
    <>
      <div ref={ref} id={`hotel-bucket-${index}`} className="hotels-bucket">
        <div className="hotels-bucket__title-container">
          {isSticky && (
            <h3
              dir={isRtl ? "rtl" : "auto"}
              className={`hotels-bucket__title hotels-bucket__title hotels-bucket__sticky-mask uppercase ${isRtl}`}
            >
              {t("hotelBucket.title", { city })}
            </h3>
          )}
          <span className={isSticky ? " hotels-bucket__sticky-title" : ""}>
            <h3 dir={isRtl ? "rtl" : "auto"} className={`hotels-bucket__title uppercase ${isRtl}`}>
              {t("hotelBucket.title", { city })}
            </h3>
          </span>
        </div>
        <div className="hotels-bucket__cards-wrapper">
          {showingMore && <Preloader />}
          {normalizeHotels.map((el) => {
            const key = el.hotel_id ?? el.id;
            const isSelected = selectedHotel?.product_id ? Number(selectedHotel?.product_id) === Number(key) : false;

            return (
              <HotelCard
                key={key}
                className={"sp-hotel-card"}
                address={`Central ${city}`}
                selected={isSelected}
                isPriceAbsolute
                price={getPrice(Number(el.price))}
                name={el.display_name}
                images={el.images}
                breakfastIncluded={breakfastIncluded}
                rating={Number.parseInt(el?.rating.replace("*", ""), 10)}
                changeSelectedHotel={() => changeSelectedHotel(el, index + 1)}
                onShowMoreInfo={() => setShowMoreInfo(el)}
                onShowLocation={() => {
                  setShowMoreInfo({ openWithMap: true, ...el });
                }}
                preventScrollIntoView
                overall_rating={el.expedia_guest_average}
                overall_ratings_total={el.expedia_guest_count}
                forceUpdateCarousel={isEmpty(showMoreInfo)}
                currency={currency}
                level={el.level}
              />
            );
          })}
        </div>

        {hotels.length > 3 && !showMore && (
          <Button ghost onClick={() => setShowingMore(true)} className="hotels-bucket__controller">
            {t("hotelBucket.showMore")}
          </Button>
        )}
      </div>

      <BookingHotelInfoModal
        product_id={showMoreInfo.hotel_id ?? showMoreInfo.id}
        isOpen={!isEmpty(showMoreInfo)}
        isMapOpen={showMoreInfo.openWithMap}
        closeModal={closeMoreInfoModal}
        onSelect={onSelectFromModal}
        selected={selectedHotel?.product_id === showMoreInfo.hotel_id}
        existingHotelInfo={{
          ...normalizeHotels.find((el) => el.hotel_id === showMoreInfo.hotel_id || el.hotel_id === showMoreInfo.id),
        }}
        price={`${currencyChar}${getPrice(Number(showMoreInfo.price))}`}
      />
    </>
  );
};

HotelsBucket.propTypes = {
  hotels: T.array,
  city: T.string,
  selectedHotel: T.object,
  changeSelectedHotel: T.func,
  persons: T.number,
  index: T.number.isRequired,
  breakfastIncluded: T.bool,
  currency: T.string,
  initShowMore: T.bool,
  accommodationPrice: T.number,
  toursPrice: T.oneOfType([T.string, T.number]),
};

export default HotelsBucket;
