export const modalSteps = {
  CONFIGURATION: "configuration",
  PAYMENT: "payment",
};

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const hasSPExtras = (itinerary) => {
  let hasNonEmptyExtras = false;
  const itineraryData = itinerary?.order?.smart_package_itinerary;

  if (!itineraryData) {
    return false;
  }

  for (const date in itineraryData) {
    if (itineraryData.hasOwnProperty(date)) {
      const extras = itineraryData[date].available_extras;
      if (!isObjectEmpty(extras)) {
        hasNonEmptyExtras = true;
        break;
      }
    }
  }

  return hasNonEmptyExtras;
};
