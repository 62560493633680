import React, { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isObject } from "lodash";

import { handleABTestingFeatures, parseClasses, QueryString } from "@tint_fe/helpers";
import { useUser, useRouterMatch, useBreakPoints, useIsRequestPayment } from "@tint_fe/hooks";
import { useCheckIsDefaultIntegration } from "@tint_fe/redux-utils/hooks";

import PaymentSummary from "./PaymentSummary";
import LangsSelect from "../LangsSelect";
import MobileSummary from "./MobileBookingSummary";
import BookingNavItem from "./BookingNavItem";
import { bookingTypes } from "../../../helpers/constants";
import { useTranslation, usePaymentLayout } from "../../../hooks";
import checkIfIsCovidTest from "../../../helpers/checkIfIsCovidTest";
import useNavigationByKeyboard from "./useNavigationByKeyboard";
import useCheckIfNextStepIsAllowed from "./useCheckIfNextStepIsAllowed";
import { getSteps } from "./helpers";
import { selectIfCustomPrice } from "../../../redux/sharedSelectors";
import { useSkipAccommodation } from "../../containers/SmartPackage/hooks";
import { CHECKOUT } from "../../router";
import CurrencySelect from "../CurrencySelect";

const BookingNav = () => {
  const { user } = useUser();
  const { t, i18n, isRtl } = useTranslation();
  const bookingType = useSelector((state) => state.global.bookingType);
  const buildType = useSelector((state) => state.order.data?.build_type);
  const order = useSelector((state) => state.order.data);
  const isCustomPrice = useSelector(selectIfCustomPrice);

  const { pathname } = useLocation();
  const isDefaultIntegration = useCheckIsDefaultIntegration();
  const isPayment = usePaymentLayout();
  const isCheckoutPage = useRouterMatch(CHECKOUT);
  const { isMd } = useBreakPoints();
  const { allowAccomodation } = useSkipAccommodation();
  const { autoAccommodation } = handleABTestingFeatures();
  const isRequestPayment = useIsRequestPayment();
  const hideCurrecnySelect = isRequestPayment || isCustomPrice;

  const [title, setTitle] = useState("");
  const [steps, setSteps] = useState([]);

  const { isAgent } = user || {};
  const { payment_uuid, source } = QueryString.parse(document.location.search);
  const isSP = bookingType === bookingTypes.SMART_PACKAGE;
  const isActivity = bookingType === bookingTypes.ACTIVITY;
  const preventNavigate = (isSP && isDefaultIntegration) || payment_uuid || source === "request_payment";

  const { mainCls, elCls } = parseClasses({
    base: "booking-nav",
    classes: [`number-fo-steps-${steps.length}`, `lang-${i18n.language}`],
  });
  const { isCovidTest } = checkIfIsCovidTest();
  const currentIndex = steps.findIndex((el) => pathname.includes(el.value));
  const checkIfNextStepAllowed = useCheckIfNextStepIsAllowed(steps);
  const isActivityOnPayment = isActivity && currentIndex === 0 && !["customer", "checkout"].includes(order?.state);

  const getTittle = (item) => {
    if (!item.title) return item.label;
    if (isCovidTest) return "details";

    if (!isObject(item.title)) return item.title;

    return item.title[bookingType] || item.title[bookingTypes.TOUR];
  };

  useEffect(() => {
    setSteps(
      getSteps(
        buildType || bookingType,
        !allowAccomodation || autoAccommodation,
        isAgent,
        isCustomPrice,
        isActivityOnPayment,
      ),
    );
  }, [bookingType, allowAccomodation, autoAccommodation, isAgent, buildType, isActivityOnPayment]);

  useEffect(() => {
    const currentStep = steps.find((el) => pathname.includes(el.value));
    if (currentStep) {
      setTitle(() => getTittle(currentStep));
    }
  }, [pathname, steps]);

  useNavigationByKeyboard(steps, checkIfNextStepAllowed, currentIndex, preventNavigate || isActivity);

  const getContent = () => {
    switch (true) {
      case isMd:
        return isCheckoutPage ? null : <MobileSummary />;
      case isPayment:
        return <PaymentSummary />;
      default:
        return (
          <div dir={isRtl ? "rtl" : "auto"} className={mainCls}>
            {steps.map((item, index) => {
              return (
                <BookingNavItem
                  path={item.path}
                  step={item.value}
                  key={uuidV4()}
                  current={currentIndex === index}
                  isAllowed={
                    (currentIndex > index || checkIfNextStepAllowed(index)) && !preventNavigate && !item.disabled
                  }
                  number={index + 1}
                  title={item.label}
                />
              );
            })}
          </div>
        );
    }
  };

  return (
    <div className={elCls("wrapper", "col col-12 col-md-7")}>
      <div className={elCls("title-wrapper")}>
        <h3 className={elCls("title", "h2", isMd && "ab-summary__title")}>{t(title)}</h3>
      </div>
      <div className="row col-md-4 nav-wrapper__justify-end">
        {(isMd || isPayment) && (
          <>
            {!hideCurrecnySelect && (
              <>
                <CurrencySelect className="mg-r-4" />
                <div className="nav-wrapper__separator" />
              </>
            )}
            <LangsSelect className="mg-l-4" />
          </>
        )}
      </div>
      {getContent()}
    </div>
  );
};

export default BookingNav;
