import { useHistory } from "react-router-dom";

import { updateUrl } from "../utils";

const useHistoryNavigation = () => {
  const { push, replace, ...rest } = useHistory();

  const decorateAction = (action, location, actionType) => {
    if (typeof location === "object") {
      if (location.search) {
        const { pathname, hash } = document.location;
        const hashLocationPathname = hash.replace("#", "");

        updateUrl(`${pathname}?${location.search}#${location.pathname || hashLocationPathname}`, actionType);

        replace({
          ...rest.location,
          pathname: location.pathname || hashLocationPathname,
        });

        return;
      }

      action({ ...rest.location, pathname: location.pathname });

      return;
    }

    action({ ...rest.location, pathname: location });
  };

  return {
    push: (location) => decorateAction(push, location, "push"),
    replace: (location) => decorateAction(replace, location, "replace"),
    ...rest,
  };
};

export default useHistoryNavigation;
