import { createSelector } from "reselect";

export const selectOrder = (state) => state.order;

export const selectOrderError = createSelector([selectOrder], (order = {}) => {
  return { ...(order.error || {}) };
});

export const selectOrderExpiredTime = ({ order }) => {
  if (order.data?.product_items) {
    const withExpire = order.data.product_items.filter((el) => !!el.expire_at);

    return withExpire.reduce((acc, el) => {
      if (!acc) return el.expire_at;

      return acc > el.expire_at || !el.expire_at < new Date().getTime() ? el.expire_at : acc;
    }, null);
  }
};

export const selectOderProductExtraLineItems = createSelector(selectOrder, ({ data }) => {
  const orderProductItem = data?.product_items?.[0] || {};
  const orderProductExtraLineItems = orderProductItem.extra_line_items || [];

  return orderProductExtraLineItems;
});
