import * as React from "react";

function SvgDocumentDownload(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7C5.5 5.89543 6.39543 5 7.5 5H14.0858C14.351 5 14.6054 5.10536 14.7929 5.29289L17.2071 7.70711C17.3946 7.89464 17.5 8.149 17.5 8.41421V17C17.5 18.1046 16.6046 19 15.5 19H7.5C6.39543 19 5.5 18.1046 5.5 17V7ZM12.25 10C12.25 9.58579 11.9142 9.25 11.5 9.25C11.0858 9.25 10.75 9.58579 10.75 10V13.1843L10.0285 12.4678C9.73457 12.176 9.2597 12.1776 8.96783 12.4715C8.67595 12.7654 8.67761 13.2403 8.97152 13.5322L10.8922 15.4396C11.0285 15.6277 11.25 15.75 11.5 15.75L11.507 15.75L11.5166 15.75C11.7155 15.7493 11.906 15.6696 12.0462 15.5285L14.0322 13.5285C14.3241 13.2345 14.3224 12.7597 14.0285 12.4678C13.7345 12.1759 13.2597 12.1776 12.9678 12.4715L12.25 13.1944V10ZM15 7.5L13.5 6V8C13.5 8.55228 13.9477 9 14.5 9H16.5L15 7.5Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgDocumentDownload;
