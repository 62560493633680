import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { validationRule } from "../../../../../utils";
import SelectField from "../../../../components/Form/SelectField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import { selectAvailableDropOffPoints, selectProductSettings } from "../../../../../redux/product/productSelectors";
import { useTranslation } from "../../../../../hooks";
import useUpdateTourPrice from "../../hooks/useUpdateTourPrice";
import usePreselectDefaultLocation from "../../hooks/usePreselectDefaultLocation";

const EndCitySelect = () => {
  const { t } = useTranslation();
  const dropOffPoints = useSelector(selectAvailableDropOffPoints);
  const loading = useSelector((state) => state.product.priceBandsFetching);
  const { display_end_location, different_end_location } = useSelector(selectProductSettings);
  const updateTourPrice = useUpdateTourPrice();
  const preselectDefaultLocation = usePreselectDefaultLocation("custom_dropoff_point");
  const showEndLocationFromAPI = display_end_location || different_end_location;

  const {
    control,
    formState: { isSubmitted },
    setValue,
    watch,
  } = useFormContext();
  const startDateValue = watch("start_date");
  const who_traveling = { ...watch("who_traveling") };
  const return_different_location = watch("return_different_location");
  const city_id = watch("city_id");
  const dropOffCityId = watch("dropoff_city_id");

  const availableDropOffPoints =
    different_end_location && city_id && dropOffPoints.length !== 1
      ? dropOffPoints.filter((el) => el.city_id !== city_id)
      : dropOffPoints;

  const handleChangeEndCity = (val) => {
    if (Object.values(who_traveling).some((el) => !!el)) {
      updateTourPrice({ formData: val }, { getExtrasFromFormContext: true });
    }

    preselectDefaultLocation(val.dropoff_city_id, availableDropOffPoints);

    return val;
  };

  useEffect(() => {
    if (availableDropOffPoints?.length === 1) {
      setValue("dropoff_city_id", availableDropOffPoints[0].city_id);
      if (dropOffCityId !== availableDropOffPoints[0].city_id) {
        setValue("custom_dropoff_point", { name: availableDropOffPoints[0].default_address, lat: null, lon: null });
      }
    }
  }, [availableDropOffPoints[0]?.city_id, startDateValue, city_id]);

  if (!return_different_location && !showEndLocationFromAPI) {
    return null;
  }

  return (
    <ControllerInput
      control={control}
      name="dropoff_city_id"
      render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => {
        return (
          <SelectField
            label={t("formField.endIn")}
            placeholder={t("formField.selectPoint")}
            disabled={!startDateValue}
            isPreselected={availableDropOffPoints.length === 1}
            options={availableDropOffPoints.map((el) => ({
              value: el.city_id,
              label: el.city_name,
            }))}
            errors={error?.message}
            isSearchable={false}
            isSubmitting={isSubmitted}
            noOptionsMessage={t("formField.startFrom.noOptionsMessage")}
            onChange={(val) => onChange(handleChangeEndCity(val))}
            loading={loading}
            value={value}
            {...restField}
          />
        );
      }}
      rules={{
        ...validationRule.required(t("formField.endIn")),
      }}
    />
  );
};

export default EndCitySelect;
